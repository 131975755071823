import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import classes from './MyVideos.module.scss'
import videoIcon from './../../../../assets/images/icons/video class icon.svg'
import Button from '../../../../components/UI/Button/Button'
import * as videoService from '../../../../services/video/videoService'
import GridList from '../../../../components/UI/GridList/GridList'
import ItemCard from '../../../../components/ItemCard/ItemCard'
import Pagination from '../../../../components/UI/Pagination/Pagination'

const paginationConf = {
  pageLimit: 20,
  total: null
}
class Videos extends Component {
  state = {
    loading: true,
    videos: []
  }

  componentDidMount () {
    this.getVideos()
  }

  getVideos = (offset = 0) => {
    videoService
      .getPurchasedVideos(
        this.props.token,
        `limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then(response => {
        paginationConf.total = response.data.meta
          ? response.data.meta.total
          : '0'

        console.log('My videos response', response)
        this.setState({
          loading: false,
          videos: response.data ? response.data.data : []
        })
      })
      .catch(error => {
        console.log(error.data)
      })
  }

  handlePaginationchange = pag => {
    console.log(pag)
    this.getVideos((pag.currentPage - 1) * pag.pageLimit)
  }

  render () {
    const { loading, videos } = this.state
    const loaderRender = <CircularProgress className={classes.VideoLoader} />
    const renderContent = () =>
      videos && videos.length > 0 ? (
        <GridList style={{ flex: '1' }}>
          {videos.map(video => (
            <ItemCard
              key={video.id}
              type='video'
              itemData={video}
              onClickPublish={(e, slug, status) =>
                this.onHandleClickPublish(e, slug, status)
              }
            />
          ))}
        </GridList>
      ) : (
        <React.Fragment>
          <section className={classes.Section}>
            <img src={videoIcon} alt='Video Icon' />
            <h3 className={classes.SectionHeadline}>
              You haven't taken any video classes yet
            </h3>
            <Link to='/browse-infodepot'>
              <Button size='large' className={classes.ButtonSettings}>
                BROWSE VIDEO CLASSES NOW
              </Button>
            </Link>
          </section>
        </React.Fragment>
      )

    return (
      <div className={classes.Container}>
        {loading ? loaderRender : renderContent()}
        {paginationConf.total ? (
          <Pagination
            style={{ marginTop: '40px' }}
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationchange}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken
  }
}

export default connect(mapStateToProps)(Videos)
