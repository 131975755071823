import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';
import classes from './Subscriptions.module.scss';
// import Switch from '../../components/UI/Switch/Switch';
import IOSSwitch from '../../components/UI/Switch/IOSSwitch';
import {
  PLAN_ID_FREE,
  getPricingPlans,
  PLAN_ID_PRO,
  isAnnualFromPaymentOrNull,
} from '../../utils/consts';
import { map, capitalize, upperCase, isObject } from 'lodash';
import * as userService from "../../services/user/userService"

function PricingPage({ match, user, token }) {
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
  const [isAnnualBilling, setIsAnnualBilling] = useState(true);
  const showAllPlans = match.params && match.params.plans === 'all';
  const activePricingPlans = getPricingPlans(
    showAllPlans || !user ? null : user.plan
  );
  // console.log(match);

  const [state, setState] = useState({
    data: null,
    error: null,
    status: "idle"
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          status: "pending"
        });
        const { data } = await userService.getMyCurrentSubscription(token);
        if (isObject(data)) {
          let isAnn = isAnnualFromPaymentOrNull(data);
          if (isAnn !== null) {
            setIsAnnualBilling(isAnn)
          }
          setState({
            ...state,
            data,
            status: "resolved"
          });
        } else {
          setState({
            ...state,
            status: "resolved"
          });
        }
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected"
        });
      }
    };

    getData();
  }, []);
  const { data: payment } = state;

  if (shouldScrollToTop) {
    window.scrollTo(0, 0);
    setShouldScrollToTop((prevState) => {
      return !prevState
    });
  }

  function onSwitchChangeHandler() {
    setIsAnnualBilling((prevState) => {
      return !prevState;
    });
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Banner}>
        <h1>Pricing plans for teachers</h1>
        {showAllPlans && user && user.plan === PLAN_ID_PRO ? (
          <div className={classes.DummyPlanNotif}></div>
        ) : (
          <>
            <h3>
              {user && user.plan
                ? 'Upgrade your plan. You can cancel any time.'
                : 'Get started for free, upgrade later when you need it. You can cancel any time.'}
            </h3>
            <div className={classes.BillingPeriod}>
              <div
                className={[classes.Label, classes.LabelLeft].join(' ')}
                style={{ fontWeight: !isAnnualBilling ? 700 : 400 }}
              >
                <div style={{ display: 'inline-block', textAlign: 'center' }}>
                  Monthly Billing
                </div>
              </div>
              <IOSSwitch
                checked={isAnnualBilling}
                value={isAnnualBilling}
                switchChanged={onSwitchChangeHandler}
              />
              <div
                className={[classes.Label, classes.LabelRight].join(' ')}
                style={{ fontWeight: isAnnualBilling ? 700 : 400 }}
              >
                Annual Billing{' '}
                <div style={{ display: 'inline-block' }}>(discounts apply)</div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={classes.CardsLayout}>
        {map(activePricingPlans, (plan) => (
          <div
            className={[
              classes.Card,
              plan.recommended ? classes.Recommended : '',
            ].join(' ')}
          >
            <h2 className={classes.Name}>{capitalize(plan.name)}</h2>
            <div className={classes.Promo}>
              {map(plan.promos, (promo) => (
                <div className={classes.PromoItem}>
                  {promo.item}{' '}
                  <span className={classes.PromoHelper}>{promo.helper}</span>
                </div>
              ))}
            </div>
            <div style={{ marginBottom: '28px' }}>
              <span className={classes.Price}>
                $
                {isAnnualBilling
                  ? plan.pricePerMonthAnnually
                  : plan.pricePerMonthMonthly}
              </span>
              {plan.id === PLAN_ID_FREE
                ? ' forever'
                : isAnnualBilling
                ? '/month (billed annually)'
                : '/month (billed monthly)'}
            </div>

            {plan.id === (user ? user.plan : null) ? (
              <div className={classes.CurrentPlanNotif}>
                This is your current plan
              </div>
            ) : showAllPlans &&
              ((plan.id === PLAN_ID_FREE &&
                user &&
                user.plan !== PLAN_ID_FREE) ||
                (user && user.plan === PLAN_ID_PRO)) ? (
              <div className={classes.DummyPlanNotif}></div>
            ) : (
              <Link
                to={`/${user && payment ? 'upgrade-teacher-plan' : user ? 'sign-up-teacher-step-2' : 'sign-up-teacher'}/${
                  plan.id
                }/${+isAnnualBilling}${user ? '/upgrade' : ''}`}
              >
                <Button fullWidth={true} color="primary" size="large">
                  {upperCase(plan.btnWording)}
                </Button>
              </Link>
            )}

            <div className={classes.Prologue}>{plan.prologue}</div>
            <ul className={classes.Benefits}>
              {map(plan.benefits, (benefit) => (
                <li>{benefit}</li>
              ))}
            </ul>
            {plan.epilogue && (
              <div style={{ marginTop: '24px' }}>
                {map(plan.epilogue, (ep) => (
                  <div className={classes.Epilogue}>{ep}</div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken
  };
};

export default withRouter(connect(mapStateToProps)(PricingPage));
