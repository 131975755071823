import React from 'react';
import { Link } from 'react-router-dom';
import * as callService from '../../services/liveCall/liveCallService';
import Video from 'twilio-video';
import { createLocalVideoTrack } from 'twilio-video';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/indexActions';
import classes from './StartVideoCallModal.module.scss';
import Modal from '../UI/Modal/Modal';
import ReactSVG from 'react-svg';
import Avatar from '../UI/Avatar/Avatar';
import LeftShape from './../../assets/images/graphics/left_shape.svg';
import CloseIcon from './../../assets/images/icons/menu_close_icon.svg';
import CheckIcon from './../../assets/images/icons/check-mark-icon.svg';
import RightShape from './../../assets/images/graphics/right_shape.svg';
import LeftMiddleShape from './../../assets/images/graphics/left_middle_shape.svg';
import RightMiddleShape from './../../assets/images/graphics/right_middle_shape.svg';
import { ChosenDisplayName } from '../UI/ChosenDisplayName/ChosenDisplayName';


class StartVideoCallModule extends React.PureComponent {
	state = {
		loading: false,
		twilioVideo: false
	}

	deleteVideoCallModal = () => {
		const {token, slug  } = this.props;
		
		callService
			.declineVideoCall(token, slug)
			.then((response) => {
				this.setState({ loading: false});
				console.log(token, slug)

				if (this.props.onSuccess) {
					this.props.onSuccess(response.data);
				}
				// console.log('rsponse',response.data)
				// console.log('newstate',this.state.videoCallData)

				// this.props.history.push({
				// 	//pathname: `/call/${this.props.call.slug}/live-call-video`,
				// 	state: { price: price }
				// });
				
			})
			.catch((error) => {
				this.setState({ loading: false });
				this.props.snackbarAdd({
					message: error.response.data.message,
					timeout: 8000,
					type: 'error'
				});
			});
	}

	acceptVideoCall = () => {	
		const {token, slug} = this.props;
		
		//console.log(call);//this.props.token, this.props.call.slug
		callService
				.acceptVideoCall(token, slug)
				.then((response) => {
					this.setState({ 
						loading: false,
						twilioVideo: true,
					});
					//console.log('accept', response.data)

					if (this.props.onSuccess) {
						this.props.onSuccess(response.data);
					}

					console.log(response.data);
					Video.connect(response.data.token,
						{  	audio: false,
						  name: response.data.roomName,
						  video: { width: 640 }
						}).then(room => {
						// console.log('Connected to Room "%s"', response.data.roomName);
						//setRoom(room);
						// room.on("participantConnected", participantConnected);

						// room.on("participantDisconnected", participantDisconnected);
						// room.once("disconnected", (error) =>
						// 	room.participants.forEach(participantDisconnected)
						// );
					  }, error => {
						console.error(`Unable to connect to Room: ${error.message}`);
					  });

					//    createLocalVideoTrack().then(track => {
					// 		const localMediaContainer = document.getElementById('twilioVideo');
					// 		localMediaContainer.appendChild(track.attach());
					// 	});


					//   Video.createLocalVideoTrack().then(track => {
					// 	container.append(track.attach());
					// 	console.log(container)
					//   });
				})
				.catch((error) => {
					this.setState({ loading: false });
					this.props.snackbarAdd({
						message: error.response.data.message,
						timeout: 8000,
						type: 'error'
					});
				});
	}

	render() {
		const {authorName, videoName, profile} = this.props;
		
		return (
			<div>
				<Modal
					className={[classes.Card, classes.ModalVideoCall].join(' ')}
					open={this.props.open}
					//onClose={() => this.props.onClose()}
					>
						<div className={classes.LeftShape}>
							<img src={LeftShape} alt="ShapeLeft" />
						</div>
						<div className={classes.RightShape}>
							<img src={RightShape} alt="ShapeLeft" />
						</div>
						<div className={classes.LeftMiddleShape}>
							<img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
						</div>
						<div className={classes.RightMiddleShape}>
							<img src={RightMiddleShape} alt="ShapeRightMiddle" />
						</div>
						<Avatar
							src={
								profile && profile.photo ? (
									profile.photo.big
								) : null
							}
							className={classes.Avatar}
							size="140"
						/>
						{/* name */}
						<h3 className={classes.VideoCallName}>{videoName}</h3>
						<h4 className={classes.AuthorName}>
							{/* {authorName} */}
							{this.props.call.author && this.props.call.author.displayName && <ChosenDisplayName user={this.props.call.author} />}
							{this.props.call.author && this.props.call.author.profile && this.props.call.author.profile.displayName && <ChosenDisplayName user={this.props.call.author.profile} />}
						</h4>
						<span>Incoming call...</span>
						<div className={classes.ButtonsWrap}>
							<a 
							className={classes.CancelVideoCall} 
							onClick={this.deleteVideoCallModal}
							callSlug={this.props.call}
							>
								<ReactSVG  src={CloseIcon} /><br/>
							</a>
							<a 
							className={classes.AcceptVideoCall} 
							onClick={this.acceptVideoCall}>
								<ReactSVG  src={CheckIcon} /><br/>
							</a>
						</div>
					</Modal>	
			</div>
			
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};

export default withRouter(connect(mapDispatchToProps)(StartVideoCallModule));
