import axiosInstance from '../axiosDefaultInstance'

/**
 * Get User data based on local storage token
 * @param token
 * @returns {AxiosPromise<any>}
 */
export const getClientPaymentToken = token => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/payment/gateway/braintree/client-token'

  return axiosInstance.get(url, config)
}

export const makeTransaction = (token, paymentToken, amount, sca = false) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const payload = sca === false ? {
    paymentMethodId: paymentToken,
    amount
  } : {
    paymentIntentId: paymentToken,
    amount
  }

  const url = '/payment/gateway/stripe/make-transaction'

  return axiosInstance.post(url, payload, config)
}

export const makeTransactionPaypal = (token, paymentID) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const payload = {
    orderID: paymentID
  }

  const url = '/payment/gateway/paypal/capture-order'

  return axiosInstance.post(url, payload, config)
}

export const getTransactions = (token, type, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const url = '/payment/' + type + (queryParams || '')
  // const url = '/payment/' + type + '?filterDate=' + (queryParams ? queryParams : '');
  // '?limit=20&offset=0&sort=orderNumber&direction=desc';

  return axiosInstance.get(url, config)
}

export const makePayout = (amount, token) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const payload = {
    amount
  }

  const url = '/payouts/make-transaction'

  return axiosInstance.post(url, payload, config)
}
