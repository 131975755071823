import React, { Component } from "react";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import clone from "lodash.clonedeep";
import Grid from "@material-ui/core/Grid";

import classes from "./SignUpTeacher.module.scss";
import { Form, Input, Checkbox } from "../../components/UI/Form/Form";
import Button from "../../components/UI/Button/Button";
import Card from "../../components/UI/Card/Card";
import * as validator from "../../utils/formValidator";
import * as userService from "../../services/user/userService";
import { errorParser } from "../../utils/errorParser";
import {
  prepareTeacherExpertiseBasedOnSubmit,
  prepareTeacherExpertiseSubmit,
} from "../../utils/common";
import { capitalize } from "lodash";
import {PLAN_ID_FREE, getPricingPlan, getPlanConst} from "../../utils/consts";
import Divider from "../../components/UI/Divider/Divider";

class SignUpTeacher extends Component {
  state = {
    loading: false,
    formIsValid: false,
    selectedExpertiseCounter: 0,
    selectedExpertiseBasedOnCounter: 0,
    formControls: {
      firstName: {
        id: "firstName",
        type: "text",
        placeholder: "First name",
        value: "",
        validation: {
          required: true,
        },
        error: "",
        touched: false,
      },
      lastName: {
        id: "lastName",
        type: "text",
        placeholder: "Last name",
        value: "",
        validation: {
          required: true,
        },
        error: "",
        touched: false,
      },
      username: {
        id: "username",
        type: "text",
        placeholder: "Username",
        value: "",
        validation: {
          required: true,
          minLength: 4,
        },
        error: "",
        touched: false,
      },
      email: {
        id: "email",
        type: "text",
        placeholder: "Email address",
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        error: "",
        touched: false,
      },
      password: {
        id: "password",
        type: "password",
        placeholder: "Password",
        infolabel: "8 characters minimum",
        value: "",
        showPassword: false,
        adornment: {
          position: "end",
          icon: () => (
            <IconButton
              className={classes.IconShowPassword}
              onClick={(e) => this.toggleShowPassword()}
            >
              {this.state.formControls.password.showPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          ),
        },
        validation: {
          required: true,
          minLength: 8,
        },
        error: "",
        touched: false,
      },
      aboutMe: {
        id: "aboutMe",
        type: "textarea",
        charCounter: 250,
        placeholder: "Short intro about you",
        infolabel: "It will be displayed below your name on listings pages",
        value: "",
        validation: {
          required: true,
          maxLength: 250,
        },
        error: "",
        touched: false,
      },
      promotional: {
        id: "promotional",
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      terms: {
        id: "terms",
        type: "checkbox",
        checked: "false",
        value: "",
        validation: {
          required: true,
        },
        error: "",
        touched: false,
      },
      expertiseArtsAndDesign: {
        id: "expertiseArtsAndDesign",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertiseBusiness: {
        id: "expertiseBusiness",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertiseTechnology: {
        id: "expertiseTechnology",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertiseLifeStyle: {
        id: "expertiseLifeStyle",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertisePersonalDevelopment: {
        id: "expertisePersonalDevelopment",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertiseHealthFitness: {
        id: "expertiseHealthFitness",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertiseMusic: {
        id: "expertiseMusic",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      expertiseLanguage: {
        id: "expertiseLanguage",
        expertise: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      basedOnEducation: {
        id: "basedOnEducation",
        basedOn: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      basedOnLifeExperience: {
        id: "basedOnLifeExperience",
        basedOn: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      basedOnTechnicalTraining: {
        id: "basedOnTechnicalTraining",
        basedOn: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
      basedOnOther: {
        id: "basedOnOther",
        basedOn: true,
        type: "checkbox",
        checked: "false",
        value: "",
        error: "",
        touched: true,
      },
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleShowPassword() {
    const stateClone = clone(this.state);

    stateClone.formControls.password.showPassword =
      !stateClone.formControls.password.showPassword;
    stateClone.formControls.password.type = stateClone.formControls.password
      .showPassword
      ? "text"
      : "password";
    this.setState(stateClone);
  }

  inputChangeHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);

    // if there is no value it is the radio/checkbox input, in that case take the name
    if (formControlsCopy[controlName].type !== "checkbox") {
      formControlsCopy[controlName].value = e.target.value;
    } else {
      formControlsCopy[controlName].value =
        formControlsCopy[controlName].value === controlName ? "" : controlName;
      // Counters are used for validation(disable submit button if at least one is not selected)
      if (formControlsCopy[controlName].expertise === true) {
        if (formControlsCopy[controlName].value) {
          this.setState((prevState) => ({
            selectedExpertiseCounter: prevState.selectedExpertiseCounter + 1,
          }));
        } else {
          this.setState((prevState) => ({
            selectedExpertiseCounter: prevState.selectedExpertiseCounter - 1,
          }));
        }
      } else if (formControlsCopy[controlName].basedOn === true) {
        if (formControlsCopy[controlName].value) {
          this.setState((prevState) => ({
            selectedExpertiseBasedOnCounter:
              prevState.selectedExpertiseBasedOnCounter + 1,
          }));
        } else {
          this.setState((prevState) => ({
            selectedExpertiseBasedOnCounter:
              prevState.selectedExpertiseBasedOnCounter - 1,
          }));
        }
      }
    }
    formControlsCopy[controlName].touched = true;
    formControlsCopy[controlName].error = "";

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  inputBlurHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const errorMsg = validator.validateInput(formControlsCopy[controlName]);
    if (errorMsg) {
      formControlsCopy[controlName].error = errorMsg;
    }

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  prepareSubmitData = () => {
    const areasOfExpertise = prepareTeacherExpertiseSubmit(
      this.state.formControls,
      []
    );
    const basedOnExpertises = prepareTeacherExpertiseBasedOnSubmit(
      this.state.formControls,
      []
    );

    return {
      username: this.state.formControls.username.value,
      email: this.state.formControls.email.value,
      plainPassword: {
        first: this.state.formControls.password.value,
        second: this.state.formControls.password.value,
      },
      profile: {
        firstName: this.state.formControls.firstName.value,
        lastName: this.state.formControls.lastName.value,
        introAboutMe: this.state.formControls.aboutMe.value,
        terms: Boolean(this.state.formControls.terms.value),
      },
      settings: {
        promotional: Boolean(this.state.formControls.promotional.value),
      },
      areaOfExpertises: areasOfExpertise,
      expertisesBasedOn: basedOnExpertises,
    };
  };

  submit = ({planType}) => {
    if (this.state.formIsValid) {
      this.setState({ loading: true });

      let preparedData = this.prepareSubmitData();
      if (planType) {
        preparedData = {...preparedData, plan: planType}
      }

      userService
        .signupTeacher(preparedData)
        .then((response) => {
          const urlParams = this.props.location.pathname;
          this.props.history.push("/check-email", { urlParams });
        })
        .catch((error) => {
          console.log(error);
          const cloneForm = clone(this.state.formControls);

          if (error.response.data.errors) {
            const apiErr = errorParser(error.response.data.errors);
            console.log(apiErr);
            // config object to be used by checkFormApiErrors
            const errorStateConfig = {
              formEmailError: "email",
              formPlainPasswordFirstError: "password",
              formProfileIntroAboutMeError: "aboutMe",
              formProfileFirstNameError: "firstName",
              formProfileLastNameError: "lastName",
              formUsernameError: "username",
            };
            // checking form errors for matching pairs defined in the errorStateConfig
            validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);
          }

          this.setState({
            formIsValid: false,
            formControls: cloneForm,
            loading: false,
          });
        });
    } else {
      this.setState({
        formIsValid: validator.formIsValid(this.state.formControls),
      });
      // TODO focus to the first errored input
    }
  };

  render() {
    let { planId, isAnnualBilling } = this.props.match.params;
    isAnnualBilling = !!+isAnnualBilling;
    planId = planId || PLAN_ID_FREE;
    const chosenPlan = getPricingPlan(planId);
    const totalPrice = isAnnualBilling
      ? 12 * chosenPlan.pricePerMonthAnnually
      : chosenPlan.pricePerMonthMonthly;
    const planType = getPlanConst(planId, isAnnualBilling);

    return (
      <div className={classes.Container}>
        <h1 style={{marginBottom: chosenPlan.pricePerMonthMonthly > 0 ? '12px' : '30px'}}>
          {planId === PLAN_ID_FREE
            ? 'Create account as teacher'
            : `Start your ${capitalize(chosenPlan.name)} plan for teachers`}
        </h1>
        {chosenPlan.pricePerMonthMonthly > 0 && (
          <div style={{ marginBottom: '30px' }}>Step 1 of 2</div>
        )}

        {chosenPlan.pricePerMonthMonthly > 0 && (
          <div className={classes.CostsSummary}>
            <div className={classes.Details}>
              {capitalize(chosenPlan.name)} plan for teachers
            </div>
            <div style={{ marginTop: '6px' }}>
              $
              {isAnnualBilling
                ? `${chosenPlan.pricePerMonthAnnually}/month (billed annually)`
                : `${chosenPlan.pricePerMonthMonthly}/month (billed monthly)`}
            </div>
            <Divider
              horizontal="true"
              style={{ margin: '15px 0', backgroundColor: '#cae5d7' }}
            />
            <div className={classes.Details}>
              <div>Today's total</div>
              <div>${totalPrice.toLocaleString()}</div>
            </div>
          </div>
        )}
      
        <Card className={classes.SignUpCard}>
          <Form onSubmit={() => this.submit({planType})}>
            <h3 style={{ width: "100%" }}>Basic Info</h3>
            <Input
              style={{ width: "calc(50% - 6px)" }}
              config={this.state.formControls.firstName}
              onChange={(e) => this.inputChangeHandler(e, "firstName")}
              onBlur={(e) => this.inputBlurHandler(e, "firstName")}
            />
            <Input
              style={{ width: "calc(50% - 6px)", marginLeft: "auto" }}
              config={this.state.formControls.lastName}
              onChange={(e) => this.inputChangeHandler(e, "lastName")}
              onBlur={(e) => this.inputBlurHandler(e, "lastName")}
            />
            <Input
              config={this.state.formControls.username}
              onChange={(e) => this.inputChangeHandler(e, "username")}
              onBlur={(e) => this.inputBlurHandler(e, "username")}
            />
            <Input
              config={this.state.formControls.email}
              onChange={(e) => this.inputChangeHandler(e, "email")}
              onBlur={(e) => this.inputBlurHandler(e, "email")}
            />
            <Input
              config={this.state.formControls.password}
              onChange={(e) => this.inputChangeHandler(e, "password")}
              onBlur={(e) => this.inputBlurHandler(e, "password")}
            />
            <h3 style={{ marginTop: "30px" }}>Introduction and Fun Facts</h3>
            <Input
              config={this.state.formControls.aboutMe}
              onChange={(e) => this.inputChangeHandler(e, "aboutMe")}
              onBlur={(e) => this.inputBlurHandler(e, "aboutMe")}
            />

            <div style={{ width: "100%", marginTop: "30px" }}>
              <h5 style={{ marginBottom: "0px", fontSize: "1rem", fontWeight: "600" }}>Area of expertise </h5>
            </div>
            <div>
              <p
                style={{
                  color: "#a5a5a5",
                  fontSize: "11.25px",
                  marginBottom: "10px",
                }}
              >
                {" "}
                You can select more than one{" "}
              </p>
            </div>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseArtsAndDesign}
                    value={"expertiseArtsAndDesign"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseArtsAndDesign")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertiseArtsAndDesign")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Arts & Design
                  </span>
                </div>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseBusiness}
                    value={"expertiseBusiness"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseBusiness")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertiseBusiness")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Business
                  </span>
                </div>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseTechnology}
                    value={"expertiseTechnology"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseTechnology")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertiseTechnology")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Technology
                  </span>
                </div>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseLifeStyle}
                    value={"expertiseLifeStyle"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseLifeStyle")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertiseLifeStyle")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Lifestyle
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <Checkbox
                    config={
                      this.state.formControls.expertisePersonalDevelopment
                    }
                    value={"expertisePersonalDevelopment"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertisePersonalDevelopment")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertisePersonalDevelopment")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px", marginRight: "-22px", display: "inline-block" }}>
                    Personal Development
                  </span>
                </div>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseHealthFitness}
                    value={"expertiseHealthFitness"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseHealthFitness")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertiseHealthFitness")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Health & Fitness
                  </span>
                </div>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseMusic}
                    value={"expertiseMusic"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseMusic")
                    }
                    onBlur={(e) => this.inputBlurHandler(e, "expertiseMusic")}
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Music
                  </span>
                </div>
                <div>
                  <Checkbox
                    config={this.state.formControls.expertiseLanguage}
                    value={"expertiseLanguage"}
                    onChange={(e) =>
                      this.inputChangeHandler(e, "expertiseLanguage")
                    }
                    onBlur={(e) =>
                      this.inputBlurHandler(e, "expertiseLanguage")
                    }
                  />
                  <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                    Language
                  </span>
                </div>
              </Grid>
            </Grid>

            <div style={{ width: "100%", marginTop: "50px" }}>
              <h5 style={{ marginBottom: "0px", fontSize: "1rem", fontWeight: "600" }}>
                Your expertise is based on{" "}
              </h5>
            </div>
            <div style={{ width: "100%", }}>
              <p
                style={{
                  color: "#a5a5a5",
                  fontSize: "11.25px",
                  marginBottom: "10px",
                }}
              >
                {" "}
                You can select more than one{" "}
              </p>
            </div>
            <div>
              <div>
                <Checkbox
                  config={this.state.formControls.basedOnEducation}
                  value={"basedOnEducation"}
                  onChange={(e) =>
                    this.inputChangeHandler(e, "basedOnEducation")
                  }
                  onBlur={(e) => this.inputBlurHandler(e, "basedOnEducation")}
                />
                <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                  Education
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.basedOnLifeExperience}
                  value={"basedOnLifeExperience"}
                  onChange={(e) =>
                    this.inputChangeHandler(e, "basedOnLifeExperience")
                  }
                  onBlur={(e) =>
                    this.inputBlurHandler(e, "basedOnLifeExperience")
                  }
                />
                <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                  Life Experience
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.basedOnTechnicalTraining}
                  value={"basedOnTechnicalTraining"}
                  onChange={(e) =>
                    this.inputChangeHandler(e, "basedOnTechnicalTraining")
                  }
                  onBlur={(e) =>
                    this.inputBlurHandler(e, "basedOnTechnicalTraining")
                  }
                />
                <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                  Technical Training
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.basedOnOther}
                  value={"basedOnOther"}
                  onChange={(e) => this.inputChangeHandler(e, "basedOnOther")}
                  onBlur={(e) => this.inputBlurHandler(e, "basedOnOther")}
                />
                <span style={{ marginLeft: "-1rem", fontSize: "15px" }}>
                  Other (i.e. Mom said I'm special)
                </span>
              </div>
            </div>
            <div className={classes.TermsWrap} style={{ marginTop: "50px" }}>
              <Checkbox
                config={this.state.formControls.promotional}
                value={"promotional"}
                onChange={(e) => this.inputChangeHandler(e, "promotional")}
                onBlur={(e) => this.inputBlurHandler(e, "promotional")}
              />
              <span style={{ marginLeft: "-1.5rem" }}>
                I wish to receive promotional emails <br />
                (newsletters and special offers).
              </span>
            </div>
            <div className={classes.TermsWrap} style={{ marginBottom: "35px" }}>
              <Checkbox
                config={this.state.formControls.terms}
                value={"terms"}
                onChange={(e) => this.inputChangeHandler(e, "terms")}
                onBlur={(e) => this.inputBlurHandler(e, "terms")}
              />
              <span style={{ marginLeft: "-1.5rem" }}>
                I agree to InfoDepot{" "}
                <Link to={"/terms-and-conditions"}>Terms & Conditions</Link> and{" "}
                <Link to={"/privacy-notice"}>Privacy Policy</Link>.
              </span>
            </div>

            <Button
              fullWidth={true}
              size="large"
              type="submit"
              disabled={
                this.state.loading ||
                !this.state.formIsValid ||
                !this.state.selectedExpertiseCounter ||
                !this.state.selectedExpertiseBasedOnCounter
              }
              loading={this.state.loading}
            >
              {chosenPlan.pricePerMonthMonthly > 0 ? 'NEXT' : 'FINISH'}
            </Button>
          </Form>
        </Card>
        <div className={classes.NoAccountWrap}>
          <span>Already have an account?</span>{" "}
          <Link to={"/auth"}>Sign in</Link>
        </div>
      </div>
    );
  }
}

export default SignUpTeacher;
