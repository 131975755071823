import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Switch from '../../../components/UI/Switch/Switch';
import classes from '../AccountSettings.module.scss';
import { updateSettings } from '../../../services/user/userService';

class EmailSettings extends React.PureComponent {
	state = {
		loading: false,
		promotional: this.props.user.settings.promotional
	};

	handleChangePromo = () => {
		this.setState({ loading: true });

		this.setState((state) => {
			this.patchSettings({ promotional: !state.promotional });
			return { loading: true, promotional: !state.promotional };
		});
	};

	patchSettings = (payload) => {
		if (!this.state.loading) {
			updateSettings(this.props.token, payload)
				.then((resp) => {
					this.setState({ loading: false });
					this.props.updateStore();
				})
				.catch((err) => console.log(err.data));
		}
	};

	render() {
		const { user } = this.props;
		const { loading, promotional } = this.state;
		return (
			<div className={classes.EmailSettings}>
				<div className={classes.TitleWrap}>
					<h3>Email notifications </h3>

					{loading && (
						<span>
							<CircularProgress style={{ width: '20px', height: '20px' }} />
						</span>
					)}
				</div>

				<div className={classes.SwitchWrap}>
					I wish to receive promotional emails <br />
					(newsletters and special offers)
					<Switch
						disabled={loading}
						checked={promotional}
						value={user.settings.promotional}
						switchChanged={this.handleChangePromo}
					/>
				</div>
			</div>
		);
	}
}

export default EmailSettings;
