import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/indexActions';
import Card from './../../../components/UI/Card/Card';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import clone from 'lodash.clonedeep';
import * as validator from '../../../utils/formValidator';
import classes from './../Auth.module.scss';
import Button from '../../../components/UI/Button/Button';
import { Form, Input } from '../../../components/UI/Form/Form';
import * as authService from '../../../services/auth/authService';

class ResetPassword extends Component {
  state = {
    loading: false,
    formIsValid: false,
    formSubmitting: false,
    formControls: {
      password: {
				id: 'password',
				type: 'password',
        placeholder: 'New password',
        infolabel: '8 characters minimum',
				value: '',
				showPassword: false,
				adornment: {
					position: 'end',
					icon: () => (
						<IconButton
							className={classes.IconShowPassword}
							onClick={(e) => this.toggleShowPassword()}>
							{
								this.state.formControls.password.showPassword ? <Visibility /> :
								<VisibilityOff />}
						</IconButton>
					)
				},
				validation: {
          required: true,
          minLength: 8
				},
				error: '',
				valid: false,
				touched: false
			},
    }
};

toggleShowPassword() {
  const stateClone = clone(this.state);

  stateClone.formControls.password.showPassword = !stateClone.formControls.password
    .showPassword;
  stateClone.formControls.password.type =
    stateClone.formControls.password.showPassword ? 'text' :
    'password';
  this.setState(stateClone);
}

inputChangeHandler = (e, controlName) => {
  const formControlsCopy = clone(this.state.formControls);

  formControlsCopy[controlName].value = e.target.value;
  formControlsCopy[controlName].touched = true;
  formControlsCopy[controlName].error = '';

  this.setState({
    formControls: formControlsCopy,
    formIsValid: validator.formIsValid(formControlsCopy)
  });
};

inputBlurHandler = (e, controlName) => {
  const formControlsCopy = clone(this.state.formControls);
  const errorMsg = validator.validateInput(formControlsCopy[controlName]);
  if (errorMsg) {
    formControlsCopy[controlName].error = errorMsg;
  }

  this.setState({
    formControls: formControlsCopy,
    formIsValid: validator.formIsValid(formControlsCopy)
  });
};

prepareSubmitData() {
  const query = new URLSearchParams(this.props.location.search);
  const code = query.get('token');
  const userData = {
    token: code,
    plainPassword: {
      first: this.state.formControls.password.value,
      second: this.state.formControls.password.value,
    },
    
  };
  return userData;
}

submit(e) {
  e.preventDefault();
  // if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
  //e.target.focus();
  console.log(this.state);
  if (this.state.formIsValid) {
    this.setState({loading: true});
    authService.resetPassword(this.prepareSubmitData())
    .then((response) => {
    console.log('[ResetPassword] Success', response);
    this.props.onAutoLogin(response.data.token, response.data.user);
    this.props.history.push('/account');
    })
    .catch((err) => {
              console.log('[ResetPassword] error', err);
              this.setState({ loading: false });
              this.props.snackbarAdd({
                message: err.response.data.error_description || err.response.data.message,
                type: 'error',
                timeout: 8000
              });
            });
    console.log(this.state);
  } else {
      this.setState({ formIsValid: validator.formIsValid(this.state.formControls) });
      // TODO focus to the first errored input
  }
}

  render() {
    const formElementsArray = [];
		for (let key in this.state.formControls) {
			const inputClasses = [];
			if (!this.state.formControls[key].valid && this.state.formControls[key].touched) {
				inputClasses.push(classes.Invalid);
			}

			formElementsArray.push({
				id: key,
				config: this.state.formControls[key],
				classes: inputClasses
			});
		} 
    return ( 
      <div className={classes.Container}>
				<h1>Choose new password</h1>
				<Card className={classes.ResetPasswordCard}>
					<Form onSubmit={(e) => this.submit(e)}>
          <Input
							config={this.state.formControls.password}
							onChange={(e) => this.inputChangeHandler(e, 'password')}
							onBlur={(e) => this.inputBlurHandler(e, 'password')}
						/>
						<Button
							fullWidth={true}
							size="large"
							type="submit"
							className="btn btn-primary"
							disabled={this.state.loading || !this.state.formIsValid}
							loading={this.state.loading}>
							RESET PASSWORD
						</Button>
					</Form>
				</Card>
			</div>
     );
  }
}

const mapStateToProps = (state) => {
	return {
		loading: state.auth.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
    onAutoLogin: (tokenData, userData) => dispatch(actions.authUsingToken(tokenData, userData))
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);