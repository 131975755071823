import React from "react"
import { Link, NavLink } from 'react-router-dom';
import SvgIcon from '@material-ui/core/SvgIcon';
import classes from './Footer.module.scss';

import Logo from './../../../assets/images/logo/InfoDepot_logo.svg';

const Footer = ()  => {
  const publicNavLinks = (
		<React.Fragment>
			<NavLink activeClassName={classes.activeLink} to={'/pricing-plans/all'}>Pricing</NavLink>
			<NavLink activeClassName={classes.activeLink} to={'/fees-info'}>Fees</NavLink>
			<NavLink activeClassName={classes.activeLink} to={'/privacy-notice'}>Privacy Notice</NavLink>
      <NavLink activeClassName={classes.activeLink} to={'/terms-and-conditions'}>Terms & Conditions</NavLink>
      <NavLink activeClassName={classes.activeLink} to={'/faq'}>FAQs</NavLink>
      <NavLink activeClassName={classes.activeLink} to={'/quick-start'}>Quick Start Tutorials</NavLink>
      <NavLink activeClassName={classes.activeLink} to={'/contact-us'}>Contact us</NavLink>
		</React.Fragment>
  );
  
  const socialBlock = (
    <ul className={classes.FooterSocial}>
      <li>
      <a
        href="https://www.facebook.com/InfoDepot-106916771015009"
        target="_blank"
        rel="noopener noreferrer"
      >
          <SvgIcon>
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" class="svg-inline--fa fa-facebook-f fa-w-10" role="img" 
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" 
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
            </svg>
          </SvgIcon>
        </a>
      </li>
      <li>
      <a
        href="https://www.instagram.com/infodepot/"
        target="_blank"
        rel="noopener noreferrer"
      >
          <SvgIcon>
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" class="svg-inline--fa fa-instagram fa-w-14" role="img" 
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" 
          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
          </svg>
          </SvgIcon>
        </a>
      </li>
      <li>
      <a
        href="https://twitter.com/InfoDepotLLC"
        target="_blank"
        rel="noopener noreferrer"
      >
          <SvgIcon>
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" class="svg-inline--fa fa-twitter fa-w-16" role="img"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" 
           d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
           </svg>
          </SvgIcon>
        </a>
      </li>
      <li>
      <a
        href="https://www.linkedin.com/company/infodepot/"
        target="_blank"
        rel="noopener noreferrer"
      >
          <SvgIcon>
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" class="svg-inline--fa fa-linkedin-in fa-w-14" role="img"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" 
           d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
           </svg>
          </SvgIcon>
        </a>
      </li>
      <li>
      <a
        href="https://www.pinterest.com/YourInfoDepot/"
        target="_blank"
        rel="noopener noreferrer"
      >
          <SvgIcon>
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="pinterest" class="svg-inline--fa fa-pinterest fa-w-16" role="img" 
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" 
          d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
          </svg>
          </SvgIcon>
        </a>
      </li>
    </ul>
  );
  
  return(
    <footer className={classes.Footer}>
      {/* logo */}
      <div>
			<Link className={classes.Logo} to={'/'}>
				<div>
					<img src={Logo} alt="Infodepot" />
				</div>
			</Link>
      </div>
      <div className={classes.NavWrapper}>
        {/* footer navigation links */}
        <nav>
          {publicNavLinks}
        </nav>
        {socialBlock}
        <div className={classes.Copyright}>© InfoDepot {(new Date().getFullYear())}</div>
      </div>
    </footer>
  );  
}

export default Footer;

