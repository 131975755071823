import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import classes from "../AccountSettings.module.scss";
import Button from "../../../components/UI/Button/Button";
import PaymentHistory from "../../Subscriptions/PaymentHistory";
import PaymentInformation from "../../Subscriptions/PaymentInformation";
import { capitalize } from "lodash";
import * as userService from "../../../services/user/userService";
import { connect } from "react-redux";
import {
  getPaymentBillingTypeText,
  isAnnualFromPaymentOrNull
} from "../../../utils/consts";
import moment from "moment";
import NoPaymentInformation from "../../Subscriptions/NoPaymentInformation"
import Modal from "../../../components/UI/Modal/Modal"
import * as actions from "../../../store/actions/indexActions"

function SubscriptionSettings({ user, token, history, snackbarAdd }) {
  if (user.type !== "teacher") {
    history.push("/");
  }
  const [openedModal, setOpenedModal] = useState(false);
  const [updatePayment, setUpdatePayment] = useState(0);

  const [state, setState] = useState({
    data: null,
    error: null,
    status: "idle"
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          status: "pending"
        });
        const { data } = await userService.getMyCurrentSubscription(token);
        console.log(data);
        setState({
          ...state,
          data,
          status: "resolved"
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected"
        });
      }
    };

    getData();
  }, [updatePayment]);
  const { data: payment } = state;

  // canceledByUser: false
  // haveFailedPayment: false
  // last4: "4242"
  // plan: "basic"
  // planPeriod: "basic_plan_yearly"
  // planPrice: 276
  // subscriptionExpiryDate: "2022-02-10T10:55:30+00:00"

  const isFreeUser = payment ? payment.plan === "free" : user.plan === "free";
  const isProUser = payment ? payment.plan === "pro" : user.plan === "pro";
  const isBasicUser = payment ? payment.plan === "basic" : user.plan === "basic";
  const isPaidUser = !isFreeUser;
  const billingText = isPaidUser ? getPaymentBillingTypeText(payment) : null;
  const isAnnual = isAnnualFromPaymentOrNull(payment);


  function closeModal(){
    setOpenedModal(false)
  }

  function openModal(){
    setOpenedModal(true)
  }

  async function onCancelRenewal(){
    try {
     const res = await userService.cancelCurrentSubscription(token);
      console.log(res)
      snackbarAdd({
        message: "You have canceled the subscription",
        timeout: 8000,
        type: "info",
      });
      closeModal();
      setUpdatePayment(updatePayment + 1)
    } catch (onError) {
      console.log(onError)
      snackbarAdd({
        message: onError.message,
        type: "error",
        timeout: 5000
      });
    }
  }

  async function onReactivate(e){
    try {
      e.preventDefault();

      const res = await userService.reactivateCurrentSubscription(token);
      console.log(res)
      snackbarAdd({
        message: "You have reactivated the subscription",
        timeout: 8000,
      });
      setUpdatePayment(updatePayment + 1)

    } catch (onError) {
      console.log(onError)
      snackbarAdd({
        message: onError.message,
        type: "error",
        timeout: 5000
      });
    }
  }

  const plaName = isProUser ? 'Pro' : isBasicUser ? 'Basic': 'Free'
  return (
    <div>
      <div className={classes.CurrentPlan}>
        <h3 className={classes.Subheaders} style={{ marginBottom: "20px" }}>
          Current plan
        </h3>
        <div
          className={classes.SocialInputsWrap}
          style={{ marginBottom: "50px" }}
        >
          <div className={classes.HighlightBox}>
            <div>
              <div className={classes.HighlightTitle}>
                {plaName}
              </div>
              {billingText && <div className={classes.HighlightPeriod}>
                {billingText} 
              </div>}
            </div>
            {!isProUser && (
              <Link to={"/pricing-plans"}>
                <Button className={classes.Button}>Upgrade</Button>
              </Link>
            )}
          </div>
          {isPaidUser && payment && payment.canceledByUser && (<>
            <p
              className={classes.SubscriptionInfo}
              style={{ fontSize: "13px", marginTop: '5px', marginBottom: 0 }}
            >
              {`You have canceled your subscription renewal. You will have access to ${capitalize(payment.plan)} features until ${moment(payment.subscriptionExpiryDate).format("MMM DD, y")}.`}
            </p>
            <Button className={classes.Button} onClick={e => onReactivate(e)} type="link" style={{ position: 'relative', left: '-13px' }}>
              Reactivate
            </Button>
          </>)}
          {isPaidUser && payment && !payment.canceledByUser && (
            <>
              <Modal
                className={classes.ModalConfirm}
                onClose={closeModal}
                open={openedModal}
              >
                <h3 className={classes.ModalSubtitle}>Cancel renewal</h3>
                <div className={classes.ModalBodyText}>
                  {`You can still enjoy all the perks of ${plaName} until your current subscription expire. 
                  After that, you will no longer have access to ${plaName} features and some of your offerings 
                 might be hidden per your new plan (check out `}<a href="/pricing-plans/all">Pricing plans</a>{`).`}
                </div>
                <div className={classes.ConfirmBtnWrap}>
                  <Button className={classes.Button} 
                    size="large" 
                    type="link"
                    loading={false} // Optionally: We can place here true when api call starts and false when api call ends.
                    onClick={onCancelRenewal}
                  >
                    Turn off auto-renewal
                  </Button>
                  <Button className={classes.Button} size="large" onClick={closeModal}>
                    RETURN TO SETTINGS
                  </Button>
                </div>
              </Modal>
              <p
                className={classes.SubscriptionInfo}
                style={{ fontSize: "13px", marginTop: '5px', marginBottom: 0 }}
              >
                {`Your next payment is $${payment.planPrice}, to be charged on ${moment(payment.subscriptionExpiryDate).format("MMM DD, y")}. Your payment will be automatically renewed ${isAnnual ? "yearly" : "monthly"}.`}
              </p>
              <Button className={classes.Button} onClick={openModal} type="link" style={{ position: 'relative', left: '-13px' }}>
                Cancel renewal
              </Button>
            </>
          )}
        </div>
      </div>
      {payment ? <PaymentInformation payment={payment}/> : <NoPaymentInformation />}
      <PaymentHistory />
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  token: state.auth.accessToken
});

const mapDispatchToProps = dispatch => {
  return {
    snackbarAdd: snackConf => dispatch(actions.snackbarAdd(snackConf))
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettings)
);
