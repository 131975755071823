import React, { Component } from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
} from 'react-stripe-elements';

import classes from './StripeForm.module.scss';
import './StripeOverride.scss';
import color from '../../../sass/settings/_settings.colors.scss';
import { isFunction } from 'react-async-script-loader/lib/utils';

const inputStyle = {
	base: {
		fontSize: '15px',
		fontFamily: 'Montserrat, sans-serif',
		color: color.black,
		'::placeholder': {
			color: color.grey
		}
	},
	invalid: {
		color: color.red
	}
};

let formErrorMessages = {
	cardNumber: '',
	cardExpiry: '',
	cardCvc: ''
};

class CheckoutForm extends Component {
	state = {
		cardNumber: false,
		cardExpiry: false,
		cardCvc: false,

		cardNumberError: '',
		cardExpiryError: '',
		cardCvcError: '',

		confirmed: false
	};

	async componentDidUpdate() {
		const { cardNumber, cardExpiry, cardCvc, confirmed } = this.state;
		if (cardNumber && cardExpiry && cardCvc && !confirmed) {
			const cardElement = this.props.elements.getElement('cardNumber');
			let { paymentMethod, error } = await this.props.stripe.createPaymentMethod({
				type: 'card',
				card: cardElement
			});

			if (error) {
				this.props.onError(error);
			} else {
				this.setState({ confirmed: true });
				if (isFunction(this.props.onConfirmed)) {
					this.props.onConfirmed(true)
				}
				if (isFunction(this.props.updateCardElement)) {
					this.props.updateCardElement(cardElement)
				}
				this.props.onFormComplete(paymentMethod);
			}
		}
	}

	handleChange = async (e) => {
		if (this.state.confirmed) {
			this.props.onFormUncomplete();
		} else if (e.error) {
			formErrorMessages[e.elementType] = e.error.message;
    }

    if(e.complete){
			formErrorMessages[e.elementType] = '';
    }
    
		this.setState({
			[e.elementType]: e.complete,
			confirmed: false,
			[e.elementType + 'Error']: ''
		});

		if (isFunction(this.props.onConfirmed)) {
			this.props.onConfirmed(false)
		}
	};

	handleBlur = (e) => {
		this.setState({
			[e.elementType + 'Error']: formErrorMessages[e.elementType]
		});
	};

	render() {
		const { cardNumberError, cardExpiryError, cardCvcError } = this.state;
		return (
			<form className={classes.Form}>
				<label>Card number</label>
				<CardNumberElement
					style={inputStyle}
					placeholder="Enter the card number"
					onChange={this.handleChange}
					onBlur={this.handleBlur}
				/>
				<p>{cardNumberError}</p>
				<label>Expiration date</label>
				<CardExpiryElement
					style={inputStyle}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
				/>
				<p>{cardExpiryError}</p>
				<label>CVV/CVC</label>
				<CardCVCElement
					style={inputStyle}
					placeholder="Enter the CVC number"
					onChange={this.handleChange}
					onBlur={this.handleBlur}
				/>
				<p>{cardCvcError}</p>
			</form>
		);
	}
}

export default injectStripe(CheckoutForm);
