import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';

import Button from '../../components/UI/Button/Button';
import classes from './CookieBar.module.scss';

class CookieBar extends PureComponent {
	state = {
		show: !Boolean(localStorage.getItem('cookiePolicyAnswer'))
	};

	storeCookiePolicy = (accepted) => {
		localStorage.setItem(
			'cookiePolicyAnswer',
			JSON.stringify({
				accepted: accepted,
				timestamp: Date.now()
			})
		);
	};

	clearAllCookies = () => {
		localStorage.clear();
	};

	handleAccept = () => {
		this.setState({ show: false });
		this.storeCookiePolicy(true);
	};

	handleReject = () => {
		this.setState({ show: false });
		this.storeCookiePolicy(false);
	};

	render() {
		const { show } = this.state;
		return (
			<Slide direction="up" in={show} mountOnEnter unmountOnExit>
				<div className={classes.Container}>
					<p>
						This website or its third-party tools use cookies to collect data to give you
						the best experience. <Link to="/privacy-notice">Learn more.</Link>
					</p>

					<div className={classes.ButtonWrap}>
						<Button onClick={this.handleAccept}>Accept</Button>
						<Button onClick={this.handleReject} type="outline">Reject</Button>
					</div>
				</div>
			</Slide>
		);
	}
}


export default CookieBar;
