import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/indexActions';
import CircularProgress from '@material-ui/core/CircularProgress'

import classes from './TwilioVideo.module.scss';
import ReactSVG from 'react-svg';
import Avatar from '../../components/UI/Avatar/Avatar';
import Modal from '../../components/UI/Modal/Modal';
import IDPopover from  '../../components/UI/Popover/Popover';

//Twilio
import Video from 'twilio-video';
import { createLocalVideoTrack, createLocalTracks, isSupported , createLocalAudioTrack } from 'twilio-video';
import * as callService from '../../services/liveCall/liveCallService';

//Icons
import CloseIcon from './../../assets/images/icons/menu close icon.svg';
import ScreenShare from './../../assets/images/icons/screenshare-icon.svg';
import StopScreenShare from './../../assets/images/icons/stop-screenshare.svg';
import Microphone from './../../assets/images/icons/microphone-solid.svg';
import FullScreen from './../../assets/images/icons/fullscreen.svg';
import ExitFullScreen from './../../assets/images/icons/exit-full-screen.svg';
import IconVideo from './../../assets/images/icons/video-solid.svg';
import IconVideoSlash from './../../assets/images/icons/video-slash-solid.svg';
import IconMicrophoneSlash from './../../assets/images/icons/microphone-slash-solid.svg';

//Modal VideoConecting icons
import LeftShape from './../../assets/images/graphics/left_shape.svg';
import RightShape from './../../assets/images/graphics/right_shape.svg';
import LeftMiddleShape from './../../assets/images/graphics/left_middle_shape.svg';
import RightMiddleShape from './../../assets/images/graphics/right_middle_shape.svg';

//Sound url
import SoundUrl from './../../assets/sounds/sound.mp3'

import { ChosenDisplayName } from '../UI/ChosenDisplayName/ChosenDisplayName';

let totalSeconds = 0;
let tracks;
let screenTrack;

class TwilioVideo extends React.PureComponent {
	state = {
		loading: false,
		twilioVideo: true,
		videoCameraControl: true,
		microphoneControl: true,
		shareScreenControl: false,
		shareScreenRemote: false,
		fullScreenVideo: true,
		videoSeted: false,
		videoTrackRemote: false,
		audioTrackRemote: false,
		callFrommQueue: false,
		modalConecting: false,
		remoteUserDisconected: false,
		trackSubscribedEventAdded: false,
		trackDisabledEventAdded: false,
		trackEnabledEventAdded: false,
		videoNav: false,
		queuedStateFinished: false,
		videoRoom: '',
		audioTrack: '',
		videoTrack: '',
		localVideoInput: undefined,
		localAudioInput: undefined,
		localAudioInputUpdated: undefined,
		localVideoInputUpdated: undefined,
		reconnecting: false,
		labelDevice: null,
	};

	countTimer = () => {
		++totalSeconds;
		let hour = Math.floor(totalSeconds /3600);
		let minute = Math.floor((totalSeconds - hour*3600)/60);
		let seconds = totalSeconds - (hour*3600 + minute*60);
		// if(hour < 10)
		// 	hour = "0"+hour;
		if(minute < 10) {
			minute = "0"+minute;
		}
		if(seconds < 10) {
			seconds = "0"+seconds;
		}

		document.getElementById('timer').innerHTML = minute + ":" + seconds;
	}

	async componentDidMount() {
		// console.log('did mount');
		// console.log(this.props);
		if(this.props.callFrommQueue == true) {
			this.setState({
				callFrommQueue: true,
				modalConecting: true
			})
		}

		this.setState({
			loading: true,
		});

		let avavlableInput = await navigator.mediaDevices.enumerateDevices().then(devices => {
			
			this.setState({
				localVideoInput : devices.find(device => device.kind === 'videoinput'),
				localAudioInput : devices.find(device => device.kind === 'audioinput'),
				labelDevice: devices.find(device => device.label),
			}, () => {
				
			})
		})
		
		if(this.state.localAudioInput !== undefined && this.state.localVideoInput == undefined) {
			tracks =  await createLocalTracks({
				audio: true,
				video: false
			});

			this.setState({
				videoCameraControl: false,
				loading: false
			});
		} else if (this.state.localAudioInput !== undefined && this.state.localVideoInput !== undefined) {
			tracks = await createLocalTracks({
				audio: true,
				video: {name:'localVideo'}
			}).catch((error) => {
				console.log('error media', error);

				this.setState({
					labelDevice: false,
				})
				this.props.closeTwilioVideo();
		
				this.props.snackbarAdd({
					message: "We’re sorry, but the call could not be completed as the website wasn't allowed to use your camera and microphone.",
					timeout: 8000,
					type: 'info',
				});
			});
		} else if (this.state.localAudioInput == undefined && this.state.localVideoInput !== undefined) {
			tracks = await createLocalTracks({
				audio: false,
				video: {name:'localVideo'}
			});

			this.setState({
				microphoneControl: false,
				loading: false
			});
		} else if (this.state.localAudioInput == undefined && this.state.localVideoInput == undefined) {
			//Message and quit the call
			//alert('There is no video or audio input, connect your devices and then try again.')
		}

		if (isSupported) {
			let videoRoom =  await Video.connect(this.props.roomToken,
				{   tracks,
					name: this.props.roomName,
					//networkQuality: true
					preferredAudioCodecs: ['opus'],
  					preferredVideoCodecs: ['H264']
			}).then(room => {
					this.setState({
						videoRoom : room
					});

					// room.localParticipant.setNetworkQualityConfiguration({
					// 	local: 2,
					// 	remote: 1
					// });

					const localMediaContainer = document.getElementById('localVideo');
				
					this.setState({
						audioTrack: tracks.find(track => track.kind === 'audio'),
						videoTrack: tracks.find(track => track.name === 'localVideo'),
					},() => {
						//console.log('test for ios',tracks)
					});
		
					// //Attach audio track
					if (this.state.localAudioInput !== undefined) {
						document.body.appendChild(this.state.audioTrack.attach());
						//console.log('audio track')

						let audioElement = this.state.audioTrack.attach();
						const platform = navigator.platform.toLowerCase(),
						iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];

						//console.log('localaudio',this.state.audioTrack)

						if (platform.includes(iosPlatforms) || /iphone|ipod|ipad/i.test(navigator.userAgent)) { //is safari iPhone
							audioElement.pause();
							audioElement.play();
						}
		
						this.setState({
							videoSeted: true
						});
					}
		
					if(this.state.callFrommQueue == false) {
							//Attach and publish video track
							if (this.state.localVideoInput !== undefined) {
								localMediaContainer.appendChild(this.state.videoTrack.attach());
								this.state.videoTrack.enable();
								this.state.videoRoom.localParticipant.publishTrack(this.state.videoTrack);
								//console.log('localVideo',this.state.videoTrack)
								this.setState({
									videoSeted: true
								});
							}
					}
				}, error => {
					if(this.state.labelDevice == false) {

					} else {
						tracks[0].stop();
						tracks[1].stop();
						this.props.closeTwilioVideo();
						this.props.snackbarAdd({
							message: "We're sorry, but the call could not be completed, it was either canceled by participant or failed.",
							timeout: 8000,
							type: 'info'
						});
					}
				})
		  } else {
			console.error('This browser is not supported by twilio-video.js.');
		}
	}

	componentDidUpdate(prevProps, prevState) {
			const roomDisconect = () => {
				clearInterval(this.intervalCheck);

				totalSeconds = 0;

				this.state.videoRoom.localParticipant.tracks.forEach(publication => {
					publication.track.stop();
					publication.unpublish();
				});

				this.state.videoRoom.localParticipant.tracks.forEach(publication => {
					publication.track.stop();
					publication.unpublish();
				});

				this.state.videoRoom.localParticipant.audioTracks.forEach(publication => {
					publication.track.disable();
				});

				this.state.videoRoom.disconnect();

				this.props.closeTwilioVideo();
			}

			let authorOfCall = this.props.author === this.props.loggedUser.fullName;

			if(prevState.videoSeted !== this.state.videoSeted) {
				
				setTimeout(() => {
					if(this.state.videoTrackRemote == false && this.state.audioTrackRemote == false) {
						//console.log('participient isnt connected');
						roomDisconect();
						this.props.snackbarAdd({
							message: "We're sorry, but the call could not be completed, student isn't available.",
							timeout: 8000,
							type: 'info'
						});
					}
				}, 55000);
			}

			// console.log(authorOfCall ,this.props.author , this.props.loggedUser.fullName)

			if (isSupported) {
				if(prevState.videoCameraControl !== this.state.videoCameraControl 
					|| prevState.videoTrackRemote !== this.state.videoTrackRemote 
					|| prevState.videoSeted !== this.state.videoSeted
					|| prevState.callFrommQueue !== this.state.callFrommQueue
					|| prevState.videoTrack !== this.state.videoTrack
					|| prevState.audioTrack !== this.state.audioTrack
					) {

						
						//console.log('state',this.state.videoRoom)
						//console.log('did update')
					
					if(this.state.videoSeted == true) {
						
						this.state.videoRoom.participants.forEach(remoteParticipant => {
						
						//Remote track subscribed
						if (!this.state.trackSubscribedEventAdded) {
							//Remove all saved events before
							remoteParticipant.removeAllListeners(['trackSubscribed']);

							remoteParticipant.on('trackSubscribed', track => {
								this.setState({
									trackSubscribedEventAdded: true
								}, () => {
									if(track.name == 'localVideo'){
										//console.log('track', track)
										this.setState({
											videoTrackRemote: true,
											loading: false,
											videoNav: true,
										}, () => {	
											document.getElementById('remoteVideo').appendChild(track.attach());
										});
									}
			
									if(track.kind == 'audio') {
										this.setState({
											audioTrackRemote: true,
											videoNav: true,
										}, () => {	
											let audioElement = track.attach();
											const platform = navigator.platform.toLowerCase(),
											iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];
											document.body.appendChild(track.attach());

											if (platform.includes(iosPlatforms) || /iphone|ipod|ipad/i.test(navigator.userAgent)) { //is safari iPhone
												audioElement.pause();
												audioElement.play();
											}
											//Start timer 
											this.intervalCheck = setInterval(this.countTimer, 1000);
										});
										
									}

									if(track.name == 'screen') {
										this.setState({
											shareScreenRemote: true,
										}, () => {
											const shareScreenContainer = document.getElementById('shareScreen');
											shareScreenContainer.appendChild(track.attach());
										});
									}
								})
							});
						}

						//Remote track disabled
						if (!this.state.trackDisabledEventAdded) {
							//Remove all saved events before
							remoteParticipant.removeAllListeners(['trackDisabled']);

							//On remoteParticipient track disable
							remoteParticipant.on('trackDisabled', track => {
								//console.log('trackDisabled');

								this.setState({
									trackDisabledEventAdded: true
								}, () => {
									if(track.trackName == 'screen') {
										this.setState({
											shareScreenRemote: false,
										});
									}
			
									if(track.trackName == 'localVideo') {
										this.setState({
											videoTrackRemote: false,
										});
									}
								})
							});
						}

						//Remote track enabled
						if (!this.state.trackEnabledEventAdded){
							//Remove all saved events before
							remoteParticipant.removeAllListeners(['trackEnabled']);

							//On remoteParticipient track enable
							remoteParticipant.on('trackEnabled', track => {
								//console.log('trackEnabled');

								this.setState({
									trackEnabledEventAdded: true
								}, () => {
									if(track.trackName == 'localVideo') {
										this.setState({
											videoTrackRemote: true,
										}, () => {
											document.getElementById('remoteVideo').appendChild(track.track.attach());
										});
									}
								})
							});
						}
					}
				);

				this.state.videoRoom.on('participantConnected', participant =>  {

					//console.log('participantConnected')
					if(this.state.callFrommQueue == true) {
						this.setState({
							callFrommQueue: false,
							modalConecting: false,
							queuedStateFinished: true,
						},() => {
							const localMediaContainer = document.getElementById('localVideo');
							//Attach and publish video track
							localMediaContainer.appendChild(this.state.videoTrack.attach());
							this.state.videoTrack.enable();
							this.state.videoRoom.localParticipant.publishTrack(this.state.videoTrack);
						})
					};

					participant.tracks.forEach(publication => {

						if(publication.trackName == 'localVideo'){
							this.setState({
								videoTrackRemote: true,
								loading: false,
								videoNav: true,
							});
						}

						//Case when one of remote participient inputs doesnt integrated
						participant.on('trackSubscribed',(track) => {
							
							// if(track.trackName == 'localVideo') {
							// 	document.getElementById('remoteVideo').appendChild(track.attach());
							// 	//Start timer 
							// 	//this.intervalCheck = setInterval(this.countTimer, 1000);
							// }
							
							if(track.kind == 'audio' && this.state.audioTrackRemote == false) {
								document.body.appendChild(track.attach());
								//Start timer 
								this.intervalCheck = setInterval(this.countTimer, 1000);
							}

							if(track.name == 'screen' && this.state.shareScreenRemote == false) {
								this.setState({
									shareScreenRemote: true,
								}, () => {
									const shareScreenContainer = document.getElementById('shareScreen');
									shareScreenContainer.appendChild(track.attach());
								});
							}
						});

						//Remote track disabled
						if (!this.state.trackDisabledEventAdded) {
							//Remove all saved events before
							participant.removeAllListeners(['trackDisabled']);

							//On remoteParticipient track disable
							participant.on('trackDisabled', track => {
								//console.log('trackDisabled');

								this.setState({
									trackDisabledEventAdded: true
								}, () => {
									if(track.trackName == 'screen') {
										this.setState({
											shareScreenRemote: false,
										});
									}
			
									if(track.trackName == 'localVideo') {
										this.setState({
											videoTrackRemote: false,
										});
									}
								})
							});
						}
					});
				});

				

				//Check conections in the room
				this.state.videoRoom.on('participantDisconnected', participant => {
					//console.log('participantDisconnected')

						this.setState({
							remoteUserDisconected: true,
							modalConecting: false,
						},() => {
							roomDisconect();

							this.props.snackbarAdd({
								message: authorOfCall == true ? <p className={classes.ParagraphMessage}>You can leave a review in <a href="/account/calls/video">your calls</a> list.</p> : <p className={classes.ParagraphMessage}>Call ended</p>,
								timeout: 8000,
								type: 'info',
							});
						});
					});
				}
			}

			//console.log(this.state.videoRoom.localParticipant)

			if(this.state.videoRoom.state == 'reconnecting') {
				this.setState({
					reconnecting: true,
				}, () => {
					this.props.snackbarAdd({
						message: "Network connection has been broken.",
						timeout: 8000,
						type: 'info'
					});

					console.log('reconnecting state of room');
					this.leaveRoom();
					roomDisconect();
					this.state.videoRoom.disconnect();
					
				})
			}

			// console.log('room status', this.state.videoRoom.state);
			if(this.state.videoRoom.state == 'disconnected') {
				roomDisconect();
				this.props.snackbarAdd({
					message: "We're sorry, but the call could not be completed, it was either canceled by participant or failed.",
					timeout: 8000,
					type: 'info'
				});

				console.log('room disconected');
				// if(this.state.modalConecting == true) {
				// 	this.props.snackbarAdd({
				// 		message: "We're sorry, but the call could not be completed, it was either canceled by participient or failed.",
				// 		timeout: 8000,
				// 		type: 'info'
				// 	});
				// }
			}

			//when the tab/browser is being closed.
			window.addEventListener('beforeunload', () => this.state.videoRoom.disconnect());

			// iOS Safari does not emit the "beforeunload" event on window.
			// Use "pagehide" instead.
			window.addEventListener('pagehide', () => this.state.videoRoom.disconnect());

			

		} //if suported
	 }

	microphoneControl = () => {
		if(this.state.microphoneControl == false) {
			this.setState({
				microphoneControl: true,
			});

			this.state.audioTrack.enable();
		} else {
			this.setState({
				microphoneControl: false,
			});
			this.state.audioTrack.disable();
		}
	}

	videoCameraControl = () => {
		if(this.state.videoCameraControl == true) {
			this.setState({
				videoCameraControl: false,
			});

			this.state.videoTrack.disable();

			//disable and detach video track
			this.state.videoRoom.localParticipant.tracks.forEach(publication => {
				if(publication.track.name == 'localVideo') {
					const attachedElements = publication.track.detach();
					attachedElements.forEach(element => element.remove());
				}
			});

			//videoRoom.localParticipant.unpublishTrack(videoTrack);
		} else {
			this.setState({
				videoCameraControl: true,
			});

			//enable and detach video track
			setTimeout(() => {
				const localMediaContainer = document.getElementById('localVideo');
				localMediaContainer.appendChild(this.state.videoTrack.attach());
				this.state.videoTrack.enable();
				//videoRoom.localParticipant.publishTrack(videoTrack);
				
			}, 100);
		}
	}

	isFirefox = () => {
		const mediaSourceSupport = !!navigator.mediaDevices.getSupportedConstraints().mediaSource;
		const matchData = navigator.userAgent.match('Firefox/(d )');

		let firefoxVersion = 83;
		// if (matchData && matchData[1]) {
		// 	firefoxVersion = parseInt(matchData[1], 10);
		// }
		return mediaSourceSupport && firefoxVersion >= 52;
	}

	isChrome = () => {
		return 'chrome' in window;
	}

	canScreenShare = () => {
		return this.isFirefox() || this.isChrome();
	}

	getUserScreen = () => {
		var extensionId = 'YOUR_EXTENSION_ID';
		if (!this.canScreenShare()) {
			return;
		}
		if (this.isChrome()) {
			// return new Promise((resolve, reject) => {
			// 	const request = {
			// 		sources: ['screen']
			// 	};
			// 	chrome.runtime.sendMessage(extensionId, request, response => {
			// 		if (response && response.type === 'success') {
			// 			resolve({ streamId: response.streamId });
			// 		} else {
			// 			reject(new Error('Could not get stream'));
			// 		}
			// 	});
			// }).then(response => {
			// 	return navigator.mediaDevices.getUserMedia({
			// 		video: {
			// 			mandatory: {
			// 				chromeMediaSource: 'desktop',
			// 				chromeMediaSourceId: response.streamId
			// 			}
			// 	});			// 		}
			// });
		} else if (this.isFirefox()) {
			return navigator.mediaDevices.getUserMedia({
				video: {
					mediaSource: 'window'
				}
			});
		}
	}


	shareScreenVideoNew = () => {
		if(!this.state.shareScreenControll) {
			this.setState({
				shareScreenControl: true
			});

			this.getUserScreen().then(function (stream) {
				let shareScreenContainer = document.getElementById('shareScreen');
				// screenTrack = stream.getVideoTracks()[0];
				screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0],{ name: 'screen' });
				// screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
				this.state.videoRoom.localParticipant.publishTrack(screenTrack);
				shareScreenContainer.appendChild(screenTrack.attach());
			});
		}
		else{
			this.setState({
				shareScreenControl: false
			});

			this.getUserScreen().then(function(stream) {
				this.state.videoRoom.localParticipant.unpublishTrack(screenTrack);
				screenTrack = null;
			});
		}
	};

	shareScreenVideo = () => {
		if(this.state.shareScreenControl == true) {
			this.setState({
				shareScreenControl: false,
			});

			this.state.videoRoom.localParticipant.tracks.forEach(publication => {
				if(publication.track.name == 'screen') {
					publication.track.disable();

					setTimeout(() => {
						this.state.videoRoom.localParticipant.unpublishTrack(publication.track);
						screenTrack.stop();
						screenTrack = null;
					}, 100)
				}
			});
		} else {
			this.setState({
				shareScreenControl: true,
			});

			navigator.mediaDevices.getDisplayMedia({ mediaSource: 'window' }).then(stream => {
				const shareScreenContainer = document.getElementById('shareScreen');
				screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0],{ name: 'screen' });
				//shareScreenContainer.appendChild(screenTrack.attach());
				this.state.videoRoom.localParticipant.publishTrack(screenTrack);
				screenTrack.mediaStreamTrack.onended = () => { this.shareScreenVideo() };
			}).catch(() => {
				alert('Could not share the screen.');
				this.setState({
					shareScreenControl: false,
				});
			});
		}
	}

	fullScreenVideo = () => {

		if(this.state.fullScreenVideo == true) {
			this.setState({
				fullScreenVideo: false,
			})
		} else {
			this.setState({
				fullScreenVideo: true,
			});
		}
	}

	leaveRoom = () => {
		let authorOfCall = this.props.author === this.props.loggedUser.fullName;

		this.state.videoRoom.localParticipant.videoTracks.forEach(publication => {
			publication.track.stop();
			publication.unpublish();
		});

		this.state.videoRoom.localParticipant.tracks.forEach(publication => {
			publication.track.stop();
			publication.unpublish();
		});

		this.state.videoRoom.localParticipant.audioTracks.forEach(publication => {
			publication.track.disable();
			clearInterval(this.intervalCheck);
		});

		this.state.videoRoom.disconnect();
		clearInterval(this.intervalCheck);
		this.setState({
			loading: true
		})

		this.props.closeTwilioVideo();
		totalSeconds = 0;

		this.props.snackbarAdd({
			message: authorOfCall == true ? <p className={classes.ParagraphMessage}>You can leave a review in <a href="/account/calls/video">your calls</a> list.</p> : <p className={classes.ParagraphMessage}>Call ended</p>,
			timeout: 8000,
			type: 'info',
		});
	}

	render() {
		 //Loading animation
		 const loaderRender = (
			<div
			  style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				// marginTop: '1rem'
				height: '100%',
			  }}
			>
			  <CircularProgress />
			</div>
		)
		
		const logedUserHasImage = this.props.loggedUser && this.props.loggedUser.profile.photo;
		//const logedUserImage =  this.props.loggedUser && this.props.loggedUser.profile.photo.big;

		return (
			this.state.callFrommQueue == false ?
			<div className={classes.TwilioVideoWrapper}>
				<div className={ this.state.fullScreenVideo == false ?  classes.TwilioVideoCompressed :  classes.TwilioVideoFull}>
					<div className={classes.VideoInfo}>
						<h3 className={classes.ClassName}>{this.props.videoName}</h3>
						<div className={classes.FlexWrapp}>
							<h4 className={classes.PartipicientName}>{this.props.incomingCallUser},</h4>
							<h4 id="timer" className={classes.Timer}></h4>
							{/* {this.state.remoteUserDisconected == true ? <h4 className={classes.CallEnded}>Call ended!</h4> : ''} */}
						</div>
					</div> 
					{this.state.videoTrackRemote == true ? 
						<div id="remoteVideo" 
							className={
								this.state.shareScreenControl == true || this.state.shareScreenRemote == true ? classes.RemoteVideoRightPos : classes.RemoteVideo }>
							{/* Remote video display */}
							<div className={this.state.shareScreenRemote == true ? classes.ShareSideIconRemote : classes.Hidden}>
								<ReactSVG  className={classes.NavIcon} src={ScreenShare} />
							</div>
						</div>
						:
						this.state.loading !== true ?
						<Avatar
							src={
								this.props.photoUrl ? this.props.photoUrl : null
							}
							className={this.state.shareScreenControl == true || this.state.shareScreenRemote == true ? classes.ImageRemoteUserRightPos  : classes.ImageRemoteUser}
						/> : loaderRender
					}
					{this.state.videoCameraControl == true ? 
						<div id="localVideo" className={classes.LocalVideo}>
							{/* Local video display */}
							<div className={this.state.shareScreenControl == true ? classes.ShareSideIconLocal : classes.Hidden}>
								<ReactSVG  className={classes.NavIcon} src={ScreenShare} />
							</div>
						</div>
						: 
						<Avatar
						src={
							this.props.loggedUser.profile.photo ? this.props.loggedUser.profile.photo.big : null
						}
						className={classes.ImageLocalUser}
						/>
					}
					{
						this.state.shareScreenRemote == true ?
						<div id="shareScreen" className={classes.ShareScreen}>
							{/* Local video display */}
						</div> : ''
					}

					{
						this.state.shareScreenControl == true ?
						<div id="shareScreenLocalView" className={`${this.state.shareScreenControl === true ? '' : classes.Hidden}`}>
							{/* Local video display */}
							<Avatar
							src={
								this.props.loggedUser.profile.photo ? this.props.loggedUser.profile.photo.big : null
							}
							className={classes.ImageLocalUserShareScreen}
							/>
						</div> : ''
					}
					<div>
						<div className={classes.TwilioVideoNav}>
							{/* {this.state.shareScreenControl == true ? <h4 className={classes.ShareScreenMessage}>You are sharing your screen.</h4> : '' } */}
							<ul>
								<li>
									<div className={classes.Tooltip}>
										<span className={classes.TooltipText}>{this.state.fullScreenVideo == false ? 'Enter full screen' : 'Exit full screen'}</span>
										<a onClick={this.fullScreenVideo} className={this.state.fullScreenVideo == false ? classes.Info : [classes.Info, classes.InfoActive].join(' ')}>
											<ReactSVG  className={classes.NavIcon} src={FullScreen}  src={this.state.fullScreenVideo == false ? FullScreen : ExitFullScreen }/>
										</a>
									</div>
								</li>
								<li className={this.state.videoNav !== true ? classes.Disabled : ''}>
									<div className={classes.Tooltip}>
										<span className={classes.TooltipText}>{this.state.shareScreenControl == false ? 'Share screen' : 'Stop sharing screen'}</span>
										<a onClick={this.shareScreenVideo} className={this.state.shareScreenControl == false ? [classes.Info, classes.InfoActive].join(' ') : this.state.shareScreenRemote == true ? [classes.Info, classes.Disabled].join(' ') : classes.Info}>
											<ReactSVG  className={classes.NavIconScreenShare} src={this.state.shareScreenControl == false ? ScreenShare : StopScreenShare} />
										</a>
									</div>
								</li>
								<li className={this.state.videoNav !== true ? classes.Disabled : ''}>
									<div className={classes.Tooltip}>
										<span className={classes.TooltipText}>{this.state.microphoneControl == false ? 'Unmute' : 'Mute'}</span>
										<a  onClick={this.microphoneControl} className={this.state.localAudioInput == undefined ? [classes.Info, classes.Disabled].join(' ') : this.state.microphoneControl == true ? classes.Info : [classes.Info, classes.InfoActive].join(' ')}>
											<ReactSVG  className={classes.NavIconMute} src={this.state.microphoneControl == false ? IconMicrophoneSlash : Microphone} />
										</a>
									</div>
								</li>
								<li className={this.state.videoNav !== true ? classes.Disabled : ''}>
									<div className={classes.Tooltip}>
										<span className={classes.TooltipText}>{this.state.videoCameraControl == false ? 'Camera on' : 'Camera off'}</span>
										<a  onClick={this.videoCameraControl} className={this.state.localVideoInput == undefined ? [classes.Info, classes.Disabled].join(' ') : this.state.videoCameraControl == true ? classes.Info : [classes.Info, classes.InfoActive].join(' ')}>
											<ReactSVG  className={classes.NavIcon} src={this.state.videoCameraControl == true ? IconVideo : IconVideoSlash} />
										</a>
									</div>
								</li>
								<li className={this.state.videoSeted !== true ? classes.Disabled : ''}>
									<div className={classes.Tooltip}>
										<span className={classes.TooltipText}>Stop the call</span>
										<a onClick={this.leaveRoom} className={classes.Warning}>
											<ReactSVG  className={classes.NavIcon} src={CloseIcon} />
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			:
			<Modal
				className={[classes.Card, classes.ModalVideoCallConecting].join('')}
				open={this.state.modalConecting}
				//onClose={() => this.props.onClose()}
				>
				<div className={classes.LeftShape}>
					<img src={LeftShape} alt="ShapeLeft" />
				</div>
				<div className={classes.RightShape}>
					<img src={RightShape} alt="ShapeLeft" />
				</div>
				<div className={classes.LeftMiddleShape}>
					<img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
				</div>
				<div className={classes.RightMiddleShape} id="right">
					<img src={RightMiddleShape} alt="ShapeRightMiddle" />
				</div>
				<Avatar
					src ={
						this.props.photoUrl
					}
					className={classes.Avatar}
					size="140"
				/>
				<h3 className={classes.VideoCallNameConecting}>{this.props.videoName}</h3>
				{/* <h4 className={classes.AuthorName}>{this.props.participient}</h4> */}
				<h4 className={classes.AuthorName}>{this.props.displayNameForCaller}</h4>
				<span className={classes.GreySpan}>Connecting...</span>
				<audio id="ringSoundPlayer" autoPlay loop><source src={SoundUrl}/></audio>
				<div className={classes.ButtonCancelWrap}>
					<a onClick={this.leaveRoom} className={classes.Warning}>
						<ReactSVG  className={classes.NavIcon} src={CloseIcon} />
					</a>
					<span>Cancel</span>
              </div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
	  loggedUser: state.user,
	  token: state.auth.accessToken,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioVideo);
