import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from '../../../components/UI/Avatar/Avatar';
import ReactSVG from 'react-svg';
import windowWidth from 'react-window-size';

import AsidePortal from '../../../components/Layout/Aside/AsidePortal';
import classes from './Dashboard.module.scss';
import CustomSelect from '../../../components/UI/Select/Select';
import VideosListing from './Listings/VideosListing';
import LiveCallsListing from './Listings/LiveCallsListing';
import CallsOverviewListing from './Listings/CallsOverviewListing';
import QueuedCallsListing from './Listings/QueuedCallsListing';
import IconVideo from '../../../../src/assets/images/icons/video class icon.svg';
import IconLiveCalls from '../../../../src/assets/images/icons/live_call_icon.svg';
import IconDashboard from '../../../../src/assets/images/icons/dashboard_icon.svg';
import IconList from '../../../../src/assets/images/icons/list_icon.svg';
import IconHourGlass from '../../../../src/assets/images/icons/hour_glass_icon.svg';
import FollowersListing from './Listings/FollowersListing';
import IconFollower from '../../../assets/images/icons/follower_icon.svg';
import IconCoupon from '../../../assets/images/icons/local_activity_icon.svg';
import Button from '../../../components/UI/Button/Button';
import CouponCode from './Listings/CouponCode';
import {
  ChosenDisplayName,
  shouldDisplayUsernameSection,
} from '../../../components/UI/ChosenDisplayName/ChosenDisplayName';

const dashboardOptions = () => {
  return {
    videos: {
      id: 1,
      icon: IconVideo,
      label: 'My Videos',
      url: '/account/dashboard/videos',
      value: 'videos',
    },
    calls: {
      id: 2,
      icon: IconLiveCalls,
      label: 'My Live Tutoring',
      url: '/account/dashboard/calls',
      value: 'calls',
    },
    'calls-overview': {
      id: 3,
      icon: IconList,
      label: 'Calls Overview',
      url: '/account/dashboard/calls-overview/video',
      value: 'calls-overview/video',
    },
    'queued-calls': {
      id: 4,
      icon: IconHourGlass,
      label: 'Queued Calls',
      url: '/account/dashboard/queued-calls/video',
      value: 'queued-calls/video',
    },
    followers: {
      id: 5,
      icon: IconFollower,
      label: 'Followers',
      url: '/account/dashboard/followers',
      value: 'followers',
    },
    'coupon-code': {
      id: 6,
      icon: IconCoupon,
      label: 'Coupon Code',
      url: '/account/dashboard/coupon-code',
      value: 'coupon-code',
    },
  };
};

const DashboardContent = (option) => {
  switch (option) {
    case 'videos':
      return <VideosListing />;
    case 'calls':
      return <LiveCallsListing />;
    case 'calls-overview':
      return <CallsOverviewListing />;
    case 'calls-overview/video':
      return <CallsOverviewListing />;
    case 'queued-calls':
      return <QueuedCallsListing />;
    case 'queued-calls/video':
      return <QueuedCallsListing />;
    case 'followers':
      return <FollowersListing />;
    case 'coupon-code':
      return <CouponCode />;
    default:
      return null;
  }
};

class Dashboard extends React.PureComponent {
  // used an iife function just to have a shorthand value for repetative usage of this.props.user
  state = {
    activeOption: 'videos',
  };

  componentDidMount() {
    this.setActiveTab();
  }

  setActiveTab = () => {
    const urlParam = this.props.match.params.type;
    // check if it is a valid type comming from the url if it isn't route to first option
    const optionsNameList = Object.keys(dashboardOptions());

    if (optionsNameList.includes(urlParam)) {
      this.setState({ activeOption: urlParam });
    } else {
      const url = '/account/dashboard/' + optionsNameList[0];
      this.props.history.replace(url);
    }
  };

  onNavOptionClick = (name) => () => {
    this.setState({ activeOption: name });
  };

  handleOptionChange = (event, value) => {
    this.setState({ activeOption: event.target.value });
    // this.props.history.push('/account/dashboard/' + value);
    this.props.history.push('/account/dashboard/' + event.target.value);
    console.log('Option change', value);
  };

  render() {
    const screenWidth = this.props.windowWidth;

    const activeOption = this.state.activeOption;

    const isActiveTab = (name) =>
      activeOption === name ? classes.ActiveTab : '';

    const typeNames = Object.keys(dashboardOptions());
    const typeConf = dashboardOptions();

    const config = typeNames.map((name) => typeConf[name]);

    const renderAsideNav = () => {
      // const typeNames = Object.keys(dashboardOptions());
      // const typeConf = dashboardOptions();

      return (
        <React.Fragment>
          <div className={[classes.ListItemGroup].join(' ')}>
            <ReactSVG svgClassName={classes.NavIcon} src={IconDashboard} />
            <span>Teacher Dashboard</span>
          </div>
          {typeNames.map((name, index) => (
            <Link
              key={name}
              to={typeConf[name].url}
              className={[classes.ListItem, isActiveTab(name)].join(' ')}
              onClick={this.onNavOptionClick(name)}
            >
              <ReactSVG
                svgClassName={classes.NavIcon}
                src={typeConf[name].icon}
              />
              <span>{typeConf[name].label}</span>
            </Link>
          ))}
        </React.Fragment>
      );
    };

    return (
      <div className={classes.Container}>
        {/* Aside Nav */}
        {screenWidth > 768 ? (
          <React.Fragment>
            <AsidePortal className={classes.Aside}>
              {/* picture uploader*/}
              <Avatar
                src={
                  this.props.userData.profile.photo &&
                  this.props.userData.profile.photo.big
                }
                className={classes.Avatar}
                size="100"
                style={{ marginBottom: '16px' }}
              />

              {/* name */}
              {/* <h4 className={classes.Name} style={{ marginBottom: "4px" }}>
                {this.props.userData.profile.name}
              </h4>
              <span style={{ marginBottom: "20px" }}>{this.props.userData.username}</span> */}
              <h4
                className={classes.Name}
                style={{
                  marginBottom:
                  shouldDisplayUsernameSection(this.props.userData)
                      ? '4px'
                      : '20px',
                }}
              >
                <ChosenDisplayName user={this.props.userData} top="-2px" />
              </h4>
              {shouldDisplayUsernameSection(this.props.userData) && (
                <span style={{ marginBottom: '20px' }}>
                  {this.props.userData.username}
                </span>
              )}

              <Link to={`/profile/${this.props.userData.profileUrl}`}>
                <Button size="small">View your public profile</Button>
              </Link>
              <div className={classes.AsideNavList}>{renderAsideNav()}</div>
            </AsidePortal>
            {/* Main Container  */}
            <article className={classes.Main}>
              <h1>Teacher Dashboard</h1>
              <div className={classes.Container}>
                {DashboardContent(activeOption)}
              </div>
            </article>
          </React.Fragment>
        ) : (
          <div>
            <article className={classes.Main}>
              <h1>Teacher Dashboard</h1>
              <div className={classes.Select}>
                <CustomSelect
                  activetab={activeOption === 'calls-overview' || activeOption === 'queued-calls' ? activeOption + '/video' : activeOption}
                  config={config}
                  onChangeOption={(e, val) => this.handleOptionChange(e, val)}
                />
              </div>

              <div className={classes.Container}>
                {DashboardContent(activeOption)}
              </div>
            </article>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(windowWidth(Dashboard)));
