import React, { Component } from "react";
import { connect } from "react-redux";
import * as videoService from "../../services/video/videoService";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "../MyAccount/ClassesList/MyVideos/MyVideos.module.scss";
import classesSorter from "../../components/UI/Sorter/Sorter.module.scss";
import GridList from "../../components/UI/GridList/GridList";
import ItemCard from "../../components/ItemCard/ItemCard";
import videoIcon from "../../assets/images/icons/video class icon.svg";
import Pagination from "../../components/UI/Pagination/Pagination";
import { getUserProfile } from "../../services/user/userService";
import { Link } from "react-router-dom";
import Button from "../../components/UI/Button/Button";
import { Input } from "../../components/UI/Form/Form";
import clone from "lodash.clonedeep";
import { withRouter } from "react-router-dom";
import ReactSVG from "react-svg";
import IconSearch from "../../assets/images/icons/search_icon_blue.svg";
import PromptToUpgradeSubscription from '../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';
import { PLAN_ID_FREE } from '../../utils/consts';

let paginationConf = {
  pageLimit: 20,
  total: null
};

const optionSort = [
  {
    name: "Latest",
    value: "publishedAt",
  },
  {
    name: "Most reviewed",
    value: "numberOfRating",
  },
  {
    name: "Highest rated",
    value: "averageRating",
  },
  {
    name: "Most sold",
    value: "purchasesCount",
  },
];

class ProfileVideos extends Component {
  state = {
    loading: true,
    profileWatching: null,
    videos: [],
    hideSearch: true,
    searchControl: {
        id: "search-input",
        type: "text",
        placeholderStatic: "Search",
        adornment: {
          position: "start",
          icon: () => (
            <ReactSVG src={IconSearch} svgClassName={classes.IconSearchInput} />
          ),
        },
        value: "",
        validation: {},
        sort: null
      },
    sorterInitalValue: 'publishedAt',
    currentPaginationPage: null
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.init();
    }
  }

  init() {
    const profileUrl = this.props.match.params.slug;
    if (profileUrl) {
      getUserProfile(this.props.match.params.slug, this.props.token)
        .then((response) => {
          this.setState(
            {
              profileWatching: response.data,
            },
            () => {
              this.getVideos();
            }
          );
        })
        .catch((error) => {});
    }
  }

  getVideos = (offset = 0) => {
    {
      this.setState({
        loading: true,
      });
      let queryParams = `cryptName=${
        this.state.profileWatching && this.state.profileWatching.cryptName
      }&limit=${paginationConf.pageLimit}&offset=${offset}`;
      if (this.state.searchControl.value) {
        queryParams = queryParams + `&name=${this.state.searchControl.value}`;
      }
      if (this.state.sort) {
        queryParams = queryParams + `&sort=${this.state.sort}`;
      }
      this.state.profileWatching &&
        videoService
          .getAllVideos(this.props.token, queryParams)
          .then((response) => {
            paginationConf.total = response.data.meta
              ? response.data.meta.total
              : 0;

            this.setState({
              loading: false,
              videos: response.data ? response.data.data : [],
              hideSearch: response.data.length < 1 && !this.state.searchControl.value,
            });
          })
          .catch((error) => {});
    }
  };

  handlePaginationchange = (pag) => {
    this.setState({currentPaginationPage: pag.currentPage - 1});
    this.getVideos((pag.currentPage - 1) * pag.pageLimit);
  };

  clearPaginationConf = () => {
    paginationConf = {
      ...paginationConf,
      total: null,
      currentPage: 1
    };
  };

  handleChangeSort = (e) => {
    this.setState({ sort: e.target.value }, () => {
      this.getVideos(this.state.currentPaginationPage);
    });
  };

  onHandleSearchChange = (e) => {
    this.clearPaginationConf();
    const controlCopy = clone(this.state.searchControl);
    controlCopy.value = e.target.value;

    this.setState({ searchControl: controlCopy }, () => {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(this.getVideos, 300);
    });
  };

  render() {
    const { loading, videos } = this.state;
    const loaderRender = <CircularProgress className={classes.VideoLoader} />;
    const renderContent = () =>
      videos && videos.length > 0 ? (
        <>
          <GridList style={{ flex: "1" }} className={classes.GridList}>
            {videos.map((video) => (
              <ItemCard
                key={video.slug}
                type="video"
                itemData={video}
                profile={true}
              />
            ))}
          </GridList>
        </>
      ) : (
        <React.Fragment>
          <section className={classes.SectionEmpty}>
            <ReactSVG src={videoIcon} className={classes.EmptyIcon} />
            <h3 className={classes.SectionHeadline}>
              {!this.state.searchControl.value ? (
                "No video classes yet."
              ) : (
                "No search results."
              )}
            </h3>
            {this.props.user &&
            this.state.profileWatching &&
            this.props.user.cryptName ===
              this.state.profileWatching.cryptName &&
            !this.state.searchControl.value &&
              <Link to="/create-video">
                <Button size="large">CREATE VIDEO CLASS</Button>
              </Link>
            }
          </section>
        </React.Fragment>
      );

    return (
      <div className={classes.Container}>
        {!this.state.hideSearch && (
          <div className={classes.controlsWrap}>
            <Input
              className={classes.InputSearch}
              onChange={(e) => this.onHandleSearchChange(e)}
              config={this.state.searchControl}
            />
            <div
              className={classesSorter.Container}
            >
              <p
                className={classesSorter.Label}
              >
                Sort by:
              </p>
              {/* Sorter */}
              <Input
                style={{ margin: '0' }}
                className={classesSorter.Control}
                config={{
                  id: 'sorter-control',
                  type: 'text',
                  select: true,
                  selectList:
                  optionSort.map((option) => {
                      return { label: option.name, value: option.value };
                    }),
                  value: this.state.sort ? this.state.sort : this.state.sorterInitalValue,
                }}
                onChange={this.handleChangeSort}
              />
              {/* <select
                onChange={this.handleChangeSort}
                style={{
                  padding: "8px",
                  borderColor: "#dbdbdb",
                  width: "188px",
                }}
              >
                {optionSort.map((option) => {
                  return <option value={option.value}>{option.name}</option>;
                })}
              </select> */}

            </div>
          </div>
        )}

        {this.props.user && this.state.profileWatching && this.props.user.cryptName === this.state.profileWatching.cryptName && this.props.user.plan === PLAN_ID_FREE && this.props.user.lastPlanDowngraded !== null && (
          <div style={{ width: '100%', marginBottom: '27px' }}>
            <PromptToUpgradeSubscription from="expiredVideos" expiredPlan={this.props.user.lastPlanDowngraded} />
          </div>
        )}

        {loading ? loaderRender : renderContent()}
        {paginationConf.total ? (
          <Pagination
            style={{ marginTop: "40px" }}
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationchange}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(ProfileVideos));
