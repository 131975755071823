import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import windowWidth from 'react-window-size';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import * as screen from '../../../../utils/common.js'
import classes from './NavDropdown.module.scss';
import Avatar from '../../../UI/Avatar/Avatar';
import Card from '../../../UI/Card/Card';

import { ChosenDisplayName } from '../../../UI/ChosenDisplayName/ChosenDisplayName.js';

class NavDropdown extends React.PureComponent {
	state = {
		open: false,
	};

	handleToggle = () => {
		this.setState((state) => ({ open: !state.open }));
	};

	handleClose = (event) => {
		this.setState({ open: false });
	};

	render() {

		const screenWidth = this.props.windowWidth;

		const isTeacher = this.props.userData.type === 'teacher';
		const icon = () =>
			this.state.open ? (
				<ArrowDropUp className={classes.IconDropdown} />
			) : (
				<ArrowDropDown className={classes.IconDropdown} />
			);
		return (
			<div className={classes.Container} onClick={this.handleToggle}>
				<Avatar
					src={
						this.props.userData.profile.photo && this.props.userData.profile.photo.small
					}
					className={classes.Avatar}
				/>
				{/* <div>
					{screenWidth > 1024 ? this.props.userData.profile.name : ''}
				</div> */}
				<div>
					{screenWidth > 1279 ? <ChosenDisplayName user={this.props.userData} noBadge /> : ''}
				</div>
				{icon()}

				{this.state.open ? (
					<Card className={classes.MenuCard}>
						<ClickAwayListener onClickAway={this.handleClose}>
							<MenuList>
							{isTeacher && (
								<>
									<Link to={`/profile/${this.props.userData.profileUrl}`}>
										<MenuItem>Public profile</MenuItem>
									</Link>
									<Link to="/account/dashboard">
										<MenuItem>Teacher dashboard</MenuItem>
									</Link>
								</>
								) }
								<Link to="/account">
									<MenuItem>My account</MenuItem>
								</Link>

								<Link to="/account/settings">
									<MenuItem>Settings</MenuItem>
								</Link>

								<Link to="/balance">
									<MenuItem>My balance</MenuItem>
								</Link>

								<Link to="/account/transaction-history/">
									<MenuItem>Transaction history</MenuItem>
								</Link>

								<Link to="/logout">
									<MenuItem>Logout</MenuItem>
								</Link>
							</MenuList>
						</ClickAwayListener>
					</Card>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userData: state.user
	};
};

export default connect(mapStateToProps)(windowWidth(NavDropdown));
