import React from 'react';
import FollowComponent from '../../components/Follow/Follow'
import {connect} from "react-redux";
import * as followingService from "../../services/following/followingService";

class Follow extends React.Component {
    state = {
        following: false,
        buttonSpinner: false
    }

    componentDidMount() {
        this.setState({following: this.props.teacher ? this.props.teacher.following : false});
    }

    follow = (cryptName) => {
        this.setState({buttonSpinner: true});
        followingService
                .followTeacher(
                    this.props.token,
                    cryptName
                )
                .then(response => {
                    this.setState({following: true, buttonSpinner: false});
                    this.props.increaseNumberOfFollowers && this.props.increaseNumberOfFollowers();
                })
                .catch(error => {
                })
    }

    unfollow = (cryptName) => {
        this.setState({buttonSpinner: true});
        followingService
            .deleteFollowingTeacher(
                this.props.token,
                cryptName
            )
            .then(response => {
                this.setState({following: false, buttonSpinner: false});
                this.props.decreaseNumberOfFollowers && this.props.decreaseNumberOfFollowers();
            })
            .catch(error => {
            })
    }

    render() {
        return (
            this.props.user && this.props.user.cryptName !== this.props.teacher.cryptName ? <FollowComponent
              unfollow={this.unfollow}
              follow={this.follow}
              teacher={this.props.teacher}
              following={this.state.following}
              buttonSpinner={this.state.buttonSpinner}
              responsiveIcon={this.props.responsiveIcon}
          /> : null
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        token: state.auth.accessToken
    };
};

export default connect(mapStateToProps)(Follow);
