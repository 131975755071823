import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactSVG from 'react-svg'
import clone from 'lodash.clonedeep'
import { connect } from 'react-redux'

import * as configurationService from './../../../services/configuration/configurationService'
import { Form, Input } from './../../../components/UI/Form/Form'
import * as validator from './../../../utils/formValidator'
import * as actions from './../../../store/actions/indexActions'
import Card from './../../../components/UI/Card/Card'
import Button from './../../../components/UI/Button/Button'
import classes from './../StaticPages.module.scss'
import IconContactUs from '../../../assets/images/icons/contact_us_icon.svg'
import IconContactUsSuccess from '../../../assets/images/icons/contact_us_success_icon.svg'
import Background from '../../../assets/images/background/contact-us-background-min.jpg'

class ContactUs extends Component {
  state = {
    formIsValid: false,
    loading: false,
    sent: false,
    formControls: {
      name: {
        id: 'name',
        type: 'text',
        ref: 'name',
        placeholder: 'Name',
        placeholderStatic: 'Enter your name',
        shrink: true,
        validation: {
          required: true,
          isValid: true,
          minLength: 2,
          maxLength: 100
        },
        error: '',
        valid: false
      },
      email: {
        id: 'email',
        type: 'email',
        ref: 'email',
        placeholder: 'Email address',
        placeholderStatic: 'Enter your email address',
        shrink: true,
        validation: {
          required: true,
          isValid: true,
          isEmail: true
        },
        error: '',
        valid: false
      },
      message: {
        id: 'message',
        type: 'textarea',
        charCounter: 600,
        ref: 'message',
        placeholder: 'Message',
        placeholderStatic: 'Enter your message',
        shrink: true,
        validation: {
          required: true,
          isValid: true,
          minLength: 2,
          maxLength: 600
        },
        error: '',
        valid: false
      }
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0);

    //Meta tags
    let metatitle = document.getElementsByName('title')[0];
    let metaDecription = document.getElementsByName('description')[0];

    document.title = 'Contact us | InfoDepot';
    metatitle.setAttribute('content', 'Contact us | InfoDepot');
    metaDecription.setAttribute('content', 'Have a question, suggestion or an issue you want to report? Contact the InfoDepot team and someone will get back to you.' )
  }

  // clears the errors and 'dirty' the input on change. Errors will be checked on blur
  clearInput = inpt => {
    inpt.touched = true
    inpt.error = ''

    // if input is part of the group clear all group memebers
    if (inpt.group) {
      inpt.groupError = false
    }
  }

  inputChangeHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls)
    const ctrl = formControlsCopy[controlName]

    // if there is no value it is the radio/checkbox input, in that case take the name
    if (ctrl.type !== 'checkbox') {
      ctrl.value = e.target.value
    } else {
      ctrl.value = ctrl.value === controlName ? '' : controlName
    }
    // if input is part of the group highlight all the memeber of a group in case of an error
    if (ctrl.group) {
      ctrl.group.forEach(inputName => {
        this.clearInput(formControlsCopy[inputName])
      })
    } else {
      this.clearInput(ctrl)
    }

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy)
    })
  }

  // checks validity of single input
  getInputError = input => {
    return validator.validateInput(input)
  }

  inputBlurHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls)
    const ctrl = formControlsCopy[controlName]

    // if it is a group show error only underneath the first input
    if (ctrl.group) {
      const errorMsg = this.getInputError(ctrl)
      if (errorMsg) {
        // show the msg on first input
        formControlsCopy[ctrl.group[0]].error = errorMsg
        // set error on all group fields
        ctrl.group.forEach(inputName => {
          formControlsCopy[inputName].groupError = true
        })
      }
    } else {
      ctrl.error = this.getInputError(ctrl)
    }

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy)
    })
  }

  submit (e) {
    e.preventDefault()
    if (this.state.formIsValid) {
      this.setState({ loading: true })
      configurationService
        .postContactMessage(
          this.state.formControls.name.value,
          this.state.formControls.email.value,
          this.state.formControls.message.value
        )
        .then(response => {
          console.log(response)
          this.setState({ loading: false, sent: true })
          this.props.history.push('/contact-us')
          window.scrollTo(0, 0)
        })
        .catch(error => {
          console.log(error.response)
          this.setState({ loading: false })
          this.props.snackbarAdd({
            message: error.response.data.children,
            timeout: 8000,
            type: 'error'
          })
        })
    }
  }

  render () {
    return (
      <div
        className={[classes.Container, classes.ContainerBackground].join(' ')}
        style={{ backgroundImage: 'url(' + Background + ')' }}
      >
        <h1>
          {this.state.sent === false
            ? 'Contact us'
            : 'Thank you for the message'}
        </h1>
        <Card className={[classes.Card, classes.CardContact].join(' ')}>
          {this.state.sent === false ? (
            <div>
              <ReactSVG className={classes.IconContactUs} src={IconContactUs} />
              <h2>We'd love to hear from you!</h2>
              <p>Send us a message and we'll respond as soon as possible.</p>
              <Form onSubmit={e => this.submit(e)}>
                <Input
                  className={classes.Input}
                  config={this.state.formControls.name}
                  onChange={e => this.inputChangeHandler(e, 'name')}
                  onBlur={e => this.inputBlurHandler(e, 'name')}
                />
                <Input
                  className={classes.Input}
                  config={this.state.formControls.email}
                  onChange={e => this.inputChangeHandler(e, 'email')}
                  onBlur={e => this.inputBlurHandler(e, 'email')}
                />
                <Input
                  className={classes.Input}
                  config={this.state.formControls.message}
                  onChange={e => this.inputChangeHandler(e, 'message')}
                  onBlur={e => this.inputBlurHandler(e, 'message')}
                />
                <Button
                  className={classes.SubmitButton}
                  color='primary'
                  fullWidth
                  size='large'
                  type='submit'
                  disabled={this.state.loading || !this.state.formIsValid}
                  loading={Number(this.state.loading)}
                >
                  SEND MESSAGE
                </Button>
              </Form>
              <div className={classes.AddressWrap}>
                <p>P.O. BOX 230385</p>
                <p>New York, New York 10023</p>
                <p>Tel. (855) 585-0800</p>
                <p>
                  <a href='mailto:contact@infodepot.com?Subject=Hello Infodepot'>
                    contact@infodepot.com
                  </a>
                </p>
              </div>
            </div>
          ) : (
            <div>
              <ReactSVG
                className={classes.IconContactUs}
                src={IconContactUsSuccess}
              />
              <h2>
                Your message has been successfully sent. We will contact you
                very soon!
              </h2>
              <p>In the meantime, Browse Infodepot.</p>
              <br />
              <Link to='/browse-infodepot'>
                <Button fullWidth fullWidth size='large'>
                  BROWSE INFODEPOT
                </Button>
              </Link>
            </div>
          )}
        </Card>
        <div className={classes.FreePick}>Photo by Freepik</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  }
}

const mapDispatchToProps = dispatch => {
  return {
    snackbarAdd: snackConf => dispatch(actions.snackbarAdd(snackConf))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUs)
