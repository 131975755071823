import React from 'react';
import classes from './PromptToUpgradeSubscription.module.scss';
import { Link } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import { renderTitle, renderText } from '../consts';

function PromptToUpgradeSubscription(props) {
  const { from, expiredPlan } = props;

  return (
    <div className={classes.Container}>
      <div className={classes.Title}>{renderTitle(from, expiredPlan)}</div>
      <div className={classes.Text}>{renderText(from, expiredPlan)}</div>
      <div className={classes.CTA}>
        <Link to={`/pricing-plans`}>
          <Button fullWidth={true} color="primary" size="large">
            UPGRADE
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default PromptToUpgradeSubscription;
