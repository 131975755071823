import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import ReactPlayer from 'react-player';
import IconPlay from '../../../assets/images/icons/play_icon.svg';
import IconBalance from '../../../assets/images/icons/credits_icon.svg';
import ArrowLeft from '../../../assets/images/icons/arrow-left-solid.svg';
import IconPayout from '../../../assets/images/icons/payout_icon.svg';
import IconVideoClass from '../../../assets/images/icons/video class icon.svg';
import IconLiveCall from '../../../assets/images/icons/live_call_icon.svg';
import IconUser from '../../../assets/images/icons/user icon.svg';
import IconDashboard from '../../../assets/images/icons/dashboard_icon.svg';
import IconStarEmpty from '../../../assets/images/icons/star_empty_icon.svg';
import IconSearch from '../../../assets/images/icons/search_icon_blue.svg';
import classes from './QuickStartTutorials.module.scss';


class QuickStartTutorials extends React.PureComponent {
      state = {
        data: {},
       
        StudentTutsData: [
          {
            id: '1',
            url: 'https://www.youtube.com/watch?v=QVeJWLi1IIk',
            title: 'Check My Balance and Top-up Account',
            titleInner: 'Balance and Top-ups',
            icon: IconBalance,
            slug: 'balance-and-top-ups'
          },
          {
            id: '2',
            url: 'https://www.youtube.com/watch?v=_8wuAD_na88',
            title: 'View My Account Info and Settings',
            titleInner: 'My Account and Settings',
            icon: IconUser,
            slug: 'my-account-and-settings',
          },
          {
            id: '3',
            url: 'https://www.youtube.com/watch?v=7hqVo3l4IkA',
            title: 'Explore InfoDepot Library',
            titleInner: 'Browse Classes',
            icon: IconSearch,
            slug: 'browse-classes'
          },
          {
            id: '4',
            url: 'https://www.youtube.com/watch?v=F2rngZTnsnY',
            title: 'Take a Video Class',
            titleInner: 'Video Class',
            icon: IconVideoClass,
            slug: 'video-class',
          },
          // {
          //   id: '5',
          //   url: 'https://www.youtube.com/watch?v=5vheNbQlsyU',
          //   title: 'Review Video Classes and Live Calls',
          //   titleInner: 'Reviews',
          //   icon: IconStarEmpty
          // }
        ],
  
        TeacherTutsData: [
          {
            id: '1',
            url: 'https://www.youtube.com/watch?v=757k81Q9W4o',
            title: 'Check My Balance and Top-up Account',
            titleInner: 'Balance and Top-ups',
            icon: IconBalance,
            slug: 'balance-and-top-ups'
          },
          {
            id: '2',
            url: 'https://www.youtube.com/watch?v=KfTLb9SQ-G0&t=8s',
            title: 'Create Video Class',
            titleInner: 'Video Class',
            icon: IconVideoClass,
            slug:'video-class'
          },
          {
            id: '3',
            url: 'https://www.youtube.com/watch?v=HhaZZNhc8sA',
            title: 'Set up Live Tutoring Call',
            titleInner: 'Live Tutoring Call',
            icon: IconLiveCall,
            slug:'live-tutoring-call'
          },
          {
            id: '4',
            url: 'https://www.youtube.com/watch?v=LSD8KmeEfVo',
            title: 'View My Account Info and Settings',
            titleInner: 'My Account and Settings',
            icon: IconUser,
            slug: 'my-account-and-settings'
          },
          
          {
            id: '5',
            url: 'https://www.youtube.com/watch?v=H07b9qBsiMI',
            title: 'Explore The Teacher Dashboard',
            titleInner: 'Teacher Dashboard',
            icon: IconDashboard,
            slug: 'teacher-dashboard'
          },
          {
            id: '6',
            url: 'https://www.youtube.com/watch?v=FXNPWrvLnQk&feature=youtu.be',
            title: 'Request a Payout',
            titleInner: 'Payouts',
            icon: IconPayout,
            slug: 'payouts'
          },
          {
            id: '7',
            url: 'https://www.youtube.com/watch?v=iqXhCYnfhmI',
            title: 'Take a Video Class or Attend a Live Call',
            titleInner: 'Take classes',
            icon: IconVideoClass,
            slug: 'take-classes'
          },
          // {
          //   id: '8',
          //   url: 'https://www.youtube.com/watch?v=5vheNbQlsyU',
          //   title: 'Teach 1-on-1 via Live Calls',
          //   titleInner: '1-1 Tutoring',
          //   icon: IconLiveCall
          // }
        ]
      }

      componentDidMount () {
        console.log();
        const onLoadType = this.props.location.pathname.split('/')[2];
        
        const LoadedId = this.props.location.pathname.split('/')[3] - 1;
        const LoadedTypeData = onLoadType == 'teacher' ? this.state.TeacherTutsData[LoadedId] : this.state.StudentTutsData[LoadedId];

        //Meta titles and descriptions
        let metatitle = document.getElementsByName('title')[0];
        let metaDecription = document.getElementsByName('description')[0];

        if(this.props.location.pathname == '/quick-start' || this.props.location.pathname == '/quick-start/') {
          this.setState({
            data: {}
          });

          document.title = 'Quick Start Tutorials | InfoDepot';
          metatitle.setAttribute('content','Quick Start Tutorials | InfoDepot');
          metaDecription.setAttribute('content', 'Find out how to become an InfoDepot teacher and jumpstart your career or how to find video classes and start learning with InfoDepot Quick Start tutorials.' )
        } else {
          this.setState({
            data: LoadedTypeData
          });

          if(!(onLoadType == undefined)) {
            const realID = LoadedId + 1;
            if(onLoadType == 'teacher') {
              //console.log(realID);
              //Check by id
              if(realID == 1) {
                document.title = 'Check Balance And Top-up Your InfoDepot Account | InfoDepot';
                metatitle.setAttribute('content','Check Balance And Top-up Your InfoDepot Account | InfoDepot');
                metaDecription.setAttribute('content', 'Find out how to check your InfoDepot balance and how to top-up your account in just a few easy steps.' );
              } else if (realID == 2) {
                document.title = 'Create Video Class | InfoDepot';
                metatitle.setAttribute('content','Create Video Class | InfoDepot');
                metaDecription.setAttribute('content', 'Are you ready to create your first InfoDepot video class? Find out how to add your InfoDepot video class properly to reach more students.' );
              } else if (realID == 3) {
                document.title = 'Set Up Live Tutoring Call | InfoDepot';
                metatitle.setAttribute('content','Set Up Live Tutoring Call | InfoDepot');
                metaDecription.setAttribute('content', 'Boost your tutoring career and set up live tutoring calls on InfoDepot. Share your knowledge and expertise with students in a new way.' );
              } else if (realID == 4) {
                document.title = 'InfoDepot Account Info And Settings | InfoDepot';
                metatitle.setAttribute('content','InfoDepot Account Info And Settings | InfoDepot');
                metaDecription.setAttribute('content', 'Find out how you can set up or make changes to the information on your InfoDepot account and how to adjust account settings.' );
              } else if (realID == 5) {
                document.title = 'Explore The InfoDepot Teacher Dashboard | InfoDepot';
                metatitle.setAttribute('content','Explore The InfoDepot Teacher Dashboard | InfoDepot');
                metaDecription.setAttribute('content', 'Take a look at all available options in the InfoDepot teacher dashboard and use them to your advantage. Check your video classes, live calls and much more.' );
              } else if (realID == 6) {
                document.title = 'Request An InfoDepot Payout| InfoDepot';
                metatitle.setAttribute('content','Request An InfoDepot Payout| InfoDepot');
                metaDecription.setAttribute('content', 'Find out how you can request a payout once you start selling your video classes or having live tutoring calls on InfoDepot.' );
              } else if (realID == 7) {
                document.title = 'Take a Video Class or Attend a Live Call | InfoDepot';
                metatitle.setAttribute('content','Take a Video Class or Attend a Live Call | InfoDepot');
                metaDecription.setAttribute('content', 'InfoDepot teachers can be students as well. Find out how to buy a video class or attend a live call when you are an InfoDepot teacher.' );
              }

            } else {
              if(realID == 1) {
                document.title = 'Check Student Account Balance | InfoDepot';
                metatitle.setAttribute('content','Check Student Account Balance | InfoDepot');
                metaDecription.setAttribute('content', 'If you are an InfoDepot student looking to find out how to check your balance or how to top-up your account, find out how to quickly do it.' );
              } else if (realID == 2) {
                document.title = 'View And Edit Student InfoDepot Account | InfoDepot';
                metatitle.setAttribute('content','View And Edit Student InfoDepot Account | InfoDepot');
                metaDecription.setAttribute('content', 'Looking for your student account info? Find out how to access and adjust any settings you wish to change in your InfoDepot account.');
              } else if (realID == 3) {
                document.title = 'Explore the InfoDepot Library | InfoDepot';
                metatitle.setAttribute('content','Explore the InfoDepot Library | InfoDepot');
                metaDecription.setAttribute('content', 'Take a look at how you can explore the InfoDepot library and find the right class for you.');
              } else if (realID == 4) {
                document.title = 'Purchase An InfoDepot Video Class | InfoDepot';
                metatitle.setAttribute('content','Purchase An InfoDepot Video Class | InfoDepot');
                metaDecription.setAttribute('content', 'Find out how you can find and purchase an InfoDepot video class.');
              }
            }
          }
        }
      }
  
    render () {
      // const onLoadId = ;
      const TeacherTuts = this.state.TeacherTutsData;
      const StudentTuts = this.state.StudentTutsData;


      
    //  console.log(this.props.location.pathname);

      const PlayIcon = (
        <div className={classes.IconPlayWrapper}>
          <ReactSVG svgClassName={classes.IconPlay} src={IconPlay} />
        </div>
      );
      
      const forwardValues = (data) => {
        //console.log(data);
        this.setState({
          data: data,
        });

        //console.log(data.type + '/' + data.id + '/' + data.slug);
      }

      const SelectedId = this.state.data.id - 1;
      const TutsSelectedType = this.props.location.pathname.split('/')[2];

      //console.log(SelectedId , TutsSelectedType);
      
      const TutorialCard = (url,titleInner,iconSvg,title,type,id, slug) => (
        <div>
          <Link to={ '/quick-start/' + type + '/' + id + '/' + slug}
            onClick={e => {
              forwardValues({url: url,title: title, titleInner: titleInner,iconSvg: iconSvg, type: type, id: id, slug: slug});
            }}
          >
            <div className={classes.VideoTutorial}>
                <h3>{titleInner}</h3>
                {PlayIcon}
                <div className={classes.IconTypeWrapper}>
                  <ReactSVG svgClassName={classes.iconSvg} src={iconSvg} /> 
                </div>
            </div>
          </Link>
         <h4>{title}</h4>
        </div>
      );

      return (
        this.props.location.pathname == '/quick-start/' || this.props.location.pathname == '/quick-start' ? (
          <div className={classes.Container}>
          <div className={classes.Section}>
            <div className={classes.SectionInner}>
              <div class={classes.SectionCenter}>
                <h1>Quick Start Tutorials</h1>
                <h3>Watch the videos below to get quick answers and step-by-step instructions on how to use InfoDepot.
                Not what you’re looking for? Go to <a href="/faq">FAQs</a> for more resources.</h3>
              </div>
            </div>
          </div>
           {/* Teacher videos wrapp */}
          <div className={[classes.SectionVideos, classes.SectionVideosTeacher
          ].join(' ')}>
            <div className={classes.SectionInner}>
              <div class={classes.SectionCenter}>
                <h1>Teachers</h1>
              </div>
              <div className={classes.Row}>
              {/* eacher tuts list  */}
              {TeacherTuts.map((teacherTut) => (
                <div className={classes.Column} >
                  {TutorialCard(teacherTut.url,teacherTut.titleInner,teacherTut.icon,teacherTut.title,'teacher',teacherTut.id,teacherTut.slug)}
                </div>
              ))}
              </div>
            </div>
          </div>
          {/* Student videos wrapp */}
          <div className={[classes.SectionVideos,
          classes.SectionVideosStudent
          ].join(' ')}>
            <div className={classes.SectionInner}>
              <div class={classes.SectionCenter}>
                <h1 id="#students">Students</h1>
              </div>
              <div className={classes.Row}>
             
              {StudentTuts.map((studentTut) => (
                <div className={classes.Column} >
                  {TutorialCard(studentTut.url,studentTut.titleInner,studentTut.icon,studentTut.title,'student',studentTut.id,studentTut.slug)}
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div className={classes.Container}>
            <div className={[classes.Section, classes.SectionVideos, TutsSelectedType == 'teacher' ? classes.VideoTeacher : classes.VideoStudent].join(' ')}>
                <div className={classes.SectionInnerColumn}>
                    <a className={classes.LinkBack} href="/quick-start"><ReactSVG svgClassName={classes.ArrowLeft} src={ArrowLeft} /> Back to Quick Start Tutorials</a>
                    {/* {PlayerComponent(this.state.data.url,this.state.data.title)} */}
                    <div>
                      <h1 className={classes.HeadBold}>{this.state.data.title}</h1>
                      <ReactPlayer className={classes.Video} 
                      url={this.state.data.url}
                      playing={false}
                      fluid={false}
                      loop={false}
                      width='100%'
                      height="650px"
                      controls={true}
                      className={classes.Video}/>
                    </div>
                </div>
            </div>
            <div className={[classes.SectionVideos,
            TutsSelectedType == 'teacher' ? classes.SectionVideosTeacher : classes.SectionVideosStudent].join(' ')}>
                <div className={classes.SectionInnerColumn}>
                {/* Tutorials next list */}
                {TutsSelectedType == 'teacher' ? (
                   SelectedId < TeacherTuts.length - 1 ? (
                  <div>
                    <h1 className={classes.HeadLight}>Up next:</h1>
                    <div className={classes.Row}>
                      <div className={classes.Column} >
                        {TeacherTuts.includes(TeacherTuts[SelectedId + 1]) ? TutorialCard(TeacherTuts[SelectedId + 1].url,TeacherTuts[SelectedId + 1].titleInner,TeacherTuts[SelectedId + 1].icon,TeacherTuts[SelectedId + 1].title, 'teacher' , TeacherTuts[SelectedId + 1].id,TeacherTuts[SelectedId + 1].slug) : ''}
                      </div>
                      <div className={classes.Column} >
                        {TeacherTuts.includes(TeacherTuts[SelectedId + 2]) ? TutorialCard(TeacherTuts[SelectedId + 2].url,TeacherTuts[SelectedId + 2].titleInner,TeacherTuts[SelectedId + 2].icon,TeacherTuts[SelectedId + 2].title, 'teacher' , TeacherTuts[SelectedId + 2].id, TeacherTuts[SelectedId + 2].slug) : ''}
                      </div>
                      <div className={classes.Column} >
                        {TeacherTuts.includes(TeacherTuts[SelectedId + 3]) ? TutorialCard(TeacherTuts[SelectedId + 3].url,TeacherTuts[SelectedId + 3].titleInner,TeacherTuts[SelectedId + 3].icon,TeacherTuts[SelectedId + 3].title, 'teacher' , TeacherTuts[SelectedId + 3].id,TeacherTuts[SelectedId + 3].slug) : ''}
                      </div>
                    </div>
                  </div>
                  ) : ''
                ) : 
                (
                  SelectedId < StudentTuts.length - 1 ? (
                  // StudentTuts[SelectedId + 1] > StudentTuts.length (
                  <div>
                    <h1 className={classes.HeadLight}>Up next:</h1>
                    <div className={classes.Row}>
                      <div className={classes.Column} >
                      {StudentTuts.includes(StudentTuts[SelectedId + 1]) ? TutorialCard(StudentTuts[SelectedId + 1].url,StudentTuts[SelectedId + 1].titleInner,StudentTuts[SelectedId + 1].icon,StudentTuts[SelectedId + 1].title, 'student' , StudentTuts[SelectedId + 1].id, StudentTuts[SelectedId + 1].slug) : ''}
                      </div>
                      <div className={classes.Column} >
                      {StudentTuts.includes(StudentTuts[SelectedId + 2]) ? TutorialCard(StudentTuts[SelectedId + 2].url,StudentTuts[SelectedId + 2].titleInner,StudentTuts[SelectedId + 2].icon,StudentTuts[SelectedId + 2].title, 'student' , StudentTuts[SelectedId + 2].id, StudentTuts[SelectedId + 2].slug) : ''}  
                      </div>
                      <div className={classes.Column} >
                      {StudentTuts.includes(StudentTuts[SelectedId + 3]) ? TutorialCard(StudentTuts[SelectedId + 3].url,StudentTuts[SelectedId + 3].titleInner,StudentTuts[SelectedId + 3].icon,StudentTuts[SelectedId + 3].title, 'student' , StudentTuts[SelectedId + 3].id, StudentTuts[SelectedId + 3].slug) : ''}
                      </div>
                    </div>
                  </div>
                  //)
                  ) : ''
                )
              }
                </div>
                <div className={classes.SectionInnerColumn}>
                  <a href="/quick-start" className={classes.LinkBackBottom}>View all Quick Start Tutorials</a>
                </div>
            </div>
          </div>
        )
      )
    }
  }


  // const mapStateToProps = (state) => {
  //   return {
  //     data: state.data
  //   };
  // };
  
  
  export default QuickStartTutorials;