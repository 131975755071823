import React from 'react';
import classes from './PromptToUpgradeSubscriptionWide.module.scss';
import { Link } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import { renderSubtitle } from '../consts';

function PromptToUpgradeSubscription(props) {
  const { from } = props;

  return (
    <div className={classes.Container}>
      <div className={classes.Subtitle}>{renderSubtitle(from)}</div>
      <div className={classes.CTA}>
        <Link to={`/pricing-plans`}>
          <Button fullWidth={true} color="primary" size="large">
            UPGRADE
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default PromptToUpgradeSubscription;
