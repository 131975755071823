import axiosInstance from '../axiosDefaultInstance';
import apiConf from '../../utils/apiConfig';

/**
 * login user call
 * @param data {username, password}
 * @returns {AxiosPromise<any>}
 */
export const login = (data) => {
	const url = '/oauth/v2/token';
	const params = {
		...data,
		client_id: apiConf.APP_ID,
		client_secret: apiConf.APP_SECRET,
		grant_type: 'password'
	};

	return axiosInstance.post(url, params);
};

export const refreshToken = (data) => {
	const url = '/oauth/v2/token';
	const params = {
		...data,
		grant_type: 'refresh_token',
		client_id: apiConf.APP_ID,
		client_secret: apiConf.APP_SECRET
	};

	return axiosInstance.post(url, params);
};

export const confirmEmail = (code) => {
	const url = '/user/confirm';
	const params = {
			token: code,
			client_id: apiConf.APP_ID
	};

	return axiosInstance.patch(url, params);
};

export const forgotPassword = (userData, code) => {
	const url = '/user/forgot';

	const params = {
			...userData,
			token: code,
			// client_id: apiConf.APP_ID
	};

	return axiosInstance.patch(url, params);
};

export const resetPassword = (userData) => {
	const url = '/user/reset';

	const params = {
			...userData,
			client_id: apiConf.APP_ID 
	};

	return axiosInstance.patch(url, params);
}