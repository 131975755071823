import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import classes from './SuccessfulPayment.module.scss';
import Card from '../../../components/UI/Card/Card';
import Button from '../../../components/UI/Button/Button';
import IconPayment from '../../../assets/images/icons/success_credits_icon.svg';

class SuccessfulPayment extends Component {
	render() {
		const user = this.props.user;

		const teacherButton = user.type === 'teacher' ? <Link to="/start-teaching"><Button fullWidth size="large">START TEACHING</Button></Link> : null;
		return (
			<div className={classes.Container}>
				<h1>Successful payment</h1>
				<Card className={classes.Card}>
					<img
						className={classes.IconCoins}
						src={IconPayment}
						alt="coins with checkmark"
					/>
					<p className={classes.Message}>You have successfully Topped Up your account.</p>
					<div className={classes.ButtonsWrap}>
						{teacherButton}
						<Link to="/browse-infodepot">
							<Button size="large">BROWSE CLASSES</Button>
						</Link>
					</div>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps)(SuccessfulPayment);
