import React, { Component } from "react";
import ReactSVG from "react-svg";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import classes from "./Followers.module.scss";
import * as followingService from "../../../../services/following/followingService";
import Pagination from "../../../../components/UI/Pagination/Pagination";
import IconFollower from "../../../../assets/images/icons/follower_icon.svg";
import List from "../../../../components/UI/List/List";
import * as screen from "../../../../utils/common";

const paginationConf = {
  pageLimit: 10,
  total: null,
};

class FollowersListing extends Component {
  state = {
    loading: true,
    followers: [],
    buttonSpinner: null
  };

  componentDidMount() {
    this.getFollowers();
  }

  getFollowers = (offset = 0) => {
    followingService
      .getFollowers(
        this.props.token,
        `?limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then((response) => {
        paginationConf.total = response.data.meta
          ? Number(response.data.meta.total)
          : 0;

        this.setState({
          loading: false,
          followers: response.data.data ? response.data.data : [],
        });
      })
      .catch((error) => {});
  };

  handlePaginationChange = (pag) => {
    if (pag.currentPage && pag.currentPage !== paginationConf.currentPage) {
      paginationConf.currentPage = pag.currentPage;
      this.getFollowers((pag.currentPage - 1) * pag.pageLimit);
    }
  };

  deleteFollower = (id) => {
    if (!id) {
      return;
    }
    this.setState({
      buttonSpinner: id,
    });

    let cryptName = null;
    this.state.followers.filter((item) => {
      if (id === item.id) {
        cryptName = item.follower.cryptName;
      }
    });
    if (cryptName) {
      followingService
          .deleteFollowerOfTeacher(this.props.token, cryptName)
          .then((response) => {
            if (this.state.followers.length === 1) {
              //if last item was deleted(can be on pagination) reload new data
              this.setState({ loading: true });
              this.getFollowers();
            }
            this.setState({
              followers: this.state.followers.filter((follower) => {
                return follower.follower.cryptName !== cryptName;
              }),
            });
          })
          .catch((error) => {});
    }
  };

  render() {
    const { loading, followers } = this.state;
    const loaderRender = <CircularProgress className={classes.Loader} />;
    const renderContent = () =>
      followers && followers.length > 0 ? (
        <Grid container>
          <List
            data={followers}
            deleteFollower={this.deleteFollower}
            buttonSpinner={this.state.buttonSpinner}
            typeList="followers"
            screenWidth={screen.mq()}
          />
        </Grid>
      ) : (
        <React.Fragment>
          <section className={classes.CardEmpty}>
            {/* <img src={IconFollower} alt="Follower Icon" /> */}
            <ReactSVG svgClassName={classes.Icon} src={IconFollower} />
            <h2 className={classes.SectionHeadline}>No followers yet.</h2>
            <p>
              Students can follow you to get notified when you add a new
              class/call or when you are
              <br /> available for live tutoring.
            </p>
          </section>
        </React.Fragment>
      );

    return (
      <div className={classes.Container}>
        <h1 className={classes.SectionHeadline}>Followers</h1>
        {loading ? loaderRender : renderContent()}
        {paginationConf.total && followers.length > 0 ? (
          <Pagination
            style={{ marginTop: "40px" }}
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationChange}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(FollowersListing);
