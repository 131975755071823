import React, { Component } from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import Delete from "./../../assets/images/icons/close icon.svg";
import IconButton from "@material-ui/core/IconButton";

//const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    top: "110px",
    width: "100%",
    flexShrink: 0,
    zIndex: 0,
    position: "unset",
    transition: "0.2s",
    [theme.breakpoints.up("sm")]: {
      top: "60px",
    },
  },
  drawerPaper: {
    width: "100%",
    top: "110px",
    background: "#f1f4f7",
    [theme.breakpoints.up("sm")]: {
      top: "60px",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
});

class MessageThreadsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTo: 0,
      isBodyFixed: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        //variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={this.props.open}
        onClose={this.props.onClose}
        disableBackdropClick={this.props.disableBackdropClick}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.props.closeOnClick ? this.props.onClose : null}
          onKeyDown={this.props.closeOnClick ? this.props.onClose : null}
        >
          {/* <div>
            <IconButton
              className="CloseButton"
              onClick={this.props.onClose}
              style={{ float: "right" }}
            >
              <img
                className="Icon"
                src={Delete}
                alt="X mark"
                style={{ margin: "0" }}
              />
            </IconButton>
          </div> */}
          {this.props.children}
        </div>
      </Drawer>
    );
  }
}

MessageThreadsDrawer.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableBackdropClick: PropTypes.bool,
  closeOnClick: PropTypes.bool,
};

export default withStyles(styles)(MessageThreadsDrawer);
