import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import classes from "./Following.module.scss";
import * as followingService from "../../../services/following/followingService";
import Pagination from "../../../components/UI/Pagination/Pagination";
import List from "../../../components/UI/List/List";
import * as screen from "../../../utils/common.js";
import IconFollower from "../../../assets/images/icons/follower_icon.svg";
import Button from "../../../components/UI/Button/Button";
import clone from "lodash.clonedeep";
import {Input} from "../../../components/UI/Form/Form";
import IconSearch from "../../../assets/images/icons/search_icon_blue.svg";

const paginationConf = {
  pageLimit: 10,
  total: null,
};
class Following extends Component {
  state = {
    loading: true,
    followings: [],
    buttonSpinner: null,
    hideSearch: true,
    searchControl: {
      id: "search-input",
      type: "text",
      placeholderStatic: "Search",
      adornment: {
        position: "start",
        icon: () => (
          <ReactSVG src={IconSearch} svgClassName={classes.IconSearchInput} />
        ),
      },
      value: "",
      validation: {},
    },
  };

  componentDidMount() {
    this.getFollowings();
  }

  getFollowings = (offset = 0, search = null) => {
    let queryParams = `?limit=${paginationConf.pageLimit}&offset=${offset}`;
    if (search) {
      queryParams = queryParams + `&search=${search}`;
    }
    followingService
      .getFollowingTeachers(
        this.props.token,
        queryParams
      )
      .then((response) => {
        paginationConf.total = response.data.meta
          ? Number(response.data.meta.total)
          : 0;

        this.setState({
          loading: false,
          followings: response.data.data ? response.data.data : [],
          hideSearch: response.data.data.length < 1 && !search
        });
      })
      .catch((error) => {});
  };

  handlePaginationChange = (pag) => {
    if (pag.currentPage && pag.currentPage !== paginationConf.currentPage) {
      paginationConf.currentPage = pag.currentPage;
      this.getFollowings((pag.currentPage - 1) * pag.pageLimit);
    }
  };

  deleteFollowing = (id) => {
    if (!id) {
      return;
    }
    this.setState({
      buttonSpinner: id,
    });

    let cryptName = null;
    this.state.followings.filter((item) => {
      if (id === item.id) {
        cryptName = item.teacher.cryptName;
      }
    });
    if (cryptName) {
      followingService
        .deleteFollowingTeacher(this.props.token, cryptName)
        .then((response) => {
          if (this.state.followings.length === 1) {
            //if last item was deleted(can be on pagination) reload new data
            this.setState({ loading: true });
            this.getFollowings();
          }
          this.setState({
            followings: this.state.followings.filter((following) => {
              return following.teacher.cryptName !== cryptName;
            }),
          });
        })
        .catch((error) => {});
    }
  };

  onHandleSearchChange = (e) => {
    const controlCopy = clone(this.state.searchControl);
    controlCopy.value = e.target.value;

    this.setState({ searchControl: controlCopy });

    this.setState((prevState) => {
      return {
        searchControl: controlCopy,
        filters: {
          ...prevState.filters,
          search: controlCopy.value
        }
      };
    });
    this.getFollowings(0, e.target.value);
  };

  render() {
    const { loading, followings } = this.state;
    const loaderRender = <CircularProgress className={classes.Loader} />;
    const renderContent = () =>
      followings && followings.length > 0 ? (
        <Grid container>
          <List
            data={followings}
            typeList="following"
            deleteFollowing={this.deleteFollowing}
            buttonSpinner={this.state.buttonSpinner}
            screenWidth={screen.mq()}
          />
        </Grid>
      ) : (
        <React.Fragment>
          <section className={classes.CardEmpty}>
            <ReactSVG svgClassName={classes.Icon} src={IconFollower} />
              {!this.state.searchControl.value ?
                <>
                  <h3 className={classes.SectionHeadline}>You don't follow anyone yet.</h3>
                  <p style={{maxWidth: "600px"}}>
                  Follow a teacher from their video class/live call page to get
                  notified when they add a new class or when they are available for
                  live tutoring
                  </p>
                </> : <h3 className={classes.SectionHeadline}>No search results</h3>
              }

            <Link to="/browse-infodepot/videos">
              <Button
                className={classes.Button}
                size="large"
              >
                BROWSE CLASSES NOW
              </Button>
            </Link>
          </section>
        </React.Fragment>
      );

    return (
      <div className={classes.Container}>
        <div>
          {!this.state.hideSearch && <Input
              className={classes.InputSearch}
              onChange={(e) => this.onHandleSearchChange(e)}
              config={this.state.searchControl}
          />}
        </div>
        {loading ? loaderRender : renderContent()}
        {paginationConf.total && followings.length > 0 ? (
          <Pagination
            style={{ marginTop: "40px" }}
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationChange}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(Following);
