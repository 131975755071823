import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserProfile } from "../../services/user/userService";
import clone from "lodash.clonedeep";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "../MyAccount/ClassesList/MyVideos/MyVideos.module.scss";
import classesSorter from "../../components/UI/Sorter/Sorter.module.scss";
import GridList from "../../components/UI/GridList/GridList";
import ItemCard from "../../components/ItemCard/ItemCard";
import {Link, withRouter} from "react-router-dom";
import Button from "../../components/UI/Button/Button";
import { Input } from "../../components/UI/Form/Form";
import Pagination from "../../components/UI/Pagination/Pagination";
import * as liveCallService from "./../../services/liveCall/liveCallService";
import LiveCallModal from "../../components/LiveCallModal/LiveCallModal";
import SignUpModal from "../../components/SignUpModal/SignUpModal";
import ReactSVG from "react-svg";
import IconSearch from "../../assets/images/icons/search_icon_blue.svg";
import IconLiveCalls from "../../assets/images/icons/live_call_icon.svg";
import TwilioVideo from "../../components/TwilioVideo/TwilioVideo";
import * as callService from "../../services/liveCall/liveCallService";
import PromptToUpgradeSubscription from '../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';

let paginationConf = {
  pageLimit: 20,
  total: null
};

const intervalAutoRefresh = (fn) => setInterval(fn, 5000);

const optionSort = [
  {
    name: "Latest",
    value: "publishedAt",
  },
  {
    name: "Most reviewed",
    value: "numberOfRating",
  },
  {
    name: "Highest rated",
    value: "averageRating",
  },
  {
    name: "Most sold",
    value: "numberOfSold",
  },
];

class ProfileLiveTutoringCallsVideos extends Component {
  state = {
    call: null,
    modalLiveCallOpened: false,
    modalCallType: "",
    roomName: "",
    roomToken: "",
    participient: "",
    videoName: "",
    twilioVideo: false,
    participientUrlImage: "",
    loading: true,
    profileWatching: null,
    calls: [],
    modalCallOpened: false,
    selectedCall: null,
    author: "",
    modalSignUpOpened: false,
    hideSearch: true,
    searchControl: {
      id: "search-input",
      type: "text",
      placeholderStatic: "Search",
      adornment: {
        position: "start",
        icon: () => (
          <ReactSVG src={IconSearch} svgClassName={classes.IconSearchInput} />
        ),
      },
      value: "",
      validation: {},
      flipCard: null,
      sort: null
    },
    sorterInitalValue: 'publishedAt',
    currentPaginationPage: null
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.clearStatusRefreshInterval();
  }

  init() {
    const profileUrl = this.props.match.params.slug;
    if (profileUrl) {
      getUserProfile(this.props.match.params.slug, this.props.token)
          .then((response) => {
            this.setState(
                {
                  profileWatching: response.data,
                },
                () => {
                  this.getCalls();
                }
            );
          })
          .catch((error) => {});
    }
  }

  clearStatusRefreshInterval = () => {
    clearInterval(this.autoRefreshStatus);
  };

  getCalls = (offset = 0) => {
    let queryParams = `cryptName=${
        this.state.profileWatching && this.state.profileWatching.cryptName
      }&limit=${paginationConf.pageLimit}&offset=${offset}`;
      if (this.state.searchControl.value) {
        queryParams = queryParams + `&name=${this.state.searchControl.value}`;
      }
      if (this.state.sort) {
        queryParams = queryParams + `&sort=${this.state.sort}`;
      }

      this.clearStatusRefreshInterval();
      // autorefresh call statuses based on interval
      setTimeout(() => {
          this.autoRefreshStatus = intervalAutoRefresh(this.getCallsStatus);
       });
      this.state.profileWatching && liveCallService
          .getAllBrowseCalls(this.props.token, queryParams)
          .then((response) => {
            paginationConf.total = response.data.meta
              ? response.data.meta.total
              : 0;

            this.setState({
              loading: false,
              calls: response.data ? response.data.data : [],
              hideSearch: response.data.length < 1 && !this.state.searchControl.value,
            });
          })
          .catch((error) => {});
  };

  getCallsStatus = () => {
    if (this.state.calls && this.state.calls.length) {
      const slugList = this.state.calls.map((_class) => _class.slug);
      callService
          .getCallStatusList(slugList.join(','))
          .then((response) => {
            this.setState((state) => {
              const callListCopy = state.calls;
              if (callListCopy && callListCopy.length) {
                callListCopy.forEach((oldCall) => {
                  response.data.forEach((freshCall) => {
                    if (oldCall.slug === freshCall.slug) {
                      oldCall.liveCallStatus = freshCall.liveCallStatus;
                      oldCall.videoCallStatus = freshCall.videoCallStatus;
                      oldCall.author.liveCallQueuesCount = freshCall.author.liveCallQueuesCount;
                    }
                  });
                });
              }
              return { calls: callListCopy };
            });
          })
          .catch((error) => {
          });
    }
  };

  handlePaginationchange = (pag) => {
    this.setState({currentPaginationPage: pag.currentPage - 1});
    this.getCalls((pag.currentPage - 1) * pag.pageLimit)
  };

  clearPaginationConf = () => {
    paginationConf = {
      ...paginationConf,
      total: null,
      currentPage: 1
    };
  };

  handleChangeSort = (e) => {
    this.setState({ sort: e.target.value }, () => {
      this.getCalls(this.state.currentPaginationPage);
    });
  };

  onHandleSearchChange = (e) => {
    this.clearPaginationConf();
    const controlCopy = clone(this.state.searchControl);
    controlCopy.value = e.target.value;

    this.setState({ searchControl: controlCopy }, () => {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(this.getCalls, 300);
    });
  };

  openCallModalPhone = (call) => {
    if (this.props.user) {
      this.setState({
        modalCallOpened: true,
        selectedCall: call,
        modalCallType: "phone",
      });
    } else {
      this.openSignUpModal("/call/" + call.slug);
    }
  };

  openCallModalVideo = (call) => {
    if (this.props.user) {
      this.setState({
        modalCallOpened: true,
        selectedCall: call,
        modalCallType: "video",
      });
    } else {
      this.openSignUpModal("/call/" + call.slug);
    }
  };

  openSignUpModal = (returnToUrl) => {
    this.setState({ modalSignUpOpened: true, returnToUrl });
  };

  closeSignUpModal = () => {
    this.setState({ modalSignUpOpened: false, returnToUrl: null });
  };

  //close twilio video
  closeTwilioVideo = () => {
    this.setState({
      twilioVideo: false
    });
  }

  closeCallModal = (
      room,
      roomToken,
      participient,
      videoName,
      twilioVideo,
      participientUrlImage
  ) => {
    this.setState({
      modalLiveCallOpened: false,
      selectedCall: null,
      modalVideoCall: false,
      roomName: room,
      roomToken: roomToken,
      participient: participient,
      videoName: videoName,
      twilioVideo: twilioVideo,
      participientUrlImage: participientUrlImage,
    });
  };

  flipCardShow = (call) => {
      this.setState({
        flipCard: call.slug
      });
  }

  flipCardHide = () => {
    this.setState({
      flipCard: null
    });
  }

  render() {
    const teacherWatchSelf = this.props.user && this.state.profileWatching && this.props.user.cryptName === this.state.profileWatching.cryptName;
    const { loading, calls, flipCard } = this.state;
    const loaderRender = <CircularProgress className={classes.VideoLoader} />;
    const renderContent = () =>
      calls && calls.length > 0 ? (
        <>
          <GridList style={{ flex: "1" }} className={classes.GridList}>
            {calls.map((call) => (
              <ItemCard
                flipCard={flipCard}
                key={call.slug}
                itemData={call}
                type={teacherWatchSelf ? 'my-call' : 'call'}
                profile={true}
                muted={teacherWatchSelf && this.props.user.statusCall}
                onMainButtonClick={() => this.flipCardShow(call)}
                onFlipCloseClick={(e) => this.flipCardHide()}
                onButtonClick={() => this.openCallModalPhone(call)}
                onButtonClickVideo={() => this.openCallModalVideo(call)}
              />
            ))}
          </GridList>
        </>
      ) : (
        <React.Fragment>
          <section className={classes.SectionEmpty}>
            <ReactSVG src={IconLiveCalls} className={classes.EmptyIcon} />
            <h3 className={classes.SectionHeadline}>
              {!this.state.searchControl.value ? (
                "No tutoring calls yet."
              ) : (
                "No search results."
              )}
            </h3>
            {teacherWatchSelf &&
              <Link to="/set-up-live-call">
                <Button size="large">SET UP LIVE CALL</Button>
              </Link>
            }
          </section>
        </React.Fragment>
      );

    return (
      <div className={classes.Container}>
        <SignUpModal
          open={this.state.modalSignUpOpened}
          onClose={this.closeSignUpModal}
        />
        <LiveCallModal
          open={this.state.modalCallOpened}
          onClose={this.closeCallModal}
          call={this.state.selectedCall}
          type={this.state.modalCallType}
        />
        {this.state.twilioVideo && this.state.roomToken !== '' ?
            <TwilioVideo
                roomName={this.state.roomName}
                roomToken={this.state.roomToken}
                incomingCallUser = {this.state.participient}
                videoName={this.state.videoName}
                photoUrl={this.state.participientUrlImage}
                author={this.state.author}
                closeTwilioVideo = {this.closeTwilioVideo}
            />
            : ''}
        {!this.state.hideSearch && <div className={classes.controlsWrap}>
          <Input
              className={classes.InputSearch}
              onChange={(e) => this.onHandleSearchChange(e)}
              config={this.state.searchControl}
          />
          <div
            className={classesSorter.Container}
          >
            <p
              className={classesSorter.Label}
            >
              Sort by:
            </p>
            {/* Sorter */}
            <Input
              style={{ margin: '0' }}
              className={classesSorter.Control}
              config={{
                id: 'sorter-control',
                type: 'text',
                select: true,
                selectList:
                optionSort.map((option) => {
                    return { label: option.name, value: option.value };
                  }),
                value: this.state.sort ? this.state.sort : this.state.sorterInitalValue,
              }}
              onChange={this.handleChangeSort}
            />
          </div>
        </div>
        }

        {teacherWatchSelf && this.props.user.plan !== 'pro' && this.props.user.lastPlanDowngraded === 'pro' && (
          <div style={{ width: '100%', marginBottom: '27px' }}>
            <PromptToUpgradeSubscription from="expiredCalls" />
          </div>
        )}

        {loading ? loaderRender : renderContent()}
        {paginationConf.total ? (
          <Pagination
            style={{ marginTop: "40px" }}
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationchange}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(ProfileLiveTutoringCallsVideos));
