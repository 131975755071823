import React from 'react';
import ReactSVG from 'react-svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { map } from 'lodash';

import Button from '../../components/UI/Button/Button';
import classes from './FeesInfo.module.scss';
import { getInfoDepotFee } from '../../services/configuration/configurationService';
import IconInfo from '../../assets/images/icons/info_icon.svg';
import { feesSections } from '../../utils/constsFees';

class FeesInfo extends React.PureComponent {
  state = {
    loading: true,
    fees: null,
  };
  async componentDidMount() {
    const fees = await getInfoDepotFee()
      .then((response) => response.data)
      .catch((err) => console.log(err));

    this.setState({
      fees: fees,
    });

    window.scrollTo(0, 0);

    //Meta tags
    let metatitle = document.getElementsByName('title')[0];
    let metaDecription = document.getElementsByName('description')[0];

    document.title = 'InfoDepot Fees | InfoDepot';
    metatitle.setAttribute('content', 'InfoDepot Fees | InfoDepot');
    metaDecription.setAttribute(
      'content',
      'Interested in becoming an InfoDepot teacher? Find out more about the InfoDepot fees and why you should register now.'
    );
  }

  formatPercent = (numb) =>
    Number(numb)
      .toFixed(0)
      .toString() + '%';

  renderCellExplanation = (explanation, feeAPIName) => {
    if (this.state.fees && feeAPIName === 'payoutFee') {
      const calculated =
        25 * (1 + this.state.fees[feeAPIName] / 100).toFixed(2);
      return (
        <div className={classes.Cell_Explanation}>
          For example, if a payout request is made by a teacher for $25, the
          amount of ${calculated} will be withdrawn from their account.
        </div>
      );
    } else {
      return (
        explanation && (
          <div className={classes.Cell_Explanation}>{explanation}</div>
        )
      );
    }
  };

  render() {
    const { fees } = this.state;
    const loggedUser = this.props.userData;
    // console.log(this.state.fees);

    return (
      <div>
        <div className={classes.SectionHeader}>
          <div className={classes.SectionInner}>
            <div className={classes.SectionLeft}>
              <h1>
                Learn all about our fees and enjoy being an InfoDepot teacher
              </h1>
              <Link to={loggedUser ? '/start-teaching' : '/pricing-plans'}>
                <Button
                  className={classes.Button}
                  color="secondary"
                  size="large"
                >
                  Start teaching today
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className={classes.ContainerTable}>
          {map(feesSections, (section) => (
            <div className={classes.TableSection}>
              <h2>{section.tableSectionTitle}</h2>
              <div className={classes.Table}>
                {map(section.tableRows, (tableRow) => (
                  <div className={classes.Row}>
                    {map(tableRow.tableLeft, (tableSide) => (
                      <div className={classes.Row_Left}>
                        <div className={classes.Heading}></div>
                        <div className={classes.Cell_Left}>
                          <h4 className={classes.Cell_Title}>
                            {tableSide.cellTitle}
                          </h4>
                          <div className={classes.Cell_Text}>
                            {tableSide.cellText}
                          </div>
                          {tableSide.cellNote && (
                            <div className={classes.Cell_Note}>
                              {tableSide.cellNote}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    {map(tableRow.tableRight, (tableSide) => (
                      <div className={classes.Row_Right}>
                        <div className={classes.Heading}>
                          {map(section.headingRight, (heading) => (
                            <div className={classes.Heading_Cell}>
                              {heading}
                            </div>
                          ))}
                        </div>
                        <div
                          className={[
                            classes.Cell_Right,
                            tableSide.feeAPINames &&
                            tableSide.feeAPINames.length > 1
                              ? classes.MultipleColumns
                              : '',
                          ].join(' ')}
                        >
                          {tableSide.feeAPINames ? (
                            <div className={classes.Sub}>
                              {map(tableSide.feeAPINames, (feeAPIName) => (
                                <div className={classes.Sub_Cell}>
                                  {fees ? (
                                    this.formatPercent(fees[feeAPIName])
                                  ) : (
                                    <CircularProgress
                                      style={{ height: '15px', width: '15px' }}
                                    />
                                  )}
                                  {this.renderCellExplanation(
                                    tableSide.cellExplanation,
                                    feeAPIName
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <>
                              <div className={classes.Cell_Value}>
                                {tableSide.cellValue}
                              </div>
                              {this.renderCellExplanation(
                                tableSide.cellExplanation
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className={classes.FeeChangeInfo}>
          <ReactSVG src={IconInfo} className={classes.IconInfo} />
          <p>
            *Please note that all fees stated above are subject to change at any
            time and for any reason at the complete and sole discretion of
            InfoDepot.
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};

export default connect(mapStateToProps)(FeesInfo);
