export const feesSections = [
  {
    tableSectionTitle: 'Video class sale',
    headingRight: ['Free', 'Basic', 'Pro'],
    tableRows: [
      {
        tableLeft: [
          {
            cellTitle: 'Transaction fee',
            cellText:
              'Transaction fee on video class sale is applied once the video class is purchased and paid by the student. No fees are applied to the videos that have not yet been purchased.',
          },
        ],
        tableRight: [
          {
            feeAPINames: [
              'freePlanVideoSaleCommission',
              'basicPlanVideoSaleCommission',
              'proPlanVideoSaleCommission',
            ],
          },
        ],
      },
    ],
  },
  {
    tableSectionTitle: 'Live tutoring call sale',
    headingRight: ['Pro'],
    tableRows: [
      {
        tableLeft: [
          {
            cellTitle: 'Transaction fee',
            cellText:
              'Transaction fee on live tutoring call sale is paid by the teacher from the total revenue collected after a call is completed.',
          },
        ],
        tableRight: [
          {
            feeAPINames: ['liveCallCommisionFee'],
          },
        ],
      },
      {
        tableLeft: [
          {
            cellTitle: 'The network conection fee',
            cellText:
              'The network connection fee is the per minute cost of connecting the Live Tutoring Call on the global network.',
            cellNote:
              'Note that during the setup process for Live Tutoring Calls teachers must charge a minimum of $1.50/minute for a call line.',
          },
        ],
        tableRight: [
          {
            cellValue: '$0.02 - $0.50*',
            cellExplanation:
              '*The fees vary depending on the student/teacher location, with the average fee ranging from $0.02 to $0.50 per minute for calls between the United States and Europe.',
          },
        ],
      },
    ],
  },
  {
    tableSectionTitle: 'Payout',
    headingRight: ['Free', 'Basic', 'Pro'],
    tableRows: [
      {
        tableLeft: [
          {
            cellTitle: 'PayPal payout fee',
            cellText:
              'A Paypal Payout Fee is added to each payout request made by a teacher. Minimum payout amount is $25.',
            cellNote:
              "Note that there must be sufficient funds in a teacher's account to complete the payout request including the payment of the Paypal Payout Fee.",
          },
        ],
        tableRight: [
          {
            feeAPINames: ['payoutFee'],
            // cellExplanation: 'For example, if a payout request is made by a teacher for $25.00, the amount of $25.50 will be withdrawn from their account.',
          },
        ],
      },
    ],
  },
];
