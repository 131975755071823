import React from "react";
import Button from "../UI/Button/Button";
import classes from './Follow.module.scss';
import ReactSVG from "react-svg";
import FollowerIcon from "../../assets/images/icons/follower_icon.svg";
import FollowerRemoveIcon from "../../assets/images/icons/follower_remove_icon.svg";

const Follow = (props) => {
  return (
    <>
      {props.following ? (
        <Button
          type="outline"
          size="small"
          color="gray"
          loading={props.buttonSpinner}
          onClick={() => props.unfollow(props.teacher.cryptName)}
          style={{ fontSize: "13px!important" }}
          className={props.responsiveIcon ? classes.ButtonResponsiveProfile : ''}
        >
          {props.responsiveIcon && (
            <ReactSVG
              style={{
                color: '#272727',
                fill: '#272727',
              }}
              src={FollowerRemoveIcon}
            />
          )}
          Unfollow
        </Button>
      ) : (
        <Button
          type="contained"
          size="small"
          loading={props.buttonSpinner}
          onClick={() => props.follow(props.teacher.cryptName)}
          style={{ fontSize: "13px!important" }}
          className={props.responsiveIcon ? classes.ButtonResponsiveProfile : ''}
        >
          {props.responsiveIcon && (
            <ReactSVG
              style={{
                color: 'white',
                fill: 'white',
              }}
              src={FollowerIcon}
            />
          )}
          Follow
        </Button>
      )}
    </>
  );
};

export default Follow;
