import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactSVG from 'react-svg'

import classes from './MyBalance.module.scss'
import Card from '../../components/UI/Card/Card'
import Button from '../../components/UI/Button/Button'
import IconPayout from '../../assets/images/icons/payout_icon.svg'
import IconCoins from '../../assets/images/icons/credits_icon.svg'

class MyBalance extends Component {
  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    const user = this.props.user

    const teacherButton =
      user.type === 'teacher' ? (
        <div>
          <Link to='/payout'>
            <Button
              size='large'
              fullWidth
              style={{ width: '273px', height: '50px' }}
            >
              <img src={IconPayout} alt='hand holding a dolar' />
              REQUEST A PAYOUT
            </Button>
          </Link>
          <p>Payouts are made to your PayPal account.</p>
        </div>
      ) : null

    const topUpInfo =
      user.type === 'teacher' ? (
        <div>
          <p>
            You must add dollars to your account to create video class, purchase
            a class or to speak live with another teacher.
          </p>
        </div>
      ) : (
        <div>
          <p style={{ width: '276px' }}>
            You must add dollars to your account to purchase a class or to speak
            live with a teacher.
          </p>
        </div>
      )

    return (
      <div className={classes.Container}>
        <h1>My balance</h1>
        <Card className={classes.Card}>
          <div className={classes.BalanceWrap}>
            You currently have{' '}
            <span className={classes.GreenBold}>
              ${user.totalCredits.toFixed(2)}
            </span>
          </div>
          <div
            className={[
              classes.ButtonsWrap,
              user.type !== 'teacher' ? classes.ButtonsWrapSingle : ''
            ].join(' ')}
          >
            {teacherButton}
            <div>
              <Link to='/buy-credits'>
                <Button size='large' style={{ width: '273px', height: '50px' }}>
                  <img src={IconCoins} alt='stack of coins' />
                  TOP UP YOUR ACCOUNT
                </Button>
              </Link>
              {topUpInfo}
            </div>
          </div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(MyBalance)
