import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as userService from "../../services/user/userService";
import classes from "../AccountSettings/AccountSettings.module.scss";
import map from "lodash/map";
import { getPaymentTitleForPlan } from "../../utils/consts";
import moment from "moment";
import Button from "../../components/UI/Button/Button";

function PaymentHistory({ user, token }) {
  if (user.type !== "teacher") {
    return null;
  }
  const [state, setState] = useState({
    items: [],
    error: null,
    status: "idle",
    limit: 5,
    offset: 0,
    hasMore: false
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setState({
          ...state,
          status: "pending"
        });

        const { limit, offset } = state;

        const response = await userService.getMyPayments(token, limit, offset);
        const items = response.data;
        let hasMore = false;
        if (items.length === limit) {
          hasMore = true;
        }

        setState({
          ...state,
          items,
          status: "resolved",
          hasMore,
          offset: limit + 1
        });
      } catch (onError) {
        console.log(onError);
        setState({
          ...state,
          error: "Something went wrong",
          status: "rejected"
        });
      }
    };

    getData();
  }, []);

  const { items } = state;
  // console.log(state);

  // paymentDate: "2022-02-07T13:54:30+00:00"
  // plan: "basic_plan_yearly"
  // price: 276

  async function loadMore() {
    try {
      setState({
        ...state,
        status: "pending"
      });

      const { limit, offset, items: prev } = state;

      const response = await userService.getMyPayments(token, limit, offset);
      const items = prev.concat(response.data);
      let hasMore = false;
      if (response.data.length === limit) {
        hasMore = true;
      }

      setState({
        ...state,
        items,
        status: "resolved",
        hasMore,
        offset: items.length
      });
    } catch (onError) {
      console.log(onError);
      setState({
        ...state,
        error: "Something went wrong",
        status: "rejected"
      });
    }
  }


  return (
    <div className={classes.PaymentHistory}>
      <h3 className={classes.Subheaders} style={{ marginBottom: "20px" }}>
        Payment history
      </h3>
      {items.length === 0 && (
        <p className={classes.HighlightBox}>No subscription payment yet.</p>
      )}
      {items.length > 0 &&
        map(items, (item, index) => (
          <div key={index} className={classes.PaymentHistoryItem}>
            <div>
              {getPaymentTitleForPlan(item.plan)}
              <br />${item.price}
            </div>
            <div style={{ flexShrink: 0, marginLeft: "15px" }}>
              {moment(item.paymentDate).format("MMM DD, y")}
            </div>
          </div>
        ))}
      {state.hasMore && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Button
            type="outline"
            onClick={loadMore}
            loading={state.status === 'pending'}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  token: state.auth.accessToken
});
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
