import axiosInstance from '../axiosDefaultInstance';

export const getInfoDepotFee = () => {
	const url = '/configuration/payment-fees';

	return axiosInstance.get(url);
};

export const getCategories = (type) => {
	const url = '/classes/categories' + (type ? '?type=' + type : '');

	return axiosInstance.get(url);
};

export const postContactMessage = (name, email, message) => {
	
	const url = `/contact`;

	const payload = {
		name,
		email,
		message
	};

	return axiosInstance.post(url, payload);
};
