import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import windowWidth from 'react-window-size';
import CircularProgress from '@material-ui/core/CircularProgress';

import classes from './VideosListing.module.scss';
import * as screen from './../../../../utils/common.js'
import * as actions from './../../../../store/actions/indexActions';
import Button from './../../../../components/UI/Button/Button';
import Card from './../../../../components/UI/Card/Card';
import videoIcon from './../../../../assets/images/icons/video class icon.svg';
import * as videoService from './../../../../services/video/videoService';
import GridList from '../../../../components/UI/GridList/GridList';
import ItemCard from '../../../../components/ItemCard/ItemCard';
import Pagination from '../../../../components/UI/Pagination/Pagination';
import Modal from './../../../../components/UI/Modal/Modal';
import PromptToUpgradeSubscription from '../../../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';
import { PLAN_ID_FREE } from '../../../../utils/consts';

const paginationConf = {
	pageLimit: 20,
	total: null
};

class Videos extends Component {
	state = {
		loading: true,
		videosData: null,
		modalConfirmOpened: false,
		videoName: '',
	};

	componentDidMount() {
		this.getVideos();
	}

	showError = (message) => {
		this.props.snackbarAdd({
			message: message,
			type: 'error',
			timeout: 8000
		});
	};

	getVideos = (offset = 0) => {

		videoService
			.getTeacherVideos(
				this.props.token,
				`limit=${paginationConf.pageLimit}&offset=${offset}`
			)
			.then((response) => {
				paginationConf.total = response.data.length ? response.data[0]['_total'] : null;
				this.setState({
					videosData: response.data,
					loading: false
				});
			})
			.catch((error) => {
				console.log(error);
				return error.response;
			});
	};

	handlePaginationchange = (pag) => {
		this.getVideos((pag.currentPage - 1) * pag.pageLimit);
	};

	checkStatusOfVideo = (status) => {
		console.log(status);
	}

	onHandleClickPublish = (e, slug, status) => {
		// e.preventDefault();
		videoService
			.publishVideoClass(this.props.token, slug, status)
			.then((response) => {
				console.log(response);
				this.props.snackbarAdd({
					message: 'You have successfully published your video.',
					timeout: 8000
				});
				this.getVideos();
			})
			.catch((error) => {
				console.log(error.response);
				if (error.response.data && error.response.data.message && error.response.data.message === 'You cannot publish video class.') {
					this.props.snackbarAdd({
						message: 'To offer more than 10 videos, upgrade your subscription.',
						timeout: 8000,
						type: 'info'
					});
				}
			});
		//console.log(this.props.history);
	};


	render() {

		let getVideosOnInterval = () => {
			setTimeout(() => {
				this.getVideos()
			},15000)
		}

		let hasNewUnUploaded = document.getElementById('checkUpload');

		hasNewUnUploaded ? getVideosOnInterval() : console.log();

		const screenWidth = this.props.windowWidth;

		const videos = this.state.videosData;
		const loaderRender = () => <CircularProgress className={classes.Loader} />;

		const buttonRender = () => (
			<Link to="/create-video">
				<Button size="large" className={classes.ButtonSettings}>
					CREATE VIDEO CLASS NOW
				</Button>
			</Link>
		);

		const onClickModalClose = () => {
			this.setState({
				modalConfirmOpened: false
			})
		}

		const noContentRender = () => (
			<section className={classes.Section}>
				<img src={videoIcon} alt="Video Icon" />
				<h3 className={classes.EmptyHeadline}>
					You haven't created any video classes yet.
				</h3>
				<Link to="/create-video">
					<Button size="large" className={classes.ButtonSettings}>
						CREATE VIDEO CLASS NOW
					</Button>
				</Link>
			</section>
		);

		const deleteVideo = (videoSlug, token, props) => {
			videoService
				.deleteVideoService(token , videoSlug)
				.then((response) => {
					this.getVideos();
					this.props.snackbarAdd({
						message: 'You have successfully deleted your video.',
						timeout: 6000
					});
				})
				.catch((error) => {
					console.log(error);
				});
				onClickModalClose();
				this.setState({
					loading: true
				})
		}


		const listRender = () => (
			<div>
				<GridList className={classes.GridList}>
					{videos.map((video) => (
						<ItemCard
							type="my-video"
							itemData={video}
							onClickPublish={(e, slug, status) =>
								this.onHandleClickPublish(e, slug, status)
							}
								
							modalState = {this.state.modalConfirmOpened}
							onClickModalActivate={(videoName) =>
								this.setState({
									modalConfirmOpened: true,
									videoName: videoName,
								})
							}
						/>
					))}
				</GridList>
				<Modal
					className={classes.ModalConfirm}
					onClose={onClickModalClose}
					open={this.state.modalConfirmOpened}
					>
					<h3 className={classes.ModalSubtitle}>Are you sure you want to delete this video?</h3>
					<div className={classes.ConfirmBtnWrap}>
						<div>
							<a className={classes.CancelBtn} onClick={onClickModalClose}>
								Cancel
							</a>
						</div>
						<div>
							<a onClick={e => {
							e.preventDefault()
							deleteVideo(this.state.videoName ,this.props.token, this.props);
							}}
							>
							<Button className={classes.RedBtn} size="large">
								Delete
							</Button>
							</a>
						</div>
					</div>
				</Modal>
			</div>
		);

		return (
			<div className={classes.Container}>
				{screenWidth > 768 ? <h1 className={classes.SectionHeadline}>My Videos</h1> : ''}
			
				{this.props.user.plan === PLAN_ID_FREE && this.props.user.lastPlanDowngraded !== null ? <PromptToUpgradeSubscription from="expiredVideos" expiredPlan={this.props.user.lastPlanDowngraded} /> : buttonRender()}

				{this.state.loading ? (
					loaderRender()
				) : // main content, shown after fetch, if there are no items show contentlessRender
				videos && videos.length > 0 ? (
					<React.Fragment>
						{listRender()}
						<Pagination
							style={{marginTop: '40px'}}
							totalRecords={paginationConf.total}
							pageLimit={paginationConf.pageLimit}
							pageNeighbours={1}
							onPageChanged={this.handlePaginationchange}
						/>
					</React.Fragment>
				) : (
					noContentRender()
				)}
			</div>
		);
		console.log();
	}
}
const mapStateToProps = (state) => {
	return {
    	user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(windowWidth(Videos));
