import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import windowWidth from 'react-window-size'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'


import * as liveCallService from './../../../../services/liveCall/liveCallService'
import * as actions from './../../../../store/actions/indexActions'
import IDTable from '../../../../components/UI/Table/Table'
import TabButtons from '../../../../components/UI/TabButtons/TabButtons';
import classes from './QueuedCalls.module.scss'
import { Link } from 'react-router-dom'
import IconLiveCall from '../../../../assets/images/icons/live_call_icon.svg'
import IconVideo from '../../../../assets/images/icons/video-solid.svg';
import Button from '../../../../components/UI/Button/Button'

class QueuedCalls extends Component {
  state = {
    queuedCalls: null,
    queuedVideoCalls: null,
    loading: true,
    activeTab: 'video'
  }

  componentDidMount () {
    this.setActiveTab();
    this.getQueuesCallsVideo();
    this.getQueuesCallsPhone();
  }

  getQueuesCallsPhone = () => {
    liveCallService
    .getStudentQueueCalls(this.props.token)
    .then(response => {
      this.setState({ loading: false, queuedCalls: response.data })
    })
    .catch(error => {
      console.log(error.data)
    })
  }

  getQueuesCallsVideo = () => {
    liveCallService
    .getStudentQueueVideoCalls(this.props.token)
    .then(response => {
      this.setState({ loading: false, queuedVideoCalls: response.data })
    })
    .catch(error => {
      console.log(error.data)
    })
  }

  setActiveTab = () => {
    const href = this.props.location.pathname;
    let callType = this.props.match.params.type;

    if(href == '/account/queued-calls/video') {
        callType = 'video';
    } else if(href == '/account/queued-calls/phone') {
      callType = 'phone';
    }

    const activeTab = this.state.activeTab
    // check if it is a valid type comming from the url if it isn't route to first option
    const tabsListNames = ['video','phone'];

    if (tabsListNames.includes(callType)) {
      this.setState({ activeTab: callType })
    } else {
      const url = '/account/queued-calls/' + activeTab
      this.props.history.replace(url)
    }
  }

  handleTabChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + value);
		this.setState({ activeTab: value });

	};

	handleOptionChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + event.target.value);
		this.setState({ activeTab: event.target.value });
  };
  

  render () {
    const screenWidth = this.props.windowWidth
    const { queuedCalls, queuedVideoCalls, activeTab } = this.state
    const loaderRender = <CircularProgress className={classes.VideoLoader} />

    const callsEmptyRender = () => {
      return (
        <div className={classes.ContinerEmpty}>
          {this.state.activeTab == 'video' ? <ReactSVG svgClassName={classes.IconLiveCall} src={IconVideo} /> : <ReactSVG svgClassName={classes.IconLiveCall} src={IconLiveCall} />}
          <h2>You have no queued calls.</h2>
          <Link to={'/browse-infodepot/calls'}>
            <Button size='large'>BROWSE LIVE TUTORING CALLS NOW</Button>
          </Link>
        </div>
      )
    }

    const tabProperties = [
			{
				value: 'video',
				label: 'Video Calls in Queue',
				content: <React.Fragment>
        {this.state.loading ? (
            loaderRender
          ) : queuedVideoCalls && queuedVideoCalls.length ? (
            <IDTable
              data={queuedVideoCalls}
              user={this.state.user}
              typeData='video'
              typeTable='queueCallsStudent'
              screen={screenWidth}
            />
          ) : (
            callsEmptyRender()
          )}
        </React.Fragment>
      },
      {
				value: 'phone',
				label: 'Phone Calls in Queue',
				content:  <React.Fragment>
        {this.state.loading ? (
            loaderRender
          ) : queuedCalls && queuedCalls.length ? (
            <IDTable
              data={queuedCalls}
              user={this.state.user}
              typeData='phone'
              typeTable='queueCallsStudent'
              screen={screenWidth}
            />
          ) : (
            callsEmptyRender()
          )}
        </React.Fragment>
			}
		];

    return (
      <div>
        {this.state.loading ? (
          loaderRender
        ) : (
          <div className={classes.TableWrap}>
           <TabButtons
            className={classes.TabsButton}
            activetab={activeTab}
            config={tabProperties}
            onChangeTab={(e, value) => this.handleTabChange(e, value)}
          />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  }
}

export default withRouter(
  connect(mapStateToProps)(windowWidth(QueuedCalls))
)
