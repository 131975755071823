import axiosInstance from '../axiosDefaultInstance';

export const postReview = (token, type, slug, rate, comment) => {
	const config = {
		headers: {
			'X-Auth-Token': token
		}
	};

	const url = `/classes/${type}/rate/${slug}`;
	const payload = {
		rate,
		comment
	};

	return axiosInstance.post(url, payload, config);
};

export const getClassReviews = (token, type, slug, query) => {
	const config = {
		headers: {
			'X-Auth-Token': token
		}
	};
	const url = '/classes/' + type + '/' + slug + '/ratings' + (query ? ('?' + query) : '');

	return axiosInstance.get(url, token ? config : null);
};
