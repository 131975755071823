import React from 'react';
import PriceRange from 'react-input-range';

import classes from './Price.module.scss';
import './Price.override.scss';
import Form from './../../../../src/components/UI/Form/Form';
import Button from './../../../../src/components/UI/Button/Button';
import './../../../../src/sass/vendor/react-input-range.scss';

class Price extends React.PureComponent {
	render(){
		return (
			<div className={classes.List}>
				<div className={classes.PriceRangeWrap}>
					<PriceRange
						allowSameValues
						className={classes.PriceRange}
						minValue={this.props.min}
						maxValue={this.props.max}
						onChange={(value) => this.props.onChange(value)}
						value={this.props.value}
					/>
					<Form onSubmit={(e) => this.props.onSubmit(e)}>
						<div className={classes.FormGroup}>
							<input
								type="number"
								className={classes.Input}
								//min='1'
								value={this.props.value.min}
								onChange={e => this.props.onChangeRange(e, 'min')}
								placeholder={'from'}
							/>
						</div>
						<span className={classes.MinToMax}>-</span>
						<div className={classes.FormGroup}>
							<input
								type="number"
								className={classes.Input}
								value={this.props.value.max}
								//min='1'
								onChange={e => this.props.onChangeRange(e, 'max')}
								placeholder={'to'}
							/>
						</div>
						<Button type="submit" className={classes.Button}>Apply</Button>
					</Form>
				</div>
			</div>
		);
	}
};
export default Price;
