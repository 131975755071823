import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconClock from '@material-ui/icons/AccessTime';
import ReactSVG from 'react-svg';
import Moment from 'react-moment';
import ClampLines from 'react-clamp-lines';

import * as actions from '../../store/actions/indexActions';
import classes from './ItemCard.module.scss';
import Card from '../UI/Card/Card';
import Modal from '../../components/UI/Modal/Modal';
import apiConfig from '../../utils/apiConfig';
import Button from '../../components/UI/Button/Button';
import IconVideo from './../../../src/assets/images/icons/video class icon.svg';
import IconExclamation from './../../../src/assets/images/icons/exclamation_icon.svg';
import IconVideoSlash from './../../../src/assets/images/icons/video-slash-solid.svg';
import IconVideoBasic from './../../../src/assets/images/icons/video-solid.svg';
import IconQueue from './../../../src/assets/images/icons/hour_glass_icon.svg';
import IconPlay from './../../../src/assets/images/icons/play_icon.svg';
import IconLiveCalls from '../../assets/images/icons/live_call_icon.svg';
import IconCalendar from './../../../src/assets/images/icons/calendar_icon.svg';
import IconEdit from './../../assets/images/icons/pen_icon.svg';
import IconTrash from './../../assets/images/icons/trash-alt-solid.svg';
import IconMute from './../../assets/images/icons/live_call_offline_icon.svg';
import IconLiveCall from './../../assets/images/icons/live_call_icon.svg';
import IconClose from './../../assets/images/icons/close icon.svg';
import IconEarth from './../../assets/images/icons/earth.svg';
import RateStars from '../../components/Ratings/RateStars/RateStars';
import Avatar from '../../components/UI/Avatar/Avatar';
import * as videoService from '../../services/video/videoService';
import {timeDateChange} from "../../utils/dateHelper";
import { ChosenDisplayName } from '../UI/ChosenDisplayName/ChosenDisplayName';


const CoverImage = (imgPath, children) => (
	<div
		className={classes.CoverImage}
		style={{
			backgroundImage: 'url(' + imgPath + ')'
		}}>
		{children}
	</div>
);

const isLink = (condition, node, url) =>
	condition ? <Link to={url}>{node}</Link> : node;

const RatingStars = (video) => (
	<div className={classes.StarsWrap}>
		<RateStars className={classes.Stars} rate={Number(video.averageRating)} />

		{video.numberOfRating ? (
			<React.Fragment>
				<strong>{video.averageRating}</strong>
				{`(${video.numberOfRating} review${video.numberOfRating > 1 ? 's' : ''})`}
			</React.Fragment>
		) : (
			'(no reviews)'
		)}
	</div>
);

const RatingStarsCall = (call) => (
	<div className={classes.StarsWrapCall}>
		<div className={classes.RatingStarsWrap}>
			<RateStars className={classes.Stars} rate={Number(call.averageRating)} />
			{call.numberOfRating ? <strong>{(call.averageRating * 1).toFixed(1)}</strong> : ''}
		</div>
		{call.numberOfRating ? (
			<React.Fragment>
				{`(${call.numberOfRating} review${call.numberOfRating > 1 ? 's' : ''})`}
			</React.Fragment>
		) : (
			'(no reviews)'
		)}
	</div>
);

const clampTwoRows = (str) => <ClampLines text={str} lines="2" ellipsis="..." buttons={false}/>;

const MuteCover = () => (
	<div className={classes.MuteWrap}>
		<ReactSVG svgClassName={classes.IconMute} src={IconMute} />
	</div>
);

const HideCover = () => (
	<div className={[classes.MuteWrap, classes.WithText].join(' ')}>
		<div className={classes.TitleMute}>Hidden</div>
		<div>Your subscription expired</div>
	</div>
);

const LabelEnrolled = (text, type = 'primary') => (
	<span
		className={[
			classes.LabelEnrolled,
			type !== 'primary' ? classes['LabelEnrolled--grey'] : ''
		].join(' ')}>
		{text}
	</span>
);

const VideoStatus = (status, videoStat, props) => {
	//console.log(status);
	switch (status) {
		case 'finished':
			switch (videoStat) {
				case 'hidden':
					return (
						<HideCover />
					);
				case 'published':
					return (
						<div className={classes.StatusText}>
							<div className={classes.IconPlayWrapper}>
								<ReactSVG svgClassName={classes.IconPlay} src={IconPlay} />
							</div>
						</div>
					);
				default:
					return (
						<div className={classes.StatusText}>
							Video is ready for publishing. Click Publish to make it visible to others.
							<Button
								size="small"
								fullWidth
								style={{ marginTop: '4px' }}
								onClick={(e) => {
									e.preventDefault();
									props.onClickPublish(e, props.itemData.slug, props.itemData.status);
								}}>
								<ReactSVG svgClassName={classes.IconVideo} src={IconEarth} style={{ position: "relative", top: "2px" }} />
								PUBLISH NOW
							</Button>
						</div>
					);
			}
		case 'failed':
			return (
				<div className={classes.StatusText}>
					<ReactSVG svgClassName={classes.IconExclamation} src={IconExclamation} />
					<span style={{ paddingLeft: '0', flex: '1 1' }}>
						An error occured. Please upload your video again.
					</span>

					{props.user.canCreateVideoClass ? (
					<Link
						to={'/edit-video/' + props.itemData.slug}
						style={{ marginTop: '18px', width: '100%', marginBottom: '-3px' }}
					>
						<Button
						className={classes.Button}
						size="small"
						fullWidth
						style={{ height: '33px', lineHeight: '12px' }}
						>
						<ReactSVG svgClassName={classes.IconVideo} src={IconVideo} />
						CHOOSE VIDEO
						</Button>
					</Link>
					) : (
						<Button
						className={classes.Button}
						size="small"
						fullWidth
						style={{ height: '33px', lineHeight: '12px' }}
						onClick={(e) => {
							e.preventDefault();
							props.snackbarAdd({
								message: 'To offer more than 10 videos, upgrade your subscription.',
								timeout: 8000,
								type: 'info'
							});
						}}>
						<ReactSVG svgClassName={classes.IconVideo} src={IconVideo} />
						CHOOSE VIDEO
						</Button>
					)}

					
				</div>
			);
		default:
			return (
				<div className={classes.StatusText}>
					{!status ? 
							<div className={classes.StatusText}>
							<ReactSVG svgClassName={classes.IconExclamation} src={IconExclamation} />
							<span style={{ paddingLeft: '0', flex: '1 1' }}>
								An error occured. Please upload your video again.
							</span>

							{props.user.canCreateVideoClass ? (
							<Link
								to={'/edit-video/' + props.itemData.slug}
								style={{ marginTop: '18px', width: '100%', marginBottom: '-3px' }}
							>
								<Button
								className={classes.Button}
								size="small"
								fullWidth
								style={{ height: '33px', lineHeight: '12px' }}
								>
								<ReactSVG svgClassName={classes.IconVideo} src={IconVideo} />
								CHOOSE VIDEO
								</Button>
							</Link>
							) : (
								<Button
								className={classes.Button}
								size="small"
								fullWidth
								style={{ height: '33px', lineHeight: '12px' }}
								onClick={(e) => {
									e.preventDefault();
									props.snackbarAdd({
										message: 'To offer more than 10 videos, upgrade your subscription.',
										timeout: 8000,
										type: 'info'
									});
								}}>
								<ReactSVG svgClassName={classes.IconVideo} src={IconVideo} />
								CHOOSE VIDEO
								</Button>
							)}
						</div>
						: 
							<div>
								Video is currently being processed. Please wait a few minutes.
								<Button disabled size="small" fullWidth style={{ marginTop: '4px' }}>
									<ReactSVG svgClassName={classes.IconVideo} src={IconEarth} style={{ position: "relative", top: "2px" }} />
									PUBLISH NOW
								</Button>
								<Button  id="checkUpload"
									size="small"
									fullWidth
									style={{ marginTop: '4px', display:'none' }}//
									>
									Refresh
								</Button>
							</div>
						}
				</div>
			);
	}
};

const VideoCard = (props) => {
	const video = props.itemData;
	const hideCard = video.hidden;
	const PlayIcon = (
		<div className={classes.IconPlayWrapper}>
			<ReactSVG svgClassName={classes.IconPlay} src={IconPlay} />
		</div>
	);
	const isAuthor = (props.user && props.user.cryptName) === video.author.cryptName;

	return (
		<Link
			key={video.id}
			className={[ classes.VideoCard, props.profile ? classes.CustomHeight : ' ', isAuthor && hideCard ? classes.isHidden : '' ].join(' ')}
			to={'/video/' + video.slug}>
			<Card noribbon="true">
				{/* Cover */}
				{CoverImage(apiConfig.DOMAIN_IMAGE + video.cover.big, isAuthor && hideCard ? <HideCover /> : PlayIcon)}

				{/* Info */}
				<div className={classes.InfoWrap}>
					<div className={classes.Title}>{clampTwoRows(video.name)}</div>
					{/* {props.profile !== true && <div className={classes.Teacher}>by&nbsp;<Link to={`/profile/${video.author.profileUrl}`}>{video.author.fullName}</Link></div>} */}
					{props.profile !== true && (
						<div className={classes.Teacher}>
						by&nbsp;
						<Link to={`/profile/${video.author.profileUrl}`}>
							<ChosenDisplayName user={video.author} />
						</Link>
						</div>
					)}
					{RatingStars(video)}

					<div className={[ classes.RowWrap, classes.PushDown ].join(' ')}>
						<div className={classes.VideoLength}>
							<IconClock />
							{ video.uploadVideo && video.uploadVideo.videoDuration && <>{(video.uploadVideo.videoDuration / 60).toFixed(1) +
								' min'}</> }
						</div>
						{!video.purchased && (
							<div className={classes.Price}>${(video.price * 1).toFixed(2)}</div>
						)}
					</div>

					{/* CTA Wrap */}
					{(!props.profile || !isAuthor) && (
						<div className={classes.CTAWrap}>
							{video.purchased || isAuthor ? (
								LabelEnrolled(
									isAuthor ? 'MY VIDEO' : 'ENROLLED',
									isAuthor ? 'secondary' : 'primary'
								)
							) : (
								<Button
									// onClick={(e) => {
									// 	e.preventDefault();
									// 	props.onButtonClick();
									// }}
									fullWidth="true"
									color="secondary">
									PREVIEW/BUY VIDEO
								</Button>
							)}
						</div>
					)}
				</div>
			</Card>
		</Link>
	);
};

const MyVideoCard = (video, props) => {
	const isProcessingVideo = video.uploadVideo.status !== 'finished';
	const toUrl = '/video/' + video.slug;
	const hideCard = video.hidden;

	const CardContent = (editMode) => (
		<div
			key={video.id}
			className={[ classes.VideoCard, !editMode ? classes.VideoDisabled : '', hideCard ? classes.isHidden : '' ].join(' ')}>
			<Card
				noribbon="true"
				loading={Number(![ 'failed', 'finished', undefined].includes(video.uploadVideo.status))}
				status={video.uploadVideo.status}>
				{/* Cover */}
				{CoverImage(
					video.cover && (apiConfig.DOMAIN_IMAGE + video.cover.big),
					VideoStatus(video.uploadVideo.status, hideCard ? 'hidden' : video.status, props)
				)}

				{/* Info */}
				<div className={classes.InfoWrap}>
					<div className={classes.Title}>{clampTwoRows(video.name)}</div>
					{/* <div className={classes.Teacher}>by {video.author.fullName}</div> */}
					<div className={classes.Teacher}>by&nbsp;<span><ChosenDisplayName user={video.author} /></span></div>
					{RatingStars(video)}

					<div className={[ classes.RowWrap, classes.PushDown ].join(' ')}>
						<div className={classes.VideoLength}>
							{video.uploadVideo.videoDuration && (
								<React.Fragment>
									<IconClock />
									{(video.uploadVideo.videoDuration / 60).toFixed(1) + ' min'}
								</React.Fragment>
							)}
						</div>
						<div className={classes.Price}>${(video.price * 1).toFixed(2)}</div>
					</div>
						{/* Edit */}
						<div className={classes.EditDeleteWrap}>
							{video.canBeDeleted && (
								<div className={classes.Tooltip}>
									<span className={classes.TooltipText}>Delete video</span>
									<a  data-tip data-for={video.slug}
									onClick={e => {
										e.preventDefault()
										//deleteVideo(video.slug, props.token, props);
										props.onClickModalActivate(video.slug);
										//console.log(props.itemData.slug)
										}}
										>
										<ReactSVG className={classes.IconTrash} src={IconTrash} />
									</a>
								</div>
							)}
							{!hideCard && editMode && (
							<div className={classes.EditWrap}>
								<Link className={classes.EditWrap} to={'/edit-video/' + video.slug}>
									<Button color="secondary">
										<ReactSVG className={classes.IconEdit} src={IconEdit} />
										<span>EDIT</span>
									</Button>
								</Link>
							</div>
							)}
							
						</div>
				</div>
			</Card>
		</div>
	);


	// when video is in the process status it shouldn't be linked to anything
	return isLink(!isProcessingVideo, CardContent(!isProcessingVideo), toUrl);
};

const CallSchedule = (call,props) => {
	let standardType = call.scheduleType === 'standard';
	let standardTypeVideo = call.videoScheduleType === 'standard';

	let parseWorkingDaysPhone = () => {
		if (call && call.scheduleDays.length === 7) {
			return 'Every day';
		} else if (call && call.scheduleDays.length === 5) {
			return 'Monday to Friday';
		} else {
			return 'Weekend days';
		}
	};

	let parseWorkingDaysVideo = () => {
		if (call && call.videoScheduleDays.length === 7) {
			return 'Every day';
		} else if (call && call.videoScheduleDays.length === 5) {
			return 'Monday to Friday';
		} else {
			return 'Weekend days';
		}
	};

	return (
		<React.Fragment>
			<div className={classes.TimeInfoWrap}>
				{call.scheduleDays !== null || call.scheduleCustomDays.length > 0 ? 
				<div className={classes.PhoneDate}>
					<ReactSVG svgClassName={classes.IconType} src={IconLiveCall} />
					{props.muted == 'all_disabled' ? <span className={classes.StatusCallText}>Unavailable indefinitely</span> 
					: props.muted == 'all_available' ? <span className={classes.StatusCallText}>Available anytime</span>
					: 
					<div className={classes.PhoneScedule}>
						<div className={classes.DateWrap}>
							{/* <ReactSVG svgClassName={classes.IconCalendar} src={IconCalendar} /> */}
							{standardType ? (
								parseWorkingDaysPhone()
							) : (
								<React.Fragment>
									<Moment format="DD/MM">{call.scheduleCustomStartDateUTC}</Moment>
									<span className={classes.Divide}>-</span>
									<Moment format="DD/MM">{call.scheduleCustomEndDateUTC}</Moment>
								</React.Fragment>
							)}
						</div>
						<div className={classes.DateWrap}>
							{/* <IconClock className={classes.IconClock} /> */}
							<Moment format="HH:mm">{timeDateChange(call.scheduleStartTimeUTC)}</Moment>{' '}
							<span className={classes.Divide}>to</span>{' '}
							<Moment format="HH:mm">{timeDateChange(call.scheduleEndTimeUTC)}</Moment>
						</div>
					</div>
					}
				</div>
				: ''}
				
				{call.videoScheduleDays !== null || call.videoScheduleCustomDays.length > 0 ? 
				<div className={classes.VideoDate}>
					<ReactSVG svgClassName={classes.IconType} src={IconVideoBasic} />
					{props.muted == 'all_disabled' ? <span className={classes.StatusCallText}>Unavailable indefinitely</span> : 
					props.muted == 'all_available' ? <span className={classes.StatusCallText}>Available anytime</span> 
					: 
					<div className={classes.VideoScedule}>
						<div className={classes.DateWrap}>
							{standardTypeVideo ? (
								parseWorkingDaysVideo()
							) : (
								<React.Fragment>
									<Moment format="DD/MM">{call.videoScheduleCustomStartDateUTC}</Moment>
									<span className={classes.Divide}>-</span>
									<Moment format="DD/MM">{call.videoScheduleCustomEndDateUTC}</Moment>
								</React.Fragment>
							)}
						</div>
						<div className={classes.DateWrap}>
							<Moment format="HH:mm">{timeDateChange(call.videoScheduleStartTimeUTC)}</Moment>{' '}
							<span className={classes.Divide}>to</span>{' '}
							<Moment format="HH:mm">{timeDateChange(call.videoScheduleEndTimeUTC)}</Moment>
						</div>
					</div>
					}
				</div>
			: ''}
			</div>
		</React.Fragment>
	);
};

const MyCallCard = (call, props) => {
  const hideCard = call.hidden;
  return (
    <Link
      key={call.id}
      className={[classes.Card, classes.CallCard, classes.MyCallCard, hideCard ? classes.isHidden : ''].join(' ')}
      to={'/call/' + call.slug}
    >
      <Card noribbon="true">
        <div
          className={classes.CoverImage}
          style={{
            backgroundImage:
              'url(' +
              (call.cover ? apiConfig.DOMAIN_IMAGE + call.cover.big : '') +
              ')',
          }}
        >
          {hideCard ? <HideCover /> : props.muted == 'all_disabled' && <MuteCover />}
        </div>
        <div className={classes.InfoWrap}>
          <div className={classes.Title}>{clampTwoRows(call.name)}</div>
          {RatingStarsCall(call)}
          {CallSchedule(call, props)}
          <div className={[classes.Price, classes.PushDown].join(' ')}>
            ${(call.price * 1).toFixed(2)}/
            <span style={{ fontSize: '16px' }}>min</span>
          </div>
          {!hideCard && !props.profile && (
            <Link
              className={classes.EditWrap}
              to={'/edit-live-call/' + call.slug}
            >
              <Button color="secondary">
                <ReactSVG className={classes.IconEdit} src={IconEdit} />
                <span>EDIT</span>
              </Button>
            </Link>
          )}
        </div>
      </Card>
    </Link>
  );
};

const CallCard = (call, props) => {
	const isAuthor = (props.user && props.user.cryptName) === call.author.cryptName;

	// console.log(call);

	const MainCallButtonStatus = () => {
		if (call.liveCallStatus === 'available' || call.videoCallStatus === 'available') {
			return (
				<Button className={classes.BtnLiveCall}
					fullWidth="true"
					onClick={(e) => {
						e.preventDefault();
						props.onMainButtonClick();
					}}>
					<div className={classes.BtnContentWrapp} style={{ justifyContent: "center" }}>
						<ReactSVG className={classes.IconLiveCall} src={IconLiveCall} /> 
						<div style={{ padding: "7px 0 8px" }}>Online / Call now</div>
					</div>
				</Button>
			);
		} else if (call.liveCallStatus === 'offline' && call.videoCallStatus === 'offline') {
			return (
				<Button  className={classes.BtnLiveCall} fullWidth="true" color="primary" disabled="true">
					<div className={classes.BtnContentWrapp} style={{ justifyContent: "center" }}>
						<ReactSVG className={classes.IconLiveCall} src={IconMute} />
						<div style={{ padding: "7px 0 8px" }}>Currently offline</div>
					</div>
				</Button>
			);
		} else {
			return (
				<Button className={classes.BtnLiveCall}
						fullWidth="true"
						color="secondary"
						onClick={(e) => {
							e.preventDefault();
							props.onMainButtonClick();
						}}>
					<div className={classes.BtnContentWrapp} style={{ justifyContent: "center" }}>
						<ReactSVG className={classes.IconLiveCall} src={IconQueue} />
						<div style={{ padding: "7px 0 8px", marginRight: "-6px" }}>Busy / Queue for a call</div>
					</div>
				</Button>
			);
		}
	}

	const ButtonCall = () => {
		switch (call.liveCallStatus) {
			case 'available':
				return (
					<Button className={classes.BtnLiveCall}
						fullWidth="true"
						onClick={(e) => {
							e.preventDefault();
							props.onButtonClick();
						}}>
						<div className={classes.BtnContentWrapp}>
							<ReactSVG className={classes.IconLiveCall} src={IconLiveCall} /> 
							<div>
								<span className={classes.BtnSpanInfo}>Get a phone call</span>
								<span className={classes.BtnSpanInfo}>We’ll call your cell phone</span>
							</div>
						</div>
					</Button>
				);
			case 'queued':
				return (
					<Button className={[classes.BtnLiveCall, classes.ProlongedWidth].join(" ")}
						fullWidth="true"
						color="secondary"
						onClick={(e) => {
							e.preventDefault();
							props.onButtonClick();
						}}>
						<div className={classes.BtnContentWrapp}>
							<ReactSVG className={classes.IconLiveCall} src={IconQueue} />
							<div>
								<span className={classes.BtnSpanInfo} style={{ letterSpacing: "-0.3px" }}>Queue for voice call</span>
								<span className={classes.BtnSpanInfo}>We’ll call your cell phone</span>
							</div>
						</div>
					</Button>
				);
			default:
				return (
					<Button  className={classes.BtnLiveCall} fullWidth="true" color="primary" disabled="true">
						<div className={classes.BtnContentWrapp}>
							<ReactSVG className={classes.IconLiveCall} src={IconMute} />
							<div>
								<span className={classes.BtnSpanInfo}>Currently offline</span>
								<span className={classes.BtnSpanInfo}>for phone calls</span>
							</div>
						</div>
					</Button>
				);
		}
	};

	const ButtonVideoCall = () => {
		switch (call.videoCallStatus) {
			case 'available':
				return (
					<Button className={classes.BtnLiveCall}
						fullWidth="true"
						onClick={(e) => {
							e.preventDefault();
							props.onButtonClickVideo();
						}}>
						<div className={classes.BtnContentWrapp}>
							<ReactSVG className={classes.IconLiveCall} src={IconVideoBasic} />
							<div>
								<span className={classes.BtnSpanInfo}>Start a video call</span>
								<span className={classes.BtnSpanInfo}>on InfoDepot website</span>
							</div>
						</div>
					</Button>
				);
			case 'queued':
				return (
					<Button className={[classes.BtnLiveCall, classes.ProlongedWidth].join(" ")}
						fullWidth="true"
						color="secondary"
						onClick={(e) => {
							e.preventDefault();
							props.onButtonClickVideo();
						}}>
						<div className={classes.BtnContentWrapp}>
							<ReactSVG className={classes.IconLiveCall} src={IconQueue} />
							<div>
								<span className={classes.BtnSpanInfo} style={{ letterSpacing: "-0.3px" }}>Queue for a video call</span>
								<span className={classes.BtnSpanInfo}>on InfoDepot website</span>
							</div>
						</div>
					</Button>
				);
			default:
				return (
					<Button className={classes.BtnLiveCall} fullWidth="true" color="primary" disabled="true">
						<div className={classes.BtnContentWrapp}>
							<ReactSVG className={classes.IconLiveCall} src={IconVideoSlash} />
							<div>
								<span className={classes.BtnSpanInfo}>Currently offline</span>
								<span className={classes.BtnSpanInfo}>for video calls</span>
							</div>
						</div>
					</Button>
				);
		}
	};

	return (
		<Link
			key={call.id}
			className={[ classes.Card, classes.CallCard ].join(' ')}
			to={'/call/' + call.slug}>
			<Card noribbon="true">
				{/* Cover */}
				{CoverImage(apiConfig.DOMAIN_IMAGE + call.cover.big)}
				{/* Info */}
				<div className={classes.InfoWrap}>
					<div className={classes.LiveCallTeacherWrap}>
						<Avatar
							src={
								call.author.profile && call.author.profile.photo ? (
									call.author.profile.photo.big
								) : null
							}
							className={classes.AvatarLiveCall}
						/>
							{/* <div className={classes.Teacher}>
								{!props.profile ? <Link to={`/profile/${call.author.profileUrl}`}>
									{`${call.author.profile &&
									call.author.profile.firstName} ${call.author.profile &&
									call.author.profile.lastName}`}
								</Link> : `${call.author.profile &&
								call.author.profile.firstName} ${call.author.profile &&
								call.author.profile.lastName}`}
								</div> */}
								<div className={classes.Teacher}>
								{!props.profile ? (
									<Link to={`/profile/${call.author.profileUrl}`}>
										{call.author.profile && <ChosenDisplayName user={call.author.profile} />}
									</Link>
								) : (
									call.author.profile && <span><ChosenDisplayName user={call.author.profile} /></span>
								)}
								</div>
							<div className={classes.Title}>{clampTwoRows(call.name)}</div>
							{/*{RatingStarsCall(call)}*/}
					</div>
					<div className={[ classes.Price, classes.PushDown ].join(' ')}>
						${(call.price * 1).toFixed(2)}/<span style={{ fontSize: '16px' }}>min</span>
					</div>

					{isAuthor ? (
					LabelEnrolled('My Live Tutoring Call', 'secondary')
					) : (
					<>
						{MainCallButtonStatus()}
						{props.flipCard === call.slug && (
							<div 
								className={classes.FlipBack}
								onClick={(e) => {
									e.preventDefault();
									props.onFlipCloseClick();
								}}
							>
								<span
									className={classes.FlipClose}
								>
									<ReactSVG src={IconClose} />
								</span>
								<div style={{ color: "white", fontSize: "14px", marginBottom: "19px", fontWeight: "600" }}>Choose the call type:</div>
								<div 
									className={[classes.BtnWrapp, !(call.scheduleDays || call.scheduleType == 'custom') || !(call.videoScheduleDays || call.videoScheduleType == 'custom') ? classes.NoHeightLimit : " "].join(" ")}
								>
									{call.scheduleDays || call.scheduleType == 'custom'
									? ButtonCall()
									: ''}
									{call.videoScheduleDays || call.videoScheduleType == 'custom'
									? ButtonVideoCall()
									: ''}
								</div>
							</div>
						)}
					</>
					)}
				</div>
			</Card>
		</Link>
	);
};

const ItemCard = (props) => {
	if (props.itemData) {
		switch (props.type) {
			case 'video':
				return VideoCard(props);
			case 'my-video':
				return MyVideoCard(props.itemData, props);
			case 'my-call':
				return MyCallCard(props.itemData, props);
			case 'call':
				return CallCard(props.itemData, props);
			default:
				return VideoCard(props);
		}
	} else {
		return null;
	}
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
		updateLoggedUser: () => dispatch(actions.getLoggedUser())
	};
};


export default React.memo(connect(mapStateToProps,mapDispatchToProps)(ItemCard));
