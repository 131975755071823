import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import windowWidth from 'react-window-size';

import classes from './AccountSettings.module.scss';
import Card from '../../components/UI/Card/Card';
import * as actions from '../../store/actions/indexActions';
import IconUser from '../../assets/images/icons/user icon.svg';
import IconContact from '../../assets/images/icons/contact details icon.svg';
import IconPayment from '../../assets/images/icons/payment information icon.svg';
import IconPayout from '../../assets/images/icons/payout_icon_small.svg';
import IconEmail from '../../assets/images/icons/email_icon.svg';
import IconSubscriptions from '../../assets/images/icons/card-account-details-outline.svg';
import * as screen from '../../utils/common.js'
import CustomSelect from '../../components/UI/Select/Select';
// settings containers
import PersonalSettings from './SettingsTab/PersonalSettings';
import PaymentSettings from './SettingsTab/PaymentSettings';
import ContactSettings from './SettingsTab/ContactSettings';
import PayoutSettings from './SettingsTab/PayoutSettings';
import EmailSettings from './SettingsTab/EmailSettings';
import SubscriptionSettings from "./SettingsTab/SubscriptionSettings"

const settingTypes = () => {
	return {
		personal: {
			label: 'Personal information',
			icon: IconUser,
			url: '/account/settings/personal',
			value: 'personal'
		},
		contact: {
			label: 'Contact details',
			icon: IconContact,
			url: '/account/settings/contact',
			value: 'contact'
		},
		payment: {
			label: 'Billing information',
			icon: IconPayment,
			url: '/account/settings/payment',
			value: 'payment'
		},
		subscription: {
			label: 'Subscription',
			icon: IconSubscriptions,
			url: '/account/settings/subscription',
			value: 'subscription'
		},
		payout: {
			label: 'Payout information',
			icon: IconPayout,
			url: '/account/settings/payout',
			value: 'payout'
		},
		email: {
			label: 'Email notifications',
			icon: IconEmail,
			url: '/account/settings/email',
			value: 'email'
		}
	};
};

class AccountSettings extends Component {
	// used an iife function just to have a shorthand value for repetative usage of this.props.user
	state = {
		activeSetting: 'personal'
	};

	componentDidMount() {
		this.setActiveTab();
	}

	setActiveTab() {
		const settingTypeParam = this.props.match.params.type;
		// check if it is a valid type comming from the url if it isn't route to first option
		const typeNameList = Object.keys(settingTypes());

		if (typeNameList.includes(settingTypeParam)) {
			this.setState({ activeSetting: settingTypeParam });
		} else {
			const url = '/account/settings/' + typeNameList[0];
			this.props.history.replace(url);
		}
		
	}

	handleOptionChange = (event, value) => {
		this.setState({ activeSetting: event.target.value });
		const url = '/account/settings/' + value;
		this.props.history.replace(url);
	};

	updateStore = () => {
		// copy the new profile data to the clone of the current user data
		this.showSuccessChangeSnack();
		return this.props.updateLoggedUser();
	};

	showSuccessChangeSnack = () => {
		window.scrollTo(0, 0);

		this.props.snackbarAdd({
			message: 'Profile successfully updated!'
		});
	};

	onSettingClick = (name) => {
		this.setState({ activeSetting: name });
	};

	render() {

		const screenWidth = this.props.windowWidth;

		const isTeacher = this.props.user.type === 'teacher';

		const activeSettingTab = this.state.activeSetting;

		const isActiveTab = (name) => (activeSettingTab === name ? classes.ActiveTab : '');
		
		const typeConf = settingTypes();

		const typeNames = Object.keys(settingTypes());
		const filteresTypeNames = isTeacher ? typeNames : typeNames.filter(e => !['payout', 'subscription'].includes(e));
		const config = filteresTypeNames.map((name) => typeConf[name]);

		const renderNav = () => {
		
			return filteresTypeNames.map((name, index) => (
				<Link
					key={name}
					to={typeConf[name].url}
					className={isActiveTab(name)}
					onClick={() => this.onSettingClick(name)}
					>
					<ReactSVG svgClassName={classes.NavIcon} src={typeConf[name].icon} />
					<span>{typeConf[name].label}</span>
				</Link>
			));
		};

		return (
			<div>
				<div className={classes.ContainerHeadline}>
					<h1>Account settings</h1>
				</div>
				<div className={classes.Container}>
					{/* nav menu on the side */}
					{screenWidth > 992 ? <aside className={classes.NavSettings}>{renderNav()}</aside> : <div 
								className={classes.SelectContainer}
								>
								<CustomSelect 
									activetab={activeSettingTab} 
									config={config}
									onChangeOption={(e, val) => this.handleOptionChange(e, val)}
								/>
					</div> }

					{/* main */}
						<Card className={classes.ContainerCard}>
							{/* PERSONAL */}
							{activeSettingTab === 'personal' ? (
								<PersonalSettings
									user={this.props.user}
									token={this.props.token}
									updateStore={this.updateStore}
								/>
							) : null}
							{/* CONTACT */}
							{activeSettingTab === 'contact' ? (
								<ContactSettings
									user={this.props.user}
									token={this.props.token}
									updateStore={this.updateStore}
								/>
							) : null}
							{/* PAYMENT */}
							{activeSettingTab === 'payment' ? (
								<PaymentSettings
									user={this.props.user}
									token={this.props.token}
									updateStore={this.updateStore}
								/>
							) : null}
							{/* SUBSCRIPTION */}
							{activeSettingTab === 'subscription' ? (
								<SubscriptionSettings
									user={this.props.user}
									token={this.props.token}
									updateStore={this.updateStore}
								/>
							) : null}
							{/* PAYOUT */}
							{isTeacher ? <div>
								{activeSettingTab === 'payout' ? (
									<PayoutSettings
										user={this.props.user}
										token={this.props.token}
										updateStore={this.updateStore}
									/>
								) : null}
							</div> : null }
							{/* EMAIL */}
							{activeSettingTab === 'email' ? (
								<EmailSettings
									user={this.props.user}
									token={this.props.token}
									
									updateStore={this.updateStore}

								/>
							) : null}
						</Card>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
		updateLoggedUser: (userData) => dispatch(actions.getLoggedUser(userData))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(windowWidth(AccountSettings));
