import React from "react";
import Button from "../../components/UI/Button/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconImage from "../../assets/images/icons/image.svg";
import IconAttachment from "../../assets/images/icons/attachment_icon.svg";
import IconFile from "../../assets/images/icons/documentTypes/file_icon.svg";
import Delete from "../../assets/images/icons/close icon.svg";
import SendIcon from "../../assets/images/icons/send_icon.svg";
import IconButton from "@material-ui/core/IconButton";
import ReactSVG from "react-svg";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  textField: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const MessageForm = ({
  apiConfig,
  imageAttachmentPaths,
  fileAttachmentNames,
  onImageChange,
  onClickUpload,
  onFileChange,
  onKeyUp,
  inputChangeHandler,
  deleteAttachment,
  messageText,
  createMessage,
  classes,
  loaderRender,
  uploadingAttachment,
}) => {
  //const { classes } = props;
  return (
    <div className="c-message-form">
      <Grid container style={{ alignItems: "flex-end" }} spacing={8}>
        <div>
          <div style={{ marginBottom: "6px" }}>
            <input
              onChange={(e) => onImageChange(e)}
              style={{ display: "none" }}
              accept="image/*"
              id="upload-photo"
              type="file"
            />
            <label htmlFor="upload-photo" className="label-button" onClick={(e) => onClickUpload(e)}>
              <ReactSVG
                  className="IconDelete"
                  src={IconImage}
                  style={{
                    width: "17px",
                    height: "16px",
                    fontSize: "10px",
                  }}
                />
            </label>
            <input
              onChange={(e) => onFileChange(e)}
              style={{ display: "none" }}
              id="upload-file"
              type="file"
            />
            <label htmlFor="upload-file" className="label-button" onClick={(e) => onClickUpload(e)}>
             
                <ReactSVG className="IconDelete" src={IconAttachment} style={{height: "16px"}}/>
    
            </label>
          </div>
        </div>
        <Grid item xs={8} sm={9} md={10}>
          <div
            className={`c-message-form__mediaUpload ${uploadingAttachment &&
              `uploading`}`}
          >
            {" "}
            {/* {uploadingAttachment && loaderRender} */}
            {imageAttachmentPaths ? (
              <>
                {" "}
                {imageAttachmentPaths.map((imagePath, i) => (
                  <div
                    key={i}
                    style={{
                      borderBottom: "1px solid #dbdbdb",
                      alignItems: "flex-start",
                    }}
                    className="c-message-chat-fileChat"
                  >
                    <img
                      width={100}
                      style={{
                        borderRadius: "8px",
                        marginTop: "7px",
                        marginBottom: "0",
                        height: "auto",
                      }}
                      src={apiConfig.DOMAIN_IMAGE + imagePath.path}
                      alt=""
                    />

                    <IconButton
                      className="CloseButton"
                      onClick={() => deleteAttachment(imagePath.id)}
                      style={{
                        padding: "0px",
                        marginLeft: "5px",
                        height: "min-content",
                      }}
                    >
                      <img
                        className="Icon"
                        src={Delete}
                        alt="X mark"
                        style={{ margin: "0" }}
                      />
                    </IconButton>
                  </div>
                ))}
              </>
            ) : null}
            {fileAttachmentNames.map((file, i) => (
              <>
                <div
                  key={i}
                  style={{ borderBottom: "1px solid #dbdbdb" }}
                  className="c-message-chat-fileChat"
                >
                  {/* <IconFile /> */}
                  <img
                    src={IconFile}
                    alt="File icon"
                    style={{ width: "15px", margin: "0 7px 0 0" }}
                  />
                  <span style={{ fontWeight: "500" }}>{file.name}</span>
                  <IconButton
                    className="CloseButton"
                    onClick={() => deleteAttachment(file.id)}
                    style={{ padding: "0px", marginLeft: "5px" }}
                  >
                    <img
                      className="Icon"
                      src={Delete}
                      alt="X mark"
                      style={{ margin: "0" }}
                    />
                  </IconButton>
                </div>
              </>
            ))}
            <TextField
              // style={{ padding: 0 }}
              //variant="outlined"
              fullWidth
              // className={classes.textField}
              InputProps={{ disableUnderline: true }}
              rows="1"
              rowsMax="4"
              multiline
              onKeyPress={onKeyUp}
              //type="text"
              name="message"
              value={messageText}
              onChange={(e) => inputChangeHandler(e, "message")}
              placeholder="Type a new message"
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          <Hidden smUp>
            <div>
              <button
                type="contained"
                size="small"
                color="primary"
                onClick={() => createMessage()}
                style={{
                  marginBottom: "4px",
                  width: "38px",
                  borderRadius: "8px",
                  backgroundColor: "#75c89f",
                  padding: "8px 0px 5px 0px",
                  border: "0",
                }}
              >
                <img src={SendIcon} alt="" style={{ margin: "0px" }} />
              </button>
            </div>
          </Hidden>
          <Hidden xsDown>
            <Button
              type="contained"
              size="small"
              color="primary"
              onClick={() => createMessage()}
              style={{ marginBottom: "4px" }}
            >
              Send
            </Button>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};
export default withStyles(styles)(MessageForm);
