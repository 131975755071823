import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/indexActions";

import Hidden from "@material-ui/core/Hidden";
import IDExpansionPanel from "../../../components/UI/ExpansionPanel/ExpansionPanel";
import classes from "../TeacherPage/TeacherPage.module.scss";
import Button from "./../../../components/UI/Button/Button";
import Background from "../../../assets/images/background/contest_background.png";
import JoinTeaching from "../../../assets/images/background/join_a_teacher.jpg";
import LeftShape from "../../../assets/images/graphics/left_shape.svg";
import RightShape from "../../../assets/images/graphics/right_shape.svg";
import LeftMiddleShape from "../../../assets/images/graphics/left_middle_shape.svg";
import RightMiddleShape from "../../../assets/images/graphics/right_middle_shape.svg";
import InappropriateContent from "../../../assets/images/graphics/inappropriate_content.svg";
import ContestRules from "../../../assets/images/graphics/contest_rules.svg";
import KeepTrack from "../../../assets/images/graphics/keep_track.svg";
import Step1 from "../../../assets/images/graphics/step1.svg";
import Step2 from "../../../assets/images/graphics/step2.svg";
import Step3 from "../../../assets/images/graphics/step3.svg";

class ContestPage extends Component {
  state = {
    active: "",
  };

  handleClick = (e) => {
    this.setState({
      active: true,
    });
  };

  // handleClickContest = (e)=> {
  //     if(!this.props.userData) {
  //         let contestPage = this.props.location.pathname
  //         localStorage.setItem('contestPage', contestPage);
  //     }

  //   }

  componentDidMount = () => {
    //Meta tags
    let metatitle = document.getElementsByName("title")[0];
    let metaDecription = document.getElementsByName("description")[0];

    document.title = "InfoDepot Creative Video Contest for Online Teachers";
    metatitle.setAttribute(
      "content",
      "InfoDepot Creative Video Contest for Online Teachers"
    );
    metaDecription.setAttribute(
      "content",
      "Join the InfoDepot creative video contest for online teachers and win the grand prize worth $1,000. Find out how to enter the contest."
    );
  };

  render() {
    return (
      <div className={classes.Container}>
        <div className={classes.Section}>
          <div
            className={[classes.SectionOne, classes.ContainerBackground].join(
              " "
            )}
          >
            <div className={classes.LeftShape}>
              <img src={LeftShape} alt="ShapeLeft" />
            </div>
            <div className={classes.RightShape}>
              <img src={RightShape} alt="ShapeLeft" />
            </div>
            <div className={classes.LeftMiddleShape}>
              <img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
            </div>
            <div className={classes.RightMiddleShape}>
              <img src={RightMiddleShape} alt="ShapeRightMiddle" />
            </div>
            <div className={classes.SectionInner}>
              <div className={classes.SectionLeft}>
                <h3 style={{ marginBottom: "5px" }}>
                  Call for all talented online teachers, creatives and crafters!
                </h3>
                <Hidden smDown>
                  <h1 style={{ marginTop: "0", marginBottom: "20px" }}>
                    Upload your video tutorials for <br /> a chance to win
                    $1,000 cash prize
                  </h1>
                </Hidden>
                <Hidden mdUp>
                  <h1 style={{ marginTop: "0", marginBottom: "20px" }}>
                    Upload your video tutorials for a chance to win $1,000 cash
                    prize
                  </h1>
                </Hidden>
                <Link to={"/create-video"}>
                  <Button
                    className={classes.ButtonDark}
                    size="large"
                    // onClick={this.handleClickContest}
                  >
                    ENTER THE CONTEST
                  </Button>
                </Link>
                <p style={{ marginTop: "40px" }}>
                  The contest begins on April 19, 2021 and will last until May
                  19, 2021
                </p>
              </div>
            </div>
            <div
              className={classes.ImageBackground}
              style={{ backgroundImage: "url(" + Background + ")" }}
            ></div>
          </div>
          <div className={classes.SectionTwo} style={{ background: "white" }}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>
                  How to enter the contest to win $1,000
                </h2>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={Step1} alt="step1" />
                  <h2>Upload your video tutorials</h2>
                  <h3>
                    To enter the competition upload 2 video tutorials minimum on
                    any topic you choose. But bear in mind that you can upload
                    as many as you want. Each new video tutorial brings you 5
                    extra points. Your video tutorials can be either free or you
                    can set the price.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={Step2} alt="step2" />
                  <h2>Invite learners to enroll</h2>
                  <h3>
                    Share your videos with your network, friends, family, and
                    colleagues - for every student who joins your class, you get
                    a point. The more students you have - the more points you
                    will get. Keep promoting your video tutorials while the
                    contest lasts to get as many students as possible.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={Step3} alt="step3" />
                  <h2>Win $1,000 cash prize</h2>
                  <h3>
                    <div style={{ paddingBottom: "10px" }}>
                      A teacher with the most points wins, so your goal is to be
                      number 1 on our leaderboard. To make this contest a fair
                      one, you can check the leaderboard on our{" "}
                      <a target="_blank" href="/blog">
                        blog
                      </a>{" "}
                      and social media profiles every Monday.
                    </div>
                    <span style={{ fontWeight: "bold" }}>
                      The winner will be announced on Monday May 24th on our{" "}
                      <a href="/blog" target="_blank">
                        blog
                      </a>{" "}
                      and social media.
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionFour}>
            {/* <div className={classes.ImageBackground} style={{ backgroundImage: 'url(' + BuildingBackground + ')' }} > */}
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>
                  Join for free and upload video tutorials
                </h2>
                <h4 style={{ lineHeight: "28px" }}>
                  The first step to winning a $1,000 prize is to join InfoDepot
                  as a teacher. Registration is free and simple. If you are
                  already an InfoDepot teacher, you can move onto the rules
                  below.
                </h4>
                <Link to={"/create-video"}>
                  <Button
                    className={classes.Button}
                    color="primary"
                    size="large"
                    onClick={this.handleClickContest}
                  >
                    ENTER THE CONTEST, IT'S FREE!
                  </Button>
                </Link>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className={classes.SectionFive}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>Contest rules</h2>
              </div>
              <div className={classes.Row} style={{ marginBottom: "100px" }}>
                <div
                  className={classes.Column}
                  style={{ marginBottom: "20px", flex: "2" }}
                >
                  <div>
                    <h2>1. Video tutorials criteria</h2>
                    <div style={{ paddingBottom: "20px" }}>
                      <h3>
                        To enter the contest upload a minimum of 2 video
                        tutorials. If you are new to online teaching - come up
                        with a topic you want to teach and record a video. It
                        can be crafts, cooking, design, technology or anything
                        you want.
                        <br />
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Regardless of whether you record new video tutorials for
                        this contest or you submit the videos that you have
                        already published elsewhere, your videos should:
                        <br />- be <b>how-to</b> videos
                        <br />
                        - have a clear introduction, main part and conclusion
                        <br />
                        - be around 30 minutes long
                        <br />
                        - not be larger than 2 GB per video
                        <br />
                        - be high quality: 1080p HD, 16:9 aspect ratio,
                        1920x1080
                        <br />
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.Column}
                  style={{ justifyContent: "flex-start", flex: "1" }}
                >
                  <img src={ContestRules} alt="contest rules" />
                </div>
              </div>
              <div className={classes.Row} style={{ marginBottom: "100px" }}>
                <div
                  className={classes.Column}
                  style={{
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                    flex: "2",
                  }}
                >
                  <div>
                    <h2>2. Inappropriate content is not allowed</h2>
                    <h3>
                      Our goal is to help talented and creative online teachers,
                      which is why we have a policy that prohibits dangerous,
                      derogatory, explicit, offensive or inappropriate content
                      on our platform. In addition to this, we reserve the right
                      to withdraw videos that are inadequately recorded
                      (blurred, low quality, misleading, etc.).
                    </h3>
                  </div>
                </div>
                <div
                  className={classes.Column}
                  style={{ justifyContent: "flex-start", flex: "1" }}
                >
                  <img src={InappropriateContent} alt="InappropriateContent" />
                </div>
              </div>
              <div className={classes.Row} style={{ marginBottom: "100px" }}>
                <div
                  className={classes.Column}
                  style={{
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                    flex: "2",
                  }}
                >
                  <div>
                    <h2>3. Calculate your points</h2>
                    <div>
                      <h3 style={{ marginBottom: "20px" }}>
                        <b>Each video brings 5 points</b>, meaning that every
                        participant gets 10 points automatically by uploading 2
                        required videos. Interested in getting extra points?
                        Don’t worry - you can upload as many quality teaching
                        videos as you like to get 5 more points per each.
                      </h3>
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "20px" }}>
                        <b>Every student enrolled brings 1 point</b>. Your goal
                        is to get as many points as possible to win the
                        competition, so make sure to share your video tutorials
                        with your network and invite them to enroll to get
                        maximum points.
                      </h3>
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "20px" }}>
                        <b>Sum your points</b>. By summing the number of points
                        you have for uploaded videos and the total number of
                        students enrolled in all your tutorials, you will get
                        your total score at any given point.
                      </h3>
                      <p>
                        You can find the exact number of students enrolled by
                        taking a look at your Transaction history.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.Column}
                  style={{
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                    flex: "1",
                  }}
                >
                  {/* <img src={SetAvailabilityGraph} alt='SetAvailabilityGraph'/> */}
                  <div
                    style={{
                      width: "100%",
                      height: "370px",
                      backgroundColor: "#e2ecf9",
                      backgroundImage:
                        "linear-gradient(rgb(128 165 208 / 30%) 0%, rgb(128 165 208 / 30%) 6%, rgb(117 200 159 / 30%) 99%, rgb(117 200 159 / 30%) 100%)",
                    }}
                  >
                    <div style={{ position: "relative", padding: "40px" }}>
                      <h3
                        style={{ color: "#73c29a", textTransform: "uppercase" }}
                      >
                        <b>Example:</b>
                      </h3>
                      <br />
                      <p>
                        You upload 3 video tutorials:
                        <br />
                        You get 15 points.
                      </p>
                      <br />

                      <p>
                        100 students in total enroll in your tutorials:
                        <br />
                        You get 100 points.
                      </p>
                      <br />

                      <p style={{ fontWeight: "bold" }}>
                        Your score on the leaderboard:
                        <br />
                        <span style={{ fontSize: "18px" }}>115 points</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.Row}>
                <div
                  className={classes.Column}
                  style={{
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                    flex: "2",
                  }}
                >
                  <div>
                    <h2>4. Keep track of the leaderboard</h2>
                    <h3>
                      The InfoDepot contest is a transparent and fair one. Every
                      Monday an updated{" "}
                      <a href="/blog" target="_blank">
                        blog
                      </a>{" "}
                      post on our{" "}
                      <a href="/blog" target="_blank">
                        blog
                      </a>{" "}
                      will be published which contains the leaderboard. In this
                      way every participant will know exactly how many points
                      the current leader has and how many points they have to
                      get to be winners. We will also post this information on
                      our social media.
                    </h3>
                  </div>
                </div>
                <div className={classes.Column} style={{ flex: "1" }}>
                  <img src={KeepTrack} alt="keepTrack" />
                </div>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column}>
                  <Link to={"/create-video"}>
                    <Button
                      className={classes.Button}
                      color="primary"
                      size="large"
                      onClick={this.handleClickContest}
                    >
                      UPLOAD VIDEO TUTORIALS
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionEight}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>
                  Frequently Asked Questions
                </h2>
                <div className={classes.Questions}>
                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"When does the contest begin and when it ends?"}
                  >
                    The competition begins on April 19, 2021 and ends on May 19,
                    2021. The video tutorials should be uploaded during this
                    period to enter the contest and to collect points to win
                    $1,000!
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "Can I register before the contest or should I wait for it to begin?"
                    }
                  >
                    You can register and set up your teacher profile as soon as
                    you like. This will give you a chance to explore the
                    platform and to get ready for the contest.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={" I am already registered - can I participate?"}
                  >
                    Yes you can. Even if you already teach on our platform, you
                    too can participate. You just need to upload at least 2 new
                    video tutorials once the competition begins on April 19,
                    2021 and you are in the running for the grand prize!
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "How many video tutorials should I upload to enter the contest?"
                    }
                  >
                    You should upload at least 2 videos to enter the contest,
                    but you can upload more if you want to get extra points.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "How many points do I get for every video I upload?"
                    }
                  >
                    You get 5 points for each video you upload, meaning that by
                    uploading 2 videos to enter the competition, you will
                    already have 10 points. The more videos you upload - the
                    more points you will get!
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      " Is there a limit on the number of video tutorials I can upload?"
                    }
                  >
                    No there is not - you can upload as many classes as you want
                    to. Just make sure that they are uploaded between April 19
                    until May 19, 2021. The more video tutorials you upload, the
                    more points you will get.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "Are video tutorials the only way to get points? Can students help as well?"
                    }
                  >
                    Yes - students are also a part of the contest. The total
                    number of points is calculated based on the number of points
                    you have based on the video tutorials and the number of
                    students enrolled. By summing the points - you get a total
                    score and you get ranked on the leaderboard.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "How many points each enrolled student gets me?"
                    }
                  >
                    Each student enrolled brings you 1 point. The more students
                    you have - the more points you will get. This is why it is
                    important to share your video tutorials with your network so
                    they can support you and sign up for your classes.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      " How are the points calculated? How many points do I need to win?"
                    }
                  >
                    Each video you upload gets you 5 points. Each student
                    enrolled gets you 1 point. The more students and videos you
                    have - the more points you will get. By uploading 2 video
                    tutorials to enter the competition, you automatically get 10
                    points. From that point on it is all calculated based on the
                    number of points you get by having students enroll and
                    uploading extra videos. The teacher who has the highest
                    number of points wins.
                  </IDExpansionPanel>
                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "Can I share my tutorials to get more students?"
                    }
                  >
                    Yes you can! By sharing your video tutorials with your
                    family, friends or your network, you will reach more people
                    and have more students. And by having more students you will
                    get more points as each enrolled student gets you 1 point.
                  </IDExpansionPanel>
                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"Can I follow the leaderboard?"}
                  >
                    Yes, our aim is to make this content as transparent as
                    possible. This means that there will be a{" "}
                    <a href="/blog" target="_blank">
                      blog
                    </a>{" "}
                    post every Monday on our{" "}
                    <a href="/blog" target="_blank">
                      blog
                    </a>{" "}
                    with the current leaderboard. In addition to this, we will
                    publish the results on our social media, so make sure to
                    follow us to know exactly how many points you need to become
                    a winner!
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"When will the winner be announced?"}
                  >
                    The winner will be announced on May 24 on our blog as well
                    as on our social media. In the meantime, you can follow the
                    leaderboard to know whether you need to share your classes
                    with more people or whether you need to upload another video
                    tutorial to get extra points.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "How will the winner get $1,000? Is it possible to use Paypal?"
                    }
                  >
                    Once the winner is announced, we will get in touch with them
                    to work out the details when it comes to sending over the
                    prize. The prize can be transferred using PayPal or any
                    other method the winner finds suitable and it is something
                    we will discuss with them as soon the winner is announced.
                  </IDExpansionPanel>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionNine}>
            <div
              className={classes.ImageBackground}
              style={{ backgroundImage: "url(" + JoinTeaching + ")" }}
            ></div>
            <div className={classes.Text}>
              <h1>
                Your expertise is valuable.
                <br />
                Your knowledge is worth sharing.
              </h1>
              <Link to={"/create-video"}>
                <Button
                  className={classes.Button}
                  color="primary"
                  size="large"
                  onClick={this.handleClickContest}
                >
                  UPLOAD VIDEO TUTORIAL
                </Button>
              </Link>
              <div className={classes.LeftShape}>
                <img src={LeftShape} alt="ShapeLeft" />
              </div>
              <div className={classes.RightShape}>
                <img src={RightShape} alt="ShapeLeft" />
              </div>
              <div className={classes.LeftMiddleShape}>
                <img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
              </div>
              <div className={classes.RightMiddleShape}>
                <img src={RightMiddleShape} alt="ShapeRightMiddle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateLoggedUser: (userData) => dispatch(actions.getLoggedUser(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContestPage);
