import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import clone from 'lodash.clonedeep'
import ReactSVG from 'react-svg'

import classes from './VideoComments.module.scss'
import CommentList from './CommentsList'
import * as videoService from '../../services/video/videoService'
import Button from '../../components/UI/Button/Button'
import IconComments from './../../assets/images/icons/comments_icon.svg'

const loaderRender = (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1rem'
    }}
  >
    <CircularProgress />
  </div>
)

class VideoComments extends Component {
  state = {
    loading: true,
    totalComments: 0,
    comments: null
  }

  async componentDidMount () {
    let commentsUriBased =
      this.props.history.location.search === '?commentId='
        ? ''
        : 'limit=3&direction=desc'

    console.log('Comment based', commentsUriBased)

    let comments = await this.getComments(commentsUriBased)

    this.setState({
      comments: comments.reverse(),
      loading: false,
      totalComments: comments.length ? comments[0]._total : 0
    })
  }

  getComments = query => {
    return videoService
      .getComments(this.props.token, this.props.video.slug, query)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  handleOnPostSuccess = (commentData, commentId) => {
    console.log('handleOnPostSuccess', commentData, commentId)
    let commentsCopy = clone(this.state.comments)
    // if there is no comment id it's a regular comment so just push it, elsewhere it's a reply so we have to find the proper comment to add the new data
    if (!commentId) {
      commentsCopy.push(commentData)
      this.setState(prevState => ({
        totalComments: prevState.totalComments + 1
      }))
    } else {
      const currentComment = commentsCopy.filter(
        comment => comment.id === commentId
      )[0]
      console.log('adding new reply to', currentComment)
      const newReply = { ...commentData.reply, _total: commentData.total }
      currentComment.replies.push(newReply)
    }
    this.setState({ comments: commentsCopy })
  }

  handleShowAllComments = async () => {
    console.log('Show all')
    this.setState({ loading: true })

    const newComments = await this.getComments(
      `limit=${this.state.totalComments}`
    )
    // async await new comments update
    this.setState({
      comments: newComments,
      totalComments: newComments[0]._total,
      loading: false
    })
  }

  handleShowAllReplies = commentId => {
    const commentsCopy = clone(this.state.comments)
    const currentComment = commentsCopy.filter(
      comment => comment.id === commentId
    )[0]

    // @TODO take the last reply as it is the most recent one if user has posted before clicking ShowAll button
    return videoService
      .getReplies(
        this.props.token,
        commentId,
        `limit=${currentComment.replies[0]._total}`
      )
      .then(response => {
        currentComment.replies = response.data
        this.setState({ comments: commentsCopy })
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  render () {
    console.log('Video comments', this.props)

    const { loading, comments, totalComments } = this.state
    const { video, user } = this.props
    const isAuthor = (user && user.username) === video.author.username

    const NoCommentIcon = message => (
      <div className={classes.NoComments}>
        <ReactSVG className={classes.IconComments} src={IconComments} />
        <p>
          No comments yet.
          <br />
          {message}
        </p>
      </div>
    )

    const noCommentsRender = () => {
      const message = isAuthor
        ? `Be the first to comment on this class.`
        : `Do you have a question for the teacher? Be the first to comment on this class.`
      return (
        <React.Fragment>
          {(isAuthor || video.purchased) && NoCommentIcon(message)}

          {commentsListRender()}
        </React.Fragment>
      )
    }

    const purchaseVideoRender = () => {
      const messageText =
        comments && comments.length
          ? `Please purchase this class to join the conversation`
          : `We only accept comments from users who enrolled in the class.`
      return (
        <React.Fragment>
          <div className={classes.PurchaseMessage}>
            <p>{messageText}</p>
            <Button color='secondary' onClick={() => this.props.showBuyModal()}>
              Buy Video Class
            </Button>
          </div>
          {comments && comments.length ? null : NoCommentIcon()}
          {/* Bug variable  */}
          {/* {comments} */}
        </React.Fragment>
      )
    }

    const commentsListRender = () => (
      <React.Fragment>
        {!isAuthor && !video.purchased && purchaseVideoRender()}
        <CommentList
          purchased={video.purchased || isAuthor}
          totalComments={totalComments}
          showBuyModal={() => this.props.showBuyModal()}
          showAllComments={() => this.handleShowAllComments()}
          showAllReplies={commentId => this.handleShowAllReplies(commentId)}
          srcData={video}
          comments={comments}
          onPostSuccess={(commentData, commentId) =>
            this.handleOnPostSuccess(commentData, commentId)
          }
        />
      </React.Fragment>
    )

    const mainRender = () =>
      comments.length ? commentsListRender() : noCommentsRender()

    return !loading ? mainRender() : loaderRender
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  }
}

export default withRouter(connect(mapStateToProps)(VideoComments))
