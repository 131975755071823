import React from 'react';
import { Link } from 'react-router-dom';

import classes from './SuccessfulPayout.module.scss';
import Card from '../../../components/UI/Card/Card';
import Button from '../../../components/UI/Button/Button';
import IconPayout from '../../../assets/images/icons/success_payout_icon.svg';

class SuccessfulPayout extends React.PureComponent {
	render() {
		return (
			<div className={classes.Container}>
				<h1>Successful payout request</h1>
				<Card className={classes.Card}>
					<img
						className={classes.IconCoins}
						src={IconPayout}
						alt="dollars with checkmark"
					/>
					<p className={classes.Message}>
            InfoDepot has sent <span className={classes.Amount}>${this.props.history.location.state.toFixed(2)}</span> to your PayPal account.
            Please allow up to 3 business days for PayPal to process the payment.
          </p>
					<div className={classes.ButtonsWrap}>
						<Link to="/account/dashboard/videos">
							<Button size="large">GO TO TEACHER DASHBOARD</Button>
						</Link>
					</div>
				</Card>
			</div>
		);
	}
}


export default SuccessfulPayout;
