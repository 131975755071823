import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

import classes from './SignUp.module.scss';
import IconStudent from '../../assets/images/icons/Student icon.svg';
import IconTeacher from '../../assets/images/icons/teacher icon.svg';
import Button from '../../components/UI/Button/Button';
import { setReturnToUrlToken } from '../../utils/redirectTo';

class SignUp extends Component {
    componentDidMount () {
        window.scrollTo(0, 0);
    
        //Meta tags
        let metatitle = document.getElementsByName('title')[0];
        let metaDecription = document.getElementsByName('description')[0];

        document.title = 'Join InfoDepot online learning platform. Register for free | InfoDepot';
        metatitle.setAttribute('content','Join InfoDepot online learning platform. Register for free | InfoDepot');
        metaDecription.setAttribute('content', 'Join InfoDepot for free and gain access to a variety of online classes. Learn about business, health, language, tech, lifestyle and many other topics.' )
    }

    render() {
        const setReturningPath = (props) => {
            setReturnToUrlToken(props.onAuthReturnToUrl);
        };

        const signUpLinkOut = (
            <div style={{ marginTop: '30px', fontWeight:'bold' }}>
                <span>Already have an account?</span>{' '}
                <Link onClick={setReturningPath} to={'/auth'}>
                    Sign in
                </Link>
            </div>
        )

        return (
            <div className={classes.Container}>
                <div className={classes.UserTypeWrap}>
                    <div>
                        <div className={classes.SignUpStudent}>
                            <Link onClick={setReturningPath} to="/sign-up-student">
                                <img className={classes.IconUserType} src={IconStudent} alt="student" />
                            </Link>
                            <h2>I want to</h2>
                            <h1>Learn new skills</h1>
                            <p>Reinvent yourself. Join a community for lifelong learning.</p>
                            <Link onClick={setReturningPath} to="/sign-up-student">
                                <Button size="large">
                                    START LEARNING
                                </Button>
                            </Link>
                            {signUpLinkOut}
                        </div>
                    </div>
                    <div>
                        <div className={classes.SignUpTeacher}>
                            <Link onClick={setReturningPath} to="/pricing-plans">
                                <img className={classes.IconUserType} src={IconTeacher} alt="teacher" />
                            </Link>
                            <h2>I want to</h2>
                            <h1>teach & earn</h1>
                            <p>Share your knowledge and become an infopreneur.</p>
                            <Link onClick={setReturningPath} to="/pricing-plans">
                                <Button color="secondary" size="large">
                                START TEACHING
                                </Button>
                            </Link>
                            {signUpLinkOut}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp;