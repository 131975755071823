import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classes from './PromptToUpgradeSubscriptionPermanent.module.scss';
import Button from '../../UI/Button/Button';
import { Checkbox } from '../../UI/Form/Form';
import clone from 'lodash.clonedeep';
import { acknowledgePlanDowngraded } from '../../../services/user/userService';
import * as actions from './../../../store/actions/indexActions';

const CHECKBOX_VALUE = 'disablePermanentPrompt';

function PromptToUpgradeSubscriptionPermanent({ user, token, snackbarAdd }) {
  const [loading, setLoading] = useState(false);
  const [showPermanentPrompt, setShowPermanentPrompt] = useState(false);
  const [checkboxConfig, setCheckboxConfig] = useState({
    id: CHECKBOX_VALUE,
    type: 'checkbox',
    value: '',
    color: 'secondary',
  });

  const handleOnClick = async (e) => {
    if (checkboxConfig.value === CHECKBOX_VALUE) {
      try {
        setLoading(true);
        const response = await acknowledgePlanDowngraded(user, token);
        setShowPermanentPrompt(false);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
        console.log(error.response);
        setLoading(false);
        snackbarAdd({
          message: 'Something went wrong, please try again.',
          timeout: 6000,
          type: 'error',
        });
      }
    } else {
      sessionStorage.setItem('PromptToUpgradeSubscriptionPermanent', true);
      setShowPermanentPrompt(false);
    }
  };

  useEffect(() => {
    const sessionPermanentPromptValue = sessionStorage.getItem(
      'PromptToUpgradeSubscriptionPermanent'
    );
    setShowPermanentPrompt(!sessionPermanentPromptValue);
  }, []);

  const checkboxChangeHandler = (e) => {
    setCheckboxConfig((prevState) => {
      const checkboxConfigCopy = clone(prevState);
      checkboxConfigCopy.value =
        prevState.value === CHECKBOX_VALUE ? '' : CHECKBOX_VALUE;
      return checkboxConfigCopy;
    });
  };

  return (
    <>
      {user && user.planDowngraded && showPermanentPrompt && (
        <div className={classes.Container}>
          <div className={classes.Subtitle}>
            Your subscription expired, so some of{' '}
            <Link className={classes.Logo} to={'/account/dashboard'}>
              your offerings
            </Link>{' '}
            might be hidden per your current plan limits. To make your classes
            and call lines available to learners,{' '}
            <Link className={classes.Logo} to={'/pricing-plans'}>
              Upgrade your account
            </Link>
            .
          </div>
          <div style={{ textAlign: 'center' }}>
            {/* Checkbox - to be checked "id" needs to be equal to the "value" inside config part */}
            <Checkbox
              config={checkboxConfig}
              onChange={(e) => checkboxChangeHandler(e)}
            />
            <span style={{ marginLeft: '-20px', fontSize: '13px' }}>
              Don't show this message anymore
            </span>
          </div>
          <div className={classes.CTA}>
            <Button
              size="small"
              className={classes.ButtonBlack}
              onClick={handleOnClick}
              loading={loading}
            >
              Got it
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromptToUpgradeSubscriptionPermanent);
