import React, { Component } from 'react';
import clone from 'lodash.clonedeep';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as validator from '../../../../utils/formValidator';
import Button from '../../../../components/UI/Button/Button';
import { Form, Input } from '../../../../components/UI/Form/Form';
import * as userService from '../../../../services/user/userService';
import { errorParser } from '../../../../utils/errorParser';
import * as actions from './../../../../store/actions/indexActions';
import classes from './CouponCode.module.scss';
import Divider from '../../../../components/UI/Divider/Divider';
import ReactSVG from 'react-svg';
import IconPercent from '../../../../assets/images/icons/percent.svg';
import Switch from '../../../../components/UI/Switch/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PLAN_ID_PRO } from '../../../../utils/consts';
import PromptToUpgradeSubscription from '../../../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';

class CouponCode extends Component {
  state = (() => {
    // const user = this.props.user;
    return {
      loadingTabCouponCode: true,
      loading: false,
      formIsValid: false,
      formControls: {
        code: {
          id: 'code',
          type: 'text',
          placeholder: 'Code',
          placeholderStatic: 'Enter code',
          shrink: true,
          value: '',
          validation: { required: true, minLength: 4, maxLength: 20 },
          error: '',
          valid: false,
          touched: false,
          infolabel:
            'This is the code your student has to enter at checkout to get the discount',
        },
        discount: {
          id: 'discount',
          type: 'text',
          placeholder: 'Discount',
          placeholderStatic: 'Enter percentage',
          shrink: true,
          value: '',
          validation: { required: true, isNumeric: true, isPercentage: true },
          error: '',
          valid: false,
          touched: false,
          adornment: {
            position: 'end',
            icon: () => (
              <ReactSVG
                src={IconPercent}
                style={{ marginLeft: '10px' }}
                className={classes.InputIcon}
              />
            ),
          },
          infolabel:
            'The coupon will apply 0% off discount on your video class price',
        },
      },
      couponActivity: false,
      storeCouponApiType: 'post',
    };
  })();

  async componentDidMount() {
    let couponData = await this.getCouponData();

    const formControlsCopy = clone(this.state.formControls);
    const ctrlCode = formControlsCopy['code'];
    ctrlCode.value = couponData.code;
    ctrlCode.touched = couponData.code;
    const ctrlDiscount = formControlsCopy['discount'];
    ctrlDiscount.value = couponData.discount ? String(couponData.discount) : '';
    ctrlDiscount.touched = couponData.discount
      ? String(couponData.discount)
      : '';
    ctrlDiscount.infolabel = this.updateDiscountInfolabel(
      couponData.discount ? couponData.discount : 0
    );

    this.setState({
      loadingTabCouponCode: false,
      formControls: formControlsCopy,
      couponActivity: couponData.active,
      storeCouponApiType: couponData ? 'patch' : 'post',
    });
  }

  getCouponData() {
    return userService
      .getMineCoupon(this.props.token)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  }

  updateDiscountInfolabel(discount) {
    return `The coupon will apply ${
      discount ? discount : 0
    }% off discount on your video class price`;
  }

  // clears the errors and 'dirty' the input on change. Errors will be checked on blur
  clearInput = (inpt) => {
    inpt.touched = true;
    inpt.error = '';
  };

  inputChangeHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    ctrl.value = e.target.value;
    this.clearInput(ctrl);

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  // checks validity of single input
  getInputError = (input) => {
    return validator.validateInput(input);
  };

  inputBlurHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    ctrl.error = this.getInputError(ctrl);

    if (controlName === 'discount') {
      ctrl.infolabel = this.updateDiscountInfolabel(ctrl.value);
    }

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  handleChangeCouponActivity = () => {
    const formControlsCopy = clone(this.state.formControls);

    this.setState((prevState) => {
      if (
        prevState.couponActivity &&
        this.state.storeCouponApiType === 'patch'
      ) {
        this.storeCouponHandler(false, this.state.storeCouponApiType);
      } else if (
        !prevState.couponActivity &&
        this.state.storeCouponApiType === 'patch'
      ) {
        this.setState({
          formIsValid: validator.formIsValid(formControlsCopy),
        });
      }
      return { couponActivity: !prevState.couponActivity };
    });
  };

  apiErrorHandler = (error) => {
    console.log(error);
    const apiErr = errorParser(error.response.data.errors);
    console.log('apiErr:');
    console.log(apiErr);

    const cloneForm = clone(this.state.formControls);

    // config object to be used by checkFormApiErrors
    const errorStateConfig = {
      formCodeError: 'code',
      formDiscountError: 'discount',
    };

    // checking form errors for matching pairs defined in the errorStateConfig
    validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

    this.setState({
      formIsValid: false,
      formControls: cloneForm,
      loading: false,
    });
  };

  storeCouponHandler = (activeValue, apiType) => {
    userService
      .storeCoupon(
        this.prepareSubmitData(activeValue),
        this.props.token,
        apiType
      )
      .then((response) => {
        const formControlsCopy = clone(this.state.formControls);
        const ctrlCode = formControlsCopy['code'];
        this.clearInput(ctrlCode);
        const ctrlDiscount = formControlsCopy['discount'];
        ctrlDiscount.infolabel = this.updateDiscountInfolabel(
          ctrlDiscount.value
        );
        this.clearInput(ctrlDiscount);
        this.setState({
          loading: false,
          formIsValid: false,
          formControls: formControlsCopy,
          storeCouponApiType: 'patch',
        });
        this.props.snackbarAdd({
          message: activeValue
            ? 'Coupon code successfully updated.'
            : 'Coupon code successfully deactivated.',
        });
      })
      .catch((error) => {
        this.apiErrorHandler(error);
      });
  };

  prepareSubmitData(activeValue) {
    const form = this.state.formControls;

    if (!activeValue) {
      return {
        active: false,
      };
    }

    return {
      code: form.code.value && form.code.value.trim(),
      discount: form.discount.value && form.discount.value.trim(),
      active: activeValue,
    };
  }

  submit(e) {
    e.preventDefault();
    if (this.state.formIsValid) {
      this.setState({ loading: true });
      this.storeCouponHandler(true, this.state.storeCouponApiType);
    }
  }

  render() {
    const loaderRender = <CircularProgress className={classes.Loader} />;

    return (
      <div className={classes.Section}>
        <h1 className={classes.SectionHeadline}>Coupon Code</h1>
        {this.props.user.plan === PLAN_ID_PRO ? (
          <>
            <div className={classes.SectionHelpline}>
              Set and share your coupon code with people that you want to offer
              a discount to for your video classes. They can apply the code at
              checkout when purchasing your video class.
            </div>
            {this.state.loadingTabCouponCode ? (
              loaderRender
            ) : (
              <>
                <div className={classes.SwitchWrap}>
                  Coupon code activation
                  <Switch
                    disabled={this.state.loading}
                    checked={this.state.couponActivity}
                    value={this.state.couponActivity}
                    switchChanged={this.handleChangeCouponActivity}
                  />
                </div>
                <div
                  className={[
                    classes.FormWrapper,
                    this.state.couponActivity
                      ? ''
                      : classes.FormWrapperDisabled,
                  ].join(' ')}
                >
                  <Form
                    onSubmit={(e) => this.submit(e)}
                    className={classes.Form}
                  >
                    <Input
                      config={this.state.formControls.code}
                      onChange={(e) => this.inputChangeHandler(e, 'code')}
                      onBlur={(e) => this.inputBlurHandler(e, 'code')}
                      className={[
                        classes.InputWhite,
                        classes.TextUppercase,
                      ].join(' ')}
                    />
                    <Divider style={{ margin: '10px 0 0' }} />
                    <Input
                      config={this.state.formControls.discount}
                      onChange={(e) => this.inputChangeHandler(e, 'discount')}
                      onBlur={(e) => this.inputBlurHandler(e, 'discount')}
                      className={classes.InputWhite}
                    />
                    <div
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        size="large"
                        type="submit"
                        disabled={this.state.loading || !this.state.formIsValid}
                        loading={this.state.loading}
                        className={classes.BtnSubmit}
                      >
                        SAVE CHANGES
                      </Button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </>
        ) : (
          <PromptToUpgradeSubscription from="coupon" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
    updateLoggedUser: () => dispatch(actions.getLoggedUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CouponCode));
