import React, { Component } from 'react';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactSVG from 'react-svg';
import Collapse from '@material-ui/core/Collapse';

import classes from './BrowseClasses.module.scss';
import CollapsableList from '../../components/UI/CollapsableList/CollapsableList';
import Card from '../../components/UI/Card/Card';
import Price from './Price/Price';
import * as videoService from '../../services/video/videoService';
import * as callService from '../../services/liveCall/liveCallService';
import * as configurationService from '../../services/configuration/configurationService';
import * as screen from '../../utils/common.js';
import clone from 'lodash.clonedeep';
import Divider from '../../components/UI/Divider/Divider';
import GridList from '../../components/UI/GridList/GridList';
import ItemCard from '../../components/ItemCard/ItemCard';
import { Input } from '../../components/UI/Form/Form';
import IconSearch from '../../assets/images/icons/search_icon_blue.svg';
import IconVideo from '../../assets/images/icons/video class icon.svg';
import IconVideoSlash from '../../assets/images/icons/video-slash-solid.svg';
import IconVideoBasic from '../../assets/images/icons/video-solid.svg';
import IconLiveCalls from '../../assets/images/icons/live_call_icon.svg';
import IconMute from './../../assets/images/icons/live_call_offline_icon.svg';
import IconFilter from '../../assets/images/icons/filter_button_icon.svg';
import RateStars from '../../components/Ratings/RateStars/RateStars';
import TabsBubble from '../../components/UI/Tabs/TabsBubble/TabsBubble';
import Tabs from "../../components/UI/Tabs/Tabs";
import BuyVideoModal from '../../components/BuyVideoModal/BuyVideoModal';
import LiveCallModal from '../../components/LiveCallModal/LiveCallModal';
import StartVideoCallModule from '../../components/StartVideoCallModal/StartVideoCallModal';
import SignUpModal from '../../components/SignUpModal/SignUpModal';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import Pagination from '../../components/UI/Pagination/Pagination';
import TwilioVideo from '../../components/TwilioVideo/TwilioVideo';
import RateModal from '../../components/Ratings/RateModal/RateModal';

const intervalAutorefresh = (fn) => setInterval(fn, 5000);

let paginationConf = {
	currentPage: 1,
	pageLimit: 20,
	total: null
};

// const tabsList = () => {
// 	return [
// 		{
// 			label: (
// 				<React.Fragment>
// 					<ReactSVG svgClassName={classes.NavIcon} src={IconVideo} />Videos
// 				</React.Fragment>
// 			),
// 			value: 'videos',
// 			icon: IconVideo
// 		},
// 		{
// 			label: (
// 				<React.Fragment>
// 					<ReactSVG svgClassName={classes.NavIcon} src={IconLiveCalls} />Live Tutoring
// 					Calls
// 				</React.Fragment>
// 			),
// 			value: 'calls',
// 			icon: IconLiveCalls
// 		}
// 	];
// };
const tabsList = () => {
	return [
		{
			label: 'Videos',
			value: 'videos',
		},
		{
			label: 'Live Calls',
			value: 'calls',
		}
	];
};

const _selectionAll = {
	name: 'All',
	slug: 'all'
};
const rateFilterValues = [
	{ val: 4.5, label: '4.5 & up' },
	{ val: 3.5, label: '3.5 & up' },
	{ val: 2.5, label: '2.5 & up' },
	{ val: 1.5, label: '1.5 & up' },
	{ val: 0, label: 'No rating' }
];

class BrowseClasses extends Component {
	state = {
		categories: null,
		levels: null,
		languages: null,
		classesList: null,
		loadingClasses: true,
		priceRange: null,
		priceRangeValue: null,
		activeTab: null,
		roomName: '',
		roomToken: '',
		participient: '',
		videoName: '',
		twilioVideo: false,
		modalRateOpened: false,
		author: '',
		allAvailable: true,

		filters: {
			selectedCategory: null,
			selectedLevel: null,
			selectedRange: null,
			selectedRating: null,
			selectedAvailability: null,
			selectedAvailabilityVideoCall: null,
			search: null,
			selectedLanguage: null
		},

		modalCallType: '',
		modalVideoOpened: false,
		modalVideoCall: false,
		modalFiltersOpened: false,
		modalCallOpened: false,
		modalSignUpOpened: false,
		selectedVideo: null,
		selectedCall: null,
		participientUrlImage: '',
		videoSlug: '',

		searchControl: {
			id: 'search-input',
			type: 'text',
			placeholderStatic: 'Search',
			adornment: {
				position: 'start',
				icon: () => <ReactSVG src={IconSearch} svgClassName={classes.IconSearchInput} />
			},
			value: '',
			validation: {}
		},
		flipCard: null
		// screenWidth: null
	};

	componentDidMount() {
		this.setActiveTab();
		setTimeout(() => this.updateFilterList());
		window.scrollTo(0, 0);


		//Meta tags
		let metatitle = document.getElementsByName('title')[0];
		let metaDecription = document.getElementsByName('description')[0];
		
		document.title = 'Start learning today. Browse popular video classes and tutorials.';
		metatitle.setAttribute('content', 'Start learning today. Browse popular video classes and tutorials.');
		metaDecription.setAttribute('content', 'Kick-start your learning journey with a selection of the best teachers and classes on InfoDepot. Join now for free and choose the right class for you.' )
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters)) {
			this.setState({ classesList: null });
			setTimeout(() => this.getClasses());
		}
	}

	componentWillUnmount() {
		this.clearStatusRefreshInterval();
		this.clearPaginationConf();
	}

	clearStatusRefreshInterval = () => {
		clearInterval(this.autoRefreshStatus);
	};

	onBrowseClick = (name) => {
		this.setState({ activeTab: name });
	};

	//close twilio video
	closeTwilioVideo = () => {
		this.setState({
		  twilioVideo: false
		});
	}

	closeModalRating = () => {
		this.setState({
			modalRateOpened: false
		})
	}

	setActiveTab = () => {
		const classTypeParam = this.props.match.params.classType;
		// check if it is a valid type comming from the url if it isn't route to first option
		const tabsNameList = tabsList().map((tab) => {
			return tab.value;
		});

		if (tabsNameList.includes(classTypeParam)) {
			this.setState({ activeTab: classTypeParam });
		} else {
			const url = '/browse-infodepot/' + tabsNameList[0];
			this.props.history.replace(url);
		}
	};

	updateFilterList = async () => {
		if (this.state.activeTab) {
			this.getInitalData(this.state.activeTab).then(() => {
				this.clearFilters();
			});
		}
	};

	clearFilters = () => {
		const categoriesCopy = clone(this.state.categories);
		const levelsCopy = clone(this.state.levels);
		const languagesCopy = clone(this.state.languages);
		this.clearRange();

		// clear the filters selection
		const emptyFilters = {
			selectedCategory: _selectionAll,
			selectedLevel: _selectionAll,
			selectedRange: null,
			selectedRating: 'all',
			selectedAvailability: null,
			selectedAvailabilityVideoCall: null,
			selectedLanguage: _selectionAll
		};

		// set collapsable lists to default
		this.setActiveListItem(levelsCopy, 'all');
		this.setActiveListItem(categoriesCopy, 'all');
		this.setActiveListItem(languagesCopy, 'all');

		this.setState({
			filters: emptyFilters,
			categories: categoriesCopy,
			levels: levelsCopy,
			languages: languagesCopy
		});
	};

	getClasses = (offset = 0) => {
		this.setState({ loadingClasses: true });
		let serviceApi;
		if (this.state.activeTab === 'calls') {
			serviceApi = () => {
				// disable the interval before calling it again so we don't stack intervals after multiple list calls
				this.clearStatusRefreshInterval();
				// autorefresh call statuses based on interval
				setTimeout(() => {
					this.autoRefreshStatus = intervalAutorefresh(this.getCallsStatus);
				});

				return callService.getAllBrowseCalls(
					this.props.token,
					this.getFilterQueryString() +
						`&limit=${paginationConf.pageLimit}&offset=${offset}`
				);
			};
		} else {
			serviceApi = () => {
				// disable the status interval if it was on
				this.clearStatusRefreshInterval();

				return videoService.getAllVideos(
					this.props.token,
					this.getFilterQueryString() +
						`&limit=${paginationConf.pageLimit}&offset=${offset}`
				);
			};
		}
		serviceApi()
			.then((response) => {
				// set total limit for pagination
				paginationConf.total = response.data.data.length
					? response.data.meta.total
					: null;

				// calculate price range based on the limits incoming from the api
				const min = response.data.meta ? response.data.meta.minPrice : 1;
				const max = response.data.meta ? response.data.meta.maxPrice : 50;
				const isRangeValid = min !== max;
				const priceRange = {
					min: isRangeValid ? min : Math.max(Math.floor(min - 1), 1),
					max: isRangeValid ? max : Math.ceil(max + 1)
				};
				let copyPriceValue = this.setState.priceRangeValue;

				this.setState((state) => {
					const loadedClasses = state.classesList || [];
					let responseClasses = response.data.data || [];

					// check if by accident same classes where fetched from the server
					responseClasses = responseClasses.filter((newClass) => {
						const matchedIdList = loadedClasses.filter(
							(oldClass) => oldClass.slug === newClass.slug
						);
						return !matchedIdList.length;
					});

					return {
						classesList: [...loadedClasses, ...responseClasses],
						loadingClasses: false,
						priceRange: state.priceRange ? state.priceRange : priceRange,
						priceRangeValue: state.priceRangeValue ? state.priceRangeValue : copyPriceValue
					};
				});
			})
			.catch((error) => {
				this.setState({ loadingClasses: false });
			});
	};

	getCallsStatus = () => {
		if (this.state.classesList && this.state.classesList.length) {
			const slugList = this.state.classesList.map((_class) => _class.slug);
			callService
				.getCallStatusList(slugList.join(','))
				.then((response) => {
					
					this.setState((state) => {
						const callListCopy = state.classesList;

						if (callListCopy && callListCopy.length) {
							callListCopy.forEach((oldCall) => {
								response.data.forEach((freshCall) => {
									if (oldCall.slug === freshCall.slug) {
										oldCall.liveCallStatus = freshCall.liveCallStatus;
										oldCall.videoCallStatus = freshCall.videoCallStatus;
										oldCall.author.liveCallQueuesCount =
											freshCall.author.liveCallQueuesCount;
									}
								});

							});
						}
						//console.log('test browse classes', callListCopy, response.data)

						return { classesList: callListCopy };
					});
				})
				.catch((error) => {
					console.log(error.response);
				});
		}
	};

	getFilterQueryString = () => {
		const cat = this.state.filters.selectedCategory;
		const level = this.state.filters.selectedLevel;
		const range = this.state.filters.selectedRange;
		const rate = this.state.filters.selectedRating;
		const search = this.state.searchControl.value;
		const language = this.state.filters.selectedLanguage;

		// call specific
		const availability = this.state.filters.selectedAvailability;
		const availableVideoCall = this.state.filters.selectedAvailabilityVideoCall;
		const callExclusiveFilters = { available: availability};
		const callExclusiveFiltersVideoCall = { videoAvailable: availableVideoCall};

		let filters = {
			category: cat && cat.slug !== 'all' && cat.slug,
			level: level && level.slug !== 'all' && level.slug,
			language: language && language.slug !== 'all' && language.slug,
			name: search.trim(),
			minPrice: range && range.min,
			maxPrice: range && range.max,
			rating: rate !== 'all' ? String(rate) : null
		};

		// if we are looking at the calls tab include the call filters
		if (this.state.activeTab === 'calls') {
			filters = Object.assign(filters, callExclusiveFilters, callExclusiveFiltersVideoCall);
			// console.log('test a filters', filters);
		}

		let parsedFilters = '';
		Object.keys(filters).forEach((filter) => {
			if (filters[filter]) {
				parsedFilters += `&${filter}=${filters[filter]}`;
			}
		});

		return parsedFilters ? parsedFilters : '';
	};

	getInitalData = async (activeTab) => {
		const categoryType = activeTab.slice(0, -1);
		const categories = await configurationService
			.getCategories(categoryType)
			.then((response) => {
				const formatedCategories = Object.keys(response.data).map(
					(cat) => response.data[cat]
				);
				return formatedCategories;
			})
			.catch((error) => {
				console.log(error);
			});

		const levels = await videoService
			.getLevels('video')
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});

		let languages = null;
        if (activeTab === 'videos') { //Send request only on videos tab
			languages = await videoService
				.getLanguages()
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		}

		//update state async
		this.setState({
			categories: categories,
			levels: levels,
			languages: languages
		});

		return { categories: categories, levels: levels, languages: languages };
	};

	// pass trough list and make only currently clicked item active
	setActiveListItem = (list, slug) => {
		let childSlug = false;

		list &&
			list.forEach((cat) => {
				if (cat.children) {
					cat.children.forEach((subcat) => {
						subcat.active = subcat.slug === slug;
						subcat.expanded = subcat.slug === slug;

						if (subcat.slug === slug) {
							childSlug = true;
						}
					});
				}
				cat.active = cat.slug === slug;

				if (!childSlug) cat.expanded = cat.slug === slug;
			});
	};

	onHandleListClick = (itemData, type) => {
		const listCopy = clone(this.state[type]);
		const filtersCopy = clone(this.state.filters);

		this.setActiveListItem(listCopy, itemData.slug);
		let selectedSlug;
		if (type === 'categories') {
			selectedSlug = 'selectedCategory';
		} else if (type === 'levels') {
			selectedSlug = 'selectedLevel';
		} else {
			selectedSlug = 'selectedLanguage';
		}

		filtersCopy[selectedSlug] = itemData;

		this.setState({
			[type]: listCopy,
			filters: filtersCopy
		});
	};

	onHandleRateClick = (val) => {
		this.setState((prevState) => ({
			filters: {
				...prevState.filters,
				selectedRating: val
			}
		}));
	};

	onHandleSearchChange = (e) => {
		const controlCopy = clone(this.state.searchControl);
		controlCopy.value = e.target.value;

		this.setState({ searchControl: controlCopy });

		this.setState((prevState) => {
			return {
				searchControl: controlCopy,
				filters: {
					...prevState.filters,
					search: controlCopy.value
				}
			};
		});
	};

	onHandleChangeRange = (e, rangeType) => {
		let copyPriceValue = clone(this.state.priceRangeValue);
		if (copyPriceValue) {
			copyPriceValue[rangeType] = e.target.value ? Math.abs(e.target.value) : e.target.value;
		} else {
			copyPriceValue = { min: this.state.priceRange.min, max: this.state.priceRange.max };
		}
		this.setState({ priceRangeValue: copyPriceValue });
	};

	onHandleChangePrice = (val) => {
		this.setState({
			priceRangeValue: val
		});
	};

	onSubmitRange = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				filters: { ...prevState.filters, selectedRange: this.state.priceRangeValue }
			};
		});
	};

	openFiltersModal = () => {
		this.setState({ modalFiltersOpened: true });
	};

	closeFiltersModal = () => {
		this.setState({ modalFiltersOpened: false });
	};

	openVideoModal = (video) => {
		if (this.props.user) {
			this.setState({ modalVideoOpened: true, selectedVideo: video });
		} else {
			this.openSignUpModal('/video/' + video.slug);
		}
	};

	openVideoCallModal = (video) => {
		// if (this.props.user) {
		// 	this.setState({ modalCallVideoOpened: true, selectedVideo: video });
		// } else {
		// 	this.openSignUpModal('/video/' + video.slug);
		// }
		this.setState({ modalVideoCallOpened: true, selectedVideo: video });
	};

	closeVideoCallModal = () => {
		this.setState({ modalVideoCallOpened: false, selectedVideo: null });
	};

	closeVideoModal = () => {
		this.setState({ modalVideoOpened: false, selectedVideo: null });
	};

	openCallModalPhone = (call) => {
		if (this.props.user) {
			this.setState({ modalCallOpened: true, selectedCall: call, modalCallType: 'phone' });
		} else {
			this.openSignUpModal('/call/' + call.slug);
		}
	};

	openCallModalVideo = (call) => {
		if (this.props.user) {
			this.setState({ modalCallOpened: true, selectedCall: call , modalCallType: 'video' });
		} else {
			this.openSignUpModal('/call/' + call.slug);
		}
	};

	closeCallModal = (room , roomToken, participient, videoName,twilioVideo,videoSlug,participientUrlImage, author) => {
		this.setState({ 
			modalCallOpened: false, 
			selectedCall: null, 
			modalVideoCall: false , 
			roomName: room, 
			roomToken: roomToken, 
			participient: participient, 
			videoName: videoName, 
			twilioVideo:twilioVideo,
			participientUrlImage: participientUrlImage,
			videoSlug: videoSlug,
			author: author
		});
	};

	openSignUpModal = (returnToUrl) => {
		this.setState({ modalSignUpOpened: true, returnToUrl });
	};

	closeSignUpModal = () => {
		this.setState({ modalSignUpOpened: false, returnToUrl: null });
	};

	clearPaginationConf = () => {
		paginationConf = {
			...paginationConf,
			total: null,
			currentPage: 1
		};
	};

	clearRange = () => {
		this.setState({
			priceRange: null,
			priceRangeValue: null
		});
	}

	handleTabClick = (val) => {
		this.clearPaginationConf();
		this.clearRange();

		this.props.history.push('/browse-infodepot/' + val);
		this.setState({ activeTab: val, loadingClasses: true, classesList: null });
		this.getInitalData(val).then(() => {
			this.clearFilters();
			setTimeout(() => this.getClasses());
		});
	};

	handleAvailabilityChange = (val) => {
		this.setState((prevState) => ({
			allAvailable: false,
			filters: {
				...prevState.filters,
				selectedAvailability: val,
				selectedAvailabilityVideoCall: null,

			}
		}));
	};

	handleAvailabilityChangeVideoCall = (val) => {
		this.setState((prevState) => ({
			allAvailable: false,
			filters: {
				...prevState.filters,
				selectedAvailability: null,
				selectedAvailabilityVideoCall: val,

			}
		}));
	};

	handleAvailabilityChangeAll = () => {
		this.setState((prevState) => ({
			allAvailable: true,
			filters: {
				...prevState.filters,
				selectedAvailability: null,
				selectedAvailabilityVideoCall: null,

			}
		}));
	};

	handlePaginationchange = (pag) => {
		if (pag.currentPage && pag.currentPage !== paginationConf.currentPage) {
			paginationConf.currentPage = pag.currentPage;
			this.getClasses((pag.currentPage - 1) * pag.pageLimit);
		}
	};

	flipCardShow = (call) => {
		this.setState({
			flipCard: call.slug
		});
	}

	flipCardHide = () => {
		this.setState({
			flipCard: null
		});
	}

	render() {
		const {
			categories,
			levels,
			languages,
			classesList,
			loadingClasses,
			priceRange,
			priceRangeValue,
			selectedVideo,
			selectedCall,
			modalFiltersOpened,
			modalVideoCallOpened,
			modalVideoOpened,
			modalCallOpened,
			modalSignUpOpened,
			filters,
			activeTab,
			returnToUrl,
			flipCard
		} = this.state;

		//console.log(this.props)

		const screenWidth = this.props.windowWidth;
		const { selectedCategory, selectedRating } = filters;
		const loaderRender = <CircularProgress className={classes.VideoLoader} />;
		const dividerRender = <Divider horizontal="true" style={{ margin: '18px 0', backgroundColor: 'transparent' }} />;
		const CallCard = (classData) => (
			<ItemCard
				flipCard={flipCard}
				key={classData.slug}
				itemData={classData}
				type="call"
				onButtonClick={() => 
					this.openCallModalPhone(classData)
				}
				onButtonClickVideo={() => 
					this.openCallModalVideo(classData)
				}
				onMainButtonClick={() => this.flipCardShow(classData)}
				onFlipCloseClick={(e) => this.flipCardHide()}
			/>
		);
		const VideoCard = (classData) => (
			<ItemCard
				key={classData.slug}
				itemData={classData}
				type="video"
				onButtonClick={(e) => this.openVideoModal(classData)}
			/>
		);

		const ClassesList = () =>
			classesList ? (
				<GridList className={classes.GridList}>
					{classesList.map(
						(classData) =>
							activeTab === 'videos' ? VideoCard(classData) : CallCard(classData)
					)}
				</GridList>
			) : (
				noResults
			);

		const noResults = (
			<div className={classes.NoResult}>
				<ReactSVG src={IconSearch} svgClassName={classes.IconSearch} />
				<span>
					There are no results for this search. Please try with some other criteria.
				</span>
			</div>
		);

		const selectedFilters = () => {
			if (selectedCategory) {
				return selectedCategory.parent
					? `${selectedCategory.parent.name}: ${selectedCategory.name}`
					: selectedCategory.name;
			}
		};

		const RateFilter = () => {
			const clickWrap = (child, val) => (
				<div
					key={val}
					val={val}
					className={[
						classes.FilterRating,
						selectedRating === val ? classes['FilterRating--active'] : ''
					].join(' ')}
					onClick={() => this.onHandleRateClick(val)}>
					{child}
				</div>
			);

			const starOptions = rateFilterValues.map((rateOption) =>
				clickWrap(
					<React.Fragment>
						<RateStars rate={rateOption.val} className={classes.StarsRate} />{' '}
						<span>{rateOption.label}</span>
					</React.Fragment>,
					rateOption.val
				)
			);

			const allOption = clickWrap('All', 'all');

			return [ allOption, starOptions ];
		};

		const AvailableFilter = () => {
			const options = [
				// { label: 'All', value: null },
				{ label: 'Available for voice calls', value: 'available' },
				{ label: 'Unavailable for voice calls', value: 'unavailable' },
			];

			const optionsVideoCall = [
				{ label: 'Available for video calls', value: 'available' },
				{ label: 'Unavailable for video calls', value: 'unavailable' }
			]
			return (
				<div className={classes.FilterAvailableWrap}>
					<span
						className={[
							classes.FilterOption,
							this.state.allAvailable == true
								? classes['FilterOption--active']
								: ''
						].join(' ')}
						onClick={() => this.handleAvailabilityChangeAll()}>
						All
					</span>
					{options.map((option) => (
						<span
							className={[
								classes.FilterOption,
								filters.selectedAvailability === option.value
									? classes['FilterOption--active']
									: ''
							].join(' ')}
							onClick={() => this.handleAvailabilityChange(option.value)}>
							<ReactSVG svgClassName={classes.IconLiveCall} src={option.value == 'available' || option.value == null ? IconLiveCalls : IconMute} />
							{option.label}
						</span>
					))}
					{optionsVideoCall.map((option) => (
						<span
							className={[
								classes.FilterOption,
								filters.selectedAvailabilityVideoCall === option.value
									? classes['FilterOption--active']
									: ''
							].join(' ')}
							onClick={() => this.handleAvailabilityChangeVideoCall(option.value)}>
							<ReactSVG svgClassName={classes.IconLiveCall} src={option.value == 'available' || option.value == null ? IconVideoBasic : IconVideoSlash} />
							{option.label}
						</span>
					))}
				</div>
			);
		};

		const animationSlideT = 700;

		// Height of the header is 60
		const responsiveAside = screenWidth > 886 ? (screen.height() - 60) + 'px' : screen.height() + 'px';
		// console.log('test',this.state);

		const filtersRender = (
			<aside className={classes.Aside} style={{ maxHeight: responsiveAside }}>
				<Card loading={Number(!categories)} className={classes.Card}>
					<div className={classes.FilterHeadline}>
						<h2 className={classes.CardHeadline}>Filter by</h2>
						<div className={classes.ClearFilters}>
							<span onClick={this.clearFilters}>Clear all filters</span>
						</div>
					</div>
					{/* category filter*/}
					<h5 className={classes.FilterTitle}>CATEGORY</h5>
					{/* animate slide down once the data is fetched */}
					<Collapse in={Boolean(categories)} timeout={animationSlideT}>
						<CollapsableList
							data={categories}
							onCategoryClick={(data) => this.onHandleListClick(data, 'categories')}
						/>
					</Collapse>
					{dividerRender}

					{/* levels filter*/}
					{activeTab === 'videos' ? (
						<React.Fragment>
							<h5 className={classes.FilterTitle}>LEVEL</h5>
							<Collapse in={Boolean(levels)} timeout={animationSlideT}>
								<CollapsableList
									hideCount
									data={levels}
									onCategoryClick={(data) => this.onHandleListClick(data, 'levels')}
								/>
							</Collapse>
							{dividerRender}
							<h5 className={classes.FilterTitle}>LANGUAGE</h5>
							<Collapse in={Boolean(languages)} timeout={animationSlideT}>
								<CollapsableList
									data={languages}
									onCategoryClick={(data) => this.onHandleListClick(data, 'languages')}
								/>
							</Collapse>
							{dividerRender}
						</React.Fragment>
					) : null}

					{/* price filter*/}
					<h5 className={classes.FilterTitle}>PRICE</h5>

					{!loadingClasses && (
						<Price
							min={priceRange && priceRange.min}
							max={priceRange && priceRange.max}
							value={
								priceRangeValue ? (
									priceRangeValue
								) : (
									{ min: priceRange && priceRange.min, max: priceRange && priceRange.max }
								)
							}
							onChange={this.onHandleChangePrice}
							onChangeRange={this.onHandleChangeRange}
							onSubmit={this.onSubmitRange}
						/>
					)}

					{/* rating filter*/}
					{dividerRender}
					<h5 className={classes.FilterTitle}>RATING</h5>
					{RateFilter()}

					{/* availability filter (calls only filter) */}
					{activeTab === 'calls' && (
						<React.Fragment>
							{dividerRender}
							<h5 className={classes.FilterTitle}>CURRENT AVAILABILITY</h5>
							{AvailableFilter()}
						</React.Fragment>
					)}
				</Card>

				{screenWidth > 886 ? (
					''
				) : (
					<div className={classes.ButtonWrap}>
						<Button
							size="large"
							style={{
								width: '273px',
								height: '50px',
								marginTop: '15px',
								marginBottom: '15px'
							}}
							onClick={() => this.closeFiltersModal()}>
							SHOW RESULTS
						</Button>
					</div>
				)}
			</aside>
		);
							
		return (
			<div className={classes.Container}>
				{/* filters */}
				{screenWidth > 886 ? filtersRender : ''}

				{/* Modals */}

				<Modal
					className={classes.BrowseModal}
					open={modalFiltersOpened}
					onClose={this.closeFiltersModal}>
					{filtersRender}
				</Modal>

				<BuyVideoModal
					open={modalVideoOpened}
					onClose={this.closeVideoModal}
					video={selectedVideo}
				/>
				<LiveCallModal
					open={modalCallOpened}
					onClose={this.closeCallModal}
					call={selectedCall}
					type={this.state.modalCallType}
					//author={this.props.call.author.name}
					// videoState={}
				/>
				 {/* <RateModal
					type='videoLiveCall'
					slug={this.state.videoSlug}
					callName={this.state.videoName}
					open={this.state.modalRateOpened}
					onClose={this.closeModalRating}
				/> */}
				{this.state.twilioVideo && this.state.roomToken !== '' ? 
					<TwilioVideo 
					roomName={this.state.roomName} 
					roomToken={this.state.roomToken} 
					incomingCallUser = {this.state.participient}
					videoName={this.state.videoName}
					photoUrl={this.state.participientUrlImage}
					author={this.state.author}
					closeTwilioVideo = {this.closeTwilioVideo}
					/>
				: ''}
				
				
				<SignUpModal
					open={modalSignUpOpened}
					onClose={this.closeSignUpModal}
					onAuthReturnToUrl={returnToUrl}
				/>

				{/* classes container */}

				<div className={classes.ClassesContainer}>
					<div style={{ display: 'flex' }}>
						<h1 style={{ flexShrink: 0, marginRight: '10px' }}>Browse InfoDepot</h1>
						{screenWidth > 886 && <Input
							className={classes.InputSearch}
							onChange={(e) => this.onHandleSearchChange(e)}
							config={this.state.searchControl}
						/>}
					</div>
					<div className={classes.Wrapper}>
						{/* tabs navigation */}
						{/* <TabsBubble
							className={classes.Tabs}
							config={tabsList()}
							activeTab={this.state.activeTab}
							onTabClick={this.handleTabClick}
						/> */}
						<Tabs
							activetab={this.state.activeTab}
							config={tabsList()}
							// onChangeTab={(e, val) => this.handleTabChange(e, val)}
							onChangeTab={(e, val) => this.handleTabClick(val)}
							diffClasses="TabsLarge"
							disabledClasses={loadingClasses ?  "TabsDisabled" : null}
						/>
						{screenWidth < 887 && <Input
							className={classes.InputSearch}
							onChange={(e) => this.onHandleSearchChange(e)}
							config={this.state.searchControl}
						/>}
					</div>
					{screenWidth < 887 ? (
						<h2 className={classes.SelectedFilters}>
							{selectedFilters()}{' '}
							<span onClick={() => this.openFiltersModal()}>
								<ReactSVG src={IconFilter} className={classes.IconFilter} />
							</span>
						</h2>
					) : (
						''
					)}

					{/* video list */}
					{loadingClasses && !classesList ? loaderRender : ClassesList()}

					{classesList &&
					paginationConf.total > classesList.length && (
						<Pagination
							style={{ marginTop: '40px' }}
							totalRecords={paginationConf.total}
							pageLimit={paginationConf.pageLimit}
							pageNeighbours={1}
							onPageChanged={this.handlePaginationchange}
							loadMore
							loading={loadingClasses}
						/>
					)}
				</div>
			</div>
			
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

export default connect(mapStateToProps)(windowSize(BrowseClasses));
