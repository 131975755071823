import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import windowWidth from 'react-window-size'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter } from 'react-router-dom'

import classes from './QueuedCallsListing.module.scss'
import * as screen from './../../../../utils/common.js'
import * as liveCallService from './../../../../services/liveCall/liveCallService'
import Tabs from './../../../../components/UI/Tabs/Tabs';
import IDTable from './../../../../components/UI/Table/Table'
import IconLiveCall from '../../../../assets/images/icons/live_call_icon.svg'
import IconVideo from '../../../../assets/images/icons/video-solid.svg';
import Pagination from './../../../../components/UI/Pagination/Pagination'

const paginationConf = {
  pageLimit: 20,
  total: null
}

class QueuedCallsListing extends Component {
  state = {
    queuedCalls: null,
    queuedCallsVideo: null,
    loading: true,
    activeTab: ''
  }

  componentDidMount () {
    this.setActiveTab();

    const href = this.props.location.pathname;

    if(href == '/account/dashboard/queued-calls/video') {
        this.getQueuesVideoCalls();
    } else if(href == '/account/dashboard/queued-calls/phone') {
      this.getQueuesPhoneCalls();
    }
  }

  setActiveTab = () => {
    const href = this.props.location.pathname;
    let callType = this.props.match.params.type;

    if(href == '/account/dashboard/queued-calls/video') {
        callType = 'video';
    } else if(href == '/account/dashboard/queued-calls/phone') {
      callType = 'phone';
    }

    const activeTab = this.state.activeTab
    // check if it is a valid type comming from the url if it isn't route to first option
    const tabsListNames = ['video','phone'];

    if (tabsListNames.includes(callType)) {
      this.setState({ activeTab: callType })
    } else {
      const url = '/account/dashboard/queued-calls/' + activeTab
      this.props.history.replace(url)
    }
  }

  getQueuesVideoCalls = () => {
    liveCallService
      .getTeacherQueueVideoCalls(this.props.token)
      .then(response => {
        this.setState({ loading: false, queuedCallsVideo: response.data })
        //console.log('data', response.data);
      })
      .catch(error => {
        console.log(error.data)
      })
  }

  getQueuesPhoneCalls = () => {
    liveCallService
    .getTeacherQueueCalls(this.props.token)
    .then(response => {
      // console.log(response)
      this.setState({ loading: false, queuedCalls: response.data })
    })
    .catch(error => {
      console.log(error.data)
    })
  }

  handleTabChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + value);
		this.setState({ activeTab: value });

	};

	handleOptionChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + event.target.value);
		this.setState({ activeTab: event.target.value });
	};

  render () {
    const {
      queuedCalls,
      queuedCallsVideo,
      loading,
			activeTab
    } = this.state;

    const screenWidth = this.props.windowWidth;

    const queued = this.state.queuedCalls;
    const queuedVideos = this.state.queuedCallsVideo;
    const loaderRender = () => <CircularProgress className={classes.Loader} />

    const callsEmptyRender = () => {
      if(this.state.activeTab == 'video') {
        return (
          <div className={classes.SectionEmpty}>
            <ReactSVG svgClassName={classes.IconLiveCall} src={IconVideo} />
            <h2 className={classes.SectionHeadline}>You have no queued video calls.</h2>
          </div>
        )
      } else {
        return (
          <div className={classes.SectionEmpty}>
            <ReactSVG svgClassName={classes.IconLiveCall} src={IconLiveCall} />
            <h2 className={classes.SectionHeadline}>You have no queued phone calls.</h2>
          </div>
        )
      }
    }

    const tabProperties = [
			{
				value: 'video',
				label: 'Video Calls',
				content: <React.Fragment>
        {this.state.loading ? (
            loaderRender()
          ) : queuedVideos && queuedVideos.length > 0 ? (
            <IDTable
              data={queuedCallsVideo}
              user={this.props.user}
              typeTable='queueCallsTeacher'
              screen={screenWidth}
            />
          ) : (
            callsEmptyRender()
          )}
          <div>
            {paginationConf.total ? (
              <Pagination
                style={{ marginTop: '40px' }}
                totalRecords={paginationConf.total}
                pageLimit={paginationConf.pageLimit}
                pageNeighbours={1}
                onPageChanged={this.handlePaginationchange}
              />
            ) : null}
          </div>
        </React.Fragment>
      },
      {
				value: 'phone',
				label: 'Phone Calls',
				content:  <React.Fragment>
        {this.state.loading ? (
            loaderRender()
          ) : queued && queued.length > 0 ? (
            <IDTable
              data={queuedCalls}
              user={this.props.user}
              typeTable='queueCallsTeacher'
              screen={screenWidth}
            />
          ) : (
            callsEmptyRender()
          )}
          <div>
            {paginationConf.total ? (
              <Pagination
                style={{ marginTop: '40px' }}
                totalRecords={paginationConf.total}
                pageLimit={paginationConf.pageLimit}
                pageNeighbours={1}
                onPageChanged={this.handlePaginationchange}
              />
            ) : null}
          </div>
        </React.Fragment>
			}
		];

    return (
      <div className={classes.Container}>
        {screenWidth > 768 ? (
          <h1 className={classes.SectionHeadline}>Queued Calls</h1>
        ) : (
          ''
        )}
       <div className={classes.TableWrap}>
        <Tabs
            className={classes.Tabs}
            activetab={activeTab}
            config={tabProperties}
            onChangeTab={(e, value) => this.handleTabChange(e, value)}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  }
}

export default withRouter(
  connect(mapStateToProps)(windowWidth(QueuedCallsListing))
)
