// MUI v3 is used on this project and thus for IOS look following documentation has been used!
// https://v3.mui.com/demos/selection-controls/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import colors from '../../../sass/settings/_settings.colors.scss';

const styles = (theme) => ({
  IOSRoot: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    width: 70,
    height: 32,
    overflow: 'hidden',
  },
  iOSSwitchBase: {
    width: 105, // ~ 70 + 70/2
    height: 32,
    transform: 'translateX(-35px)',
    '&$iOSChecked': {
      color: colors['white'],
      '& + $iOSBar': {
        backgroundColor: colors['blue'],
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(1px)',
    color: colors['white'],
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    top: 0,
    left: 0,
    margin: 0,
    borderRadius: 35,
    width: 70,
    height: 32,
    backgroundColor: colors['grey-light'],
    opacity: 1,
    // transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 26,
    height: 26,
    boxShadow: 'none',
  },
  iOSIconChecked: {
    // boxShadow: theme.shadows[1],
    boxShadow: 'none',
  },
});

const IOSSwitch = (props) => {
  const { classes } = props;
  return (
    <Switch
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.switchChanged}
      value={props.value}
      disableRipple
      classes={{
        root: classes.IOSRoot,
        switchBase: classes.iOSSwitchBase,
        bar: classes.iOSBar,
        icon: classes.iOSIcon,
        iconChecked: classes.iOSIconChecked,
        checked: classes.iOSChecked,
      }}
    />
  );
};

export default withStyles(styles)(IOSSwitch);
