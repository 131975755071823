import React, { useEffect, useState } from "react";
import classes from "../AccountSettings/AccountSettings.module.scss";
import Button from "../../components/UI/Button/Button";
import ReactSVG from "react-svg";
import IconCard from "../../assets/images/icons/credit_card_icon.svg";
import Collapse from "@material-ui/core/Collapse";
import { Elements, StripeProvider } from "react-stripe-elements";
import apiConfig from "../../utils/apiConfig";
import SubscriptionForm from "../BuyCredits/StripeForm/SubscriptionForm";
import { connect } from "react-redux";
import * as actions from "../../store/actions/indexActions";
import * as userService from "../../services/user/userService";

const inputFont = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800"
  }
];

function PaymentInformation({ payment, snackbarAdd, token }) {
  const [ajaxRequest, setAjaxRequest] = useState({
    status: "idle"
  });
  const [last4, setLast4] = useState(payment.last4 || '****');
  const [cardSelected, setCardSelected] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeToken, setStripeToken] = useState(null);
  const [stripeConfirmed, setStripeConfirmed] = useState(false);
  const [cardElement, setCardElement] = useState(null);
  useEffect(() => {
    setStripe(window.Stripe(apiConfig.STRIPE_ID));
  }, []);

  // console.log(stripe);
  console.log(stripeToken);
  // console.log(stripeConfirmed);
  // console.log(cardElement);

  const isPaymentSelected = val => {
    return cardSelected === val;
  };

  async function onUpdateCardClick(e, paymentType) {
    try {
      e.preventDefault();
      // console.log(cardElement);

      setAjaxRequest({
        status: "pending"
      });

      const { data } = await userService.createPaymentMethodForSubscription(
        token
      );

      console.log(data);

      const { setupIntent, error } = await stripe.confirmCardSetup(
        data.client_secret,
        {
          payment_method: {
            card: cardElement
          }
        }
      );
      if (setupIntent) {
        // setupIntent:
        //   cancellation_reason: null
        //   client_secret: "seti_1KVhsUCmi1xa20xSA0rZgZp1_secret_LC64ADPL83vLztu7UQJPCLpJvWe61oc"
        //   created: 1645472938
        //   description: null
        //   id: "seti_1KVhsUCmi1xa20xSA0rZgZp1"
        //   last_setup_error: null
        //   livemode: false
        //   next_action: null
        //   object: "setup_intent"
        //   payment_method: "pm_1KVhsUCmi1xa20xSdt6bbaQO"
        //   payment_method_types: ['card']
        //   status: "succeeded"
        //   usage: "off_session"
        const {
          data: payment
        } = await userService.updatePaymentMethodForSubscription(token, {
          paymentMethodId: setupIntent.payment_method
        });
        console.log(payment);

        const stripeLast4 =
          (stripeToken && stripeToken.card && stripeToken.card.last4) || null;
        if (stripeLast4) {
          setLast4(stripeLast4);
        }
        snackbarAdd({
          message: "The card was updated successfully!",
          timeout: 5000
        });

        if (cardSelected) {
          setCardSelected(null);
          // setTimeout(() => setCardSelected(paymentType), 310);
        } else {
          setCardSelected(paymentType);
        }
      }

      if (error) {
        console.log(error);
        snackbarAdd({
          message: error.message,
          type: "error",
          timeout: 5000
        });
      }

      // console.log(error)
      // snackbarAdd({
      //   message: error.message,
      //   type: "error",
      //   timeout: 5000
      // });
      setAjaxRequest({
        status: "resolved"
      });
    } catch (onError) {
      console.log(onError);
      snackbarAdd({
        message: onError.message,
        type: "error",
        timeout: 5000
      });
      setAjaxRequest({
        status: "rejected"
      });
    }
  }

  function onPaymentClick(e, paymentType) {
    e.preventDefault();
    if (cardSelected) {
      setCardSelected(null);
      // setTimeout(() => setCardSelected(paymentType), 310);
    } else {
      setCardSelected(paymentType);
    }
  }

  function setPaymentToken(token) {
    console.log(token);
    setStripeToken(token);
  }

  function clearPaymentToken() {
    setStripeToken(null);
  }
  function stripeFormConfirmed(isConfirmed) {
    console.log(isConfirmed);
    setStripeConfirmed(isConfirmed);
  }

  function updateCardElement(cardElement) {
    console.log(cardElement);
    setCardElement(cardElement);
  }
  function showError(message) {
    console.log(message);
  }

  return (
    <div className={classes.PaymentInformation}>
      <h3 className={classes.Subheaders} style={{ marginBottom: "20px" }}>
        Payment information
      </h3>
      {payment ? (
        <>
          {!isPaymentSelected("card") && (
            <p
              className={classes.HighlightBox}
              style={{ marginBottom: "20px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactSVG
                  className={classes.IconCard}
                  svgClassName={classes.IconActive}
                  src={IconCard}
                />
                <div style={{ position: "relative", top: "3px" }}>
                  **** **** ****&nbsp;
                </div>
                <div>{last4 || payment.last4}</div>
              </div>

              <Button
                className={classes.Button}
                active={isPaymentSelected("card")}
                onClick={e => onPaymentClick(e, "card")}
                type={"link"}
              >
                Update card
              </Button>
            </p>
          )}
          <div className={classes.CollapseWrap}>
            <Collapse
              style={{ width: "100%" }}
              in={isPaymentSelected("card")}
              timeout={310}
            >
              <StripeProvider stripe={stripe}>
                <Elements fonts={inputFont}>
                  <SubscriptionForm
                    onFormComplete={setPaymentToken}
                    onFormUncomplete={clearPaymentToken}
                    onConfirmed={stripeFormConfirmed}
                    updateCardElement={updateCardElement}
                    onError={error => showError(error.message)}
                  />
                </Elements>
              </StripeProvider>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingBottom: "24px"
                }}
              >
                {ajaxRequest.status !== "pending" && (
                  <Button
                    className={classes.Button}
                    onClick={e => onPaymentClick(e, "card")}
                    type={"link"}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className={classes.Button}
                  onClick={e => onUpdateCardClick(e, "card")}
                  loading={ajaxRequest.status === "pending"}
                  disabled={
                    !stripeConfirmed || ajaxRequest.status === "pending"
                  }
                >
                  {ajaxRequest.status === "pending"
                    ? "Updating..."
                    : "Update card"}
                </Button>
              </div>
            </Collapse>
          </div>
        </>
      ) : (
        <p className={classes.HighlightBox} style={{ marginBottom: "20px" }}>
          No payment information yet.
        </p>
      )}
      <p
        className={classes.SubscriptionInfo}
        style={{ fontSize: "13px", marginBottom: "0px", marginTop: "0px" }}
      >
        Our payment process is secured, and InfoDepot company cannot store nor
        access this data. We will display the last four digits of your card so
        you can identify the card you used for purchasing the subscription.
      </p>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    snackbarAdd: snackConf => dispatch(actions.snackbarAdd(snackConf))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInformation);
