import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import * as callService from '../../services/liveCall/liveCallService';

import classes from './ConfirmMessageVideoQueue.module.scss';
import Card from '../../components/UI/Card/Card';
import Button from '../../components/UI/Button/Button';
import IconVideo from '../../assets/images/icons/video-solid.svg';

class ConfirmMessage extends React.PureComponent {

    render() {
        return (
            <div className={classes.Container}>
                <h1>Satrting Video Call</h1>
                <Card className={classes.Card}>
                    <ReactSVG svgClassName={classes.IconSvg} src={IconVideo} />
                    <div className={classes.QueueInfo}>
                        <p>
                        There are <span className={classes.GreenSpan}>{this.props.location.state.queueCount}</span> users in the queue. Please, 
                        stay on InfoDepot website to get connected as 
                        soon as the teacher is available. 
                        After you are connected with the teacher, your account will be charged <span className={classes.GreenSpan}>${this.props.location.state.price}</span> per minute.
                        </p>
                        <p>
                            To track the video queue list, go to your account.
                        </p>
                    </div>
                    <Link
						to={'/account/queued-calls/video'}
						style={{width: '100%' }}>
						<Button
							className={classes.Button}
							size="large"
							style={{ height: '50px', lineHeight: '12px' }}>
							GO TO VIDEO QUEUE LIST
						</Button>
					</Link>
                </Card>
            </div>
        )
    }


}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
// 	};
// };

export default withRouter(connect(mapStateToProps)(ConfirmMessage));

