import React from 'react'
import ModalUI from '@material-ui/core/Modal'
import Delete from './../../../assets/images/icons/close icon.svg'
import IconButton from '@material-ui/core/IconButton'
import Card from './../../../components/UI/Card/Card'
import classes from './Modal.module.scss'

const Modal = props => {
  //   console.log(props)
  return (
    <ModalUI
      className={[classes.Modal, props.modalScrollable ? classes.ModalScrollable : " "].join(" ")}
      aria-labelledby='modal'
      aria-describedby='prompt'
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
      style={{ top: `${props.style}` }}
    >
      <Card className={[classes.Card, props.className].join(' ')}>
        <IconButton className={classes.CloseButton} onClick={props.onClose}>
          <img className={classes.Icon} src={Delete} alt='X mark' />
        </IconButton>
        {props.children}
      </Card>
    </ModalUI>
  )
}

export default Modal
