import * as actionTypes from '../actions/actionTypes';
import clone from 'lodash.clonedeep';

const reducer = (state = {newMessageNotification: false}, action) => {
    const cloneState = clone(state);
    switch (action.type) {
        case actionTypes.NEW_MESSAGE_NOTIFICATION:
            cloneState.newMessageNotification = action.notification;
            return cloneState;
        default:
            return state;
    }
};

export default reducer;
