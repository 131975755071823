import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import classes from './LandingPage.module.scss'
import ReactPlayer from 'react-player';
import Button from './../../../components/UI/Button/Button'
import Background from '../../../assets/images/background/landing-page-background-min.jpg'
import FirstGraph from '../../../assets/images/graphics/video_classes_illustration.svg'
import SecondGraph from '../../../assets/images/graphics/live_calls_illustration.svg'
import ThirdGraph from '../../../assets/images/graphics/learn_from_expert.svg'
import BrowseInfodepotCover from '../../../assets/images/graphics/explore_videos_trim.png'
import PhotographyBasics from '../../../assets/images/graphics/enroll_video_section.png'
import PhotographyBasicsMobile from '../../../assets/images/graphics/enroll_video_section_mobile.png'
import Consultation from '../../../assets/images/graphics/calls_section.png'
import Rating from '../../../assets/images/background/Rating.png'
import BackgroundBottom from '../../../assets/images/background/bottom-background-image.png'
import VideoPreview from '../../../assets/images/graphics/video_preview.svg'
import SearchKnow from '../../../assets/images/graphics/search_know.svg'
import LeftShape from '../../../assets/images/graphics/left_shape.svg'
import RightShape from '../../../assets/images/graphics/right_shape.svg'
import LeftMiddleShape from '../../../assets/images/graphics/left_middle_shape.svg'
import RightMiddleShape from '../../../assets/images/graphics/right_middle_shape.svg'
import BackgroundVideoSrc from '../../../assets/videos/Infodepot cover video.mp4'
import WebBooks from '../../../assets/images/graphics/book_learner.svg'
import SaiftyComunication from '../../../assets/images/graphics/saifty.svg'
import CallPreview from '../../../assets/images/graphics/phone_mobile.svg'
import Connect from '../../../assets/images/graphics/private_conection.svg'
import Availability from '../../../assets/images/graphics/check_availability.svg'
import CommentsAndReview from '../../../assets/images/graphics/comments_and_review.svg'
import ReviewSection from '../../../assets/images/graphics/reviews_section.png'
import ReviewSectionMobile from '../../../assets/images/graphics/reviews_section.png'

class LandingPage extends Component {
  state = {}

  componentDidMount () {
    window.scrollTo(0, 0);
   
    //Meta tags
    let metatitle = document.getElementsByName('title')[0];
    let metaDecription = document.getElementsByName('description')[0];
    
    document.title = 'InfoDepot: Teach anything. Learn everything.';
    metatitle.setAttribute('content', 'InfoDepot: Teach anything. Learn everything.');
    metaDecription.setAttribute('content', 'Discover InfoDepot, an online learning and teaching platform that offers bite-sized video classes, tutorials and live phone calls. Learn something new every day.');
  }
  handleClickContestRemoveFlag = (e)=> {
    if(localStorage.getItem('contestPage') ) {
        localStorage.removeItem('contestPage');
      }
  }

  render () {
    return (
      <div className={classes.Container}>
        <div className={classes.Section}>
          <div
            className={[classes.SectionOne, classes.ContainerBackground].join(
              ' '
            )}
          >
            <ReactPlayer url={BackgroundVideoSrc}
                    playIcon={false}
                    playing={true}
                    fluid={true}
                    loop={true}
                    muted={true}
                    width='100%'
                    height="100%"
                    controls={false}
                    className={classes.BackgroundVideo}/>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h1>Teach Anything. Learn Everything.</h1>
                <h2>
                  <Link to={'/sign-up-student'} className={classes.Link}>
                    Join now for free!
                  </Link>
                </h2>
                <div className={classes.ButtonsWrap}>
                  <Link to={'/sign-up-student'}>
                    <Button
                      className={classes.Button}
                      color='primary'
                      size='large'
                    >
                      START LEARNING
                    </Button>
                  </Link>
                  <Link to={'/become-a-teacher'}>
                    <Button
                      className={classes.Button}
                      color='secondary'
                      size='large'
                      onClick={this.handleClickContestRemoveFlag}
                    >
                      START TEACHING
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className={classes.LeftShape}>
					    <img src={LeftShape} alt="ShapeLeft" />
				    </div>
            <div className={classes.RightShape}>
					    <img src={RightShape} alt="ShapeLeft" />
				    </div>
            <div className={classes.LeftMiddleShape}>
					    <img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
				    </div>
            <div className={classes.RightMiddleShape}>
					    <img src={RightMiddleShape} alt="ShapeRightMiddle" />
				    </div> */}
            <div className={classes.ImageBackground} style={{ backgroundImage: 'url(' + Background + ')' }}>
             
            </div>
          </div>
          <div className={classes.SectionTwo}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2>
                Rediscover your talents. Reinvent yourself.<br/>
                Learn from experts’ life experiences and teachers with diverse backgrounds. 
                </h2>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column} style={{ textAlign: 'center' }}>
                  <img src={FirstGraph} alt='FirstGraph' />
                  <h2>Start Your Video Classes On Any Topic</h2>

                  <h3>
                    Wish to refine your knowledge or master a new skill? 
                    Browse our catalogue of videos lessons and tutorials and choose the right class for you.
                  </h3>
                  <h3>
                    Check out our <a href="/quick-start/student/4/video-class" >Quick Start Video on Taking a Video Class.</a>
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: 'center' }}>
                  <img src={SecondGraph} alt='SecondGraph' />

                  <h2>Turn To Teachers For Advice Via Live Calls</h2>

                  <h3>
                  Need a quick answer to your question? Whenever you need extra help or 
                  want to learn new skills, you can have a 1-to-1 call with a tutor.
                  </h3>
                  <h3>
                  Check out our <a href="/quick-start/teacher/7/take-classes">Quick Start Video on Placing a Live Call With a Teacher.</a>
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: 'center' }}>
                  <img src={ThirdGraph} alt='ThirdGraph' />
                  <h2>Learn From Experts Via A Peer to Peer Tutorial</h2>

                  <h3>
                  Searching for an expert advice or an interesting one-on-one tutorial? 
                  Search our videos and start with live tutoring lessons. 
                  </h3>
                </div>
              </div>
              <div className={classes.Row}>
              <div className={classes.CentredButton}>
                  <Button
                      className={classes.Button}
                      color='secondary'
                      size='large'
                      href="/quick-start"
                      >
                      Watch more Quick Start Videos
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionFour}>
            <div className={classes.SectionInner}>
              <div className={classes.Row}>
                <div className={classes.Column}>
                  <h2>Never stop learning.</h2>
                  <h3>
                  Browse our catalogue to discover videos,
                  classes and tutorials based on your interests, teacher’s expertise, 
                  and rating. Choose lessons that fit your skills levels and price.  
                  </h3>
                  <div className={classes.ImageWrapHide}>
                    <img
                      src={BrowseInfodepotCover}
                      className={classes.ImageHideLarge}
                      alt=''
                    />
                  </div>
                  <ul className={classes.List}>
                    <li>
                      <div className={classes.Image}>
                        <img src={SearchKnow} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Expand your knowledge</h3>
                        Search our vast list of topics and categories
                         and dive into different subjects and knowledge.
                      </div>
                    </li>
                    <li>
                      <div className={classes.Image}>
                        <img src={WebBooks} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Become a lifelong learner</h3>
                        Learn more about the teacher and see if any 
                        workbooks are included with the purchase of the video. 
                      </div>
                    </li>
                    <li>
                      <div className={classes.Image}>
                        <img src={VideoPreview} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Explore video classes</h3>
                        Search our vast list of topics 
                        and categories and dive into 
                        different subjects and knowledge. 
                      </div>
                    </li>
                    <li>
                      <div className={classes.Image}>
                        <img src={CommentsAndReview} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Find your perfect teacher </h3>
                        Decide if it is the right class and t
                        eacher for you by viewing comments and 
                        reviews left by others.
                      </div>
                    </li>
                    <div class={classes.BtnWrapp}>
                    <Link to={'browse-infodepot/videos'}>
                      <Button
                        className={classes.Button}
                        color='primary'
                        size='large'
                      >
                        Browse Classes
                      </Button>
                    </Link>
                    </div>
                  </ul>
                </div>

                <div className={classes.Column}>
                  <img
                    src={BrowseInfodepotCover}
                    className={classes.BrowseInfoDepotImage}
                    alt=''
                  />
                </div>
              </div>
            </div>
            `
          </div>
          <div className={classes.SectionFive}>
            <div className={classes.SectionInner}>
              <div className={classes.Column}>
                <img
                  src={PhotographyBasics}
                  className={classes.BrowseInfoDepotImage}
                  alt=''
                />
              </div>
              <div className={classes.Column}>
                <h2>Your questions, answered.<br/>
                    Your skills, sharpened. </h2>
                <div className={classes.Text}>
                  <h3>
                    Learn at your own pace and time
                  </h3>
                  <p>
                  Purchased video classes are permanently stored in your video library <br/>
                  so you can watch them whenever you want and as many times as you want.
                  </p>
                </div>
                <div className={classes.Text}>
                  <h3>
                    Enjoy in continuous learning
                  </h3>
                  <p>
                  Download workbooks and supporting materials for further learning.<br/>
                  Come back to them even after you complete a class.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionSix}>
            <div className={classes.SectionInner}>
              <div className={classes.Row}>
                <div className={classes.Column}>
                  <h2>Call Now For Live Consultation</h2>
                  <h3>
                  Speak to someone directly who can help you learn<br/>something new, 
                  figure out a problem, discuss an issue, explore a new passion, 
                  expand your skillset or dive a little deeper into an existing area of interest.
                  </h3>

                  <div className={classes.ImageWrapHide}>
                    <img
                      src={Consultation}
                      className={classes.ImageHideLarge}
                      alt=''
                    />
                  </div>

                  <ul className={classes.List}>
                    <li>
                      <div className={classes.Image}>
                        <img src={CallPreview} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Place a call</h3>
                        Top Up your account easily and place 
                        a call now or whenever suits your schedule. 
                      </div>
                    </li>
                    <li>
                      <div className={classes.Image}>
                        <img src={SaiftyComunication} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Communicate safely</h3>
                        Your personal information is not shared. 
                        Connect quickly, seamlessly and anonymously.
                      </div>
                    </li>
                    <li>
                      <div className={classes.Image}>
                        <img src={Connect} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Connect privately</h3>
                        We privately connect you with a tutor for 
                        a live call at the per minute rate set by the tutor.
                      </div>
                    </li>
                    <li>
                      <div className={classes.Image}>
                        <img src={Availability} alt='' />
                      </div>
                      <div className={classes.Text}>
                        <h3>Check availability</h3>
                        Check tutors' schedules for availability and
                        call whenever their schedule is also convenient for you.
                      </div>
                    </li>
                    <Link to={'/browse-infodepot/calls'}>
                      <Button
                        className={classes.Button}
                        color='primary'
                        size='large'
                      >
                        Browse Live Tutoring Calls
                      </Button>
                    </Link>
                  </ul>
                </div>

                <div className={classes.Column}>
                  <img
                    src={Consultation}
                    className={classes.BrowseInfoDepotImage}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionSeven}>
            <div className={classes.SectionInner}>
              <div className={classes.Column}>
                <img
                  src={ReviewSection}
                  className={classes.BrowseInfoDepotImage}
                  alt=''
                />
              </div>
              <div className={classes.Column}>
                <h2>Contribute to sharing<br/>quality knowledge</h2>
                <h3>
                InfoDepot is an online community of teachers<br/> and students and we care 
                about your opinion. After buying a video class or finishing your
                live tutoring call, you can rate and review both of these services. 
                </h3>
                <div className={classes.ImageWrap}>
                  <img
                    src={ReviewSection}
                    className={classes.ImageHideLarge}
                    alt=''
                  />
                </div>
                <p>
                Rating and reviews help to make informed choices about 
                online tutoring videos and lessons and provide feedback to 
                teachers and tutors about the quality of their services and content.
                </p>
              </div>
            </div>
          </div>
          <div
            className={[
              classes.SectionFooter,
              classes.ContainerBackground,
              classes.SectionEight
            ].join(' ')}
            style={{ backgroundImage: 'url(' + BackgroundBottom + ')' }}
          >
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadNormalWeight}>
                  Is teaching your passion?{' '}
                </h2>
                <h2>Become an InfoDepot Teacher</h2>
                <p>
                  Love to teach? Share your knowledge and earn extra income
                  or grow your online teaching business.<br/> 
                  Support lifelong learning by joining InfoDepot today.{' '}
                </p> 
                <br />
                <div>
                  <Link to={'/become-a-teacher'}>
                    <Button
                      className={classes.Button}
                      color='secondary'
                      size='large'
                    >
                      Become an InfoDepot teacher
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LandingPage
