import { PLAN_FREE_VIDEOS_LIMIT } from "../../utils/consts"; 
import { capitalize } from "lodash";

export const renderTitle = (from, expiredPlan) => {
  let wording = '';
  switch (from) {
    case 'setUpLiveCall':
      wording =
        'Upgrade your subscription to get unlimited 1-on-1 Live Tutoring Call lines';
      break;
    case 'createVideo':
      wording =
        'Upgrade your subscription to upload more video classes';
      break;
    case 'expiredCalls':
      wording = 'Your Pro subscription expired';
      break;
    case 'expiredVideos':
      wording = `Your ${capitalize(expiredPlan)} subscription expired`;
      break;
    case 'coupon':
      wording = 'Upgrade your subscription to get Coupon Code feature';
      break;
    default:
      console.log('No Prompt-Title found!');
  }
  return wording;
};

export const renderText = (from, expiredPlan) => {
  let wording = '';
  switch (from) {
    case 'setUpLiveCall':
      wording =
        "Your current plan doesn't include Live Tutoring Call feature. To offer 1-on-1 live tutoring via phone and video calls, upgrade your subscription to Pro plan.";
      break;
    case 'createVideo':
      wording =
        `Your current plan allows ${PLAN_FREE_VIDEOS_LIMIT} videos and you have reached the limit. To offer more video classes to learners, upgrade your subscription.`;
      break;
    case 'expiredCalls':
      wording =
        "Your current plan doesn't include Live Tutoring Call feature. To offer 1-on-1 live tutoring via phone and video calls, upgrade your subscription to Pro plan.";
      break;
    case 'expiredVideos':
      wording =
        `Your current plan includes ${PLAN_FREE_VIDEOS_LIMIT} video classes, so the rest of your videos are now hidden from new learners. To offer more than ${PLAN_FREE_VIDEOS_LIMIT} videos,  upgrade your subscription. Please note that learners who already purchased your classes can still access them.`;
      break;
    case 'coupon':
      wording =
        "Your current plan doesn't include Coupon Code feature. To set and share your coupon code with people that you want to offer a discount to for your video classes, upgrade your subscription to Pro plan.";
      break;
    default:
      console.log('No Prompt-Text found!');
  }
  return wording;
};

export const renderSubtitle = (from) => {
  let wording = '';
  switch (from) {
    case 'singleCall':
      wording =
        'This call line is hidden from learners per your current plan limits. To activate it, upgrade your subscription to Pro plan.';
      break;
    case 'singleVideo':
      wording =
        'This video is hidden from new learners per your current plan limits. To activate it, upgrade your subscription.';
      break;
    default:
      console.log('No Prompt-Title found!');
  }
  return wording;
};
