import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import ReactSVG from 'react-svg';
import clone from 'lodash.clonedeep';
import IconButton from '@material-ui/core/IconButton';

import * as actions from '../../store/actions/indexActions';
import classes from './CreateVideo.module.scss';
import Card from '../../components/UI/Card/Card';
import Button from '../../components/UI/Button/Button';
import { Form, Input, Checkbox } from '../../components/UI/Form/Form';
import * as validator from '../../utils/formValidator';
import { errorParser } from '../../utils/errorParser';
import apiConfig from '../../utils/apiConfig';
import Divider from '../../components/UI/Divider/Divider';
import IconMegaphone from '../../assets/images/icons/megaphone_icon.svg';
import IconVideo from '../../assets/images/icons/video class icon.svg';
import IconImage from '../../assets/images/icons/image.svg';
import IconTrash from '../../assets/images/icons/delete_icon.svg';
import IconDocument from '../../assets/images/icons/document_icon.svg';
import IconCheckmark from '../../assets/images/icons/checkmark_icon.svg';

import * as userService from '../../services/user/userService';
import * as videoService from '../../services/video/videoService';
import * as configurationService from '../../services/configuration/configurationService';
import languagesJSON from '../../shared/json/languages.json';
import PromptToUpgradeSubscription from '../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';

let categories;
const formatSubCategory = (list) =>
	Object.keys(list).map((categoryName) => {
		return {
			label: list[categoryName].name,
			value: list[categoryName].slug
		};
	});
let levels;
const documentFormats = [
	'application/pdf',
	'application/illustrator',
	'application/postscript',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/x-compressed',
	'application/x-zip-compressed',
	'application/psd',
	'application/zip',
	'multipart/x-zip',
	'application/x-gzip',
	'multipart/x-gzip',
	'image/png',
	'image/jpeg',
	'image/gif',
	'application/x-rar-compressed',
	'application/x-7z-compressed',
	'application/vnd.oasis.opendocument.text',
	'application/vnd.oasis.opendocument.spreadsheet',
	'application/vnd.oasis.opendocument.presentation',
	'image/vnd.adobe.photoshop',
	'application/x-photoshop',
	'image/psd',
	'zz-application/zz-winassoc-psd'
];

const coverImageFormats = [ 'image/jpg', 'image/jpeg', 'image/png', 'image/x-png' ];
const publishControler = (savedData) => ({
	publishAutomatically: {
		id: 'publishAutomatically',
		type: 'checkbox',
		checked: 'false',
		value: savedData ? savedData.publishAutomatically : '',
		validation: {},
		error: '',
		valid: false,
		touched: true
	}
});
class CreateVideo extends Component {
	state = {
		editMode: false,
		replacedVideo: false,
		uploadEnabled: false,
		initialData: null,
		categoriesLoaded: null,
		levelsLoaded: null,
		documentsUploading: false,
		uploadVideoCheck: false,

		uploadingOverlay: false,
		videoUploadPercent: null,
		videoName: null,

		photoLoading: false,
		coverImage: null,

		formIsValid: false,
		formControls: null,

		feeData: null
	};
	componentDidMount() {
		// edit video page setup
		if (this.props.match.params.slug) {
			this.setState({ editMode: true });
			//console.log(this.props.token, this.props.match.params.slug);
			videoService
				.getVideoClass(this.props.token, this.props.match.params.slug)
				.then((response) => {
					this.setUploadListeners();
					const videoStatus = response.data.status;
					
					this.setState({
						uploadEnabled: videoStatus === 'drafted',
						initialData: response.data,
						videoName: response.data.name && Number([undefined, null].includes(response.data.uploadVideo)) == 0
							? response.data.name
							: '',
						coverImage: response.data.cover
							? apiConfig.DOMAIN_IMAGE + response.data.cover.big
							: null
							// videoName: response.data.uploadVideo.fileName
							// ? response.data.uploadVideo.fileName
							// : response.data.name,
					});

					this.initSelectOptions();
				})
				.catch((error) => {
					console.log(error.response);
				});
		} else {
			// @TODO make this async
			configurationService
				.getInfoDepotFee()
				.then((response) => {
					const fee = response.data;
					this.setState({
						feeData: fee,
						uploadEnabled: true
					});

					// create video page setup
					this.creditsCheck();
				})
				.catch((error) => {
					console.log(error);
					return error.response;
				});
		}
	}
	uploader = new FineUploaderTraditional({
		options: {
			request: {
				//endpoint: apiConfig.API_ENDPOINT + 'classes/video/video-01/upload-video'
				customHeaders: {
					'X-Auth-Token': this.props.token
				}
			},
			chunking: {
				enabled: true,
				partSize: 5000 * 1024, //chunk at 200kb
				success: {
					endpoint: apiConfig.API_ENDPOINT + '/classes/video/chunks-done',
					customHeaders: {
						'X-Auth-Token': this.props.token
					}
				}
			},
			/* 			callbacks: {
				onSubmit: (id, name) => {
					console.log('Video submited!', id, name);
				},
				onProgress: (id, name, uploadedBytes, totalBytes) => {
					console.log('Progress', Math.round(uploadedBytes / totalBytes * 100));
				},
				onComplete: (id, name, response) => {
					console.log('Video Upload completed!', id, name, response);
				},
				onStatusChange: (id, oldStatus, newStatus) => {
					console.log('Status Change', oldStatus + '=>' + newStatus);
				}
			}, */
			autoUpload: false
			//debug: true
		}
	});

	initForm = () => {
		this.setUploadListeners();
		// init the create video skeleton
		this.initiateSkeleton();
		this.initSelectOptions();
	};
	setUploadListeners = () => {
		// setup listeners for video upload
		/* 		this.uploader.on('validate', (obj, el) => {
			console.log(el);
			return this.isValidVideo(obj);
		}); */
		this.uploader.on('progress', (id, name, uploadedBytes, totalBytes) => {
			const progressPercent = Math.round(uploadedBytes / totalBytes * 100);
			this.setState({ videoUploadPercent: progressPercent });
		});
		this.uploader.on('submit', (id, name) => {
			this.setState({ videoName: name });
		});
		this.uploader.on('complete', () => {
			this.props.history.push('/account/dashboard');
		});
	};
	updateFormState = (hasntParent) => {
		const savedData = this.state.initialData;
		// let hasntParent = savedData && savedData.categories[0].parent == null;
		// if we are not in edit mode include publish control
		//console.log(categories);

		const publishControl = !this.state.editMode ? publishControler(savedData) : {};
		this.setState({
			categoriesLoaded: categories,
			levelsLoaded: levels,
			formControls: {
				name: {
					id: 'name',
					type: 'text',
					placeholder: 'Video Class Title:',
					placeholderStatic: 'Enter what students will learn',
					shrink: true,
					value: savedData ? savedData.name : '',
					validation: {
						required: true
					},
					error: '',
					valid: false,
					touched: savedData ? Boolean(savedData.name) : false
				},
				description: {
					id: 'description',
					type: 'textarea',
					charCounter: 500,
					placeholder: 'Video Class Description:',
					placeholderStatic: 'Describe what your video class is about ',
					shrink: true,
					value: savedData ? savedData.description : '',
					validation: {
						required: true,
						maxLength: 500
					},
					error: '',
					valid: false,
					touched: savedData ? Boolean(savedData.description) : false
				},
				language: {
					id: 'language',
					type: 'text',
					select: true,
					shrink: true,
					selectList:
						languagesJSON.map((language) => {
							return {
								label: language.name,
								value: language.isoCode
							};
						}),
					placeholder: 'Language:',
					placeholderStatic: 'Choose a language',
					value: savedData ? savedData.language : '',
					validation: {
						required: true
					},
					error: '',
					valid: true,
					touched: savedData ? Boolean(savedData.language) : false
				},
				categories: {
					id: 'categories',
					type: 'text',
					select: true,
					shrink: true,
					selectList:
						categories &&
						categories.map((category) => {
							return {
								label: category.name,
								value: category.slug
							};
						}),
					placeholder: 'Category:',
					placeholderStatic: 'Choose a category',
					value: hasntParent == true ? savedData.categories[0].slug : hasntParent == false ? savedData.categories[1].slug : '',
					validation: {
						required: true
					},
					error: '',
					valid: false,
					touched: hasntParent == true ? savedData.categories[0].slug : hasntParent == false ? savedData.categories[1].slug : '',
				},
				subcategories: {
					id: 'subcategories',
					type: 'text',
					select: true,
					shrink: true,
					selectList:
						hasntParent == true ? formatSubCategory(
							categories.filter((category) => {
								return savedData.categories[0].slug === category.slug;
							})[0].children
						)
						: hasntParent == false ? formatSubCategory(
							categories.filter((category) => {
								return savedData.categories[1].slug === category.slug;
							})[0].children
						) : [],
					placeholder: 'Subcategory:',
					placeholderStatic: 'Choose a subcategory',
					value: hasntParent == true ? savedData.categories[1].slug : hasntParent == false ? savedData.categories[0].slug : '',
					validation: {
						required: true
					},
					error: '',
					valid: false,
					touched: hasntParent == true ? savedData.categories[1].slug : hasntParent == false ? savedData.categories[0].slug : false
				},
				level: {
					id: 'level',
					type: 'text',
					select: true,
					shrink: true,
					selectList:
						levels &&
						levels.length &&
						levels.map((i) => {
							return { label: i.name, value: i.slug };
						}),
					placeholder: 'Level:',
					placeholderStatic: 'Choose a level',
					value: savedData && savedData.level ? savedData.level.slug : '',
					validation: {
						required: true
					},
					error: '',
					valid: false,
					touched: savedData ? savedData.level : false
				},
				price: {
					id: 'price',
					type: 'text',
					placeholder: <div className={classes.LabelIndent}>Video Class Price</div>,
					placeholderStatic: 'Price',
					shrink: true,
					value: savedData ? savedData.price : '',
					validation: {
						required: true,
						isNumericDecimal: true
					},
					error: '',
					valid: false,
					touched: savedData ? Boolean(savedData.price) : false
				},
				...publishControl
			}
		});
	};
	//check if user had credits to create a video
	creditsCheck = () => {
		// getting the latest user data to check the credits
		userService.getLoggedUser(this.props.token).then((response) => {
			// if there is no enough credit send user to the buy-credits page and show him the message
			if (response.data.totalCredits < this.state.feeData.createClassFee) {
				const backToQueryParam = () => {
					const query = new URLSearchParams(this.props.location.search);
					query.append('back-to', this.props.location.pathname);
					return '?' + query.toString();
					
				};
				this.props.snackbarAdd({
					message: 'You must Top Up your account to create a video class.',
					type: 'info',
					timeout: 10000
				});
				this.props.history.replace('/buy-credits' + backToQueryParam());
				return;
			}
			// init form data
			this.initForm();
		});
	};

	initSelectOptions = () => {
		const savedData = this.state.initialData;
		let hasntParent = savedData && savedData.categories[0].parent == null;
		//console.log(hasntParent);

		configurationService
			.getCategories()
			.then((response) => {
				const formatedCategories = Object.keys(response.data).map((categoryName) => {
					return response.data[categoryName];
				});
				// set the categories to be used in category and subcategory select input
				categories = formatedCategories;
				this.updateFormState(hasntParent);
			})
			.catch((error) => {
				console.log(error);
			});

		videoService
			.getLevels()
			.then((response) => {
				// set the level to be used in level select input
				levels = response.data;
				this.updateFormState(hasntParent);
				//console.log('uspelo');
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	initiateSkeleton = () => {
		videoService
			.createSeleton(this.props.token, true)
			.then((response) => {
				this.setState({
					initialData: response.data,
					coverImage: response.data.cover
						? apiConfig.DOMAIN_IMAGE + response.data.cover.big
						: null
				});
			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	onChangePhoto = (e) => {
		let file = e.target.files[0];

		if (this.isValidImage(e, file)) {
			this.setState({ photoLoading: true });

			// upload call to api
			videoService
				.uploadCoverPhoto(
					this.props.token,
					file,
					this.state.initialData.id,
					this.state.initialData.slug
				)
				.then((response) => {
					this.setState({
						photoLoading: false,
						coverImage: apiConfig.DOMAIN_IMAGE + response.data.cover.big,
						formIsValid: validator.formIsValid(this.state.formControls)
					});
				})
				.catch((err) => {
					console.log(err.response.data);
					this.setState({ photoLoading: false });
				});
		}
	};

	onDeleteDocument = (docSlug) => {
		videoService
			.deleteDocument(this.props.token, docSlug, this.state.initialData.id)
			.then((response) => {
				this.removeDocumentFromList(docSlug);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	addDocumentToList = (document) => {
		const clonedData = clone(this.state.initialData);
		clonedData.documents.push(document);
		this.setState({
			initialData: clonedData,
			documentsUploading: false,
			formIsValid: validator.formIsValid(this.state.formControls)
		});
	};

	removeDocumentFromList = (docSlug) => {
		const clonedData = clone(this.state.initialData);
		clonedData.documents = clonedData.documents.filter((i) => i.documentSlug !== docSlug);
		this.setState({ initialData: clonedData });
	};

	onChangeUploadDoc = (e) => {
		let file = e.target.files[0];

		if (!this.state.documentsUploading && this.isValidDocument(e, file)) {
			this.setState({ documentsUploading: true });
			videoService
				.addDocument(
					this.props.token,
					file,
					this.state.initialData.id,
					this.state.initialData.slug
				)
				.then((response) => {
					this.addDocumentToList(response.data);
				})
				.catch((error) => {
					console.log(error.response);
					this.setState({ documentsUploading: false });
					this.props.snackbarAdd({
						message: error.response.data.errors
							? error.response.data.errors.errors.join(' ')
							: error.response.data.message,
						type: 'error',
						timeout: 100000
					});
				});
		}
	};

	isValidImage = (e, file) => {
		// frontend validation of uploaded file
		if (file) {
			// validation for size, return the function if rule is not applied
			let errorMessage = '';
			if (file.size > 20 * 1024 * 1024) {
				errorMessage = 'This file is too big. Please upload a file up to 20MB.';
			} else if (!coverImageFormats.includes(file.type)) {
				// validation for type, return the function if rule is not applied
				errorMessage = `Please upload one of the following formats 'JPG', 'JPEG', 'PNG'.`;
			}

			if (errorMessage) {
				this.showError(errorMessage);
				return false;
			} else {
				return true;
			}
		}
	};

	isValidDocument = (e, file) => {
		// frontend validation of uploaded file
		console.log(file);
		if (file) {
			// validation for size, return the function if rule is not applied
			let errorMessage = '';
			if (file.size > 20 * 1024 * 1024) {
				errorMessage = 'This file is too big. Please upload a file up to 20MB.';
			} else if (!documentFormats.includes(file.type)) {
				// validation for type, return the function if rule is not applied
				errorMessage = `Please upload one of the following formats 'JPG', 'JPEG', 'PNG', 'PSD', 'AI', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF', 'PPT', 'PPTX', 'ODT', 'ODS', 'ODP', 'ZIP', 'GZ', '7Z'.`;
			}
			if (errorMessage) {
				this.showError(errorMessage);
				return false;
			} else {
				return true;
			}
		}
	};

	onChangeVideo = (e) => {
		// if file wasn't picked from the input block the further validation
		if (!e.target.files.length) return;
		if (this.isValidVideo(e.target.files[0])) {
			if (this.uploader.methods.getFile(0)) {
				this.uploader.methods.reset();
			}
			console.log('Is there file at place 0 AFTER ?', this.uploader.methods.getFile(0));
			this.setState({
				replacedVideo: true,
				formIsValid: validator.formIsValid(this.state.formControls)
			});
			this.uploader.methods.addFiles(e.target.files);
		}
	};

	isValidVideo = (file) => {
		const fileFormats = [
			'application/octet-stream',
			'video/mp4',
			'video/mpeg',
			'video/x-matroska',
			'video/quicktime',
			'video/x-msvideo',
			'video/x-flv'
		];

		// frontend validation of uploaded file
		// validation for size, return the function if rule is not applied
		let errorMessage = '';
		if (file.size > 2000 * 1024 * 1024) {
			errorMessage = 'This file is too big. Please upload a video file up to 2GB.';
		} else if (!fileFormats.includes(file.type)) {
			// validation for type, return the function if rule is not applied
			errorMessage = `Please upload one of the valid video formats: MP4, MPEG, Matroska, Quicktime, Msvideo, FLV. For best web experience we recommend the MP4 format.`;
		}

		if (errorMessage) {
			this.showError(errorMessage);
			return false;
		} else {
			return true;
		}

		// if (this.state.replacedVideo == true) {
		// 	console.log('false');
		// }
	};

	showError = (message) => {
		this.props.snackbarAdd({
			message: message,
			type: 'error',
			timeout: 18000
		});
	};

	// clears the errors and 'dirty' the input on change. Errors will be checked on blur
	clearInput = (inpt) => {
		inpt.touched = true;
		inpt.error = '';

		// if input is part of the group clear all group memebers
		if (inpt.group) {
			inpt.groupError = false;
		}
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if there is no value it is the radio/checkbox input, in that case take the name
		if (ctrl.type !== 'checkbox') {
			ctrl.value = e.target.value;
		} else {
			ctrl.value = ctrl.value === controlName ? '' : controlName;
		}

		// once category is selected apply its subcategories list to relevant input
		if (controlName === 'categories') {
			const subcategory = formControlsCopy['subcategories'];
			const currentList = categories.filter(
				(category) => e.target.value === category.slug
			)[0].children;
			if (e.target.value) {
				subcategory.selectList = formatSubCategory(currentList);
			}
			// make the subcategory data pristine
			subcategory.value = '';
			subcategory.error = '';
			subcategory.touched = '';
		}

		this.clearInput(ctrl);
		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	// checks validity of single input
	getInputError = (input) => {
		return validator.validateInput(input);
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];
		ctrl.error = this.getInputError(ctrl);
		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	controlGroupIsValid = (controls, additionalRules) => {
		const form = this.state.formControls;

		if (controls) {
			for (var i = 0; i < controls.length; i++) {
				if (!form[controls[i]].error && form[controls[i]].touched) {
					continue;
				} else {
					return false;
				}
			}
		}

		if (additionalRules) {
			for (var x = 0; x < additionalRules.length; x++) {
				if (!additionalRules[x]) {
					return false;
				}
			}
		}
		return true;
	};

	prepareSubmitData = () => {
		const form = this.state.formControls;
		// if it is an edit mode do not send automaticaly publish
		const publish = this.state.editMode
			? {}
			: { publishAutomatically: Boolean(form.publishAutomatically.value) };
		return {
			name: form.name.value.trim(),
			description: form.description.value.trim(),
			categories: [ form.categories.value, form.subcategories.value ],
			level: form.level.value,
			price: form.price.value,
			language: form.language.value,
			...publish
		};
	};

	submit = () => {
		// if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
		//e.target.focus();

		const skipSubmit = !this.props.user.canCreateVideoClass && !this.state.editMode;

		if (this.state.formIsValid && !skipSubmit) {
			this.setState({ loading: true });
			if (this.state.editMode) {
				this.editVideoService();
			} else {
				this.createVideoService();
			}
		}
	};

	uploadVideo = (slug) => {
		// after successfull creation of Draft start uploading the selected video
		this.uploader.methods.setEndpoint(
			apiConfig.API_ENDPOINT + '/classes/video/' + slug + '/upload-video'
		);
		this.uploader.methods.uploadStoredFiles();

		this.setState({ loading: false, uploadingOverlay: true });
	};

	editVideoService = () =>
		videoService
			.editVideoClass(
				this.props.token,
				this.prepareSubmitData(),
				this.state.initialData.slug
			)
			.then((response) => {
				console.log(response.data);

				if (this.state.replacedVideo && this.state.videoName) {
					this.uploadVideo(response.data.slug);
				} else {
					this.props.history.push('/video/' + response.data.slug);
				}
			})
			.catch((error) => {
				console.log(error.response);
			});

	createVideoService = () =>
		videoService
			.createVideoClass(this.props.token, this.prepareSubmitData())
			.then((response) => {
				// on complete update the user to reduce the price of the video creation
				this.props.updateLoggedUser();
				this.uploadVideo(response.data.slug);
			})
			.catch((error) => {
				console.log(error.response);

				if (error.response.data.errors && error.response.data.errors.length) {
					const apiErr = errorParser(error.response.data.errors);
					console.log(apiErr);
					const cloneForm = clone(this.state.formControls);

					// config object to be used by checkFormApiErrors
					const errorStateConfig = {
						formDescriptionError: 'description',
						formLevelError: 'level',
						formNameError: 'name',
						formPriceError: 'price',
						formCategoriesError: 'categories',
						formLanguageError: 'language',
					};

					// checking form errors for matching pairs defined in the errorStateConfig
					validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

					this.setState({
						formIsValid: false,
						formControls: cloneForm,
						loading: false
					});
				} else {
					this.props.snackbarAdd({
						message: error.response.data.message,
						type: 'error',
						timeout: 10000
					});
				}
			});
			

	render() {
		const {
			loading,
			uploadEnabled,
			editMode,
			coverImage,
			videoName,
			photoLoading,
			categoriesLoaded,
			levelsLoaded,
			uploadingOverlay,
			videoUploadPercent,
			initialData,
			documentsUploading
		} = this.state;
		//console.log(initialData);
		const infoDepotFee = this.state.feeData;
		const mainTitle = editMode ? `Edit Video Class` : `Create Video Class`;
		const { user } = this.props;
		const promptToUpgradeSubscription = !user.canCreateVideoClass && !editMode;
		const documentLabel = (i) => (
			<span className={classes.DocumentLabel}>
				{i.document.fileName}
				<IconButton
					className={classes.IconTrashButton}
					onClick={(e) => this.onDeleteDocument(i.documentSlug)}>
					<ReactSVG src={IconTrash} className={classes.IconTrash} />
				</IconButton>
			</span>
		);
		const SelectVideo = () =>
			(!editMode || uploadEnabled) && (
				<React.Fragment>
					{/* form section validator */}
					{FormSectionCheckValidity(
						<h3 id={'form-section-1'}>1. Video source</h3>,
						null,
						[ videoName ]
					)}
					<input
						style={{ display: 'none' }}
						id="video-select"
						type="file"
						//accept="video/*"
						accept="'video/*' 'video/mp4' 'video/x-m4v'"
						multiple
						onChange={this.onChangeVideo}
					/>
					<div className={classes.createVideoLabel}>Choose video:</div>
					<label htmlFor="video-select">
						<div
							style={
								this.state.videoName === false ? (
									{ marginBottom: '0px' }
								) : (
									{ wordBreak: 'break-all', marginBottom: '6px' }
								)
							}>
							{videoName}
						</div>
						<Button component="section" className={classes.Button} color="secondary">
							<ReactSVG src={IconVideo} className={classes.ButtonIcon} />
							<span>{videoName ? 'Replace video' : 'Choose video'}</span>
						</Button>
					</label>
					<p style={{ marginBottom: '15px' }}>
						Maximum size: 2GB<br />
						Recommended duration: Up to 30 min<br />
					</p>
					<div className={classes.TipWrapper}>
						<div className={classes.IconWrapper}>
							<ReactSVG className={classes.IconMegaphone} src={IconMegaphone} />
						</div>
						<div className={classes.Text}>
							<strong>Tip:</strong> Briefly explain what your video is about in the first
							30 seconds, as it will be used as a preview for non-enrolled users.
						</div>
					</div>
					<Divider className={classes.Divider} horizontal />
				</React.Fragment>
			);

		const publishControlRender = () =>
			!editMode && (
				<div className={classes.PublishWrap}>
					<Checkbox
						className={classes.Checkbox}
						config={this.state.formControls.publishAutomatically}
						onChange={(e) => this.inputChangeHandler(e, 'publishAutomatically')}
						onBlur={(e) => this.inputBlurHandler(e, 'publishAutomatically')}
					/>
					<span>Publish video as soon as upload is completed.</span>
				</div>
			);

		const FormSectionCheckValidity = (content, controls, additionalRules) => {
			if (this.controlGroupIsValid(controls, additionalRules)) {
				return (
					<div className={classes.ValidGroup}>
						{content} <ReactSVG src={IconCheckmark} className={classes.IconCheckmark} />
					</div>
				);
			} else {
				return content;
			}
		};

		const formRender =
			!initialData || !categoriesLoaded || !levelsLoaded ? null : (
				<Form onSubmit={this.submit}>
					{/* select video */
					SelectVideo()}

					{/* form section validator */}
					{FormSectionCheckValidity(
						<h3 id={'form-section-2'}> {2 - Number(!uploadEnabled)}. Basic info</h3>,
						[ 'name', 'description' ]
					)}
					{/* title */}
					<Input
						style={{ marginBottom: '16px' }}
						config={this.state.formControls.name}
						onChange={(e) => this.inputChangeHandler(e, 'name')}
						onBlur={(e) => this.inputBlurHandler(e, 'name')}
					/>
					{/* description */}
					<Input
						config={this.state.formControls.description}
						onChange={(e) => this.inputChangeHandler(e, 'description')}
						onBlur={(e) => this.inputBlurHandler(e, 'description')}
					/>
					<Input
						config={this.state.formControls.language}
						onChange={(e) => this.inputChangeHandler(e, 'language')}
						onBlur={(e) => this.inputBlurHandler(e, 'language')}
					/>
					<Divider className={classes.Divider} horizontal />

					{/* form section validator */}
					{FormSectionCheckValidity(
						<h3 id={'form-section-3'}>{3 - Number(!uploadEnabled)}. Additional info</h3>,
						[ 'categories', 'subcategories', 'level' ],
						[ coverImage ]
					)}
					{/* cover image */}
					<div className={classes.Label}>Video Class Cover Image:</div>

					<input
						style={{ display: 'none' }}
						id="cover-image"
						onChange={(e) => this.onChangePhoto(e)}
						accept="image/*"
						type="file"
					/>
					<label htmlFor="cover-image">
						{/* preview */}
						{coverImage ? (
							<div
								className={classes.ImageCover}
								style={{ backgroundImage: 'url(' + coverImage + ')' }}
							/>
						) : (
							<div className={classes.ImagePlaceholder}>
								<ReactSVG src={IconImage} />
							</div>
						)}
						<Button
							component="section"
							loading={Number(photoLoading)}
							className={classes.Button}
							color="secondary">
							<ReactSVG src={IconImage} className={classes.ButtonIcon} />
							Upload cover image
						</Button>
					</label>
					<p>
						Please upload a file up to 20MB.<br />
						Cover image should provide a clear depiction of your Video Class topic. Choose
						a high-quality, visually-compelling image that will help your Video Class
						stand out in our listings.
					</p>
					{/* category */}
					<Input
						style={{ marginBottom: '14px' }}
						className={classes.Input}
						config={this.state.formControls.categories}
						onChange={(e) => this.inputChangeHandler(e, 'categories')}
						onBlur={(e) => this.inputBlurHandler(e, 'categories')}
					/>
					{/* subcategory */}
					<Input
						style={{ marginBottom: '14px' }}
						disabled={!this.state.formControls.subcategories.selectList.length}
						config={this.state.formControls.subcategories}
						onChange={(e) => this.inputChangeHandler(e, 'subcategories')}
						onBlur={(e) => this.inputBlurHandler(e, 'subcategories')}
					/>
					{/* levels */}
					<Input
						style={{ marginBottom: '14px' }}
						config={this.state.formControls.level}
						onChange={(e) => this.inputChangeHandler(e, 'level')}
						onBlur={(e) => this.inputBlurHandler(e, 'level')}
					/>
					{/* Documents */}
					<div className={classes.Label}>Add documents:</div>
					{/* files list */}
					{initialData.documents.map((i) => documentLabel(i))}
					{/* hidden input */}
					<input
						style={{ display: 'none' }}
						id="document-upload"
						onChange={(e) => this.onChangeUploadDoc(e)}
						type="file"
					/>
					{/* visible styled button constrained to the input using label, if we are working with the api block the click */}
					<label
						htmlFor="document-upload"
						onClick={(e) => (documentsUploading ? e.preventDefault() : null)}>
						<Button
							component="section"
							loading={Number(documentsUploading)}
							disabled={Number(documentsUploading)}
							className={classes.Button}
							color="secondary">
							<ReactSVG src={IconDocument} className={classes.ButtonIcon} />
							Add document
						</Button>
					</label>
					<p>
						Maximum number of files: 10<br /> Maximum size of file: 20MB
					</p>
					<Divider className={classes.Divider} horizontal />

					{/* form section validator */}
					{FormSectionCheckValidity(
						<h3 id={'form-section-4'}>{4 - Number(!uploadEnabled)}. Price</h3>,
						[ 'price' ]
					)}
					{/* price */}
					<div className={classes.CreditsWrap}>
						<div className={classes.CreditsLabel}>$</div>
						<Input
							className={classes.CreditInput}
							config={this.state.formControls.price}
							onChange={(e) => this.inputChangeHandler(e, 'price')}
							onBlur={(e) => this.inputBlurHandler(e, 'price')}
						/>
						<span className={classes.LabelAfterPrice}>per student</span>
					</div>
					<p
						style={
							this.state.formControls.price.error === '' ? (
								{ marginTop: '-14px', marginBottom: '14px' }
							) : (
								{ marginTop: '0px', marginBottom: '14px' }
							)
						}>
						InfoDepot commission will be deducted from<br />
						the total sales{' '}
						<Link to="/fees-info" target="_blank">
							Learn more.
						</Link>
					</p>

					<Divider className={classes.Divider} horizontal />

					<div className={classes.FormProgress}>
						<div className={classes.Label}>Finished steps</div>
						<div className={classes.ValidGroupLegend}>
							{// if upload isn't enabled remove the checker render of validation for video upload
							uploadEnabled && (
								<div>
									{FormSectionCheckValidity(
										<a href="#form-section-1">
											<h3>1.</h3>
										</a>,
										null,
										[ videoName ]
									)}
								</div>
							)}
							<div>
								{FormSectionCheckValidity(
									<a href="#form-section-2">
										<h3>{2 - Number(!uploadEnabled)}.</h3>
									</a>,
									[ 'name', 'description', 'language' ]
								)}
							</div>
							<div>
								{FormSectionCheckValidity(
									<a href="#form-section-3">
										<h3>{3 - Number(!uploadEnabled)}.</h3>
									</a>,
									[ 'categories', 'subcategories', 'level' ],
									[ coverImage ]
								)}
							</div>
							<div>
								{FormSectionCheckValidity(
									<a href="#form-section-4">
										<h3>{4 - Number(!uploadEnabled)}.</h3>
									</a>,
									[ 'price' ]
								)}
							</div>
						</div>
					</div>

					{/* automatically publish */
					publishControlRender()}

					{/* fee charge info, only for creating the video 
					{!editMode && (
						<div
							className={classes.Label}
							style={{ textAlign: 'center', marginBottom: '30px' }}>
							InfoDepot Platform Fee For a Video:{' '}
							<span className={classes.TextGreen}>
								${infoDepotFee && (infoDepotFee.createClassFee * 1).toFixed(2)}
							</span>
						</div>
					)}*/}
					{editMode ? (
						<Button
							fullWidth={true}
							size="large"
							type="submit"
							disabled={
								!this.state.coverImage ||
								this.state.loading ||
								!this.state.formIsValid ||
								!this.state.videoName
							}
							loading={Number(loading)}>
							SAVE CHANGES
						</Button>
					) : (
						<Button
							fullWidth={true}
							size="large"
							type="submit"
							disabled={
								!this.state.coverImage ||
								!this.state.videoName ||
								this.state.loading ||
								!this.state.formIsValid
							}
							loading={Number(loading)}>
							CREATE VIDEO CLASS
						</Button>
					)}
				</Form>
			);

		return (
			<div className={classes.Container}>
				{uploadingOverlay && (
					<div className={classes.UploadingOverlay}>
						<div>
							<p>
								Your video is being uploaded. Please stay on this page until it's done.
							</p>
							{/* upload progress video */}
							<span className={classes.PercentTicker}>
								{videoUploadPercent === null ? '0' : videoUploadPercent} %
							</span>
							<div className={classes.ProgressVideo}>
								<span
									style={{
										width: videoUploadPercent ? videoUploadPercent + '%' : 0
									}}
								/>
							</div>
						</div>
					</div>
				)}

				{promptToUpgradeSubscription && <PromptToUpgradeSubscription from='createVideo' />}
				<h1 className={promptToUpgradeSubscription ? classes.Untouchable : ''}>{mainTitle}</h1>
				
				<Card
					loading={Number(!initialData || !categoriesLoaded || !levelsLoaded)}
					className={[classes.Card, promptToUpgradeSubscription ? classes.Untouchable : ''].join(' ')}>
						{this.props.user.type === 'student' ?
							<h4 style={{color: "black",fontWeight: "bold", textAlign:"center"}}>Only users registered as teachers can access this page.</h4>
							: <>{formRender}</>
						}
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
		updateLoggedUser: () => dispatch(actions.getLoggedUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVideo);
