import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import Modal from "../../components/UI/Modal/Modal";
import Button from "../../components/UI/Button/Button"
import 'react-image-crop/dist/ReactCrop.css';
import classes from "./TeacherProfile.module.scss"

import * as actions from '../../store/actions/indexActions'
import {uploadCoverPhoto} from '../../services/user/userService'


class ImageCropDialog extends Component {
  state = {
    crop: {
      unit: '%',
      width: 100,
      height: 50,
      y: 23,
      aspect: 16 / 9
    },
    croppedImage: null,
    loading: false
  };
  showCropImageModal() {
    const actions = [
      <Button
      disabled={Boolean(!this.state.croppedImage)}
      className={classes.Button}
      color="primary"
      variant="contained"
      loading={this.state.loading}
      size="large"
      onClick={this.handleSubmit}
      style={{ marginTop: '24px' }}
    >
      Save
    </Button>
    ];
    if (this.props.modalCropOpen) {
        const { crop } = this.state;
      return (
        <div>
          <Modal
            className={[classes.Card, classes.CardWithBorder].join(" ")}
            onClose={this.props.closeModal}
            open={this.props.modalCropOpen}
            autoScrollBodyContent={true}  
            modalScrollable={true}
          >
            <h3 style={{ fontSize: "30px", color: "#272727" }}>
            Change cover image
            </h3>
        <p style={{textAlign: "left", fontSize: "16px"}}>Move and resize the selection until you are happy with the preview and then click Save. </p>
        <div>
          <div
          >
            <ReactCrop
              src={this.props.image}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
            <div>
              {actions}
            </div>
          </div>
        </div>
      </Modal>

        </div>
      );
    }
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };



  // handleCancel = () => {
  //   this.props.closeModal()
  // }

  handleSubmit = () => {
      if (this.state.croppedImage) {
        this.setState({ loading: true });
        uploadCoverPhoto(this.props.token, this.state.croppedImage)
				.then((response) => {
					this.setState({ loading: false });
                    this.props.closeModal();
					this.updateStore();
				})
				.catch((err) => {
					this.setState({ loading: false });
					this.showError(err.response.data && err.response.data.message);
                    this.props.closeModal();
				});
      }
		}

    updateStore = () => {
      this.props.updateLoggedUser();
    };
  
    showError = (message) => {
      this.props.snackbarAdd({
        message: message,
        type: 'error',
        timeout: 8000
      });
    };
    
  // handleCropClose = () => {
  //   let { crop } = this.state;
  //
  //
  //   const croppedImg = this.getCroppedImg(this.refImageCrop, crop);
  //   this.setState({ showImageCropper: false, croppedImage: croppedImg })
  //   this.props.closeModal()
  // }

  async makeClientCrop(crop) {
    if (!this.state.loading) {
      if (this.imageRef && crop.width && crop.height) {
        this.setState({croppedImage: null});
        const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
        );
        this.setState({ croppedImageUrl });
      }
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
          this.setState({croppedImage: blob})
        },
        'image/jpeg',
        1
      );
    });
  }

  // handleOpen = () => {
  //   this.setState({ showImageCropper: true });
  // }

  render() {
    return (
        <div>
        { this.showCropImageModal() }
        </div>

    );
  }
}

const mapStateToProps = (state) => {
	return {
		userData: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
		updateLoggedUser: () => dispatch(actions.getLoggedUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropDialog);
