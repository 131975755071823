import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactSVG from "react-svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import clone from "lodash.clonedeep";

import classes from "./LiveCall.module.scss";
import * as callService from "../../services/liveCall/liveCallService";
import apiConfig from "../../utils/apiConfig";
import * as screen from "../../utils/common.js";
import DescriptionTab from "./TabsRender/DescriptionTab/DescriptionTab";
import ReviewsTab from "./TabsRender/ReviewsTab/ReviewsTab";
import Card from "../../components/UI/Card/Card";
import Button from "../../components/UI/Button/Button";
import Avatar from "../../components/UI/Avatar/Avatar";
import Tabs from "../../components/UI/Tabs/Tabs";
import IconEdit from "./../../assets/images/icons/pen_icon.svg";
import IconLiveCall from "./../../assets/images/icons/live_call_icon.svg";
import IconHourGlass from "./../../assets/images/icons/hour_glass_icon.svg";
import IconMute from "./../../assets/images/icons/live_call_offline_icon.svg";
import RateWidget from "../../components/Ratings/RateWidget/RateWidget";
import LiveCallModal from "../../components/LiveCallModal/LiveCallModal";
import IconVideoSlash from "./../../../src/assets/images/icons/video-slash-solid.svg";
import IconVideoBasic from "./../../../src/assets/images/icons/video-solid.svg";
import SignUpModal from "../../components/SignUpModal/SignUpModal";
import CustomSelect from "../../components/UI/Select/Select";
import TwilioVideo from "../../components/TwilioVideo/TwilioVideo";
import Follow from "../Follow/Follow";
import { ChosenDisplayName, shouldDisplayUsernameSection } from "../../components/UI/ChosenDisplayName/ChosenDisplayName";
import PromptToUpgradeSubscriptionWide from "../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscriptionWide";

const intervalAutorefresh = (fn) => setInterval(fn, 5000);

class LiveCall extends Component {
  state = {
    call: null,
    loading: false,
    activeTab: null,
    modalLiveCallOpened: false,
    modalSignUpOpened: false,
    screenWidth: null,
    modalCallType: "",
    roomName: "",
    roomToken: "",
    participient: "",
    videoName: "",
    twilioVideo: false,
    participientUrlImage: "",
  };

  openModal = () => {
    if (this.props.user) {
      this.setState({ modalLiveCallOpened: true });
    } else {
      this.openSignUpModal();
    }
  };

  openModalVideo = () => {
    if (this.props.user) {
      this.setState({ modalLiveCallOpened: true, modalCallType: "video" });
    } else {
      this.openSignUpModal();
    }
  };

  openModalPhone = () => {
    if (this.props.user) {
      this.setState({ modalLiveCallOpened: true, modalCallType: "phone" });
    } else {
      this.openSignUpModal();
    }
  };

  closeModal = (
    room,
    roomToken,
    participient,
    videoName,
    twilioVideo,
    participientUrlImage
  ) => {
    this.setState({
      modalLiveCallOpened: false,
      selectedCall: null,
      modalVideoCall: false,
      roomName: room,
      roomToken: roomToken,
      participient: participient,
      videoName: videoName,
      twilioVideo: twilioVideo,
      participientUrlImage: participientUrlImage,
    });
  };

  //close twilio video
  closeTwilioVideo = () => {
    this.setState({
      twilioVideo: false,
    });
    console.log("delete");
  };

  openSignUpModal = () => {
    this.setState({ modalSignUpOpened: true });
  };

  closeSignUpModal = () => {
    this.setState({ modalSignUpOpened: false });
  };

  async componentDidMount() {
    const callId = this.props.match.params.id;
    const query = new URLSearchParams(this.props.location.search);
    const autoOpenModal = query.get("call-modal");
    const autoOpenModalVideo = query.get("call-modal-video");

    window.scrollTo(0, 0);

    //Check which type of button is clicked before redirection
    if (autoOpenModalVideo == 1) {
      this.setState({ modalCallType: "video" });
    } else if (autoOpenModal == 1) {
      this.setState({ modalCallType: "phone" });
    }

    const activeTabAsync = new Promise((resolve) => {
      setTimeout(() => {
        const queryTab = this.props.match.params.tab;
        const activeTab = queryTab || "description";

        if (!queryTab) {
          this.props.history.push(this.props.match.url + "/" + activeTab);
        }
        resolve(activeTab);
      }, 100);
    });

    this.setState({
      activeTab: await activeTabAsync,
      modalLiveCallOpened: autoOpenModal || autoOpenModalVideo,
    });

    if (callId) {
      callService
        .getLiveCall(this.props.token, callId)
        .then((response) => {
          this.setState({
            call: response.data,
          });

          setTimeout(() => {
            this.autoRefreshStatus = intervalAutorefresh(this.getCallStatus);
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      this.props.history.replace("/account");
    }
    this.setState({ screenWidth: screen.mq() });

    //Meta tags
    let metatitle = document.getElementsByName("title")[0];

    document.title = this.props.match.params.id + " | InfoDepot";
    metatitle.setAttribute(
      "content",
      this.props.match.params.id + " | InfoDepot"
    );
  }

  componentWillUnmount() {
    clearInterval(this.autoRefreshStatus);
  }

  getCallStatus = () => {
    callService
      .getCallStatusList(this.state.call.slug)
      .then((response) => {
        this.setState((state) => {
          const callCopy = clone(state.call);
          callCopy.liveCallStatus = response.data[0].liveCallStatus;
          callCopy.author.liveCallQueuesCount =
            response.data[0].author.liveCallQueuesCount;
          return { call: callCopy };
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleTabChange = (event, value) => {
    const cleanUrl = this.props.match.url;

    this.props.history.push(
      cleanUrl.substring(0, cleanUrl.lastIndexOf("/" + 1)) + value
    );
    this.setState({ activeTab: value });
  };

  handleOptionChange = (event, value) => {
    const cleanUrl = this.props.match.url;
    this.props.history.push(
      cleanUrl.substring(0, cleanUrl.lastIndexOf("/" + 1)) + event.target.value
    );
    this.setState({ activeTab: event.target.value });
    // this.props.history.push('/account/' + value);
    // console.log('Option change', value)
  };

  render() {
    const {
      call,
      activeTab,
      loading,
      modalLiveCallOpened,
      modalSignUpOpened,
      modalCallType,
      screenWidth,
    } = this.state;

    const { user } = this.props;

    const isAuthor = () =>
      Boolean(call && user && user.fullName === call.author.fullName);

    const loaderRender = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <CircularProgress />
      </div>
    );

    const CallCTA = () => {
      if (!isAuthor()) {
        switch (call && call.liveCallStatus) {
          case "available":
            return (
              <Button
                className={[classes.Button, classes.BtnLiveCall].join(" ")}
                size="large"
                onClick={this.openModalPhone}
              >
                <div className={classes.BtnContentWrapp}>
                  <ReactSVG
                    className={classes.IconLiveCall}
                    src={IconLiveCall}
                  />{" "}
                  <div>
                    <span className={classes.BtnSpanInfo}>
                      Get a phone call
                    </span>
                    <span className={classes.BtnSpanInfo}>
                      We’ll call your cell phone
                    </span>
                  </div>
                </div>
              </Button>
            );
          case "queued":
            return (
              <Button
                className={[classes.Button, classes.BtnLiveCall].join(" ")}
                size="large"
                color="secondary"
                onClick={this.openModalPhone}
              >
                <div className={classes.BtnContentWrapp}>
                  <ReactSVG
                    className={classes.IconLiveCall}
                    src={IconHourGlass}
                  />{" "}
                  <div>
                    <span className={classes.BtnSpanInfo}>
                      Queue for phone call
                    </span>
                    <span className={classes.BtnSpanInfo}>
                      We’ll call your cell phone
                    </span>
                  </div>
                </div>
              </Button>
            );
          default:
            return (
              <Button
                className={[
                  classes.Button,
                  classes.BtnLiveCall,
                  classes["Button--disabled"],
                ].join(" ")}
                size="large"
                color="primary"
                disabled="true"
              >
                <div className={classes.BtnContentWrapp}>
                  <ReactSVG className={classes.IconLiveCall} src={IconMute} />{" "}
                  <div>
                    <span className={classes.BtnSpanInfo}>
                      Currently offline
                    </span>
                    <span className={classes.BtnSpanInfo}>for phone calls</span>
                  </div>
                </div>
              </Button>
            );
        }
      }
    };

    const CallCTAVideo = () => {
      if (!isAuthor()) {
        switch (call && call.videoLiveCallStatus) {
          case "available":
            return (
              <Button
                className={[classes.Button, classes.BtnLiveCall].join(" ")}
                size="large"
                onClick={this.openModalVideo}
              >
                <div className={classes.BtnContentWrapp}>
                  <ReactSVG
                    className={classes.IconLiveCall}
                    src={IconVideoBasic}
                  />{" "}
                  <div>
                    <span className={classes.BtnSpanInfo}>
                      Start a video call
                    </span>
                    <span className={classes.BtnSpanInfo}>
                      on InfoDepot website
                    </span>
                  </div>
                </div>
              </Button>
            );
          case "queued":
            return (
              <Button
                className={[classes.Button, classes.BtnLiveCall].join(" ")}
                size="large"
                color="secondary"
                onClick={this.openModalVideo}
              >
                <div className={classes.BtnContentWrapp}>
                  <ReactSVG
                    className={classes.IconLiveCall}
                    src={IconHourGlass}
                  />{" "}
                  <div>
                    <span className={classes.BtnSpanInfo}>
                      Queue for a video call
                    </span>
                    <span className={classes.BtnSpanInfo}>
                      on InfoDepot website
                    </span>
                  </div>
                </div>
              </Button>
            );
          default:
            return (
              <Button
                className={[
                  classes.Button,
                  classes["Button--disabled"],
                  classes.BtnLiveCall,
                ].join(" ")}
                size="large"
                color="primary"
                disabled="true"
              >
                <div className={classes.BtnContentWrapp}>
                  <ReactSVG
                    className={classes.IconLiveCall}
                    src={IconVideoSlash}
                  />{" "}
                  <div>
                    <span className={classes.BtnSpanInfo}>
                      Currently offline
                    </span>
                    <span className={classes.BtnSpanInfo}>
                      for a video calls
                    </span>
                  </div>
                </div>
              </Button>
            );
        }
      }
    };

    // console.log('test', this.state.modalCallType)
    const CallOverlay = (
      <div className={classes.LiveCallOverLay}>
        <Avatar
          className={classes.TeacherImage}
          size={114}
          src={
            call && call.author.profile.photo && call.author.profile.photo.big
          }
        />
        <div>
          {/* <h3 className={classes.TeacherName}>
            {call && <Link to={`/profile/${call.author.profileUrl}`} className={classes.TeacherLink}>{call.author.profile.name}</Link>}
          </h3> */}
          <h3 className={classes.TeacherName}>
            {call && <Link to={`/profile/${call.author.profileUrl}`} className={classes.TeacherLink}><ChosenDisplayName user={call.author} top="-1px" /></Link>}
          </h3>

          {screenWidth > 1024 ? (
            <React.Fragment>
              <h1 className={classes.LiveCallName}>
                {call ? call.name : "Loading call..."}{" "}
              </h1>
              <div>
                {call && call.purchased ? (
                  ""
                ) : (
                  <span className={classes.Price}>
                    ${call ? call.price.toFixed(2) : "Loading..."}/
                    <span>min</span>
                  </span>
                )}
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
          {(call && call.scheduleDays !== null) ||
          (call && call.scheduleCustomDays.length > 0)
            ? CallCTA()
            : ""}
          {(call && call.videoScheduleDays !== null) ||
          (call && call.videoScheduleCustomDays.length > 0)
            ? CallCTAVideo()
            : ""}
        </div>
      </div>
    );

    const tabProperties = [
      {
        value: "description",
        label: "Description",
        content: (
          <DescriptionTab
            call={call}
            statusCall={this.props.user ? this.props.user.statusCall : ""}
          />
        ),
      },
      {
        value: "reviews",
        label: "Reviews",
        content: (
          <ReviewsTab
            call={call}
            callCta={CallCTA}
            callCtaVideo={CallCTAVideo}
            user={user}
            button=""
          />
        ),
      },
    ];

    return (
      <Card loading={Number(!call)} noribbon={1} className={classes.Container}>
        {isAuthor() && call && call.hidden && <PromptToUpgradeSubscriptionWide from="singleCall" />}
        <div className={classes.CoverImageWrap}>
          <img
            className={classes.CoverImage}
            src={
              call && call.cover ? apiConfig.DOMAIN_IMAGE + call.cover.big : ""
            }
            alt="Live tutoring call cover"
          />
          {CallOverlay}
        </div>
        {/* Title */}
        <div className={classes.TitleWrap}>
          <section>
            <h1>
              {call ? call.name : "Loading call..."}{" "}
              {call && call.purchased ? (
                ""
              ) : (
                <span className={classes.Price}>
                  ${call ? call.price.toFixed(2) : "Loading..."}/
                  <span>min</span>
                </span>
              )}
            </h1>

            {call && !call.hidden && isAuthor() && (
              <Link to={"/edit-live-call/" + call.slug}>
                <Button color="secondary">
                  <ReactSVG className={classes.IconEdit} src={IconEdit} />
                  <span>Edit</span>
                </Button>
              </Link>
            )}
          </section>
        </div>

        {/* Live Call or SignUp Modal */}
        {user ? (
          <LiveCallModal
            open={modalLiveCallOpened}
            onClose={this.closeModal}
            call={call}
            type={modalCallType}
          />
        ) : (
          <SignUpModal
            open={modalSignUpOpened}
            onClose={this.closeSignUpModal}
            onAuthReturnToUrl={call && "/call/" + call.slug}
          />
        )}

        {this.state.twilioVideo && this.state.roomToken !== "" ? (
          <TwilioVideo
            roomName={this.state.roomName}
            roomToken={this.state.roomToken}
            incomingCallUser={this.state.participient}
            videoName={this.state.videoName}
            photoUrl={this.state.participientUrlImage}
            closeTwilioVideo={this.closeTwilioVideo}
          />
        ) : (
          ""
        )}

        {/* Details */}
        <div className={classes.InfoSection}>
          {/* Tabs */}
          {screenWidth > 1024 ? (
            <Tabs
              className={classes.Tabs}
              activetab={activeTab}
              config={tabProperties}
              onChangeTab={(e, val) => this.handleTabChange(e, val)}
            />
          ) : (
            <div className={classes.SelectContainer}>
              <CustomSelect
                activetab={activeTab}
                config={tabProperties}
                onChangeOption={(e, val) => this.handleOptionChange(e, val)}
              />
            </div>
          )}
          <aside>
            {
              /* rate aside */
              <RateWidget srcData={call} type={"call"} />
            }

            {/* teacher aside */}
            <h5>About the teacher</h5>
            <div className={classes.AuthorWrap}>
              <div className={classes.AuthorInner}>
                <Avatar
                  size={62}
                  src={
                    call &&
                    call.author.profile.photo &&
                    call.author.profile.photo.small
                  }
                  className={classes.Avatar}
                />
                <div>
                  <span>
                      <strong>{call && <Link to={`/profile/${call.author.profileUrl}`}><ChosenDisplayName user={call.author} top="-1px" /></Link>}</strong>
                  </span>
                  {call && shouldDisplayUsernameSection(call.author) && <span>{call && call.author.username}</span>}
                  {this.props.user ? (
                    call &&
                    call.author &&
                    call.author.enabled && (
                      <>
                        {" "}
                        <div className={classes.ButtonWrap}>
                          <Follow teacher={call.author} />

                          {call &&
                            call.author &&
                            call.author.cryptName !==
                              this.props.user.cryptName &&
                            call.author.enabled && (
                              <Link to={`/messages/${call.author.cryptName}`}>
                                <Button
                                  type="contained"
                                  size="small"
                                  style={{
                                    fontSize: "13px!important",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Message
                                </Button>
                              </Link>
                            )}
                        </div>
                      </>
                    )
                  ) : (
                    <div className={classes.ButtonWrap}>
                      <Button
                        type="contained"
                        size="small"
                        onClick={() => this.openSignUpModal()}
                        style={{ fontSize: "13px!important" }}
                      >
                        Follow
                      </Button>
                      <Button
                        type="contained"
                        size="small"
                        style={{
                          fontSize: "13px!important",
                          marginLeft: "10px",
                        }}
                        onClick={() => this.openSignUpModal()}
                      >
                        Message
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <p>{call && call.author.profile.introAboutMe}</p>
            </div>
          </aside>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(LiveCall);
