import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReactSVG from 'react-svg'

import classes from './ReviewsTab.module.scss'
import RateModal from '../../../../components/Ratings/RateModal/RateModal'
import * as ratingService from '../../../../services/rating/ratingService'
import Review from '../../../../components/Ratings/Review/Review'
import Button from '../../../../components/UI/Button/Button'
import IconStarEmpty from '../../../../assets/images/icons/star_empty_icon.svg'
import IconLiveCall from '../../../../assets/images/icons/live_call_icon.svg'
import IconHourGlass from '../../../../assets/images/icons/hour_glass_icon.svg'
import IconMute from '../../../../assets/images/icons/live_call_offline_icon.svg'
import Pagination from '../../../../components/UI/Pagination/Pagination'

let paginationConf = {
  currentPage: 1,
  pageLimit: 5,
  total: null
}

const loaderRender = (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1rem'
    }}
  >
    <CircularProgress />
  </div>
)
class ReviewsTab extends React.PureComponent {
  state = {
    loading: true,
    modalReview: false,
    ratings: null,
    callSlug: null
  }

  getReviews = (offset = 0) => {
    const slug = this.props.call && this.props.call.slug

    this.setState({ loadingMore: true })
    ratingService
      .getClassReviews(
        this.props.token,
        'call',
        slug,
        `&limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then(response => {
        paginationConf.total = response.data.length
          ? response.data[0]['_total']
          : null

        this.setState(state => {
          const loadedReviews = state.ratings || []
          const responseReviews = response.data || []
          return {
            ratings: [...loadedReviews, ...responseReviews],
            loading: false,
            loadingMore: false
          }
        })
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  componentDidMount () {
    setTimeout(() => {
      this.getReviews()
    }, 1000)
  }

  onHandleSuccessReview = () => {
    this.setState({ loading: true, ratings: null })
    setTimeout(() => {
      this.onHandleCloseReview()
      this.getReviews()
    }, 1000)
  }

  handlePaginationchange = pag => {
    if (pag.currentPage && pag.currentPage !== paginationConf.currentPage) {
      paginationConf.currentPage = pag.currentPage
      this.getReviews((pag.currentPage - 1) * pag.pageLimit)
    }
  }

  render () {
    const { loading, loadingMore, ratings } = this.state
    const { call, user } = this.props

    const callCTA = this.props.callCta;
    const callCTAVideo = this.props.callCtaVideo;

    const isAuthor = (user && user.username) === (call && call.author.username)

    const isTeacher = user && user.type == 'teacher';
    //console.log((user && user.username) === (call && call.author.username))

    const LeaveReview =  !isAuthor && (
      <div className={classes.LeaveReview}>
        <p style={{ textAlign: 'center' }}>
          We accept reviews from users after the tutoring call is completed. You can leave a review in <a href="/account/calls/video">your calls</a> list.
        </p> 
        <div className={classes.ButtonWrapp}>
          {callCTA()}
          {callCTAVideo()}
        </div>
      </div>
    )

    const ReviewList = (
      <React.Fragment>
        <div>
          {ratings &&
            ratings.length &&
            ratings.map(rating => <Review review={rating} call={call} />)}
        </div>
      </React.Fragment>
    )

    const NoReviews = (
     <div>
      <div className={classes.NoResults}>
        <ReactSVG src={IconStarEmpty} className={classes.IconEmptyStar} />
        <p>No reviews yet</p>
      </div>
     </div>
    )

    const Content = (
      <div>
        {LeaveReview}
        {ratings && ratings.length ? ReviewList : NoReviews}
        {ratings && ratings.length && paginationConf.total > ratings.length ? (
          <Pagination
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationchange}
            loadMore
            loading={loadingMore}
          />
        ) : null}
      </div>
    )
    return loading ? loaderRender : Content
  }
}

export default ReviewsTab
