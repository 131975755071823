import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "../MyAccount/ClassesList/MyVideos/MyVideos.module.scss";
import Pagination from "../../components/UI/Pagination/Pagination";
import { getUserProfile } from "../../services/user/userService";
import { Input } from "../../components/UI/Form/Form";
import clone from "lodash.clonedeep";
import { withRouter } from "react-router-dom";
import * as followingService from "../../services/following/followingService";
import Grid from "@material-ui/core/Grid";
import List from "../../components/UI/List/List";
import * as screen from "../../utils/common";
import ReactSVG from "react-svg";
import IconFollower from "../../assets/images/icons/follower_icon.svg";
import IconSearch from "../../assets/images/icons/search_icon_blue.svg";

let paginationConf = {
  pageLimit: 20,
  total: null,
};

class ProfileFollowers extends Component {
  state = {
    loading: true,
    profileWatching: null,
    followers: [],
    hideSearch: true,
    buttonSpinner: null,
    searchControl: {
      id: "search-input",
      type: "text",
      placeholderStatic: "Search",
      adornment: {
        position: "start",
        icon: () => (
          <ReactSVG src={IconSearch} svgClassName={classes.IconSearchInput} />
        ),
      },
      value: "",
      validation: {},
    },
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.init();
    }
  }

  init() {
    const profileUrl = this.props.match.params.slug;
    if (profileUrl) {
      getUserProfile(this.props.match.params.slug, this.props.token)
        .then((response) => {
          this.setState(
            {
              profileWatching: response.data,
            },
            () => {
              this.getFollowers(response.data.cryptName);
            }
          );
        })
        .catch((error) => {});
    }
  }

  getFollowers = (cryptName, offset = 0) => {
    let queryParams = `?limit=${paginationConf.pageLimit}&offset=${offset}&cryptName=${cryptName}`;
    if (this.state.searchControl.value) {
      queryParams = queryParams + `&search=${this.state.searchControl.value}`;
    }
    followingService
      .getFollowers(this.props.token, queryParams)
      .then((response) => {
        paginationConf.total = response.data.meta
          ? Number(response.data.meta.total)
          : 0;

        this.setState({
          loading: false,
          followers: response.data.data ? response.data.data : [],
          hideSearch: response.data.data.length < 1 && !this.state.searchControl.value,
        });
      })
      .catch((error) => {});
  };

  handlePaginationChange = (pag) => {
    this.getFollowers(
      this.state.profileWatching.cryptName,
      (pag.currentPage - 1) * pag.pageLimit
    );
  };

  clearPaginationConf = () => {
    paginationConf = {
      ...paginationConf,
      total: null,
      currentPage: 1
    };
  };

  deleteFollower = (id) => {
    if (!id) {
      return;
    }

    this.setState({
      buttonSpinner: id,
    });

    let cryptName = null;
    this.state.followers.filter((item) => {
      if (id === item.id) {
        cryptName = item.follower.cryptName;
      }
    });
    if (cryptName) {
      followingService
        .deleteFollowerOfTeacher(this.props.token, cryptName)
        .then((response) => {
          if (this.state.followers.length === 1) {
            //if last item was deleted(can be on pagination) reload new data
            this.setState({ loading: true });
            this.getFollowers(this.state.profileWatching.cryptName);
          }
          this.setState({
            followers: this.state.followers.filter((follower) => {
              return follower.follower.cryptName !== cryptName;
            }),
          });
          this.props.decreaseNumberOfFollowers();
        })
        .catch((error) => {
        });
    }
  };

  onHandleSearchChange = (e) => {
    this.clearPaginationConf();
    const controlCopy = clone(this.state.searchControl);
    controlCopy.value = e.target.value;

    this.setState({ searchControl: controlCopy }, () => {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(this.getFollowers, 300, this.state.profileWatching.cryptName, 0);
    });
  };

  render() {
    const { loading, followers, profileWatching } = this.state;
    const loaderRender = <CircularProgress className={classes.VideoLoader} />;
    const teacherWatchSelf = this.props.user && profileWatching && this.props.user.cryptName === profileWatching.cryptName;
    const renderContent = () =>
      followers && followers.length > 0 ? (
        <Grid container>
          <List
            watchPublicProfile={true}
            watchOwnProfile={teacherWatchSelf}
            data={followers}
            typeList="followers"
            deleteFollower={this.deleteFollower}
            buttonSpinner={this.state.buttonSpinner}
            screenWidth={screen.mq()}
          />
        </Grid>
      ) : (
        <React.Fragment>
          <section className={classes.SectionEmpty}>
            <ReactSVG src={IconFollower} className={classes.EmptyIcon} />
            <h3 className={classes.SectionHeadline}>
              {!this.state.searchControl.value ? (
                "No followers yet."
              ) : (
                "No search results."
              )}
            </h3>
            {teacherWatchSelf && (
              <>
                <p style={{maxWidth: "600px", textAlign: "center", color: "#b6c1cb", fontWeight: "600"}}>
                    Students can follow you to get notified when you add a new class/call or when you are available for live tutoring.
                </p>
              </>
            )}
          </section>
        </React.Fragment>
      );

    return (
      <div className={classes.Container}>
        <div>
          {!this.state.hideSearch && (
            <Input
              className={classes.InputSearch}
              onChange={(e) => this.onHandleSearchChange(e)}
              config={this.state.searchControl}
            />
          )}
        </div>
        {loading ? loaderRender : renderContent()}
        {paginationConf.total && followers.length > 0 ? (
          <Pagination
            style={{ marginTop: "40px" }}
            totalRecords={paginationConf.total}
            pageLimit={paginationConf.pageLimit}
            pageNeighbours={1}
            onPageChanged={this.handlePaginationChange}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(ProfileFollowers));
