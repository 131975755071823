import React from 'react';
import Collapse from '@material-ui/core/Collapse';

import classes from './RateWidget.module.scss';
import RateStars from '../RateStars/RateStars';

class RateWidget extends React.PureComponent {
	render() {
		const src = this.props.srcData;

		const type = this.props.type;

		const count = (type === 'call') ? ' ' : `of ${src && src.purchasesCount}`;

		

		const plural = src && src.numberOfRating === 1 ? '' : 's'; 

		return (
			<div className={classes.Container}>
				<Collapse in={src} timeout={750}>
					<section>
						<h5>Avarage rating</h5>
						<h1>{src && Number(src.averageRating).toFixed(1)}</h1>
						<RateStars size="large" rate={src && src.averageRating} />
						<div className={classes.TotalRated}>
							{src && src.numberOfRating ? `${src.numberOfRating} ${count} ${(type === 'video') ? 'student' + plural + ' reviewed' : 'review' + plural } ` : `No reviews yet.`}
						</div>
					</section>
				</Collapse>
			</div>
		);
	}
}

export default RateWidget;
