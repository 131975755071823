import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactSVG from 'react-svg'

import classes from './StartTeaching.module.scss'
import Card from '../../components/UI/Card/Card'
import Button from '../../components/UI/Button/Button'
import IconVideo from '../../assets/images/icons/video class icon.svg'
import IconPhone from '@material-ui/icons/PhoneInTalk'
import * as configurationService from '../../services/configuration/configurationService'
import { CircularProgress } from '@material-ui/core'

class StartTeaching extends Component {
  constructor (props) {
    super(props)
    this.state = {
      feeData: null
    }
  }

  componentDidMount () {
    configurationService
      .getInfoDepotFee()
      .then(response => {
        const fee = response.data
        this.setState({
          feeData: fee
        })
      })
      .catch(error => {
        console.log(error)
        return error.response
      })
    window.scrollTo(0, 0)
  }

  render () {
    const infoDepotFee = this.state.feeData

    return (
      <div className={classes.Container}>
        <h1>Start teaching</h1>
        <div className={classes.SubHeader}>Select an option below:</div>
        <div className={classes.CardsWrap}>
          <Card color='orange' className={classes.Card}>
            <ReactSVG src={IconVideo} svgClassName={classes.IconSvg} />
            <h2>Video Class</h2>
            <p>
              Upload your video lesson and share your skills with millions of
              students all over the world.
            </p>

            {/* <div className={classes.FeeInfo}>
              <strong>
                InfoDepot fee:
                <span className={classes.ColorMain}>
                  {' '}
                  ${infoDepotFee ? (
                    `${infoDepotFee &&
                      (infoDepotFee.createClassFee * 1).toFixed(2)}`
                  ) : (
                    <CircularProgress
                      style={{ height: '15px', width: '15px' }}
                    />
                  )}{' '}
                </span>
              </strong>
            </div> 
            <Link className={classes.MoreLink} to='/fees-info' target='_blank'>
              Learn more
            </Link>*/}
            <Link to='/create-video'>
              <Button size='large'>CREATE VIDEO CLASS</Button>
            </Link>
          </Card>

          <Card color='yellow' className={classes.Card}>
            <IconPhone className={classes.IconUI} />
            <h2>Live Tutoring Calls</h2>
            <p>
              Give a private consultation to a student when they need extra
              help.
            </p>
            <Link to='/set-up-live-call'>
              <Button size='large'>SET UP LIVE CALL</Button>
            </Link>
          </Card>
        </div>
      </div>
    )
  }
}
export default StartTeaching
