import React, { Component } from 'react';
import clone from 'lodash.clonedeep';
import { withRouter } from 'react-router-dom';

import classes from '../AccountSettings.module.scss';
import * as validator from '../../../utils/formValidator';
import Button from '../../../components/UI/Button/Button';
import { Form, Input, NativeSelect } from '../../../components/UI/Form/Form';
import * as userService from '../../../services/user/userService';
import { errorParser } from '../../../utils/errorParser';
import { phoneCodes } from '../../../shared/json/phoneCodes';
import { isUrlQueued, goToNextQueuedUrl } from '../../../utils/redirectTo';

class PersonalSettings extends Component {
	state = (() => {
		const user = this.props.user;
		return {
			loading: false,
			formIsValid: false,
			formControls: {
				phoneCountryCode: {
					id: 'phoneCountryCode',
					type: 'text',
					select: true,
					native: true,
					// formating the object of state names into array with proper value/label pairs to be accepted by input select
					selectList: phoneCodes.reduce(
						(agg, cur) => {
							agg.push({ value: cur.code, label: `${cur.name} (${cur.dial_code})` });
							return agg;
						},
						[ { value: '', label: '' } ]
					),
					placeholder: 'Country code',
					placeholderStatic: '',
					shrink: true,
					value: user.profile.phoneNumberCountryCode
						? phoneCodes.filter((cur, i) => {
								return '+' + user.profile.phoneNumberCountryCode === cur.dial_code;
							})[0].code
						: 'US',
					dialCode: user.profile.phoneNumberCountryCode
						? phoneCodes.filter((cur, i) => {
								return '+' + user.profile.phoneNumberCountryCode === cur.dial_code;
							})[0].dial_code
						: '+1',
					validation: {},
					error: '',
					valid: false,
					touched: true
				},
				phoneNumber: {
					id: 'phoneNumber',
					type: 'text',
					placeholder: 'Phone number',
					placeholderStatic: 'Enter phone number',
					shrink: true,
					value: user.profile.phoneNumber
						? user.profile.phoneNumber.split('+' + user.profile.phoneNumberCountryCode)[1]
						: '',
					validation: {
						required: true,
						isNumeric: true
					},
					error: '',
					valid: false,
					touched: user.profile.phoneNumberCountryCode
				}
			}
		};
	})();

	// clears the errors and 'dirty' the input on change. Errors will be checked on blur
	clearInput = (inpt) => {
		inpt.touched = true;
		inpt.error = '';

		// if input is part of the group clear all group memebers
		if (inpt.group) {
			inpt.groupError = false;
		}
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if there is no value it is the radio/checkbox input, in that case take the name
		if (ctrl.type !== 'checkbox') {
			ctrl.value = e.target.value;
		} else {
			ctrl.value = ctrl.value === controlName ? '' : controlName;
		}
		// if input is part of the group highlight all the memeber of a group in case of an error
		if (ctrl.group) {
			ctrl.group.forEach((inputName) => {
				this.clearInput(formControlsCopy[inputName]);
			});
		} else {
			this.clearInput(ctrl);
		}

		// select proper dial country code based on value (country acronym)
		if (controlName === 'phoneCountryCode') {
			ctrl.dialCode = phoneCodes.filter((cur) => {
				return ctrl.value === cur.code;
			})[0].dial_code;
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	// checks validity of single input
	getInputError = (input) => {
		return validator.validateInput(input);
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if it is a group show error only underneath the first input
		if (ctrl.group) {
			const errorMsg = this.getInputError(ctrl);
			if (errorMsg) {
				// show the msg on first input
				formControlsCopy[ctrl.group[0]].error = errorMsg;
				// set error on all group fields
				ctrl.group.forEach((inputName) => {
					formControlsCopy[inputName].groupError = true;
				});
			}
		} else {
			ctrl.error = this.getInputError(ctrl);
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	prepareSubmitData() {
		const form = this.state.formControls;
		return {
			phoneNumber: {
				number: form.phoneNumber.value && form.phoneNumber.value.trim(),
				country: form.phoneCountryCode.value
			}
		};
	}

	submit(e) {
		e.preventDefault();
		// if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
		//e.target.focus();

		if (this.state.formIsValid) {
			this.setState({ loading: true });
			userService
				.updateContactInfo(this.prepareSubmitData(), this.props.token)
				.then((response) => {
					console.log(response);
					// update the store in the main component. As we are getting only the profile clone deep the rest of the data
					const clonedUserData = clone(this.props.user);
					clonedUserData.profile = response.data;
					this.setState({ loading: false });

					this.props.updateStore().then(() => {
						setTimeout(() => {
							//const queryParam = new URLSearchParams(this.props.location.search);
							if (isUrlQueued(this.props)) {
								goToNextQueuedUrl(this.props);
							}
						}, 50);
					});
				})
				.catch((error) => {
					console.log(error);
					const apiErr = errorParser(error.response.data.errors);
					console.log(apiErr);

					const cloneForm = clone(this.state.formControls);

					// config object to be used by checkFormApiErrors
					const errorStateConfig = {
						formPhoneNumberError: 'phoneNumber'
					};

					// checking form errors for matching pairs defined in the errorStateConfig
					validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

					this.setState({
						formIsValid: false,
						formControls: cloneForm,
						loading: false
					});
				});
		}
	}

	render() {
		const ButtonSaveBase = (text) => (
			<Button
				fullWidth={true}
				size="large"
				type="submit"
				disabled={this.state.loading || !this.state.formIsValid}
				loading={this.state.loading}>
				{text}
			</Button>
		);

		const ButtonSave =
			isUrlQueued(this.props)
				? ButtonSaveBase('SAVE AND GO BACK')
				: ButtonSaveBase('SAVE CHANGES');

		return (
			<Form onSubmit={(e) => this.submit(e)}>
				<h3>Phone number</h3>
				<div className={classes.SocialInputsWrap}>
					<NativeSelect
						style={{ width: 'calc(32% - 7px)' }}
						config={this.state.formControls.phoneCountryCode}
						onChange={(e) => this.inputChangeHandler(e, 'phoneCountryCode')}
						onBlur={(e) => this.inputBlurHandler(e, 'phoneCountryCode')}
						renderVal={this.state.formControls.phoneCountryCode.dialCode}
					/>
					<Input
						style={{ width: 'calc(68% - 7px)' }}
						config={this.state.formControls.phoneNumber}
						onChange={(e) => this.inputChangeHandler(e, 'phoneNumber')}
						onBlur={(e) => this.inputBlurHandler(e, 'phoneNumber')}
					/>
				</div>
				<p className={classes.PhoneNumberInfo}>
					This is your default number where all live calls are received
				</p>
				{ButtonSave}
			</Form>
		);
	}
}

export default withRouter(PersonalSettings);
