import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import windowWidth from 'react-window-size'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as liveCallService from './../../../../services/liveCall/liveCallService'
import * as actions from './../../../../store/actions/indexActions'
import IDTable from '../../../../components/UI/Table/Table'
import TabButtons from '../../../../components/UI/TabButtons/TabButtons';
import classes from './Calls.module.scss'
import { Link } from 'react-router-dom'
import IconLiveCall from '../../../../assets/images/icons/live_call_icon.svg'
import IconVideo from '../../../../assets/images/icons/video-solid.svg';
import Button from '../../../../components/UI/Button/Button'
import RateModal from '../../../../components/Ratings/RateModal/RateModal'
import Pagination from '../../../../components/UI/Pagination/Pagination'

const paginationConf = {
  pageLimit: 20,
  total: null
}

class Calls extends Component {
  state = {
    callsData: null,
    loading: true,
    modalRateOpened: false,
    slug: null,
    callName: null,
    callsDataVideo: null,
    activeTab: 'video'
  }

  closeModal = () => {
    this.setState({ modalRateOpened: false })
  }

  openModal = () => {
    this.setState({ modalRateOpened: true })
  }

  componentDidMount () {
    this.setActiveTab();
    this.getStudentsVideoCalls();
    this.getStudentLiveCalls();
  }

  setActiveTab = () => {
    const href = this.props.location.pathname;
    let callType = this.props.match.params.type;

    if(href == '/account/calls/video') {
        callType = 'video';
    } else if(href == '/account/calls/phone') {
      callType = 'phone';
    }

    const activeTab = this.state.activeTab
    // check if it is a valid type comming from the url if it isn't route to first option
    const tabsListNames = ['video','phone'];

    if (tabsListNames.includes(callType)) {
      this.setState({ activeTab: callType })
    } else {
      const url = '/account/calls/' + activeTab
      this.props.history.replace(url)
    }
  }

  getStudentsVideoCalls = (offset = 0)  => {
    liveCallService
      .getStudentVideoCalls(
        this.props.token,
        `?limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then(response => {
        
        paginationConf.total = response.data[0] ? response.data[0]._total : '0'

        this.setState({ loading: false, callsDataVideo: response.data})
        //console.log(response.data)
      })
      .catch(error => {
        console.log(error.data)
      })
  }

  getStudentLiveCalls = (offset = 0) => {
    liveCallService
      .getStudentLiveCalls(
        this.props.token,
        `?limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then(response => {
        //console.log(response.data)
        paginationConf.total = response.data[0] ? response.data[0]._total : '0'

        this.setState({ loading: false, callsData: response.data })
      })
      .catch(error => {
        console.log(error.data)
      })
  }

  handlePaginationchange = pag => {
    console.log(pag)
    this.getStudentLiveCalls((pag.currentPage - 1) * pag.pageLimit)
  }


  handlePaginationchangeVideos = pag => {
    console.log(pag)
    this.getStudentsVideoCalls((pag.currentPage - 1) * pag.pageLimit)
  }

  handleTabChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + value);
    this.setState({ activeTab: value });
	};

	handleOptionChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + event.target.value);
		this.setState({ activeTab: event.target.value });
  };

  render () {
    const screenWidth = this.props.windowWidth
    const { callsData, callsDataVideo, activeTab } = this.state

    const calls = this.state.callsData

    const loaderRender = <CircularProgress className={classes.VideoLoader} />

    const callsEmptyRender = () => {
      return (
        <div className={classes.ContinerEmpty}>
          {this.state.activeTab == 'phone' ? <ReactSVG svgClassName={classes.IconLiveCall} src={IconLiveCall} /> : <ReactSVG svgClassName={classes.IconLiveCall} src={IconVideo} />}
          {this.state.activeTab == 'phone' ? <h2>You haven't made any phone calls yet.</h2> : <h2>You haven't made any video calls yet.</h2>}
          <Link to={'/browse-infodepot/calls'}>
            <Button size='large'>BROWSE LIVE TUTORING CALLS NOW</Button>
          </Link>
        </div>
      )
    }

    const RateButton = (
      <Button
        onButtonClick={(callId, callName) => {
          this.openModal()
          this.setState({ slug: callId, callName: callName })
          console.log(callId, callName)
        }}
      >
        Leave a Review
      </Button>
    )

    const tabProperties = [
			{
				value: 'video',
				label: 'Video Calls',
				content: <React.Fragment>
        {this.state.loading ? (
            loaderRender
          ) : callsDataVideo && callsDataVideo.length > 0 ? (
            <div>
              <IDTable
                key={callsDataVideo.slug}
                data={callsDataVideo}
                user={this.state.user}
                typeTable='callsTableStudent'
                type='video'
                rate={RateButton}
                screen={screenWidth}
              />
              {paginationConf.total ? (
                <Pagination
                  style={{ marginTop: '40px' }}
                  totalRecords={paginationConf.total}
                  pageLimit={paginationConf.pageLimit}
                  pageNeighbours={1}
                  onPageChanged={this.handlePaginationchangeVideos}
                />
              ) : null}
            </div>
          ) : (
            callsEmptyRender()
          )}
           <div>
          <RateModal
            type='videoLiveCall'
            slug={this.state.slug}
            callName={this.state.callName}
            open={this.state.modalRateOpened}
            onClose={this.closeModal}
          />
        </div>
        </React.Fragment>
      },
      {
				value: 'phone',
				label: 'Phone Calls',
				content:  <React.Fragment>
        {this.state.loading ? (
            loaderRender
          ) : callsData && callsData.length ? (
            <div>
            <IDTable
              key={callsData.slug}
              data={callsData}
              user={this.state.user}
              typeData='phone'
              rate={RateButton}
              typeTable='callsTableStudent'
              screen={screenWidth}
            />
            {paginationConf.total ? (
                <Pagination
                  style={{ marginTop: '40px' }}
                  totalRecords={paginationConf.total}
                  pageLimit={paginationConf.pageLimit}
                  pageNeighbours={1}
                  onPageChanged={this.handlePaginationchange}
                />
              ) : null}
            </div>
          ) : (
            callsEmptyRender()
          )}
           <div>
          <RateModal
            type='liveCall'
            slug={this.state.slug}
            callName={this.state.callName}
            open={this.state.modalRateOpened}
            onClose={this.closeModal}
          />
        </div>
        </React.Fragment>
			}
		];

    return (
      <div className={classes.TableWrap}>
         {this.state.loading ? (
          loaderRender
        ) : (
          <div className={classes.TableWrap}>
           <TabButtons
            className={classes.TabsButton}
            activetab={activeTab}
            config={tabProperties}
            onChangeTab={(e, value) => this.handleTabChange(e, value)}
          />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateLoggedUser: userData => dispatch(actions.getLoggedUser(userData)),
    snackbarAdd: snackConf => dispatch(actions.snackbarAdd(snackConf))
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(windowWidth(Calls))
)
