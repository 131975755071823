import React from "react";
import Snackbar from "../Snackbar/Snackbar";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Aside from "./Aside/Aside";
import CookieBar from "../../components/CookieBar/CookieBar";
import classes from "./Layout.module.scss";
import CoverPhotoUpload from "../../components/UI/PhotoUpload/CoverUpload"
import {connect} from "react-redux";
import PromptToUpgradeSubscriptionPermanent from "../Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscriptionPermanent";

const addPaddingToPages = [
  "/sign-up-teacher",
  "/sign-up-student/",
  "/account/",
  "/account/settings/:type/",
];

class Layout extends React.PureComponent {
  render() {
    let path = window.location.pathname;
    let messageLayout = false;
    let profileLayout = false;
    if (path.includes("messages")) {
      messageLayout = true;
    }
    if (path.includes("profile")) {
      profileLayout = true;
    }
    return (
      <div className={classes.LayoutRoot}>
        <Header>
          {/* snackbar is added to the header as it is leaner to handle it's positioning.
					 Becouse it dependes on the height of the Header element  */}
          <Snackbar />
        </Header>

        <div id="main_scroller" className={!messageLayout && classes.Scroller}>
          {/* scrollable part of the app */}
          <PromptToUpgradeSubscriptionPermanent />
          {!profileLayout ?
          <div className={classes.MainWrap}>
            {/* central section */}
            <section className={classes.MainContainer}>
              <Aside />
              <main className={classes.Main}>{this.props.children}</main>
            </section>
            {/* footer */}
            {!messageLayout === true && <Footer />}
          </div> :
           <div className={classes.ProfileMainWrap}>
           <section className={classes.ProfileMainContainer}>
             <main className={classes.Main}>{this.props.children}</main>
           </section>
           {/* footer */}
           {!messageLayout === true && <Footer />}
         </div> }
        </div>

        <CookieBar />
      </div>
    );
  }
}
export default Layout;
