import React from 'react';

import classes from './CommentsList.module.scss';
import PostComment from './PostComment/PostComment';
import Comment from './Comment/Comment';
import Button from '../UI/Button/Button';

export const CommentsList = (props) => {
	
	const viewAllRender = () =>
		props.totalComments > props.comments.length && (
			<div className={classes.ViewAllWrap}>
				<Button type="link" onClick={() => props.showAllComments()}>
					View all comments
				</Button>{' '}
				<strong>{props.totalComments} comments</strong>
			</div>
		);


		return (
		<div>
			
			{viewAllRender()}

			{props.comments.map((comment) => (
				<Comment
					showBuyModal={() => props.showBuyModal && props.showBuyModal()}
					purchased={props.purchased}
					videoOwnerCrypt={props.srcData.author.cryptName}
					key={comment.id}
					comment={comment}
					showAllReplies={() => props.showAllReplies(comment.id)}
					onPostSuccess={(replyData) => props.onPostSuccess(replyData, comment.id)}
				/>
			))}
			{props.purchased ? (
				<PostComment
					type="comment"
					slug={props.srcData.slug}
					onPostSuccess={(commentData) => props.onPostSuccess(commentData)}
				/>
			) : null}
		</div>
	);
};

export default CommentsList;
