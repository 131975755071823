// JS functions that will be used often accross the platform

export const mq = () => {
  return Math.max(window.innerWidth);
}

export const height = () => {
  return Math.max(window.innerHeight);
}

export const addProtocolPrefixUrl = (url) => {
  if (url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = 'https://' + url;
    }
    return url;
  }
}

export const addHttpsProtocolPrefixUrl = (url) => {
  if (url) {
    if (!url.startsWith("https://")) {
      url = url.replace(/^http\:\/\//i, "");
      url = 'https://' + url;
    }
    return url;
  }
}

export const prepareTeacherExpertiseSubmit = (form, areasOfExpertise) => {
  if (form.expertiseArtsAndDesign.value) {
    areasOfExpertise.push('arts-design');
  }
  if (form.expertiseBusiness.value) {
    areasOfExpertise.push('business');
  }
  if (form.expertiseTechnology.value) {
    areasOfExpertise.push('technology');
  }
  if (form.expertiseLifeStyle.value) {
    areasOfExpertise.push('lifestyle');
  }
  if (form.expertisePersonalDevelopment.value) {
    areasOfExpertise.push('personal-development');
  }
  if (form.expertiseHealthFitness.value) {
    areasOfExpertise.push('health-fitness');
  }
  if (form.expertiseMusic.value) {
    areasOfExpertise.push('music');
  }
  if (form.expertiseLanguage.value) {
    areasOfExpertise.push('languages');
  }

  return areasOfExpertise;
}

export const prepareTeacherExpertiseBasedOnSubmit = (form, basedOnExpertises) => {
  if (form.basedOnEducation.value) {
    basedOnExpertises.push('education');
  }
  if (form.basedOnLifeExperience.value) {
    basedOnExpertises.push('life-experience');
  }
  if (form.basedOnTechnicalTraining.value) {
    basedOnExpertises.push('technical-training');
  }
  if (form.basedOnOther.value) {
    basedOnExpertises.push('other-i-e-mom-said-iam-special');
  }

  return basedOnExpertises;
}

