import axiosInstance from '../axiosDefaultInstance'

/**
 * Get teachers that follow
 * @param token
 * @param queryParams
 * @returns {AxiosPromise<any>}
 */
export const getFollowingTeachers = (token, queryParams) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = '/follow/student/following' + (queryParams || '');

    return axiosInstance.get(url, token ? config : null)
}

/**
 * Get teacher followers
 * @param token
 * @param queryParams
 * @returns {AxiosPromise<any>}
 */
export const getFollowers = (token, queryParams) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = '/follow/teacher/following' + (queryParams || '');

    return axiosInstance.get(url, token ? config : null)
}

/**
 * Student delete following teacher
 * @param token
 * @param cryptName
 * @returns {AxiosPromise}
 */
export const deleteFollowingTeacher = (token, cryptName) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = '/follow/teacher/' + cryptName;

    return axiosInstance.delete(url, config)
}

export const deleteFollowerOfTeacher = (token, cryptName) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = '/follow/follower/' + cryptName;

    return axiosInstance.delete(url, config)
}

/**
 * Follow teacher
 * @param token
 * @param cryptName
 * @returns {AxiosPromise<any>}
 */
export const followTeacher = (token, cryptName) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = '/follow/teacher/' + cryptName;

    return axiosInstance.post(url, {}, config)
}
