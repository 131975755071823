import axiosInstance from '../axiosDefaultInstance'

export const getThreads = (token) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }

    const url = '/messages/threads';

    return axiosInstance.get(url, config)
}

export const getThreadMessages = (token, threadId, limit, offset) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = `/messages/threads/${threadId}?limit=${limit}&offset=${offset}`;

    return axiosInstance.get(url, config)
}

export const createMessage = (token, cryptName, messageBody, attachments) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = `/messages`;

    return axiosInstance.post(url, {"body": messageBody, 'cryptName': cryptName, 'attachments': attachments}, config)
}

export const deleteThread = (token, threadId) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = `/messages/threads/${threadId}`;

    return axiosInstance.delete(url, config)
}

export const createThread = (token, cryptName) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = `/messages/threads`;

    return axiosInstance.post(url, {cryptName: cryptName}, config)
}

export const markMessageAsSeen = (token, messageId) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = `/messages/${messageId}/status`;

    return axiosInstance.put(url, {}, config)
}

export const uploadImage = (token, file) => {
    const config = {
        headers: {
            'X-Auth-Token': token,
            'content-type': 'multipart/form-data'
        }
    }
    const url = `/messages/upload/image`
    const formData = new FormData()
    formData.append('image[binaryContent]', file, file.name)

    return axiosInstance.post(url, formData, config)
}

export const uploadFile = (token, file) => {
    const config = {
        headers: {
            'X-Auth-Token': token,
            'content-type': 'multipart/form-data'
        }
    }
    const url = `/messages/upload/file`
    const formData = new FormData()
    formData.append('file[binaryContent]', file, file.name)

    return axiosInstance.post(url, formData, config)
}

export const deleteAttachment = (token, id) => {
    const config = {
        headers: {
            'X-Auth-Token': token,
        }
    }
    const url = `/messages/attachment/${id}`

    return axiosInstance.delete(url, config)
}

export const downloadDocument = (token, id) => {
    const config = {
        responseType: 'blob',
        headers: {
            'X-Auth-Token': token
        }
    }
    const url = `/messages/attachment/${id}/download`
    return axiosInstance.get(url, config)
}

export const countUnread = (token) => {
    const config = {
        headers: {
            'X-Auth-Token': token
        }
    }

    const url = '/messages/unread';
    return axiosInstance.get(url, config)
}


