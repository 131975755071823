import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader';

import apiConfig from '../../utils/apiConfig';

class PaypalButton extends Component {
	state = {
		showButton: false
	};

	componentDidMount() {
		const { isScriptLoaded, isScriptLoadSucceed } = this.props;

		if (isScriptLoaded && isScriptLoadSucceed) {
			this.setState({ showButton: true });
		}
	}

	button = null;

	createButton = (amount) => {
    // remove the button if there was any
    document.querySelector('#paypal-button-container').innerHTML = '';
    
		this.button = window.paypal.Buttons({
			style: {
				layout: 'horizontal',
				label: 'checkout',
				shape: 'pill',
				size: 'small',
				tagline: true
			},
			createOrder: (data, actions) => {
				return actions.order.create({
					purchase_units: [
						{
							amount: {
								value: amount
							}
						}
					]
				});
			},
			onApprove: (data, actions) => {
				//return actions.order.capture().then((details) => {
					this.props.onSuccess(data);
				//});
			}
		});
	};

	renderButton = () => {
		this.button.render('#paypal-button-container');
		this.setState({ showButton: true });
	};

	componentDidUpdate(prevProps, prevState) {
		const { isScriptLoaded, isScriptLoadSucceed, amount } = this.props;

		const isLoadedButWasntLoadedBefore =
			!this.state.showButton && !prevProps.isScriptLoaded && isScriptLoaded;

		const amountHasChanged = amount !== prevProps.amount;

		if (isLoadedButWasntLoadedBefore || (this.state.showButton && amountHasChanged)) {//&& this.props.paymentSelected == 'paypal'
			if (isScriptLoadSucceed) {
				this.createButton(amount);
				this.renderButton();
			}
		}
	}

	render() {
		return (
			<div style={{margin: '20px 0', width: '170px'}} id="paypal-button-container">
			</div>
		);
	}
}

export default scriptLoader(
	'https://www.paypal.com/sdk/js?client-id=' + apiConfig.PAYPAL_ID + '&disable-funding=credit,card'
)(PaypalButton);
