import React from 'react';
import ReactSVG from 'react-svg';
import clone from 'lodash.clonedeep';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as paymentService from '../../services/payment/paymentService';
import * as actions from '../../store/actions/indexActions';
import classes from './Payout.module.scss';
import Card from '../../components/UI/Card/Card';
import Button from '../../components/UI/Button/Button';
import Divider from '../../components/UI/Divider/Divider';
import { Form, Input } from '../../components/UI/Form/Form';
import * as validator from '../../utils/formValidator';
import { errorParser } from '../../utils/errorParser';
import IconPaypal from './../../assets/images/icons/PayPal_logo.svg';
import Modal from '../../components/UI/Modal/Modal';
import { getInfoDepotFee } from '../../services/configuration/configurationService';

const calcTotalAmount = (amount, fee, max = 20) => {
	const _amount = Number(amount);
	const _fee = Number(fee);
	// fee should not come above max amount dollars, default is 20
	return _amount + Math.min(_amount * (_fee / 100), 20);
};

class Payout extends React.PureComponent {
	state = {
		modalOpened: false,
		showFeeError: false,
		loading: true,
		formIsValid: false,
		transactionFee: null,
		amountFull: null,
		formControls: {
			amount: {
				id: 'amount',
				type: 'text',
				ref: 'amount',
				placeholder: 'How many dollars do you want to withdraw?',
				placeholderStatic: 'Enter amount',
				infolabel: 'Minimum amount is $25',
				shrink: true,
				validation: {
					required: true,
					isNumericDecimal: true,
					isLargerThen: 25
				},
				error: '',
				valid: false
			}
		}
	};

	async componentDidMount() {
		const fees = await getInfoDepotFee()
			.then((resp) => {
				return resp.data.payoutFee;
			})
			.catch((error) => console.log(error.data));

		this.setState({
			transactionFee: fees,
			loading: false
		});
	}

	closeModal = () => {
		this.setState({ modalOpened: false });
	};

	openModal = () => {
		this.setState({ modalOpened: true });
	};

	// clears the errors and 'dirty' the input on change. Errors will be checked on blur
	clearInput = (inpt) => {
		inpt.touched = true;
		inpt.error = '';

		// if input is part of the group clear all group memebers
		if (inpt.group) {
			inpt.groupError = false;
		}
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if there is no value it is the radio/checkbox input, in that case take the name
		if (ctrl.type !== 'checkbox') {
			ctrl.value = e.target.value;
		} else {
			ctrl.value = ctrl.value === controlName ? '' : controlName;
		}

		this.clearInput(ctrl);

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy),
			amountFull: calcTotalAmount(ctrl.value, this.state.transactionFee),
			showFeeError: ''
		});
	};

	// checks validity of single input
	getInputError = (input) => {
		return validator.validateInput(input);
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if it is a group show error only underneath the first input
		if (ctrl.group) {
			const errorMsg = this.getInputError(ctrl);
			if (errorMsg) {
				// show the msg on first input
				formControlsCopy[ctrl.group[0]].error = errorMsg;
				// set error on all group fields
				ctrl.group.forEach((inputName) => {
					formControlsCopy[inputName].groupError = true;
				});
			}
		} else {
			ctrl.error = this.getInputError(ctrl);
		}

		const userCredits = this.props.user.totalCredits;

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy),
			showFeeError: userCredits < this.state.amountFull
		});
	};

	submit = () => {
		if (
			this.props.user.profile.payPalAccount &&
			!this.state.loading &&
			this.state.formIsValid &&
			!this.state.showFeeError
		) {
      const amount = this.state.formControls.amount.value;
      this.setState({ loading: true });
			paymentService
				.makePayout(amount, this.props.token)
				// success
				.then((response) => {
					const queryParam = new URLSearchParams(this.props.location.search);
					const backTo = queryParam.get('back-to');
					const backToQueryParam = new URLSearchParams(this.props.location.search);
					const paymentAmount = response.data.amount;
					this.props.updateLoggedUser();
					if (backToQueryParam) {
						backToQueryParam.delete('back-to');
					}
					if (backTo) {
						this.props.history.push(backTo + '?' + backToQueryParam.toString());
					} else {
						this.props.history.push('/successful-payout', paymentAmount);
					}
				})
				// error
				.catch((error) => {
					console.log(error);
					const apiErr = error.response.data.errors
						? errorParser(error.response.data.errors)
						: null;
					console.log(apiErr);

					const cloneForm = clone(this.state.formControls);

					if (apiErr) {
						// config object to be used by checkFormApiErrors
						const errorStateConfig = {
							formAmountError: 'amount'
						};

						// checking form errors for matching pairs defined in the errorStateConfig
						validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);
					} else {
						this.showError(error.response.data.message);
					}

					this.setState({
						formIsValid: false,
						formControls: cloneForm,
						loading: false
					});
				});
		}
	}

	showError = (message) => {
		this.props.snackbarAdd({
			message: message,
			type: 'error',
			timeout: 8000
		});
	};

	render() {
		const { amountFull, loading, showFeeError, modalOpened } = this.state;
		const user = this.props.user;
		const query = new URLSearchParams(this.props.location.search);

		const backToQueryParam = () => {
			query.append('back-to', this.props.location.pathname);
			return '?' + query.toString();
		};

		const payPalEmailRender = () =>
			user.profile.payPalAccount ? (
				<React.Fragment>
					<div className={classes.EmailWrap}>
						<span className={classes.payPalAccount}>{user.profile.payPalAccount}</span>
						<div style={{ marginBottom: '25px' }}>
							<small>
								To edit your PayPal account email go to{' '}
								<Link to={'/account/settings/payout'}>Account Settings</Link>
							</small>
						</div>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<p className={classes.NoInfo}>No information yet.</p>
					<small>To receive your payout, please add your PayPal account email.</small>
					<Link to={'/account/settings/payout' + backToQueryParam()}>
						<Button color="secondary" className={classes.Button}>
							Add PayPal account email
						</Button>
					</Link>
				</React.Fragment>
			);

		return (
			<div className={classes.Container}>
				<h1>Payout request</h1>
				<Card className={classes.Card} loading={Number(loading)}>
					<h2>Step 1</h2>
					<Form onSubmit={this.submit}>
						<Input
							// style={{ width: 'calc(65% - 7px)' }}
							disabled={loading}
							config={this.state.formControls.amount}
							onChange={(e) => this.inputChangeHandler(e, 'amount')}
							onBlur={(e) => this.inputBlurHandler(e, 'amount')}
						/>
						<div className={classes.FeesInfo}>
							{showFeeError && (
								<div className={classes.FeesError}>
									You need to have at least ${amountFull.toFixed(2)} on your balance to cover PayPal
									transfer fees for the requested amount. Please enter the lower
									withdrawal amount.
								</div>
							)}
							To cover PayPal transfer fees, your balance will be charged:<span> ${amountFull ? amountFull.toFixed(2) : '0.00'}</span>
						</div>
						<Divider horizontal="true" style={{ margin: '25px 0px 23px' }} />
						<h2>Step 2</h2>
						<ReactSVG src={IconPaypal} className={classes.IconPaypal} />
						<p className={classes.PayPalLabel}>Your PayPal account email</p>
						{payPalEmailRender()}
						<Button
							fullWidth={true}
							size="large"
							disabled={
								!this.props.user.profile.payPalAccount ||
								this.state.loading ||
								!this.state.formIsValid ||
								showFeeError
							}
							onClick={() => this.setState({ modalOpened: true })}>
							PAY OUT
						</Button>

						<Modal
							loading={Number(loading)}
							className={classes.Modal}
							open={modalOpened}
							onClose={() => this.closeModal()}>
							<h2 className={classes.ModalTitle}>Confirm your payout request</h2>
							<div className={classes.ModalContent}>
								<div className={classes.PayPalLabel}>PayPal account email:</div>
								<div>{user.profile.payPalAccount}</div>
								<Divider horizontal="true" style={{ margin: '30px 0px 23px' }} />

								<div className={classes.PayPalLabel} style={{ marginBottom: '30px' }}>
									Withdrawal amount:{' '}
									<span className={classes.AmountWithdrawed}>
										${(Number(this.state.formControls.amount.value)).toFixed(2)}
									</span>
								</div>

                <div className={classes.PayPalLabel} style={{ marginBottom: '30px' }}>
                  Your balance will be charged:{' '}
									<span className={classes.AmountWithdrawed}>
										${(Number(this.state.amountFull)).toFixed(2)}
									</span>
								</div>

								<Button
									fullWidth={true}
									size="large"
									onClick={this.submit}
									disabled={!this.props.user.profile.payPalAccount ||
                    this.state.loading ||
                    !this.state.formIsValid ||
                    showFeeError}
									loading={loading}>
									CONFIRM PAYOUT
								</Button>
							</div>
						</Modal>
					</Form>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
		updateLoggedUser: () => dispatch(actions.getLoggedUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payout);
