import React from 'react';

import classes from './../Aside/Aside.module.scss';

const Aside = (props) => {
	return (
		// we are using this id in the AsidePortal to pass the children to this component. This component will appear accross the platform but and it much leaner and less repetative to put it next to the <main> DOM container then inside of it
		<div className={classes.AsideContainer}>
			<aside id="aside-root"/>
		</div>
	);
};

export default Aside;
