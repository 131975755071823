import React from "react";
import classes from "../AccountSettings/AccountSettings.module.scss";

function NoPaymentInformation() {
  return (
    <div className={classes.PaymentInformation}>
      <h3 className={classes.Subheaders} style={{ marginBottom: "20px" }}>
        Payment information
      </h3>
      {
        <p className={classes.HighlightBox} style={{ marginBottom: "20px" }}>
          No payment information yet.
        </p>
      }
      <p className={classes.SubscriptionInfo} style={{ fontSize: "12px" }}>
        Our payment process is secured, and InfoDepot company cannot store nor
        access this data. We will display the last four digits of your card so
        you can identify the card you used for purchasing the subscription.
      </p>
    </div>
  );
}

export default NoPaymentInformation;
