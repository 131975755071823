import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './StartLiveCall.module.scss';
import * as callService from '../../services/liveCall/liveCallService';
import Card from '../../components/UI/Card/Card';
import ReactSVG from 'react-svg';
import IconPhone from './../../assets/images/icons/live_call_icon.svg';
import IconPhoneFailed from './../../assets/images/icons/failed-call.svg';
import IconPhoneMissed from './../../assets/images/icons/missed-call.svg';
import IconCheckmark from './../../assets/images/icons/check-mark-icon-green.svg';
import Button from '../../components/UI/Button/Button';

class StartLiveCall extends React.PureComponent {
	state = {
		statusOfCall: null,
		callDuration: null,
	};

	componentDidMount() {
		// console.log(this.props.location.state.slug);
		if (!this.props.location.state || !this.props.location.state.price) {
			this.props.history.replace('/');
		}

		this.statusCheck();

		this.intervalStatusCheck = setInterval(this.statusCheck, 10000);
	}

	componentDidUpdate(prevState) {
		console.log('update', this.state.statusOfCall)
		if(this.state.statusOfCall == 'completed' 
		|| this.state.statusOfCall == 'teacher_missed'
		|| this.state.statusOfCall == 'teacher_rejected'
		) {
			clearInterval(this.intervalStatusCheck);
		}
	}

	statusCheck = () => {
		//get live call status
		callService
		.getLiveCallPhoneStatus(this.props.token, this.props.location.state.slug)
		.then((response) => {
			//console.log('response', response.data.status)
			this.setState({
				statusOfCall: response.data.status,
				callDuration: response.data.callDuration
			})
		})
		.catch((error) => {
			console.log(error)
		});
	}

	durationRender = () => {
		let callDuration = this.state.callDuration;
  
		const hours = Math.floor(callDuration / 3600);
  
		callDuration -= hours * 3600;
  
		const minutes = Math.floor(callDuration / 60);
		callDuration -= minutes * 60;
  
		const seconds = parseInt(callDuration % 60, 10);
  
		return (hours > 0 ? hours + ' hour' : '') + ' ' + (minutes > 0 ? (minutes < 10 ? '0' + minutes + ' min' : minutes + ' min') : '') + ' ' + (seconds > 0 ? (seconds < 10 ? '0' + seconds + ' sec' : seconds + ' sec') : '');
	}

	render() {
		const CallText = () => (
			<React.Fragment>
				{this.state.statusOfCall == 'in-progress' ? 
					<div>
						<ReactSVG className={classes.IconPhoneStatus} src={IconPhone} />
						<p class={classes.TextStatus}>
						Call in progress...
						</p>
						<p class={classes.TextStatusInfo}>
						We are calling the teacher, please have your phone near you to receive the call
						when the teacher is connected. Your account will be charged
						<span className={classes.Amount}>
							{' '}
							${this.props.location.state && this.props.location.state.price}
						</span>{' '}
						per minute.
						</p>
						<p className={classes.TextInfoSpan}>You can always check the call information in <a href="/account/calls/phone">your calls</a> list.</p>
					</div>
				: this.state.statusOfCall == 'teacher_missed' ?
				<div>
					<ReactSVG className={classes.IconPhoneStatus} src={IconPhoneMissed} />
					<p class={classes.TextStatus}>
					Missed call...
					</p>
					<p class={classes.TextStatusInfo}>
					We’re sorry, but the teacher is not available.<br/> Please try your call again later.
					</p>
					<p className={classes.TextInfoSpan}>You can always check the call information in <a href="/account/calls/phone">your calls</a> list.</p>
				</div>
				
				: this.state.statusOfCall == 'teacher_rejected' ? 
					<div>
						<ReactSVG className={classes.IconPhoneStatus} src={IconPhoneFailed} />
						<p class={classes.TextStatus}>
						Call failed...
						</p>
						<p class={classes.TextStatusInfo}>
						We’re sorry, but the teacher is not available.<br/> Please try your call again later.
						</p>
						<p className={classes.TextInfoSpan}>You can always check the call information in <a href="/account/calls/phone">your calls</a> list.</p>
					</div>
				:  this.state.statusOfCall == 'completed' ?
				<div>
					<ReactSVG className={classes.IconPhoneStatus} src={IconCheckmark} />
					<p class={classes.TextStatusCompleted}>
						Call completed.
					</p>
					<p class={classes.CallDuration}>{this.durationRender()}</p>
					<p class={classes.TextStatusInfo}>Tell us about your experience with the teacher during this live tutoring call.</p>
					<Link to="/account/calls/phone">
						<Button className={classes.LinkWithMargin} size="large">GO TO YOUR CALLS LIST AND LEAVE A REVIEW</Button>
					</Link>
					<p className={classes.TextInfoSpan}>You can always check the call information in <a href="/account/calls/phone">your calls</a> list.</p>
				</div>
				
				:
				<div>
					<ReactSVG className={classes.IconPhoneStatus} src={IconCheckmark} />
					<p class={classes.TextStatusInfo}>
					We are calling the teacher, please have your phone near you to receive the call
					when the teacher is connected. Your account will be charged
					<span className={classes.Amount}>
						{' '}
						${this.props.location.state && this.props.location.state.price}
					</span>{' '}
					per minute.
					</p>
					<Link to="/account/calls/phone">
						<Button size="large">GO TO YOUR CALLS LIST</Button>
					</Link>
				</div>
				}
				
			</React.Fragment>
		);

		const QueueText = () => (
			<div>
				<ReactSVG className={classes.IconPhone} src={IconPhone} />
				{Number(this.props.location.state.queueCount) ? (
					<React.Fragment>
						There {this.props.location.state.queueCount > 1 ? 'are ' : 'is '}
						<span className={classes.Amount}>
							{this.props.location.state.queueCount}
						</span>{' '}
						user{this.props.location.state.queueCount > 1 ? 's' : ''} in the queue.
					</React.Fragment>
				) : (
					<React.Fragment>You are a first user in the queue.</React.Fragment>
				)}{' '}

				Please, have your phone near you to receive the call as soon as the teacher
				becomes available. Your account will be charged
				<span className={classes.Amount}>
					{' '}
					${this.props.location.state && this.props.location.state.price}
				</span>{' '}
				per minute when the call is connected.
				<p>To track the queue list, go to your account.</p>
				<Link to="/account/queued-calls">
					<Button size="large">GO TO QUEUE LIST</Button>
				</Link>
			</div>
		);

		return (
			<div className={classes.Container}>
				<h1>Starting Live Tutoring Call</h1>
				<Card className={classes.Card}>
					<div>
						{this.props.location.state &&
						this.props.location.state.hasOwnProperty('queueCount') ? (
							QueueText()
						) : (
							CallText()
						)}
					</div>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

//export default StartLiveCall;
export default withRouter(connect(mapStateToProps)(StartLiveCall));
