import React from 'react';

import classes from './DescriptionTab.module.scss';

const DescriptionTab = (props) => {
	const video = props.video;
	let category = video && video.categories[0].parent == null;
	return (
		<article>
			<div>
				<p>{video && video.description}</p>
			</div>

			<div className={classes.GroupLabels}>
				<label>CATEGORY: </label>
				<span>{category ? video && video.categories[0].name : video && video.categories[1].name}</span>
			</div>
			<div className={classes.GroupLabels}>
				<label>SUBCATEGORY: </label>
				<span>{category == true ? video && video.categories[1].name : video && video.categories[0].name}</span>
			</div>
			<div className={classes.GroupLabels}>
				<label>LEVEL: </label>
				<span>{video && video.level.name}</span>
			</div>
		</article>
	);
};

export default DescriptionTab;
