import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import moduleClasses from './ExpansionPanel.module.scss'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import color from '../../../sass/settings/_settings.colors.scss'

const styles = {
  root: {
    color: color.blue
  },
  expanded: {
    color: color.black
  },
  expandIcon: {
    transform: 'translateY(-50%) rotate(90deg)'
  }
}

class IDExpansionPanel extends Component {
  render () {
    const { classes, activeBlack } = this.props

    const expandIconRender = () => {
      return <ArrowDropUp />
    }
    return (
      <div className={moduleClasses.ExpansionPanelWrap}>
        <ExpansionPanel
          className={[moduleClasses.ExpansionPanel, this.props.className].join(
            ' '
          )}
        >
          <ExpansionPanelSummary
            // onClick={this.props.onClick}
            classes={{
              expanded: activeBlack ? classes.expanded : null,
              expandIcon: activeBlack ? classes.expandIcon : '',
              root: classes.root
            }}
            style={this.props.styles && this.props.styles.panelSummaryStyle}
            className={moduleClasses.ExpansionPanelSummary}
            expandIcon={expandIconRender()}
          >
            <h2
              style={this.props.styles && this.props.styles.panelHeadlineStyle}
              className={moduleClasses.Heading}
            >
              {this.props.paneltitleExpanded && (
                <span>{this.props.paneltitleExpanded} {this.props.icon}</span>
              )}
              <span>{this.props.paneltitle} {this.props.icon}</span>
            </h2>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={this.props.styles && this.props.styles.panelDetailsStyle}
            className={moduleClasses.ExpansionPanelDetails}
          >
            <div className={moduleClasses.InnerPanel}>
              {this.props.children}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(IDExpansionPanel)
