import React, { Component } from "react";
import { connect } from "react-redux";
import windowWidth from "react-window-size";
import { Snackbar } from "@material-ui/core";
import ShareIcon from "../../assets/images/icons/share-link.svg";
import FollowerIcon from "../../assets/images/icons/follower_icon.svg";
import { Link } from "react-router-dom";

import IconLiveCall from "../../assets/images/icons/live_call_icon.svg";
import IconVideoBasic from "../../assets/images/icons/video-solid.svg";
import IconMessage from "../../assets/images/icons/messages_icon.svg";
import IconMute from "../../assets/images/icons/live_call_offline_icon.svg";
import IconVideoSlash from "../../assets/images/icons/video-slash-solid.svg";
import IconPhoneBusy from "../../assets/images/icons/phone_call_busy_icon.svg";
import IconVideoBusy from "../../assets/images/icons/video_call_busy_icon.svg";

import classes from "./TeacherProfile.module.scss";
import classesFollow from '../../components/Follow/Follow.module.scss';
import classesButton from '../../components/UI/Button/Button.module.scss'
import Button from "../../components/UI/Button/Button";
import PhotoUpload from "../../components/UI/PhotoUpload/PhotoUpload";
import Tabs from "../../components/UI/Tabs/Tabs";
import ReactSVG from "react-svg";
import CustomSelect from "../../components/UI/Select/Select";
import ExpansionPanel from "../../components/UI/ExpansionPanel/ExpansionPanel";
import { getUserProfile } from "../../services/user/userService";
import ProfileVideos from "./ProfileVideos";
import ProfileLiveTutoringCalls from "./ProfileLiveTutoringCalls";
import SignUpModal from "../../components/SignUpModal/SignUpModal";
import LiveCallModal from '../../components/LiveCallModal/LiveCallModal';
import TwilioVideo from '../../components/TwilioVideo/TwilioVideo';
import ProfileFollowing from "./ProfileFollowing";
import Follow from "../Follow/Follow";
import ProfileFollowers from "./ProfileFollowers";
import CoverPhotoUpload from "../../components/UI/PhotoUpload/CoverUpload";
import Avatar from "../../components/UI/Avatar/Avatar";
import * as liveCallService from "../../services/liveCall/liveCallService";
import { ChosenDisplayName, shouldDisplayUsernameSection } from "../../components/UI/ChosenDisplayName/ChosenDisplayName";

const intervalAutoRefresh = (fn, cryptName) => setInterval(fn, 5000, cryptName);

const expansionPanelStyles = {
  panelSummaryStyle: {
    minHeight: "20px",
    width: "180px",
    margin: "0 auto",
  },
  panelHeadlineStyle: {
    fontSize: "13px",
  },
  panelDetailsStyle: {
    padding: "20px 0 0 0",
  },
};

class Profile extends Component {
  decreaseNumberOfFollowers = () => {
    this.tabProperties.followers.label = `Followers (${this.state.numberOfFollowers - 1})`;
    this.setState((prevState) => {
      return {
        numberOfFollowers: prevState.numberOfFollowers - 1,
        tabConfig: Object.keys(this.tabProperties).map(
            (key) => this.tabProperties[key]
        )
      }
    });
  }

  decreaseNumberOfFollowing = () => {
    this.tabProperties.following.label = `Following (${this.state.numberOfFollowing - 1})`;
    this.setState((prevState) => {
      return {
        numberOfFollowing: prevState.numberOfFollowing - 1,
        tabConfig: Object.keys(this.tabProperties).map(
            (key) => this.tabProperties[key]
        )
      }
    });
  }

  increaseNumberOfFollowers = () => {
    this.tabProperties.followers.label = `Followers (${parseInt(this.state.numberOfFollowers) + 1})`;
    this.setState((prevState) => {
      return {
        numberOfFollowers: prevState.numberOfFollowers + 1,
        tabConfig: Object.keys(this.tabProperties).map(
            (key) => this.tabProperties[key]
        )
      }
    });
  }

  tabProperties = {
    videos: {
      value: "videos",
      label: "Videos",
      content: <ProfileVideos />,
    },
    calls: {
      value: "calls",
      label: "Live Tutoring Calls",
      content: <ProfileLiveTutoringCalls />,
    },
    followers: {
      value: "followers",
      label: "Followers",
      content: <ProfileFollowers decreaseNumberOfFollowers={this.decreaseNumberOfFollowers} />,
    },
    following: {
      value: "following",
      label: "Following",
      content: <ProfileFollowing decreaseNumberOfFollowing={this.decreaseNumberOfFollowing}/>,
    },
  };

  state = {
    activeTab: null,
    profileWatching: null,
    open: false,
    vertical: "top",
    horizontal: "center",
    modalSignUpOpened: false,
    numberOfFollowers: 0,
    numberOfFollowing: 0,
    firstAvailableCalls: {},
    tabConfig: Object.keys(this.tabProperties).map(
      (key) => this.tabProperties[key]
    ),
    modalCallOpened: false,
    selectedCall: null,
    modalCallType: '',
    twilioVideo: false,
    modalLiveCallOpened: false,
    modalVideoCall: false,
    roomName: '',
    roomToken: '',
    participient: '',
    videoName: '',
    participientUrlImage: '',
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.clearStatusRefreshInterval();
  }

  init() {
    const profileUrl = this.props.match.params.slug;
    const teacherWatchSelf = this.props.user && this.props.user.profileUrl === profileUrl;
    if (profileUrl) {
      getUserProfile(profileUrl, this.props.token)
        .then((response) => {
          if (!teacherWatchSelf) {
            this.getFirstAvailableCalls(response.data.cryptName);
            this.clearStatusRefreshInterval();
            setTimeout(() => {
              this.autoRefreshStatus = intervalAutoRefresh(this.getFirstAvailableCalls, response.data.cryptName);
            });
          }
          this.tabProperties.following.label = `Following (${response.data.numberOfFollowing})`;
          this.tabProperties.followers.label = `Followers (${response.data.numberOfFollowers})`;
          this.setState({
            profileWatching: response.data,
            tabConfig: Object.keys(this.tabProperties).map(
              (key) => this.tabProperties[key]
            ),
            numberOfFollowing: response.data.numberOfFollowing,
            numberOfFollowers: response.data.numberOfFollowers
          });
        })
        .catch((error) => {
          this.props.history.push('/');
        });
    }
    this.setActiveTab();
    // there is some visual bug with the tabs. We need to reset the active tab for indicator to pick up the right positon and the width
    setTimeout(() => this.setActiveTab(), 10);
  }

  openCallModalPhone = (call) => {
    if (this.props.user) {
      this.setState({
        modalCallOpened: true,
        selectedCall: call,
        modalCallType: 'phone',
      });
    } else {
      this.openSignUpModal('/call/' + call.slug);
    }
  };

  openCallModalVideo = (call) => {
    if (this.props.user) {
      this.setState({
        modalCallOpened: true,
        selectedCall: call,
        modalCallType: 'video',
      });
    } else {
      this.openSignUpModal('/call/' + call.slug);
    }
  };

  //close twilio video
  closeTwilioVideo = () => {
    this.setState({
      twilioVideo: false,
    });
  };

  closeCallModal = (
    room,
    roomToken,
    participient,
    videoName,
    twilioVideo,
    participientUrlImage
  ) => {
    this.setState({
      modalLiveCallOpened: false,
      selectedCall: null,
      modalVideoCall: false,
      roomName: room,
      roomToken: roomToken,
      participient: participient,
      videoName: videoName,
      twilioVideo: twilioVideo,
      participientUrlImage: participientUrlImage,
    });
  };

  getFirstAvailableCalls = (cryptName) => {
    let queryParams = `cryptName=${cryptName}`;

    liveCallService
        .getFirstAvailableCalls(queryParams)
        .then((response) => {
          this.setState({
            firstAvailableCalls: response.data ? response.data : {},
          });
        })
        .catch((error) => {});
  };

  clearStatusRefreshInterval = () => {
    clearInterval(this.autoRefreshStatus);
  }


  async setActiveTab() {
    const settingTypeParam = this.props.match.params.tab;
    const slug = this.props.match.params.slug;
    // check if it is a valid type comming from the url if it isn't route to first option
    const typeNameList = Object.keys(this.tabProperties);

    if (typeNameList.includes(settingTypeParam)) {
      this.setState({ activeTab: settingTypeParam });
    } else {
      const url = `/profile/${slug}/` + typeNameList[0];
      this.props.history.replace(url);
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
    this.props.history.push(
      `/profile/${this.props.match.params.slug}/` + value
    );
  };

  handleOptionChange = (event) => {
    this.setState({ activeTab: event.target.value });
    this.props.history.push(
        `/profile/${this.props.match.params.slug}/` + event.target.value
    );
  };

  copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ open: true });
  }

  openSignUpModal = () => {
    this.setState({ modalSignUpOpened: true });
  };

  closeSignUpModal = () => {
    this.setState({ modalSignUpOpened: false });
  };

  avatarSize = () => {
    return this.props.windowWidth <= 768 ? '100' : '200';
  };

  socialMediaLinksExist = () => {
    let result = false;
    if (this.state.profileWatching.socialMediaLink) {
      Object.keys(this.state.profileWatching.socialMediaLink).map((key) => (
        result = result || this.state.profileWatching.socialMediaLink[key]
      ));
    }
    return result;
  };

  render() {
    const screenWidth = this.props.windowWidth;

    const {
      activeTab,
      profileWatching,
      vertical,
      horizontal,
      open,
      modalSignUpOpened,
    } = this.state;
    const teacherWatchSelf = this.props.user && this.props.user.type === "teacher" && profileWatching && this.props.user.cryptName === profileWatching.cryptName;

    const teacherInfo = (
      <React.Fragment>
        <section className={classes.Section}>
          {screenWidth > 768 && (
            <span style={{ fontSize: "20px" }} className={classes.SectionTitles}>
              About
            </span>
          )}
          {profileWatching && (
            <>
              {screenWidth > 768 && (
                <p>{profileWatching.profile.introAboutMe}</p>
              )}
              {profileWatching.profile.webSiteUrl && (
                <p style={{ color: "#83a8d4", marginBottom: this.socialMediaLinksExist() ? "10px" : "30px" }}>
                  <a target="_blank" rel="noopener noreferrer" style={{ wordBreak: "break-word" }} href={profileWatching.profile.webSiteUrl}>
                    {profileWatching.profile.webSiteUrl.replace(/(^\w+:|^)\/\/(www\.)?/, '')}
                  </a>
                </p>
              )}
              
              {this.socialMediaLinksExist() &&
                <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "27px" }}>
                  {Object.keys(profileWatching.socialMediaLink).map((key, index) => (
                    profileWatching.socialMediaLink[key] &&
                    <a
                      key={index}
                      href={profileWatching.socialMediaLink[key]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classesButton.SocialMediaLink}
                    >
                      <ReactSVG src={require(`../../assets/images/icons/${key}.svg`)} />
                    </a>
                  ))}
                </div>
              }

              {profileWatching.areaOfExpertises.length ? (
                  <>
                      <span
                          className={classes.SectionSubtitles}
                          style={{
                            color: "#a7a7a7",
                            textTransform: "uppercase",
                            fontSize: "12px",
                            marginBottom: "10px",
                          }}
                      >
                Area of expertise
              </span>
                  <ul style={{ marginLeft: "17px", marginTop: "8px" }}>
                    {profileWatching.areaOfExpertises.map((areaOfExpertise, i) => (
                        <li key={i} style={{ fontSize: "14px" }}>
                          {areaOfExpertise.name}
                        </li>
                    ))}
                  </ul>
                    </>
              ) : null}

              {profileWatching.expertisesBasedOn.length ? (
                  <>
                    <span
                        className={classes.SectionSubtitles}
                        style={{
                          color: "#a7a7a7",
                          textTransform: "uppercase",
                          fontSize: "12px",
                        }}
                    >
                Expertise based on
              </span>
                    <ul style={{ marginLeft: "17px", marginTop: "8px" }}>
                      {profileWatching.expertisesBasedOn.map(
                          (expertiseBasedOn, i) => (
                              <li key={i} style={{ fontSize: "14px" }}>
                                {expertiseBasedOn.name}
                              </li>
                          )
                      )}
                    </ul>
                  </>
              ) : null}
              {profileWatching.schools.length ? (
                <>
                  <span
                    className={classes.SectionSubtitles}
                    style={{
                      color: "#a7a7a7",
                      textTransform: "uppercase",
                      fontSize: "12px",
                    }}
                  >
                    Schools
                  </span>
                  <ul style={{ marginLeft: "17px", marginTop: "8px" }}>
                    {profileWatching.schools.map((school, i) => (
                      <li key={i} style={{ fontSize: "14px", marginTop: "6px" }}>
                        {school.name}
                        <br />
                        <span style={{ color: "#a7a7a7" }}>
                          {school.degree}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              {profileWatching.certifications.length ? (
                <>
                  <span
                    className={classes.SectionSubtitles}
                    style={{
                      color: "#a7a7a7",
                      textTransform: "uppercase",
                      fontSize: "12px",
                    }}
                  >
                    Certifications & awards
                  </span>
                  <ul style={{ marginLeft: "17px", marginTop: "8px" }}>
                    {profileWatching.certifications.map((certification, i) => (
                      <li key={i} style={{ fontSize: "14px", marginTop: "6px" }}>
                        {certification.name}
                        <br />
                        <span style={{ color: "#a7a7a7" }}>
                          {certification.institution}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </>
          )}
        </section>
      </React.Fragment>
    );

    const renderFollowButton = (
      <>
        {this.props.user && profileWatching ? (
          <Follow increaseNumberOfFollowers={this.increaseNumberOfFollowers} decreaseNumberOfFollowers={this.decreaseNumberOfFollowers} teacher={profileWatching} responsiveIcon={true} />
        ) : (
          <Button
            type="contained"
            style={{
              fontSize: "13px!important",
            }}
            className={classesFollow.ButtonResponsiveProfile}
            onClick={() => this.openSignUpModal()}
          >
            <ReactSVG
              style={{
                color: "white",
                fill: "white",
              }}
              src={FollowerIcon}
            />{" "}
            Follow
          </Button>
        )}
      </>
    );

		const ButtonCall = (call) => {
			switch (call.liveCallStatus) {
				case 'available':
					return (
            <Button
              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
              style={{ margin: '0px 5px 10px' }}
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalPhone(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: "13px",
                  width: "14px",
                  marginRight: "5px",
                  position: "relative",
                  top: "3px",
                }}
                src={IconLiveCall}
              />{" "}
            </Button>
					);
				case 'queued':
					return (
            <Button
              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
              style={{ margin: '0px 5px 10px' }}
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalPhone(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: "13px",
                  width: "14px",
                  marginRight: "5px",
                  position: "relative",
                  top: "3px",
                }}
                src={IconPhoneBusy}
              />{" "}
            </Button>
					);
				default:
					return (
            <Button
              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
              style={{ margin: '0px 5px 10px' }}
              disabled="true"
            >
              <ReactSVG
                style={{
                  fontSize: "13px",
                  width: "14px",
                  marginRight: "5px",
                  position: "relative",
                  top: "3px",
                }}
                src={IconMute}
              />{" "}
            </Button>
					);
			}
		};

		const ButtonVideoCall = (call) => {
			switch (call.videoCallStatus) {
				case 'available':
					return (
            <Button
              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
              style={{ margin: '0px 5px 10px' }}
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalVideo(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: "13px",
                  width: "14px",
                  marginRight: "5px",
                  position: "relative",
                  top: "3px",
                }}
                src={IconVideoBasic}
              />{" "}
            </Button>
					);
				case 'queued':
					return (
            <Button
              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
              style={{ margin: '0px 5px 10px' }}
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalVideo(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: "13px",
                  width: "14px",
                  marginRight: "5px",
                  position: "relative",
                  top: "3px",
                }}
                src={IconVideoBusy}
              />{" "}
            </Button>
					);
				default:
					return (
            <Button
              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
              style={{ margin: '0px 5px 10px' }}
              disabled="true"
            >
              <ReactSVG
                style={{
                  fontSize: "13px",
                  width: "14px",
                  marginRight: "5px",
                  position: "relative",
                  top: "3px",
                }}
                src={IconVideoSlash}
              />{" "}
            </Button>
					);
			}
		};

    return (
      <div>
        <div className={classes.Container}>
          <CoverPhotoUpload firstAvailableCalls={this.state.firstAvailableCalls} profileWatching={profileWatching}/>
          <div className={classes.MainContent}>
            <div className={classes.LeftSidebar}>
              {teacherWatchSelf ? (
                <PhotoUpload profile={true} />
              ) : (
                profileWatching && (
                  <div style={{ margin: "12px 0" }}>
                    <Avatar
                      src={
                        profileWatching.profile.photo &&
                        profileWatching.profile.photo.big
                      }
                      size={this.avatarSize()}
                      style={{border : `${this.props.profile}` ? `5px solid white` : `transparent`, display: 'inline-block'}}
                    />
                  </div>
                )
              )}
              {screenWidth > 768 && (
                <div style={{ textAlign: "center", margin: "12px 0 34px" }}>
                  {renderFollowButton}
                  <Button
                    style={{ marginLeft: "6px" }}
                    onClick={(e) =>
                      this.copy(e, { vertical: "top", horizontal: "center" })
                    }
                    className={classes.ButtonSettings}
                  >
                    <ReactSVG
                      style={{
                        fontSize: "13px",
                        width: "14px",
                        marginRight: "5px",
                        position: "relative",
                        top: "3px",
                      }}
                      src={ShareIcon}
                    />{" "}
                    Share profile
                  </Button>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  open={open}
                  autoHideDuration={5000}
                  onClose={() => this.setState({ open: false })}
                  ContentProps={{
                    "aria-describedby": "message-id",
                  }}
                  message={<span id="message-id">Copied</span>}
                />
              </div>
              {screenWidth <= 768 ? (
                <>
                  <div className={classes.CoverContent}>
                    <div>
                      <p style={{ fontSize: "24px" }}>{profileWatching && <ChosenDisplayName user={profileWatching} top="-2px" />}</p>
                      {profileWatching && shouldDisplayUsernameSection(profileWatching) && <p>{profileWatching && profileWatching.username}</p>}
                      <p>{profileWatching && profileWatching.profile.city}</p>
                    </div>
                    {teacherWatchSelf ? (
                      <>
                        <Link to={'/account/dashboard/videos'}>
                          <Button
                            className={[classes.Button, classes.ButtonForMobile].join(" ")}
                            size="medium"
                            color="primary"
                            style={{ margin: '0px 5px 10px', height: '44px' }}
                          >
                            Manage classes
                          </Button>
                        </Link>
                        <Link to={'/account/settings/personal'}>
                          <Button
                            className={[classes.Button, classes.ButtonForMobile].join(" ")}
                            color="primary"
                            size="medium"
                            style={{ margin: '0px 5px 10px', height: '44px' }}
                          >
                            Edit profile
                          </Button>
                        </Link>
                        <Button
                          onClick={(e) =>
                            this.copy(e, { vertical: "top", horizontal: "center" })
                          }
                          className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
                          style={{ margin: '0px 5px 10px' }}
                        >
                          <ReactSVG
                            style={{
                              position: "relative",
                              top: "3px",
                            }}
                            src={ShareIcon}
                          />{" "}
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.props.user ? (
                          <>
                            {profileWatching && !teacherWatchSelf && this.state.firstAvailableCalls && (
                              <>
                                {this.state.firstAvailableCalls.haveCallClasses &&
                                  ButtonCall(
                                    this.state.firstAvailableCalls.call &&
                                    this.state.firstAvailableCalls.call.data &&
                                    this.state.firstAvailableCalls.call.data[0]
                                      ? this.state.firstAvailableCalls.call.data[0]
                                      : {}
                                  )}
                                {this.state.firstAvailableCalls.haveVideoClasses &&
                                  ButtonVideoCall(
                                    this.state.firstAvailableCalls.videoCall &&
                                    this.state.firstAvailableCalls.videoCall.data &&
                                    this.state.firstAvailableCalls.videoCall.data[0]
                                      ? this.state.firstAvailableCalls.videoCall.data[0]
                                      : {}
                                  )}
                              </>
                            )}
                            {profileWatching && <Link to={`/messages/${profileWatching.cryptName}`}>
                              <Button
                                  className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
                                  style={{ margin: '0px 5px 10px' }}
                              >
                                <ReactSVG
                                    style={{
                                      fontSize: "13px",
                                      width: "14px",
                                      marginRight: "5px",
                                      position: "relative",
                                      top: "3px",
                                    }}
                                    src={IconMessage}
                                />{" "}
                              </Button>
                            </Link>}
                          </>
                        ) : (
                          <>
                              {this.state.firstAvailableCalls.haveCallClasses && <Button
                                  className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
                                  style={{ margin: '0px 5px 10px' }}
                                  onClick={() => this.openSignUpModal()}
                              >
                                  <ReactSVG
                                      style={{
                                          fontSize: "13px",
                                          width: "14px",
                                          marginRight: "5px",
                                          position: "relative",
                                          top: "3px",
                                      }}
                                      src={IconLiveCall}
                                  />{" "}
                              </Button>}
                              {this.state.firstAvailableCalls.haveVideoClasses && <Button
                                  className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
                                  style={{ margin: '0px 5px 10px' }}
                                  onClick={() => this.openSignUpModal()}
                              >
                                  <ReactSVG
                                      style={{
                                          fontSize: "13px",
                                          width: "14px",
                                          marginRight: "5px",
                                          position: "relative",
                                          top: "3px",
                                      }}
                                      src={IconVideoBasic}
                                  />{" "}
                              </Button>}
                            <Button
                              className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
                              style={{ margin: '0px 5px 10px' }}
                              onClick={() => this.openSignUpModal()}
                            >
                              <ReactSVG
                                style={{
                                  fontSize: "13px",
                                  width: "14px",
                                  marginRight: "5px",
                                  position: "relative",
                                  top: "3px",
                                }}
                                src={IconMessage}
                              />{" "}
                            </Button>
                          </>
                        )}
                        {renderFollowButton}
                        <Button
                          onClick={(e) =>
                            this.copy(e, { vertical: "top", horizontal: "center" })
                          }
                          className={[classes.ButtonSettings, classes.onlyIcon].join(" ")}
                          style={{ margin: '0px 5px 10px' }}
                        >
                          <ReactSVG
                            style={{
                              position: "relative",
                              top: "3px",
                            }}
                            src={ShareIcon}
                          />{" "}
                        </Button>
                      </>
                    )}
                  </div>
                  <div>
                    <div style={{ fontSize: "20px", marginTop: "20px" }} className={classes.SectionTitles}>
                      About
                    </div>
                    {profileWatching && (
                      <p style={{ marginBottom: '0px' }}>
                        {profileWatching.profile.introAboutMe}
                      </p>
                    )}
                    <ExpansionPanel
                      paneltitle={"Show more"}
                      paneltitleExpanded={"Show less"}
                      styles={expansionPanelStyles}
                      className={classes.ShowMorePanel}
                    >
                      {/* intro */}
                      {teacherInfo}
                    </ExpansionPanel>
                  </div>
                </>
              ) : (
                <React.Fragment>
                  {/* intro */}
                  {teacherInfo}
                </React.Fragment>
              )}
            </div>
            <SignUpModal
              open={modalSignUpOpened}
              onClose={this.closeSignUpModal}
            />
            <LiveCallModal
              open={this.state.modalCallOpened}
              onClose={this.closeCallModal}
              call={this.state.selectedCall}
              type={this.state.modalCallType}
            />
            {this.state.twilioVideo && this.state.roomToken !== '' ? (
              <TwilioVideo
                roomName={this.state.roomName}
                roomToken={this.state.roomToken}
                incomingCallUser={this.state.participient}
                videoName={this.state.videoName}
                photoUrl={this.state.participientUrlImage}
                author={this.state.author}
                closeTwilioVideo={this.closeTwilioVideo}
              />
            ) : (
              ''
            )}
            <article className={classes.Main}>
              {/* Tabs */}
              {screenWidth > 1024 ? (
                <Tabs
                  activetab={activeTab}
                  config={this.state.tabConfig}
                  onChangeTab={(e, val) => this.handleTabChange(e, val)}
                />
              ) : (
                <div className={classes.SelectContainer}>
                  <CustomSelect
                    activetab={activeTab}
                    config={this.state.tabConfig}
                    onChangeOption={(e, val) => this.handleOptionChange(e)}
                  />
                </div>
              )}
            </article>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(windowWidth(Profile));
