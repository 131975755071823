import React, { Component } from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import clone from "lodash.clonedeep";
import classes from "./SignUpTeacherStep2.module.scss";
import { Form, Input } from "../../components/UI/Form/Form";
import Button from "../../components/UI/Button/Button";
import Card from "../../components/UI/Card/Card";
import * as validator from "../../utils/formValidator";
import * as userService from "../../services/user/userService";
import { capitalize, isObject } from "lodash";
import {
  getPlanConst,
  getPricingPlan,
  getTotalPrice,
  isPricedPlans
} from "../../utils/consts";
import Divider from "../../components/UI/Divider/Divider";
import countriesJSON from "../../shared/json/countries.json";
import usaStatesJSON from "../../shared/json/usaStates.json";
import { Elements, StripeProvider } from "react-stripe-elements";
import apiConfig from "../../utils/apiConfig";
import SubscriptionForm from "../BuyCredits/StripeForm/SubscriptionForm";
import * as actions from "../../store/actions/indexActions";
import PaymentInformation from "../Subscriptions/PaymentInformation"

const inputFont = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800"
  }
];

function hasValidCountry(user) {
  const { country } = user.profile;

  if (country === "USA") {
    const { state } = user.profile;
    return !!state;
  }
  return country !== "";
}

function hasBillingInformation(user) {
  if (user) {
    return (
      user.profile.street &&
      user.profile.city &&
      user.profile.zipCode &&
      hasValidCountry(user)
    );
  }
  return false;
}

class UpgradeTeacherPlan extends Component {
  state = (() => {
    const user = this.props.user;
    const formIsValid = hasBillingInformation(user);
    return {
      loading: false,
      payment: null,
      amount: null,
      stripeObject: null,
      stripeToken: null,
      stripeConfirmed: false,
      cardElement: null,
      paymentFinished: false,
      paymentFormValid: false,
      price: 0,
      formIsValid,
      formControls: {
        street: {
          id: "Street address",
          type: "text",
          placeholder: "Street address",
          placeholderStatic: "Enter street name and number",
          shrink: true,
          value: user.profile.street,
          validation: { required: true },
          error: "",
          valid: false,
          touched: user.profile.street
        },
        city: {
          id: "city",
          type: "text",
          placeholderStatic: "Enter city",
          placeholder: "City",
          shrink: true,
          value: user.profile.city,
          validation: { required: true },
          error: "",
          valid: false,
          touched: user.profile.city
        },
        zipCode: {
          id: "zipCode",
          type: "text",
          placeholder: "ZIP code",
          placeholderStatic: "Enter ZIP code",
          shrink: true,
          value: user.profile.zipCode,
          validation: { required: true },
          error: "",
          valid: false,
          touched: user.profile.zipCode
        },
        country: {
          id: "country",
          type: "text",
          select: true,
          native: true,
          // using the same list that backend is using and adding an empty value for placeholder
          selectList: [...countriesJSON, { value: "", label: "" }],
          placeholder: "Country",
          placeholderStatic: "Select country",
          shrink: true,
          value: user.profile.country || "",
          validation: { required: true },
          error: "",
          valid: false,
          touched: user.profile.country
        },
        state: {
          id: "state",
          type: "text",
          select: true,
          native: true,
          // formating the object of state names into array with proper value/label pairs to be accepted by input select
          selectList: Object.keys(usaStatesJSON).reduce(
            (agg, cur) => {
              agg.push({ value: cur, label: usaStatesJSON[cur] });
              return agg;
            },
            [{ value: "", label: "" }]
          ),
          placeholder: "State",
          placeholderStatic: "Select state",
          shrink: true,
          value: user.profile.state,
          validation: { required: true },
          error: "",
          valid: false,
          touched: user.profile.state
        }
      }
    };
  })();

  componentDidMount() {
    this.getPaymentsInformation()
    this.getDiscountedPrice()
    window.scrollTo(0, 0);
    this.setState({ stripeObject: window.Stripe(apiConfig.STRIPE_ID) });
  }

  getPaymentsInformation = () => {
    userService
      .getMyCurrentSubscription(this.props.token)
      .then(response => {

        if(isObject(response.data)) {
          this.setState({ loading: false, payment: response.data })
        } else {
          const {planId, isAnnualBilling } = this.props.match.params
          this.props.history.push(`/sign-up-teacher-step-2/${planId}/${isAnnualBilling}`)
        }

      })
      .catch(error => {
        console.log(error.data)
      })
  }

  getDiscountedPrice = () => {
    // console.log(this.props)
    const {match, user } = this.props

    let { planId, isAnnualBilling } = match.params;
    if (!isPricedPlans(planId)) {
      // throw new Error
      return null;
    }
    if (user.plan === 'basic' && planId !== 'pro') {
      // throw new Error
      return null;
    }

    isAnnualBilling = !!+isAnnualBilling;
    const chosenPlan = getPricingPlan(planId);
    const totalPrice = getTotalPrice(chosenPlan, isAnnualBilling);
    const planType = getPlanConst(planId, isAnnualBilling);

    console.log(planType)


    userService
      .getDiscountedPrice(this.props.token, `plan=${planType}`)
      .then(response => {
        const { price } = response.data
        console.log(response)
        console.log(price)
        console.log(totalPrice)
      })
      .catch(error => {
        console.log(error)
      })
  }

  // clears the errors and 'dirty' the input on change. Errors will be checked on blur
  clearInput = inpt => {
    inpt.touched = true;
    inpt.error = "";

    // if input is part of the group clear all group memebers
    if (inpt.group) {
      inpt.groupError = false;
    }
  };

  // exclude the input from validation if they are constrainded to certain conditon
  excludeRestrictedInputs = (e, controlName, form) => {
    let formFiltered;
    // if country is a non USA or is a student there is no social number fields so remove them from validation
    if (this.props.user.type === "student" || form.country.value !== "USA") {
      formFiltered = clone(this.state.formControls);
      // keep the reference to the original input (ctrl) as we still need to validate most current value
      formFiltered[controlName] = form[controlName];
      // delete formFiltered.socialNumber1;
      // delete formFiltered.socialNumber2;
      // delete formFiltered.socialNumber3;
      // only in case of USA value as a country, keep the state input for validation
      if (form.country.value !== "USA" && e.target.value !== "USA") {
        delete formFiltered.state;
      }
    }
    return formFiltered;
  };

  inputChangeHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];
    //console.log(this.state.formIsValid);

    // if there is no value it is the radio/checkbox input, in that case take the name
    if (ctrl.type !== "checkbox") {
      ctrl.value = e.target.value;

      // SPECIAL USA CHECK! If it is not USA empty the dependant fields
      if (controlName === "country" && e.target.value !== "USA") {
        formControlsCopy.state.value = "";
        formControlsCopy.state.error = "";
      }
    } else {
      ctrl.value = ctrl.value === controlName ? "" : controlName;
    }
    // if input is part of the group highlight all the memeber of a group in case of an error
    if (ctrl.group) {
      ctrl.group.forEach(inputName => {
        this.clearInput(formControlsCopy[inputName]);
      });
    } else {
      this.clearInput(ctrl);
    }

    // remove inputs from validation if it has rules: 'State' input shown only for USA or social number only for teachers
    const filteredForm = this.excludeRestrictedInputs(
      e,
      controlName,
      formControlsCopy
    );

    this.setState({
      formControls: formControlsCopy,
      // if is is a non USA country selected exclude state and social number checks
      formIsValid: validator.formIsValid(
        filteredForm ? filteredForm : formControlsCopy
      )
    });
  };

  // checks validity of single input
  getInputError = input => {
    return validator.validateInput(input);
  };

  inputBlurHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    // if it is a group show error only underneath the first input
    if (ctrl.group) {
      const errorMsg = this.getInputError(ctrl);
      if (errorMsg) {
        // show the msg on first input
        formControlsCopy[ctrl.group[0]].error = errorMsg;
        // set error on all group fields
        ctrl.group.forEach(inputName => {
          formControlsCopy[inputName].groupError = true;
        });
      }
    } else {
      ctrl.error = this.getInputError(ctrl);
    }

    // remove inputs from validation if it has rules: 'State' input shown only for USA or social number only for teachers
    const filteredForm = this.excludeRestrictedInputs(
      e,
      controlName,
      formControlsCopy
    );

    this.setState({
      formControls: formControlsCopy,
      // if is is a non USA country selected exclude state and social number checks
      formIsValid: validator.formIsValid(
        filteredForm ? filteredForm : formControlsCopy
      )
    });
  };

  submit = async ({ planType }) => {
    if (this.state.formIsValid) {
      this.setState({ loading: true });
      const cloneForm = clone(this.state.formControls);
      this.setState({
        formIsValid: false,
        formControls: cloneForm,
        loading: true
      });

      const country = this.state.formControls.country.value;
      let profile = {
        street: this.state.formControls.street.value,
        city: this.state.formControls.city.value,
        zipCode: this.state.formControls.zipCode.value,
        country
      };

      if (country === "USA") {
        profile = { ...profile, state: this.state.formControls.state.value };
      }

      try {
        const response = await userService.upgradeSubscription(
          this.props.token,
          { profile, plan: planType });

        console.log(response);

        // const payment = response.data;

        this.props.snackbarAdd({
          message: "The upgrade was done successfully!",
          type: "info",
          timeout: 10000
        });

        this.setState({ loading: false });
        setTimeout(() => {
          this.props.history.push("/account/settings/subscription");
        }, 3000);
      } catch (e) {
        console.log(e);
        this.setState({ loading: false });
      }
    } else {
      this.setState({
        formIsValid: validator.formIsValid(this.state.formControls)
      });
      // TODO focus to the first errored input
    }
  };

  // Stripe methods
  setPaymentToken = token => {
    this.setState({
      stripeToken: token
    });
  };

  clearPaymentToken = () => {
    this.setState({
      stripeToken: null
    });
  };
  showError = message => {
    console.log(message);
    this.props.snackbarAdd({
      message: message,
      type: "error",
      timeout: 10000
    });
  };

  stripeFormConfirmed = stripeConfirmed => {
    this.setState({ stripeConfirmed });
  };

  updateCardElement = cardElement => {
    this.setState({ cardElement });
  };

  render() {
    console.log(this.props.user)
    const {user} = this.props;
    if (user.plan === 'pro') {
      // throw new Error
      return null;
    }


    let { planId, isAnnualBilling } = this.props.match.params;
    if (!isPricedPlans(planId)) {
      // throw new Error
      return null;
    }
    if (user.plan === 'basic' && planId !== 'pro') {
      // throw new Error
      return null;
    }

    isAnnualBilling = !!+isAnnualBilling;
    const chosenPlan = getPricingPlan(planId);
    const totalPrice = getTotalPrice(chosenPlan, isAnnualBilling);
    const planType = getPlanConst(planId, isAnnualBilling);

    // only visible when USA is selected as a country
    const USAInfoRender = () =>
      this.state.formControls.country.value === "USA" ? (
        <React.Fragment>
          <Input
            config={this.state.formControls.state}
            onChange={e => this.inputChangeHandler(e, "state")}
            onBlur={e => this.inputBlurHandler(e, "state")}
          />
          {/* {teacherOnlyInfoRender()} */}
        </React.Fragment>
      ) : null;

    return (
      <div className={classes.Container}>
        <h1
          style={{ marginBottom: "30px" }}
        >
          {`Start your ${capitalize(chosenPlan.name)} plan for teachers`}
        </h1>
        {/* {chosenPlan.pricePerMonthMonthly > 0 && (
          <div style={{ marginBottom: "30px" }}>Step 2 of 2</div>
        )} */}

        {chosenPlan.pricePerMonthMonthly > 0 && (
          <div className={classes.CostsSummary}>
            <div className={classes.Details}>
              {capitalize(chosenPlan.name)} plan for teachers
            </div>
            <div style={{ marginTop: "6px" }}>
              $
              {isAnnualBilling
                ? `${chosenPlan.pricePerMonthAnnually}/month (billed annually)`
                : `${chosenPlan.pricePerMonthMonthly}/month (billed monthly)`}
            </div>
            <Divider
              horizontal="true"
              style={{ margin: "15px 0", backgroundColor: "#cae5d7" }}
            />
            <div className={classes.Details}>
              <div>Today's total</div>
              <div>${totalPrice.toLocaleString()}</div>
            </div>
          </div>
        )}

        <Card className={classes.SignUpCard}>
          <Form onSubmit={() => this.submit({ planType })}>
            <h3>Billing information</h3>
            <Input
              config={this.state.formControls.street}
              onChange={e => this.inputChangeHandler(e, "street")}
              onBlur={e => this.inputBlurHandler(e, "street")}
            />
            <Input
              config={this.state.formControls.city}
              onChange={e => this.inputChangeHandler(e, "city")}
              onBlur={e => this.inputBlurHandler(e, "city")}
            />
            <Input
              config={this.state.formControls.zipCode}
              onChange={e => this.inputChangeHandler(e, "zipCode")}
              onBlur={e => this.inputBlurHandler(e, "zipCode")}
            />
            <Input
              config={this.state.formControls.country}
              onChange={e => this.inputChangeHandler(e, "country")}
              onBlur={e => this.inputBlurHandler(e, "country")}
            />

            {/*only visible when USA is selected as a country*/}
            {USAInfoRender()}

            {!this.state.payment ? (
              <>
                <h3 style={{marginTop: '30px', marginBottom: '20px'}}>Payment information</h3>
                <p className={classes.PaymentInfo} style={{ marginBottom: '-10px' }}>
                  Our payment process is secured, and InfoDepot company cannot store
                  nor access this data.
                </p>
                <StripeProvider stripe={this.state.stripeObject}>
                  <Elements fonts={inputFont}>
                    <SubscriptionForm
                      onFormComplete={this.setPaymentToken}
                      onFormUncomplete={this.clearPaymentToken}
                      onConfirmed={this.stripeFormConfirmed}
                      updateCardElement={this.updateCardElement}
                      onError={error => this.showError(error.message)}
                    />
                  </Elements>
                </StripeProvider>

                <p className={classes.PaymentInfo}>
                  {`You will be charged now and ${
                    isAnnualBilling ? "yearly" : "monthly"
                  } at this time when your
                subscription automatically renews. Cancel automatic renewal at any
                time.`}
                </p>
              </>
            ) : (
              <div style={{ marginTop: '30px' }}>
                <PaymentInformation payment={this.state.payment} />
              </div>
            )}
            <Button
              fullWidth={true}
              size="large"
              type="submit"
              disabled={
                this.state.loading ||
                !this.state.formIsValid
                // || !this.state.stripeConfirmed
              }
              loading={this.state.loading}
            >
              UPGRADE MY PLAN
            </Button>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    snackbarAdd: snackConf => dispatch(actions.snackbarAdd(snackConf)),
    updateLoggedUser: () => dispatch(actions.getLoggedUser())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpgradeTeacherPlan));
