import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clone from 'lodash.clonedeep';
import { Divider } from '@material-ui/core';
//import PaypalBtn from 'react-paypal-checkout';
import { Elements, StripeProvider } from 'react-stripe-elements';
import ReactSVG from 'react-svg';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';

import classes from './BuyCredits.module.scss';
import './OverrideStripe.scss';
import Card from '../../components/UI/Card/Card';
import * as paymentService from '../../services/payment/paymentService';
import * as actions from '../../store/actions/indexActions';
import Button from '../../components/UI/Button/Button';
import { Form, Input } from '../../components/UI/Form/Form';
import * as validator from '../../utils/formValidator';
import { errorParser } from '../../utils/errorParser';
import StripeForm from './StripeForm/StripeForm';
import apiConfig from '../../utils/apiConfig';
import PaypalButton from '../../components/PaypalButton/PaypalButton';
import IconPaypal from './../../assets/images/icons/paypal_icon.svg';
import IconCard from './../../assets/images/icons/credit_card_icon.svg';
import { addToUrlQueue, goToNextQueuedUrl, isUrlQueued } from '../../utils/redirectTo';

const inputFont = [
	{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800' }
];
class BuyCredits extends Component {
	state = {
		stripeObject: null,
		stripeToken: null,
		instanceLoaded: false,
		bundleSelected: null,
		paymentSelected: null,
		inputShown: null,
		loading: false,
		paymentFinished: false,
		paymentFormValid: false,
		formIsValid: false,
		formControls: {
			creditsAmount: {
				id: 'creditsAmount',
				type: 'text',
				placeholderStatic: 'Enter number of dollars you want to Top Up',
				value: '',
				validation: {
					required: true,
					isNumericDecimal: true
				},
				error: '',
				valid: false,
				touched: false
			}
		}
	};

	componentDidMount(){
		if (!this.props.user.profile.street) {
			this.props.snackbarAdd({
				message: 'To buy credits, please add your billing address.',
				type: 'info',
				timeout: 10000
			});

			this.props.history.push('/account/settings/payment?' + addToUrlQueue(this.props.location.pathname, this.props));
		}
		this.setState({stripeObject: window.Stripe(apiConfig.STRIPE_ID)})
	}

	updateStore = () => {
		this.props.updateLoggedUser();
	};

	onBundleClick = (bundleVal) => {
		if (bundleVal === 'custom') {
			// show the custom input
			this.setState({ inputShown: true, bundleSelected: 'custom' });
			
			
			setTimeout(
				()=>this.inputChangeHandler({target:{value:0}}, 'creditsAmount')
			);
			
			// focus the input on custom button hit
			this.inputRef.focus();
		} else {
			this.inputChangeHandler(bundleVal, 'creditsAmount');
		}
	};

	onPaymentClick = (e, paymentType) => {
		e.preventDefault();
		if (this.state.paymentSelected) {
			this.setState({ paymentSelected: null });
			setTimeout(() => this.setState({ paymentSelected: paymentType }), 310);
		} else {
			this.setState({ paymentSelected: paymentType });
		}
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// when there is no event.value we are using buttons to set the val so just take that data
		if (e.target) {
			ctrl.value = e.target.value;
		} else {
			ctrl.value = e;
		}

		ctrl.touched = true;
		ctrl.error = '';
		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy),
			bundleSelected: e.target ? this.state.bundleSelected : ctrl.value,
			// if we are not updating the value from the input hide the custom input
			inputShown: e.target ? true : false
		});
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		const errorMsg = validator.validateInput(ctrl);
		if (errorMsg) {
			ctrl.error = errorMsg;
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	// Stripe methods
	setPaymentToken = (token) => {
		this.setState({
			stripeToken: token
		});
	};

	clearPaymentToken = () => {
		this.setState({
			stripeToken: null
		});
	};

	onPaypalSuccess = (payment) => {
		console.log('Successful paypal fetch', payment);
		this.setState({
			paymentFinished: true
		});
		this.submit('paypal', payment.orderID);
	};

	submit = async (transactionType, paymentID) => {
		if (
			(transactionType === 'paypal' || this.state.stripeToken) &&
			this.state.formIsValid &&
			this.props.user.profile.street
		) {
			// start loader
			this.setState({ loading: true });

			// once we get nonce send it to our API with the price definied
			const amount = this.state.formControls.creditsAmount.value;
			const { token } = this.props;

			// payment via credit card, stripe
			const stripeService = await paymentService.makeTransaction;
			// payment via paypal
			const paypalService = await paymentService.makeTransactionPaypal;
			// current service
			const currentService = transactionType === 'stripe' ? stripeService : paypalService;

			currentService(token, paymentID, amount)
				.then((response) => {
					if (response.status === 200 && transactionType === 'stripe') { //need SCA, 201 if no need SCA
                        this.handleStripeSca(response);
					} else {
						this.updateStore();
						this.setState({loading: false});
						if (isUrlQueued(this.props)) {
							goToNextQueuedUrl(this.props);
						} else {
							this.props.history.push('/successful-payment');
						}
					}
				})
				// error
				.catch((error) => {
					const cloneForm = clone(this.state.formControls);
					this.showError(error.response.data.message);
					this.setState({
						formIsValid: false,
						formControls: cloneForm,
						loading: false
					});
				});
		}
	};

	handleStripeSca = (response) => {
		const stripe = this.state.stripeObject;
		stripe.handleCardAction(response.data.payment_intent_client_secret).then((result) => {
			this.handleScaResponse(result);
		}).catch((error) => {
			const cloneForm = clone(this.state.formControls);
			this.showError(error.message);
			this.setState({
				formIsValid: false,
				formControls: cloneForm,
				loading: false
			});
			this.clearPaymentToken();
		});
	};

	handleScaResponse = async (result) => {
		const amount = this.state.formControls.creditsAmount.value;
		const { token } = this.props;
		const cloneForm = clone(this.state.formControls);
		if (result.error) {
			this.showError(result.error.message);
			this.setState({
				formIsValid: false,
				formControls: cloneForm,
				loading: false,
			});
			this.clearPaymentToken();
		} else {
			const stripeService = await paymentService.makeTransaction;
			stripeService(token, result.paymentIntent.id, amount, true).then((result) => {
				this.updateStore();
				this.setState({loading: false});
				if (isUrlQueued(this.props)) {
					goToNextQueuedUrl(this.props);
				} else {
					this.props.history.push('/successful-payment');
				}
			}).catch((error) => {
				this.showError(error.response.data.message);
				this.setState({
					formIsValid: false,
					formControls: cloneForm,
					loading: false
				});
				this.clearPaymentToken();
			})
		}
	};

	showError = (message) => {
		this.props.snackbarAdd({
			message: message,
			type: 'error',
			timeout: 10000
		});
	};

	render() {
		const user = this.props.user;

		const loaderRender = <CircularProgress className={classes.PaymentLoader} />;

		const credits =
			(this.state.formControls.creditsAmount.value &&
				Number(this.state.formControls.creditsAmount.value).toFixed(2)) ||
			'0.00';

		const bundlesConfig = [
			{ label: '$15', value: '15.00' },
			{ label: '$25', value: '25.00' },
			{ label: '$50', value: '50.00' },
			{ label: '$100', value: '100.00' },
			{ label: 'Custom', value: 'custom' }
		];

		const isBundleSelected = (val) => {
			return this.state.bundleSelected === val;
		};

		const isPaymentSelected = (val) => {
			return this.state.paymentSelected === val;
		};

		const query = new URLSearchParams(this.props.location.search);

		const bundlesRender = () =>
			bundlesConfig.map((i) => (
				<Button
					key={i.value}
					active={isBundleSelected(i.value)}
					type="input"
					size="large"
					onClick={() => this.onBundleClick(i.value)}>
					{i.label}
				</Button>
			));

		const countryState = this.props.user.profile.fullStateName
			? ', ' + this.props.user.profile.fullStateName
			: '';

		const addressRender = () =>
			user.profile.street ? (
				<React.Fragment>
					<div className={classes.AddressWrap}>
						<span>{user.profile.street}</span>
						<span>{user.profile.city + countryState}</span>
						<span>{user.profile.zipCode}</span>
						<span>{user.profile.fullCountryName}</span>
					</div>
					<div className={classes.EditAccountWrap}>
						To add or edit billing address go to your{' '}
						<Link to={'/account/settings/payment?' + addToUrlQueue(this.props.location.pathname, this.props)}>
							Account Settings
						</Link>.
					</div>
				</React.Fragment>
			) : (
				<div className={classes.NoInformationText}>
					No information yet.
					<p>To buy credits, please add your billing address.</p>
					<Link to={'/account/settings/payment?' + addToUrlQueue(this.props.location.pathname, this.props)}>
						<Button color="secondary">Add billing address</Button>
					</Link>
				</div>
			);

		const formIsInvalid =
			this.state.loading || !this.state.formIsValid || !user.profile.street;

		const buttonPayBase = (text) => (
			<Button
				className={classes.PayButton}
				fullWidth={true}
				size="large"
				type="submit"
				disabled={!this.state.stripeToken || formIsInvalid}
				loading={this.state.loading}>
				{text}
			</Button>
		);

		const paymentFinishedCheck = this.state.paymentFinished;

		const buttonPayRender =
			isUrlQueued(this.props)
				? buttonPayBase('PAY AND GO BACK')
				: buttonPayBase('PAY');

		return (
			<div className={classes.Container}>
				<h1>Top Up your account</h1>
				<Card className={classes.Card}>
					{/* loader */}
					{paymentFinishedCheck == true ? loaderRender : ''}
					<div className={classes.SubtitlesBold}>
						How many dollars do you want to add to your account?
					</div>
					<div className={classes.ButtonsWrap}>{bundlesRender()}</div>
					<Form onSubmit={() => this.submit('stripe', this.state.stripeToken.id)}>
						{/* custom price */}
						<Input
							className={[
								classes.InputCustom,
								this.state.inputShown ? classes.InputCustomShown : ''
							].join(' ')}
							inputRef={(ref) => (this.inputRef = ref)}
							config={this.state.formControls.creditsAmount}
							onChange={(e) => this.inputChangeHandler(e, 'creditsAmount')}
							onBlur={(e) => this.inputBlurHandler(e, 'creditsAmount')}
						/>

						{/* price */}
						<div className={classes.PriceWrap}>
							Price: <span className={classes.TextGreen}>{'$' + credits}</span>
						</div>
						<Divider style={{ width: '100%', marginBottom: '20px' }} />
						{/* address */}
						<div className={classes.SubtitlesBold}>Billing address</div>
						{addressRender()}
						<Divider style={{ width: '100%', marginBottom: '20px' }} />

						<div className={classes.SubtitlesBold}>Choose a way to pay</div>

						<div className={classes.PaymentOptions} style={{ marginBottom: '30px' }}>
							<Button
								active={isPaymentSelected('card')}
								type="input"
								size="large"
								onClick={(e) => this.onPaymentClick(e, 'card')}>
								<ReactSVG
									className={classes.IconCard}
									svgClassName={isPaymentSelected('card') ? classes.IconActive : ''}
									src={IconCard}
								/>
								Card
							</Button>

							<Button
								active={isPaymentSelected('paypal')}
								type="input"
								size="large"
								nohover
								onClick={(e) => this.onPaymentClick(e, 'paypal')}>
								<ReactSVG className={classes.IconPaypal} src={IconPaypal} />
								PayPal
							</Button>
						</div>

						<div className={classes.CollapseWrap}>
							<Collapse
								style={{ width: '100%' }}
								in={isPaymentSelected('card')}
								timeout={310}>
								<StripeProvider stripe={this.state.stripeObject}>
									<Elements fonts={inputFont}>
										<StripeForm
											onFormComplete={this.setPaymentToken}
											onFormUncomplete={this.clearPaymentToken}
											onError={(error) => this.showError(error.message)}
										/>
									</Elements>
								</StripeProvider>
								{buttonPayRender}
							</Collapse>

							<Collapse
								style={{ width: '100%' }}
								in={isPaymentSelected('paypal')}
								timeout={310}>
								<div className={classes.PayPalContainer}>
									<PaypalButton amount={credits}  paymentSelected={this.state.paymentSelected} onSuccess={this.onPaypalSuccess} />
									{formIsInvalid && <div className={classes.OverlayWhite} />}
								</div>
								{formIsInvalid && (
									<p className={classes.EnterAmountMsg}>
										To Top Up your account, please first choose or enter an amount at the
										top of the form.
									</p>
								)}
							</Collapse>
						</div>
					</Form>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
		updateLoggedUser: () => dispatch(actions.getLoggedUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyCredits);
