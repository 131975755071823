import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ReactSVG from 'react-svg'

import classes from './ReviewsTab.module.scss';
import RateModal from '../../../../components/Ratings/RateModal/RateModal';
import * as ratingService from '../../../../services/rating/ratingService';
import Review from '../../../../components/Ratings/Review/Review';
import Button from '../../../../components/UI/Button/Button';
import IconStarEmpty from '../../../../assets/images/icons/star_empty_icon.svg';
import Pagination from '../../../../components/UI/Pagination/Pagination';

let paginationConf = {
	currentPage: 1,
	pageLimit: 5,
	total: null
};

const loaderRender = (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1rem'
    }}
  >
    <CircularProgress />
  </div>
)
class ReviewsTab extends React.PureComponent {
	state = {
		loading: true,
		loadingMore: false,
		modalReview: false,
		ratings: null,
		// a fast way to remove the purchase widget without calling the source data again
		localReviewedFlag: false
	};

	componentDidMount() {
		this.getReviews();
	}

	getReviews = (offset = 0) => {
		const slug = this.props.video.slug;
		this.setState({loadingMore: true});
		ratingService
			.getClassReviews(
				this.props.token,
				'video',
				slug,
				`&limit=${paginationConf.pageLimit}&offset=${offset}`
			)
			.then((response) => {
				paginationConf.total = response.data.length ? response.data[0]['_total'] : null;

				this.setState((state) => {
					const loadedReviews = state.ratings || [];
					let responseReviews = response.data || [];
					return {
						ratings: [ ...loadedReviews, ...responseReviews ],
						loading: false,
						loadingMore: false
					};
				});

			})
			.catch((error) => {
				console.log(error.response);
			});
	};

	showModalReview = () => {
    this.setState({ modalReview: true })
  }

  onHandleCloseReview = () => {
    this.setState({ modalReview: false })
  }

  onHandleSuccessReview = response => {
    this.props.onSuccessReview(response)
    this.setState({ loading: true, localReviewedFlag: true })
    setTimeout(() => {
      this.onHandleCloseReview()
      this.getReviews()
    }, 1000)
  }

	handlePaginationchange = (pag) => {
		if (pag.currentPage && pag.currentPage !== paginationConf.currentPage) {
			paginationConf.currentPage = pag.currentPage;
			this.getReviews((pag.currentPage - 1) * pag.pageLimit);
		}
	};

	onHandleSuccessReview = (response) => {
		this.props.onSuccessReview(response);
		this.setState({ loading: true, localReviewedFlag: true, ratings: null });
		setTimeout(() => {
			this.onHandleCloseReview();
			this.getReviews();
		}, 1000);
	};

	render() {
		const { loading, loadingMore, ratings, modalReview, localReviewedFlag } = this.state;
		const { video, showBuyModal, user } = this.props;
		const isAuthor = () => Boolean(user && user.username === video.author.username);

		const ReviewList =
			ratings &&
			ratings.length &&
			ratings.map((rating) => <Review review={rating} video={video} />);

		const LeaveReview =
			video.rated || localReviewedFlag ? null : (
				<div className={classes.LeaveReview}>
					<p style={{ textAlign: 'center' }}>
						Tell us about your experience with this class. Do you recommend it? Is it a
						good value and quality?
					</p>
					<Button onClick={this.showModalReview}>Leave a review</Button>
				</div>
			);
		const BuyVideo = !isAuthor() && (
			<div className={classes.PurchaseMessage}>
				<p>We only accept reviews from users who enrolled in the class.</p>
				<Button color="secondary" onClick={showBuyModal}>
					Buy Video Class
				</Button>
			</div>
		);
		const NoReviews = (
			<div className={classes.NoResults}>
				<ReactSVG src={IconStarEmpty} className={classes.IconEmptyStar} />
				<p>No reviews yet</p>
			</div>
		);

		const Content = (
			<div>
				{video.purchased ? LeaveReview : BuyVideo}
				<RateModal
					open={modalReview}
					onClose={this.onHandleCloseReview}
					onSuccess={this.onHandleSuccessReview}
					srcData={video}
					type="videoClass"
				/>

				{ratings && ratings.length ? ReviewList : NoReviews}

				{(ratings &&
				ratings.length &&
				paginationConf.total > ratings.length) ? (
					<Pagination
						totalRecords={paginationConf.total}
						pageLimit={paginationConf.pageLimit}
						pageNeighbours={1}
						onPageChanged={this.handlePaginationchange}
						loadMore
						loading={loadingMore}
					/>
				) : null}
			</div>
		);
		return loading ? loaderRender : Content;
	}
}

export default ReviewsTab
