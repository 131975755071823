import React from "react";
import ReactSVG from "react-svg";
import Avatar from "../UI/Avatar/Avatar";
import "./Messages.scss";
import DeleteIcon from "../../assets/images/icons/delete_icon-gray.svg";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import { ChosenDisplayName, shouldDisplayUsernameSection } from "../UI/ChosenDisplayName/ChosenDisplayName";

const MessageTitle = ({
  user,
  selectedThread,
  openConfirmModal,
  receiverObject,
}) => {
  return (
    <div className="head head--withBottomSeparatorShadow">
      <div className="userCard">
        <Avatar
          src={
            receiverObject &&
            receiverObject.profile &&
            receiverObject.profile.photo &&
            receiverObject.profile.photo.big
          }
          className="Avatar"
          size="42"
        />
        <div className="userCard__body">
          {/* <strong>
            {receiverObject && receiverObject.profileUrl ? (
              <Link to={`/profile/${receiverObject.profileUrl}`}>
                {receiverObject.fullName}
              </Link>
            ) : (
              receiverObject.fullName
            )}
          </strong>
          <p className="subname">{receiverObject && receiverObject.username}</p> */}
          <strong>
            {receiverObject && receiverObject.profileUrl ? (
              <Link to={`/profile/${receiverObject.profileUrl}`}>
                <ChosenDisplayName user={receiverObject} top="-1px" />
              </Link>
            ) : (
              <ChosenDisplayName user={receiverObject} top="-1px" />
            )}
          </strong>
          {receiverObject && shouldDisplayUsernameSection(receiverObject) && <p className="subname">{receiverObject && receiverObject.username}</p>}
          <div className="right">
            {selectedThread && (
              <div>
                <IconButton
                  size="small"
                  onClick={openConfirmModal}
                  className="IconTrashButton"
                >
                  <ReactSVG className="IconDelete" src={DeleteIcon} />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageTitle;
