import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress'
import {isSupported } from 'twilio-video';
import classes from './LiveCallModal.module.scss';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import apiConfig from '../../utils/apiConfig';
import * as callService from '../../services/liveCall/liveCallService';
import * as actions from '../../store/actions/indexActions';
import RateStars from '../../components/Ratings/RateStars/RateStars';
import IconLiveCall from './../../assets/images/icons/live_call_icon.svg';
import IconHourGlass from './../../assets/images/icons/hour_glass_icon.svg';
import IconCoins from '../../assets/images/icons/credits_icon.svg';
import IconSafari from '../../assets/images/icons/Safari-icon.svg';
import IconFirefox from '../../assets/images/icons/Firefox-icon.svg';
import IconEdge from '../../assets/images/icons/Edge-icon.svg';
import IconChrome from '../../assets/images/icons/Chrome-icon.svg';

import IconVideoSlash from '../../assets/images/icons/video-slash-solid.svg';
import IconVideoBasic from '../../assets/images/icons/video-solid.svg';
import CloseIcon from '../../assets/images/icons/menu close icon.svg';
import ReactSVG from 'react-svg';
import { addToUrlQueue } from '../../utils/redirectTo';
import Avatar from '../../components/UI/Avatar/Avatar';
import LeftShape from '../../assets/images/graphics/left_shape.svg';
import RightShape from '../../assets/images/graphics/right_shape.svg';
import LeftMiddleShape from '../../assets/images/graphics/left_middle_shape.svg';
import RightMiddleShape from '../../assets/images/graphics/right_middle_shape.svg';

import { ChosenDisplayName } from '../UI/ChosenDisplayName/ChosenDisplayName';

//Sound url
import SoundUrl from '../../assets/sounds/sound.mp3'

const intervalCheckingSatus = fn => setInterval(fn, 5000)

class BuyVideoModal extends React.PureComponent {
	state = {
		loading: false,
		modalVideoCall: false,
		modalLiveCall: true,
		videoCallInfo: [],
		roomName: '',
		roomToken: '',
		roomStatus: '',
		teacherStatus: '',
		twilioVideo: false,
		participant: '',
		videoName: '',
		videoQueuedModal: false,
		videoSlug: '',
		type: '',
		osDetected: null,
		authorOfCall: '',
	};


	componentDidMount(){
		this.detectOS();
	}

	detectOS = () => {
		const platform = navigator.platform.toLowerCase(),
		iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];

		const Chrome = (window.navigator.userAgent.indexOf('Android') > -1 && (/Chrome\/[.0-9]*/).test(window.navigator.userAgent)) || navigator.userAgent.match('CriOS');

		//alert('ios test', platform,  navigator.userAgent.indexOf("Safari"),navigator.userAgent.indexOf("Chrome"))
	
		if (platform.includes('mac')){
			//console.log('MacOS')
			this.setState({
				osDetected: 'MacOS'
			});
		} else if (platform.includes(iosPlatforms) || /iphone|ipod|ipad/i.test(navigator.userAgent)){
			//console.log('iOS')
			this.setState({
				osDetected: 'iOS'
			});
		} else if (platform.includes('win') && navigator.userAgent.indexOf("Safari") != -1){
			//console.log('Windows')
			this.setState({
				osDetected: 'Windows'
			});
		} else if ((/android/.test(navigator.userAgent.toLowerCase()) && navigator.userAgent.indexOf("Safari") != -1) || /android/.test(navigator.userAgent.toLowerCase()) && navigator.userAgent.indexOf("Edge") != -1){
			//console.log('Android')
			this.setState({
				osDetected: 'Android'
			});
		} else if ((/linux/.test(platform) && navigator.userAgent.indexOf("Safari") != -1) || (/linux/.test(platform) && navigator.userAgent.indexOf("Edge") != -1)){
			//console.log('Linux')
			this.setState({
				osDetected: 'Linux'
			});
		} else {
			//console.log('unknown');
			this.setState({
				osDetected: 'undefined'
			});
		}
	}

	//Check status of teacher in call
	checkCallStatus = () => {
		callService
			.callStatusStudent(this.props.token, this.state.videoCallInfo.slug)
			.then((response) => {
				this.setState({ 
					loading: false,
					roomName: response.data.roomName,
					roomToken: response.data.token,
					teacherStatus: response.data.videoCall.teacherStatus,
					roomStatus: response.data.videoCall.roomStatus,
					participant: response.data.videoCall.teacher.username,
					videoName: response.data.videoCall.callClass.name,
					videoSlug: response.data.videoCall.slug,
					authorOfCall: response.data.videoCall.author.fullName
				});

				//console.log('test', response.data.videoCall.author.fullName)

				if(response.data.videoCall.teacherStatus == 'declined' || response.data.videoCall.roomStatus == 'ended') {
					clearInterval(this.intervalCheck);

					this.setState({ 
						loading: false,
						modalVideoCall: false,
						videoCallInfo: [],
						twilioVideo: false,
					});

					this.props.onClose(response.data.roomName, response.data.token);

					this.props.snackbarAdd({
						message: 'We’re sorry, but the teacher is not available. Please try your call again later.',
						timeout: 8000,
						type: 'info'
					});
				}
				
				if(response.data.videoCall.teacherStatus == 'connected') {
					
						this.setState({ 
						  loading: false,
						  twilioVideo: true,
						  modalVideoCall: false,
						}, () => {
							clearInterval(this.intervalCheck);
							//Close modal
							this.props.onClose(response.data.roomName, 
							response.data.token, 
							this.state.participant, 
							this.state.videoName, 
							this.state.twilioVideo,
							this.state.videoSlug,
							response.data.videoCall.teacher.profile.photo && response.data.videoCall.teacher.profile.photo.big,
							this.state.authorOfCall
							);
							//console.log(response.data.videoCall.teacher.profile.photo.big)
						});
				 }
			})
			.catch((error) => {
				this.setState({ 
					loading: false,
					modalVideoCall: false,
					twilioVideo: false,
					videoCallInfo: [],
				});
				this.props.snackbarAdd({
					message: error.message,
					timeout: 8000,
					type: 'info'
				});
			});
	}

	//Delete call, stop call action
	deleteVideoCallModal = () => {
		//Delete Video call by student
		clearInterval(this.intervalCheck);

		this.setState({ 
			loading: true
		});

		callService
			.deleteVideoCall(this.props.token, this.state.videoCallInfo.slug)
			.then((response) => {
					this.setState({ 
						loading: false,
						videoCallInfo: [],
						modalVideoCall: false,
						modalLiveCall: false
					});

					this.props.onClose(response.data.roomName, response.data.token);

					this.props.snackbarAdd({
						message: 'You have canceled the call',
						timeout: 8000,
						type: 'info',
					});
					//console.log(response);

			})
			.catch((error) => {
				this.setState({ 
					loading: false
				});
				this.props.snackbarAdd({
					message: error.response.data.message,
					timeout: 8000,
					type: 'error'
				});
			});
	};

	handleTopUp = () => {
		if(this.props.type == 'phone') {
			this.props.history.push(
				`/buy-credits?` +
				addToUrlQueue('/call/' + this.props.call.slug + '?call-modal=1', this.props),
			);
		} else if (this.props.type == 'video'){
			this.props.history.push(
				`/buy-credits?` +
				addToUrlQueue('/call/' + this.props.call.slug + '?call-modal-video=1', this.props),
			);
		}
		
	};

	handleRedirectVideoCall = () => {
		let returnToUrlList = [];

		if (Math.floor(this.props.user.totalCredits / this.props.call.price) < 2) {

			returnToUrlList.push(
				'/buy-credits?' + addToUrlQueue('/call/' + this.props.call.slug + '?call-modal=1', this.props)
			);

			this.props.snackbarAdd({
				message:
					'To call a teacher you need to have enough dollars for at least two minutes, please Top Up your account.',
				type: 'info',
				timeout: 10000
			});
		}

		// proceed with redirection with 1 or 2 actions to finish before returning
		this.props.history.replace(returnToUrlList[returnToUrlList.length - 1]);
	}

	handleRedirect = () => {
		let returnToUrlList = [];

		if (Math.floor(this.props.user.totalCredits / this.props.call.price) < 1) {
			returnToUrlList.push(
				'/buy-credits?' + addToUrlQueue('/call/' + this.props.call.slug + '?call-modal=1', this.props)
			);

			this.props.snackbarAdd({
				message:
					'To call a teacher you need to have enough dollars for at least one minute, please Top Up your account.',
				type: 'info',
				timeout: 10000
			});
		}

		if (!this.props.user.profile.phoneNumber) {
			// if we have price redirection already in the returnToUrlList then after entering the phone number return to that URL
			const returningUrl = returnToUrlList.length
				? '/account/settings/contact?' +
				addToUrlQueue(returnToUrlList[0], this.props)
				: '/account/settings/contact?' +
					addToUrlQueue('/call/' + this.props.call.slug + '?call-modal=1', this.props);

			returnToUrlList.push(returningUrl);

			this.props.snackbarAdd({
				message: 'To place a call, please add your phone number.',
				type: 'info',
				timeout: 10000
			});
		}

		// proceed with redirection with 1 or 2 actions to finish before returning
		this.props.history.replace(returnToUrlList[returnToUrlList.length - 1]);
	};

	handleCall = () => {
		const price = this.props.call.price;

		// if there isn't enough credits for 1 minute of conversation or no phone number, redirect user to TopUp/Settings
		if (
			Math.floor(this.props.user.totalCredits / price) < 1 ||
			!this.props.user.profile.phoneNumber
		) {
			this.handleRedirect();
		} else {
		//	console.log('slug when make call',this.props.call.slug)
			
			this.setState({ loading: true });
			callService
				.startLiveCall(this.props.token, this.props.call.slug)
				.then((response) => {
					this.setState({ loading: false });
					//console.log('test live call modal',response.data.slug);

					if (this.props.onSuccess) {
						this.props.onSuccess(response.data.callClass);
					}

					this.props.history.push({
						pathname: `/call/${this.props.call.slug}/live-call`,
						state: { price: price, slug: response.data.slug, token: this.props.token}
					});

					// this.props.history.push({
					// 	pathname: `/call/${this.props.call.slug}/live-call`,
					// 	state: { price: price, slug: response.data.callClass.slug}
					// });
				})
				.catch((error) => {
					console.log(error.response);
					this.setState({ loading: false });
					this.props.snackbarAdd({
						message: error.response.data.message,
						timeout: 8000,
						type: 'error'
					});
				});
		}
	};
	

	handleCallVideo = () => {
		const price = this.props.call.price;
	
		//if there isn't enough credits for 1 minute of conversation or no phone number, redirect user to TopUp/Settings
		if (
			this.props.user.totalCredits / price < 2
		) {
			this.handleRedirectVideoCall();
		} else {
			this.setState({ loading: true });
			callService
				.startLiveCallVideo(this.props.token, this.props.call.slug)
				.then((response) => {
					this.setState({ loading: false, videoCallData: response.data, modalVideoCall: true });
					//console.log('b', response.data)

					this.setState({
						videoCallInfo: response.data,
						roomstatus: response.data.roomstaus
					});
					
					this.intervalCheck = setInterval(this.checkCallStatus, 5000);

					this.props.history.push({
						//pathname: `/call/${this.props.call.slug}/live-call-video`,
						state: { price: price}
					});
				})
				.catch((error) => {
					this.setState({ loading: false });
					this.props.snackbarAdd({
						message: error.response.data.message,
						timeout: 8000,
						type: 'error'
					});
				});
		}
	};

	handleAddToQueue = () => {
		if (
			Math.floor(this.props.user.totalCredits / this.props.call.price) < 1 ||
			!this.props.user.profile.phoneNumber
		) {
			this.handleRedirect();
		} else {
			callService
				.addToQueue(this.props.token, this.props.call.slug)
				.then((response) => {
					this.props.history.push({
						pathname: `/call/${this.props.call.slug}/live-call`,
						state: {
							price: this.props.call.price,
							queueCount: this.props.call.author.liveCallQueuesCount - 1
						}
					});
				})
				.catch((error) => {
					console.log(error.response);
					this.props.snackbarAdd({
						message: error.response.data.message,
						type: 'error',
						timeout: 10000
					});
				});
		}
	};

	handleAddToQueueVideo = () => {
		console.log()
		if (
			this.props.user.totalCredits / this.props.call.price < 2
		) {
			this.handleRedirect();
		} else {
			this.setState({loading: true})
			callService
			.addToQueueVideo(this.props.token, this.props.call.slug)
			.then((response) => {
				this.props.history.push({
					pathname: '/confirmation-message-video-queue',
					state: {
						price: this.props.call.price,
						queueCount: this.props.call.author.videoLiveCallQueuesCount
					}
				});
				
				this.setState({loading: false})
				
			})
			.catch((error) => {
				this.props.snackbarAdd({
					message: error.response.data.message,
					type: 'error',
					timeout: 10000
				});

				this.setState({loading: false})
			});
			//console.log(this.props.token, this.props.call.slug)
		}
	};


	render() {
		const { call, user} = this.props;
		const maxMinutes = () => Math.floor(user.totalCredits / call.price);
		const maxMinutesVideo = () => Math.floor((user.totalCredits / call.price) - 2);
		const maxMinutesVideoReal = () => Math.floor(user.totalCredits / call.price);
		const totalCredits = () => user.totalCredits  //Math.floor();
		const callPriceMultiple = () => Math.floor(call.price * 3);

		const loaderRender = (
			<div
			  style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: '1rem'
			  }}
			>
			  <CircularProgress />
			</div>
		  )
	
		const MinutesInfo = () =>
			maxMinutes() ? (
				<React.Fragment>
					the call can last no more than <span>{maxMinutes()}</span> minute{maxMinutes() !== 1 ? 's' : null}.
					For longer calls, please add more dollars to your account.
				</React.Fragment>
			) : (
				`the call cannot be started. You need to have dollars for at least one minute of conversation.
					Please add more dollars to your account.`
			);

			const MinutesInfoVideo = () =>
			totalCredits() >= callPriceMultiple() ? (
				<React.Fragment>
				the call can last a maximum of <span>{maxMinutesVideoReal()}</span> minutes.
				For longer calls, please add more dollars to your account.
				</React.Fragment>
			) : (
				<React.Fragment>
				the call cannot be started. You need to have at least <span>${callPriceMultiple()}</span>  to start the conversation.
				Please add more dollars to your account.
				</React.Fragment>
			);
			// <span>{maxMinutesVideo()}</span><span></span>{'-'}

		const RatingStars = (data) => (
			<div className={classes.StarsWrap}>
				<RateStars className={classes.Stars} rate={Number(data.averageRating || 0)} />
				<strong>{(data.averageRating * 1).toFixed(1) || 0}</strong> 
				{/* data.averageRating */}
				{`(${data.numberOfRating ? data.numberOfRating : 0} reviews)`}
			</div>
		);


		const ButtonCall = () => {
			//console.log(call.liveCallStatus);
			switch (call.liveCallStatus) {
				case 'available':
					return (
						<Button
							size="large"
							onClick={this.handleCall}
							loading={Number(this.state.loading)}>
							<ReactSVG className={classes.IconLiveCall} src={IconLiveCall} /> CONTINUE
							WITH THE CALL
						</Button>
					);
				case 'queued':
					return (
						<Button
							size="large"
							color="secondary"
							onClick={this.handleAddToQueue}
							loading={Number(this.state.loading)}>
							<ReactSVG className={classes.IconLiveCall} src={IconHourGlass} />CLICK TO
							QUEUE{call.author.liveCallQueuesCount > 1 ? (
								` (${call.author.liveCallQueuesCount - 1})`
							) : null}
						</Button>
					);
				default:
					return (
						<Button
							color="primary"
							disabled="true"
							size="large"
							loading={Number(this.state.loading)}>
							<ReactSVG className={classes.IconLiveCall} src={IconLiveCall} />CURRENTLY
							OFFLINE
						</Button>
					);
			}
		};

		const ButtonCallVideo = () => {
			//console.log('button', this.state);
			switch (call.videoCallStatus || call && call.videoLiveCallStatus) {
				case 'available':
					return (
						<Button
							size="large"
							onClick={this.handleCallVideo}
							loading={Number(this.state.loading)}>
							<ReactSVG className={classes.IconLiveCall} src={IconVideoBasic} /> CONTINUE
							WITH THE CALL
						</Button>
					);
				case 'queued':
					return (
						<Button
							size="large"
							color="secondary"
							onClick={this.handleAddToQueueVideo}
							loading={Number(this.state.loading)}>
							<ReactSVG className={classes.IconLiveCall} src={IconHourGlass} />CLICK TO
							QUEUE{call.author.liveCallQueuesCount > 1 ? (
								` (${call.author.videoLiveCallQueuesCount - 1})`
							) : null}
						</Button>
					);
				default:
					return (
						<Button
							color="primary"
							disabled="true"
							size="large"
							loading={Number(this.state.loading)}>
							<ReactSVG className={classes.IconLiveCall} src={IconVideoSlash} />CURRENTLY
							OFFLINE
						</Button>
					);
			}
		};

		const checkManyVideo = () =>
		totalCredits() >= callPriceMultiple() &&  this.props.type == 'video' || totalCredits() >= callPriceMultiple() && this.props.type == 'video' ? (
				ButtonCallVideo()
			) : ('')


			const checkManyPhone = () =>
			maxMinutes() > 1 &&  this.props.type == 'phone' || maxMinutes() > 1  && this.props.type == 'phone' ? (
				ButtonCall()
			) : ('')

		const ModalLiveCall = () => <div>
		<h2 className={classes.ModalTitle}>Live Tutoring Call</h2>
			<div className={classes.ModalContent}>
				<div className={classes.ModalColumns}>
					<div className={classes.ImageWrapper}>
						<div
							className={classes.CoverImage}
							style={{
								backgroundImage:
									'url(' +
									(call.cover ? apiConfig.DOMAIN_IMAGE + call.cover.big : '') +
									')'
							}}
						/>
					</div>
				</div>
				<div className={classes.ModalColumns}>
					<h2>{call.name}</h2>
					{/* <p className={classes.ModalVideoAuthor}>
						{`${call.author.profile.firstName} ${call.author.profile.lastName}`}
					</p> */}
					<p className={classes.ModalVideoAuthor}>
						{call.author && call.author.displayName && <ChosenDisplayName user={call.author} />}
						{call.author && call.author.profile && call.author.profile.displayName && <ChosenDisplayName user={call.author.profile} />}
					</p>
					{RatingStars(call)}
					<h3 className={classes.ModalPrice}>${Number(call.price).toFixed(2)}/min</h3>

					{/* queue number */}
					{call.liveCallStatus === 'queued' && (
						<div className={classes.QueueCount}>
							<strong>
								Users in a queue:{' '}
								<span className={classes.ModalPrice}>
									{call.author.liveCallQueuesCount - 1}
								</span>
							</strong>
						</div>
					)}
				</div>
			</div>

			{/* available minutes info*/}
			<p className={classes.PriceInfo}>
				You have <span>${totalCredits()}</span> on your balance,{' '}
				{this.props.type == 'video' ? MinutesInfoVideo() : MinutesInfo()}
			</p>

			<div className={classes.CTAWrap}>
				{/* {this.props.type == 'video' ? ButtonCallVideo() : ButtonCall()} */}
				{checkManyVideo()}
				{checkManyPhone()}
				{/*  &&   this.props.type == 'video' && */}
				<Button fullWidth={true} size="large" onClick={(e) => this.handleTopUp()}>
					<ReactSVG className={classes.IconLiveCall} src={IconCoins} />
					TOP UP YOUR ACCOUNT
				</Button>
			</div>
		</div>

		const ModalBrowserInfo = () => <div className={classes.ModalBrowserInfo}>
			<h1>Unsupported browser</h1>
			<p>Your current browser doesn't support our video conversations yet. 
				Don't worry, there is an easy fix. Just use one of the following supported browsers to get on the call:</p>
				
				{this.state.osDetected == 'Linux' ? 
					<div className={classes.BrowsersWrapp}>
						{/* <div className={classes.BrowserItem}><a href=""><ReactSVG  src={IconSafari} />Safari</a></div> */}
						<div className={classes.BrowserItem}><a href="https://www.google.com/chrome/"><ReactSVG  src={IconChrome} />Chrome</a></div>
						<div className={classes.BrowserItem}><a href="https://www.mozilla.org/en-US/firefox/browsers/"><ReactSVG  src={IconFirefox} />Firefox</a></div>
					</div>
				: this.state.osDetected == 'Windows' ? 
					<div className={classes.BrowsersWrapp}>
						<div className={classes.BrowserItem}><a href="https://www.google.com/chrome/"><ReactSVG  src={IconChrome} />Chrome</a></div>
						<div className={classes.BrowserItem}><a href="https://www.mozilla.org/en-US/firefox/browsers/"><ReactSVG  src={IconFirefox} />Firefox</a></div>
						<div className={classes.BrowserItem}><a href="https://www.microsoft.com/en-us/edge"><ReactSVG  src={IconEdge} />Edge</a></div>
					</div>
				: this.state.osDetected == 'MacOS' ?
					<div className={classes.BrowsersWrapp}>
						<div className={classes.BrowserItem}><a href="https://www.google.com/chrome/"><ReactSVG  src={IconChrome} />Chrome</a></div>
						<div className={classes.BrowserItem}><a href="https://www.mozilla.org/en-US/firefox/browsers/"><ReactSVG  src={IconFirefox} />Firefox</a></div>
						<div className={classes.BrowserItem}><a href="https://support.apple.com/downloads/safari"><ReactSVG  src={IconSafari} />Safari</a></div>
					</div> 
				: this.state.osDetected == 'iOS' ? 
					<div className={classes.BrowsersWrapp}>
						<div className={classes.BrowserItem}><a href="https://support.apple.com/downloads/safari"><ReactSVG  src={IconSafari} />Safari</a></div>
					</div>
				: this.state.osDetected == 'Android' ? 
				<div className={classes.BrowsersWrapp}>
					<div className={classes.BrowserItem}><a href="https://www.google.com/chrome/"><ReactSVG  src={IconChrome} />Chrome</a></div>
					<div className={classes.BrowserItem}><a href="https://www.mozilla.org/en-US/firefox/browsers/"><ReactSVG  src={IconFirefox} />Firefox</a></div>
				</div>
				: ''
				}
		</div>
		
		return (
			(call && (
				this.state.modalVideoCall == false ?
				<Modal
					className={classes.Card}
					open={this.props.open}
					onClose={() => this.props.onClose()}>
					{/* Chckif video call supported */}
					{!isSupported
					?
					this.props.type == 'video' ?
					ModalBrowserInfo()
					: ModalLiveCall()
					:
					ModalLiveCall() }
				</Modal> :
				<Modal
					className={[classes.Card, classes.ModalVideoCall].join(' ')}
					open={this.props.open}>
						<div className={classes.LeftShape}>
							<img src={LeftShape} alt="ShapeLeft" />
						</div>
						<div className={classes.RightShape}>
							<img src={RightShape} alt="ShapeLeft" />
						</div>
						<div className={classes.LeftMiddleShape}>
							<img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
						</div>
						<div className={classes.RightMiddleShape}>
							<img src={RightMiddleShape} alt="ShapeRightMiddle" />
						</div>
						<Avatar
							src={
								call.author.profile && call.author.profile.photo ? (
									call.author.profile.photo.big
								) : null
							}
							className={classes.Avatar}
							size="140"
						/>
						{/* name */}
						<h3 className={classes.VideoCallName}>{call.name}</h3>
						{/* <h4 className={classes.AuthorName}><span>{call.author.profile.firstName}</span>{' '}<span>{call.author.profile.lastName}</span></h4> */}
						{call.author && call.author.displayName && <h4 className={classes.AuthorName}><ChosenDisplayName user={call.author} /></h4>}
						{call.author && call.author.profile && call.author.profile.displayName && <h4 className={classes.AuthorName}><ChosenDisplayName user={call.author.profile} /></h4>}
						<span className={classes.GreySpan}>Calling...</span>
						{this.state.loading !== true ? <audio id="ringSoundPlayer" autoPlay loop><source src={SoundUrl}/></audio> : ''}
						{this.state.loading == false ? 
							<div className={classes.ButtonAction}>
								<a 
								onClick={this.deleteVideoCallModal} 
								className={classes.CancelVideoCall}
								>
								<ReactSVG src={CloseIcon} />
								</a>
								<span>Cancel</span>
							</div>
						: loaderRender}

				</Modal>
				)
			)
		);
		
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyVideoModal));
