import React, { Component } from 'react';
import clone from 'lodash.clonedeep';

import classes from '../AccountSettings.module.scss';
import * as validator from '../../../utils/formValidator';
import Button from '../../../components/UI/Button/Button';
import Grid from '@material-ui/core/Grid';
import { Form, Input, Checkbox } from '../../../components/UI/Form/Form';
import TextField from '@material-ui/core/TextField';
import * as userService from '../../../services/user/userService';
import { errorParser } from '../../../utils/errorParser';
import { withStyles } from '@material-ui/core/styles';
import {
  addProtocolPrefixUrl,
  addHttpsProtocolPrefixUrl,
  prepareTeacherExpertiseSubmit,
  prepareTeacherExpertiseBasedOnSubmit,
} from '../../../utils/common';
import {
  DISPLAY_NAME_TYPE_BOTH,
  DISPLAY_NAME_TYPE_ONLY_FULLNAME,
  DISPLAY_NAME_TYPE_ONLY_USERNAME,
} from '../../../components/UI/ChosenDisplayName/ChosenDisplayName';

const styles = {
  shrinked: {
    transform: 'translate(0, -10px) scale(1) !important',
    fontWeight: '600',
    color: '#272727',
  },
};

const optionNames = [
  {
    id: 1,
    name: 'Full name and Username',
    value: DISPLAY_NAME_TYPE_BOTH,
  },
  {
    id: 2,
    name: 'Full name only',
    value: DISPLAY_NAME_TYPE_ONLY_FULLNAME,
  },
  {
    id: 3,
    name: 'Username only',
    value: DISPLAY_NAME_TYPE_ONLY_USERNAME,
  },
];

class PersonalSettings extends Component {
  state = (() => {
    const user = this.props.user;
    const selectedAreasOfExpertise = [];
    const selectedBasedOn = [];
    let certifications = [];
    let schools = [];
    if (user.type === 'teacher') {
      user.areaOfExpertises.forEach((areaOfExpertise) => {
        selectedAreasOfExpertise.push(areaOfExpertise.slug);
      });
      user.expertisesBasedOn.forEach((basedOn) => {
        selectedBasedOn.push(basedOn.slug);
      });
      certifications = [...user.certifications, { name: '', institution: '' }];
      schools = [...user.schools, { name: '', degree: '' }];
    }

    const createDisplayNameSetting = (displayName) => {
      const displayNameObj = JSON.parse(displayName);
      if (
        !displayNameObj ||
        (displayNameObj.name !== '' && displayNameObj.username !== '')
      ) {
        return DISPLAY_NAME_TYPE_BOTH;
      } else if (displayNameObj.name !== '') {
        return DISPLAY_NAME_TYPE_ONLY_FULLNAME;
      } else {
        return DISPLAY_NAME_TYPE_ONLY_USERNAME;
      }
    };

    return {
      dynamicCertificatesFieldsValid: true,
      dynamicSchoolsFieldsValid: true,
      certificatesFormValues: certifications,
      schoolsFormValues: schools,
      selectedExpertiseCounter: selectedAreasOfExpertise.length,
      selectedExpertiseBasedOnCounter: selectedBasedOn.length,
      displayNameSetting: createDisplayNameSetting(user.displayName),
      loading: false,
      formIsValid: false,
      formControls: {
        firstName: {
          id: 'firstName',
          type: 'text',
          placeholder: 'First name',
          value: user.profile.firstName,
          validation: {
            required: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        lastName: {
          id: 'lastName',
          type: 'text',
          placeholder: 'Last name',
          value: user.profile.lastName,
          validation: {
            required: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        username: {
          id: 'username',
          type: 'text',
          placeholder: 'Username',
          value: user.username,
          validation: {
            required: true,
            minLength: 4,
          },
          error: '',
          valid: false,
          touched: true,
        },
        aboutMe: {
          id: 'aboutMe',
          type: 'textarea',
          charCounter: 250,
          placeholder: 'Short intro about you',
          infolabel: 'It will be displayed below your name on listings pages',
          value: user.profile.introAboutMe,
          validation: {
            required: true,
            maxLength: 250,
          },
          error: '',
          valid: false,
          touched: true,
        },
        expertiseArtsAndDesign: {
          id: 'expertiseArtsAndDesign',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('arts-design')
            ? 'expertiseArtsAndDesign'
            : '',
          error: '',
          touched: true,
        },
        expertiseBusiness: {
          id: 'expertiseBusiness',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('business')
            ? 'expertiseBusiness'
            : '',
          error: '',
          touched: true,
        },
        expertiseTechnology: {
          id: 'expertiseTechnology',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('technology')
            ? 'expertiseTechnology'
            : '',
          error: '',
          touched: true,
        },
        expertiseLifeStyle: {
          id: 'expertiseLifeStyle',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('lifestyle')
            ? 'expertiseLifeStyle'
            : '',
          error: '',
          touched: true,
        },
        expertisePersonalDevelopment: {
          id: 'expertisePersonalDevelopment',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('personal-development')
            ? 'expertisePersonalDevelopment'
            : '',
          error: '',
          touched: true,
        },
        expertiseHealthFitness: {
          id: 'expertiseHealthFitness',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('health-fitness')
            ? 'expertiseHealthFitness'
            : '',
          error: '',
          touched: true,
        },
        expertiseMusic: {
          id: 'expertiseMusic',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('music')
            ? 'expertiseMusic'
            : '',
          error: '',
          touched: true,
        },
        expertiseLanguage: {
          id: 'expertiseLanguage',
          expertise: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedAreasOfExpertise.includes('languages')
            ? 'expertiseLanguage'
            : '',
          error: '',
          touched: true,
        },
        basedOnEducation: {
          id: 'basedOnEducation',
          basedOn: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedBasedOn.includes('education')
            ? 'basedOnEducation'
            : '',
          error: '',
          touched: true,
        },
        basedOnLifeExperience: {
          id: 'basedOnLifeExperience',
          basedOn: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedBasedOn.includes('life-experience')
            ? 'basedOnLifeExperience'
            : '',
          error: '',
          touched: true,
        },
        basedOnTechnicalTraining: {
          id: 'basedOnTechnicalTraining',
          basedOn: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedBasedOn.includes('technical-training')
            ? 'basedOnTechnicalTraining'
            : '',
          error: '',
          touched: true,
        },
        basedOnOther: {
          id: 'basedOnOther',
          basedOn: true,
          type: 'checkbox',
          checked: 'false',
          value: selectedBasedOn.includes('other-i-e-mom-said-iam-special')
            ? 'basedOnOther'
            : '',
          error: '',
          touched: true,
        },
        webSiteUrl: {
          id: 'webSiteUrl',
          type: 'text',
          placeholder: 'Website',
          value: user.profile.webSiteUrl,
          error: '',
          valid: false,
          validation: {
            required: false,
            isUrl: true,
          },
          touched: true,
        },
        facebook: {
          id: 'facebook',
          type: 'text',
          placeholder: 'Facebook',
          value: user.socialMediaLink ? user.socialMediaLink.facebook : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        instagram: {
          id: 'instagram',
          type: 'text',
          placeholder: 'Instagram',
          value: user.socialMediaLink ? user.socialMediaLink.instagram : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        twitter: {
          id: 'twitter',
          type: 'text',
          placeholder: 'Twitter',
          value: user.socialMediaLink ? user.socialMediaLink.twitter : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        linkedin: {
          id: 'linkedin',
          type: 'text',
          placeholder: 'LinkedIn',
          value: user.socialMediaLink ? user.socialMediaLink.linkedin : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        pinterest: {
          id: 'pinterest',
          type: 'text',
          placeholder: 'Pinterest',
          value: user.socialMediaLink ? user.socialMediaLink.pinterest : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        youtube: {
          id: 'youtube',
          type: 'text',
          placeholder: 'YouTube',
          value: user.socialMediaLink ? user.socialMediaLink.youtube : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
        tiktok: {
          id: 'tiktok',
          type: 'text',
          placeholder: 'TikTok',
          value: user.socialMediaLink ? user.socialMediaLink.tiktok : '',
          validation: {
            required: false,
            isUrl: true,
          },
          error: '',
          valid: false,
          touched: true,
        },
      },
    };
  })();

  // clears the errors and 'dirty' the input on change. Errors will be checked on blur
  clearInput = (inpt) => {
    inpt.touched = true;
    inpt.error = '';

    // if input is part of the group clear all group memebers
    if (inpt.group) {
      inpt.groupError = false;
    }
  };

  inputChangeHandler = (e, controlName, id) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];
    if (ctrl.type !== 'checkbox') {
      ctrl.value = e.target.value;
    } else {
      ctrl.value = ctrl.value === controlName ? '' : controlName;
      if (ctrl.expertise === true) {
        if (ctrl.value) {
          this.setState((prevState) => ({
            selectedExpertiseCounter: prevState.selectedExpertiseCounter + 1,
          }));
        } else {
          this.setState((prevState) => ({
            selectedExpertiseCounter: prevState.selectedExpertiseCounter - 1,
          }));
        }
      } else if (ctrl.basedOn === true) {
        if (ctrl.value) {
          this.setState((prevState) => ({
            selectedExpertiseBasedOnCounter:
              prevState.selectedExpertiseBasedOnCounter + 1,
          }));
        } else {
          this.setState((prevState) => ({
            selectedExpertiseBasedOnCounter:
              prevState.selectedExpertiseBasedOnCounter - 1,
          }));
        }
      }
    }

    // if input is part of the group highlight all the memeber of a group in case of an error
    if (ctrl.group) {
      ctrl.group.forEach((inputName) => {
        this.clearInput(formControlsCopy[inputName]);
      });
    } else {
      this.clearInput(ctrl);
    }

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  // checks validity of single input
  getInputError = (input) => {
    return validator.validateInput(input);
  };

  inputBlurHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    // if it is a group show error only underneath the first input
    if (ctrl.group) {
      const errorMsg = this.getInputError(ctrl);
      if (errorMsg) {
        // show the msg on first input
        formControlsCopy[ctrl.group[0]].error = errorMsg;
        // set error on all group fields
        ctrl.group.forEach((inputName) => {
          formControlsCopy[inputName].groupError = true;
        });
      }
    } else {
      ctrl.error = this.getInputError(ctrl);
    }
    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  handleChangeDynamicInputs(i, e, type) {
    let dynamicFieldsValid = true;
    const formControlsCopy = clone(this.state.formControls);
    if (type === 'certificates') {
      let certificatesFormValues = this.state.certificatesFormValues;
      certificatesFormValues[i][e.target.name] = e.target.value;
      certificatesFormValues.forEach((element) => {
        if (
          (element.institution && !element.name) ||
          (!element.institution && element.name)
        ) {
          dynamicFieldsValid = false;
        }
      });
      this.setState({
        certificatesFormValues,
        dynamicCertificatesFieldsValid: dynamicFieldsValid,
      });
    } else if (type === 'schools') {
      let schoolsFormValues = this.state.schoolsFormValues;
      schoolsFormValues[i][e.target.name] = e.target.value;
      schoolsFormValues.forEach((element) => {
        if (
          (element.degree && !element.name) ||
          (!element.degree && element.name)
        ) {
          dynamicFieldsValid = false;
        }
      });
      this.setState({
        schoolsFormValues,
        dynamicSchoolsFieldsValid: dynamicFieldsValid,
      });
    }
    this.setState({
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  }

  addFormDynamicFields(e, type) {
    e.preventDefault();
    if (type === 'certificates') {
      this.setState({
        certificatesFormValues: [
          ...this.state.certificatesFormValues,
          { name: '', institution: '' },
        ],
      });
    } else if (type === 'schools') {
      this.setState({
        schoolsFormValues: [
          ...this.state.schoolsFormValues,
          { name: '', degree: '' },
        ],
      });
    }
  }

  removeFormDynamicFields(e, i, type) {
    e.preventDefault();
    if (type === 'certificates') {
      let certificatesFormValues = this.state.certificatesFormValues;
      certificatesFormValues.splice(i, 1);
      const formControlsCopy = clone(this.state.formControls);
      this.setState({
        formIsValid: validator.formIsValid(formControlsCopy),
        certificatesFormValues,
      });
    } else if (type === 'schools') {
      let schoolsFormValues = this.state.schoolsFormValues;
      schoolsFormValues.splice(i, 1);
      const formControlsCopy = clone(this.state.formControls);
      this.setState({
        formIsValid: validator.formIsValid(formControlsCopy),
        schoolsFormValues,
      });
    }
  }

  onOptionChangeHandler = (selectedVal) => {
    const formControlsCopy = clone(this.state.formControls);
    this.setState({
      displayNameSetting: selectedVal,
      formIsValid: validator.formIsValid(formControlsCopy),
    });
  };

  prepareSubmitData() {
    const form = this.state.formControls;

    if (this.props.user.type === 'teacher') {
      const areasOfExpertise = prepareTeacherExpertiseSubmit(form, []);
      const basedOnExpertises = prepareTeacherExpertiseBasedOnSubmit(form, []);
      return {
        username: form.username.value.trim(),
        profile: {
          firstName: form.firstName.value && form.firstName.value.trim(),
          lastName: form.lastName.value && form.lastName.value.trim(),
          webSiteUrl: addProtocolPrefixUrl(
            form.webSiteUrl.value && form.webSiteUrl.value.trim()
          ),
          introAboutMe: form.aboutMe.value && form.aboutMe.value.trim(),
        },
        areaOfExpertises: areasOfExpertise,
        expertisesBasedOn: basedOnExpertises,
        socialMediaLink: {
          facebook: addHttpsProtocolPrefixUrl(
            form.facebook.value && form.facebook.value.trim()
          ),
          instagram: addHttpsProtocolPrefixUrl(
            form.instagram.value && form.instagram.value.trim()
          ),
          twitter: addHttpsProtocolPrefixUrl(
            form.twitter.value && form.twitter.value.trim()
          ),
          linkedin: addHttpsProtocolPrefixUrl(
            form.linkedin.value && form.linkedin.value.trim()
          ),
          pinterest: addHttpsProtocolPrefixUrl(
            form.pinterest.value && form.pinterest.value.trim()
          ),
          youtube: addHttpsProtocolPrefixUrl(
            form.youtube.value && form.youtube.value.trim()
          ),
          tiktok: addHttpsProtocolPrefixUrl(
            form.tiktok.value && form.tiktok.value.trim()
          ),
        },
        certifications: this.state.certificatesFormValues.filter((item) => {
          return item.name && item.institution;
        }),
        schools: this.state.schoolsFormValues.filter((item) => {
          return item.name && item.degree;
        }),
        displayNameSetting: this.state.displayNameSetting,
      };
    } else {
      return {
        username: form.username.value.trim(),
        profile: {
          firstName: form.firstName.value && form.firstName.value.trim(),
          lastName: form.lastName.value && form.lastName.value.trim(),
          introAboutMe: form.aboutMe.value && form.aboutMe.value.trim(),
        },
        displayNameSetting: this.state.displayNameSetting,
      };
    }
  }

  submit(e) {
    e.preventDefault();
    // if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
    if (this.state.formIsValid) {
      this.setState({ loading: true });
      userService
        .updatePersonalInfo(
          this.prepareSubmitData(),
          this.props.token,
          this.props.user.type
        )
        .then((response) => {
          console.log(response);
          // update the store in the main component
          this.props.updateStore(response.data);
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          const apiErr = errorParser(error.response.data.errors);
          console.log(apiErr);

          const cloneForm = clone(this.state.formControls);

          // config object to be used by checkFormApiErrors
          const errorStateConfig = {
            formProfileFirstNameError: 'firstName',
            formProfileLastNameError: 'lastName',
            formProfileIntroAboutMeError: 'aboutMe',
            formUsernameError: 'username',
            formProfileWebSiteUrlError: 'webSiteUrl',
            formSocialMediaLinkFacebookError: 'facebook',
            formSocialMediaLinkLinkedinError: 'linkedin',
            formSocialMediaLinkInstagramError: 'instagram',
            formSocialMediaLinkTwitterError: 'twitter',
            formSocialMediaLinkPinterestError: 'pinterest',
            formSocialMediaLinkYoutubeError: 'youtube',
            formSocialMediaLinkTiktokError: 'tiktok',
          };

          // checking form errors for matching pairs defined in the errorStateConfig
          validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

          this.setState({
            formIsValid: false,
            formControls: cloneForm,
            loading: false,
          });
        });
    }
  }

  render() {
    const teacherInfoRender = () =>
      this.props.user.type === 'teacher' ? (
        <React.Fragment>
          <h3 className={classes.Subheaders}>Introduction and Fun Facts</h3>
          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.aboutMe}
            onChange={(e) => this.inputChangeHandler(e, 'aboutMe')}
            onBlur={(e) => this.inputBlurHandler(e, 'aboutMe')}
          />

          <div style={{ width: '100%', marginTop: '30px' }}>
            <h5
              style={{
                marginBottom: '0px',
                fontSize: '1rem',
                fontWeight: '600',
              }}
            >
              Area of expertise{' '}
            </h5>
          </div>
          <div>
            <p
              style={{
                color: '#a5a5a5',
                fontSize: '11.25px',
                marginBottom: '10px',
              }}
            >
              {' '}
              You can select more than one{' '}
            </p>
          </div>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseArtsAndDesign}
                  value={'expertiseArtsAndDesign'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertiseArtsAndDesign')
                  }
                  onBlur={(e) =>
                    this.inputBlurHandler(e, 'expertiseArtsAndDesign')
                  }
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Arts & Design
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseBusiness}
                  value={'expertiseBusiness'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertiseBusiness')
                  }
                  onBlur={(e) => this.inputBlurHandler(e, 'expertiseBusiness')}
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Business
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseTechnology}
                  value={'expertiseTechnology'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertiseTechnology')
                  }
                  onBlur={(e) =>
                    this.inputBlurHandler(e, 'expertiseTechnology')
                  }
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Technology
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseLifeStyle}
                  value={'expertiseLifeStyle'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertiseLifeStyle')
                  }
                  onBlur={(e) => this.inputBlurHandler(e, 'expertiseLifeStyle')}
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Lifestyle
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <Checkbox
                  config={this.state.formControls.expertisePersonalDevelopment}
                  value={'expertisePersonalDevelopment'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertisePersonalDevelopment')
                  }
                  onBlur={(e) =>
                    this.inputBlurHandler(e, 'expertisePersonalDevelopment')
                  }
                />
                <span
                  style={{
                    marginLeft: '-1rem',
                    fontSize: '15px',
                    marginRight: '-22px',
                    display: 'inline-block',
                  }}
                >
                  Personal Development
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseHealthFitness}
                  value={'expertiseHealthFitness'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertiseHealthFitness')
                  }
                  onBlur={(e) =>
                    this.inputBlurHandler(e, 'expertiseHealthFitness')
                  }
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Health & Fitness
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseMusic}
                  value={'expertiseMusic'}
                  onChange={(e) => this.inputChangeHandler(e, 'expertiseMusic')}
                  onBlur={(e) => this.inputBlurHandler(e, 'expertiseMusic')}
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Music
                </span>
              </div>
              <div>
                <Checkbox
                  config={this.state.formControls.expertiseLanguage}
                  value={'expertiseLanguage'}
                  onChange={(e) =>
                    this.inputChangeHandler(e, 'expertiseLanguage')
                  }
                  onBlur={(e) => this.inputBlurHandler(e, 'expertiseLanguage')}
                />
                <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                  Language
                </span>
              </div>
            </Grid>
          </Grid>
          <div style={{ width: '100%', marginTop: '50px' }}>
            <h5
              style={{
                marginBottom: '0px',
                fontSize: '1rem',
                fontWeight: '600',
              }}
            >
              Your expertise is based on{' '}
            </h5>
          </div>
          <div style={{ width: '100%' }}>
            <p
              style={{
                color: '#a5a5a5',
                fontSize: '11.25px',
                marginBottom: '10px',
              }}
            >
              {' '}
              You can select more than one{' '}
            </p>
          </div>
          <div>
            <div>
              <Checkbox
                config={this.state.formControls.basedOnEducation}
                value={'basedOnEducation'}
                onChange={(e) => this.inputChangeHandler(e, 'basedOnEducation')}
                onBlur={(e) => this.inputBlurHandler(e, 'basedOnEducation')}
              />
              <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                Education
              </span>
            </div>
            <div>
              <Checkbox
                config={this.state.formControls.basedOnLifeExperience}
                value={'basedOnLifeExperience'}
                onChange={(e) =>
                  this.inputChangeHandler(e, 'basedOnLifeExperience')
                }
                onBlur={(e) =>
                  this.inputBlurHandler(e, 'basedOnLifeExperience')
                }
              />
              <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                Life Experience
              </span>
            </div>
            <div>
              <Checkbox
                config={this.state.formControls.basedOnTechnicalTraining}
                value={'basedOnTechnicalTraining'}
                onChange={(e) =>
                  this.inputChangeHandler(e, 'basedOnTechnicalTraining')
                }
                onBlur={(e) =>
                  this.inputBlurHandler(e, 'basedOnTechnicalTraining')
                }
              />
              <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                Technical Training
              </span>
            </div>
            <div>
              <Checkbox
                config={this.state.formControls.basedOnOther}
                value={'basedOnOther'}
                onChange={(e) => this.inputChangeHandler(e, 'basedOnOther')}
                onBlur={(e) => this.inputBlurHandler(e, 'basedOnOther')}
              />
              <span style={{ marginLeft: '-1rem', fontSize: '15px' }}>
                Other (i.e. Mom said I'm special)
              </span>
            </div>
          </div>
          <h3
            style={{ marginTop: '40px', marginBottom: '20px' }}
            className={classes.Subheaders}
          >
            Schools
          </h3>
          <div style={{ width: '100%', display: 'block' }}>
            {this.state.schoolsFormValues.map((element, index) => (
              <div
                key={index}
                style={{
                  padding: '20px',
                  paddingTop: '30px',
                  backgroundColor: '#f1f4f7',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    id="outlined-bare"
                    className={classes.textField}
                    value={element.name || ''}
                    label="School name"
                    placeholder="Enter school name"
                    name="name"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      this.handleChangeDynamicInputs(index, e, 'schools')
                    }
                    touched="true"
                    InputProps={{
                      labelWidth: 0,
                      margin: 'dense',

                      style: {
                        paddingTop: '4px',
                        paddingBottom: '0px',
                        backgroundColor: '#fff',
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        shrink: classes.shrinked,
                        outlined: classes.outlined,
                      },

                      style: {
                        whiteSpace: 'normal',
                        top: '-0.78rem',
                        color: '#272727',
                      },
                      floatingLabelFocusStyle: {
                        color: 'blue',
                      },
                      shrink: {
                        color: 'red',
                      },
                    }}
                  />
                </div>
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  value={element.degree || ''}
                  label="Degree"
                  placeholder="Enter degree"
                  name="degree"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    this.handleChangeDynamicInputs(index, e, 'schools')
                  }
                  touched="true"
                  InputProps={{
                    labelWidth: 0,
                    margin: 'dense',

                    style: {
                      paddingTop: '4px',
                      paddingBottom: '0px',
                      backgroundColor: '#fff',
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      shrink: classes.shrinked,
                      outlined: classes.outlined,
                    },
                    floatingLabel: {
                      color: 'blue',
                      pointerEvents: 'none',
                    },

                    style: {
                      whiteSpace: 'normal',
                      top: '-0.78rem',
                      color: '#272727',
                    },
                    floatingLabelFocusStyle: {
                      color: 'blue',
                    },
                    shrink: {
                      color: 'red',
                    },
                  }}
                />

                {this.state.schoolsFormValues.length > 1 ? (
                  <a
                    style={{ fontSize: '13px' }}
                    href="#"
                    onClick={(e) =>
                      this.removeFormDynamicFields(e, index, 'schools')
                    }
                  >
                    Remove
                  </a>
                ) : null}
              </div>
            ))}
            <div style={{ marginTop: '10px' }}>
              <a
                href="#"
                onClick={(e) => this.addFormDynamicFields(e, 'schools')}
                style={{ fontSize: '13px' }}
              >
                Add another
              </a>
            </div>
          </div>
          <h3
            style={{ marginTop: '40px', marginBottom: '20px' }}
            className={classes.Subheaders}
          >
            Certifications & Awards
          </h3>
          <div
            style={{ width: '100%', display: 'block', marginBottom: '30px' }}
          >
            {this.state.certificatesFormValues.map((element, index) => (
              <div
                key={index}
                style={{
                  padding: '20px',
                  paddingTop: '30px',
                  backgroundColor: '#f1f4f7',
                  width: '100%',
                  marginBottom: '10px',
                }}
              >
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    id="outlined-bare"
                    className={classes.textField}
                    value={element.name || ''}
                    label="Certification/Award name"
                    placeholder="Enter certification/award name"
                    name="name"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      this.handleChangeDynamicInputs(index, e, 'certificates')
                    }
                    touched="true"
                    InputProps={{
                      labelWidth: 0,
                      margin: 'dense',

                      style: {
                        paddingTop: '4px',
                        paddingBottom: '0px',
                        backgroundColor: '#fff',
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        shrink: classes.shrinked,
                        outlined: classes.outlined,
                      },

                      style: {
                        whiteSpace: 'normal',
                        top: '-0.78rem',
                        color: '#272727',
                      },
                      floatingLabelFocusStyle: {
                        color: 'blue',
                      },
                      shrink: {
                        color: 'red',
                      },
                    }}
                  />
                </div>

                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  value={element.institution || ''}
                  label="Issuing institution"
                  placeholder="Enter issuing institution"
                  name="institution"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    this.handleChangeDynamicInputs(index, e, 'certificates')
                  }
                  touched="true"
                  InputProps={{
                    labelWidth: 0,
                    margin: 'dense',

                    style: {
                      paddingTop: '4px',
                      paddingBottom: '0px',
                      backgroundColor: '#fff',
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      shrink: classes.shrinked,
                      outlined: classes.outlined,
                    },

                    style: {
                      whiteSpace: 'normal',
                      top: '-0.78rem',
                      color: '#272727',
                    },
                    floatingLabelFocusStyle: {
                      color: 'blue',
                    },
                    shrink: {
                      color: 'red',
                    },
                  }}
                />
                {this.state.certificatesFormValues.length > 1 ? (
                  <a
                    href="#"
                    style={{ fontSize: '13px' }}
                    onClick={(e) =>
                      this.removeFormDynamicFields(e, index, 'certificates')
                    }
                  >
                    Remove
                  </a>
                ) : null}
              </div>
            ))}
            <div style={{ marginTop: '10px' }}>
              <a
                href="#"
                style={{ fontSize: '13px' }}
                onClick={(e) => this.addFormDynamicFields(e, 'certificates')}
              >
                Add another
              </a>
            </div>
          </div>

          <h3
            style={{ marginTop: '40px', marginBottom: '20px' }}
            className={classes.Subheaders}
          >
            Links to other profiles
          </h3>
          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.webSiteUrl}
            onChange={(e) => this.inputChangeHandler(e, 'webSiteUrl')}
            onBlur={(e) => this.inputBlurHandler(e, 'webSiteUrl')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.facebook}
            onChange={(e) => this.inputChangeHandler(e, 'facebook')}
            onBlur={(e) => this.inputBlurHandler(e, 'facebook')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.instagram}
            onChange={(e) => this.inputChangeHandler(e, 'instagram')}
            onBlur={(e) => this.inputBlurHandler(e, 'instagram')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.twitter}
            onChange={(e) => this.inputChangeHandler(e, 'twitter')}
            onBlur={(e) => this.inputBlurHandler(e, 'twitter')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.linkedin}
            onChange={(e) => this.inputChangeHandler(e, 'linkedin')}
            onBlur={(e) => this.inputBlurHandler(e, 'linkedin')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.pinterest}
            onChange={(e) => this.inputChangeHandler(e, 'pinterest')}
            onBlur={(e) => this.inputBlurHandler(e, 'pinterest')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.youtube}
            onChange={(e) => this.inputChangeHandler(e, 'youtube')}
            onBlur={(e) => this.inputBlurHandler(e, 'youtube')}
          />

          <Input
            style={{ marginTop: '20px !important' }}
            config={this.state.formControls.tiktok}
            onChange={(e) => this.inputChangeHandler(e, 'tiktok')}
            onBlur={(e) => this.inputBlurHandler(e, 'tiktok')}
          />
        </React.Fragment>
      ) : null;

    return (
      <Form onSubmit={(e) => this.submit(e)}>
        <h3 className={classes.Subheaders}>Basic Info</h3>
        <Input
          style={{ width: 'calc(50% - 6px)' }}
          config={this.state.formControls.firstName}
          onChange={(e) => this.inputChangeHandler(e, 'firstName')}
          onBlur={(e) => this.inputBlurHandler(e, 'firstName')}
        />
        <Input
          style={{ width: 'calc(50% - 6px)', marginLeft: 'auto' }}
          config={this.state.formControls.lastName}
          onChange={(e) => this.inputChangeHandler(e, 'lastName')}
          onBlur={(e) => this.inputBlurHandler(e, 'lastName')}
        />
        <Input
          config={this.state.formControls.username}
          onChange={(e) => this.inputChangeHandler(e, 'username')}
          onBlur={(e) => this.inputBlurHandler(e, 'username')}
        />

        <div style={{ width: '100%' }}>
          <h5
            style={{ marginBottom: '0px', fontSize: '1rem', fontWeight: '600' }}
          >
            Display name
          </h5>
        </div>
        <div style={{ width: '100%' }}>
          <p
            style={{
              color: '#a5a5a5',
              fontSize: '11.25px',
              marginBottom: '10px',
            }}
          >
            {' '}
            Choose how your name will be displayed across the website{' '}
          </p>
        </div>
        <ul className={classes.CustomselectOption}>
          {optionNames.map((optionName) => (
            <li key={optionName.id}>
              <a
                id={optionName.id}
                onClick={() =>
                  this.onOptionChangeHandler(optionName.value)
                }
                className={
                  this.state.displayNameSetting === optionName.value
                    ? classes.Active
                    : ''
                }
              >
                {optionName.name}
                <div className={classes.RadioOption}></div>
              </a>
            </li>
          ))}
        </ul>

        {// restricted for teachers only
        teacherInfoRender()}

        <Button
          fullWidth={true}
          size="large"
          type="submit"
          disabled={
            this.state.loading ||
            !this.state.formIsValid ||
            (this.props.user.type === 'teacher' &&
              (!this.state.selectedExpertiseCounter ||
                !this.state.selectedExpertiseBasedOnCounter ||
                !this.state.dynamicCertificatesFieldsValid ||
                !this.state.dynamicSchoolsFieldsValid))
          }
          loading={this.state.loading}
        >
          SAVE CHANGES
        </Button>
      </Form>
    );
  }
}
export default withStyles(styles)(PersonalSettings);
