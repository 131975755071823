import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../components/UI/Card/Card';
import classes from './../StaticPages.module.scss';

class PrivacyNotice extends Component {
  state = {  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //Meta tags
    let metatitle = document.getElementsByName('title')[0];
    let metaDecription = document.getElementsByName('description')[0];

    document.title = 'Privacy Policy | InfoDepot';
    metatitle.setAttribute('content', 'Privacy Policy | InfoDepot');
    metaDecription.setAttribute('content', 'Read more about the InfoDepot privacy policy and find out how InfoDepot collects, uses, protects and handles personal data.' )
  }

  render() {
    return (
      <div className={classes.Container}>
        <h1>InfoDepot Privacy Notice</h1>
        <Card className={classes.Card}>

          <span>Effective Date: February 1, 2019</span>
          <br />
          <span>Notice Version: 1.0</span>
          <br />
          <span>Data Controller Contact Information:</span>
          <br />
          <span>Sagacio LLC</span>
          <span>P.O. Box 230385</span>
          <span>New York, New York 10023</span>
          <span>Tel. (855) 585-0800</span>
          <span>Email: <a href="mailto:contact@infodepot.com?subject=Hello Infodepot">contact@infodepot.com</a></span>
          <br />

          <div className={classes.Section}>
            This document governs the privacy notice of our website InfoDepot.com. Our privacy notice tells you what personal data and nonpersonal data we may collect from you, how we collect them, how we protect them, how we share them, how you can access and change them, and how you can limit our sharing of them. Our privacy notice also explains certain legal rights that you have with respect to your personal data. Any capitalized terms not defined herein will have the same meaning as where they are defined elsewhere on our website.
          </div>

          <h2>Definitions</h2>

          <div className={classes.Section}>
            <p><strong>‘NONPERSONAL DATA’ (NPD)</strong> is information that is in no way personally identifiable.</p>
            <br/>

            <p><strong>‘PERSONAL DATA’ (PD)</strong> means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified directly or indirectly by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person. PD is in many ways the same as Personally Identifiable Information (PII). However, PD is broader in scope and covers more data. (GDPR) means General Data Protection Regulation.</p>
          </div>

          <h2>Table of Contents</h2>

          <div className={classes.Section}>
            <ul>
            <li>
              <p>
              Your Rights
              </p>
            </li>
            <li>
              <p>
              Information We Collect and How We Collect It 
              </p>
            </li>
            <li>
              <p>
              How Your Information Is Used and Shared
              </p>
            </li>
            <li>
              <p>
              Retaining and Destroying Your Pd 
              </p>
            </li>
            <li>
              <p>
              Updating Your Pd
              </p>
            </li>
            <li>
              <p>
              Revoking Your Consent for Using Your Pd
              </p>
            </li>
            <li>
              <p>
              Protecting the Privacy Rights of Third Parties
              </p>
            </li>
            <li>
              <p>
              Do Not Track Settings
              </p>
            </li>
            <li>
              <p>
              Links to Other Websites
              </p>
            </li>
            <li>
              <p>
              Protecting Children’s Privacy
              </p>
            </li>
            <li>
              <p>
              Our Email Policy
              </p>
            </li>
            <li>
              <p>
              Our Security Policy
              </p>
            </li>
            <li>
              <p>
              Use of Your Credit Card
              </p>
            </li>
            <li>
              <p>
              Transferring PD from the European Union
              </p>
            </li>
            <li>
              <p>
              Changes to Our Privacy Notice
              </p>
            </li>
          </ul>
          </div>

          <h2>Your Rights</h2>

          <div className={classes.Section}>
            <p>When using our services and submitting PD to us, you may have certain rights under the GDPR if you reside or are in any of the countries of the European Union. Depending on the legal basis for processing your PD you may have some or all of the following rights:</p>
            <br/>
            <ul>
              <li>
                <p>The Right to Be Informed - You have the right to be informed about the PD that we collect from you and how we process them. </p>
              </li>
              <li>
                <p>The Right of Access - You have the right to get confirmation that your PD are being processed and you have the ability to access your PD.</p>
              </li>
              <li>
                <p>The Right to Rectification - You have the right to have your PD corrected if they are inaccurate or incomplete.</p>
              </li>
              <li>
                <p>The Right to Erasure (Right to Be Forgotten) - You have the right to request the removal or deletion of your PD if there is no compelling reason for us to continue processing them.</p>
              </li>
              <li>
                <p>The Right to Restrict Processing - You have the right to ‘block’ or restrict the processing of your PD. When your PD are restricted, we are permitted to store your data, but not to process them further.</p>
              </li>
              <li>
                <p>The Right to Data Portability - You have the right to request your PD that you provided to us and use them for your own purposes. We will provide your data to you within 30 days of your request. Contact us using the information at the top of this privacy notice to request your PD.</p>
              </li>
              <li>
                <p>The Right to Object - You have the right to object to us processing your PD for the following reasons:</p>
                <ul>
                  <li>
                    <p>Processing was based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling)</p>
                  </li>
                  <li>
                    <p>Direct marketing (including profiling)</p>
                  </li>
                  <li>
                    <p>Processing for purposes of scientific/historical research and statistics</p>
                  </li>
                  <li>
                    <p>Rights in relation to automated decision-making and profiling.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>Automated Individual Decision-Making and Profiling - You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects regarding you or similarly significantly affects you. </p>
              </li>
              <li>
                <p>Filing a Complaint with Authorities - You have the right to file a complaint with supervisory authorities if your information has not been processed in compliance with the General Data Protection Regulation. If the supervisory authorities fail to address your complaint properly, you may have the right to a judicial remedy.</p>
              </li>
            </ul>
          </div>

          <h3>Your California Privacy Rights </h3>

          <div className={classes.Section}>
            <p>As a Californian consumer you have certain rights under the California Consumer Privacy Act (CaCPA) AB 375, some of these rights are:</p>

            <ul>
              <li>
                <p>the right of Californians to know what personal information is being collected about them </p>
              </li>
              <li>
                <p>the right of Californians to know whether their personal information is sold or disclosed and to whom</p>
              </li>
              <li>
                <p>the right of Californians to say no to the sale of their personal information</p>
              </li>
              <li>
                <p>the right of Californians to access their personal information</p>
              </li>
              <li>
                <p>the right of Californians of the deletion of their personal information</p>
              </li>
              <li>
                <p>the right of Californians of equal service, price, and not being discriminated against even if they exercise their privacy rights</p>
              </li>
              <li>
                <p>one or more designated means for Californian consumers to submit requests under the CACPA including (at minimum) a toll-free number, and if the business maintains an Internet website, a website address.</p>
              </li>
            </ul>

            <p>These rights include the right to request what personal information we collect and disclose about consumers. </p>
            <br/>
            <p>Personal information includes:</p>

            <ul>
              <li>categories of personal information that a business collected about the consumer</li>
              <li>categories of sources from which the personal information was collected</li>
              <li>specific pieces of personal information that the business has collected about consumers</li>
              <li>categories of third parties with whom the business shares personal information</li>
              <li>the business or commercial purpose of collecting or selling personal information</li>
            </ul>
          </div>

          <h2>Information We Collect and How We Collect It</h2>

          <div className={classes.Section}>
            Generally, you control the amount and type of information that you provide to us when using our website.
          </div>

          <h3>Our Legal Basis for Collecting and Processing PD</h3>
          
          <div className={classes.Section}>
            Our legal basis for collecting and processing your PD when you buy our products or services is based on and the necessity for the performance of a contract or to take steps to enter into a contract. Our legal basis for collecting and processing your PD when you sign up for our newsletter, download free information, access free audios, videos, and webinars through our website, including information you enter using any of our opt-in forms is based on consent.
          </div>
          
          <h3>Automatic Information</h3>

          <div className={classes.Section}>
            We automatically receive information from your web browser or mobile device. This information may include the name of the website from which you entered our website, if any, as well as the name of the website you’ll visit when you leave our website. This information may also include the IP address of your computer/the proxy server you use to access the Internet, your Internet service provider’s name, your web browser type, the type of mobile device, your computer operating system, and data about your browsing activity when using our website. We use all this information to analyze trends among our users to help improve our website.
          </div>

          <h3>When Entering and Using Our Website</h3>

          <div className={classes.Section}>
            When you enter and use our website and agree to accept cookies, some of these cookies may contain your PD.
          </div>

          <h3>Our Use of Cookies</h3>

          <div className={classes.Section}>
            Our website uses cookies. A cookie is a small piece of data or a text file that is downloaded to your computer or mobile device when you access certain websites. Cookies may contain text that can be read by the web server that delivered the cookie to you. The text contained in the cookie generally consists of a sequence of letters and numbers that uniquely identifies your computer or mobile device; it may contain other information as well. 
            <br/>
            <br/>
            <p>By agreeing to accept our use of cookies you are giving us and the third parties with which we partner permission to place, store, and access some or all the cookies described below on your computer.</p>

            <ul>
              <li>
                <p>
                Strictly Necessary Cookies - These cookies are necessary for proper functioning of the website, such as displaying content, logging in, validating your session, responding to your request for services, and other functions. Most web browsers can be set to disable the use of cookies. If you disable these cookies, you may not be able to access features on our website correctly or at all.
                </p>
              </li>
              <li>
                <p>Performance Cookies - These cookies collect information about the use of the website, such as pages visited, traffic sources, users’ interests, content management, and other website measurements.</p>
              </li>
              <li>
                <p>Functional Cookies - These cookies enable the website to remember users’ choices, such as their language, usernames, and other choices while using the website. They can also be used to deliver services, such as letting a user create a blog post, listen to audios, or watch videos on the website.</p>
              </li>
              <li>
                <p>Media Cookies - These cookies can be used to improve a website’s performance and provide special features and content. They can be placed by us or third parties who provide services to us.</p>
              </li>
              <li>
                <p>Advertising or Targeting Cookies - These cookies are usually placed and used by advertising companies to develop a profile of your browsing interests and serve advertisements on other websites that are related to your interests. You will see less advertising if you disable these cookies.</p>
              </li>
              <li>
                <p>Session Cookies - These cookies allow websites to link the actions of a user during a browser session. They may be used for a variety of purposes, such as remembering what a user has put in their shopping cart as they browse a website. Session cookies also permit users to be recognized as they navigate a website so that any item or page changes they make are remembered from page to page. Session cookies expire after a browser session; they are not stored long term.</p>
              </li>
              <li>
                <p>Persistent Cookies - These cookies are stored on a user’s device between browser sessions, which allows the user’s preferences or actions across a website or across different websites to be remembered. Persistent cookies may be used for several purposes, including remembering users’ choices and preferences when using a website or to target advertising to them.</p>
              </li>
              <li>
                <p>We may also use cookies for:</p>
                <ul>
                  <li>identifying the areas of our website that you have visited</li>
                  <li>personalizing content that you see on our website</li>
                  <li>our website analytics</li>
                  <li>remarketing our products or services to you</li>
                  <li>remembering your preferences, settings, and login details</li>
                  <li>targeted advertising and serving ads relevant to your interests</li>
                  <li>affiliate marketing</li>
                  <li>allowing you to post comments</li>
                  <li>allowing you to share content with social networks.</li>
                </ul>
              </li>
            </ul>
            <p>Most web browsers can be set to disable the use of cookies. However, if you disable cookies, you may not be able to access features on our website correctly or at all. </p>
          </div>

          <h3>Web Beacons</h3>

          <div className={classes.Section}>
            We may also use a technology called web beacons to collect general information about your use of our website and your use of special promotions or newsletters. The information we collect by web beacons allows us to statistically monitor the number of people who open our emails. Web beacons also help us to understand the behavior of our customers and users.
          </div>

          <h3>At User Registration</h3>

          <div className={classes.Section}>
            When you register as a user, we collect your name and email address. 
          </div>

          <h3>When Buying Products or Services</h3>

          <div className={classes.Section}>
            If you buy products or services from us, we collect your first and last name, email address, physical address, credit card or other payment information, phone number, and other information listed. 
          </div>

          <h3>When Teaching On This Site</h3>
          
          <div className={classes.Section}>
            If you are a United States citizen and a registered teacher on this site, we are required to collect your social security number and report any income generated by you on this site over Six Hundred ($600) Dollars by a 1099 form mail to you and the Internal Revenue Service.
          </div>

          <h3>Cross Device Tracking</h3>

          <div className={classes.Section}>
            We use a service that tracks your behavior from device to device. This technology can track your responses from your desktop computer to your laptop, smartphone, and tablet, in any order when using these devices. We use the information we get from this technology to analyze traffic behavior, improve our marketing and advertising.
          </div>

          <h3>Google Analytics</h3>

          <div className={classes.Section}>
            <p>Our website uses Google Analytics to collect information about the use of our website. Google Analytics collects information from users such as age, gender, interests, demographics, how often they visit our website, what pages they visit, and what other websites they have used before coming to our website. We use the information we get from Google Analytics to analyze traffic, improve our marketing, advertising, and website. Google Analytics collects only the IP address assigned to you on the date you visit our website, not your name or other identifying information. We do not combine the information collected using Google Analytics with PD. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit our website, the cookie cannot be used by anyone but Google. Google also uses specific identifiers to help collect information about the use of our website. </p>
            <ul>
              <li>For more information on how Google collects and processes your data visit: <a href='https://www.google.com/policies/privacy/partners/' target="_blank">https://www.google.com/policies/privacy/partners/</a></li>
              <li>You can prevent Google Analytics from using your information by opting out at this link: <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a></li>
            </ul>
          </div>

          <h3>Remarketing</h3>

          <div className={classes.Section}>
            Our website and applications may use remarketing advertising services. These remarketing services are provided by companies that show our ads on websites and devices across the Internet. With remarketing you may see ads for our products you have previously looked at. As an example, suppose you visit a website that sells computers, but you do not buy a computer on your first visit to that website. The website’s owner might like to encourage you to revisit their site and buy a computer by showing you their ads on other websites that you visit. We use remarketing for similar purposes. For this to happen remarketing companies will read a cookie in your browser. This can only happen if your browser is set to let it happen. You can opt out of these types of advertising cookies by visiting  <a href='http://www.networkadvertising.org/choices'>http://www.networkadvertising.org/choices</a>.
          </div>

          <h3>What Happens If You Don’t Give Us Your PD</h3>

          <div className={classes.Section}>
            If you do not provide us with enough PD, we may not be able to provide you all our products and services. However, you can access and use some parts of our website without giving us your PD.
          </div>

          <h2>How Your Information Is Used and Shared</h2>

          <div className={classes.Section}>
            <p>We use the information we receive from you to:</p>
            <ul>
              <li>provide our products and services you have requested or purchased from us</li>
              <li>personalize and customize our content </li>
              <li>make improvements to our website</li>
              <li>contact you with updates to our website, products, and services</li>
              <li>resolve problems and disputes</li>
              <li>contact you with marketing and advertising that we believe may be of interest to you.</li>
            </ul>
          </div>

          <h3>Communications and Emails</h3>

          <div className={classes.Section}>
            When we communicate with you about our website, we will use the email address you provided when you registered as a user or customer. If you have provided your consent to receive promotional emails from the website, we may also send you emails with information about our website or offers from us or our affiliates.  You may opt-out of receiving such promotional information by (i) sending a reply to the promotional email with a message of “unsubscribe”, (ii) emailing us at <a href='mailto:contact@infodepot.com'>contact@infodepot.com</a> or (iii) by following the “unsubscribe” link at the bottom of the promotional email.
          </div>

          <h3>Sharing Information with Affiliates and Other Third Parties</h3>

          <div className={classes.Section}>
            We do not sell or rent your PD to third parties for marketing purposes. However, for data aggregation purposes we may use your NPD, which might be sold to other parties at our discretion. Any such data aggregation would not contain any of your PD. We may give your PD to third-party service providers whom we hire to provide services to us. These third-party service providers may include but are not limited to payment processors, web analytics companies, advertising networks, call centers, data management services, help desk providers, accountants, law firms, auditors, shopping cart and email service providers, and shipping companies.
          </div>

          <h3>Sharing Your Personal Data (PD) With Other Users </h3>

          <div className={classes.Section}>
            Some of your user personal information, including your location, first and last name, business type, LinkedIn and Facebook profiles, current place of work, job position, business name, business address, profile picture, email address, friend connections, spoken languages, business phone number, mobile number, business URL, username, and any image or video content that you have uploaded to our website or mobile app may be displayed to other users to help user interaction within our services or address your request for our services. 
            <br/>
            <br/>
            You understand that any content you upload to your public user profile, including PD, or content that you disclose online in a way that other users can see, including discussion boards, messaging, or other communication mechanisms becomes publicly accessible and can be used by anyone. We do not filter or monitor what is posted on discussion boards. If you post on these discussion boards or other communication devices, you should use care when exposing any PD, as such information is not protected by our privacy notice nor are we liable if you disclose your PD through such postings.
          </div>

          <h3>Sharing Your PD for Lookalike or Similar Audience Marketing</h3>

          <div className={classes.Section}>
            We may share your PD with third parties for similar audience marketing purposes. Similar audience marketing is also called lookalike audience marketing. The third parties we share your PD with for this type of marketing include Facebook and/or Google. Using your PD for similar audience marketing or lookalike audience marketing helps us find new audiences (users and customers) based on similar interests to yours. This helps us improve our marketing services. Your PD is only shared with Facebook and Google for this type of marketing. By using our website and agreeing to our privacy notice you are giving consent for your PD to be used for the marketing purposes described within this section.
          </div>

          <h3>Sharing Information With Business Partners</h3>
          
          <div className={classes.Section}>
            We may share your PD with our business partners. The business partners include general business partners, affiliates, and joint venture partners. We share this information with them so that they can send you information about our products and services as well as their products and services. When you choose to take part in our services and/or offerings, you are authorizing us to provide your email address and other PD to our business partners. Please understand that when we share your PD with our business partners, your PD becomes subject to our business partners’ as well as our privacy notice.
          </div>

          <h3>Text Messaging and Push Notifications</h3>

          <div className={classes.Section}>
            If you provide a mobile telephone number to us, you are giving your consent and authorize us or a third party to send you text messages and push notifications. You are not required to give us your consent for these text messages and push notifications. However, withholding your consent may interfere or prevent us from providing some or all of our services to you. You can stop receiving text messages and push notifications at any time by contacting us.
          </div>

          <h3>Legally Required Releases of Information</h3>

          <div className={classes.Section}>
            We may be legally required to disclose your PD if such disclosure is (a) required by subpoena, law, or other legal process; (b) necessary to assist law enforcement officials or governmental enforcement agencies; (c) necessary to investigate violations of or otherwise enforce our terms and conditions; (d) necessary to protect us from legal action or claims from third parties, including you and/or other users; or (e) necessary to protect the legal rights, personal/real property, or personal safety of our company, users, employees, and affiliates.
          </div>

          <h3>Disclosures to Successors</h3>

          <div className={classes.Section}>
            If our business is sold or merges in whole or in part with another business that would become responsible for providing the website to you, we retain the right to transfer your PD to the new business. The new business would retain the right to use your PD according to the terms of this privacy notice as well as to any changes to this privacy notice as instituted by the new business. We also retain the right to transfer your PD if our company files for bankruptcy and some or all of our assets are sold to another individual or business.
          </div>

          <h3>Community Discussion Boards, Blogs, or Other Mechanisms</h3>

          <div className={classes.Section}>
            Our website may offer the ability for users to communicate through online community discussion boards, blogs, or other mechanisms. We do not filter or monitor what is posted on such discussion mechanisms. If you choose to post on these discussion mechanisms, you should use care when exposing any PD, as such information is not protected by our privacy notice nor are we liable if you disclose your PD through such postings. Also, PD which you post on our website for publication may be available worldwide by means of the Internet. We cannot prevent the use or misuse of such information by others.
          </div>

          <h2>Retaining and Destroying Your PD</h2>

          <div className={classes.Section}>
            We retain information that we collect from you (including your PD) only for as long as we need it for legal, business, or tax purposes. Your information may be retained in electronic, paper, or a combination of both forms. When your information is no longer needed, we will destroy, delete, or erase it.
          </div>

          <h2>Updating Your PD</h2>

          <div className={classes.Section}>
            You can update your PD using services found on our website. If no such services exist, you can contact us using the contact information found at the top of this privacy notice and we will help you. However, we may keep your PD as needed to enforce our agreements and to comply with any legal obligations.
          </div>

          <h2>Revoking Your Consent for Using Your PD</h2>

          <div className={classes.Section}>
            You have the right to revoke your consent for us to use your PD at any time. Such optout will not affect disclosures otherwise permitted by law including but not limited to: (i) disclosures to affiliates and business partners, (ii) disclosures to third-party service providers that provide certain services for our business, such as payment processors, web analytics companies, advertising networks, call centers, data management services, help desk providers, accountants, law firms, auditors, shopping cart and email service providers, and shipping companies, (iii) disclosures to third parties as necessary to fulfill your requests, (iv) disclosures to governmental agencies or law enforcement departments, or as otherwise required to be made under applicable law, (v) previously completed disclosures to third parties, or (vi) disclosures to third parties in connection with subsequent contests or promotions you may choose to enter, or third-party offers you may choose to accept. If you want to revoke your consent for us to use your PD, please contact us through the contact information at the top of this privacy notice.
          </div>

          <h2>Protecting the Privacy Rights of Third Parties</h2>

          <div className={classes.Section}>
            If any postings you make on our website contain information about third parties, you agree to make sure that you have permission to include that information. While we are not legally liable for the actions of our users, we will remove any postings about which we are notified, if such postings violate the privacy rights of others.
          </div>

          <h2>Do Not Track Settings</h2>

          <div className={classes.Section}>
            Some web browsers have settings that enable you to request that our website not track your movement within our website. Our website does not obey such settings when transmitted to and detected by our website. You can turn off tracking features and other security settings in your browser by referring to your browser’s user manual.
          </div>

          <h2>Links to Other Websites</h2>

          <div className={classes.Section}>
            Our website may contain links to other websites. These websites are not under our control and are not subject to our privacy notice. These websites will likely have their own privacy notices. We have no responsibility for these websites and we provide links to these websites solely for your convenience. You acknowledge that your use of and access to these websites are solely at your risk. It is your responsibility to check the privacy notices of these websites to see how they treat your PD. 
          </div>

          <h2>Protecting Children’s Privacy</h2>

          <div className={classes.Section}>
            Even though our website is not designed for use by anyone under the age of 16, we realize that a child under the age of 16 may attempt to access our website. We do not knowingly collect PD from children under the age of 16. If you are a parent or guardian and believe that your child is using our website, please contact us. Before we remove any information we may ask for proof of identification to prevent malicious removal of account information. If we discover that a child is accessing our website, we will delete his/her information within a reasonable period of time. You acknowledge that we do not verify the age of our users nor have any liability to do so. 
          </div>

          <h2>Our Email Policy </h2>

          <div className={classes.Section}>
            You can always opt out of receiving email correspondence from us or our affiliates. We will not sell, rent, or trade your email address to any unaffiliated third party without your permission except in the sale or transfer of our business, or if our company files for bankruptcy.
          </div>

          <h2>Our Security Policy</h2>

          <div className={classes.Section}>
            We have built our website using industry-standard security measures and authentication tools to protect the security of your PD. We and the third parties who provide services to us also maintain technical and physical safeguards to protect your PD. Unfortunately we cannot guarantee prevention of loss or misuse of your PD or secure data transmission over the Internet because of its nature. We strongly urge you to protect any password you may have for our website and not share it with anyone.
          </div>

          <h2>Use of Your Credit Card</h2>

          <div className={classes.Section}>
            You may have to provide a credit card to buy products and services from our website. We use third-party billing services and have no control over them. We use commercially reasonable efforts to ensure that your credit card number is kept strictly confidential by using only third-party billing services that use industry-standard encryption technology to protect your credit card number from unauthorized use. However, you understand and agree that we are in no way responsible for any misuse of your credit card number. 
          </div>

          <h2>Transferring PD from the European Union</h2>

          <div className={classes.Section}>
            PD that we collect from you may be stored, processed, and transferred among any countries in which we operate. The European Union has not found the United States and some other countries to have an adequate level of protection of PD under Article 45 of the GDPR. Our company relies on derogations for specific situations as defined in Article 49 of the GDPR. If you are a European Union customer or user, with your consent your PD may be transferred to the United States or other countries outside the European Union when you request information from us. When you buy goods or services from us, we will use your PD for the performance of a contract or to take steps to enter into a contract. Wherever we transfer, process, or store your PD, we will take reasonable steps to protect it. We will use the information we collect from you in accordance with our privacy notice. By using our website, services, or products you agree to the transfers of your PD described within this section.
          </div>

          <h2>Changes to Our Privacy Notice</h2>

          <div className={classes.Section}>
            We reserve the right to change this privacy notice at any time. If our company decides to change this privacy notice, we will post those changes on our website so that our users and customers are always aware of what information we collect, use, and disclose. If at any time we decide to disclose or use your PD in a method different from that specified at the time it was collected, we will provide advance notice by email sent to the email address on file in your account. Otherwise we will use and disclose our users’ and customers’ PD in agreement with the privacy notice in effect when the information was collected. In all cases your continued use of our website, services, and products after any change to this privacy notice will constitute your acceptance of such change. If you have questions about our privacy notice, please contact us through the information at the top of this privacy notice.
          </div>

          </Card>
      </div>
    );
  }
}

export default PrivacyNotice;