import axiosInstance from '../axiosDefaultInstance'

/**
 * Get User data based on local storage token
 * @param token
 * @returns {AxiosPromise<any>}
 */
export const getLoggedUser = token => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/user/me'

  return axiosInstance.get(url, config)
}

export const getUserProfile = (profileUrl, token = null) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const url = `/user/profile/${profileUrl}`

  return axiosInstance.get(url, token ? config : null)
}

export const signupTeacher = userData => {
  const url = '/user/teacher'

  const params = {
    ...userData
    // "client_id": apiConf.APP_ID //appConfig.REACT_APP_ID
  }

  return axiosInstance.post(url, params)
}

export const signupTeacherStep2 = (data, token) => {
  return axiosInstance.post("/subscriptions/plan", data, {
    headers: {
      "X-Auth-Token": token
    }
  });
};


export const getMyPayments = (token, limit, offset) => {
  return axiosInstance.get(`/subscriptions/plan/payment-history?limit=${limit}&offset=${offset}`, {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const createPaymentMethodForSubscription = (token) => {
  return axiosInstance.post('/subscriptions/plan/payment-method/setup-intent', null, {
    headers: {
      "X-Auth-Token": token
    }
  });
};


export const updatePaymentMethodForSubscription = (token, data) => {
  return axiosInstance.put('/subscriptions/plan/payment-method', data , {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const getMyCurrentSubscription = (token) => {
  return axiosInstance.get('/subscriptions/plan', {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const getDiscountedPrice = (token, queryString) => {
  return axiosInstance.get(`/subscriptions/plan/upgrade/dicsount?${queryString}`, {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const upgradeSubscription = (token, data) => {
  return axiosInstance.put(`/subscriptions/plan/upgrade`,  data, {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const cancelCurrentSubscription = (token) => {
  return axiosInstance.delete(`/subscriptions/plan`,  {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const reactivateCurrentSubscription = (token) => {
  return axiosInstance.patch(`/subscriptions/plan/reactivate`, null,  {
    headers: {
      "X-Auth-Token": token
    }
  });
};

export const signupStudent = userData => {
  const url = '/user/student'

  const params = {
    ...userData
    // "client_id": apiConf.APP_ID //appConfig.REACT_APP_ID
  }

  return axiosInstance.post(url, params)
}

export const uploadProfilePhoto = (token, file) => {
  const config = {
    headers: {
      'X-Auth-Token': token,
      'content-type': 'multipart/form-data'
    }
  }
  const url = '/user/photo'
  const formData = new FormData()
  formData.append('photo[binaryContent]', file, file.name)

  return axiosInstance.post(url, formData, config)
}

export const uploadCoverPhoto = (token, file) => {
  const config = {
    headers: {
      'X-Auth-Token': token,
      'content-type': 'multipart/form-data'
    }
  }
  const url = '/user/cover-photo'
  const formData = new FormData()
  formData.append('coverPhoto[binaryContent]', file, file.name)

  return axiosInstance.post(url, formData, config)
}

export const updatePersonalInfo = (userData, token, type) => {
  const url = '/user/' + type + '/change-personal-info'

  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const params = {
    ...userData
  }

  return axiosInstance.put(url, params, config)
}

export const updatePaymentInfo = (userData, token, type) => {
  const url = '/user/' + type + '/payment-information'

  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const params = {
    ...userData
  }

  return axiosInstance.put(url, params, config)
}

export const updateContactInfo = (userData, token) => {
  const url = '/user/change-contact-info'

  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const params = {
    ...userData
  }

  return axiosInstance.patch(url, params, config)
}

export const updatePayoutInfo = (userData, token, type) => {
  const url = '/user/' + type + '/payout-information'

  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const params = {
    ...userData
  }

  return axiosInstance.put(url, params, config)
}

export const updateCallAvailability = (userData, token, type) => {
  const url = '/user/' + type + '/change-call-availability'
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const params = {
    ...userData
  }

  return axiosInstance.put(url, params, config)
}

export const updateSettings = (token, payload) => {
  const url = '/user/settings'

  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  return axiosInstance.patch(url, payload, config)
}

export const getMineCoupon = (token) => {
  const url = '/coupon/me';

  const config = {
    headers: {
      'X-Auth-Token': token,
    },
  };

  return axiosInstance.get(url, config);
};

export const storeCoupon = (userData, token, apiType) => {
  const url = '/coupon';

  const config = {
    headers: {
      'X-Auth-Token': token,
    },
  };

  const params = {
    ...userData,
  };

  if (apiType === 'post') {
    return axiosInstance.post(url, params, config);
  } else if (apiType === 'patch') {
    return axiosInstance.patch(url, params, config);
  }
};

export const acknowledgePlanDowngraded = (userData, token) => {
  const url = '/subscriptions/plan/downgrade/acknowledge';

  const config = {
    headers: {
      'X-Auth-Token': token,
    },
  };

  const params = {
    ...userData,
  };

  return axiosInstance.patch(url, params, config);
};
