import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../components/UI/Card/Card';

import classes from './../StaticPages.module.scss';

class TermsAndConditions extends Component {
  state = {  }

  componentDidMount() {
    window.scrollTo(0, 0);

    //Meta tags
    let metatitle = document.getElementsByName('title')[0];
    let metaDecription = document.getElementsByName('description')[0];

    document.title = 'Terms and Conditions | InfoDepot';
    metatitle.setAttribute('content','Terms and Conditions | InfoDepot');
    metaDecription.setAttribute('content', 'Read more about the InfoDepot terms and conditions.' )
  }

  render() {
    return (
      <div className={classes.Container}>
        <h1>Terms and Conditions</h1>
        <Card className={classes.Card}>
          <span>Sagacio LLC</span>
          <span>P.O. Box 230385</span>
          <span>New York, New York 10023</span>
          <br />
          <span>Tel. (855) 585-0800</span>
          <span>Email: <a href="mailto:contact@infodepot.com?subject=Hello Infodepot">contact@infodepot.com</a></span>
          <br />
          <span>Effective Date: February 1, 2019</span>
          <br />

          <div className={classes.Section}>
            This web page represents a legal document and is the Terms and Conditions (“Agreement”) for our website, InfoDepot.com (“Website”), its mobile applications and all other interactive properties through which services are delivered (collectively, the “Services”). By using our Services, you agree to fully comply with and be bound by the following Agreement each time you use our Service. 
          </div>



          <h2>Definitions</h2>

          <div className={classes.Section}>
            The terms “us”, “we”, and “our” refer to Sagacio LLC, a New York limited liability company, together with its affiliates, is the owner of this Website (hereinafter “Sagacio”). A “Visitor” is someone who merely browses our Website. A “Member” is someone who has registered with our Website to use our Services. The term “User” is a collective identifier that refers to either a Visitor or a Member. The term “Product” refers to any products we sell or give away.
            <br />
            <br />
            All text, information, graphics, design, and data offered through our Website or Services, whether produced by our Members or by us, are collectively known as our “Content”. We distinguish content posted by our Members as “Member Content”. 
          </div>


          <h2>Acceptance of Agreement</h2>

          <p>This Agreement is between you and Sagacio.</p>

          <div className={classes.Section}>
            THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER PROVISIONS THAT LIMIT OUR LIABILITY TO YOU. PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY AND IN THEIR ENTIRETY, AS USING, ACCESSING, AND/OR BROWSING OUR WEBSITE CONSTITUTES ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO BE BOUND TO EACH AND EVERY TERM AND CONDITION SET FORTH HEREIN, PLEASE EXIT OUR WEBSITE IMMEDIATELY AND DO NOT USE, ACCESS, AND/OR BROWSE IT FURTHER.
            <br />
            <br />
            Except as otherwise noted, this Agreement constitutes the entire and only Agreement between you and Sagacio and supersedes all other Agreements, representations, warranties, and understandings with respect to our Services, and the subject matter contained herein. However, for you to use our Services, you may also be required to agree to additional terms and conditions. Those additional terms and conditions will be incorporated into this Agreement unless otherwise stated. 
          </div>


          <h2>Privacy Notice</h2>

          <div className={classes.Section}>
            Our Privacy Notice is considered part of this Agreement and is available on this Website. You must review our Privacy Notice by clicking on this <Link to={'/privacy-notice'} target="_blank">link</Link>. If you do not accept and agree to be bound by all the terms of this Agreement, including the www.infodepot.com Privacy Notice, do not use this Website or our Services. 
          </div>


          <h2>Arbitration</h2>

          <div className={classes.Section}>
            Any legal controversy or claim arising from or relating to this Agreement and/or our Service, excluding legal action taken by us to collect or recover damages for – or obtain any injunction relating to – website operations, intellectual property, and our Service, will be settled solely by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Any such controversy or claim will be arbitrated on an individual basis, and will not be consolidated in any arbitration with any claim or controversy of any other party. 
            The arbitration will be conducted in New York, New York, and judgment on the arbitration award may be entered into any court having jurisdiction thereof. You or we may seek any interim or preliminary relief from a court of competent jurisdiction in New York, New York, necessary to protect our or your rights or property pending the completion of arbitration. Each party will bear half the arbitration fees and costs.
          </div>


          <h2>Choice of Law and Jurisdiction</h2>

          <div className={classes.Section}>
            This Agreement will be treated as if it were executed and performed in New York, New York, and will be governed by and construed in accordance with the laws of the State of New York without regard to conflict of law provisions. In addition, you agree to submit to the personal jurisdiction and venue of such courts. Any cause of action by you with respect to our Services must be instituted within one (1) year after the cause of action arose or be forever waived and barred. 
          </div>


          <h2>Limited License</h2>

          <div className={classes.Section}>
            Sagacio grants you a nonexclusive, nontransferable, revocable license to access and use our Services strictly in accordance with this Agreement. Your use of our Services is solely for internal, personal, noncommercial purposes unless otherwise provided for in this Agreement. No printout or electronic version of any part of our Website or Services may be used by you in any litigation or arbitration matter whatsoever under any circumstances.
          </div>


          <h2>Legal Compliance</h2>

          <div className={classes.Section}>
            You agree to comply with all applicable domestic and international laws, statutes, ordinances, and regulations regarding your use of our Services, and any Content or software provided therein.
          </div>


          <h2>Our Relationship to You</h2>

          <div className={classes.Section}>
            This Agreement in no way creates any agency, partnership, joint venture, or employee-employer or franchisor-franchisee relationship between you and Sagacio.
          </div>


          <h2>Our Intellectual Property</h2>

          <div className={classes.Section}>
            Our Services may contain our service marks or trademarks as well as those of our affiliates or other companies in the form of words, graphics, and logos. Your use of our Website or Services does not constitute any right or license for you to use our service marks or trademarks without the prior written permission of Sagacio.
            <br/>
            <br/>
            Our Content, as found within our Services, is protected under United States and foreign copyrights. The copying, redistribution, use, or publication by you of any such Content is strictly prohibited. Your use of our Website and Services does not grant you any ownership rights to our Content.
          </div>


          <h2>Eligibility and Registration for Membership</h2>

          <div className={classes.Section}>
            To use our Services, you must register with our Website to become a Member. Your Membership is not transferable or assignable and is void where prohibited. Our Website and Services are intended solely for Users who are at least (18) years of age or older. Any registration by, use of, or access to our Website by anyone under that age is unauthorized, unlicensed, and in violation of these Terms and Conditions. By using our Website and/or Services, you represent and warrant that you are (18) years of age or older and agree to abide by all the terms and conditions of this Agreement. Sagacio has sole right and discretion to determine whether to accept a Member, and may reject a Member’s registration with or without explanation.
            When you complete the registration process, you will receive a password that will allow you to access our Services. You agree to maintain the confidentiality of your password and are fully responsible for all liability and damages resulting from your failure to maintain that confidentiality as well as all activities that occur through the use of your password. 
            You agree to immediately notify us of any unauthorized use of your password or any other breach of security. You agree that our Website cannot and will not be liable for any loss or damage arising from your failure to comply with password security as discussed herein.
          </div>


          <h2>Errors, Corrections, and Changes</h2>

          <div className={classes.Section}>
            We do not represent or otherwise warrant that our Website will be error-free or free from viruses or other harmful components, or that we will correct any errors. We do not represent or otherwise warrant that the information available on or through our Website will be correct, accurate, timely, or otherwise reliable. Sagacio reserves the right at our sole discretion to change any Content, software, and other items used or contained in our Website or Services at any time without notice. 
          </div>


          <h2>Disclaimer</h2>

          <div className={classes.Section}>
            Our Website publishes Content supplied by third parties, Users, Advertisers, Merchants, and Sponsors. Accordingly, Sagacio has no editorial control over such Content. Any opinions or other information or Content expressed or made available by third parties, including information providers, Users, or any other user of our Website, are those of the respective author(s) and not of Sagacio. Sagacio does not guarantee the accuracy, completeness, merchantability, or fitness for any particular purpose nor the legality of any Content provided by any of these parties.
            <br/>
            <br/>
            You understand that we do not operate or control the products or services offered by third-party Merchants. These merchants are responsible for all aspects of order processing, fulfillment, billing, and customer service. We are not a party to the transactions entered into between you and Merchants. You agree that use of or purchase from such Merchants is AT YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND BY US. All rules, legal documents (including privacy policies), and operating procedures of Merchants will apply to you while on any Merchant websites. 
            <br/>
            <br/>
            You hereby acknowledge that nothing contained in our Website will constitute financial, investment, legal, and/or other professional advice and that no professional relationship of any kind is created between you and Sagacio or our Members. You hereby agree that you will not make any financial, investment, legal, and/or other decision based in whole or in part on anything contained in our Website or Services.
          </div>


          <h2>Warranty Disclaimer </h2>

          <div className={classes.Section}>
            Sagacio is not responsible or liable in any manner for any Content posted on our Website or in connection with our Services, whether posted or caused by Members of our Website, or by Sagacio. Although we provide rules for Member conduct and postings, we do not control and are not responsible for what Members post, transmit, or share on our Website or Services, and are not responsible for any offensive, inappropriate, obscene, unlawful, or otherwise objectionable Content you may encounter using our Website or Services. Sagacio is not responsible for the online or offline conduct of any User of our Website or Services. 
            Our Website or Services may be temporarily unavailable from time to time for maintenance or other reasons. Sagacio assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction, unauthorized access to, or alteration of Member communications.  
            Sagacio is not responsible for any technical malfunction or other problems of any telephone network or service, computer system, server or provider, computer or mobile phone equipment, or software, or for any failure of email on account of technical problems or traffic congestion on the Internet, or for any combination thereof – including injury or damage to Members’ or any other person’s computer, mobile phone, or other hardware or software – related to or resulting from the use or downloading of materials in connection with our Website or Services, including, without limitation, any software provided through our Website or Services. 
            Under no circumstances will Sagacio be responsible for any loss or damage, including any loss or damage, personal injury, or death resulting from anyone’s use of our Website or Services, or any interactions between Users of our Website or Services, whether online or offline. 
            Reference to any products, services, processes, or other information by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, recommendation, or any affiliation with our Website by third parties or by any of the equipment or programming associated with or utilized by our Services. 
            <br/>
            <br/>
            THE INFORMATION, CONTENT, AND DOCUMENTS FROM OR THROUGH OUR WEBSITE ARE PROVIDED ‘AS-IS’, ‘AS AVAILABLE’, WITH ‘ALL FAULTS’, AND ALL EXPRESS OR IMPLIED WARRANTIES ARE DISCLAIMED (INCLUDING, BUT NOT LIMITED TO, THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE). OUR WEBSITE AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS, OR OTHER LIMITATIONS. 
            <br/>
            <br/>
            SAGACIO, INCLUDING ALL OUR AFFILIATES, HAS NO LIABILITY WHATSOEVER FOR YOUR USE OF OUR WEBSITE OR SERVICES. SAGACIO CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM THE USE OF OUR WEBSITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO, RELATED SOFTWARE. SAGACIO DOES NOT REPRESENT OR WARRANT THAT OUR CONTENT, SERVICES, OR ANY SOFTWARE FOUND WITHIN ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY SUCH CONTENT OR SOFTWARE AND USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND REMOVE VIRUSES. ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES CAUSED BY VIRUSES SOMEHOW ATTRIBUTED TO OUR CONTENT, SERVICES, AND RELATED SOFTWARE IS DISCLAIMED. 
            <br/>
            <br/>
            WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT AND RELATED SOFTWARE FROM OR THROUGH OUR WEBSITE OR SERVICES AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM OF ANY KIND THAT MAY RESULT. WE AND ALL OUR AFFILIATES ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND SAGACIO.
            <br/>
            <br/>
            OUR WEBSITE AND SERVICES WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US THROUGH OUR WEBSITE OR SERVICES WILL CREATE ANY WARRANTY, REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. 
          </div>


          <h2>Limitation of Liability</h2>

          <div className={classes.Section}>
            IN NO EVENT WILL SAGACIO OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR WEBSITE, CONTENT, SERVICES, OR ANY RELATED SOFTWARE ACCESSED THROUGH OR DOWNLOADED FROM OUR WEBSITE OR SERVICES, EVEN IF SAGACIO IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, SAGACIO’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR WEBSITE AND/OR SERVICES ACCESSED DURING THE PREVIOUS MONTH OF YOUR MEMBERSHIP PRIOR TO THE EVENT GIVING RISE TO LIABILITY.
          </div>


          <h2>Member Conduct</h2>

          <div className={classes.Section}> 
            Members may post their content to our Website through our Services (Member Content). Members and Visitors understand that by using our Website or Service, they may be exposed to content that is offensive, indecent, or objectionable. We have no control over Member Content and do not in any way guarantee its quality, accuracy, or integrity. Sagacio is not responsible for the monitoring or filtering of any Member Content. Should any Member Content be found illegal, Sagacio will submit all necessary information to relevant authorities.
            <br/>
            <br/>
            If any Member Content is reported to Sagacio as being offensive or inappropriate, we may ask the Member to retract or otherwise modify the questionable content within 24 hours of being notified by Sagacio. If the Member fails to meet such a request, Sagacio has full authority to restrict the Member’s ability to post Member Content OR to immediately terminate the Member’s membership without further notice to the Member.
            <br/>
            <br/>
            Without limiting the foregoing, we have sole discretion to remove any Member Content that violates this Agreement or that is otherwise objectionable in our sole discretion. Members are responsible for complying with all applicable federal and state laws for their content, including copyright and trademark laws. Members will respect copyright and trademark laws.
            <br/>
            <br/>
            You warrant that you will not use our Services to infringe on the intellectual property rights of others in any way. In accordance with the DMCA and other applicable law, we have adopted a policy of terminating Members whom we deem, in our sole discretion, to be infringers of others’ intellectual property rights.
            <br/>
            <br/>
            <strong><i>As a Member, you agree not to use our Services to do any of the following:</i></strong>
            <br/>
            <br/>
            <p><u>Upload, post, or otherwise transmit any Member Content that:</u></p> 
            <br/>
            <ul>
              <li>
                <p>Violates any local, state, federal, or international laws</p>
              </li>
              <li>
                <p>Infringes on any patent, trademark, trade secret, copyright, or other proprietary rights of any party</p>
              </li>
              <li>
                <p>Harms, threatens, defames, promotes violence or illegal activities, or is otherwise vulgar, obscene, abusive, harassing, tortuous, libelous, invasive of another’s privacy, hateful, or racially, ethically, or otherwise objectionable</p>
              </li>
              <li>
                <p>Links directly or indirectly to any materials to which you do not have a right to link</p>
              </li>
              <li>
                <p>Contains any private information of any third party, including, without limitation, addresses, phone numbers, email addresses, Social Security numbers, and credit card numbers</p>
              </li>
              <li>
                <p>Contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment, or to extract information from our Website or Services</p>
              </li>
              <li>
                <p>Contains any unsolicited or unauthorized advertising, solicitations, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation</p>
              </li>
              <li>
                <p>In the sole judgment of Sagacio is objectionable or restricts or inhibits any other person from using or enjoying our Website or Services, or which may expose Sagacio, our affiliates, or our Users to any harm or liability of any type</p>
              </li>
            </ul>
            <p><u>Use our Content to:</u></p> 
            <ul>
              <li>
                <p>Develop a competing website</p>
              </li>
              <li>
                <p>Create compilations or derivative works as defined under United States copyright laws</p>
              </li>
              <li>
                <p>Redistribute it in any manner, including, but not limited to, sale, license, lease, rental, subscription, or any other distribution mechanism</p>
              </li>
              <li>
                <p>Decompile, disassemble, or reverse engineer our Website, Services, and any related software </p>
              </li>
              <li>
                <p>Use our Website or Services in any manner that violates this Agreement or any local, state, federal, or international laws</p>
              </li>
            </ul>
          </div>

          <h2>Additional Terms for Teachers</h2>

          <div className={classes.Section}>
            <p>When you register as a teacher on the Website and you publish a class on the Website, set up a live call with a student or otherwise provide Content, you hereby expressly agree to the following additional terms:</p>
            <ul>
              <li>
                <p>No content you submit to the Website or used by the Services will not contain any third party copyrighted material, or any material that is subject to other third party proprietary rights, unless you have the express and documented permission from the rightful owner of the material</p>
              </li>
              <li>
                <p>You will conduct yourself in a professional manner at all times and in compliance with all applicable laws</p>
              </li>
              <li>
                <p>You will honor any prices that you established on the Website for the Services and will not be discriminatory or offensive (i.e. using defamatory or obscene language) to any user on the Website including any student enrolled in your class, purchasers of your content or any user that posts a review pertaining to the foregoing</p>
              </li>
              <li>
                <p>We may place or remove advertisements, our service mark and/or logo on your content at our sole and exclusive discretion</p>
              </li>
              <li>
                <p>You acknowledge that the Website permits users to post reviews and rate Member Content and we cannot control the contents of any such posts and are not responsible for the information or opinions that a user may include in any such review or post</p>
              </li>
              <li>
                <p>If you choose to remove your content from the Website, you will continue to own such content, but you grant us the irrevocable right to use that content and to provide it to students who have paid for that content on the Website or Services</p>
              </li>
              <li>
                <p>If we film any video or class, you hereby agree and grant to us the full ownership and control of any video content or class that we have filmed for you.  You further agree and acknowledge that we shall have the sole and exclusive right to add, remove or edit that video content</p>
              </li>
              <li>
                <p>We are not responsible for any third party providers that is utilizes to collect fees and to facilitate  payouts to teachers</p>
              </li>
              <li>
                <p>You acknowledge and agree that we have the right to, but are under no obligation, to delete any listing of any video, class or live tutoring call at any time and for any reason</p>
              </li>
            </ul>
          </div>

          <h2>Additional Terms for Students</h2>

          <div className={classes.Section}>
            <p>As a Student on the Website and/or using the Services, you hereby agree to the following additional terms:</p>

            <ul>
              <li>
                <p>You will not use any profane or defamatory language or otherwise act in any offensive manner towards any teacher or other user on the Website or Services</p>
              </li>
              <li>
                <p>Any Content purchased or accessed by you on the Website or through the Services are for your personal use only and you will not copy or disseminate any materials</p>
              </li>
              <li>
                <p>You acknowledge that we may delete or suspend your account if you violate any of our rules, or at any time and for any reason</p>
              </li>
            </ul>
          </div>

          <h2>Payments to Teachers and Taxes</h2>

          <div className={classes.Section}>
            <ul>
              <li>
                <p>You may earn revenue by publishing videos, take live calls or offering classes through the Website</p>
              </li>
              <li>
                <p>Any revenue earned by you as a  teacher through the Website or Services can be requested through the Website through the payout section of the teacher dashboard at any time in stated increments</p>
              </li>
              <li>
                <p>You must report any discrepancies within 30 days of the date of any revenue payout otherwise you right to dispute any payment is hereby waived</p>
              </li>
              <li>
                <p>You hereby acknowledge that the Website is a platform facilitating your connection with users and it is not party to any transaction between you and any student, user or member and cannot be held responsible for any issues arising from any such transaction</p>
              </li>
              <li>
                <p>You are responsible for all income, sales, and other taxes associated with the fees you have   earned utilizing the Website or the Services.  We will issue to you the required and appropriate tax forms, such as a form 1099 (or 1099k), from Sagacio or one of its payment providers or partners. Sagacio reserves the right to withhold any payment until it has received all tax identification information it requires to satisfy its obligations or as required by law</p>
              </li>
              <li>
                <p>We reserve the right to withhold or cancel payments to you if we deem, in our sole judgement, and absolute discretion, that you have engaged in any behavior that has violated any part of this Agreement</p>
              </li>
            </ul>
          </div>

          <h2>Use of Information</h2>

          <div className={classes.Section}>
            You grant Sagacio a non-exclusive, royalty-free, worldwide, perpetual license (“License”) to use the information and materials you post to our Website.  By posting, displaying, transmitting, performing, or otherwise distributing information or other content (“Member Content”) to our Website, you are further grant to Sagacio, its officers, directors, employees, agents, consultants, representatives, and affiliates, an irrevocable License to use the Member Content in connection with the operation of the business of Sagacio, its directors, employees, officers, affiliates, representatives, consultants, and agents, including, without limitation, a right to distribute, copy, transmit, publicly display, reproduce, translate, edit, and reformat Member Content. You understand and agree that you will not be compensated for any Member Content and you agree that you shall have no recourse against Sagacio to use your name, likeness and other information in your Member Content in all marketing, promotion or advertising material related to the Website and/or Services.  By posting Member Content on our Website or Service, you warrant and represent that you own the rights to the Member Content or are authorized to post, display, distribute, perform, or transmit Member Content. 
          </div>

          <h2>Unlawful Activity</h2>

          <div className={classes.Section}>
            We reserve the right to investigate complaints or reported violations of this Agreement and to take any action we deem appropriate, including, but not limited to, reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to your profile, email addresses, usage history, posted materials, IP addresses, and traffic information.
          </div>

          <h2>Linking to Our Website</h2>

          <div className={classes.Section}>
            You may provide links to our Website provided that (a) you do not remove or obscure any portion of our Website by framing or otherwise, (b) your website does not engage in illegal or pornographic activities, and (c) you cease providing links to our Website immediately upon our request.
          </div>

          <h2>Links to Other Websites</h2>

          <div className={classes.Section}>
            Our Website may from time to time contain links to third-party websites. Inclusion of links for any website on our Website does not mean that we endorse, guarantee, warrant, or recommend the services, information, content, and/or data of such third-party websites. Sagacio has no control over the legal documents and privacy practices of third-party websites; you access any third-party websites at your own risk. We recommend that you review the privacy notice and terms and conditions of those websites to fully understand what information is collected and how it is used.
          </div>

          <h2>Platform Fees and Commissions</h2>

          <div className={classes.Section}>
            You acknowledge and agree that we may, at any time, add, change or modify any fees or charges charged for use of the Services or Website, including, but not limited to the platform fees and commissions.
          </div>

          <h2>Payments</h2>

          <div className={classes.Section}>
            You represent and warrant that if you are purchasing something from us, (i) any payment information you supply is true and complete, (ii) charges incurred by you will be honored by your bank or credit card company, (iii) you will pay the charges incurred by you at the posted prices, including any applicable taxes, and (iv) if your initial payment method is dishonored, you will still pay the incurred charges, including any surcharge we may incur due to the dishonored payment.
          </div>

          <h2>Refund and Return Policy</h2>

          <div className={classes.Section}>
            To the extent that you purchase any goods or Services directly from us and such goods or Services are refundable, we may refund your purchase price within thirty (30) days of your notifying us in writing of your desire for the refund together with a reason for the request subject to the return of the Product or Service to us in substantially the same condition as when you purchased it. Any refunds will be returned as a credit to the user for use on the Website.
          </div>

          <h2>Termination of Membership</h2>

          <div className={classes.Section}>
            Your membership with us is effective until terminated by you or us. Your rights under these Terms and Conditions will terminate without our notice if you fail to comply with any term of these Terms and Conditions. Upon termination, you will stop representing yourself as a registered Member or Client. You must delete or destroy any information or content (including all copies) obtained from our Website. Certain provisions of this Agreement, including, but not limited to, copyrights, indemnity, trademarks, limitation of liability, warranty, and jurisdictional issues will survive the termination of this Agreement.
          </div>

          <h2>Indemnification</h2>

          <div className={classes.Section}>
            You agree to indemnify, defend, and hold us and our partners, agents, officers, directors, employees, subcontractors, successors, assigns, third-party suppliers of information and documents, attorneys, advertisers, product and service providers, and affiliates free from any liability, loss, claim, and expense, including reasonable attorney’s fees, related to your violation of this Agreement or use of our Website or Services. 
          </div>

          <h2>Severability and Survival</h2>

          <div className={classes.Section}>
            Should any part of this Agreement be held invalid or unenforceable, that portion will be construed consistent with applicable law and the remaining portions will remain in full force and effect. To the extent that any Content is in conflict or inconsistent with this Agreement, this Agreement will take precedence. Our failure to enforce any provision of this Agreement will not be deemed a waiver of such a provision, nor of the right to enforce such a provision. Our rights under this Agreement will survive any termination of this Agreement.
          </div>

          <h2>Changes to Our Terms and Conditions</h2>

          <div className={classes.Section}>
            We reserve the right to change these Terms and Conditions at any time by giving you advance notice of the changes by email or in writing. We will also post these changes on our Website. These changes will become effective 30 days after receipt of the notice. To avoid doubt, no unilateral amendment will retroactively change agreed dispute-resolution provisions of these Terms and Conditions, if any, including, for example, arbitration provisions for then-pending disputes unless the parties expressly agree otherwise. Your continued use of our Website, Services, and Products after any change to these Terms and Conditions and our notifying you will constitute your acceptance of such change. If you do not agree with the changes to these Terms and Conditions, you can choose to discontinue the use of our Website, Services, and Products.
          </div>

        </Card>
      </div>
    );
  }
}

export default TermsAndConditions;