import React from 'react';
import { connect } from 'react-redux';
import clone from 'lodash.clonedeep';
import windowWidth from 'react-window-size';

import classes from './RateModal.module.scss';
import Modal from '../../../components/UI/Modal/Modal';
import { Form, Input } from '../../../components/UI/Form/Form';
import Button from '../../UI/Button/Button';
import * as validator from '../../../utils/formValidator';
import RatePicker from '../RatePicker/RatePicker';
import * as ratingService from '../../../services/rating/ratingService';
import * as actions from '../../../store/actions/indexActions';
import { errorParser } from './../../../utils/errorParser';

class RateModal extends React.PureComponent {
	state = {
		loading: false,
		rating: null,
		formIsValid: true,
		formControls: {
			post: {
				id: 'post-' + this.props.id,
				type: 'textarea',
				charCounter: 500,
				placeholderStatic: 'Write your review',
				value: '',
				validation: {
					maxLength: 500
				},
				error: '',
				valid: true,
				touched: true,
				
			}
		},
		moveToTop: ''
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);

		formControlsCopy[controlName].value = e.target.value;
		formControlsCopy[controlName].touched = true;
		formControlsCopy[controlName].error = '';
		

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy),
			
		});

		
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const errorMsg = validator.validateInput(formControlsCopy[controlName]);
		
		if (errorMsg) {
			formControlsCopy[controlName].error = errorMsg;
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy),
			moveToTop: ''
		});

		
	};

	inputFocusHandler = () => {
		this.setState({
			moveToTop: '-100px'
		});
	}

	onHandleRateChange = (newRate) => {
		this.setState({
			rating: newRate
		});
	};

	onHandleCloseRateModal = () => {
		this.props.onClose();
	};

	showSuccessRateSnack = () => {
		window.scrollTo(0, 0);

		this.props.snackbarAdd({
			type: 'info',
			message: 'You have successfully rated' + ' "' + this.props.callName + '" call. You can see your review on the live tutoring call detail page.'
		});
		this.onHandleCloseRateModal();
	};

	submit = () => {
		if (this.state.formIsValid && this.state.rating) {
			this.setState({ loading: true });

			const slug = this.props.type === 'videoClass' ? this.props.srcData.slug : this.props.slug;
			const successResponse = (responseData) => {
				this.props.type === 'videoClass' ? this.props.onSuccess(responseData) : this.showSuccessRateSnack();
			}

			ratingService
				.postReview(
					this.props.token,
					this.props.type,
					slug,
					this.state.rating,
					this.state.formControls.post.value
				)
				.then((response) => {
					this.setState({ loading: false });
					console.log('success rate' ,response);
					successResponse(response.data);
					//this.onHandleCloseRateModal();

				})
				.catch((error) => {
					this.setState({ loading: false });
					console.log(error.response);
					if (error.response.data) {
						const apiErr = errorParser(error.response.data.errors);
						console.log(apiErr);
						if (apiErr.formError)
							this.props.snackbarAdd({
								message: apiErr.formError.join(' '),
								type: 'error',
								timeout: 8000
							});
						else {
							if (apiErr.formCommentError) {
								const formCopy = clone(this.state.formControls);
								formCopy.post.error = apiErr.formCommentError;

								this.setState({ formControls: formCopy });
							}
						}
						this.onHandleCloseRateModal();
					}
				});
		}
	}
	render() {
		const { loading, rating, formControls, moveToTop } = this.state;
	
		const {type} = this.props;

		const isCall = type === 'liveCall';
		const isCallVideo = type === 'videoLiveCall';

		const screenWidth = this.props.windowWidth;

		const topValue = moveToTop && screenWidth <= 450 ? moveToTop : '';

		return (
			<Modal
				open={this.props.open}
				className={classes.Container}
				onClose={this.props.onClose}
				style={topValue}
				>
				<h1>Rate and Review</h1>
				<h4>{isCall || isCallVideo ? 'Rate your experience with the teacher during this live call' : 'Rate this class' }:</h4>
				<RatePicker
					onRateChange={this.onHandleRateChange}
					className={classes.RatePicker}
				/>
				<Form onSubmit={this.submit}>
					<h4>What did you think of this {isCall || isCallVideo ? 'conversation' : 'class' }?</h4>
					<Input
						className={classes.TextControl}
						config={formControls.post}
						onChange={(e) => this.inputChangeHandler(e, 'post')}
						onBlur={(e) => this.inputBlurHandler(e, 'post')}
						onFocus={(e) => this.inputFocusHandler()}
						
					/>
					<small>{isCall || isCallVideo ? "It will be displayed on the teacher's live tutoring call page." : "" }</small>
					<div className={classes.ButtonWrap}>
						<Button type="link" size="large" onClick={this.onHandleCloseRateModal}>
							Cancel
						</Button>
						<Button
							size="large"
							type="submit"
							disabled={!rating || loading || !this.state.formIsValid}
							loading={Number(loading)}>
							SUBMIT
						</Button>
					</div>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.accessToken
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(windowWidth(RateModal));
