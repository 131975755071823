import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Iframe from "react-iframe";
import IconPublic from "@material-ui/icons/Public";
import clone from "lodash.clonedeep";
import ReactSVG from "react-svg";
import windowWidth from "react-window-size";

import * as videoService from "../../services/video/videoService";
import * as actions from "../../store/actions/indexActions";
import * as screen from "../../utils/common.js";
import classes from "./VideoClass.module.scss";
import Card from "../../components/UI/Card/Card";
import Button from "../../components/UI/Button/Button";
import Avatar from "../../components/UI/Avatar/Avatar";
import Modal from "../../components/UI/Modal/Modal";
import apiConfig from "../../utils/apiConfig";
import Tabs from "../../components/UI/Tabs/Tabs";
import DescriptionTab from "./TabsRender/DescriptionTab/DescriptionTab";
import WorkbookTab from "./TabsRender/WorkbookTab/WorkbookTab";
import ReviewsTab from "./TabsRender/ReviewsTab/ReviewsTab";
import VideoComments from "../../components/Comments/VideoComments";
import IconEdit from "./../../assets/images/icons/pen_icon.svg";
import RateWidget from "../../components/Ratings/RateWidget/RateWidget";
import BuyVideoModal from "../../components/BuyVideoModal/BuyVideoModal";
import SignUpModal from "../../components/SignUpModal/SignUpModal";
import CustomSelect from "../../components/UI/Select/Select";
import IconTrash from "./../../assets/images/icons/trash-alt-solid.svg";
import Follow from "../Follow/Follow";
import { ChosenDisplayName, shouldDisplayUsernameSection } from "../../components/UI/ChosenDisplayName/ChosenDisplayName";
import PromptToUpgradeSubscriptionWide from "../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscriptionWide";

const parseIframeURL = (iframeString) =>
  iframeString.split('src="')[1].split('" ')[0];

const tabTypes = () => {
  return {
    description: {
      url: "/account/settings/personal",
      value: "description",
    },
    workbook: {
      url: "/account/settings/contact",
      value: "workbook",
    },
    comments: {
      url: "/account/settings/payment",
      value: "comments",
    },
    reviews: {
      url: "/account/settings/payout",
      value: "reviews",
    },
  };
};

class VideoClass extends React.PureComponent {
  state = {
    video: null,
    modalPreviewOpened: false,
    modalBuyOpened: false,
    modalConfirmOpened: false,
    modalSignUpOpened: false,
    loading: false,
    publishing: false,
    activeTab: null,
  };

  openBuyModal = () => {
    if (this.props.user) {
      this.setState({ modalBuyOpened: true });
    } else {
      this.openSignUpModal();
    }
  };

  closeBuyModal = () => {
    this.setState({ modalBuyOpened: false });
  };

  openConfirmwModal = () => {
    this.setState({ modalConfirmOpened: true });
  };

  closeConfirmwModal = () => {
    this.setState({ modalConfirmOpened: false });
  };

  openPreviewModal = () => {
    this.setState({ modalPreviewOpened: true });
  };

  closePreviewModal = () => {
    this.setState({ modalPreviewOpened: false });
  };

  openSignUpModal = () => {
    this.setState({ modalSignUpOpened: true });
  };

  closeSignUpModal = () => {
    this.setState({ modalSignUpOpened: false });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const videoId = this.props.match.params.id;
    const query = new URLSearchParams(this.props.location.search);
    const autoOpenBuyModal = query.get("buy-modal");

    const activeTabAsync = new Promise((resolve) => {
      if (this.props.location.search === "?commentId=") {
        setTimeout(() => {
          resolve("comments");
          setTimeout(() => {
            const hash = this.props.history.location.hash.replace("#", "");
            const element = document.getElementById(hash);
            element !== null &&
              element.scrollIntoView(element, { behavior: "smooth" });
          }, 2000);
        }, 100);
      } else {
        setTimeout(() => {
          const queryTab = this.props.match.params.tab;
          const activeTab = queryTab || "description";
          // console.log(this.props.match)
          if (!queryTab) {
            this.props.history.push(this.props.match.url + "/" + activeTab);
          }
          resolve(activeTab);
        }, 100);
      }
    });

    this.setState({
      activeTab: await activeTabAsync,
      modalBuyOpened: autoOpenBuyModal,
    });

    if (videoId) {
      videoService
        .getVideoClass(this.props.token, videoId)
        .then((response) => {
          // if video isn't finished redirect user to his browse-infodepot
          if (response.data.uploadVideo.status === "finished") {
            // if we have access to the videoIframe it means video is purchased or we are the owner. In both cases we have full access
            this.setState({
              video: response.data,
              fullAccess: Boolean(response.data.uploadVideo.videoIframe),
            });
          } else {
            this.props.snackbarAdd({
              message: "Video you request could not be reached!",
              type: "error",
              timeout: 8000,
            });
            this.props.history.replace("/browse-infodepot");
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.props.history.replace("/browse-infodepot");
        });
    } else {
      this.props.history.replace("/account");
    }
    this.setState({ screenWidth: screen.mq() });
    //console.log(this.props.match.params.id);

    //Meta tags
    let metatitle = document.getElementsByName("title")[0];

    document.title = this.props.match.params.id + " | InfoDepot";
    metatitle.setAttribute(
      "content",
      this.props.match.params.id + " | InfoDepot"
    );
  }

  publishVideo = (e) => {
    e.preventDefault();
    this.setState({ publishing: true });
    videoService
      .publishVideoClass(
        this.props.token,
        this.state.video.slug,
        this.props.status
      )
      .then((response) => {
        // on complete update the user to reduce the price of the video
        this.props.snackbarAdd({
          message: "You have successfully published your video.",
          timeout: 8000,
        });

        const copyVideo = clone(this.state.video);
        copyVideo.status = "published";

        this.setState({ video: copyVideo });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ publishing: false });
				if (error.response.data && error.response.data.message && error.response.data.message === 'You cannot publish video class.') {
					this.props.snackbarAdd({
						message: 'To offer more than 10 videos, upgrade your subscription.',
						timeout: 8000,
						type: 'info'
					});
				}
      });
  };

  handleTabChange = (event, value) => {
    const cleanUrl = this.props.match.url;
    //console.log(event.currentTarget.offsetTop);
    this.props.history.push(
      cleanUrl.substring(0, cleanUrl.lastIndexOf("/" + 1)) + value
    );
    this.setState({ activeTab: value });
  };

  handleSuccessReview = (response) => {
    this.setState({ video: response.videoClass });
  };

  handleOptionChange = (event, value) => {
    const cleanUrl = this.props.match.url;
    this.props.history.push(
      cleanUrl.substring(0, cleanUrl.lastIndexOf("/" + 1)) + event.target.value
    );
    this.setState({ activeTab: event.target.value });
  };

  onHandleDownload(docSlug, filename) {
    console.log("download clicked");
    videoService
      .downloadDocument(this.props.token, docSlug)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((error) => {
        console.dir(error);
        this.props.snackbarAdd({
          message: error.response.statusText,
          type: "error",
          timeout: 8000,
        });
      });
  }

  deleteVideo = (videoSlug, token) => {
    this.setstate = {
      loading: true,
    };
    videoService
      .deleteVideoService(token, videoSlug)
      .then((response) => {
        //console.log(response.data);

        this.props.snackbarAdd({
          message: "You have successfully deleted your video.",
          timeout: 6000,
        });

        this.setstate = {
          loading: false,
        };
      })
      .catch((error) => {
        console.log(error);
      });
    this.closeConfirmwModal();
    this.setstate = {
      loading: true,
    };
    this.props.history.push("/account/dashboard");
  };

  render() {
    const {
      video,
      fullAccess,
      modalPreviewOpened,
      modalConfirmOpened,
      modalBuyOpened,
      modalSignUpOpened,
      activeTab,
    } = this.state;

    const screenWidth = this.props.windowWidth;

    const { user, token } = this.props;
    const status = video && video.status;
    const isAuthor = () =>
      Boolean(video && user && user.username === video.author.username);

    const tabProperties = [
      {
        value: "description",
        label: "Description",
        content: <DescriptionTab video={video} />,
      },
      {
        value: "workbook",
        label: "Workbook",
        content: video && (
          <WorkbookTab
            user={user}
            video={video}
            onClickBuy={this.openBuyModal}
            onClickDownload={(docSlug, filename) =>
              this.onHandleDownload(docSlug, filename)
            }
          />
        ),
      },
      {
        value: "comments",
        label: "Comments",
        content: video && (
          <VideoComments
            video={video}
            showBuyModal={() => {
              this.openBuyModal();
            }}
          />
        ),
      },
      {
        value: "reviews",
        label: "Reviews",
        content: video && (
          <ReviewsTab
            token={token}
            user={user}
            video={video}
            showBuyModal={this.openBuyModal}
            onSuccessReview={this.handleSuccessReview}
          />
        ),
      },
    ];

    // full video url
    const iframeUrl = () =>
      fullAccess ? parseIframeURL(video.uploadVideo.videoIframe) : null;

    // preview video url
    const iframePreviewUrl = () => {
      // for mobile devices we have added muted flag so autoplay would work
      return (
        parseIframeURL(video.uploadVideo.videoPreviewIframe) + "&autoplay=1"
      );
    };

    const publishRender = status === "drafted" && (
      <div className={classes.PublishWrap}>
        <p>
          This video is visible only to you. Click Publish to make it visible to
          others.
        </p>
        <Button
          size="large"
          loading={Number(this.state.publishing)}
          onClick={(e) => this.publishVideo(e)}
        >
          <IconPublic style={{ marginRight: "8px" }} /> PUBLISH NOW
        </Button>
      </div>
    );

    const buyOverlayRender = video && !fullAccess && (
      <React.Fragment>
        <img
          src={apiConfig.DOMAIN_IMAGE + video.cover.big}
          alt="lession cover"
        />

        <div className={classes.BuyOverlay}>
          {screenWidth > 601 ? (
            <React.Fragment>
              <h1>{video.name}</h1>
              <h3>${video.price.toFixed(2)}</h3>
            </React.Fragment>
          ) : (
            ""
          )}

          <p>Buy video class to enroll now or to preview class below.</p>
          <div className={classes.ButtonsWrap}>
            <Button
              className={classes.BuyClassButton}
              size="large"
              onClick={this.openBuyModal}
            >
              BUY VIDEO CLASS
            </Button>
            <Button
              size="large"
              type="outline"
              onClick={this.openPreviewModal}
              className={classes.PreviewButton}
            >
              PREVIEW VIDEO
            </Button>
          </div>
          {/* modals */}
          <Modal
            className={classes.ModalPreview}
            onClose={this.closePreviewModal}
            open={modalPreviewOpened}
          >
            <h3>{"Video Class Preview: " + video.name}</h3>
            <Iframe
              className={classes.IframePreview}
              url={iframePreviewUrl()}
              allow="autoplay=1&loop=1&autopause=0"
              allowFullScreen
            />
          </Modal>
          <BuyVideoModal
            open={modalBuyOpened}
            onClose={this.closeBuyModal}
            onSuccess={(newVideo) =>
              this.setState({ video: newVideo, fullAccess: true })
            }
            video={video}
          />
          <SignUpModal
            open={modalSignUpOpened}
            onClose={this.closeSignUpModal}
            onAuthReturnToUrl={video && "/video/" + video.slug}
          />
        </div>
      </React.Fragment>
    );

    return (
      <>
        <Card
          loading={Number(!video)}
          noribbon={1}
          className={classes.Container}
        >
          {isAuthor() && video && video.hidden && status === "published" && <PromptToUpgradeSubscriptionWide from="singleVideo" />}
          {/* Publish CTA */}
          {publishRender}

          <div className={classes.IframeWrap}>
            {fullAccess ? (
              <Iframe
                className={classes.Iframe}
                url={iframeUrl()}
                //allow="autoplay; fullscreen"
                allowFullScreen
              />
            ) : (
              buyOverlayRender
            )}
            {/* Video Iframe */}
          </div>

          {/* Title */}
          <div className={classes.TitleWrap}>
            <section>
              <h1>
                {video ? video.name : "Loading video..."}{" "}
                {video && video.purchased ? (
                  ""
                ) : (
                  <span className={classes.Price}>
                    ${video ? video.price.toFixed(2) : "Loading..."}
                  </span>
                )}
              </h1>

              {isAuthor() && (
                <div className={classes.EditDeleteWrap}>
                  {video.canBeDeleted && (
                    <div className={classes.Tooltip}>
                      <span className={classes.TooltipText}>Delete video</span>
                      <a
                        onClick={(e) => {
                          //e.preventDefault()
                          //this.deleteVideo(video.slug, this.props.token);
                          this.openConfirmwModal();
                        }}
                      >
                        <ReactSVG
                          className={classes.IconTrash}
                          src={IconTrash}
                        />
                      </a>
                    </div>
                  )}

                  {!video.hidden && <Link to={"/edit-video/" + video.slug}>
                    <Button color={"secondary"}>
                      <ReactSVG className={classes.IconEdit} src={IconEdit} />
                      <span>Edit</span>
                    </Button>
                  </Link>}
                </div>
              )}
            </section>
          </div>

          {/* Details */}
          <div className={classes.InfoSection}>
            {/* Tabs */}
            {screenWidth > 1024 ? (
              <Tabs
                className={classes.Tabs}
                activetab={activeTab}
                config={tabProperties}
                onChangeTab={(e, val) => this.handleTabChange(e, val)}
              />
            ) : (
              <div className={classes.SelectContainer}>
                <CustomSelect
                  activetab={activeTab}
                  config={tabProperties}
                  onChangeOption={(e, val) => this.handleOptionChange(e, val)}
                />
              </div>
            )}
            <aside>
              {
                /* rate aside */
                <RateWidget srcData={video} type={"video"} />
              }

              {/* teacher aside */}
              <h5>About the teacher</h5>
              <div className={classes.AuthorWrap}>
                <div className={classes.AuthorInner}>
                  <Avatar
                    size={62}
                    src={
                      video &&
                      video.author.profile.photo &&
                      video.author.profile.photo.small
                    }
                    className={classes.Avatar}
                  />
                  <div>
                    <span>
                       <strong>{video && <Link to={`/profile/${video.author.profileUrl}`}><ChosenDisplayName user={video.author} top="-1px" /></Link>}</strong>
                    </span>
                    {video && shouldDisplayUsernameSection(video.author) && <span>{video && video.author.username}</span>}
                    {this.props.user ? (
                      video &&
                      video.author &&
                      video.author.enabled && (
                        <>
                          {" "}
                          <div className={classes.ButtonWrap}>
                            <Follow teacher={video.author} />

                            {video &&
                              video.author &&
                              video.author.cryptName !==
                                this.props.user.cryptName &&
                              video.author.enabled && (
                                <Link
                                  to={`/messages/${video.author.cryptName}`}
                                >
                                  <Button
                                    type="contained"
                                    size="small"
                                    style={{
                                      fontSize: "13px!important",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    Message
                                  </Button>
                                </Link>
                              )}
                          </div>
                        </>
                      )
                    ) : (
                      <div className={classes.ButtonWrap}>
                        <Button
                          type="contained"
                          size="small"
                          onClick={() => this.openSignUpModal()}
                          style={{ fontSize: "13px!important" }}
                        >
                          Follow
                        </Button>
                        <Button
                          type="contained"
                          size="small"
                          style={{
                            fontSize: "13px!important",
                            marginLeft: "10px",
                          }}
                          onClick={() => this.openSignUpModal()}
                        >
                          Message
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <p>{video && video.author.profile.introAboutMe}</p>
              </div>
            </aside>
          </div>
        </Card>
        <Modal
          className={classes.ModaConfirm}
          onClose={this.closeConfirmwModal}
          open={modalConfirmOpened}
        >
          <h3 className={classes.ModalSubtitle}>
            Are you sure you want to delete this video?
          </h3>
          <div className={classes.ConfirmBtnWrap}>
            <div>
              <a
                className={classes.CancelBtn}
                onClick={this.closeConfirmwModal}
              >
                Cancel
              </a>
            </div>
            <div>
              <a
                onClick={(e) => {
                  //e.preventDefault()
                  this.deleteVideo(video.slug, this.props.token);
                  //console.log(video.slug);
                }}
              >
                <Button className={classes.RedBtn} size="large">
                  Delete
                </Button>
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(windowWidth(VideoClass));
