import React from 'react';
import ReactSVG from 'react-svg';
import IconClock from '@material-ui/icons/AccessTime';
import moment from 'moment';
import Moment from 'react-moment';

import classes from './DescriptionTab.module.scss';
import IconCalendar from './../../../../assets/images/icons/calendar_icon.svg';
import Popover from  '../../../../components/UI/Popover/Popover';
import IconLiveCall from './../../../../assets/images/icons/live_call_icon.svg'
import IconVideoBasic from './../../../../assets/images/icons/video-solid.svg';
import { timeDateChange } from "../../../../utils/dateHelper";

const DescriptionTab = (props) => {
	const call = props.call;
	let category = call && call.categories[0].parent == null;
	const statusCall = props.call && props.call.author && props.call.author.statusCall ? props.call.author.statusCall : null ;

	//console.log(category);
	
	const callDateRender = () => {
		let standardType = call && call.scheduleType == 'standard'
		let scheduleDaysRender = () => {
			if(call && call.scheduleDays.length === 7) {
				return 'Every day'
			} else if (call && call.scheduleDays.length === 5) {
				return 'Monday to Friday'
			} else {
				return 'Weekend days'
			}
		}

		const timezoneOffsetsToNextDay = () => {
			const date = moment(call.scheduleCustomEndDateUTC).format('YYYY-MM-DD')
			const dateUTC = moment(call.scheduleCustomEndDateUTC).utc().format('YYYY-MM-DD')
			return (call.scheduleType === 'custom') && moment(date).isAfter(dateUTC);
		};

		const OffsetDayBadge = () => timezoneOffsetsToNextDay() ? <div className={classes.OffsetDayBadge}>+1</div> : null;


		return <React.Fragment>
			<div className={classes.dateWrap}>
			<ReactSVG svgClassName={classes.IconCalendar} src={IconCalendar}/> 
				{standardType ? scheduleDaysRender() : <React.Fragment>
					<Moment format="MMM DD, YYYY">{call && call.scheduleCustomStartDateUTC}</Moment> 
					<span className={classes.divide}>-</span>
					<Moment utc format="MMM DD, YYYY">{call && call.scheduleCustomEndDateUTC}</Moment>
				</React.Fragment> }
			</div>
			<div className={classes.dateWrap}>
				<IconClock className={classes.IconClock}/> 
				<Moment format="HH:mm">{call && timeDateChange(call.scheduleStartTimeUTC)}</Moment><span className={classes.divide}>
				to
				</span> <Moment format="HH:mm">{call && timeDateChange(call.scheduleEndTimeUTC)}</Moment>

				{call && <Popover open={OffsetDayBadge()} content={`Ends on ${moment(call.scheduleCustomEndDateUTC).format('MMM DD, YYYY.')}`} />}
			</div>
		</React.Fragment>
		
	}

	// let sheduleDaysExist = call & call.videoScheduleDays;
	// console.log(sheduleDaysExist)

	
	const callDateRenderVideo = () => {
		let standardType = call && call.videoScheduleType == 'standard'
		
		let scheduleDaysRender = () => {
			if(call && call.videoScheduleDays.length === 7) {
				return 'Every day'
			} else if (call && call.videoScheduleDays.length === 5) {
				return 'Monday to Friday'
			} else {
				return 'Weekend days'
			}
		}

		const timezoneOffsetsToNextDay = () => {
			const date = moment(call.videoScheduleCustomEndDateUTC).format('YYYY-MM-DD')
			const dateUTC = moment(call.videoScheduleCustomEndDateUTC).utc().format('YYYY-MM-DD');
			//console.log(moment(date).isAfter(dateUTC));
			return (call.scheduleTypeVideo === 'custom') && moment(date).isAfter(dateUTC);
		};

		const OffsetDayBadge = () => timezoneOffsetsToNextDay() ? <div className={classes.OffsetDayBadge}>+1</div> : null;


		return <React.Fragment>
			<div className={classes.dateWrap}>
			<ReactSVG svgClassName={classes.IconCalendar} src={IconCalendar}/> 
				{standardType ? scheduleDaysRender() : <React.Fragment>
					<Moment format="MMM DD, YYYY">{call && call.videoScheduleCustomStartDateUTC}</Moment> 
					<span className={classes.divide}>-</span>
					<Moment utc format="MMM DD, YYYY">{call && call.videoScheduleCustomEndDateUTC}</Moment>
				</React.Fragment> }
			</div>
			<div className={classes.dateWrap}>
				<IconClock className={classes.IconClock}/> 
				<Moment format="HH:mm">{call && timeDateChange(call.videoScheduleStartTimeUTC)}</Moment><span className={classes.divide}>
				to
				</span> <Moment format="HH:mm">{call && timeDateChange(call.videoScheduleEndTimeUTC)}</Moment>

				{call && <Popover open={OffsetDayBadge()} content={`Ends on ${moment(call.videoScheduleCustomEndDateUTC).format('MMM DD, YYYY.')}`} />}
			</div>
		</React.Fragment>
		
	}

	const callVideoBlockRender = () => {

	}


	return (
		<article>
			<div>
				<p>{call && call.description}</p>
			</div>

			<div className={classes.GroupLabels}>
				<label>CATEGORY: </label>
				<span>{ category == true ? call && call.categories[0].name : call && call.categories[1].name}</span>
			</div>
			<div className={classes.GroupLabels}>
				<label>SUBCATEGORY: </label>
				<span>{category == true ? call && call.categories[1].name : call && call.categories[0].name}</span>
			</div>
			<div className={classes.CallAvailabilities}>
				{
					call && call.scheduleDays !== null || call && call.scheduleCustomDays.length > 0 ?
					<div className={classes.AvailabilitiesPhone}>
						<ReactSVG className={classes.IconLiveCall} src={IconLiveCall}/>
						<h5 style={{marginTop: '10px'}}>Availability for phone calls</h5>
						{statusCall == 'all_disabled' ? <span className={classes.StatusCallText}>Unavailable indefinitely</span> 
						: statusCall == 'all_available' ? <span className={classes.StatusCallText}>Available anytime</span>
						: !call ? 'Loading...' :  callDateRender()}
					</div>
					: ''
				}
				{
					call && call.videoScheduleDays !== null || call && call.videoScheduleCustomDays.length > 0 ?
					<div className={classes.AvailabilitiesVideo}>
						<ReactSVG className={classes.IconLiveCall} src={IconVideoBasic}/>
						<h5 style={{marginTop: '10px'}}>Availability for video calls</h5>
						{statusCall == 'all_disabled' ? <span className={classes.StatusCallText}>Unavailable indefinitely</span> 
						: statusCall == 'all_available' ? <span className={classes.StatusCallText}>Available anytime</span>
						: !call ? 'Loading...' : callDateRenderVideo()}
					</div>
					: ''}
			</div>
			
		</article>
	);
};

export default DescriptionTab;
