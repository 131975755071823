export const addToUrlQueue = (returnToUrl, props) => {
	const query = new URLSearchParams(props.location.search);
	const redirectionQueue = query.get('return-to-url');
	const _returnToUrl = Array.isArray(returnToUrl) ? String(returnToUrl) : returnToUrl;

	if (redirectionQueue) {
		query.set('return-to-url', [ redirectionQueue, _returnToUrl ]);
	} else {
		query.append('return-to-url', [ _returnToUrl ]);
	}

	return query.toString();
};

export const goToNextQueuedUrl = (props) => {
	const query = new URLSearchParams(props.location.search);
	const urlQueueArr = query.get('return-to-url').split(',');
	const returningUrl = urlQueueArr.pop();
	const returningQuery = urlQueueArr.length ? '?return-to-url=' + urlQueueArr : '';
	props.history.push(returningUrl + returningQuery);
};

export const isUrlQueued = (props) => {
	const query = new URLSearchParams(props.location.search);
	return query.get('return-to-url');
};

export const setReturnToUrlToken = (url) => {
	if (url && typeof url === 'string') {
		localStorage.setItem('returnTo', url);
	} else {
		console.error('"setReturnToUrlToken" requires a "string" path');
	}
};

export const getReturnToUrlToken = () => localStorage.getItem('returnTo');

export const deleteReturnToUrlToken = () => localStorage.removeItem('returnTo');
