import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import Card from "../../../components/UI/Card/Card";
import IDExpansionPanel from "../../../components/UI/ExpansionPanel/ExpansionPanel";
import classes from "./TeacherPage.module.scss";
import Button from "./../../../components/UI/Button/Button";
import Background from "../../../assets/images/background/teacher_background_image.jpg";
import StartNowTeaching from "../../../assets/images/background/start_teaching_earning.png";
import JoinTeaching from "../../../assets/images/background/join_a_teacher.jpg";
import LeftShape from "../../../assets/images/graphics/left_shape.svg";
import RightShape from "../../../assets/images/graphics/right_shape.svg";
import LeftMiddleShape from "../../../assets/images/graphics/left_middle_shape.svg";
import RightMiddleShape from "../../../assets/images/graphics/right_middle_shape.svg";
import Infopreneur from "../../../assets/images/graphics/infopreneur.svg";
import BussinesHobby from "../../../assets/images/graphics/bussines_hobby.svg";
import StartCarier from "../../../assets/images/graphics/carier_start.svg";
import BussinesGrow from "../../../assets/images/graphics/bussines_grow.svg";
import ExtraEarn from "../../../assets/images/graphics/extra_earn.svg";
import LifeLongLearning from "../../../assets/images/graphics/life_long_learning.svg";
import BuildingBackground from "../../../assets/images/background/start_building_background.jpg";
import FirstGraph from "../../../assets/images/graphics/video_classes_illustration.svg";
import SecondGraph from "../../../assets/images/graphics/live_calls_illustration.svg";
import ThirdGraph from "../../../assets/images/graphics/learn_from_expert.svg";
import SetAvailabilityGraph from "../../../assets/images/graphics/set_availability_graph.svg";
import SetYourUploadGraph from "../../../assets/images/graphics/set_your_upload_graph.svg";
import IconCheckmark from "../../../assets/images/icons/check-mark-icon.svg";

class TeacherPage extends Component {
  state = {
    active: "",
  };

  handleClick = (e) => {
    this.setState({
      active: true,
    });
  };
  // handleClickContestRemoveFlag = (e)=> {
  //     if(localStorage.getItem('contestPage') ) {
  //         localStorage.removeItem('contestPage');
  //       }
  //   }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    //Meta tags
    let metatitle = document.getElementsByName("title")[0];
    let metaDecription = document.getElementsByName("description")[0];

    document.title = "Become an InfoDepot Teacher";
    metatitle.setAttribute("content", "Become an InfoDepot Teacher");
    metaDecription.setAttribute(
      "content",
      "Create and sell your video classes or set up live tutoring calls. Become a teacher on InfoDepot and transform your online teaching career."
    );
  };

  render() {
    return (
      <div className={classes.Container}>
        <div className={classes.Section}>
          <div
            className={[classes.SectionOne, classes.ContainerBackground].join(
              " "
            )}
          >
            {/* <div className={classes.LeftShape}>
              <img src={LeftShape} alt="ShapeLeft" />
            </div>
            <div className={classes.RightShape}>
              <img src={RightShape} alt="ShapeLeft" />
            </div>
            <div className={classes.LeftMiddleShape}>
              <img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
            </div>
            <div className={classes.RightMiddleShape}>
              <img src={RightMiddleShape} alt="ShapeRightMiddle" />
            </div> */}
            <div className={classes.SectionInner}>
              <div className={classes.SectionLeft}>
                <h1>Become an InfoDepot teacher</h1>
                <h3>
                  Transform your life and the lives of learners across the world{" "}
                  <br /> by sharing your knowledge.
                </h3>
                <Link to={"/pricing-plans"}>
                  <Button
                    className={classes.ButtonDark}
                    size="large"
                    //onClick={this.handleClickContestRemoveFlag}
                  >
                    JOIN NOW
                  </Button>
                </Link>
                <p>Join now and enjoy a promo fee-free period!</p>
                <a href="/fees-info" className={classes.LinkUnderlined}>
                  Learn more
                </a>
              </div>
            </div>
            <div
              className={classes.ImageBackground}
              style={{ backgroundImage: "url(" + Background + ")" }}
            ></div>
          </div>
          <div className={classes.SectionTwo}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>Why InfoDepot?</h2>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={Infopreneur} alt="Infopreneur" />
                  <h2>Become an infopreneur</h2>
                  <h3>
                    Set up a business based on your knowledge and skills.
                    Identify your biggest strengths and share them with the
                    world by creating bite-sized video classes.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={BussinesHobby} alt="BussinesHobby" />
                  <h2>Turn your hobby into business</h2>
                  <h3>
                    Have a special hobby that you love? By becoming a teacher on
                    InfoDepot , you can easily make your hobby a career and earn
                    full-time income.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={StartCarier} alt="StartCarier" />
                  <h2>Start a new career</h2>
                  <h3>
                    Join the revolution of online teaching and virtual tutoring
                    and take advantage of their flexibility. Create as many
                    classes as you like and explore your own skills and
                    knowledge.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={BussinesGrow} alt="BussinesGrow" />
                  <h2>Grow your business</h2>

                  <h3>
                    Looking for ways to expand your business? What a better way
                    to build brand awareness than by sharing your expertise with
                    those who look up to you.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={ExtraEarn} alt="ExtraEarn" />
                  <h2>Earn extra income</h2>
                  <h3>
                    You don’t have to rely on advertising dollars to promote
                    your video classes or live peer-to-peer phone calls to earn
                    extra income. Monetize your expertise immediately
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={LifeLongLearning} alt="LifeLongLearning" />
                  <h2>Support lifelong learning</h2>
                  <h3>
                    Long gone are the days of learning only at school. Nowadays
                    people want to learn throughout their lifetime and your
                    classes can help them do that.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionThree}>
            <div
              className={classes.ImageBackground}
              style={{ backgroundImage: "url(" + BuildingBackground + ")" }}
            >
              <div className={classes.SectionInner}>
                <div className={classes.SectionCenter}>
                  <h2>Start building your learning community.</h2>
                  <Link to={"/pricing-plans"}>
                    <Button
                      className={classes.Button}
                      color="primary"
                      size="large"
                    >
                      Join InfoDepot now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionFour}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>
                  How to become an InfoDepot Teacher?
                </h2>
                <div className={classes.Step}>
                  <span>STEP</span>
                  <span>1</span>
                </div>
                <h3>Register for free</h3>
                <p>
                  Sign up and choose what kind of teacher you want to be.
                  <br />
                  Find what’s your tutoring style and what works for you.
                </p>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={FirstGraph} alt="FirstGraph" />
                  <h2>Video classes</h2>
                  <h3>
                    Video classes are a great option if you prefer recording a
                    video and then sharing it with learners. Choose a topic,
                    prepare a class and upload your video.
                    <br />
                  </h3>
                  <h3>
                    Check out our{" "}
                    <a href="/quick-start/teacher/2/video-class">
                      Quick Start Video on Creating a Video Class.
                    </a>
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={SecondGraph} alt="SecondGraph" />
                  <h2>Live tutoring phone calls</h2>
                  <h3>
                    Offer valuable tips and tricks, solve a learner’s dilemma or
                    tackle any issue with live phone calls. Speak to your
                    learners directly when it fits your schedule.
                    <br />
                  </h3>
                  <h3>
                    Check out our{" "}
                    <a href="/quick-start/teacher/3/live-tutoring-call">
                      Quick Start Video on Creating a Live Call.
                    </a>
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <img src={ThirdGraph} alt="ThirdGraph" />
                  <h2>Peer to peer tutoring lessons</h2>
                  <h3>
                    Share your knowledge and expertise in real time while
                    interacting with learners. Get to know them via 1-on-1 video
                    classes and adapt your lessons to suit their needs.
                  </h3>
                </div>
              </div>
              <div className={classes.Row}>
                <div
                  className={classes.CentredButton}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Button
                    className={classes.Button}
                    color="secondary"
                    size="large"
                    href="/quick-start"
                  >
                    Watch more Quick Start Videos
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionFive}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <div className={classes.Step}>
                  <span>STEP</span>
                  <span>2</span>
                </div>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column}>
                  <div className={classes.Text}>
                    <h2>Upload your video lessons</h2>
                    <h3>
                      Choose your expertise category, insert important
                      information your learners would want to know, explain what
                      a class is about in a video preview, and begin teaching.
                    </h3>
                  </div>
                </div>
                <div className={classes.Column}>
                  <img src={SetYourUploadGraph} alt="SetYourUploadGraph" />
                </div>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column}>
                  <img src={SetAvailabilityGraph} alt="SetAvailabilityGraph" />
                </div>
                <div className={classes.Column}>
                  <div className={classes.Text}>
                    <h2>Set availability for live tutoring</h2>
                    <h3>
                      Gain valuable experience by interacting directly with
                      learners. Organize your teaching schedule, set
                      availability on InfoDepot and start live tutoring.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionSix}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <div className={classes.Step}>
                  <span>STEP</span>
                  <span>3</span>
                </div>
                <h3>Start your online teaching and start earning</h3>
                <p>
                  Once you set up your account and choose the right teaching
                  approach,
                  <br />
                  you are ready to begin your InfoDepot online teaching career.{" "}
                </p>
                <Link to={"/pricing-plans"}>
                  <Button
                    className={classes.Button}
                    color="primary"
                    size="large"
                    //onClick={this.handleClickContestRemoveFlag}
                  >
                    START TEACHING TODAY
                  </Button>
                </Link>
              </div>
            </div>
            <div
              className={classes.ImageBackground}
              style={{ backgroundImage: "url(" + StartNowTeaching + ")" }}
            ></div>
          </div>
          <div className={classes.SectionSeven}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>
                  Be a part of a community of educators
                </h2>
              </div>
              <div className={classes.Row}>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <ReactSVG
                    src={IconCheckmark}
                    className={classes.IconCheckmark}
                  />
                  <h2>Private and safe online community</h2>
                  <h3>
                    Put a spotlight on your knowledge and teaching skills. Join
                    a community of innovative teachers where you can enjoy doing
                    a job you love feeling safe and secure.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <ReactSVG
                    src={IconCheckmark}
                    className={classes.IconCheckmark}
                  />
                  <h2>Online teaching made easy</h2>
                  <h3>
                    WIth InfoDepot, you will deliver your lessons and interact
                    with students seamlessly. Kick off your online teaching
                    career the right way.
                  </h3>
                </div>
                <div className={classes.Column} style={{ textAlign: "center" }}>
                  <ReactSVG
                    src={IconCheckmark}
                    className={classes.IconCheckmark}
                  />
                  <h2>Direct communication</h2>
                  <h3>
                    Gain relevant experience and receive valuable feedback from
                    your learning community. Use it to improve your classes and
                    become an even better teacher.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionEight}>
            <div className={classes.SectionInner}>
              <div className={classes.SectionCenter}>
                <h2 className={classes.HeadWithBefore}>
                  Frequently Asked Questions
                </h2>
                <div className={classes.Questions}>
                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"Who can teach on InfoDepot?"}
                  >
                    Anyone who has skills, knowledge or expertise to share is
                    welcomed to become an InfoDepot teacher. You can choose from
                    creating video lessons, peer to peer tutoring and live phone
                    calls to find a teaching style that suits you the most.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"How can I add video lessons?"}
                  >
                    If you opt for video lessons, once you define, plan and
                    record your bite-seized lesson (maximum size is 2GB or
                    around 30 minutes long), you can upload it for free until
                    August 31st. You will only need to include additional info
                    about it (basic info, description, learning level, etc.) and
                    set up a price. Once you complete these steps, your video
                    lesson will be ready to be published and bought by learners.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "How do I begin with peer to peer live tutoring?"
                    }
                  >
                    If on the other hand you opt for peer to peer live phone
                    calls, you will need to set availability as well as the
                    price of your phone calls. Learners will be able to contact
                    you when you are available for live consultations.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"Is there a membership or platform fee?"}
                  >
                    During our promo period, there are no platform fees.
                    Everything you earn by teaching will be yours to take. The
                    only fee you have to pay is the Paypal payout fee, and you
                    can read more about it using the page{" "}
                    <a href="/fees-info">InfoDepot fees</a>. After December
                    31st, teachers will pay 25% commission fee on each sale.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "Can I offer the same lesson for live tutoring calls and in a video lesson?"
                    }
                  >
                    Yes, you can. The only difference is that for video lessons
                    you will have to prepare lesson plans and record videos,
                    while for live tutoring calls you will talk to learners
                    directly, answering their questions and offering advice
                    about the topic in question. If you want to offer the same
                    lesson for peer to peer tutoring as well, you can do it. You
                    will be directly communicating with learners, adjusting and
                    adapting your lesson plan to suit their needs as you work.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={"How can I earn money? "}
                  >
                    InfoDepot teachers earn by selling video classes, live peer
                    to peer tutoring lessons and live tutoring calls. As a
                    teacher, you set a price per student for video lessons and
                    price per minute for live tutoring calls. In this way, you
                    are able to earn money for every sale you make.
                  </IDExpansionPanel>

                  <IDExpansionPanel
                    activeBlack
                    active={this.state.active}
                    className={classes.Panel}
                    paneltitle={
                      "Who owns the video if I hire InfoDepot to create a class or if they hire me? "
                    }
                  >
                    If InfoDepot hires you to create an original lesson or if
                    InfoDepot helps you create a video, all rights related to
                    that video and related content including, but not limited to
                    copyrights, distribution and editing will be exclusively
                    owned and controlled by InfoDepot.
                  </IDExpansionPanel>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.SectionNine}>
            <div
              className={classes.ImageBackground}
              style={{ backgroundImage: "url(" + JoinTeaching + ")" }}
            ></div>
            <div className={classes.Text}>
              <h1>
                Your expertise is valuable.
                <br />
                Your knowledge is worth sharing.
              </h1>
              <Link to={"/pricing-plans"}>
                <Button
                  className={classes.Button}
                  color="primary"
                  size="large"
                  //onClick={this.handleClickContestRemoveFlag}
                >
                  JOIN AS A TEACHER
                </Button>
              </Link>
              <div className={classes.LeftShape} style={{ pointerEvents: 'none' }}>
                <img src={LeftShape} alt="ShapeLeft" />
              </div>
              <div className={classes.RightShape}>
                <img src={RightShape} alt="ShapeLeft" />
              </div>
              <div className={classes.LeftMiddleShape}>
                <img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
              </div>
              <div className={classes.RightMiddleShape}>
                <img src={RightMiddleShape} alt="ShapeRightMiddle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeacherPage;
