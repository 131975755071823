export default {
	APP_ID: process.env.REACT_APP_BACKEND_ID,
	APP_SECRET: process.env.REACT_APP_BACKEND_SECRET_CODE,
	DOMAIN: process.env.REACT_APP_DOMAIN,
	DOMAIN_IMAGE: process.env.REACT_APP_DOMAIN_IMAGE,
	API_ENDPOINT: process.env.REACT_APP_BACKEND_API_ENDPOINT,
	STRIPE_ID: process.env.REACT_APP_PAYMENT_STRIPE_CLIENT_ID,
	PAYPAL_ID: process.env.REACT_APP_PAYMENT_PAYPAL_CLIENT_ID,
	GOOGLE_ANALYTICS_API_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY,
	FACEBOOK_PIXEL_CODE: process.env.REACT_APP_FACEBOOK_PIXEL_CODE,
	PINTEREST_TAG: process.env.REACT_APP_PINTEREST_TAG,
	HOTJAR_HJID: process.env.REACT_APP_HOTJAR_HJID,
	HOTJAR_HJSV: process.env.REACT_APP_HOTJAR_HJSV
};
