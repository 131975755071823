import React from 'react';
import ReactSVG from 'react-svg';

import classes from './RatePicker.module.scss';
import IconStarFull from '../../../assets/images/icons/star_full_icon.svg';
import IconStarEmpty from '../../../assets/images/icons/star_empty_icon.svg';

class RatePicker extends React.PureComponent {
	state = {
		rate: 0,
		hoverIndex: null,
		prevHover: null
  };

	onEnter = (i) => {
		// In case of hovering from one star to another we need to remeber previous hower to reduce it's index when adding delay class.
		// Elsewhere it would use the class based on the its position index and animation delays would be too long.
		// As there would be no way to know which stars before it where already animated.
		const prevHover = this.state.hoverIndex;
		this.setState({ hoverIndex: i, prevHover: prevHover });
	}
	onExit = () => {
		this.setState({ hoverIndex: null, prevHover: null });
	}
	onClick = (i) => {
		this.props.onRateChange(i);
		this.setState({ rate: i, hoverIndex: null, prevHover: null });
	}

	render() {
		const { hoverIndex, prevHover, rate } = this.state;
		const Star = (src, i) => {
			const animationClass = () => {
				if (hoverIndex) {
					return i <= hoverIndex
						? classes['Star--jump--' + (i > prevHover ? i - Number(prevHover) : i)]
						: '';
				} else {
					return i <= rate ? classes['Star--shake'] : '';
				}
			};
			return (
				<ReactSVG
					key={i}
					src={src}
					className={[ classes.Star, animationClass() ].join(' ')}
					onMouseEnter={() => this.onEnter(i)}
					onMouseLeave={() => this.onExit()}
					onClick={() => this.onClick(i)}
				/>
			);
		};

		let starsGroup = () => {
			let stars = [];
			for (var i = 1; i < 6; i++) {
				stars.push(
					Star(i <= (hoverIndex ? hoverIndex : rate) ? IconStarFull : IconStarEmpty, i)
				);
			}
			return stars;
		};

		return (
			<div className={[ classes.Container, this.props.className ].join(' ')}>
				{starsGroup()}
			</div>
		);
	}
}

export default RatePicker;
