import React, { Component } from 'react';
import { connect } from 'react-redux';
import windowWidth from 'react-window-size';
import { Divider } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';

import AsidePortal from '../../components/Layout/Aside/AsidePortal';
import classes from './MyAccount.module.scss';
import Button from '../../components/UI/Button/Button';
import PhotoUpload from '../../components/UI/PhotoUpload/PhotoUpload';
import Tabs from '../../components/UI/Tabs/Tabs';
import MyVideos from './ClassesList/MyVideos/MyVideos';
import ReactSVG from 'react-svg';
import IconPaypal from './../../assets/images/icons/paypal_icon.svg';
import Calls from './ClassesList/Calls/Calls';
import QueuedCalls from './ClassesList/Calls/QueuedCalls';
import CustomSelect from '../../components/UI/Select/Select';
import ExpansionPanel from '../../components/UI/ExpansionPanel/ExpansionPanel';
import Following from "./Following/Following";
import { ChosenDisplayName, shouldDisplayUsernameSection } from '../../components/UI/ChosenDisplayName/ChosenDisplayName';


const tabProperties = {
	videos: {
		value: 'videos',
		label: 'Video Library',
		content: <MyVideos />
	},
	calls: {
		value: 'calls',
		label: 'Live Tutoring Calls',
		url: '/account/calls/video',
		content: <Calls />
	},
	'queued-calls': {
		value: 'queued-calls',
		label: 'Queued Calls',
		url: '/account/queued-calls/video',
		content: <QueuedCalls />
	},
	'following': {
		value: 'following',
		label: 'Following',
		url: '/account/following',
		content: <Following />
	}
};

const expansionPanelStyles = {
	panelSummaryStyle: {
		minHeight: '20px',
		width: '180px',
    margin: '0 auto'
	},
	panelHeadlineStyle: {
		fontSize: '13px'
	},
	panelDetailsStyle: {
		padding: '20px 0 0 0'
	},
}

class MyAccount extends Component {
	state = {
		activeTab: null
	};

	

	componentDidMount() {
		this.setActiveTab();
		// there is some visual bug with the tabs. We need to reset the active tab for indicator to pick up the right positon and the width
		setTimeout(()=> this.setActiveTab(), 10);
	}

	async setActiveTab() {
		const settingTypeParam = this.props.match.params.tab;
		// check if it is a valid type comming from the url if it isn't route to first option
		const typeNameList = Object.keys(tabProperties);

		if (typeNameList.includes(settingTypeParam)) {
			this.setState({ activeTab: settingTypeParam });
		} else {
			const url = '/account/' + typeNameList[0];
			this.props.history.replace(url);
		}
	}

	tabConfig = Object.keys(tabProperties).map((key) => tabProperties[key]);

	onClickEdit() {
		this.props.history.push('/account/settings');
	}

	onClickDashboard() {
		this.props.history.push('/account/dashboard');
	}

	handleTabChange = (event, value) => {
		this.setState({ activeTab: value });
		this.props.history.push('/account/' + value);
		console.log(value)
	};

	handleOptionChange = (event, value) => {
		this.setState({ activeTab: event.target.value });
		this.props.history.push('/account/' + value);
		console.log('Option change', value)
	};

	render() {
		const screenWidth = this.props.windowWidth;
		
		const { activeTab } = this.state;
		const isTeacher = this.props.user.type === 'teacher';

		const teacherInfo = isTeacher ? (
			<React.Fragment>
				<section className={classes.Section}>
					<span className={classes.SectionTitles}>Intro</span>
					<p>{this.props.user.profile.introAboutMe}</p>

					<span className={classes.SectionSubtitles}>Area of expertise</span>
					{this.props.user.areaOfExpertises.map((areaOfExpertise, i) =>
						<p key={i}>{areaOfExpertise.name}</p>
					)}
					<span className={classes.SectionSubtitles}>Expertise based on</span>
					{this.props.user.expertisesBasedOn.map((expertisesBasedOn, i) =>
						 <p key={i}>{expertisesBasedOn.name}</p>
					)}
				</section>
				<Divider style={{ width: '100%', marginBottom: '20px' }} />
			</React.Fragment>
		) : null;

		const address = <React.Fragment>
			<section className={classes.Section}>
					<span className={classes.SectionTitles}>Billing address</span>
					{this.props.user.profile.street ||
					this.props.user.profile.city ||
					this.props.user.profile.fullCountryName ? (
						<React.Fragment>
							<span className={classes.FilledValText}>
								{this.props.user.profile.street}
							</span>
							<br />
							<span className={classes.FilledValText}>
								{this.props.user.profile.city}
							</span>
							<br />
							<span className={classes.FilledValText}>
								{this.props.user.profile.zipCode}
							</span>
							<br />
							<span className={classes.FilledValText}>
								{this.props.user.profile.fullCountryName}
							</span>
						</React.Fragment>
					) : (
						<span className={classes.EmptyValText}>No information yet.</span>
					)}
				</section>
				<Divider style={{ width: '100%', marginBottom: '20px' }} />
		</React.Fragment>;

		const phone = <React.Fragment>
			<section className={classes.Section}>
					<span className={classes.SectionTitles}>Phone number</span>
					{this.props.user.profile.phoneNumber ? (
						<span className={classes.FilledValText}>
							{this.props.user.profile.phoneNumber}
						</span>
					) : (
						<span className={classes.EmptyValText}>No information yet.</span>
					)}
				</section>
				<Divider style={{ width: '100%', marginBottom: '20px' }} />
		</React.Fragment>;

		const payment = <React.Fragment>
			<section className={classes.Section}>
					{isTeacher ? (
						<div>
							<span className={classes.SectionTitles}>Payout information</span>
							{this.props.user.profile.payPalAccount ? (
								<div className={classes.FilledValText}>
									<ReactSVG src={IconPaypal} className={classes.IconPaypal} />
									{this.props.user.profile.payPalAccount}
								</div>
							) : (
								<span className={classes.EmptyValText}>No information yet.</span>
							)}
						</div>
					) : null}
				</section>
		</React.Fragment>



		const teacherDashboard = isTeacher ? (
			<React.Fragment>
				<section className={classes.Panel}>
					<p className={classes.SectionSubtitles}>
						To add a video class, set up a live call, and manage your listings
					</p>
					<Button
						onClick={(e) => this.onClickDashboard(e)}
						className={classes.ButtonSettingsDashboard}
						color="secondary">
						Go to Teacher Dashboard
					</Button>
				</section>
			</React.Fragment>
		) : null;

		const countryState = this.props.user.profile.fullStateName
			? ', ' + this.props.user.profile.fullStateName
			: '';

		return (
			<div className={classes.Container}>
				<div>
					
					<AsidePortal className={classes.Aside} >
						{/* picture uploader*/}
						<PhotoUpload />
						{/* name */}
						{/* <h4 className={classes.Name}>{this.props.user.profile.name}</h4>
						<span>{this.props.user.username}</span> */}
						<h4 className={classes.Name} style={{ marginBottom: shouldDisplayUsernameSection(this.props.user) ? '' : '20px' }}><ChosenDisplayName user={this.props.user} top="-2px" /></h4>
						{shouldDisplayUsernameSection(this.props.user) && <span>{this.props.user.username}</span>}

						<Button
							onClick={(e) => this.onClickEdit(e)}
							className={classes.ButtonSettings}
							fullWidth>
							<Settings style={{ fontSize: '17px', marginRight: '2px' }} /> Edit my account
						</Button>
						<Divider style={{ width: '100%', marginBottom: '20px' }} />
						
						{screenWidth < 768 ? <div>
							<ExpansionPanel paneltitle={'Show more'} styles={expansionPanelStyles} className={classes.ShowMorePanel}>
								{/* intro */}
							{teacherInfo}
							
							{/* address */}
							{address}

							{/* phone */}
							{phone}

							{/* payment */}
							{payment}
						</ExpansionPanel>
						</div> : <React.Fragment>
							{/* intro */}
							{teacherInfo}
							
							{/* address */}
							{address}

							{/* phone */}
							{phone}

							{/* payment */}
							{payment}
						</React.Fragment>}
					
					
					</AsidePortal>
				</div>
			
				<article className={classes.Main}>
					{screenWidth > 600 ? <h1 className={classes.Headline}>My Account</h1> : ''}
					{teacherDashboard}
					{/* Tabs */}
					{screenWidth > 1024 ? <Tabs
						activetab={activeTab}
						config={this.tabConfig}
						onChangeTab={(e, val) => this.handleTabChange(e, val)}
					/> : <div 
								className={classes.SelectContainer}
								>
								<CustomSelect 
									activetab={activeTab} 
									config={this.tabConfig}
									onChangeOption={(e, val) => this.handleOptionChange(e, val)}
								/>
					</div> }
				</article>
		</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps)(windowWidth(MyAccount));
