import React from "react";
import Avatar from "../UI/Avatar/Avatar";
import "./Messages.scss";
import Moment from "react-moment";
import IconFile from "../../assets/images/icons/documentTypes/file_icon.svg";
import { ChosenDisplayName } from "../UI/ChosenDisplayName/ChosenDisplayName";

const MessageChat = ({
  key,
  message,
  isAuthor,
  apiConfig,
  openImageModal,
  fileDownload,
  mesRef,
}) => {
  return (
    <div
      key={key}
      // ref={mesRef}
      className={`c-message-chat ${
        isAuthor ? "c-message-chat--sender" : "c-message-chat--receiver"
      }`}
    >
      {!isAuthor && (
        <Avatar
          src={message.author.profile.photo && message.author.profile.photo.big}
          className={Avatar}
          style={{ marginRight: "10px" }}
          size="32"
        />
      )}
      <div className="c-message-chat-body">
        {message.body && (
          <div className="c-message-chat__bubble">
            <div>
              <p style={{ maxWidth: "500px", whiteSpace: "pre-line" }}>
                {message.body}
              </p>
            </div>
          </div>
        )}
        {message.attachments.length > 0
          ? message.attachments.map((attachment, i) => {
              return attachment.image ? (
                <div
                  className="imageContainer"
                  onClick={() =>
                    openImageModal(
                      apiConfig.DOMAIN_IMAGE + attachment.image.reference
                    )
                  }
                  key={i}
                >
                  <div className="overlay"></div>
                  <img
                    width="150"
                    style={{
                      borderRadius: "8px",
                      marginTop: "0",
                      marginBottom: "0",
                    }}
                    src={apiConfig.DOMAIN_IMAGE + attachment.image.reference}
                    alt=""
                  />
                </div>
              ) : (
                <div
                  className="c-message-chat-fileChat"
                  style={{ marginTop: "10px" }}
                  onClick={() =>
                    fileDownload(attachment.id, attachment.file.fileName)
                  }
                >
                  {/* <IconFile /> */}
                  <img
                    src={IconFile}
                    alt="File icon"
                    style={{ width: "15px", margin: "0 7px 0 0" }}
                  />
                  <span style={{ fontWeight: "500" }}>
                    {attachment.file.fileName}
                  </span>
                </div>
              );
            })
          : null}
        <div className="info">
          {/* {!isAuthor && message.author.profile.firstName + ` • `} */}
          {!isAuthor && <ChosenDisplayName user={message.author} />}
          {!isAuthor && ` • `}
          {""}
          <Moment format="DD MMM YYYY, HH:mm">{message.createDate}</Moment>
        </div>
      </div>
    </div>
  );
};

export default MessageChat;
