import React from "react";
import Grid from "@material-ui/core/Grid";

import Avatar from "../Avatar/Avatar";
import classes from "./List.module.scss";
import Button from "../Button/Button";
import { Link } from 'react-router-dom';
import { ChosenDisplayName, shouldDisplayUsernameSection } from "../ChosenDisplayName/ChosenDisplayName";

//Student following
const followingList = (props) => {
  const listItemRender = (props, following) => {
    return (
      <Grid item xs={12} md={6}>
        <div className={[classes.Card].join(" ")}>
          <Avatar
            src={
              following.teacher.profile.photo &&
              following.teacher.profile.photo.big
            }
            size="63"
          />
          <div
            className={[classes.content]}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              {/* <strong><Link to={`/profile/${following.teacher.profileUrl}`}>{following.teacher.fullName}</Link></strong>
              <p>{following.teacher.username}</p> */}
              <strong>
                {following.teacher.profileUrl ? (
                  <Link to={`/profile/${following.teacher.profileUrl}`}>
                    <ChosenDisplayName user={following.teacher} top="-1px" />
                  </Link>
                ) : (
                  <ChosenDisplayName user={following.teacher} top="-1px" />
                )}
              </strong>
            {following.teacher && shouldDisplayUsernameSection(following.teacher) && <p>{following.teacher.username}</p>}
            </div>
            {(!props.watchPublicProfile || props.watchOwnProfile) && <Button
              className={classes.ButtonDark}
              type="outline"
              size="small"
              color="gray"
              loading={
                props.buttonSpinner && props.buttonSpinner === following.id
              }
              onClick={() => props.deleteFollowing(following.id)}
            >
              Unfollow
            </Button>
            }
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <>
      {props.data &&
        props.data.map((following, i) => (
          <React.Fragment key={i}>
            {listItemRender(props, following)}
          </React.Fragment>
        ))}
    </>
  );
};

//Teacher followers
const followersList = (props) => {
  const listItemRender = (props, follower) => {
    return (
      <Grid item xs={12} md={6}>
        <div className={[classes.Card].join(" ")}>
          <Avatar
            src={
              follower.follower.profile.photo &&
              follower.follower.profile.photo.big
            }
            size="63"
          />
          <div className={[classes.content]}>
            {/* <strong>{follower.follower.profileUrl ? <Link to={`/profile/${follower.follower.profileUrl}`}>{follower.follower.fullName}</Link> : follower.follower.fullName}</strong>
            <p>{follower.follower.username}</p> */}
            <strong>
              {follower.follower.profileUrl ? (
                <Link to={`/profile/${follower.follower.profileUrl}`}>
                  <ChosenDisplayName user={follower.follower} top="-1px" />
                </Link>
              ) : (
                <ChosenDisplayName user={follower.follower} top="-1px" />
              )}
            </strong>
            {follower.follower && shouldDisplayUsernameSection(follower.follower) && <p>{follower.follower.username}</p>}
          </div>
          {(!props.watchPublicProfile || props.watchOwnProfile) && <Button
              className={classes.ButtonDark}
              type="outline"
              size="small"
              color="gray"
              loading={
                props.buttonSpinner && props.buttonSpinner === follower.id
              }
              onClick={() => props.deleteFollower(follower.id)}
          >
            Remove
          </Button>
          }
        </div>
      </Grid>
    );
  };

  return (
    <>
      {props.data &&
        props.data.map((follower, i) => (
          <React.Fragment key={i}>
            {listItemRender(props, follower)}
          </React.Fragment>
        ))}
    </>
  );
};

const List = (props) => {
  switch (props.typeList) {
    case "following":
      return followingList(props);
    case "followers":
      return followersList(props);
    default:
      return null;
  }
};

export default List;
