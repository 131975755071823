import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import windowSize from 'react-window-size'

import * as screen from '../../utils/common'
import classes from './TransactionHistory.module.scss'
import IconCredits from '../../assets/images/icons/credits_icon.svg'
import IconDollars from '../../assets/images/icons/dollar_icon.svg'
import * as paymentService from '../../services/payment/paymentService'
import NativeSelect from '@material-ui/core/NativeSelect'
import IDTable from '../../components/UI/Table/Table'
import { CircularProgress } from '@material-ui/core'
import Pagination from '../../components/UI/Pagination/Pagination'

const transactionTypes = () => {
  return {
    purchases: {
      label: 'My Purchases',
      url: '/account/transaction-history/purchases',
      icon: IconCredits,
      student: true,
      teacher: true
    },
    orders: {
      label: 'My Top-Ups',
      url: '/account/transaction-history/orders',
      icon: IconDollars,
      student: true,
      teacher: true
    },
    sales: {
      label: 'My Sales',
      url: '/account/transaction-history/sales',
      icon: IconCredits,
      student: false,
      teacher: true
    },
    payouts: {
      label: 'My Payouts',
      url: '/account/transaction-history/payouts',
      icon: IconDollars,
      student: false,
      teacher: true
    }
  }
}

const monthNames = [
  {
    id: '01',
    name: 'January'
  },
  {
    id: '02',
    name: 'February'
  },
  {
    id: '03',
    name: 'March'
  },
  {
    id: '04',
    name: 'April'
  },
  {
    id: '05',
    name: 'May'
  },
  {
    id: '06',
    name: 'June'
  },
  {
    id: '07',
    name: 'July'
  },
  {
    id: '08',
    name: 'August'
  },
  {
    id: '09',
    name: 'September'
  },
  {
    id: '10',
    name: 'October'
  },
  {
    id: '11',
    name: 'November'
  },
  {
    id: '12',
    name: 'December'
  }
]
let date = new Date()

const currentYear = date.getFullYear()

const parsedMonth = date.getMonth()

const currentMonth = parseInt(parsedMonth) + 1;
//['0', parseInt(parsedMonth) + 1].join('')

// const paginationConf = {
//   pageLimit: 5,
//   total: null
// }

class TransactionHistory extends Component {
  state = {
    open: false,
    active: true,
    activeTransaction: 'purchases',
    month: currentMonth,
    year: currentYear,
    filterDate: currentYear + '-' + currentMonth,
    transactionData: null,
    sumData: null,
    loading: false,
    screenHeight: null,
    paginationConf: {
      pageLimit: 20,
      total: null
    }
  }

  // Filter by Month and year

  getDropListMonth = () => {
    let date = new Date()
    const parsedMonth = date.getMonth()

    const currentMonth = parseInt(parsedMonth) + 1;

    const selected = currentMonth === this.state.month ? 'selected' : ''
  
    return monthNames.map(monthName => (
      <option
        key={monthName.id}
        selected={monthName.id == currentMonth}
        value={monthName.id}
      >
        {monthName.name}
      </option>
    ))
  }

  getDropListYear = () => {
    const year = new Date().getFullYear();
    const startYear = 2018;
    let diffYears = year - startYear;

    return Array.from(new Array(diffYears + 1), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ))

  }

  componentDidMount () {

    this.getTransactions()
    this.setState({ screenHeight: screen.height() })
    this.setActiveTab()
  }

  getTransactions = (offset = 0) => {
    paymentService
      .getTransactions(
        this.props.token,
        this.props.match.params.type,
        `?filterDate=${this.state.filterDate}&limit=${
          this.state.paginationConf.pageLimit
        }&offset=${offset}`
      )
      .then(response => {
        const transactionData = response.data.data
        const transactionSum = response.data.meta
        this.state.paginationConf.total =
          response.data.meta.total
       // console.log('response', response.data)
        this.setState({
          transactionData: transactionData,
          sumData: transactionSum,
          filterDate: currentYear + '-' + currentMonth,
          activeTransaction: this.props.match.params.type
        })
        this.setActiveTab(this.state.activeTransaction, this.state.filterDate)
      })
      .catch(error => {
        console.log(error)
      })
  }

  onMonthChangeHandler = month => {
    let name = this.state.activeTransaction
    let filterDate = this.state.year + '-' + month.target.value
    let offset = 0
    this.setState({
      month: month.target.value,
      year: this.state.year,
      filterDate, 
      total: this.state.paginationConf.total
    })

    this.getTransactionsByMonthAndYear(name, filterDate, `?filterDate=${filterDate}&limit=${
      this.state.paginationConf.pageLimit
    }&offset=${offset}`)
  }

  onYearChangeHandler = year => {
    let name = this.state.activeTransaction
    let filterDate = year.target.value + '-' + this.state.month
    let offset = 0
    this.setState({
      month: this.state.month,
      year: year.target.value,
      filterDate,
      total: this.state.paginationConf.total
    })
    this.getTransactionsByMonthAndYear(name, filterDate, `?filterDate=${filterDate}&limit=${
      this.state.paginationConf.pageLimit
    }&offset=${offset}`)
  }

  getTransactionsByMonthAndYear = (name, filterDate, offset = 0) => {
    let tabChange = name || this.props.match.params.type
    // let offset = 0
    
    let query = `?filterDate=${filterDate}&limit=${
      this.state.paginationConf.pageLimit
    }&offset=${offset}`
    paymentService
      .getTransactions(this.props.token, tabChange, query)
      .then(response => {
        // const filterResults = response.data;
        const transactionResults = response.data.data
        const transactionSum = response.data.meta
        //console.log(filterDate,transactionResults,this.props.match.params.type);
        this.state.paginationConf.total =
          response.data.meta === undefined ? null : response.data.meta.total
        //console.log('Sum', transactionSum)
        this.setState({
          transactionData: transactionResults,
          sumData: transactionSum,
          activeTransaction: this.props.match.params.type
        })
      })
      .catch(error => {
        console.log(error) //error
      })
  }

  handlePaginationchange = pag => {
    let name = this.state.activeTransaction;
    let filterDate = this.state.filterDate;
   // console.log(pag)
    // this.getTransactions();
    this.getTransactionsByMonthAndYear(name, filterDate, (pag.currentPage - 1) * pag.pageLimit)
  }

  setActiveTab () {
    const transactionTypeParam = this.props.match.params.type

    const activeTransaction = this.state.activeTransaction
    // check if it is a valid type comming from the url if it isn't route to first option
    const typeNameList = Object.keys(transactionTypes())

    if (typeNameList.includes(transactionTypeParam)) {
      this.setState({ activeTransaction: transactionTypeParam })
    } else {
      const url = '/account/transaction-history/' + activeTransaction
      this.props.history.replace(url)
      let filterDate = this.state.filterDate
      let offset = 0
      this.getTransactionsByMonthAndYear(this.state.activeTransaction, filterDate)
    }
  }

  onTransactionClick = name => {
    // let name = this.state.activeTransaction;
    let filterDate = this.state.filterDate
    let offset = 0
    let query = `?filterDate=${filterDate}&limit=${
      this.state.paginationConf.pageLimit
    }&offset=${offset}`
    this.setActiveTab(name, filterDate, query)
    this.getTransactionsByMonthAndYear(name, filterDate, query)
  }

  render () {
    const screenWidth = this.props.windowWidth

    const isTeacher = this.props.userData.type === 'teacher'

    const activeTransactionTab = this.state.activeTransaction

    //console.log(this.state.transactionData)

    const isActiveTab = name =>
      activeTransactionTab === name ? classes.ActiveTab : ''

    const renderNav = () => {
      const typeNames = Object.keys(transactionTypes())
      const typeConf = transactionTypes()

      const filteredTypeNames = isTeacher
        ? typeNames
        : typeNames.filter(function (tab) {
          return tab !== 'sales' && tab !== 'payouts'
        })

      return filteredTypeNames.map((name, index) => (
        <Link
          key={name}
          to={typeConf[name].url}
          className={isActiveTab(name)}
          onClick={() => this.onTransactionClick(name)}
        >
          {/* <ReactSVG svgClassName={classes.NavIcon} src={typeConf[name].icon} /> */}
          <span>{typeConf[name].label}</span>
        </Link>
      ))
    }

    //console.log('Pagination', this.state.paginationConf.total)


    const total = this.state.paginationConf && this.state.paginationConf.total;

    return (
      <div className={classes.TransactionWrap}>
        <div className={classes.Container}>
          <h1>Transaction History</h1>
        </div>
        <div className={classes.NavSettings}>{renderNav()}</div>
        <div className={classes.Wrapper}>
          <div className={[classes.Filter, classes.Column].join(' ')}>
            <div>
              <div className={classes.Wrapper}>
                <div className={classes.Selects}>
                  <NativeSelect
                    disableUnderline
                    className={classes.Select}
                    onChange={this.onMonthChangeHandler}
                  >
                    {this.getDropListMonth()}
                  </NativeSelect>
                  <NativeSelect
                    disableUnderline
                    className={classes.Select}
                    onChange={this.onYearChangeHandler}
                  >
                    {this.getDropListYear()}
                  </NativeSelect>
                </div>
                <div className={[classes.Amount, classes.Column].join(' ')}>
                  {activeTransactionTab === 'purchases' ? (
                    <span>
                      TOTAL PRICE:{' '}
                      <span className={classes.Sum}>
                        $
                        {this.state.sumData && this.state.sumData.sumPrice > 0
                          ? this.state.sumData.sumPrice.toFixed(2)
                          : '0.00'}
                      </span>
                    </span>
                  ) : null}
                  {activeTransactionTab === 'orders' ? (
                    <span>
                      TOTAL AMOUNT:{' '}
                      <span className={classes.Sum}>
                        $
                        {this.state.sumData && this.state.sumData.sumAmount > 0
                          ? this.state.sumData.sumAmount.toFixed(2)
                          : '0.00'}
                      </span>
                    </span>
                  ) : null}
                  {activeTransactionTab === 'sales' ? (
                    <span>
                      TOTAL EARNING:{' '}
                      <span className={classes.Sum}>
                        $
                        {this.state.sumData && this.state.sumData.sumEarning > 0
                          ? this.state.sumData.sumEarning.toFixed(2)
                          : '0.00'}
                      </span>
                    </span>
                  ) : null}
                  {activeTransactionTab === 'payouts' ? (
                    <span>
                      TOTAL AMOUNT:{' '}
                      <span className={classes.Sum}>
                        $
                        {this.state.sumData && this.state.sumData.sumAmount > 0
                          ? this.state.sumData.sumAmount.toFixed(2)
                          : '0.00'}
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className={classes.TableWrap}>
             
                <React.Fragment>
                  <IDTable
                    data={this.state.transactionData}
                    meta={this.state.sumData}
                    name={this.state.activeTransaction}
                    user={this.props.userData.type}
                    loading={this.state.loading}
                    screen={screenWidth}
                    screenHeight={this.state.screenHeight}
                    typeTable='transactionsTable'
                  />
                  <div>
                    {total && total > 1 ? (
                      <Pagination
                        style={{ marginTop: '40px' }}
                        totalRecords={this.state.paginationConf.total}
                        pageLimit={this.state.paginationConf.pageLimit}
                        pageNeighbours={1}
                        onPageChanged={this.handlePaginationchange}
                      />
                    ) : null}
                  </div>
                </React.Fragment>
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userData: state.user,
    token: state.auth.accessToken
  }
}

export default withRouter(
  connect(mapStateToProps)(windowSize(TransactionHistory))
)
