import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactSVG from 'react-svg'
import windowWidth from 'react-window-size'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withRouter, Link } from 'react-router-dom'

import classes from './CallsOverviewListing.module.scss'
import * as screen from './../../../../utils/common.js'
import * as liveCallService from './../../../../services/liveCall/liveCallService'
import Tabs from './../../../../components/UI/Tabs/Tabs';
import IDTable from './../../../../components/UI/Table/Table'
import TabsBubble from './../../../../components/UI/Tabs/TabsBubble/TabsBubble';
import IconLiveCall from '../../../../assets/images/icons/live_call_icon.svg';
import IconVideo from '../../../../assets/images/icons/video-solid.svg';
import IconVideoBasic from '../../../../assets/images/icons/video-solid.svg';
import Pagination from './../../../../components/UI/Pagination/Pagination'

const paginationConf = {
  pageLimit: 20,
  total: null
}

class CallsOverviewListing extends Component {
  state = {
    callsData: null,
    callsDataVideo: null,
    loading: true,
    activeCallType: '',
    activeTab: ''
  }

  componentDidMount () {
    this.setActiveTab();

    const href = this.props.location.pathname;

    if(href == '/account/dashboard/calls-overview/video') {
      this.getLiveCallsVideo();
    } else if(href == '/account/dashboard/calls-overview/phone') {
      this.getLiveCalls();
    }
  }

  setActiveTab = () => {
    const href = this.props.location.pathname;
    let callType = this.props.match.params.type;

    if(href == '/account/dashboard/calls-overview/video') {
        callType = 'video';
    } else if(href == '/account/dashboard/calls-overview/phone') {
      callType = 'phone';
    }

    const activeTab = this.state.activeTab
    // check if it is a valid type comming from the url if it isn't route to first option
    const tabsListNames = ['phone','video'];

    if (tabsListNames.includes(callType)) {
      this.setState({ activeTab: callType })
    } else {
      const url = '/account/dashboard/calls-overview/' + activeTab
      this.props.history.replace(url)
    }
  }

  getLiveCalls = (offset = 0) => {
    liveCallService
      .getTeacherLiveCalls(
        this.props.token,
        `?limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then(response => {
       // paginationConf.total = response.data[0]._total
        paginationConf.total = response.data[0] ? response.data[0]._total : '0'
        // response.data !== undefined ? response.data[0]._total : null
        this.setState({ loading: false, callsData: response.data })
        console.log('data', response.data);
      })
      .catch(error => {
        console.log(error.data)
      })
  }

  getLiveCallsVideo = (offset = 0) => {
    liveCallService
      .getTeacherLiveCallsVideo(
        this.props.token,
        `?limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then(response => {
       // paginationConf.total = response.data[0]._total
        paginationConf.total = response.data[0] ? response.data[0]._total : '0'
        // response.data !== undefined ? response.data[0]._total : null
        this.setState({ loading: false, callsDataVideo: response.data })
        console.log('data', response.data);
      })
      .catch(error => {
        console.log(error.data)
      })
  }

  handlePaginationchange = pag => {
    //console.log(pag)
    if (this.state.activeTab === 'phone') {
      this.getLiveCalls((pag.currentPage - 1) * pag.pageLimit);
    } else if(this.state.activeTab === 'video') {
      this.getLiveCallsVideo((pag.currentPage - 1) * pag.pageLimit);
    }
  }

  onTypeClick = name => {
    this.setActiveTab(name);
   // this.getTransactionsByMonthAndYear(name, filterDate, query)
  }

  handleTabChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + value);
		this.setState({ activeTab: value });
		paginationConf.total = 0;
		if (value === 'phone') {
            this.getLiveCalls();
        } else if (value === 'video') {
            this.getLiveCallsVideo();
        }
	};

	handleOptionChange = (event, value) => {
		const cleanUrl = this.props.match.url;
		this.props.history.push(cleanUrl.substring(0, cleanUrl.lastIndexOf('/' + 1)) + event.target.value);
		this.setState({ activeTab: event.target.value });
	};


  render () {
    const {
      callsData,
      callsDataVideo,
      loading,
			activeTab
    } = this.state;
    
    const screenWidth = this.props.windowWidth
    const calls = this.state.callsData
    const videoCalls = this.state.callsDataVideo
    const loaderRender = () => <CircularProgress className={classes.Loader} />

    const callsEmptyRender = () => {

      if(this.state.activeTab == 'video') {
        return (
          <div className={classes.SectionEmpty}>
            <ReactSVG svgClassName={classes.IconLiveCall} src={IconVideo} />
            <h2 className={classes.SectionHeadline}>
              You haven't received any video calls yet.
            </h2>
          </div>
        )
      } else {
        return (
          <div className={classes.SectionEmpty}>
            <ReactSVG svgClassName={classes.IconLiveCall} src={IconLiveCall} />
            <h2 className={classes.SectionHeadline}>
              You haven't received any phone calls yet.
            </h2>
          </div>
        )
      }
     
    }


    const tabProperties = [
			{
				value: 'video',
				label: 'Video Calls',
				content: <React.Fragment>
        {this.state.loading ? (
            loaderRender()
          ) : videoCalls && videoCalls.length > 0 ? (
            <IDTable
              data={callsDataVideo}
              user={this.props.user}
              typeTable='callsTableTeacher'
              screen={screenWidth}
            />
          ) : (
            callsEmptyRender()
          )}
          <div>
            {paginationConf.total ? (
              <Pagination
                style={{ marginTop: '40px' }}
                totalRecords={paginationConf.total}
                pageLimit={paginationConf.pageLimit}
                pageNeighbours={1}
                onPageChanged={this.handlePaginationchange}
              />
            ) : null}
          </div>
        </React.Fragment>
      },
      {
				value: 'phone',
				label: 'Phone Calls',
				content:  <React.Fragment>
        {this.state.loading ? (
            loaderRender()
          ) : calls && calls.length > 0 ? (
            <IDTable
              data={callsData}
              user={this.props.user}
              typeTable='callsTableTeacher'
              screen={screenWidth}
            />
          ) : (
            callsEmptyRender()
          )}
          <div>
            {paginationConf.total ? (
              <Pagination
                style={{ marginTop: '40px' }}
                totalRecords={paginationConf.total}
                pageLimit={paginationConf.pageLimit}
                pageNeighbours={1}
                onPageChanged={this.handlePaginationchange}
              />
            ) : null}
          </div>
        </React.Fragment>
			}
		];

    return (
      <div className={classes.Container}>
        {screenWidth > 768 ? (
          <h1 className={classes.SectionHeadline}>Calls Overview</h1>
        ) : (
          ''
        )}

          <div className={classes.Wrapper}>
						{/* tabs navigation */}
						{/* <TabsBubble
							className={classes.Tabs}
							config={tabsList()}
							activeTab={this.state.activeTab}
							//onTabClick={this.handleTabClick}
            /> */}
					</div>
        <div className={classes.TableWrap}>
        <Tabs
								className={classes.Tabs}
								activetab={activeTab}
								config={tabProperties}
								onChangeTab={(e, val) => this.handleTabChange(e, val)}
							/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth.accessToken
  }
}

export default withRouter(
  connect(mapStateToProps)(windowWidth(CallsOverviewListing))
)
