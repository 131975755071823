import React from 'react';
import ReactSVG from 'react-svg';

import classes from './RateStars.module.scss';
import IconStarFull from '../../../assets/images/icons/star_full_icon.svg';
import IconStarHalf from '../../../assets/images/icons/star_half_icon.svg';
import IconStarEmpty from '../../../assets/images/icons/star_empty_icon.svg';
import { checkPropTypes } from 'prop-types';

const round = (value, step) => {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
};

class RateStars extends React.PureComponent {

  render(){
    let classSize;
    switch(this.props.size){
      case 'large' :
        classSize= classes['StarIcon--large'];
        break;
        default:
        classSize = null;
    }
    const stars = () => {
      const _stars = [];
      const rate = round(Number(this.props.rate), 0.5);
      const remainder = rate % 1;
      let remainderAdded = false;
      for (var i = 1; i<6; i++){
        var starSrc;
        if(i <= rate) {
          starSrc = IconStarFull;
        }
        else if (remainder && !remainderAdded){
          remainderAdded = true;
          starSrc = IconStarHalf;
        }
        else{
          starSrc = IconStarEmpty;
        }
        _stars.push(<ReactSVG key={i}  src={starSrc} className={[classes.StarIcon, classSize].join(' ')}/>)
      }
  
      return _stars;
    }
    return (
      <div className={[classes.Container, this.props.className].join(' ')}>
        {stars()}
      </div>
    );
  }
};

export default RateStars;