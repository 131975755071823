import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Aside.module.scss';

// anything passed to this component is appearing in the Aside componenet defined in the Layout component
class AsidePortal extends React.Component {
	// component should be rendered on children number change therefore added to state
	state = {
		children: null
	};

	componentDidMount() {
		this.containerEl = document.querySelector('#aside-root');
		this.setState({ children: this.props.children });

		// if there are children in the props then we are passing them to the <aside> and adding/passing classes
		// adding the default class and the class passed from this component to the portal destination container
		if (this.containerEl && this.props.children) {
			const passedClass = this.props.className ? this.props.className : null;
			this.containerEl.classList.add(classes.Aside, passedClass);
		}
	}

	static getDerivedStateFromProps(props, state) {
		return {
			children: props.children
		};
	}

	componentWillUnmount() {
		// on unmount clear the styling of the aside
		this.containerEl.className = '';
	}

	render() {
		// append the children to the selected DOM container
		return !this.containerEl
			? null
			: ReactDOM.createPortal(this.state.children, this.containerEl);
	}
}

export default AsidePortal;
