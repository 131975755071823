import React, { Component } from 'react'
import clone from 'lodash.clonedeep';
import { withRouter, Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import classes from '../AccountSettings.module.scss';
import * as validator from '../../../utils/formValidator';
import Button from '../../../components/UI/Button/Button';
import { Form, Input } from '../../../components/UI/Form/Form';
import * as userService from '../../../services/user/userService';
import { errorParser } from '../../../utils/errorParser';
import IconPaypal from '../../../assets/images/icons/PayPal_logo.svg';



class PayoutSettings extends Component {
  state = (() => {
    const user = this.props.user;
		return {
			loading: false,
			formIsValid: false,
			formControls: {
				email: {
					id: 'payPalAccount',
					type: 'text',
					placeholder: 'Your PayPal account email',
          placeholderStatic: 'Enter PayPal account email',
					shrink: true,
					value: user.profile.payPalAccount,
					validation: { required: true, isEmail: true },
					error: '',
					valid: false,
					touched: user.profile.payPalAccount
        },
        emailConfirm: {
					id: 'payPalAccountEmailConfirm',
					type: 'text',
					placeholder: 'Confirm your PayPal account email',
          placeholderStatic: 'Confirm PayPal account email again',
					shrink: true,
					value: user.profile.payPalAccount,
					validation: { required: true, isEmail: true },
					error: '',
					valid: false,
					touched: user.profile.payPalAccount
				}
			}
		};
  })();

  
  
  // clears the errors and 'dirty' the input on change. Errors will be checked on blur
  clearInput = (inpt) => {
    inpt.touched = true;
    inpt.error = '';

    // if input is part of the group clear all group memebers
    if (inpt.group) {
      inpt.groupError = false;
    }
  };

  inputChangeHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    ctrl.value = e.target.value;

    if (formControlsCopy.email.value && (formControlsCopy.email.value === formControlsCopy.emailConfirm.value)) {
      formControlsCopy.email.error = "";
      formControlsCopy.emailConfirm.error = "";
    } 

    // if input is part of the group highlight all the memeber of a group in case of an error
    if (ctrl.group) {
      ctrl.group.forEach((inputName) => {
        this.clearInput(formControlsCopy[inputName]);
      });
    } else {
      this.clearInput(ctrl);
    }

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy)
    });
  };

  // checks validity of single input
  getInputError = (input) => {
    return validator.validateInput(input);
  };

  inputBlurHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    // if it is a group show error only underneath the first input
    ctrl.error = this.getInputError(ctrl);

    if (!ctrl.error && formControlsCopy.email.value !== formControlsCopy.emailConfirm.value) {
      ctrl.error = "Emails don't match!";
    } else if (!ctrl.error) {
      formControlsCopy.email.error = "";
      formControlsCopy.emailConfirm.error = "";
    } 

    this.setState({
      formControls: formControlsCopy,
      formIsValid: validator.formIsValid(formControlsCopy)
    });
  };

  prepareSubmitData() {
		const form = this.state.formControls;
		return {
      "payPalAccount": {
			  "first": form.email.value && form.email.value.trim(),
				"second": form.email.value.trim()
			}
		}
  }
  
  submit(e) {
		e.preventDefault();
		// if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
		//e.target.focus();

		if (this.state.formIsValid) {
			this.setState({ loading: true });
			userService
				.updatePayoutInfo(
					this.prepareSubmitData(),
					this.props.token,
					this.props.user.type
				)
				.then((response) => {
					// update the store in the main component. As we are getting only the profile clone deep the rest of the data
					const clonedUserData = clone(this.props.user);
					clonedUserData.profile = response.data;
					this.props.updateStore(clonedUserData);
					this.setState({ loading: false });

					const queryParam = new URLSearchParams(this.props.location.search);
					if (queryParam.get('back-to')) {
						const backToQueryParam = new URLSearchParams(this.props.location.search);
						backToQueryParam.delete('back-to');
						this.props.history.push(queryParam.get('back-to') + '?' + backToQueryParam);
					}
				})
				.catch((error) => {
					console.log(error);
					const apiErr = errorParser(error.response.data.errors);
					console.log(apiErr);

					const cloneForm = clone(this.state.formControls);

					// config object to be used by checkFormApiErrors
					const errorStateConfig = {
						formPayPalAccountError: 'payPalAccount',
					};

					// checking form errors for matching pairs defined in the errorStateConfig
					validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

					this.setState({
						formIsValid: false,
						formControls: cloneForm,
						loading: false
					});
				});
		}
	}

  render() {

    console.log()

    const queryParam = new URLSearchParams(this.props.location.search);

		const buttonSaveBase = (text) => (
			<Button
        className={classes.ButtonSave}
				fullWidth={true}
				size="large"
				type="submit"
				disabled={this.state.loading || !this.state.formIsValid}
				loading={this.state.loading}>
				{text}
			</Button>
    );
    
		const buttonSaveRender =
			queryParam && queryParam.get('back-to') ? (
				buttonSaveBase('SAVE AND GO BACK')
			) : (
				buttonSaveBase('SAVE CHANGES')
			);

    return (
      <Form onSubmit={(e) => this.submit(e)}>

				<h3 className={classes.Subheaders}>Payouts</h3>
        <p className={classes.Notice}>
          We use PayPal to securely pay our teachers. Your profits will be sent to the email address below via PayPal.
        </p>
        <ReactSVG src={IconPaypal} className={classes.IconPaypal} />

				<Input
					config={this.state.formControls.email}
					onChange={(e) => this.inputChangeHandler(e, 'email')}
					onBlur={(e) => this.inputBlurHandler(e, 'email')}
				/>

        <Input
          style={{marginBottom: '0'}}
					config={this.state.formControls.emailConfirm}
					onChange={(e) => this.inputChangeHandler(e, 'emailConfirm')}
					onBlur={(e) => this.inputBlurHandler(e, 'emailConfirm')}
				/>
        
        {this.props.user.profile.payPalAccount === null ? <small className={classes.InfoText}>Please ensure to enter a correct PayPal account. If you make a mistake, we won't be able to issue refunds. 
          <br/>Don't have a PayPal account? <a href="https://paypal.com" target="_blank" rel="noopener noreferrer" >Create PayPal account</a> 
        </small> : '' }
			  

        {buttonSaveRender}
        
			</Form>
    )
  }
}

export default withRouter(PayoutSettings);