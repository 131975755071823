import React from "react";
import Avatar from "../UI/Avatar/Avatar";
// import MessageUser from "./MessageUser";
import "./Messages.scss";
import Moment from "react-moment";

import { ChosenDisplayName } from '../UI/ChosenDisplayName/ChosenDisplayName.js';

const MessageSidebar = (props) => {
  const { selectedThread, threads, loggedInUser, getMessages, onClose } = props;

  return (
    <div className="threadContainer">
      <div
        style={{ padding: "0 20px" }}
        className="head head--withBottomSeparatorBorder"
      >
        <h1>Messages</h1>
      </div>
      {threads.length > 0 ? (
        threads.map((thread, i) => {
          const isAuthor = thread.author.cryptName === loggedInUser.cryptName;
          const profileImage = isAuthor
            ? thread.receiver.profile.photo
              ? thread.receiver.profile.photo.big
              : null
            : thread.author.profile.photo
            ? thread.author.profile.photo.big
            : null;

          let lastMessageBody = thread.lastMessage
            ? thread.lastMessage.body
            : null;
          if (lastMessageBody && lastMessageBody.length > 30) {
            lastMessageBody = lastMessageBody.substring(0, 30);
            lastMessageBody = lastMessageBody.trim() + "...";
          }
          const isAuthorLastMessage = thread.lastMessage
            ? thread.lastMessage.author.cryptName === loggedInUser.cryptName
            : false;
          return (
            <div
              key={i}
              className={`userCard border-bottom ${
                thread.lastMessage &&
                !thread.lastMessage.seen &&
                !isAuthorLastMessage &&
                selectedThread !== thread.id
                  ? "userCard--new"
                  : thread.id === selectedThread
                  ? "userCard--active"
                  : null
              }`}
              onClick={() => {
                getMessages(
                  thread.id,
                  isAuthor ? thread.receiver.cryptName : thread.author.cryptName
                );
                if (onClose) {
                  onClose();
                }
              }}
            >
              <Avatar src={profileImage} className="Avatar" size="42" />
              <div className="userCard__body">
                {isAuthor ? (
                  // <strong> {thread.receiver.fullName}</strong>
                  <span><ChosenDisplayName user={thread.receiver} top="-2px" /></span>
                ) : (
                  // <strong> {thread.author.fullName}</strong>
                  <span><ChosenDisplayName user={thread.author} top="-2px" /></span>
                )}

                <p className="small">
                  {isAuthorLastMessage && <span>You: </span>}
                  {lastMessageBody}
                </p>
                <div className="right">
                  {thread.lastMessage && (
                    <p className="small">
                      <Moment format="HH:mm">
                        {thread.lastMessage.createDate}
                      </Moment>
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <div className="userCard border-bottom">
            <div className="emptyImage"></div>
            <div className="userCard__body">
              <div
                className="emptyText"
                style={{ width: "110px", marginBottom: "10px" }}
              ></div>
              <div className="emptyText" style={{ width: "160px" }}></div>
            </div>
          </div>
          <div className="userCard border-bottom">
            <div className="emptyImage"></div>
            <div className="userCard__body">
              <div
                className="emptyText"
                style={{ width: "110px", marginBottom: "10px" }}
              ></div>
              <div className="emptyText" style={{ width: "160px" }}></div>
            </div>
          </div>
          <div className="userCard border-bottom">
            <div className="emptyImage"></div>
            <div className="userCard__body">
              <div
                className="emptyText"
                style={{ width: "110px", marginBottom: "10px" }}
              ></div>
              <div className="emptyText" style={{ width: "160px" }}></div>
            </div>
          </div>
          <div className="userCard border-bottom">
            <div className="emptyImage"></div>
            <div className="userCard__body">
              <div
                className="emptyText"
                style={{ width: "110px", marginBottom: "10px" }}
              ></div>
              <div className="emptyText" style={{ width: "160px" }}></div>
            </div>
          </div>
          <div className="userCard border-bottom">
            <div className="emptyImage"></div>
            <div className="userCard__body">
              <div
                className="emptyText"
                style={{ width: "110px", marginBottom: "10px" }}
              ></div>
              <div className="emptyText" style={{ width: "160px" }}></div>
            </div>
          </div>
          <div className="userCard border-bottom">
            <div className="emptyImage"></div>
            <div className="userCard__body">
              <div
                className="emptyText"
                style={{ width: "110px", marginBottom: "10px" }}
              ></div>
              <div className="emptyText" style={{ width: "160px" }}></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MessageSidebar;
