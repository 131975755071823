import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/indexActions';
import Card from './../../../components/UI/Card/Card';
import clone from 'lodash.clonedeep';
import * as validator from '../../../utils/formValidator';
import classes from './ForgotPassword.module.scss';
import Button from '../../../components/UI/Button/Button';
import { Form, Input } from '../../../components/UI/Form/Form';
import * as authService from '../../../services/auth/authService';

class ForgotPassword extends Component {
  state = { 
    loading: false,
		formIsValid: false,
		formControls: {
			email: {
				id: 'email',
				type: 'email',
				placeholder: 'Enter you email address',
				value: '',
				validation: {
					required: true,
					isEmail: true
				},
				error: '',
				valid: false,
				touched: false
			}
   }
  }

  inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);

		formControlsCopy[controlName].value = e.target.value;
		formControlsCopy[controlName].touched = true;
		formControlsCopy[controlName].error = '';

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const errorMsg = validator.validateInput(formControlsCopy[controlName]);
		if (errorMsg) {
			formControlsCopy[controlName].error = errorMsg;
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
  };

  prepareSubmitData() {
		const userData = {
			email: this.state.formControls.email.value
		};
		return userData;
		
	}

  submit(e) {
		e.preventDefault();
		// if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
    //e.target.focus();
    console.log(this.state);
    if (this.state.formIsValid) {
      this.setState({loading: true});
      authService.forgotPassword(this.prepareSubmitData())
      .then((response) => {
		console.log('[ForgotPassword] Success', response);
		const urlParams = this.props.location.pathname;
		// console.log(urlParams)
		this.props.history.push('/check-email', {urlParams});
		console.log(this.props.history)
      })
      .catch((err) => {
        				console.log('[ForgotPassword] error', err);
        				this.setState({ loading: false });
        				this.props.snackbarAdd({
        					message: err.response.data.error_description || err.response.data.message,
        					type: 'error',
        					timeout: 8000
        				});
        			});
      console.log(this.state);
    } else {
        this.setState({ formIsValid: validator.formIsValid(this.state.formControls) });
		    // TODO focus to the first errored input
    }
	}

  render() { 
    const formElementsArray = [];
		for (let key in this.state.formControls) {
			const inputClasses = [];
			if (!this.state.formControls[key].valid && this.state.formControls[key].touched) {
				inputClasses.push(classes.Invalid);
			}

			formElementsArray.push({
				id: key,
				config: this.state.formControls[key],
				classes: inputClasses
			});
		}
	
    return ( 
      <div className={classes.Container}>
				<h1>Forgot your password?</h1>
				<Card className={classes.ForgotPasswordCard}>
					<Form onSubmit={(e) => this.submit(e)}>
						<p className={classes.TextContent}>
							We'll send you an email with further instructions on how to reset your password.
						</p>
						<Input
							config={this.state.formControls.email}
							onChange={(e) => this.inputChangeHandler(e, 'email')}
							onBlur={(e) => this.inputBlurHandler(e, 'email')}
						/>
						<Button
							fullWidth={true}
							size="large"
							type="submit"
							className="btn btn-primary"
							disabled={this.state.loading || !this.state.formIsValid}
							loading={this.state.loading}>
							SEND EMAIL
						</Button>
					</Form>
				</Card>
			</div>
     );
  }
}

const mapStateToProps = (state) => {
	return {
		loading: state.auth.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);