import React, { Component } from 'react'

import Card from '../../../components/UI/Card/Card'
import IDExpansionPanel from '../../../components/UI/ExpansionPanel/ExpansionPanel'
import classes from './../StaticPages.module.scss'

class FAQ extends Component {
  state = {
    active: ''
  }

  componentDidMount () {
    window.scrollTo(0, 0);

    //Meta tags
    let metatitle = document.getElementsByName('title')[0];
    let metaDecription = document.getElementsByName('description')[0];

    document.title = 'InfoDepot FAQs: Learn more | InfoDepot';
    metatitle.setAttribute('content','InfoDepot FAQs: Learn more | InfoDepot');
    metaDecription.setAttribute('content', 'If you need help or you want to find out more about InfoDepot, take a look at the InfoDepot FAQ page where all your questions will be answered.' )
  }

  handleClick = (e) => {
    this.setState({
        active: true
    });
};

  render () {
    return (
      <div className={[classes.Container, classes.ContainerFaq].join(' ')}>
        <h1>FAQ</h1>
        
        <h2>General Questions</h2>
        <div className={classes.FaqWrapper}>
          <Card className={classes.CardFaq}>
            <h3>Getting started</h3>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is InfoDepot?'}
            >
              InfoDepot is a global e-learning platform where anyone can teach
              anything and learn just about everything through educational
              videos and live calls with teachers. The website is an information
              marketplace that facilitates user-to-user connections creating a
              virtual community that encourages dynamic learning and the
              seamless exchange of information. InfoDepot is an amazing resource
              for lifelong learning as well as a great business platform for
              users who want share and sell what they know to other users.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I register on InfoDepot?'}
            >
              You can register on InfoDepot for free by using your name and a
              valid email address. When you register on the website, an
              activation email will be sent to the email address that you
              provided. Your registration is not complete until you receive and
              activate your account through the email. If you don’t receive the
              email, please check your spam filter.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if I didn’t receive the registration email?'}
            >
              In case you didn’t receive a registration email, check your SPAM
              folder. Please designate InfoDepot email as “NOT SPAM” and add our
              email address to your contacts to solve the problem.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Why must I register on InfoDepot?'}
            >
              You can search through the website as a guest, but in order to
              purchase a video or to speak live with a tutor, you must create an
              account as either a student or a teacher. Registration is free.
              Once you register, you can elect to “start learning” as a student
              to purchase videos and to speak with any teachers available
              online; or, you can set up a teacher profile by selecting the
              “start teaching” option. By selecting “start teaching” the teacher
              dashboard is launched within your account and now you are ready to
              (i) upload videos and (ii) set up live tutoring call lines to
              speak with students. The teacher dashboard becomes a control
              center for managing your content.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Is there a membership fee or monthly subscription to join InfoDepot?'
              }
            >
              No. There is no membership fee or monthly subscription to join
              InfoDepot. As a student, your only costs are for your purchases of
              videos or speaking live with a tutor on the phone. Teachers on
              InfoDepot who upload and sell their videos and/or speak live with
              students pay a platform fee for use of the site and commission for
              each transaction. Please use this{' '}
              <a href={'/fees-info'} target={'_blank'}>
                link
              </a>{' '}
              to view the current platform fees and commission rates paid by
              teachers on InfoDepot.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What are the fees paid by Teachers on InfoDepot?'}
            >
              Platform fees are standard fees paid by teachers who upload videos
              and/or create live tutoring lines. When a teacher uploads a video,
              the platform fee is paid by the teacher as a part of the set up
              process. The platform fee for the live tutoring call is deducted
              from the total amount of each completed call by the teacher. In
              addition to the forgoing platform frees, each teacher pays a
              commission to InfoDepot for each completed transaction. Please use
              this{' '}
              <a href={'/fees-info'} target={'_blank'}>
                link
              </a>{' '}
              to view the current platform fees and commission rates paid by
              teachers on InfoDepot.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Why do I have to “Top Up” my account on the site?'}
            >
              Before you can make a purchase on InfoDepot (i.e. purchase a video
              or speak live with a teacher), you must have enough money in your
              account to complete the transaction. If your account doesn’t have
              enough funds to complete a transaction, you must first “top up”
              your balance by adding funds directly from your credit card or
              through Paypal. The amount of your purchase will be automatically
              deducted from your balance and any remaining funds will be
              reflected on your account page.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Who can teach on InfoDepot?'}
            >
              Anyone can teach on InfoDepot and everyone has information to
              share! You can teach on InfoDepot if you have experience or an
              expertise in any area and you would like to share and sell that
              information provided that you have created the content (videos or
              other files uploaded to the site) and/or you legally own and
              control it.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Who owns the information uploaded to InfoDepot by teachers?'
              }
            >
              If a teacher uploads content to InfoDepot, the teacher owns the
              content except the teacher, by accepting the Terms and Conditions
              on the site, grants to InfoDepot an irrevocable, worldwide license
              to use the content on the website once it is uploaded to the
              platform. This license protects other users who purchase the
              content from the teacher and ensures that the content will always
              be available on the site after purchase.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Who owns the information uploaded by InfoDepot to the website?'
              }
            >
              If InfoDepot creates and/or uploads a video to the site or if
              InfoDepot videotapes a class for a teacher or features a teacher
              in a video or other content, that video and any content contained
              in the video and all rights related to the video and related
              content including, but not limited to copyrights, distribution and
              editing will be exclusively owned and controlled by InfoDepot.
            </IDExpansionPanel>
          </Card>
          <Card className={classes.CardFaq}>
            <h3>My account</h3>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where do I watch purchased videos?'}
            >
              When you purchase a video, you can watch it in the Video Library
              of your Account.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'How long are purchased videos available after purchase?'
              }
            >
              You have indefinite and unlimited access to all videos that your
              purchased in your Video Library.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Why do I need to add my telephone number?'}
            >
              You must add your telephone number to have a one-to-one call on
              the website. The website connects both parties to the call and
              your personal telephone number is not shared with a tutor or any
              other users.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How do I deactivate my account?'}
            >
              If you decide to deactivate your account, please email us at 
              <a href='mailto:contact@infodepot.com?Subject=Hello Infodepot'> contact@infodepot.com </a>  
              and please write “Deactivate” in the subject
              line of the email.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How to add/edit details about me?'}
            >
              You can make these changes at any time in the Settings menu of
              your account.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How to reset a password?'}
            >
              On the sign-in page, please click forgot password and a reset link
              will be emailed to you. Please click onto the link and follow the
              instructions to reset your password.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I turn off email notifications?'}
            >
              You can unsubscribe from promotional emails and special offers by
              unchecking the option in the Settings menu of your account.
            </IDExpansionPanel>
            {/* <IDExpansionPanel activeBlac
              active={this.state.active} className={classes.Panel}
                paneltitle={'Where do I watch purchased videos?'}

              >
              When you purchase a video, you can watch it in the Video Library of your Account.
              </IDExpansionPanel> */}
          </Card>
          <Card className={classes.CardFaq}>
            <h3>Contact us</h3>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'If I have questions, who can I ask for help?'}
            >
              If you have questions, please email us at <a href='mailto:contact@infodepot.com?Subject=Hello Infodepot'>contact@infodepot.com</a>.
            </IDExpansionPanel>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I report a problem?'}
            >
              If there is a problem, please email us at <a href='mailto:contact@infodepot.com?Subject=Hello Infodepot'>contact@infodepot.com</a>.
            </IDExpansionPanel>
          </Card>
        </div>

        <h2>Students</h2>
        <p>Watch <a href="/quick-start">Quick Start Tutorial Videos</a> to get step-by-step instructions on how to use InfoDepot</p>
        <div className={classes.FaqWrapper}>
          <Card className={classes.CardFaq}>
            <h3>Balance</h3>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I top up my account?'}
            >
              You can top up your account at any time by clicking onto your
              balance at the top of your Account page. By clicking onto your
              balance, the add funds or “top up” page will appear. Please select
              the amount of dollars that you want to add to your account and
              follow the prompts to either add funds by using your credit card
              or Paypal.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I check my balance?'}
            >
              The amount of your available balance will always be located at the
              top of your Account page.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How much should I top up?'}
            >
              On the top up page, there are preset amounts to add dollars to
              your account. You can also add a custom amount to your account by
              selecting “custom” on that page. Please add enough funds to
              complete a transaction or a call with a teacher. For example, if a
              teacher charges $2.00/per minute to talk and you know you want to
              talk about 10 minutes, please top up to have at least $20 in your
              account to prevent the call from being terminated before the 10
              minutes are up.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Do dollars on my balance expire?'}
            >
              No. Any remaining balance on your account does not expire and will
              remain available to you for use at any time.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Can I withdraw dollars from my balance if I don’t find any videos or live calls that I want to purchase?'
              }
            >
              No. You cannot withdraw dollars from your balance once they have
              been added to your account. The available balance does not expire
              and can be used at any time.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where can I see an overview of my transactions?'}
            >
              Please visit the Transaction History section of your Account to
              see an overview of your transactions.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Video classes</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is a video class?'}
            >
              A video class is a pre-recorded video uploaded to website by other
              registered users or by InfoDepot that are, generally, “how-to” and
              educational videos.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I search video classes?'}
            >
              Videos on InfoDepot can be searched by selecting “video classes”
              under the “Browse InfoDepot” feature. Once selected, you can
              search the videos by using the built-in filters or the side
              navigation bar to choose a video from a specific category, skill
              level, rating or price. Video classes can also be searched using a
              keyword or words that include a title, teacher name, etc.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I purchase a video class?'}
            >
              Purchasing a video class is easy. Just click onto the “Enroll Now”
              button under the selected video and follow the prompts on the page
              to complete the purchase.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where can I find the videos I purchased?'}
            >
              Purchased videos can be found and viewed in the Video Library of
              your Account.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I contact a teacher?'}
            >
              If you need to contact a teacher, please post a comment to the
              teacher for the specific video class. The teacher will be notified
              by email that a message was posted for them.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I know if someone replied to my comment? '}
            >
              If someone replies to a comment that you have posted, you will
              immediately receive an email that there has been a reply to your
              comment on the website. You can follow the link in the email or
              log onto the site to respond to any replies or comments. If other
              registered users made comments to the same post (but not replying
              to your comments), you will receive an email at the end of the day
              that aggregates the comments on that post for that day.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What are reviews and ratings? '}
            >
              Reviews and ratings are the opinions and experiences of registered
              users who have purchased that particular video or who have spoken
              with a particular teacher. They are helpful to other users in
              deciding whether the content in a video they want to purchase or a
              teacher they want to speak to is right for them. This feedback
              also lets teachers know if students found value in their content.
              Ratings and reviews are a vital part of the community engagement
              on InfoDepot and we encourage all registered users to review and
              rate any content or service they have purchased on the website.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I access the documents of a video?'}
            >
              After you purchase a video class, you will have access to any
              documents and files uploaded by the teacher to the Video Workbook.
              Click onto the files and they will download to your hard drive
              where you can access them.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Live Tutoring Calls and Videos</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is a live tutoring phone call?'}
            >
              A live tutoring phone call is a one-to-one call between a teacher and
              student on the phone in real time. The teacher sets a per minute
              charge for the call and InfoDepot connects the two parties.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is a live tutoring video?'}
            >
              A live tutoring video is a one-to-one video tutoring class between a teacher and student in real time. 
              The teacher sets a per minute charge for the video class and InfoDepot connects the two parties.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How does the video call work?'}
            >
              Once you start a video call you will get the screen with another person behind it, 
              as well as the options to mute or unmute the mic, turn on or turn off the camera, share, minimize or maximize the screen.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I know how long I can speak with my teacher on phone and video call?'}
            >
              Before you initiate the call you will be able to see an estimation that will provide 
              you with information about how many minutes you can have on a call according to your balance and the per minute rate set by the teacher.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I search live tutoring phone calls and videos?'}
            >
              Just like videos, you can search live calls by selecting “Live
              Tutoring Calls” under Browse InfoDepot. Once selected, you can
              filter, sort and search the calls using criteria such as subject,
              rating and price. You can also search within the live tutoring
              calls using a keyword or words. You will also see teachers who are
              online and immediately available to take calls versus teachers who
              are offline.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I call a teacher via live phone call feature?'}
            >
              When searching live tutoring phone calls you can call a teacher that is online by 
              clicking onto “Get a phone call’’ Once you choose the option for calling, the website 
              is going to call the teacher and get them on the line. After the teacher is on the line, 
              the website will call your mobile phone (make sure 
              you have your phone near you) then will connect you with the teacher. 
              Your account will not be charged until you are connected with the teacher on the phone. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Do I need to be logged in to make a phone call?'}
            >
              In order to make a call you need to be logged into your InfoDepot account as each 
              call is initiated by clicking the “Get a phone call” button on a class you choose. 
              Once you click the button, the system will notify the teacher about your request 
              and call you back when the teacher is ready. Make sure you have your phone near 
              you to receive the call  but you don’t have to remain online waiting for a call. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Do I need to be logged in to make a video call?'}
            >
              Yes, you need to be logged into your account on the platform to make or receive video calls. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I call a teacher via live video call?'}
            >
              When searching live video calls you can call a teacher that is online by clicking onto “Start a video call’’. 
              Once the teacher accepts your call the session will start. 
              Your account will not be charged until you are connected with the teacher. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'If I’m reading the InfoDepot blog, can I receive video calls?'}
            >
              To receive video calls, you need to be logged in the InfoDepot website and be 
              anywhere on the platform except on the Blog which is not part of the main website. 
              However, you can read guides and articles on the blog and receive calls  as 
              long as you have any other page of InfoDepot opened in another tab.  
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if a teacher is offline or busy on a phone call?'}
            >
              If a teacher is busy with another phone call, you can place yourself on a queue. 
              Once the teacher is off the call, the system will call the next person in the queue 
              until the queue ends or the teacher goes offline. 
              If the teacher goes offline, the queue remains and the 
              website will call the next person in the queue the next time the teacher is available.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if a teacher is offline or busy on a video call?'}
            >
              If a teacher is busy with another video call, you can place yourself on a queue. 
              Once the teacher is off the call, the system will notify a teacher about the next 
              student in line. Make sure to stay logged in to answer the video call once the 
              teacher is ready to connect. If the teacher goes offline 
              while there are still students in the queue, the queue remains and 
              the website will call the next person in the queue the next time the 
              teacher is available.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I check the number of people in the queue?'}
            >
              If a teacher is busy with another call, you can place yourself in the 
              queue by clicking the “Click to queue” button. After that you will 
              be able to check the number of people who are queued before you.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if I’m not online when the teacher calls me when I am next in the queue?'}
            >
              If you are not logged into the InfoDepot website at the moment you are 
              next in the queue, you will lose your queue and won’t be able to receive the call.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if I can’t answer a queued video call?'}
            >
              If you cannot answer, the video call will be terminated and you will not be charged for the call.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can I start video calls on my mobile device?'}
            >
              Yes, but there are certain conditions regarding the operating systems 
              and browsers you are using on computer or phone.  If you are using iOS, 
              you will be able to start a video call by using the Safari browser, while 
              Android users can start video calls by using Chrome and Firefox. 
              If you have a Windows operating system you can start video calls by using Chrome, 
              Firefox and Edge browsers. Chrome and Firefox are also options for Linux users while 
              Mac users can start video calls by using Chrome, Firefox and Safari.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Where do I add/edit my phone number for receiving a phone or video call?'
              }
            >
              You can add/change your telephone number in the Settings menu in
              your Account under the Contact details section.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can a teacher see my telephone number?'}
            >
              No. The website calls each party separately and then connects the
              call so the teacher and student can speak to each other. Your
              personal telephone number is not seen or shared with the teacher
              or other users.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'If I am in a queue, when will I be connected to the teacher?'
              }
            >
              If there is a queue, the teacher will take calls in the order of
              the queue when the teacher is online. If the teacher is online and
              you are not available when it is your turn and the website calls
              you, the call will terminate and the teacher will take the next
              student in the queue. If you miss the call, you will have to place
              yourself back in the queue.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if a teacher doesn’t answer?'}
            >
              A live one-to-one call with a teacher is initiated by the system
              calling a teacher first to make sure they are available. If the
              teacher answers, then the website contacts a student. If the
              teacher doesn’t answer, the student’s phone will not be called and
              the connection will not take place.
            </IDExpansionPanel>

            {/* <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if teacher doesn’t answer?'}
            >
              A live one-to-one call with a teacher is initiated by the system
              calling a teacher first to make sure they are available. If the
              teacher answers, then the website contacts a student. If the
              teacher doesn’t answer, the student’s phone will not be called and
              the connection will not take place.
            </IDExpansionPanel> */}

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if I can’t answer?'}
            >
              Please try to schedule or set up calls when you are available
              since teachers make themselves available to speak with you. If you
              cannot answer, the call will be terminated and you will not be
              charged for the call.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'What if my live tutoring call is longer than I planned and my balance is cleared during the call?'
              }
            >
              Your live tutoring call will only last for amount of time that
              your available balance will cover. You will receive a notice
              during the call that your call will be terminated within one
              minute before it ends. Once the call terminates, you will need to
              top up your account to place the call again with your tutor. To
              avoid calls being interrupted, please top up your balance with
              enough funds or larger amounts. Any remaining balance will not
              expire and can be used for future purchases on the site.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where can I see an overview of my calls?'}
            >
              A list of all of your purchases including your calls can be found
              in the Transaction History section of your Account.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I review a live tutoring call?'}
            >
              After your call is completed, you can review and rate it in Your
              account under Live Tutoring Calls list.
            </IDExpansionPanel>
          </Card>
        </div>

        <h2>Teachers</h2>
        <p>Watch <a href="/quick-start">Quick Start Tutorial Videos</a> to get step-by-step instructions on how to use InfoDepot</p>
        <div className={classes.FaqWrapper}>
          <Card className={classes.CardFaq}>
            <h3>Teachers Dashboard</h3>
            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is a Teacher Dashboard?'}
            >
              The Teacher Dashboard is the control center where you can create,
              upload and manage your content on InfoDepot. When you select
              “Start Teaching” the Teacher Dashboard is automatically created
              and becomes a part of your Account. The Teacher Dashboard has a
              menu that allows you to upload a video, add/edit video details,
              set pricing and publish the video on the website. A teacher can
              also set up Live Tutoring Calls within the Dashboard and add/edit
              call details, set pricing and create an online schedule.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How to access the Teacher Dashboard?'}
            >
              You can access Teacher Dashboard by clicking the link under My
              Account menu.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Creating Video Classes</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How do I create a video class?'}
            >
              To create a video class on InfoDepot, click Start Teaching on top
              of the page and select Video Class, then fill in the form. You can
              also access Create Video Class form from Teacher Dashboard under
              your Account.
              <br />A video class can be recorded using a wide range of
              technology from your mobile phone to professional equipment and
              can be made in various formats (the website is able to convert
              most formats to make the video usable on the site). Regardless of
              the technology, remember that you want videos to be a of high
              quality with clear audio and visual so registered users will want
              to purchase it and leave positive feedback to encourage additional
              sales. The first 30 seconds of the video should be a preview where
              the teacher gives a brief introduction of themselves and the
              content of the video. A single video should not be larger than 2GB, 
              and we recommend that is not longer than 30 minutes.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is a Cover Image?'}
            >
              A Cover Image is the static image (.jpg, etc.) uploaded by a
              teacher on the Create Video Class form to represent the content of
              a particular video. It’s a visual cue to potential purchasers and
              helps to market your video. For instance, if you are making a
              video about “how to bake a pound cake from scratch”, the Cover
              Image should be a picture of the cake to be baked. When the video
              is published on the site, a content card will appear with the
              Cover Image of the cake.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How do I publish a video?'}
            >
              When you upload a video class to your Teacher Dashboard you have
              the option of having the video automatically published to the
              website once it is available or you can manually publish the
              video. Depending on the option checked, an email will be sent to
              you to notify you that a video has been automatically published or
              is ready to be published which will require your manual action.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where can I find video classes I created?'}
            >
              The Video Class that you have created can be found in your Teacher
              Dashboard on the Videos page.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Video Class duration</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How long should my video be?'}
            >
              Your video can be any length as long as it is not larger than 2GB. 
              However, we recommend that the video is to be at least 15 minutes, but not more than 30 minutes.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if my class is longer than permitted?'}
            >
              If your video class is more than 30 minutes, it is best to
              separate the videos of the same topic into installments of Part 1,
              Part 2, etc. and sell each video separately. If you are creating a
              multi-part video class, make sure you accurately describe the
              video as Part 1, Part 2, etc. in the video details on your Create
              Video Class form to avoid confusion for potential purchasers.
            </IDExpansionPanel>
          </Card>
        </div>
        <div className={classes.FaqWrapper}>
          <Card className={classes.CardFaq}>
            <h3>Promoting a Video Class</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is presented in the video preview?'}
            >
              The Video Preview is the first 30 seconds of any video on the site
              which can be viewed for free on the site. This free clip allows a
              potential purchaser to see the quality of the teacher, video class
              and content before purchase. When making videos, a teacher should
              include a brief introduction and, if relevant, show the project to
              focused on in the video (e.g. “Hi, my name is Ann and in this
              video I am going to teach you how to make this yummy pound cake
              from scratch.”).
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I offer my video for free?'}
            >
              You can offer free video classes on InfoDepot. When you upload
              your video class on your Teacher Dashboard using the Create Video
              Class form, you can the price for the video to “$0.00”. You may
              still need to pay a Platform Fee for uploading the video to the
              platform.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Video Class Ownership</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How long will my video be visible to other users?'}
            >
              After someone purchases your video class, that user will have
              unlimited access to your video in their Video Library.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Who owns my content after I upload It?'}
            >
              If you have uploaded a video class to InfoDepot, you own the video
              class, but by accepting the Terms and Conditions of the website,
              you grant to InfoDepot an irrevocable, worldwide License to use
              the content. This license protects users who have purchased the
              class from you so they can continue to have unlimited access to it
              at all times on the website.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Who owns the video if I hire InfoDepot to create a video class or they hire me?'
              }
            >
              If InfoDepot creates and/or uploads a video to the site or if
              InfoDepot videotapes a class for a teacher or features a teacher
              in a video or other content, that video and any content contained
              in the video and all rights related to the video and related
              content including, but not limited to copyrights, distribution and
              editing will be exclusively owned and controlled by InfoDepot.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Uploading documents to a Video Class</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I upload documents to my video class?'}
            >
              On the Video Class setup page, there is a field on the page to
              upload documents from your computer. The documents will be in the
              Video Class Workbook when the Video Class is published on the
              website.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Are there any limits for uploading documents to video class?'
              }
            >
              Yes. There is a limit of 10 documents per video class and the
              document size should not exceed 20mb.
            </IDExpansionPanel>
          </Card>
        </div>
        <div className={classes.FaqWrapper}>
          <Card className={classes.CardFaq}>
            <h3>Editing and Deleting a Video Class</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I edit my video class information?'}
            >
              Select the Video Class with the information that you want to change. 
              Select Edit and you can change the details for the 
              Video Class except you can not change the video.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can I replace the video file after the video is published?'}
            >
              No. Once a video file has been published on 
              the site it cannot be replaced by the teacher. 
              If you would like to upload another video, please create a new Video Class.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Can I delete my video after it was published?'
              }
            >
              You are able to delete your video class only if it 
              has not been purchased by any students. Go to the 
              Video Class that you want to delete and click on the bin icon to delete it.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Comments</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can students contact me?'}
            >
              Yes. Students can post a message to you under the relevant Video
              Class Comments section. You will receive an email that someone has
              posted a message to you. Please note that all comments are visible
              to other users too.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'How can I know if someone replied or commented on my video?'
              }
            >
              If someone replies or comments on your Video Class, you will
              immediately receive an email that there has been a reply or
              comment on the website. You can follow the link in the email or
              log onto the site to respond to any replies or comments. If other
              registered users made comments to the same post (but not replying
              to your comments), you will receive an email at the end of the day
              that aggregates the comments on that post for that day.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Reviews and Ratings</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What are reviews and ratings?'}
            >
              Reviews and ratings are the opinions and experiences of registered
              users who have purchased that particular video or who have spoken
              with a particular teacher. They are helpful to other users in
              deciding whether the content in a video they want to purchase or a
              teacher they want to speak to is right for them. This feedback
              also lets teachers know if students found value in their content.
              Ratings and reviews are a vital part of the community engagement
              on InfoDepot and we encourage all registered users to review and
              rate any content or service they have purchased on the website.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'How can I know when someone reviews my video or a live tutoring call?'
              }
            >
              You will receive an email when someone rates and reviews your
              video class or a live tutoring call with you.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Can I change a review on my video or my live tutoring call?'
              }
            >
              No. InfoDepot does not change reviews or ratings for any Video
              Classes or Live Tutoring Calls.
            </IDExpansionPanel>
          </Card>
        </div>
        <div className={classes.FaqWrapperLast}>
          <Card className={classes.CardFaq}>
            <h3>Setting up Live Tutoring Calls</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I set up a live call?'}
            >
              Go to your Teacher Dashboard. On a Live Call setup page you can choose between a 
              live phone or video tutoring option, but you can also choose to offer both. 
              Continue with adding more details and setting your rate per minute.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How do I set my per minute rate?'}
            >
              When you create your Live Tutoring Call in the Teacher Dashboard,
              you must include a per minute rate for your live calls. The rate
              should be set to account for the platform fee and payment of the
              commission to InfoDepot.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can I offer more than one live call/video topic?'}
            >
              Yes. You can set up more than one Live Tutoring Call. All calls
              would be setup on the Teacher Dashboard.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'Where do I set my phone number for receiving the calls?'
              }
            >
              You can add/change your telephone number in the Settings menu in
              your Account under the Contact details section.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can a student see my phone number?'}
            >
              No. The website calls each party separately and then connects the
              call so that the teacher and student can speak to each other. Your
              personal telephone number is not seen or shared with other users.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What is a live phone and video call schedule?'}
            >
              When you set up Live Tutoring Calls on your Teacher Dashboard, 
              you can create an automatic schedule to be available for phone and 
              video calls on InfoDepot. The calendar feature is on the set up page.
               During your schedule, you will appear online and students can 
               place a call to you. You can override your 
              calendar by disabling all your calls in your Teacher Dashboard. 
              Students cannot call you on any dates and times not permitted within your set calendar
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can I offer only video calls or only phone calls?'}
            >
              Yes, you are able to choose whether you want to offer 
              video or phone calls by setting up the schedule only for the tutoring option you prefer. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can I set different availability for phone and video calls?'}
            >
              Yes. Setting up schedules is flexible and made to answer your needs and availability. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How will I know that I have any students signed up for my tutoring sessions?'}
            >
              You will receive an email reminder to be online 15 minutes before your 
              availability starts and according to a calendar you have previously set, 
              but you won’t know how many 
              students you’ll have that day. In order to receive any call 
              from your students you have to be online following the time you set up in your schedule.  
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if there is more than one student for my video call?'}
            >
              They will be signed up in a queue and receive notification to 
              wait for your call back. Only if you agree to take the next call, 
              the system will make the connection with the student.  
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if I’m not available anymore, but there are students in the queue?'}
            >
              If you are, according to your schedule, not available anymore, but there are more students in the queue, 
              they will stay in the queue and be notified the next time your availability starts. 
              On the other hand, if you log out before your availability ends, students will lose 
              their queue and you will miss their calls. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'What if a student in a queue refuses my call back?'}
            >
              It may happen that the student is not able to take the call anymore. You will be notified if the call is missed. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Can I have more than one topic scheduled at the same time?'}
            >
              Yes. No matter how many topics you have offered,  if there is more than one student that wants to make a connection at same time, 
              they will either way form a queue. You will receive the notification for which topic each student has sent its request. 
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Do students form the same queue both for phone and video calls?'}
            >
              No, there is one queue for phone calls and one for video calls. 
              You need to create two different calendars in order to avoid different types of calls at the same time.  
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I disable/enable all my calls at once?'}
            >
              On the Live Tutoring Call listings located in your Teacher Dashboard there are 3 options you can use to enable or disable your calls:<br/><br/> 

              1. Available per schedule<br/>
              You can receive calls only during the dates and time you set within your individual call schedule.<br/><br/> 

              2. Available for all calls indefinitely<br/>
              You can receive any of your calls anytime.<br/><br/>

              3. Unavailable for all calls indefinitely<br/>
              You will not receive any of your calls.<br/>

            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where can I find calls I created?'}
            >
              You can find a list of the Live Tutoring Calls that you created in
              your Teacher Dashboard.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I edit a live call information?'}
            >
              On the Live Tutoring Call listing page in the Teacher Dashboard
              click onto the Live Tutoring Call you would like to edit. Please
              edit all relevant fields.
            </IDExpansionPanel>
          </Card>

          <Card className={classes.CardFaq}>
            <h3>Earnings, Fees and Payouts</h3>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I earn money on InfoDepot?'}
            >
              Teachers on InfoDepot can earn money by selling their video
              classes or from live tutoring calls with students. You set your
              own price per student for a Video Class and price per minute for a
              Live tutoring Call.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Fees and commissions'}
            >
              InfoDepot charges a Commission Fee on the gross revenue generated 
              from the sale of each video and for each completed Live Tutoring Call. 
              Commission Fees are determined at the sole 
              discretion of InfoDepot and are, at any time, subject to change. 
              You can read more about platform fees and commissions on the <a href="/fees-info">InfoDepot fees</a> page.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I check my balance?'}
            >
              All your earnings from selling video classes and live tutoring
              calls will be on your Balance. The amount of your available
              balance will always be located at the top of your Account page.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Do the dollars on my balance expire?'}
            >
              No. Any remaining balance on your account does not expire and will
              remain available to you for use at any time.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How can I request a payout?'}
            >
              After you make earnings from selling your videos or live tutoring
              calls, you can request a payout to your PayPal account. Click
              Balance on the top of the page and select Request a payout, then
              enter the amount you would like to withdraw and add your PayPal
              address where you want the amount to be sent. Before you submit
              your request, you will be presented with the transfer fees that
              may be applied.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Is there a minimum withdrawal amount?'}
            >
              Yes. The minimum you can request is $25.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'How do I add my PayPal address?'}
            >
              When you Top Up your account or request a Pay Out, you will be
              prompted on the page to log into your PayPal account. If you don’t
              have a PayPal Account, you will be directed to setup a PayPal
              account first and then redirected to either the Top Up or Payout
              page.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={
                'How long does it take to transfer the funds to my PayPal account?'
              }
            >
              We cannot guarantee the time frame for the transfer of funds to
              your account via Paypal as they are a third party provider that we
              rely upon for this service and we do not and cannot control their
              process. Under normal circumstances, transfers of funds to
              accounts within a reasonable time frame and are usually completed
              within a day.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Will I receive tax documents?'}
            >
              Yes. If you are a citizen of the United States, we will issue you
              a 1099 (or 1099K) to document any revenue you earn on the site.
            </IDExpansionPanel>

            <IDExpansionPanel
              activeBlack
              active={this.state.active}
              className={classes.Panel}
              paneltitle={'Where can I see an overview of my transactions?'}
            >
              An overview of your transactions can be found in the Transaction
              History section of your Account.
            </IDExpansionPanel>
          </Card>
        </div>
      </div>
    )
  }
}

export default FAQ
