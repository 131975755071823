export const PLAN_ID_FREE = 'free';
export const PLAN_ID_BASIC = 'basic';
export const PLAN_ID_PRO = 'pro';
export const PRICED_PLANS = [PLAN_ID_BASIC, PLAN_ID_PRO];
export const PLAN_BASIC_PER_MONTH_MONTHLY = 29;
export const PLAN_BASIC_PER_MONTH_ANNUALLY = 23;
export const PLAN_PRO_PER_MONTH_MONTHLY = 89;
export const PLAN_PRO_PER_MONTH_ANNUALLY = 80;
export const PLAN_FREE_VIDEOS_LIMIT = 10;

export const BASIC_PLAN_MONTHLY = 'basic_plan_monthly';
export const BASIC_PLAN_YEARLY = 'basic_plan_yearly';
export const PRO_PLAN_MONTHLY = 'pro_plan_monthly';
export const PRO_PLAN_YEARLY = 'pro_plan_yearly';

export function getPricingPlan(id) {
  return pricingPlans.find((plan) => plan.id === id);
}

export function isPricedPlans(planId) {
  return PRICED_PLANS.includes(planId);
}

export function getPlanConst(planId, isAnnualBilling) {
  if (planId === PLAN_ID_BASIC) {
    if (isAnnualBilling) return BASIC_PLAN_YEARLY;
    else return BASIC_PLAN_MONTHLY;
  }

  if (planId === PLAN_ID_PRO) {
    if (isAnnualBilling) return PRO_PLAN_YEARLY;
    else return PRO_PLAN_MONTHLY;
  }

  return null;
}

export function getPaymentTitleForPlan(plan) {
  switch (plan) {
    case BASIC_PLAN_MONTHLY:
      return 'Basic account, monthly subscription';
    case BASIC_PLAN_YEARLY:
      return 'Basic account, annual subscription';
    case PRO_PLAN_MONTHLY:
      return 'Pro account, monthly subscription';
    case PRO_PLAN_YEARLY:
      return 'Pro account, annual subscription';
    default:
      return null;
  }
}

export function getPaymentBillingTypeText(payment) {
  if (payment && payment.planPeriod) {
    switch (payment.planPeriod) {
      case BASIC_PLAN_MONTHLY:
        return 'Monthly Billing';
      case BASIC_PLAN_YEARLY:
        return 'Annual Billing';
      case PRO_PLAN_MONTHLY:
        return 'Monthly Billing';
      case PRO_PLAN_YEARLY:
        return 'Annual Billing';
      default:
        return null;
    }
  }
  return null;
}

export function isAnnualFromPaymentOrNull(payment) {
  if (payment && payment.planPeriod) {
    switch (payment.planPeriod) {
      case BASIC_PLAN_MONTHLY:
        return false;
      case BASIC_PLAN_YEARLY:
        return true;
      case PRO_PLAN_MONTHLY:
        return false;
      case PRO_PLAN_YEARLY:
        return true;
      default:
        return null;
    }
  }
  return null;
}

export function getTotalPrice(chosenPlan, isAnnualBilling) {
  if (isAnnualBilling) {
    return 12 * chosenPlan.pricePerMonthAnnually;
  }
  return chosenPlan.pricePerMonthMonthly;
}

export function getPricingPlans(userPlanId) {
  let plans = [];
  switch (userPlanId) {
    case PLAN_ID_BASIC:
      plans = [...pricingPlans].splice(1);
      break;
    case PLAN_ID_PRO:
      plans = [...pricingPlans].splice(2, 1);
      break;
    default:
      plans = [...pricingPlans];
  }
  return plans;
}

export function userHasPaidPlan(userPlanId) {
  return userPlanId === PLAN_ID_BASIC || userPlanId === PLAN_ID_PRO;
}

export const pricingPlans = [
  {
    id: PLAN_ID_FREE,
    name: 'free',
    pricePerMonthMonthly: 0,
    pricePerMonthAnnually: 0,
    recommended: false,
    btnWording: 'Get started for free',
    prologue: 'Everything you get with Free plan:',
    epilogue: null,
    promos: [{ item: 'Up to 10 video classes' }],
    benefits: [
      'Unlimited students',
      'Teacher Profile page',
      '15% transaction fee on any video sale',
      'Same day payouts',
      'Access to InfoDepot community',
      'Automated notifications  for your followers	when you upload a new video',
      'Internal messaging',
    ],
  },
  {
    id: PLAN_ID_BASIC,
    name: 'basic',
    pricePerMonthMonthly: PLAN_BASIC_PER_MONTH_MONTHLY,
    pricePerMonthAnnually: PLAN_BASIC_PER_MONTH_ANNUALLY,
    recommended: false,
    btnWording: 'Get basic plan',
    prologue: 'Everything you get with Basic plan:',
    epilogue: null,
    promos: [{ item: 'Unlimited video classes' }],
    benefits: [
      'Unlimited students',
      'Teacher Profile page',
      '5% transaction fee on any video sale',
      'Same day payouts',
      'Access to InfoDepot community',
      'Automated notifications  for your followers when you upload a new video',
      'Internal messaging',
    ],
  },
  {
    id: PLAN_ID_PRO,
    name: 'pro',
    pricePerMonthMonthly: PLAN_PRO_PER_MONTH_MONTHLY,
    pricePerMonthAnnually: PLAN_PRO_PER_MONTH_ANNUALLY,
    recommended: true,
    btnWording: 'Get Pro plan',
    prologue: 'Everything in Basic, plus:',
    epilogue: [
      '*3rd party network connection fees for calls excluded',
      '**Your username will be used for your profile URL',
    ],
    promos: [
      { item: 'Unlimited video classes' },
      {
        item: 'Unlimited 1-on-1 Live Tutoring Lines',
        helper: '(phone & video calls)',
      },
    ],
    benefits: [
      'No transaction fees on video sales and calls*',
      'Branded Teacher Profile link**',
      'Custom coupon code',
      'Pro Badge displayed next to your name',
      'Premium Support',
      'Integrated Marketing Services',
      'Automated notifications  for your followers when you are available for Live Tutoring',
    ],
  },
];
