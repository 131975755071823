import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'lodash.clonedeep';

import classes from './PostComment.module.scss';
import { Form, Input } from '../../UI/Form/Form';
import Avatar from '../../UI/Avatar/Avatar';
import * as validator from '../../../utils/formValidator';
import Button from '../../UI/Button/Button';
import * as videoService from '../../../services/video/videoService';

class PostComment extends Component {
	state = {
		loading: false,
		formIsValid: false,
		formControls: {
			post: {
				id: 'post-' + this.props.id,
				type: 'textarea',
				placeholderStatic: 'Write your ' + this.props.type,
				value: '',
				validation: {
					maxLength: 1000
				},
				error: '',
				valid: false,
				touched: false
			}
		}
	};

	inputChangeHandler = (e, controlName) => {
		console.log(e.target.value);
		const formControlsCopy = clone(this.state.formControls);

		formControlsCopy[controlName].value = e.target.value;
		formControlsCopy[controlName].touched = true;
		formControlsCopy[controlName].error = '';

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const errorMsg = validator.validateInput(formControlsCopy[controlName]);
		if (errorMsg) {
			formControlsCopy[controlName].error = errorMsg;
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	submit() {
		console.log('submit');
		if (this.state.formIsValid) {
			const textareaContent = this.state.formControls.post.value.trim();
			const serviceTypeName = this.props.type === 'reply' ? 'postReply' : 'postComment';
			this.setState({ loading: true });
			videoService
				[serviceTypeName](this.props.token, this.props.slug, textareaContent)
				.then((response) => {
					console.log(response.data);
					this.props.onPostSuccess(response.data);

					const formCopy = clone(this.state.formControls);
					const input = formCopy.post;
					input.value = '';
					input.touched = false;
					input.valid = false;

					this.setState({ formControls: formCopy, formIsValid: false, loading: false });
				})
				.catch((error) => {
					console.dir(error);
				});
		}
	}
	render() {
		const { formControls } = this.state;
		const { type, user } = this.props;
		const photo = user.profile.photo && user.profile.photo.small;
		const replyClass = type === 'reply' ? classes['Container--reply'] : '';
		return (
			<div className={[ classes.Container, replyClass ].join(' ')}>
				<Avatar src={photo} className={classes.Avatar} />
				<Form onSubmit={(e) => this.submit(e)}>
					<Input
						className={classes.TextControl}
						config={formControls.post}
						onChange={(e) => this.inputChangeHandler(e, 'post')}
						onBlur={(e) => this.inputBlurHandler(e, 'post')}
					/>
					<Button
						className={classes.SubmitButton}
						color="secondary"
						type="submit"
						disabled={this.state.loading || !this.state.formIsValid}
						loading={Number(this.state.loading)}>
						Submit
					</Button>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

export default connect(mapStateToProps)(PostComment);
