import React from "react";
import { connect } from "react-redux";
import ReactSVG from "react-svg";
import Hidden from "@material-ui/core/Hidden";
import MessageSidebar from "../../components/Message/MessageSidebar";
import MessageTitle from "../../components/Message/MessageTitle";
import MessageChat from "../../components/Message/MessageChat";
import MessageForm from "../../components/Message/MessageForm";
import MessageThreadsDrawer from "../../components/Message/Drawer";
import * as messageService from "../../services/message/messageService";
import Button from "../../components/UI/Button/Button";
import { EventSourcePolyfill } from "event-source-polyfill";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./Message.module.scss";
import Modal from "../../components/UI/Modal/Modal";
import IconMessage from "./../../assets/images/icons/messages_icon.svg";
import * as actions from "../../store/actions/indexActions";
import apiConfig from "../../utils/apiConfig";
import IconArrowBack from "./../../assets/images/icons/arrow_left_icon.svg";
import clone from "lodash.clonedeep";
import { errorParser } from "../../utils/errorParser";

class Message extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mesRef = React.createRef();
  }
  state = {
    threads: [],
    selectedThread: null,
    receiverCryptName: null,
    messageText: null,
    messages: [],
    loading: true,
    loaderMessages: false,
    loadMoreDisabled: false,
    modalConfirmOpened: false,
    modalImageOpened: false,
    uploadingAttachment: false,
    attachments: [],
    imageAttachmentPaths: [],
    fileAttachmentNames: [],
    modalImagePath: null,
    threadDrawerOpen: true,
    receiverObject: {},
    sendDisabled: false,
  };

  toggleThreadDrawer = () => {
    this.setState({ threadDrawerOpen: !this.state.threadDrawerOpen });
  };

  scrollToBottom = () => {
    if (this.mesRef && this.mesRef.current) {
      this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
    }
  };

  handleScroll = () => {
    if (
      this.mesRef &&
      this.mesRef.current.scrollTop < 5 &&
      !this.state.loadMoreDisabled
    ) {
      this.setState(
        (prevState) => ({ loadMoreDisabled: true }),
        () =>
          this.getThreadMessages(
            this.state.selectedThread,
            this.state.receiverCryptName,
            true
          )
      );
    }
  };

  componentWillUnmount() {
    if (this.es) {
      this.es.close();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.cryptName !== "list" &&
      this.props.match.params.cryptName === "list"
    ) {
      if (this.state.threads.length > 0) {
        this.props.history.push(
          `/messages/${prevProps.match.params.cryptName}`
        );
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const url =
      process.env.REACT_APP_MERCURE_URL +
      "/.well-known/mercure?topic=" +
      encodeURIComponent(
        "https://infodepot.com/messages/" + this.props.userData.cryptName
      );
    const es = new EventSourcePolyfill(url, {
      headers: {
        Authorization: "Bearer " + this.props.userData.jwtTokenMercure,
      },
    });

    messageService
      .getThreads(this.props.token)
      .then((response) => {
        this.setState(
          () => ({ threads: response.data }),
          () => this.setActiveThread()
        );
      })
      .catch((error) => {});

    this.es = es;

    es.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const message = JSON.parse(data.message);

      if (this.state.selectedThread === message.thread.id) {
        message.seen = true;
        this.setState({
          messages: [...this.state.messages, message],
        });
        messageService
          .markMessageAsSeen(this.props.token, message.id)
          .catch((error) => {});
        this.scrollToBottom();
      }
      this.injectLastMessage(message); //last message is show on sidebar single thread
    };
  }

  injectLastMessage = (message) => {
    const logedInUserCryptName = this.props.userData.cryptName;
    const threadForInjectingMessage = this.state.threads.find(
      (thread) => thread.id === message.thread.id
    );
    if (threadForInjectingMessage) {
      threadForInjectingMessage.lastMessage = message;
      this.setState((prevState) => ({
        threads: [
          threadForInjectingMessage,
          ...prevState.threads.filter(
            (item) => item.id !== threadForInjectingMessage.id
          ),
        ],
      }));
    } else {
      if (this.state.threads.length > 0) {
        messageService
          .getThreads(this.props.token)
          .then((response) => {
            this.setState({ threads: response.data });
          })
          .catch((error) => {});
      } else {
        messageService
          .getThreads(this.props.token)
          .then((response) => {
            this.setState(
              () => ({ threads: response.data }),
              () => {
                const thread = message.thread;
                const author = thread.author;
                const receiver = thread.receiver;
                this.getThreadMessages(
                  thread.id,
                  author.cryptName === logedInUserCryptName
                    ? receiver.cryptName
                    : author.cryptName
                );
              }
            );
          })
          .catch((error) => {});
      }
    }
  };

  setActiveThread = () => {
    const cryptName = this.props.match.params.cryptName;
    const logedInUserCryptName = this.props.userData.cryptName;
    console.log(cryptName);
    if (cryptName !== "list") {
      this.setState({ threadDrawerOpen: false });
      let threadFound = false;
      this.state.threads.find((thread) => {
        const author = thread.author;
        const receiver = thread.receiver;
        if (
          (author.cryptName === cryptName &&
            receiver.cryptName === logedInUserCryptName) ||
          (receiver.cryptName === cryptName &&
            author.cryptName === logedInUserCryptName)
        ) {
          threadFound = true;
          this.getThreadMessages(
            thread.id,
            author.cryptName === logedInUserCryptName
              ? receiver.cryptName
              : author.cryptName
          );
        }
      });
      if (!threadFound) {
        messageService
          .createThread(this.props.token, cryptName)
          .then((response) => {
            const thread = response.data;
            this.setState((prevState) => {
              return {
                threads: [thread, ...prevState.threads],
                receiverObject: { ...thread.receiver },
              };
            });
            this.getThreadMessages(thread.id, cryptName);
          })
          .catch((error) => {});
      }
    } else {
      if (this.state.threads.length > 0) {
        const thread = this.state.threads[0];
        const author = thread.author;
        const receiver = thread.receiver;
        this.getThreadMessages(
          thread.id,
          author.cryptName === logedInUserCryptName
            ? receiver.cryptName
            : author.cryptName
        );
      } else {
        this.setState({ loading: false });
      }
    }
  };

  getThreadMessages = (threadId, receiverCryptName, update = false) => {
    if (!update) {
      this.setState({ loaderMessages: true, loadMoreDisabled: false });
    }
    messageService
      .getThreadMessages(
        this.props.token,
        threadId,
        30,
        !update ? 0 : this.state.messages.length
      )
      .then((response) => {
        if (response.data.length > 0) {
          if (
            response.data[0].thread.author.cryptName ===
            this.props.userData.cryptName
          ) {
            this.setState({
              receiverObject: { ...response.data[0].thread.receiver },
            });
          } else {
            this.setState({
              receiverObject: { ...response.data[0].thread.author },
            });
          }
        }
        if (!update) {
          this.props.history.push(`/messages/${receiverCryptName}`);
          this.setState((prevState) => ({
            messages: response.data,
            selectedThread: threadId,
            receiverCryptName: receiverCryptName,
            loaderMessages: false,
            loading: false,
            fileAttachmentNames: [],
            imageAttachmentPaths: [],
            attachments: [],
            threads: prevState.threads.map((thread) => {
              if (thread.id === threadId) {
                const threadMessage = clone(thread);
                if (threadMessage.lastMessage) {
                  threadMessage.lastMessage.seen = true;
                }
                return threadMessage;
              }
              return thread;
            }),
          }));
          this.scrollToBottom();
        } else {
          response.data.length < 1
            ? this.setState({ loadMoreDisabled: true })
            : this.setState({ loadMoreDisabled: false });
          this.setState(
            () => ({
              messages: [...response.data, ...this.state.messages],
              selectedThread: threadId,
              receiverCryptName: receiverCryptName,
              loaderMessages: false,
              fileAttachmentNames: [],
              imageAttachmentPaths: [],
              attachments: [],
            }),
            () => {
              if (this.mesRef) {
                this.mesRef.current.scrollTop = 7;
              }
            }
          );
        }
      })
      .catch((error) => {});
  };

  createMessage = () => {
    if (
      !this.state.receiverCryptName ||
      !this.state.selectedThread ||
      (!this.state.messageText && !this.state.attachments.length > 0) ||
      this.state.sendDisabled
    ) {
      return;
    }
    this.setState({ sendDisabled: true });
    messageService
      .createMessage(
        this.props.token,
        this.state.receiverCryptName,
        this.state.messageText,
        this.state.attachments
      )
      .then((response) => {
        this.setState((prevState) => ({
          messages: [...prevState.messages, response.data],
          messageText: "",
          fileAttachmentNames: [],
          imageAttachmentPaths: [],
          attachments: [],
          sendDisabled: false,
        }));
        this.injectLastMessage(response.data);
        this.scrollToBottom();
      })
      .catch((error) => {
        this.setState({ sendDisabled: false });
        const apiErr = errorParser(error.response.data.errors);
        if (apiErr.formBodyError && apiErr.formBodyError.length > 0) {
          this.showError(apiErr.formBodyError[0]);
        } else {
          this.showError("Error happened, please try again.");
        }
      });
  };

  deleteThread = () => {
    this.setState({ loading: true });
    this.closeConfirmModal();
    messageService
      .deleteThread(this.props.token, this.state.selectedThread)
      .then((response) => {
        this.props.snackbarAdd({
          message: "You have successfully deleted the chat from your inbox.",
          timeout: 8000,
        });
        this.setState((prevState) => ({
          loading: false,
          messageText: "",
          fileAttachmentNames: [],
          imageAttachmentPaths: [],
          attachments: [],
          threads: prevState.threads.filter(
            (thread) => thread.id !== this.state.selectedThread
          ),
        }));
        if (this.state.threads.length > 0) {
          const thread = this.state.threads[0];
          const author = thread.author;
          const receiver = thread.receiver;
          this.getThreadMessages(
            thread.id,
            author.cryptName === this.props.userData.cryptName
              ? receiver.cryptName
              : author.cryptName
          );
        } else {
          this.props.history.push(`/messages/list`);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.showError("Error happened, please try again.");
      });
  };

  closeConfirmModal = () => {
    this.setState({ modalConfirmOpened: false });
  };

  openConfirmModal = () => {
    this.setState({ modalConfirmOpened: true });
  };

  openImageModal = (modalImagePath) => {
    this.setState({ modalImageOpened: true, modalImagePath: modalImagePath });
  };

  closeImageModal = () => {
    this.setState({ modalImageOpened: false });
  };

  inputChangeHandler = (e, name) => {
    this.setState({ messageText: e.target.value });
  };

  onImageChange = (e) => {
    let file = e.target.files[0];

    if (this.validImage(e, file) && this.state.selectedThread) {
      this.setState({ uploadingAttachment: true });

      // upload call to api
      messageService
        .uploadImage(this.props.token, file)
        .then((response) => {
          this.setState({
            uploadingAttachment: false,
            imageAttachmentPaths: [
              ...this.state.imageAttachmentPaths,
              { id: response.data.id, path: response.data.image.reference },
            ],
            attachments: [...this.state.attachments, response.data.id],
          });
        })
        .catch((err) => {
          this.setState({ uploadingAttachment: false });
          this.showError(err.response.data && err.response.data.message);
        });
    }
  };

  onFileChange = (e) => {
    let file = e.target.files[0];

    if (this.validFile(e, file) && this.state.selectedThread) {
      this.setState({ uploadingAttachment: true });

      messageService
        .uploadFile(this.props.token, file)
        .then((response) => {
          this.setState({
            uploadingAttachment: false,
            fileAttachmentNames: [
              ...this.state.fileAttachmentNames,
              { id: response.data.id, name: response.data.file.fileName },
            ],
            attachments: [...this.state.attachments, response.data.id],
          });
        })
        .catch((err) => {
          this.setState({ uploadingAttachment: false });
          this.showError(err.response.data && err.response.data.message);
        });
    }
  };

  validImage = (e, file) => {
    const fileFormats = ["image/jpg", "image/jpeg", "image/png", "image/x-png"];

    if (file) {
      let errorMessage = "";
      if (file.size > 20 * 1024 * 1024) {
        errorMessage = "This file is too big. Please upload a file up to 20MB.";
      } else if (!fileFormats.includes(file.type)) {
        errorMessage = `Please upload one of the following formats 'JPG', 'JPEG', 'PNG'.`;
      }

      if (errorMessage) {
        this.showError(errorMessage);
        return false;
      } else {
        return true;
      }
    }
  };

  validFile = (e, file) => {
    const fileFormats = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.presentation",
      "image/psd",
      "image/x-photoshop",
      "application/photoshop",
      "zz-application/zz-winassoc-psd",
      "application/psd",
      "image/vnd.adobe.photoshop",
      "application/x-photoshop",
      "application/x-compressed",
      "application/x-zip-compressed",
      "application/zip",
      "multipart/x-zip",
      "application/x-gzip",
      "multipart/x-gzip",
      "application/x-rar-compressed",
      "application/x-7z-compressed",
      "application/x-rar",
      "application/vnd.rar",
      "application/rar",
      "application/octet-stream",
    ];

    if (file) {
      let errorMessage = "";
      if (file.size > 20 * 1024 * 1024) {
        errorMessage = "This file is too big. Please upload a file up to 20MB.";
      } else if (
        !fileFormats.includes(file.type) &&
        file.name.split(".").pop() !== "rar"
      ) {
        errorMessage = `Please upload one of the following formats 'PDF', 'DOC', 'XLS', 'PSD', 'PPT', 'ZIP', 'RAR'.`;
      }

      if (errorMessage) {
        this.showError(errorMessage);
        return false;
      } else {
        return true;
      }
    }
  };

  deleteAttachment = (id) => {
    messageService
      .deleteAttachment(this.props.token, id)
      .then((response) => {
        this.setState((prevState) => {
          return {
            imageAttachmentPaths: prevState.imageAttachmentPaths.filter(
              (attachment) => attachment.id !== id
            ),
            fileAttachmentNames: prevState.fileAttachmentNames.filter(
              (attachment) => attachment.id !== id
            ),
            attachments: prevState.attachments.filter(
              (attachment) => attachment !== id
            ),
          };
        });
      })
      .catch((err) => {
        this.showError(err.response.data && err.response.data.message);
      });
  };

  showError = (message) => {
    this.props.snackbarAdd({
      message: message,
      type: "error",
      timeout: 8000,
    });
  };

  onClickUpload = (e) => {
    if (this.state.uploadingAttachment) {
      e.preventDefault();
    }
    console.log(this.state.uploadingAttachment)

  };

  onKeyUp = (e) => {
    if (e.charCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.createMessage();
    }
  };

  renderConfirmModal = () => {
    return (
      <Modal
        className={[classes.Card, classes.CardWithBorder].join(" ")}
        onClose={this.closeConfirmModal}
        open={this.state.modalConfirmOpened}
      >
        <h3 style={{ fontSize: "30px", color: "#272727" }}>
          Delete chat from your inbox?
        </h3>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              onClick={(e) => {
                this.deleteThread();
              }}
              style={{ marginRight: "20px" }}
            >
              <Button
                className={classes.RedBtn}
                size="large"
                color="secondary"
                variant="contained"
              >
                YES, DELETE
              </Button>
            </div>
            <Button
              className={classes.ButtonLink}
              color="secondary"
              variant="transparent"
              type="link"
              size="large"
              onClick={this.closeConfirmModal}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  renderImageViewModal = () => {
    return (
      <Modal
        className={[classes.Card, classes.CardMediaModal].join(" ")}
        onClose={this.closeImageModal}
        open={this.state.modalImageOpened}
        maxWidth={'md'}
      >
        <div>
          <img
            src={this.state.modalImagePath}
            alt={""}
          />
        </div>
      </Modal>
    );
  };

  downloadFileAttachment(id, filename) {
    messageService
      .downloadDocument(this.props.token, id)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((error) => {
        this.props.snackbarAdd({
          message: error.response.statusText,
          type: "error",
          timeout: 8000,
        });
      });
  }

  render() {
    const loaderRender = <CircularProgress className={classes.Loader} />;
    const renderContent = (
      <>
        <div
          className={classes.mainContainer}
          style={{ display: "flex", posiiton: "relative" }}
        >
          <Hidden mdUp>
            <div
              onClick={() => this.toggleThreadDrawer()}
              className={classes.messageResponsivehamburger}
            >
              <ReactSVG src={IconArrowBack} className={classes.IconHamburger} />
            </div>
          </Hidden>
          <Hidden mdDown>
            <MessageSidebar
              threads={this.state.threads}
              selectedThread={this.state.selectedThread}
              loggedInUser={this.props.userData}
              getMessages={this.getThreadMessages}
            />
          </Hidden>

          {this.state.loaderMessages && loaderRender}

          <div
            className={classes.messagesContainer}
            style={{ position: "relative" }}
          >
            {this.state.threads.length > 0 ? (
              <>
                <div className={classes.messageChatContainer}>
                  {this.state.selectedThread && (
                    <>
                      <MessageTitle
                        user={this.props.userData}
                        selectedThread={this.state.selectedThread}
                        openConfirmModal={() => this.openConfirmModal()}
                        receiverObject={this.state.receiverObject}
                      />
                      <div
                        className={classes.messageChatHolder}
                        onScroll={this.handleScroll}
                        ref={this.mesRef}
                      >
                        {this.state.messages.map((message, i) => {
                          const isAuthor =
                            message.author.cryptName ===
                            this.props.userData.cryptName;
                          return (
                            <>
                              <MessageChat
                                key={i}
                                message={message}
                                isAuthor={isAuthor}
                                apiConfig={apiConfig}
                                openImageModal={this.openImageModal}
                                // mesRef={this.mesRef}
                                fileDownload={(id, fileName) =>
                                  this.downloadFileAttachment(id, fileName)
                                }
                              />
                            </>
                          );
                        })}
                        <div ref={this.ref}></div>
                      </div>
                      <MessageForm
                        apiConfig={apiConfig}
                        imageAttachmentPaths={this.state.imageAttachmentPaths}
                        fileAttachmentNames={this.state.fileAttachmentNames}
                        onImageChange={this.onImageChange}
                        onClickUpload={this.onClickUpload}
                        onFileChange={this.onFileChange}
                        onKeyUp={this.onKeyUp}
                        inputChangeHandler={this.inputChangeHandler}
                        deleteAttachment={this.deleteAttachment}
                        messageText={this.state.messageText}
                        createMessage={this.createMessage}
                        loaderRender={loaderRender}
                        uploadingAttachment={this.state.uploadingAttachment}
                      />
                    </>
                  )}
                </div>
                {this.renderConfirmModal()}
                {this.renderImageViewModal()}
              </>
            ) : (
              <div className={classes.messagesContainer}>
                <div style={{ height: "80px" }}></div>
                <div className={classes.messageChatHolder}>
                  <div className={classes.empty}>
                    <ReactSVG
                      svgClassName={classes.IconMessage}
                      src={IconMessage}
                    />
                    <p
                      style={{
                        fontSize: "20px",
                        marginBottom: "10px",
                        fontWeight: "500",
                      }}
                    >
                      No messages yet.
                    </p>
                    <p style={{ fontSize: "18px", textAlign: "center" }}>
                      Start a conversation with a teacher from their video
                      class/live call page.
                    </p>
                  </div>
                </div>
                <div style={{ height: "27px" }}></div>
              </div>
            )}
          </div>
          <Hidden mdUp>
            <MessageThreadsDrawer
              open={this.state.threadDrawerOpen}
              onClose={this.toggleThreadDrawer}
              wide
              disableBackdropClick
            >
              <div toggleCompanyDrawer={this.toggleThreadDrawer} />
              {this.state.threads.length > 0 ? (
                <MessageSidebar
                  threads={this.state.threads}
                  selectedThread={this.state.selectedThread}
                  loggedInUser={this.props.userData}
                  getMessages={this.getThreadMessages}
                  onClose={this.toggleThreadDrawer}
                />
              ) : (
                <div className={classes.empty} style={{ paddingTop: "50px" }}>
                  <ReactSVG
                    svgClassName={classes.IconMessage}
                    src={IconMessage}
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      marginBottom: "10px",
                      fontWeight: "500",
                    }}
                  >
                    No messages yet.
                  </p>
                  <p style={{ fontSize: "18px", textAlign: "center" }}>
                    Start a conversation with a teacher from their video
                    class/live call page.
                  </p>
                </div>
              )}
            </MessageThreadsDrawer>
          </Hidden>
        </div>
      </>
    );
    return <>{this.state.loading ? loaderRender : renderContent}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
