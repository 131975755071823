import React, { Component } from 'react';

import classes from './CheckYourEmail.module.scss';
import Card from './../../components/UI/Card/Card';
import PlaneIcon from '../../assets/images/icons/email sent icon.svg';

class CheckYourEmail extends Component {
	componentDidMount() {

		if(!this.props.location.state) {
			this.props.history.push('/')
		}
 	}

	renderMessage() {
	const forgotPassCheckEmailMessage = `We have just sent instructions for resseting your password to your email address. Please go to your inbox and follow the instructions.`;
	const registerCheckEmailMessage = `We have just sent activation link to your email address. Please go to your inbox and click on the link to activate your InfoDepot account.`;

	const checkPath = () => this.props.location.state.urlParams === '/forgot-password';;

	if (checkPath()) {
		return forgotPassCheckEmailMessage;
	} else {
		return registerCheckEmailMessage;
	}

	}

	render() {
		return (
			<div className={classes.Container}>

				<h1>Check your email inbox!</h1>
				<Card className={classes.Card}>
					<img className={classes.Icon} src={PlaneIcon} alt="paper plane" />
					<p className={classes.TextContent}>
						{this.renderMessage()}
					</p>
				</Card>
			</div>
		);
	}
}

export default CheckYourEmail;
