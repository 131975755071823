import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import ReactSVG from "react-svg";
import windowWidth from "react-window-size";
import * as screen from "../../../utils/common.js";

import classes from "./Header.module.scss";
import Logo from "./../../../assets/images/logo/InfoDepot_logo.svg";
import IconMessage from "./../../../assets/images/icons/messages_icon.svg";
import Button from "../../UI/Button/Button";
import Divider from "../../UI/Divider/Divider";
import NavDropdown from "./NavDropdown/NavDropdown";
import IconCoins from "../../../assets/images/icons/credits_icon.svg";
import IconHamburger from "../../../assets/images/icons/menu_hamburger_icon.svg";
import IconClose from "../../../assets/images/icons/menu_close_icon.svg";
import IDSlide from "../../UI/Slide/Slide";
import SlideLogo from "../../../assets/images/logo/InfoDepot_logo_White.png";

const BREAKPOINT_FOR_HEADER = 886;
const BREAKPOINT_FOR_HEADER_TEXT = 1199;

class Header extends Component {
  state = {
    checked: false,
  };

  //   handleClickContestRemoveFlag = (e) => {
  //     if (localStorage.getItem("contestPage")) {
  //       localStorage.removeItem("contestPage");
  //     }
  //   };
  render() {
    this.closeSlide = () => {
      this.setState({ checked: !this.state.checked });
    };

    const screenWidth = this.props.windowWidth;

    const loggedUser = this.props.userData;
    const isTeacher =
      this.props.userData && this.props.userData.type === "teacher";
    const isStudent =
      this.props.userData && this.props.userData.type === "student";

    const publicNavLinks =
      screenWidth > BREAKPOINT_FOR_HEADER ? (
        <React.Fragment>
          <div className={screenWidth > BREAKPOINT_FOR_HEADER ? classes.MobileNav : ""} />
          {/* <NavLink
					className={classes.navLink}
					activeClassName={classes.activeLink}
					to={'/contest'}>
					Contest
				</NavLink> */}

          <NavLink
            className={classes.navLink}
            activeClassName={classes.activeLink}
            to="/browse-infodepot"
          >
            Browse classes
          </NavLink>
          <NavLink
            className={classes.navLink}
            activeClassName={classes.activeLink}
            to={"/become-a-teacher"}
          >
            Become a teacher
          </NavLink>
          <a
            href="/blog"
            className={classes.navLink}
            activeClassName={classes.activeLink}
          >
            Read our blog
          </a>
          <Link to={"/auth"} className={classes.AuthLink}>
            <Button
              //   onClick={this.handleClickContestRemoveFlag}
              type={"outline"}
            >
              Sign in
            </Button>
          </Link>
          <Link to={"/sign-up"} className={classes.AuthLink}>
            <Button
              //   onClick={this.handleClickContestRemoveFlag}
              type={"primary"}
            >
              Join now for free
            </Button>
          </Link>
        </React.Fragment>
      ) : (
        <IDSlide
          logo={
            <img src={SlideLogo} className={classes.SlideLogo} alt={"Logo"} />
          }
          closeIcon={<ReactSVG src={IconClose} className={classes.IconClose} />}
          slideOpenIcon={
            <ReactSVG src={IconHamburger} className={classes.IconHamburger} />
          }
          className={classes.NavPaper}
          // closeSlide={this.closeSlide}
        >
          <div className={classes.NonLogedInMobileNavigation}>
            {/* <NavLink
              className={classes.navLink}
              activeClassName={classes.activeLink}
              to="/contest"
            >
              Contest
            </NavLink> */}
            <NavLink
              className={classes.navLink}
              activeClassName={classes.activeLink}
              to="/browse-infodepot"
            >
              Browse classes
            </NavLink>
            <NavLink
              className={classes.navLink}
              activeClassName={classes.activeLink}
              to={"/become-a-teacher"}
            >
              Become a teacher
            </NavLink>
            <a
              href="/blog"
              className={classes.navLink}
              activeClassName={classes.activeLink}
            >
              Read our blog
            </a>
            <Link to={"/auth"}>
              <Button
                onClick={this.handleClickContestRemoveFlag}
                className={classes.AuthButton}
                color={"#fff"}
                size={"large"}
                type={"outline"}
              >
                Sign in
              </Button>
            </Link>
            <Link to={"/sign-up"}>
              <Button
                onClick={this.handleClickContestRemoveFlag}
                className={classes.ButtonDark}
                size={"large"}
              >
                Join now for free
              </Button>
            </Link>
          </div>
        </IDSlide>
      );

    const loggedUserNavLinks = () =>
      screenWidth > BREAKPOINT_FOR_HEADER ? (
        <React.Fragment>
          {/* <NavLink
					className={classes.navLink}
					activeClassName={classes.activeLink}
					to={'/contest'}>
					Contest
				</NavLink> */}
          {/* <a
            href="/contest"
            className={classes.navLink}
            activeClassName={classes.activeLink}
          >
            Contest
          </a> */}
          <Divider className={classes.Divider} />
          <NavLink
            className={classes.navLink}
            activeClassName={classes.activeLink}
            to="/browse-infodepot"
          >
            {screenWidth > BREAKPOINT_FOR_HEADER_TEXT ? "Browse classes" : "Browse"}
          </NavLink>
          <Divider className={classes.Divider} />
          <a
            href="/blog"
            className={classes.navLink}
            activeClassName={classes.activeLink}
          >
            {screenWidth > BREAKPOINT_FOR_HEADER_TEXT ? "Read our blog" : "Blog"}
          </a>
          <Divider className={classes.Divider} />
          <NavLink
            activeClassName={classes.activeLink}
            to="/balance"
            className={[classes.CurrentCredits, classes.navLink].join(" ")}
          >
            <ReactSVG className={classes.IconCredits} src={IconCoins} />
            {screenWidth > BREAKPOINT_FOR_HEADER_TEXT ? 
              <>
                <span style={{ textTransform: "capitalize" }}>Balance</span>
                <span>{`: $${loggedUser.totalCredits.toFixed(2)}`}</span>
              </> : 
              <span>{`$${loggedUser.totalCredits.toFixed(2)}`}</span>
            }
          </NavLink>
          <Divider className={classes.Divider} />
          <NavLink
            activeClassName={classes.activeLink}
            to="/messages/list"
            className={classes.navLink}
          >
            <>
              {this.props.notification.newMessageNotification && (
                <div class={classes.MessageNew}></div>
              )}
              <ReactSVG svgClassName={classes.IconMessage} src={IconMessage} />
            </>
          </NavLink>
          <Divider className={classes.Divider} />
          {/* teacher restricted links */}
          {isTeacher ? (
            <React.Fragment>
              <Link to="/start-teaching">
                <Button>Start teaching</Button>
              </Link>

              <Divider className={classes.Divider} />
            </React.Fragment>
          ) : null}
          <NavDropdown />
        </React.Fragment>
      ) : (
        // Mobile Nav
        <React.Fragment>
          <div className={classes.MobileNav}>
            {/* logo */}
            {isStudent === true || (isTeacher === true && screenWidth <= BREAKPOINT_FOR_HEADER) ? (
              <Link className={classes.Logo} to={"/"}>
                <div>
                  <img src={Logo} alt="Infodepot" />
                </div>
              </Link>
            ) : (
              ""
            )}

            {/* <NavLink
              className={classes.navLink}
              activeClassName={classes.activeLink}
              to="/contest"
            >
              Contest
            </NavLink> */}
            <NavLink
              className={classes.navLink}
              activeClassName={classes.activeLink}
              to="/browse-infodepot"
            >
              Browse
            </NavLink>
            <a
              href="/blog"
              className={classes.navLink}
              activeClassName={classes.activeLink}
            >
              blog
            </a>
          </div>
          <div className={classes.MobileNav}>
            <NavLink
              activeClassName={classes.activeLink}
              to="/balance"
              className={classes.CurrentCredits}
            >
              <ReactSVG svgClassName={classes.IconCredits} src={IconCoins} />
              {` $${loggedUser.totalCredits.toFixed(2)}`}
            </NavLink>
            <Divider className={classes.Divider} />
            <NavLink
              activeClassName={classes.activeLink}
              to="/messages/list"
              className={classes.navLink}
            >
              <>
                {this.props.notification.newMessageNotification && (
                  <div class={classes.MessageNew}></div>
                )}
                <ReactSVG
                  svgClassName={classes.IconMessage}
                  src={IconMessage}
                />
              </>
            </NavLink>
            <Divider className={classes.Divider} />
            {/* teacher restricted links */}
            {isTeacher ? (
              <React.Fragment>
                <Link to="/start-teaching">
                  <Button className={classes.Button}>Teach</Button>
                </Link>
                <Divider className={classes.Divider} />
              </React.Fragment>
            ) : null}
            <NavDropdown />
          </div>
        </React.Fragment>
      );

    return (
      <header
        id="infodepot_header"
        className={
          isStudent || isTeacher ? classes.Header : classes.NonLogedInHeader
        }
      >
        <div className={classes.LogoWrap}>
          {isStudent === null || (isTeacher === null && screenWidth <= BREAKPOINT_FOR_HEADER) ? (
            <Link className={classes.Logo} to={"/"}>
              <div>
                <img src={Logo} alt="Infodepot" />
              </div>
            </Link>
          ) : (
            ""
          )}

          {isTeacher === true && screenWidth > BREAKPOINT_FOR_HEADER ? (
            <Link className={classes.Logo} to={"/"}>
              <div>
                <img src={Logo} alt="Infodepot" />
              </div>
            </Link>
          ) : (
            ""
          )}

          {isStudent === true && screenWidth > BREAKPOINT_FOR_HEADER ? (
            <Link className={classes.Logo} to={"/"}>
              <div>
                <img src={Logo} alt="Infodepot" />
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>

        {/* navigation links */}
        <nav className={!loggedUser ? classes.NavNoDividers : ""}>
          {loggedUser ? loggedUserNavLinks() : publicNavLinks}
        </nav>

        {this.props.children}
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    notification: state.notification,
  };
};

export default connect(mapStateToProps)(windowWidth(Header));
