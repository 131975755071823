import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import clone from 'lodash.clonedeep';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import classes from './SetUpLiveCall.module.scss';
import * as actions from '../../store/actions/indexActions';
import Card from '../../components/UI/Card/Card';
import Button from '../../components/UI/Button/Button';
import Divider from '../../components/UI/Divider/Divider';
import { Form, Input, Checkbox } from '../../components/UI/Form/Form';
//import { Form, Checkbox } from '../../components/UI/Form/Form';
import * as validator from '../../utils/formValidator';
import { errorParser } from '../../utils/errorParser';
import * as callService from '../../services/liveCall/liveCallService';
import * as configurationService from '../../services/configuration/configurationService';

import IconImage from '../../assets/images/icons/image.svg';
import IconCalendar from '../../assets/images/icons/calendar_icon.svg';
import IconClock from '../../assets/images/icons/clock_icon.svg';
import IconCheckmark from '../../assets/images/icons/checkmark_icon.svg'
import IconCheckmarkLight from '../../assets/images/icons/check-mark-icon.svg';
import IconVideo from '../../assets/images/icons/video-solid.svg';
import IconLiveCalls from '../../assets/images/icons/live_call_icon.svg';
import apiConfig from '../../utils/apiConfig';
import { addToUrlQueue } from '../../utils/redirectTo';
import PromptToUpgradeSubscription from '../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';

// Stored form data in case returned from settings
let storedFormData;
// Static data
let categories;
const formatSubCategory = (list) =>
	Object.keys(list).map((categoryName) => {
		return {
			label: list[categoryName].name,
			value: list[categoryName].slug
		};
	});
const coverImageFormats = [ 'image/jpg', 'image/jpeg', 'image/png', 'image/x-png' ];
const calendarIcon = <ReactSVG src={IconCalendar} className={classes.InputIcon} />;
const clockIcon = (
	<ReactSVG src={IconClock} style={{ marginLeft: '7px' }} className={classes.InputIcon} />
);
const hoursList = [
	{ label: '00:00', value: '00:00' },
	{ label: '00:30', value: '00:30' },
	{ label: '01:00', value: '01:00' },
	{ label: '01:30', value: '01:30' },
	{ label: '02:00', value: '02:00' },
	{ label: '02:30', value: '02:30' },
	{ label: '03:00', value: '03:00' },
	{ label: '03:30', value: '03:30' },
	{ label: '04:00', value: '04:00' },
	{ label: '04:30', value: '04:30' },
	{ label: '05:00', value: '05:00' },
	{ label: '05:30', value: '05:30' },
	{ label: '06:00', value: '06:00' },
	{ label: '06:30', value: '06:30' },
	{ label: '07:00', value: '07:00' },
	{ label: '07:30', value: '07:30' },
	{ label: '08:00', value: '08:00' },
	{ label: '08:30', value: '08:30' },
	{ label: '09:00', value: '09:00' },
	{ label: '09:30', value: '09:30' },
	{ label: '10:00', value: '10:00' },
	{ label: '10:30', value: '10:30' },
	{ label: '11:00', value: '11:00' },
	{ label: '11:30', value: '11:30' },
	{ label: '12:00', value: '12:00' },
	{ label: '12:30', value: '12:30' },
	{ label: '13:00', value: '13:00' },
	{ label: '13:30', value: '13:30' },
	{ label: '14:00', value: '14:00' },
	{ label: '14:30', value: '14:30' },
	{ label: '15:00', value: '15:00' },
	{ label: '15:30', value: '15:30' },
	{ label: '16:00', value: '16:00' },
	{ label: '16:30', value: '16:30' },
	{ label: '17:00', value: '17:00' },
	{ label: '17:30', value: '17:30' },
	{ label: '18:00', value: '18:00' },
	{ label: '18:30', value: '18:30' },
	{ label: '19:00', value: '19:00' },
	{ label: '19:30', value: '19:30' },
	{ label: '20:00', value: '20:00' },
	{ label: '20:30', value: '20:30' },
	{ label: '21:00', value: '21:00' },
	{ label: '21:30', value: '21:30' },
	{ label: '22:00', value: '22:00' },
	{ label: '22:30', value: '22:30' },
	{ label: '23:00', value: '23:00' },
	{ label: '23:30', value: '23:30' },
	{ label: '23:59', value: '23:59' }
];
const timeZonesList = [
	{
		label: '(GMT-11:00) Midway Island',
		value: 'Pacific/Midway'
	},
	{
		label: '(GMT-11:00) Samoa',
		value: 'US/Samoa'
	},
	{
		label: '(GMT-10:00) Hawaii',
		value: 'US/Hawaii'
	},
	{
		label: '(GMT-09:00) Alaska',
		value: 'US/Alaska'
	},
	{
		label: '(GMT-08:00) Pacific Time (US & Canada)',
		value: 'US/Pacific'
	},
	{
		label: '(GMT-08:00) Tijuana',
		value: 'America/Tijuana'
	},
	{
		label: '(GMT-07:00) Arizona',
		value: 'US/Arizona'
	},
	{
		label: '(GMT-07:00) Mountain Time (US & Canada)',
		value: 'US/Mountain'
	},
	{
		label: '(GMT-07:00) Chihuahua',
		value: 'America/Chihuahua'
	},
	{
		label: '(GMT-07:00) Mazatlan',
		value: 'America/Mazatlan'
	},
	{
		label: '(GMT-06:00) Mexico City',
		value: 'America/Mexico_City'
	},
	{
		label: '(GMT-06:00) Monterrey',
		value: 'America/Monterrey'
	},
	{
		label: '(GMT-06:00) Saskatchewan',
		value: 'Canada/Saskatchewan'
	},
	{
		label: '(GMT-06:00) Central Time (US & Canada)',
		value: 'US/Central'
	},
	{
		label: '(GMT-05:00) Eastern Time (US & Canada)',
		value: 'US/Eastern'
	},
	{
		label: '(GMT-05:00) Indiana (East)',
		value: 'US/East-Indiana'
	},
	{
		label: '(GMT-05:00) Bogota',
		value: 'America/Bogota'
	},
	{
		label: '(GMT-05:00) Lima',
		value: 'America/Lima'
	},
	{
		label: '(GMT-04:30) Caracas',
		value: 'America/Caracas'
	},
	{
		label: '(GMT-04:00) Atlantic Time (Canada)',
		value: 'Canada/Atlantic'
	},
	{
		label: '(GMT-04:00) La Paz',
		value: 'America/La_Paz'
	},
	{
		label: '(GMT-04:00) Santiago',
		value: 'America/Santiago'
	},
	{
		label: '(GMT-03:30) Newfoundland',
		value: 'Canada/Newfoundland'
	},
	{
		label: '(GMT-03:00) Buenos Aires',
		value: 'America/Buenos_Aires'
	},
	{
		label: '(GMT-03:00) Greenland',
		value: 'Greenland'
	},
	{
		label: '(GMT-02:00) Stanley',
		value: 'Atlantic/Stanley'
	},
	{
		label: '(GMT-01:00) Azores',
		value: 'Atlantic/Azores'
	},
	{
		label: '(GMT-01:00) Cape Verde Is.',
		value: 'Atlantic/Cape_Verde'
	},
	{
		label: '(GMT) Casablanca',
		value: 'Africa/Casablanca'
	},
	{
		label: '(GMT) Dublin',
		value: 'Europe/Dublin'
	},
	{
		label: '(GMT) Lisbon',
		value: 'Europe/Lisbon'
	},
	{
		label: '(GMT) London',
		value: 'Europe/London'
	},
	{
		label: '(GMT) Monrovia',
		value: 'Africa/Monrovia'
	},
	{
		label: '(GMT+01:00) Amsterdam',
		value: 'Europe/Amsterdam'
	},
	{
		label: '(GMT+01:00) Belgrade',
		value: 'Europe/Belgrade'
	},
	{
		label: '(GMT+01:00) Berlin',
		value: 'Europe/Berlin'
	},
	{
		label: '(GMT+01:00) Bratislava',
		value: 'Europe/Bratislava'
	},
	{
		label: '(GMT+01:00) Brussels',
		value: 'Europe/Brussels'
	},
	{
		label: '(GMT+01:00) Budapest',
		value: 'Europe/Budapest'
	},
	{
		label: '(GMT+01:00) Copenhagen',
		value: 'Europe/Copenhagen'
	},
	{
		label: '(GMT+01:00) Ljubljana',
		value: 'Europe/Ljubljana'
	},
	{
		label: '(GMT+01:00) Madrid',
		value: 'Europe/Madrid'
	},
	{
		label: '(GMT+01:00) Paris',
		value: 'Europe/Paris'
	},
	{
		label: '(GMT+01:00) Prague',
		value: 'Europe/Prague'
	},
	{
		label: '(GMT+01:00) Rome',
		value: 'Europe/Rome'
	},
	{
		label: '(GMT+01:00) Sarajevo',
		value: 'Europe/Sarajevo'
	},
	{
		label: '(GMT+01:00) Skopje',
		value: 'Europe/Skopje'
	},
	{
		label: '(GMT+01:00) Stockholm',
		value: 'Europe/Stockholm'
	},
	{
		label: '(GMT+01:00) Vienna',
		value: 'Europe/Vienna'
	},
	{
		label: '(GMT+01:00) Warsaw',
		value: 'Europe/Warsaw'
	},
	{
		label: '(GMT+01:00) Zagreb',
		value: 'Europe/Zagreb'
	},
	{
		label: '(GMT+02:00) Athens',
		value: 'Europe/Athens'
	},
	{
		label: '(GMT+02:00) Bucharest',
		value: 'Europe/Bucharest'
	},
	{
		label: '(GMT+02:00) Cairo',
		value: 'Africa/Cairo'
	},
	{
		label: '(GMT+02:00) Harare',
		value: 'Africa/Harare'
	},
	{
		label: '(GMT+02:00) Helsinki',
		value: 'Europe/Helsinki'
	},
	{
		label: '(GMT+02:00) Istanbul',
		value: 'Europe/Istanbul'
	},
	{
		label: '(GMT+02:00) Jerusalem',
		value: 'Asia/Jerusalem'
	},
	{
		label: '(GMT+02:00) Kyiv',
		value: 'Europe/Kiev'
	},
	{
		label: '(GMT+02:00) Minsk',
		value: 'Europe/Minsk'
	},
	{
		label: '(GMT+02:00) Riga',
		value: 'Europe/Riga'
	},
	{
		label: '(GMT+02:00) Sofia',
		value: 'Europe/Sofia'
	},
	{
		label: '(GMT+02:00) Tallinn',
		value: 'Europe/Tallinn'
	},
	{
		label: '(GMT+02:00) Vilnius',
		value: 'Europe/Vilnius'
	},
	{
		label: '(GMT+03:00) Baghdad',
		value: 'Asia/Baghdad'
	},
	{
		label: '(GMT+03:00) Kuwait',
		value: 'Asia/Kuwait'
	},
	{
		label: '(GMT+03:00) Nairobi',
		value: 'Africa/Nairobi'
	},
	{
		label: '(GMT+03:00) Riyadh',
		value: 'Asia/Riyadh'
	},
	{
		label: '(GMT+03:00) Moscow',
		value: 'Europe/Moscow'
	},
	{
		label: '(GMT+03:30) Tehran',
		value: 'Asia/Tehran'
	},
	{
		label: '(GMT+04:00) Baku',
		value: 'Asia/Baku'
	},
	{
		label: '(GMT+04:00) Volgograd',
		value: 'Europe/Volgograd'
	},
	{
		label: '(GMT+04:00) Muscat',
		value: 'Asia/Muscat'
	},
	{
		label: '(GMT+04:00) Tbilisi',
		value: 'Asia/Tbilisi'
	},
	{
		label: '(GMT+04:00) Yerevan',
		value: 'Asia/Yerevan'
	},
	{
		label: '(GMT+04:30) Kabul',
		value: 'Asia/Kabul'
	},
	{
		label: '(GMT+05:00) Karachi',
		value: 'Asia/Karachi'
	},
	{
		label: '(GMT+05:00) Tashkent',
		value: 'Asia/Tashkent'
	},
	{
		label: '(GMT+05:30) Kolkata',
		value: 'Asia/Kolkata'
	},
	{
		label: '(GMT+05:45) Kathmandu',
		value: 'Asia/Kathmandu'
	},
	{
		label: '(GMT+06:00) Ekaterinburg',
		value: 'Asia/Yekaterinburg'
	},
	{
		label: '(GMT+06:00) Almaty',
		value: 'Asia/Almaty'
	},
	{
		label: '(GMT+06:00) Dhaka',
		value: 'Asia/Dhaka'
	},
	{
		label: '(GMT+07:00) Novosibirsk',
		value: 'Asia/Novosibirsk'
	},
	{
		label: '(GMT+07:00) Bangkok',
		value: 'Asia/Bangkok'
	},
	{
		label: '(GMT+07:00) Jakarta',
		value: 'Asia/Jakarta'
	},
	{
		label: '(GMT+08:00) Krasnoyarsk',
		value: 'Asia/Krasnoyarsk'
	},
	{
		label: '(GMT+08:00) Chongqing',
		value: 'Asia/Chongqing'
	},
	{
		label: '(GMT+08:00) Hong Kong',
		value: 'Asia/Hong_Kong'
	},
	{
		label: '(GMT+08:00) Kuala Lumpur',
		value: 'Asia/Kuala_Lumpur'
	},
	{
		label: '(GMT+08:00) Perth',
		value: 'Australia/Perth'
	},
	{
		label: '(GMT+08:00) Singapore',
		value: 'Asia/Singapore'
	},
	{
		label: '(GMT+08:00) Taipei',
		value: 'Asia/Taipei'
	},
	{
		label: '(GMT+08:00) Ulaan Bataar',
		value: 'Asia/Ulaanbaatar'
	},
	{
		label: '(GMT+08:00) Urumqi',
		value: 'Asia/Urumqi'
	},
	{
		label: '(GMT+09:00) Irkutsk',
		value: 'Asia/Irkutsk'
	},
	{
		label: '(GMT+09:00) Seoul',
		value: 'Asia/Seoul'
	},
	{
		label: '(GMT+09:00) Tokyo',
		value: 'Asia/Tokyo'
	},
	{
		label: '(GMT+09:30) Adelaide',
		value: 'Australia/Adelaide'
	},
	{
		label: '(GMT+09:30) Darwin',
		value: 'Australia/Darwin'
	},
	{
		label: '(GMT+10:00) Yakutsk',
		value: 'Asia/Yakutsk'
	},
	{
		label: '(GMT+10:00) Brisbane',
		value: 'Australia/Brisbane'
	},
	{
		label: '(GMT+10:00) Canberra',
		value: 'Australia/Canberra'
	},
	{
		label: '(GMT+10:00) Guam',
		value: 'Pacific/Guam'
	},
	{
		label: '(GMT+10:00) Hobart',
		value: 'Australia/Hobart'
	},
	{
		label: '(GMT+10:00) Melbourne',
		value: 'Australia/Melbourne'
	},
	{
		label: '(GMT+10:00) Port Moresby',
		value: 'Pacific/Port_Moresby'
	},
	{
		label: '(GMT+10:00) Sydney',
		value: 'Australia/Sydney'
	},
	{
		label: '(GMT+11:00) Vladivostok',
		value: 'Asia/Vladivostok'
	},
	{
		label: '(GMT+12:00) Magadan',
		value: 'Asia/Magadan'
	},
	{
		label: '(GMT+12:00) Auckland',
		value: 'Pacific/Auckland'
	},
	{
		label: '(GMT+12:00) Fiji',
		value: 'Pacific/Fiji}'
	}
];
// React Component
class SetUpLiveCall extends React.PureComponent {
	state = {
		editMode: false,
		loading: true,
		initialData: null,
		liveCallVideo: null,
		copyStateVideo: true,

		startDate: null,
		endDate: null,
		startDateVideo: null,
		endDateVideo: null,
		maxDate: null,
		liveCallVideo: null,
		liveCallVideo: null,

		formIsValid: true,
		formControls: null,

		coverLoading: false,
		coverImage: null
	};

	getFormControl = (initialData) => {
		// if there is a stored form use it to continiue where user left off, elsewhere
		const hasntParent = initialData && initialData.categories[0].parent == null;
		//console.log(hasntParent);
		return storedFormData && !initialData
			? storedFormData
			: {		
					name: {
						id: 'name',
						type: 'text',
						placeholder: 'Live Tutoring Call Title:',
						placeholderStatic: 'Enter what students will learn',
						shrink: true,
						value: initialData ? initialData.name : '',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData ? Boolean(initialData.name) : false
					},
					description: {
						id: 'description',
						type: 'textarea',
						charCounter: 500,
						placeholder: 'Live Tutoring Call Description:',
						placeholderStatic: 'Describe what your Live Call is about',
						shrink: true,
						value: initialData ? initialData.description : '',
						validation: {
							required: true,
							maxLength: 500
						},
						error: '',
						valid: false,
						touched: initialData ? Boolean(initialData.description) : false
					},
					categories: {
						id: 'categories',
						type: 'text',
						select: true,
						shrink: true,
						selectList:
							categories &&
							categories.map((category) => {
								return {
									label: category.name,
									value: category.slug
								};
							}),
						placeholder: 'Category:',
						placeholderStatic: 'Choose a category',
						value:
						hasntParent == true
						? initialData.categories[0].slug
						: hasntParent == false ? initialData.categories[1].slug : '',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched:
						hasntParent == true
						? initialData.categories[0].slug
						: initialData && hasntParent == false ? initialData.categories[1].slug : false,
					},
					subcategories: {
						id: 'subcategories',
						type: 'text',
						select: true,
						shrink: true,
						selectList: hasntParent == true ? formatSubCategory(
								categories.filter((category) => {
									return initialData.categories[0].slug === category.slug;
								})[0].children
							)
						: hasntParent == false ? formatSubCategory(
							categories.filter((category) => {
								return initialData.categories[1].slug === category.slug;
							})[0].children
						) : [],
						placeholder: 'Subcategory:',
						placeholderStatic: 'Choose a subcategory',
						value: hasntParent == true
							? initialData.categories[1].slug
							: hasntParent == false ? initialData.categories[0].slug : '',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: hasntParent == true ? initialData.categories[1] : hasntParent == false ? initialData.categories[0] : false
					},
					scheduleDays: {
						id: 'schedule',
						type: 'text',
						select: true,
						shrink: true,
						selectList: [
							{ label: 'Every day', value: '1;2;3;4;5;6;0' },
							{ label: 'Monday to Friday', value: '1;2;3;4;5' },
							{ label: 'Weekend days', value: '6;0' },
							{ label: 'Custom', value: 'custom' }
						],
						placeholder: 'Schedule:',
						placeholderStatic: 'Choose a schedule',
						value: initialData
							? initialData.scheduleDays ? initialData.scheduleDays.join(';') : initialData.scheduleCustomStartDate ? 'custom' : ''
							: '',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData,
					},
					startDate: {
						id: 'startDate',
						type: 'text',
						group: true,
						adornment: {
							position: 'start',
							icon: () => calendarIcon
						},
						value:
							initialData && initialData.scheduleCustomStartDate
								? moment(initialData.scheduleCustomStartDate).format('DD/MM/YYYY')
								: '',
						placeholderStatic: 'Start date',
						validation: {
							//required: true
						},
						error: '',
						valid: false,
						touched: true //initialData ? Boolean(initialData.scheduleCustomStartDate) : false
					},
					endDate: {
						id: 'endDate',
						type: 'text',
						group: true,
						shrink: true,
						adornment: {
							position: 'start',
							icon: () => calendarIcon
						},
						value:
							initialData && initialData.scheduleCustomEndDate
								? moment(initialData.scheduleCustomEndDate).format('DD/MM/YYYY')
								: '',
						placeholderStatic: 'End date',
						validation: {
							//required: true
						},
						error: '',
						valid: false,
						touched: true //initialData ? Boolean(initialData.scheduleCustomEndDate) : false
					},
					startHour: {
						id: 'startHour',
						type: 'text',
						group: true,
						adornment: {
							position: 'start',
							icon: () => clockIcon
						},
						select: true,
						// remove the last hour from the array as there is no sence to pick it in the startTime
						selectList: [ ...hoursList ].splice(0, hoursList.length - 1),
						value:
							initialData && initialData.scheduleStartTime
								? moment(initialData.scheduleStartTime).format('HH:mm')
								: '',
						placeholderStatic: 'Start time',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData && initialData.scheduleStartTime
					},
					endHour: {
						id: 'endHour',
						type: 'text',
						group: true,
						adornment: {
							position: 'start',
							icon: () => clockIcon
						},
						select: true,
						selectList: hoursList,
						value:
							initialData && initialData.scheduleEndTime
								? moment(initialData.scheduleEndTime).format('HH:mm')
								: '',
						placeholderStatic: 'End time',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData && initialData.scheduleEndTime
					},
					timeZone: {
						id: 'timeZone',
						type: 'text',
						select: true,
						selectList: timeZonesList,
						shrink: true,
						placeholder: 'Time zone:',
						value: initialData && initialData.timeZone ? initialData.timeZone : '',
						placeholderStatic: 'Choose a timezone',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData && initialData.timeZone
					},
					//Video
					scheduleDaysVideo: {
						id: 'scheduleVideo',
						type: 'text',
						select: true,
						shrink: true,
						selectList: [
							{ label: 'Every day', value: '1;2;3;4;5;6;0' },
							{ label: 'Monday to Friday', value: '1;2;3;4;5' },
							{ label: 'Weekend days', value: '6;0' },
							{ label: 'Custom', value: 'custom' }
						],
						placeholder: 'Schedule:',
						placeholderStatic: 'Choose a schedule',
						value: initialData
							? initialData.videoScheduleDays ? initialData.videoScheduleDays.join(';') : initialData.videoCustomScheduleDays ? 'custom' :''
							: '',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData,
					},
					startDateVideo: {
						id: 'startDateVideo',
						type: 'text',
						group: true,
						adornment: {
							position: 'start',
							icon: () => calendarIcon
						},
						value:
							initialData && initialData.videoScheduleCustomStartDate
								? moment(initialData.videoScheduleCustomStartDate).format('DD/MM/YYYY')
								: '',
						placeholderStatic: 'Start date',
						validation: {
							//required: true
						},
						error: '',
						valid: false,
						touched: true //initialData ? Boolean(initialData.scheduleCustomStartDate) : false
					},
					endDateVideo: {
						id: 'endDateVideo',
						type: 'text',
						group: true,
						shrink: true,
						adornment: {
							position: 'start',
							icon: () => calendarIcon
						},
						value:
							initialData && initialData.videoScheduleCustomEndDate
								? moment(initialData.videoScheduleCustomEndDate).format('DD/MM/YYYY')
								: '',
						placeholderStatic: 'End date',
						validation: {
							//required: true
						},
						error: '',
						valid: false,
						touched: true //initialData ? Boolean(initialData.scheduleCustomEndDate) : false
					},
					startHourVideo: {
						id: 'startHourVideo',
						type: 'text',
						group: true,
						adornment: {
							position: 'start',
							icon: () => clockIcon
						},
						select: true,
						// remove the last hour from the array as there is no sence to pick it in the startTime
						selectList: [ ...hoursList ].splice(0, hoursList.length - 1),
						value:
							initialData && initialData.videoScheduleStartTime
								? moment(initialData.videoScheduleStartTime).format('HH:mm')
								: '',
						placeholderStatic: 'Start time',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData && initialData.videoScheduleStartTime
					},
					endHourVideo: {
						id: 'endHourVideo',
						type: 'text',
						group: true,
						adornment: {
							position: 'start',
							icon: () => clockIcon
						},
						select: true,
						selectList: hoursList,
						value:
							initialData && initialData.videoScheduleEndTime
								? moment(initialData.videoScheduleEndTime).format('HH:mm')
								: '',
						placeholderStatic: 'End time',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData && initialData.videoScheduleEndTime
					},
					timeZoneVideo: {
						id: 'timeZoneVideo',
						type: 'text',
						select: true,
						selectList: timeZonesList,
						shrink: true,
						placeholder: 'Time zone:',
						value: initialData && initialData.videoTimeZone ? initialData.videoTimeZone : '',
						placeholderStatic: 'Choose a timezone',
						validation: {
							required: true
						},
						error: '',
						valid: false,
						touched: initialData && initialData.videoTimeZone
					},
					price: {
						id: 'price',
						type: 'text',
						placeholder: <div className={classes.LabelIndent}>Live Call Price:</div>,
						placeholderStatic: 'Price',
						shrink: true,
						value: initialData ? initialData.price : '',
						validation: {
							required: true,
							isNumericDecimal: true,
							isLargerThen: 1.5
						},
						error: '',
						valid: false,
						touched: initialData ? Boolean(initialData.price) : false
					},
					liveCallPhone: {
						id: 'liveCallPhone',
						type: 'checkbox',
						checked: true,
						value: '',
						error: '',
						validation: {
							required: false
						},
						touched: false
					},
					liveCallVideo: {
						id: 'liveCallVideo',
						type: 'checkbox',
						checked: true,
						value: '',
						error: '',
						validation: {
							required: false
						},
						touched: false,
					},
					copyStateVideo: {
						id: 'copyStateVideo',
						type: 'checkbox',
						checked: true,
						value: '',
						validation: {
							required: false
						},
						error: '',
						touched: false
					},
					};
	};

	async componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const formControlsCopy = clone(this.state.formControls);

		if (!query.get('restore-form')) {
			storedFormData = null;
		}
		// check if user has entered phone number before showing him the form
		if (this.props.user.profile.phoneNumber) {
			// if we have a slug this is edit page
			if (this.props.match.params.slug) {
				// edit mode set
				this.setState({
					editMode: true
				});
				// get categories
				categories = await this.getCategories();
				// get call data
				const callData = await callService
					.getLiveCall(this.props.token, this.props.match.params.slug)
					.then((response) => {
						return response.data;
					})
					.catch((error) => {
						console.log(error.response);
					});

				await Promise.all([ categories, callData ]).then((values) => {
					const callData = values[1];
					this.setState({
						loading: false,
						initialData: callData,
						liveCallPhone: callData.scheduleType !== null,
						liveCallVideo: callData.videoScheduleType !== null,
						startDate: callData.scheduleCustomStartDate,
						endDate: callData.scheduleCustomEndDate,
						startDateVideo: callData.videoScheduleCustomStartDate,
						endDateVideo: callData.videoScheduleCustomEndDate,
						formControls: this.getFormControl(callData),
						coverImage: callData.cover
							? apiConfig.DOMAIN_IMAGE + callData.cover.big
							: null
					});
				});
			} else {
				// if there is no slug it's create page
				const initialData = await this.getSkeleton();
				categories = await this.getCategories();

				this.setState({
					initialData: initialData,
					formControls: this.getFormControl(),
					loading: false
				});

		
			}
		} else {
			// if he did't enter the phone number before send him to settings and add returning url to the queue so he comes back after updating profile
			this.props.snackbarAdd({
				message: 'Before setting up your Live Call please enter your phone number first',
				type: 'info',
				timeout: 8000
			});

			this.props.history.push(
				'/account/settings/contact?' +
					addToUrlQueue(this.props.location.pathname, this.props)
			);
		}

		//Edit mode, loading checked values
		if (this.state.initialData && this.state.initialData.scheduleType !== null) {
			this.state.formControls.liveCallPhone.value = 'liveCallPhone';
			this.state.formControls.liveCallPhone.touched = true;
		}

		if (this.state.initialData && this.state.initialData.videoScheduleType !== null) {
			this.state.formControls.liveCallVideo.value = 'liveCallVideo';
			this.state.formControls.liveCallVideo.touched = true;
		}
	}

	getSkeleton = () =>
		callService
			.createSeleton(this.props.token)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error.data);
			});

	getCategories = () =>
		configurationService
			.getCategories()
			.then((response) => {
				const formatedCategories = Object.keys(response.data).map((categoryName) => {
					return response.data[categoryName];
				});
				// set the categories to be used in category and subcategory select input
				return formatedCategories;
			})
			.catch((error) => {
				console.log(error);
			});

	showError = (message) => {
		this.props.snackbarAdd({
			message: message,
			type: 'error',
			timeout: 8000
		});
	};

	// clears the errors and 'dirty' the input on change. Errors will be checked on blur
	clearInput = (inpt) => {
		inpt.touched = true;
		inpt.error = '';

		// if input is part of the group clear all group memebers
		if (inpt.group) {
			inpt.groupError = false;
		}
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if there is no value it is the radio/checkbox input, in that case take the name
		if (ctrl.type !== 'checkbox') {
			ctrl.value = e.target.value;
			ctrl.touched = true;
		} else {
			ctrl.value = ctrl.value === controlName ? '' : controlName;
		}

		//LiveCallPhoneCheckbox state
		if(controlName === 'liveCallPhone') {
			//ctrl.touched = true;
			this.setState((prevState) => {
				const formControlsCopy = clone(prevState.formControls);
				formControlsCopy.liveCallPhone.value = prevState.liveCallPhone ? 'liveCallPhone' : '';
				
				return {
					liveCallPhone: !prevState.liveCallPhone,
					formControls: formControlsCopy
				};
			});

			//Validation part
			if(formControlsCopy.liveCallPhone.value == 'liveCallPhone') {
				formControlsCopy.startHour.touched =  false;
				formControlsCopy.endHour.touched = false;
				formControlsCopy.scheduleDays.touched = false;
				formControlsCopy.timeZone.touched = false;
			}
		}

		//LiveCallVideo checkbox state
		if(controlName === 'liveCallVideo') {
			//ctrl.touched = true;
			this.setState((prevState) => {
				const formControlsCopy = clone(prevState.formControls);
				formControlsCopy.liveCallVideo.value = prevState.liveCallVideo ? 'liveCallVideo' : '';
				
				return {
					liveCallVideo: !prevState.liveCallVideo,
					formControls: formControlsCopy
				};
			});

			if(formControlsCopy.liveCallVideo.value == 'liveCallVideo') {
				formControlsCopy.startHourVideo.touched =  false;
				formControlsCopy.endHourVideo.touched = false;
				formControlsCopy.scheduleDaysVideo.touched = false;
				formControlsCopy.timeZoneVideo.touched = false;
			}
		}


		if (formControlsCopy.liveCallPhone.value == '' && formControlsCopy.liveCallVideo.value == 'liveCallVideo' ) { //
			formControlsCopy.startHour.value =  '';
			formControlsCopy.endHour.value = '';
			formControlsCopy.scheduleDays.value = '';
			formControlsCopy.timeZone.value = '';
			formControlsCopy.startDate.value = '';
			formControlsCopy.endDate.value = '';
			formControlsCopy.copyStateVideo.value = '';

			formControlsCopy.startHour.touched= true;
			formControlsCopy.endHour.touched = true;
			formControlsCopy.scheduleDays.touched = true;
			formControlsCopy.timeZone.touched = true;
			formControlsCopy.copyStateVideo.touched = true;
			formControlsCopy.startDate.touched = true;
			formControlsCopy.endDate.touched= true;
			formControlsCopy.liveCallPhone.touched= true;

			//console.log('callVideo active');
		}

		//console.log(formControlsCopy.liveCallPhone.value);
		
		if (formControlsCopy.liveCallPhone.value == 'liveCallPhone' && formControlsCopy.liveCallVideo.value == '') {//&& formControlsCopy.liveCallVideo.value == ''
			formControlsCopy.startHourVideo.value = '';
			formControlsCopy.endHourVideo.value = '';
			formControlsCopy.scheduleDaysVideo.value = '';
			formControlsCopy.timeZoneVideo.value = '';
			formControlsCopy.startDateVideo.value = '';
			formControlsCopy.endDateVideo.value = '';
			formControlsCopy.copyStateVideo.value = '';

			formControlsCopy.startHourVideo.touched =  true;
			formControlsCopy.endHourVideo.touched = true;
			formControlsCopy.scheduleDaysVideo.touched = true;
			formControlsCopy.timeZoneVideo.touched = true;
			formControlsCopy.copyStateVideo.touched = true;
			formControlsCopy.startDateVideo.touched = true;
			formControlsCopy.endDateVideo.touched = true;
			formControlsCopy.liveCallVideo.touched= true;
			//console.log('callPhone active');
		}

		if (formControlsCopy.liveCallPhone.value == 'liveCallPhone' && formControlsCopy.liveCallVideo.value == 'liveCallVideo') {
			formControlsCopy.copyStateVideo.touched = true;
		}

		//console.log(this.state.videoScheduleType);
		
		if(formControlsCopy.liveCallPhone.value == '' && formControlsCopy.liveCallVideo.value == '') {
			formControlsCopy.startHourVideo.value = '';
			formControlsCopy.endHourVideo.value = '';
			formControlsCopy.scheduleDaysVideo.value = '';
			formControlsCopy.timeZoneVideo.value = '';
			formControlsCopy.startDateVideo.value = '';
			formControlsCopy.endDateVideo.value = '';
			formControlsCopy.copyStateVideo.value = '';

			formControlsCopy.startHour.value =  '';
			formControlsCopy.endHour.value = '';
			formControlsCopy.scheduleDays.value = '';
			formControlsCopy.timeZone.value = '';
			formControlsCopy.startDate.value = '';
			formControlsCopy.endDate.value = '';

			formControlsCopy.startHour.touched =  false;
			formControlsCopy.endHour.touched = false;
			formControlsCopy.scheduleDays.touched = false;
			formControlsCopy.timeZone.touched = false;
			formControlsCopy.startDate.touched = false;
			formControlsCopy.endDate.touched= false;
			formControlsCopy.startHourVideo.touched = false;
			formControlsCopy.endHourVideo.touched = false;
			formControlsCopy.scheduleDaysVideo.touched = false;
			formControlsCopy.timeZoneVideo.touched = false;
			formControlsCopy.startDateVideo.touched = false;
			formControlsCopy.endDateVideo.touched= false;
		}

		if (formControlsCopy.startDateVideo.value == '' && formControlsCopy.endDateVideo.value == '') {
			formControlsCopy.startDateVideo.touched = true;
			formControlsCopy.endDateVideo.touched = true;
		}

		if (formControlsCopy.startDate.value == '' && formControlsCopy.endDate.value == '') {
			formControlsCopy.startDate.touched = true;
			formControlsCopy.endDate.touched = true;
		}

		if(controlName === 'copyStateVideo') {
			
			this.setState((prevState) => {
				const formControlsCopy = clone(prevState.formControls);
				formControlsCopy.copyStateVideo.value = prevState.copyStateVideo ? 'copyStateVideo' : '';
				//ctrl.value = controlName;
				return {
					copyStateVideo: !prevState.copyStateVideo,
					formControls: formControlsCopy
				};
			});

			//console.log(this.state.copyStateVideo);

			if(this.state.copyStateVideo == true) {
				//ctrl.value = ''
				ctrl.value = 'copyStateVideo';
				formControlsCopy.startHourVideo.value =  formControlsCopy.startHour.value;
				formControlsCopy.endHourVideo.value = formControlsCopy.endHour.value;
				formControlsCopy.scheduleDaysVideo.value = formControlsCopy.scheduleDays.value;
				formControlsCopy.timeZoneVideo.value = formControlsCopy.timeZone.value;
				formControlsCopy.startDateVideo.value = formControlsCopy.startDate.value;
				formControlsCopy.endDateVideo.value= formControlsCopy.endDate.value;

				formControlsCopy.startHourVideo.touched =  true;
				formControlsCopy.endHourVideo.touched = true;
				formControlsCopy.scheduleDaysVideo.touched = true;
				formControlsCopy.timeZoneVideo.touched = true;
				formControlsCopy.startDateVideo.touched = true;
				formControlsCopy.endDateVideo.touched= true;
			} else {
				//ctrl.value = 'copyStateVideo';
				formControlsCopy.startHourVideo.value =  '';
				formControlsCopy.endHourVideo.value = '';
				formControlsCopy.scheduleDaysVideo.value = '';
				formControlsCopy.timeZoneVideo.value = '';
				formControlsCopy.startDateVideo.value = '';
				formControlsCopy.endDateVideo.value = '';
				formControlsCopy.copyStateVideo.value = '';

				formControlsCopy.startHourVideo.touched =  false;
				formControlsCopy.endHourVideo.touched = false;
				formControlsCopy.scheduleDaysVideo.touched = false;
				formControlsCopy.timeZoneVideo.touched = false;
				formControlsCopy.startDateVideo.touched = false;
				formControlsCopy.endDateVideo.touched= false;
				formControlsCopy.copyStateVideo.toched = false;
			}

			//console.log(this.state.copyStateVideo);
		}

		// once category is selected apply its subcategories list to relevant input
		if (controlName === 'categories') {
			const subcategory = formControlsCopy['subcategories'];
			const currentList = categories.filter(
				(category) => e.target.value === category.slug
			)[0].children;
			if (e.target.value) {
				subcategory.selectList = formatSubCategory(currentList);
			}
			// make the subcategory data pristine
			subcategory.value = '';
			subcategory.error = '';
			subcategory.touched = '';
		}

		if (controlName === 'scheduleDays' && ctrl.value === 'custom') {
			formControlsCopy.startDate.validation = {
				required: true
			};

			formControlsCopy.endDate.validation = {
				required: true
			};
		} else if (controlName === 'scheduleDays' && ctrl.value !== 'custom') {
			formControlsCopy.startDate.validation = {
				required: false
			};

			formControlsCopy.endDate.validation = {
				required: false
			};
		}

		// if we are changing the startTime make sure that endTime is not earlier then.
		if (controlName === 'startHour') {
			let startHourIndex;

			for (var i = 0; i < hoursList.length - 1; i++) {
				if (hoursList[i].value === ctrl.value) {
					startHourIndex = i;
					break;
				}
			}

			const timeToNumber = (time) => time.replace(':', '');

			// if endTime isn't defined of it is less then startTime change the end time to the next available value, and remove options that are earlier then startTime
			if (timeToNumber(e.target.value) >= timeToNumber(formControlsCopy.endHour.value)) {
				formControlsCopy.endHour.value = hoursList[startHourIndex + 1].value;
				formControlsCopy.endHour.touched = true;
				formControlsCopy.endHour.selectList = [ ...hoursList ].splice(
					startHourIndex + 1,
					hoursList.length - startHourIndex - 1
				);
			}
		}
		
		//phone
		if (controlName === 'scheduleDaysVideo' && ctrl.value === 'custom') {
			formControlsCopy.startDateVideo.validation = {
				required: true
			};

			formControlsCopy.endDateVideo.validation = {
				required: true
			};
		} else if (controlName === 'scheduleDaysVideo' && ctrl.value !== 'custom') {
			formControlsCopy.startDateVideo.validation = {
				required: false
			};

			formControlsCopy.endDateVideo.validation = {
				required: false
			};
		}

		// if we are changing the startTime make sure that endTime is not earlier then.
		if (controlName === 'startHourVideo') {
			let startHourIndex;

			for (var i = 0; i < hoursList.length - 1; i++) {
				if (hoursList[i].value === ctrl.value) {
					startHourIndex = i;
					break;
				}
			}

			const timeToNumber = (time) => time.replace(':', '');

			// if endTime isn't defined of it is less then startTime change the end time to the next available value, and remove options that are earlier then startTime
			if (timeToNumber(e.target.value) >= timeToNumber(formControlsCopy.endHourVideo.value)) {
				formControlsCopy.endHourVideo.value = hoursList[startHourIndex + 1].value;
				formControlsCopy.endHourVideo.touched = true;
				formControlsCopy.endHourVideo.selectList = [ ...hoursList ].splice(
					startHourIndex + 1,
					hoursList.length - startHourIndex - 1
				);
			}
		}

		this.clearInput(ctrl);
		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});

		console.log(this.state.formControls)
		
	};

	// checks validity of single input
	getInputError = (input) => {
		return validator.validateInput(input);
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];
		ctrl.error = this.getInputError(ctrl);
		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	onChangePhoto = (e) => {
		let file = e.target.files[0];

		if (this.isValidImage(e, file)) {
			this.setState({ coverLoading: true });

			// upload call to api
			callService
				.uploadCoverPhoto(
					this.props.token,
					file,
					this.state.initialData.id,
					this.state.initialData.slug
				)
				.then((response) => {
					this.setState({
						coverLoading: false,
						coverImage: apiConfig.DOMAIN_IMAGE + response.data.cover.big
					});
				})
				.catch((err) => {
					console.log(err.response.data);
					this.props.snackbarAdd({
						message: err.response.data.message,
						type: 'error',
						timeout: 10000
					});
					this.setState({ coverLoading: false });
				});
		}
	};

	isValidImage = (e, file) => {
		// frontend validation of uploaded file
		if (file) {
			// validation for size, return the function if rule is not applied
			let errorMessage = '';
			if (file.size > 20 * 1024 * 1024) {
				errorMessage = 'This file is too big. Please upload a file up to 20MB.';
			} else if (!coverImageFormats.includes(file.type)) {
				// validation for type, return the function if rule is not applied
				errorMessage = `Please upload one of the following formats 'JPG', 'JPEG', 'PNG'.`;
			}

			if (errorMessage) {
				this.showError(errorMessage);
				return false;
			} else {
				return true;
			}
		}
	};

	controlGroupIsValid = (controls, additionalRules) => {
		const form = this.state.formControls;

		for (var i = 0; i < controls.length; i++) {
			if (!form[controls[i]].error && form[controls[i]].touched) {
				//console.log('continue');
				continue;
				
			} else {
				//console.log(form[controls[i]]);
				return false;
			}
		}

		if (additionalRules) {
			for (var x = 0; x < additionalRules.length; x++) {
				if (!additionalRules[x]) {
					return false;
				}
			}
		}
		
		return true;
	};

	handleChangeStartDate = (date) => {
		const formCopy = clone(this.state.formControls);
		formCopy.startDate.value = moment(date).format('DD/MM/YYYY');

		formCopy.startDate.touched = true;

		this.setState({
			startDate: date,
			maxDate: moment(date).add(1, 'day'),
			formControls: formCopy
		});
	};

	handleChangeStartDateVideo = (date) => {
		const formCopy = clone(this.state.formControls);
		formCopy.startDateVideo.value = moment(date).format('DD/MM/YYYY');

		formCopy.startDateVideo.touched = true;

		this.setState({
			startDateVideo: date,
			maxDate: moment(date).add(1, 'day'),
			formControls: formCopy
		});

			//console.log('bilja',moment(this.state.startDateVideo).format('YYYY-MM-DD'));
	};

	handleChangeEndDate = (date) => {
		const formCopy = clone(this.state.formControls);

		formCopy.endDate.value = moment(date).format('DD/MM/YYYY');
		formCopy.endDate.touched = true;

		this.setState({ endDate: date, formControls: formCopy });

	
	};

	handleChangeEndDateVideo = (date) => {
		const formCopy = clone(this.state.formControls);

		formCopy.endDateVideo.value = moment(date).format('DD/MM/YYYY');
		formCopy.endDateVideo.touched = true;
	
		this.setState({ endDateVideo: date, formControls: formCopy });
	};
	
	prepareSubmitData() {
		const form = this.state.formControls;
		const formControlsCopy = clone(this.state.formControls);

		const customDays =
		    form.scheduleDays.value !== '' ?
					form.scheduleDays.value == 'custom'
					? {
						scheduleType: 'custom',
						scheduleCustomStartDate:
							this.state.startDate && moment(this.state.startDate).format('YYYY-MM-DD'),
						scheduleCustomEndDate:
							this.state.endDate && moment(this.state.endDate).format('YYYY-MM-DD')
					}
					: { scheduleType: 'standard', scheduleDays: form.scheduleDays.value }
				: { scheduleType: null, scheduleDays: form.scheduleDays.value };

		const customDaysVideo =
			form.scheduleDaysVideo.value !== '' ?
				form.scheduleDaysVideo.value == 'custom'
					? {
						videoScheduleType: 'custom',
							videoScheduleCustomStartDate:
								this.state.startDateVideo && moment(this.state.startDateVideo).format('YYYY-MM-DD'),
							videoScheduleCustomEndDate:
								this.state.endDateVideo && moment(this.state.endDateVideo).format('YYYY-MM-DD')
						}
					: { videoScheduleType: 'standard', videoScheduleDays: form.scheduleDaysVideo.value }
			: { videoScheduleType: null, videoScheduleDays: form.scheduleDaysVideo.value };

		
		return {
			name: form.name.value.trim(),
			description: form.description.value.trim(),
			categories: [ form.categories.value, form.subcategories.value ],
			price: form.price.value,
			timeZone: form.timeZone.value,
			scheduleStartTime: form.startHour.value,
			scheduleEndTime: form.endHour.value,
			videoScheduleStartTime: form.startHourVideo.value,
			videoScheduleEndTime: form.endHourVideo.value,
			videoTimeZone: form.timeZoneVideo.value,

			...customDays,
			...customDaysVideo
		};

		
	}
	submit = () => {
		const form = this.state.formControls;

		const skipSubmit = this.props.user.plan !== 'pro' && !this.state.editMode;
		
		if (this.state.formIsValid && !skipSubmit) {
			this.setState({ loading: true });

			//based on editMode call the right service
			const service = this.state.editMode
				? callService.editCallClass
				: callService.createCallClass;

			service(this.props.token, this.prepareSubmitData(), this.state.initialData.slug)
				.then((response) => {
					this.setState({ loading: false });
					this.props.history.push('/call/' + response.data.slug);
					console.log(response);
				})
				.catch((error) => {
					console.log(error.response);
					if (error.response.data.errors) {
						const apiErr = errorParser(error.response.data.errors);
						console.log(apiErr);

						const cloneForm = clone(this.state.formControls);

						if (apiErr.formError) {
							this.props.snackbarAdd({
								message: apiErr.formError.join(' '),
								type: 'error',
								timeout: 10000
							});
						}

						// config object to be used by checkFormApiErrors
						const errorStateConfig = {
							formDescriptionError: 'description',
							formNameError: 'name',
							formPriceError: 'price',
							formCategoriesError: 'categories',
							formScheduleCustomEndDateError: 'endDate',
							formScheduleCustomStartDateError: 'startDate',
							formScheduleCustomEndDateErrorVideo: 'endDateVideo',
							formScheduleCustomStartDateErrorVideo: 'startDateVideo'
						};
						// checking form errors for matching pairs defined in the errorStateConfig
						validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

						this.setState({
							formIsValid: false,
							formControls: cloneForm,
							loading: false
						});
					} else {
						this.props.snackbarAdd({
							message: error.response.data.message,
							type: 'error',
							timeout: 10000
						});
					}
				});
		}
	};

	handleStoreForm = () => {
		storedFormData = this.state.formControls;
	};

	render() {
		const {
			editMode,
			initialData,
			formControls,
			coverImage,
			coverLoading,
			startDate,
			endDate,
			startDateVideo,
			endDateVideo,
			maxDate,
			loading,
			formIsValid,
		} = this.state;

		const { user } = this.props;

		const mainTitle = editMode ? 'Edit Live Tutoring Call' : 'Set Up Live Tutoring Call';

		const promptToUpgradeSubscription = user.plan !== 'pro' && !editMode;

		const PhoneNumber = () =>
			user.profile.phoneNumberCountryCode ? (
				<div className={classes.PhoneInformation}>
					<strong>{user.profile.phoneNumber}</strong>
					<p>
						To edit the phone number go to your{' '}
						<Link
							onClick={this.handleStoreForm}
							to={
								'/account/settings/contact?' +
								addToUrlQueue(
									this.props.location.pathname + '?restore-form=1',
									this.props
								)
							}>
							Account Settings
						</Link>.
					</p>
				</div>
			) : (
				<div className={classes.NoPhoneInformation}>
					<p>No information yet.</p>
					<Link
						onClick={this.handleStoreForm}
						to={
							'/account/settings/contact?' +
							addToUrlQueue(this.props.location.pathname + '?restore-form=1', this.props)
						}>
						<Button color="secondary">Add phone number</Button>
					</Link>
				</div>
			);

		const FormSectionCheckValidity = (content, controls, additionalRules) => {
			if (this.controlGroupIsValid(controls, additionalRules)) {
				return (
					<div className={classes.ValidGroup}>
						{content} <ReactSVG src={IconCheckmark} className={classes.IconCheckmark} />
					</div>
				);
			} else {
				return content;
			}
		};

		const FormContent = () =>
			initialData && (
				<Form onSubmit={this.submit}>
					{FormSectionCheckValidity(<h3 id={'form-section-1'}>1. Basic info</h3>,
						[ 'name', 'description','categories', 'subcategories'],
						[coverImage]
					)}
					<div className={classes.Label}>Live tutoring type:</div>
					<h5 className={classes.LabelDescription}>Select both options if you would like to offer 1 on 1 tutoring via your cell phone and via live video call on InfoDepot website.</h5>
					<div className={classes.CallsTypeWrapp}>
						<div onClick={(e) => this.inputChangeHandler(e, 'liveCallPhone')} className={this.state.liveCallPhone == true ? classes.BoxSelectType : classes.BoxSelecTypeDefault}>
							<h4>Phone call</h4>
							<h5>receive voice only tutoring calls via your cell phone</h5>
							<Checkbox 
									className={classes.Checkbox}
									config={formControls.liveCallPhone}
									//onChange={(e) => this.inputChangeHandler(e, 'liveCallPhone')}
									
								/>
							<ReactSVG src={IconCheckmarkLight} className={classes.IconCheckmarkLight} />
						</div>
						<div onClick={(e) => this.inputChangeHandler(e, 'liveCallVideo')} className={this.state.liveCallVideo == true ? classes.BoxSelectType : classes.BoxSelecTypeDefault}>
							<h4>Video call</h4>
							<h5>via InfoDepot website, internet connection required</h5>
							<Checkbox 
									className={classes.Checkbox}
									config={this.state.formControls.liveCallVideo}
									//onChange={(e) => this.inputChangeHandler(e, 'liveCallVideo')}
								/>
							<ReactSVG src={IconCheckmarkLight} className={classes.IconCheckmarkLight} />
						</div>
					</div>
					

					{/* title */}
					<Input
						className={classes.Input}
						style={{ marginBottom: '16px' }}
						config={formControls.name}
						onChange={(e) => this.inputChangeHandler(e, 'name')}
						onBlur={(e) => this.inputBlurHandler(e, 'name')}
					/>
					{/* description */}
					<Input
						className={[ classes.Input, classes.InputDescription ].join(' ')}
						config={formControls.description}
						onChange={(e) => this.inputChangeHandler(e, 'description')}
						onBlur={(e) => this.inputBlurHandler(e, 'description')}
					/>
					{/* <Divider className={classes.Divider} horizontal /> */}

					{/* category */}
					<Input
						className={[ classes.Input, classes.InputCategory ].join(' ')}
						config={formControls.categories}
						onChange={(e) => this.inputChangeHandler(e, 'categories')}
						onBlur={(e) => this.inputBlurHandler(e, 'categories')}
					/>
					{/* subcategory */}
					<Input
						className={classes.Input}
						disabled={!formControls.subcategories.selectList.length}
						config={formControls.subcategories}
						onChange={(e) => this.inputChangeHandler(e, 'subcategories')}
						onBlur={(e) => this.inputBlurHandler(e, 'subcategories')}
					/>
					{/* cover */}
					<div className={classes.Label}>Live Tutoring Call Cover Image:</div>
					<input
						style={{ display: 'none' }}
						id="cover-image"
						onChange={(e) => this.onChangePhoto(e)}
						accept="image/*"
						type="file"
					/>
					<label htmlFor="cover-image">
						{/* preview */}
						{coverImage ? (
							<div
								className={classes.ImageCover}
								style={{ backgroundImage: 'url(' + coverImage + ')' }}
							/>
						) : (
							<div className={classes.ImagePlaceholder}>
								<ReactSVG src={IconImage} />
							</div>
						)}
						<Button
							component="section"
							loading={Number(coverLoading)}
							className={classes.Button}
							color="secondary">
							<ReactSVG src={IconImage} className={classes.ButtonIcon} />
							Upload cover image
						</Button>
					</label>
					<p>
						Please upload a file up to 20MB.<br />
						Cover image should provide a clear depiction of your Live Tutoring Call topic.
						Choose a high-quality, visually-compelling image that will help your Live
						Tutoring Call stand out in our listings.
					</p>
					<Divider className={classes.Divider} horizontal />
							{/* this.state.liveCallVideo == true ? */}
					{FormSectionCheckValidity(
						<h3 id={'form-section-2'}>2. Availability</h3>,
						[
							'scheduleDays',
							'startDate',
							'endDate',
							'startHour',
							'endHour',
							'timeZone',
							'scheduleDaysVideo',
							'startDateVideo',
							'endDateVideo',
							'startHourVideo',
							'endHourVideo',
							'timeZoneVideo'
						],
						[ user.profile.phoneNumberCountryCode ]
					)}
					<div className={this.state.liveCallPhone == false || this.state.liveCallPhone == null ? classes.PhoneCallWrapp : classes.TypeWrapp}>
						<div className={classes.InfoTypeWrapp}>
							<ReactSVG src={IconLiveCalls} className={classes.TypeIcon} />
							<div>
								<h4 className={classes.CallTitle}>Set your availability for phone calls:</h4>
								<h5 className={classes.LabelDescription}>Please have your phone near you at your scheduled availability to receive the calls.</h5>
							</div>
						</div>
						<div className={classes.TypeInnerWrapp}>
							{/* schedule */}
							<Input
								// className={classes.Input}
								style={{
									marginBottom: 0,
								}}
								config={formControls.scheduleDays}
								onChange={(e) => this.inputChangeHandler(e, 'scheduleDays')}
								onBlur={(e) => this.inputBlurHandler(e, 'scheduleDays')}
							/>
							{/* date */}
							{formControls.scheduleDays.value === 'custom' && (
								<div className={classes.DatePickerWrap}>
									{/* start date */}
									<DatePicker
										customInput={<Input config={formControls.startDate} />}
										className={[ classes.Input, classes.DateInput ].join(' ')}
										minDate={new Date()}
										//selected={this.state.startDate}
										//selectsStart
										startDate={startDate}
										endDate={endDate}
										onBlur={(e) => this.inputBlurHandler(e, 'startDate')}
										onChange={this.handleChangeStartDate}
		
									/>
									<p>to</p>
									{/* end date */}
									<DatePicker
										customInput={<Input config={formControls.endDate} />}
										className={[ classes.Input, classes.DateInput ].join(' ')}
										minDate={Math.max(new Date(), startDate)}
										maxDate={
											maxDate ? (
												moment(maxDate).add(1, 'year').toDate()
											) : (
												moment(new Date()).add(1, 'year').toDate()
											)
										}
										//selected={endDate}
										//selectsEnd
										startDate={startDate}
										endDate={endDate}
										onBlur={(e) => this.inputBlurHandler(e, 'endDate')}
										onChange={this.handleChangeEndDate}
		
									/>
								</div>
								)}
								<div className={classes.TimePickerWrap}>
									{/* start time */}
									<Input
										className={[ classes.HourInput, classes.Input ].join(' ')}
										config={formControls.startHour}
										onChange={(e) => this.inputChangeHandler(e, 'startHour')}
										onBlur={(e) => this.inputBlurHandler(e, 'startHour')}
									/>
									<p>to</p>
									{/* end time */}
									<Input
										className={[ classes.HourInput, classes.Input ].join(' ')}
										config={formControls.endHour}
										onChange={(e) => this.inputChangeHandler(e, 'endHour')}
										onBlur={(e) => this.inputBlurHandler(e, 'endHour')}
									/>
								</div>
								{/* time zone */}
								<Input
									className={classes.Input}
									config={formControls.timeZone}
									onChange={(e) => this.inputChangeHandler(e, 'timeZone')}
									onBlur={(e) => this.inputBlurHandler(e, 'timeZone')}
								/>
							
								<div className={classes.Label}>Phone number for receiving the call:</div>
								{PhoneNumber()}
							</div>
						</div>

						<div className={this.state.liveCallVideo == false || this.state.liveCallVideo == null ? classes.VideoCallWrapp : classes.TypeWrapp}>
							<div className={classes.InfoTypeWrapp}>
								<ReactSVG src={IconVideo} className={classes.TypeIcon} />
								<div>
									<h4 className={classes.CallTitle}>Set your availability for video calls:</h4>
									<h5 className={classes.LabelDescriptionVideo}>Please keep InfoDepot website opened in your browser at your scheduled availability to receive the calls.</h5>
								</div>
							</div>
							<div className={classes.TypeInnerWrapp}>
								<div className={classes.CheckboxWrapp}>
									<Checkbox 
										className={this.state.liveCallPhone == false || this.state.liveCallPhone == null ? classes.PhoneCallDisabledCheckbox : classes.Checkbox}
										//value={'copyStateVideo'}
										config={formControls.copyStateVideo}
										onClick={(e) => this.inputChangeHandler(e, 'copyStateVideo')}
									/>
									<h5 className={this.state.liveCallPhone == false || this.state.liveCallPhone == null ? classes.LabelCopyPhoneDisabled : classes.LabelCopy}>Same as phone call availability</h5>
								</div>
								<div className={this.state.copyStateVideo == false ? classes.Hidden : ''}>
									{/* schedule */}
									<Input
										// className={classes.Input}
										style={{
											marginBottom: 0,
										}}
										config={formControls.scheduleDaysVideo}
										onChange={(e) => this.inputChangeHandler(e, 'scheduleDaysVideo')}
										onBlur={(e) => this.inputBlurHandler(e, 'scheduleDaysVideo')}
									/>
									{/* date */}
									{formControls.scheduleDaysVideo.value === 'custom' && (
										<div className={classes.DatePickerWrap}>
											{/* start date */}
											<DatePicker
												customInput={<Input config={formControls.startDateVideo} />}
												className={[ classes.Input, classes.DateInput ].join(' ')}
												minDate={new Date()}
												//selected={startDateVideo}
												//selectsStart
												startDate={startDateVideo}
												endDate={endDateVideo}
												onBlur={(e) => this.inputBlurHandler(e, 'startDateVideo')}
												onChange={this.handleChangeStartDateVideo}
											/>
											<p>to</p>
											{/* end date */}
											<DatePicker
												customInput={<Input config={formControls.endDateVideo} />}
												className={[ classes.Input, classes.DateInput ].join(' ')}
												minDate={Math.max(new Date(), startDateVideo)}
												maxDate={
													maxDate ? (
														moment(maxDate).add(1, 'year').toDate()
													) : (
														moment(new Date()).add(1, 'year').toDate()
													)
												}
												//selected={formControls.endDateVideo}
												//selectsEnd
												startDate={startDateVideo}
												endDate={endDateVideo}
												onBlur={(e) => this.inputBlurHandler(e, 'endDateVideo')}
												onChange={this.handleChangeEndDateVideo}
											/>
										</div>
									)}
									<div className={classes.TimePickerWrap}>
										{/* start time */}
										<Input
											className={[ classes.HourInput, classes.Input ].join(' ')}
											config={formControls.startHourVideo}
											onChange={(e) => this.inputChangeHandler(e, 'startHourVideo')}
											onBlur={(e) => this.inputBlurHandler(e, 'startHourVideo')}
										/>
										<p>to</p>
										{/* end time */}
										<Input
											className={[ classes.HourInput, classes.Input ].join(' ')}
											config={formControls.endHourVideo}
											onChange={(e) => this.inputChangeHandler(e, 'endHourVideo')}
											onBlur={(e) => this.inputBlurHandler(e, 'endHourVideo')}
										/>
									</div>
									{/* time zone */}
									<Input
										className={classes.Input}
										config={formControls.timeZoneVideo}
										onChange={(e) => this.inputChangeHandler(e, 'timeZoneVideo')}
										onBlur={(e) => this.inputBlurHandler(e, 'timeZoneVideo')}
									/>
									{/* <div className={classes.Label}>Phone number for receiving the call:</div>
									{PhoneNumber()} */}
								</div>
							</div>
						</div>

						<Divider className={classes.Divider} horizontal />
						{FormSectionCheckValidity(<h3 id={'form-section-3'}>3. Price</h3>, [ 'price' ])}

						{/* price */}
						<div className={classes.CreditsWrap}>
							<div className={classes.CreditsLabel}>$</div>
							<Input
								sty
								config={formControls.price}
								onChange={(e) => this.inputChangeHandler(e, 'price')}
								onBlur={(e) => this.inputBlurHandler(e, 'price')}
							/>
							<span className={classes.LabelAfterPrice}>per minute</span>
						</div>
						<p
							style={
								formControls.price.error === '' ? (
									{ marginTop: '-14px', marginBottom: '30px' }
								) : (
									{ marginTop: '0px', marginBottom: '14px' }
								)
							}>
							Minimum price: $1.50 per minute<br />
							InfoDepot commission will be deducted from<br />
							the total sales{' '}
							<Link to="/fees-info" target="_blank">
								Learn more.
							</Link>
						</p>
						
					<Divider className={classes.Divider} horizontal />

					<div className={classes.FormProgress}>
						<div className={classes.Label}>Finished steps</div>
						<div className={classes.ValidGroupLegend}>
							<div>
								{FormSectionCheckValidity(
									<a href="#form-section-1">
										<h3>1.</h3>
									</a>,
									[ 'name', 'description','categories', 'subcategories'],
									[coverImage]
								)}
							</div>
							<div>
								{FormSectionCheckValidity(
									<h3 id={'form-section-2'}>2.</h3>,
									[
										'scheduleDays',
										'startDate',
										'endDate',
										'startHour',
										'endHour',
										'timeZone',
										'scheduleDaysVideo',
										'startDateVideo',
										'endDateVideo',
										'startHourVideo',
										'endHourVideo',
										'timeZoneVideo'
									],
									[ user.profile.phoneNumberCountryCode ]	
								)}
							</div>
							<div>
								{FormSectionCheckValidity(
									<a href="#form-section-3">
										<h3>3.</h3>
									</a>,
									[ 'price' ]
								)}
							</div>
						</div>
					</div>
					<Button
						fullWidth={true}
						size="large"
						type="submit"
						disabled={!coverImage || !user.profile.phoneNumber || loading || !formIsValid}
						loading={Number(loading)}>
						{editMode ? 'SAVE CHANGES' : 'CREATE LIVE TUTORING CALL'}
					</Button>
				</Form>
			);

		return (
			<div className={classes.Container}>
				{promptToUpgradeSubscription && <PromptToUpgradeSubscription from='setUpLiveCall' />}
				<h1 className={promptToUpgradeSubscription ? classes.Untouchable : ''}>{mainTitle}</h1>
				<Card loading={Number(!initialData)} className={[classes.Card, promptToUpgradeSubscription ? classes.Untouchable : ''].join(' ')}>
					{FormContent()}
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	console.log(state);
	return {
		user: state.user,
		token: state.auth.accessToken
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SetUpLiveCall);
