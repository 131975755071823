import React from 'react';
import ReactSVG from 'react-svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import classes from './WorkbookTab.module.scss';
import Button from '../../../../components/UI/Button/Button';
import Divider from '../../../../components/UI/Divider/Divider';
import IconFile from '../../../../assets/images/icons/document_icon.svg';
// document icons
import IconDefault from '../../../../assets/images/icons/documentTypes/universal_icon.svg';
import IconAI from '../../../../assets/images/icons/documentTypes/ai_icon.svg';
import IconDOC from '../../../../assets/images/icons/documentTypes/doc_icon.svg';
import IconJPG from '../../../../assets/images/icons/documentTypes/jpg_icon.svg';
import IconPDF from '../../../../assets/images/icons/documentTypes/pdf_icon.svg';
import IconPNG from '../../../../assets/images/icons/documentTypes/png_icon.svg';
import IconPPT from '../../../../assets/images/icons/documentTypes/ppt_icon.svg';
import IconPSD from '../../../../assets/images/icons/documentTypes/psd_icon.svg';
import IconXLS from '../../../../assets/images/icons/documentTypes/xls_icon.svg';

const getExtenstionFromName = (name) => name.split('.').pop();

const WorkbookTab = (props) => {
	const { video } = props;
	const userIsAuthor = () =>
		(props.user && props.user.username) === video.author.username;
	const iconRender = (name) => {
		switch (getExtenstionFromName(name)) {
			case 'jpg':
			case 'jpeg':
				return IconJPG;
			case 'doc':
				return IconDOC;
			case 'pdf':
				return IconPDF;
			case 'ai':
				return IconAI;
			case 'png':
				return IconPNG;
			case 'ppt':
				return IconPPT;
			case 'psd':
				return IconPSD;
			case 'xls':
				return IconXLS;
			default:
				return IconDefault;
		}
	};
	const purchaseDocumentsRender = () =>
		video.purchased || userIsAuthor() ? null : (
			<div className={classes.PurchaseVideoWrap}>
				<p>Please purchase this class to download the files</p>
				<Button color="secondary" onClick={props.onClickBuy}>
					Buy Video Class
				</Button>
			</div>
		);
	const documentLabel = (i, index) => (
		<React.Fragment key={index}>
			<span className={[ classes.DocumentLabel, classes.AnimateIn ].join(' ')}>
				<div className={classes.DocButtonWrapper}>
					<span className={[ classes.DocumentLabel, classes.AnimateIn ].join(' ')}>
						<ReactSVG
							src={iconRender(i.document.fileName)}
							className={classes.IconDocument}
						/>
						<div className={classes.ItemDocument}>
							<span>{i.document.fileName}</span>
						</div>
					</span>
					<Button
						onClick={() => props.onClickDownload(i.documentSlug, i.document.fileName)}
						color="secondary"
						disabled={!(video.purchased || userIsAuthor())}>
						Download Now
					</Button>
				</div>
			</span>
			{index !== video.documents.length - 1 && <Divider horizontal={1} />}
		</React.Fragment>
	);
	const loaderRender = (
		<div className={classes.Loader}>
			<CircularProgress />
		</div>
	);
	const noResults = () => {
		const text = userIsAuthor()
			? `You haven't added any documents yet.`
			: `No documents yet.`;
		const teacherButton = () =>
			userIsAuthor() ? (
				<Link to={'/edit-video/' + video.slug}>
					<Button size="large">ADD DOCUMENTS NOW</Button>
				</Link>
			) : null;
		return (
			<div className={classes.NoResults}>
				<ReactSVG src={IconFile} className={classes.IconNoResult} />
				<p>{text}</p>
				{teacherButton()}
			</div>
		);
	};
	return (
		<div className={classes.Container}>
			{video ? video.documents.length ? (
				<React.Fragment>
					{/* purchase video info */}
					{purchaseDocumentsRender()}
					{/* Documents list */}
					{video.documents.map((i, index) => documentLabel(i, index))}
				</React.Fragment>
			) : (
				// no results render
				noResults()
			) : (
				loaderRender
			)}
		</div>
	);
};

export default WorkbookTab;
