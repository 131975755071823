import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import windowWidth from 'react-window-size';
import CircularProgress from '@material-ui/core/CircularProgress';
import clone from 'lodash.clonedeep';
import ReactSVG from 'react-svg';

import classes from './VideosListing.module.scss';
import * as screen from './../../../../utils/common.js';
import * as actions from './../../../../store/actions/indexActions';
import Button from './../../../../components/UI/Button/Button';
import IconLiveCall from '../../../../assets/images/icons/live_call_icon.svg';
import IconMute from '../../../../assets/images/icons/live_call_offline_icon.svg';
import IconScheduleCall from '../../../../assets/images/icons/schedule-call.svg';
import IconArrowDown from '../../../../assets/images/icons/arrow_down_white_icon.svg';
import * as liveCallService from './../../../../services/liveCall/liveCallService';
import * as userService from './../../../../services/user/userService';
import Select from '@material-ui/core/NativeSelect';
import GridList from '../../../../components/UI/GridList/GridList';
import ItemCard from '../../../../components/ItemCard/ItemCard';
import { Form, Checkbox } from '../../../../components/UI/Form/Form';
import Pagination from '../../../../components/UI/Pagination/Pagination';
import PromptToUpgradeSubscription from '../../../../components/Prompts/PromptToUpgradeSubscription/PromptToUpgradeSubscription';

const paginationConf = {
  pageLimit: 20,
  total: null,
};

const optionNames = [
  {
    id: 1,
    name: 'Available per schedule',
    svgUrl: IconScheduleCall,
    value: 'available_per_schedule',
    desc:
      'You can receive calls only during the dates and time you set within your individual call schedule.',
  },
  {
    id: 2,
    name: 'Available for all calls indefinitely',
    svgUrl: IconLiveCall,
    value: 'all_available',
    desc: 'You can receive any of your calls anytime.',
  },
  {
    id: 3,
    name: 'Unavailable for all calls indefinitely',
    svgUrl: IconMute,
    value: 'all_disabled',
    desc: 'You will not receive any of your calls.',
  },
];

class LiveCallsListing extends Component {
  state = {
    loading: true,
    liveCallsData: null,
    statusCall: null,
    callOptionStatus: null,
    callStatusName: null,
    listOptionsOpen: false,
    formIsValid: false,
    svgUrl: false,
    formControls: {
      statusCall: {
        color: 'secondary',
        id: 'statusCall',
        type: 'checkbox',
        checked: 'true',
        value: '',
        error: '',
        valid: false,
        touched: false,
      },
    },
    screenWidth: null,
  };

  getDropListOption = () => {
    return optionNames.map((optionName) => (
      <li key={optionName.id}>
        <a
          id={optionName.id}
          onClick={() =>
            this.onOptionChangeHandler(
              optionName.value,
              optionName.name,
              optionName.svgUrl
            )
          }
          className={
            this.state.statusCall == optionName.value ? classes.Active : ''
          }
        >
          <ReactSVG
            svgClassName={classes.IconOptionCall}
            src={optionName.svgUrl}
          />
          {optionName.name}
          <span className={classes.OptionDesc}>{optionName.desc}</span>
          <div className={classes.RadioOption}></div>
        </a>
      </li>
    ));
  };

  prepareSubmitData = () => {
    const statusCallData = {
      statusCall: Boolean(this.state.formControls.statusCall.value),
    };
    //console.log('Status call', statusCallData);

    return statusCallData;
  };

  componentDidMount() {
    // console.log(this.props.user);
    const formControlsCopy = clone(this.state.formControls);
    //formControlsCopy.statusCall.value = !this.props.user.statusCall ? 'statusCall' : '';
    this.setState(
      {
        statusCall: this.props.user.statusCall,
        callStatusName:
          this.props.user.statusCall == 'all_available'
            ? 'Available for all calls indefinitely'
            : this.props.user.statusCall == 'available_per_schedule'
            ? 'Available per schedule'
            : this.props.user.statusCall == 'all_disabled'
            ? 'Unavailable for all calls indefinitely'
            : '',
        svgUrl:
          this.props.user.statusCall == 'all_available'
            ? IconLiveCall
            : this.props.user.statusCall == 'available_per_schedule'
            ? IconScheduleCall
            : this.props.user.statusCall == 'all_disabled'
            ? IconMute
            : '',
        //formControls: formControlsCopy
      },
      () => {
        // console.log('test url',this.state.svgUrl )
      }
    );
    this.getCalls();
    this.setState({ screenWidth: screen.mq() });
  }

  getCalls = (offset = 0) => {
    liveCallService
      .getAllLiveCalls(
        this.props.token,
        `limit=${paginationConf.pageLimit}&offset=${offset}`
      )
      .then((response) => {
        paginationConf.total = response.data.length
          ? response.data[0]['_total']
          : null;
        this.setState({
          liveCallsData: response.data,
          loading: false,
        });

        //console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  };

  handlePaginationchange = (pag) => {
    // console.log(pag);
    this.getCalls((pag.currentPage - 1) * pag.pageLimit);
  };

  showError = (message) => {
    this.props.snackbarAdd({
      message: message,
      type: 'error',
      timeout: 8000,
    });
  };

  toggleSelectOption = () => {
    this.setState((state) => ({ listOptionsOpen: !state.listOptionsOpen }));
  };

  onOptionChangeHandler = (selectedVal, selectedName, svgUrl) => {
    //console.log(e.currentTarget.value)
    this.toggleSelectOption();
    this.setState(
      {
        statusCall: selectedVal,
        callStatusName: selectedName,
        svgUrl: svgUrl,
      },
      () => {
        userService
          .updateCallAvailability(
            { statusCall: this.state.statusCall },
            this.props.token,
            this.props.user.type
          )
          .then((response) => {
            // this.setState({
            this.props.updateLoggedUser();
            // });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  handleCheckboxClick = () => {
    this.setState((prevState) => {
      const formControlsCopy = clone(prevState.formControls);
      formControlsCopy.statusCall.value = prevState.statusCall
        ? 'statusCall'
        : '';
      return {
        statusCall: !prevState.statusCall,
        formControls: formControlsCopy,
      };
    });

    userService
      .updateCallAvailability(
        { statusCall: !this.state.statusCall },
        this.props.token,
        this.props.user.type
      )
      .then((response) => {
        // this.setState({
        console.log(response);
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const screenWidth = this.props.windowWidth;

    const calls = this.state.liveCallsData;
    const loaderRender = () => <CircularProgress className={classes.Loader} />;

    const buttonRender = () => (
      <Link to="/set-up-live-call">
        <Button size="large" className={classes.ButtonSettings}>
          CREATE NEW LIVE TUTORING CALL
        </Button>
      </Link>
    );

    const noContentRender = () => (
      <section className={classes.SectionEmpty}>
        <ReactSVG svgClassName={classes.IconLiveCall} src={IconLiveCall} />
        <h3 className={classes.EmptyHeadline}>
          You haven't created any live calls yet.
        </h3>
        {buttonRender()}
      </section>
    );

    const listRender = () => (
      <GridList className={classes.GridList}>
        {calls.map((call) => (
          <ItemCard
            muted={this.state.statusCall}
            type="my-call"
            itemData={call}
            // onClickPublish={(e, slug, status) => this.onHandleClickPublish(e, slug, status)}
          />
        ))}
      </GridList>
    );

    return (
      <div className={classes.Container}>
        {screenWidth > 768 ? (
          <h1 className={classes.SectionHeadline}>My Live Tutoring</h1>
        ) : (
          ''
        )}

        {this.props.user.plan !== 'pro' && this.props.user.lastPlanDowngraded === 'pro' ? (
          <PromptToUpgradeSubscription from="expiredCalls" />
        ) : (
          <div className={classes.NavWrap}>
            {buttonRender()}
            <div className={classes.SelectOption}>
              <a
                onClick={() => this.toggleSelectOption()}
                className={[
                  classes.SelectOptionName,
                  this.state.listOptionsOpen == true ? classes.Active : '',
                ].join(' ')}
              >
                {/* this.state.listOptionsOpen == false ? classes.Active : '', */}
                <ReactSVG
                  svgClassName={classes.IconLiveCall}
                  src={this.state.svgUrl}
                />
                {this.state.callStatusName}
                <ReactSVG
                  svgClassName={classes.IconArrow}
                  src={IconArrowDown}
                />
              </a>
              <ul
                className={[
                  this.state.listOptionsOpen == false ? classes.Hidden : '',
                  classes.CustomselectOption,
                ].join(' ')}
              >
                {this.getDropListOption()}
              </ul>
            </div>
            <div className={classes.DisableWrap}>
              {/* <ReactSVG src={IconMute} className={classes.IconMute}/>
						<div className={classes.DisableWrapInner}>
						
							<section>
								<strong>Disable All your Calls</strong>
								<Form>
									<Checkbox
										className={classes.Checkbox}
										config={this.state.formControls.statusCall}
										onClick={this.handleCheckboxClick}
										//onChange={(e) => {this.inputChangeHandler(e, 'statusCall');}}
									/>
								</Form>
								
							
							</section>

						<p>You will not receive any calls.</p>
						</div> */}
            </div>
          </div>
        )}

        {this.state.loading ? (
          loaderRender()
        ) : // main content, shown after fetch, if there are no items show contentlessRender
        calls && calls.length > 0 ? (
          <React.Fragment>
            {listRender()}
            <Pagination
              style={{ marginTop: '40px' }}
              totalRecords={paginationConf.total}
              pageLimit={paginationConf.pageLimit}
              pageNeighbours={1}
              onPageChanged={this.handlePaginationchange}
            />
          </React.Fragment>
        ) : (
          noContentRender()
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
    updateLoggedUser: () => dispatch(actions.getLoggedUser()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(windowWidth(LiveCallsListing));
