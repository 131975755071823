import React from "react";
import { Link } from "react-router-dom";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPixel from "react-facebook-pixel";

import "./App.scss";
import * as actions from "./store/actions/indexActions";
import * as callService from "./services/liveCall/liveCallService";
import {
  getReturnToUrlToken,
  deleteReturnToUrlToken,
} from "./utils/redirectTo";
import apiConfig from "./utils/apiConfig";
import Button from "./components/UI/Button/Button";
import Trophy from "./assets/images/graphics/Trophy_Popup.svg";
// import classes from './StartVideoCallModal.module.scss';
import classes from "./TwilioVideo.module.scss";
import Modal from "./components/UI/Modal/Modal";
import ReactSVG from "react-svg";
import Avatar from "./components/UI/Avatar/Avatar";
import TwilioVideo from "./components/TwilioVideo/TwilioVideo";
//import Sound from 'react-sound';
// containers
import * as container from "./containers/indexContainers";
// components
import Layout from "./components/Layout/Layout";
import TransactionHistory from "./containers/TransactionHistory/TransactionHistory";
import Dashboard from "./containers/MyAccount/Dashboard/Dashboard";
import { set } from "date-fns/esm";

//Sound url
import SoundUrl from "./assets/sounds/sound.mp3";
import SoundUrlQueue from "./assets/sounds/sound_queue_call.mp3";
import SoundUrlOgg from "./assets/sounds/sound.ogg";
import SoundUrlQueueOgg from "./assets/sounds/sound_queue_call.ogg";
import NotificationMessage from './assets/sounds/mixkit-soap-bubble-sound-2925.wav';
//Twilio
import { isSupported } from "twilio-video";
import RateModal from "./components/Ratings/RateModal/RateModal";
import IconCoins from "./assets/images/icons/credits_icon.svg";
import IconSafari from "./assets/images/icons/Safari-icon.svg";
import IconFirefox from "./assets/images/icons/Firefox-icon.svg";
import IconEdge from "./assets/images/icons/Edge-icon.svg";
import IconChrome from "./assets/images/icons/Chrome-icon.svg";

//Modal VideoCall icons
import LeftShape from "./assets/images/graphics/left_shape.svg";
import CloseIcon from "./assets/images/icons/menu close icon.svg";
import CheckIcon from "./assets/images/icons/check-mark-icon.svg";
import RightShape from "./assets/images/graphics/right_shape.svg";
import LeftMiddleShape from "./assets/images/graphics/left_middle_shape.svg";
import RightMiddleShape from "./assets/images/graphics/right_middle_shape.svg";
import { compose } from "@material-ui/system";
import { hotjar } from "react-hotjar";
import { EventSourcePolyfill } from "event-source-polyfill";
import { matchPath } from "react-router";
import * as messageService from "./services/message/messageService";
import { ChosenDisplayName, renderDisplayName } from "./components/UI/ChosenDisplayName/ChosenDisplayName";

const intervalAutorefresh = (fn) => setInterval(fn, 20000);
let cookieBlockInitiated = false;
let contestPageVisited;

class App extends React.PureComponent {
  state = {
    loading: false,
    modalVideoCall: false,
    videoCallInfo: [],
    videoCallInfoAuthor: [],
    videoCallSlug: "",
    callInitiator: [],
    videoName: "",
    twilioVideo: false,
    roomName: "",
    roomToken: "",
    videoName: "",
    profile: "",
    checkPhoto: "",
    photoUrl: "",
    callInitiator: "",
    participant: "",
    callFrommQueue: false,
    price: "",
    modalRateOpened: false,
    callStarted: false,
    incomingCallUser: "",
    author: "",
    videoSlug: "",
    modalVideoCallQueueStudent: false,
    ringToneQueue: false,
    osDetected: null,
    modalSupport: false,
    modalContest: false,
    mercureListening: false,
    displayNameForCaller: "",
  };

  componentDidMount() {
    this.props.onTryAutoSignup();
    // modal contest
    // if (localStorage.getItem("contestPopUp")) {
    //   this.setState({ modalContest: false });
    // }

    // let contestPage = this.props.location.pathname;
    // if (contestPage === "/contest" || contestPage === "/blog") {
    //   let contestPageVisited = "contestPageVisited";
    //   localStorage.setItem("contestPageVisited", contestPageVisited);
    //   this.setState({ modalContest: false });
    //   let startDateFromPage = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    //   localStorage.setItem("startDateFromPage", startDateFromPage);
    // }

    // if (
    //   localStorage.getItem("startDatePopup") ||
    //   localStorage.getItem("startDateFromPage")
    // ) {
    //   let newDate = new Date().getTime();
    //   if (
    //     (localStorage.getItem("startDatePopup") ||
    //       localStorage.getItem("startDateFromPage")) < newDate
    //   ) {
    //     localStorage.removeItem("startDatePopup");
    //     localStorage.removeItem("contestPageVisited");
    //     localStorage.removeItem("contestPopUp");
    //     this.setState({ modalContest: true });
    //   }

    // }

    //detect os for twilio video support
    const platform = navigator.platform.toLowerCase(),
      iosPlatforms = ["iphone", "ipad", "ipod", "ipod touch"];
    const Chrome =
      (window.navigator.userAgent.indexOf("Android") > -1 &&
        /Chrome\/[.0-9]*/.test(window.navigator.userAgent)) ||
      navigator.userAgent.match("CriOS");

    if (platform.includes("mac")) {
      //console.log('MacOS')
      this.setState({
        osDetected: "MacOS",
      });
    } else if (
      platform.includes(iosPlatforms) ||
      /iphone|ipod|ipad/i.test(navigator.userAgent)
    ) {
      //console.log('iOS')
      this.setState({
        osDetected: "iOS",
      });
    } else if (
      platform.includes("win") &&
      navigator.userAgent.indexOf("Safari") != -1
    ) {
      //console.log('Windows')
      this.setState({
        osDetected: "Windows",
      });
    } else if (
      (/android/.test(navigator.userAgent.toLowerCase()) &&
        navigator.userAgent.indexOf("Safari") != -1) ||
      (/android/.test(navigator.userAgent.toLowerCase()) &&
        navigator.userAgent.indexOf("Edge") != -1)
    ) {
      //console.log('Android')
      this.setState({
        osDetected: "Android",
      });
    } else if (
      (/linux/.test(platform) && navigator.userAgent.indexOf("Safari") != -1) ||
      (/linux/.test(platform) && navigator.userAgent.indexOf("Edge") != -1)
    ) {
      //console.log('Linux')
      this.setState({
        osDetected: "Linux",
      });
    } else {
      //console.log('unknown');
      this.setState({
        osDetected: "undefined",
      });
    }
    // listening for route changes
    this.props.history.listen((location, action) => {
      const url = window.location.pathname;
      let doScroll = true;
      if (url) {
        const callPattern = /^\/call\/.+\/(description|reviews)$/;
        const videoPattern = /^\/video\/.+\/(description|workbook|comments|reviews)$/;
        const transactionHistoryPattern = /^\/account\/transaction-history\/(purchases|orders|sales|payouts)$/;
        const myAccountCallPattern = /^\/account\/calls(\/video|\/phone)?$/;
        const myAccountQueuedCallPattern = /^\/account\/queued-calls(\/video|\/phone)?$/;
        const myAccountVideoLibraryPattern = /^\/account\/videos$/;
        const teacherDashboardPattern = /^\/account\/dashboard\/(videos|calls|calls-overview\/video|calls-overview\/phone|queued-calls\/video|queued-calls\/phone)$/;

        if (
          url.match(callPattern) ||
          url.match(videoPattern) ||
          url.match(transactionHistoryPattern) ||
          url.match(myAccountCallPattern) ||
          url.match(myAccountVideoLibraryPattern) ||
          url.match(myAccountQueuedCallPattern) ||
          url.match(teacherDashboardPattern)
        ) {
          doScroll = false;
        }
      }
      // scrolling to top on routing
      const mainDOMContainer = document.getElementById("main_scroller");
      if (mainDOMContainer && mainDOMContainer.scrollTo && doScroll) {
        mainDOMContainer.scrollTo(0, 0);
      }
    });
  }
  updateUserData = () => {
    this.props.updateLoggedUser();
  };

  async componentDidUpdate(prevProps, prevState) {
    if (!this.props.loggedUser) {
      if (this.es) {
        this.es.close();
        this.setState({ mercureListening: false });
      }
    }
    if (localStorage.getItem("contestPageVisited")) {
      this.setState({ modalContest: false });
    }
    //Clear intervals
    clearInterval(this.intervalTeacherCheck);
    clearInterval(this.intervalStudentCheck);

    if (prevProps.location.state !== this.props.location.state) {
      if (
        this.props.location.state &&
        this.props.location.state.modalSupport !== undefined
      ) {
        //console.log('test only on change', this.props.location.state.modalSupport)
        this.setState({
          modalSupport: this.props.location.state.modalSupport,
        });
      }
    }

    // check URL's if it has 'redirect' flag is comming from an email.
    // if it is and user isn't logged we should redirect him to sign-in
    // and remeber where he came from in the query to redirect him to the proper page after sign-in inside auth component
    const query = new URLSearchParams(this.props.location.search);
    const redirected = query.get("redirect");
    // const anchorHash = query.get('commentId');
    if (
      redirected &&
      !this.props.isAuthenticated &&
      this.props.location.pathname !== "/auth"
    ) {
      this.props.history.push({
        pathname: "/auth",
        search: "?redirect=" + this.props.location.pathname,
      });
    }

    // setting interval for logged in user and removing it for public one. @TODO create socket to update with less payload
    if (this.props.loggedUser && !this.userRefreshData) {
      this.userRefreshData = intervalAutorefresh(this.updateUserData);
    } else if (!this.props.loggedUser && this.userRefreshData) {
      clearInterval(this.userRefreshData);
    }

    // accepted cookie policy block, any unessental thirdparty library that uses cookies or local storage
    // should be included under this condition
    const cookiePolicy = localStorage.getItem("cookiePolicyAnswer");
    // we need a way to initate data once user accepts the policy, even if triggered after the component did mount hook.
    // Also we don't want to re-call the same block on each update so we added a cookieBlockInitiated flag
    if (
      !cookieBlockInitiated &&
      cookiePolicy &&
      JSON.parse(cookiePolicy).accepted
    ) {
      cookieBlockInitiated = true;
      // Place any cookie/localstorage generating libraries/code in HERE
      ReactGA.initialize(apiConfig.GOOGLE_ANALYTICS_API_KEY); // Unique Google Analytics tracking number

      const advancedMatching = {};
      const pixelOptions = {
        autoConfig: true,
        debug: false,
      };
      if (apiConfig.FACEBOOK_PIXEL_CODE) {
        ReactPixel.init(
          apiConfig.FACEBOOK_PIXEL_CODE,
          advancedMatching,
          pixelOptions
        );
      }
      function triggerFacebookPixel() {
        ReactPixel.pageView();
      }
      //Hotjar initialize
      if (apiConfig.HOTJAR_HJID && apiConfig.HOTJAR_HJSV) {
        hotjar.initialize(apiConfig.HOTJAR_HJID, apiConfig.HOTJAR_HJSV);
      }

      function triggerGATracking() {
        ReactGA.pageview(window.location.pathname);
      }

      this.props.onTryAutoSignup();

      // inital GA trigger
      triggerGATracking();
      if (apiConfig.FACEBOOK_PIXEL_CODE) {
        triggerFacebookPixel();
      }

      this.handlePinterestTag();

      // listening for route changes
      this.props.history.listen((location, action) => {
        // trigger GA per page
        triggerGATracking();
        if (apiConfig.FACEBOOK_PIXEL_CODE) {
          triggerFacebookPixel();
        }
        console.log("Google page change");
      });
    }

    // return the user to the private URL he wanted to access while he was a public user (before login/signup)
    const returnToTokenUrl = getReturnToUrlToken();
    if (this.props.loggedUser && returnToTokenUrl) {
      deleteReturnToUrlToken();
      setTimeout(() => this.props.history.push(returnToTokenUrl));
    }

    //Teacher checking
    const isTeacher =
      this.props.loggedUser && this.props.loggedUser.type === "teacher";
    const isStudent =
      this.props.loggedUser && this.props.loggedUser.type === "student";

    //Check teacher incoming call
    if (this.props.loggedUser && isTeacher) {
      this.intervalTeacherCheck = setInterval(this.teacherCheck, 5000);
    }

    //Check student incoming call
    if (this.props.loggedUser) {
      this.intervalStudentCheck = setInterval(this.studentCheck, 5000);
    }

    if (this.props.loggedUser && !this.state.mercureListening) {
      this.setState({ mercureListening: true });
      const response = await messageService.countUnread(this.props.token);
      const haveUnreadMessages = Boolean(response.data.total);

      this.props.newMessageNotification(haveUnreadMessages);

      const url =
        process.env.REACT_APP_MERCURE_URL +
        "/.well-known/mercure?topic=" +
        encodeURIComponent(
          "https://infodepot.com/notifications/" +
            this.props.loggedUser.cryptName
        );
      const es = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer " + this.props.loggedUser.jwtTokenMercure,
        },
      });

      if (this.es) { //should not happen
         this.es.close();
      }
      this.es = es;

      es.onmessage = (e) => {
        const data = JSON.parse(e.data);
        const match = matchPath(this.props.location.pathname, {
          path: "/messages/:cryptName",
          exact: true,
          strict: false,
        });

        if (data.type === "new_message_notification") {
          if (
            data.status === true &&
            match &&
            match.params.cryptName === data.cryptName
          ) {
            return false;
          }

          if (data.status === true) {
            this.playNewMessageNotification();
          }
          data.status === true
            ? this.props.newMessageNotification(true)
            : this.props.newMessageNotification(false);
        }
      };
    }
  }

  playNewMessageNotification = () => {
    const audio = new Audio(NotificationMessage);
    audio.play();
  };

  handlePinterestTag = () => {
    if (apiConfig.PINTEREST_TAG) {
      const pinterestScript = document.createElement("script");
      pinterestScript.innerHTML = `!function(e){if(!window.pintrk){window.pintrk = function () {
      window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
      n=window.pintrk;n.queue=[],n.version="3.0";var
      t=document.createElement("script");t.async=!0,t.src=e;var
      r=document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
      pintrk('load', '${apiConfig.PINTEREST_TAG}');
      pintrk('page');
       `;
      document.head.appendChild(pinterestScript);
    }
  };

  closeModalRating = () => {
    this.setState({ modalRateOpened: false });
  };

  //close twilio video
  closeTwilioVideo = () => {
    const isStudent =
      this.props.loggedUser && this.props.loggedUser.type === "student";

    this.setState({
      twilioVideo: false,
    });
  };

  closeModalSupport = () => {
    this.setState({ modalSupport: false });
    this.props.history.push({
      pathname: "/browse-infodepot/videos",
      state: { modalSupport: false },
    });
  };
  closeModalContest = () => {
    this.setState({ modalContest: false });
    let contestPopUp = "contestPopUp";
    localStorage.setItem("contestPopUp", contestPopUp);
    let startDate = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    localStorage.setItem("startDatePopup", startDate);
  };

  declineVideoCallTeacher = () => {
    const isTeacher =
      this.props.loggedUser && this.props.loggedUser.type === "teacher";
    const isStudent =
      this.props.loggedUser && this.props.loggedUser.type === "student";
    this.setState({ loading: true });

    const isAuthor = () =>
      Boolean(this.state.participant === this.props.loggedUser.fullName);

    //console.log('is or not author', isAuthor == true)

    if (!isAuthor()) {
      callService
        .declineVideoCall(this.props.token, this.state.videoSlug)
        .then((response) => {
          this.setState({
            loading: false,
            modalVideoCall: false,
            videoCallInfo: [],
          });
          this.props.snackbarAdd({
            message: "You have canceled the call",
            timeout: 8000,
            type: "info",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            modalVideoCall: false,
          });

          this.props.snackbarAdd({
            message:
              "We’re sorry, but the call could not be completed, it was either canceled or failed.",
            timeout: 8000,
            type: "info",
          });
        });
    }

    if (isAuthor()) {
      //console.log('check state',this.props.token, this.state.videoCallSlug);
      callService
        .deleteVideoCall(this.props.token, this.state.videoCallInfo.slug)
        .then((response) => {
          this.setState({
            loading: false,
            videoCallInfo: [],
            modalVideoCallQueueStudent: false,
          });

          this.props.snackbarAdd({
            message: "You have declined the call",
            timeout: 6000,
            type: "info",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            modalVideoCallQueueStudent: false,
          });

          this.props.snackbarAdd({
            message:
              "We’re sorry, but the call could not be completed, it was either canceled or failed.",
            timeout: 8000,
            type: "info",
          });
        });
    }
  };

  acceptVideoCall = () => {
    //this.setState({ loading: true});
    const isTeacher =
      this.props.loggedUser && this.props.loggedUser.type === "teacher";
    const isStudent =
      this.props.loggedUser && this.props.loggedUser.type === "student";

    const isAuthor = () =>
      Boolean(this.state.participant === this.props.loggedUser.fullName);

    this.setState({
      loading: true,
    });

    if (!isAuthor()) {
      callService
        .acceptVideoCall(this.props.token, this.state.videoCallInfoAuthor.slug)
        .then((response) => {
          this.setState({
            roomName: response.data.roomName,
            roomToken: response.data.token,
            loading: false,
            twilioVideo: true,
            modalVideoCall: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            modalVideoCall: false,
          });
          this.props.snackbarAdd({
            message:
              "We’re sorry, but the call could not be completed, it was either canceled or failed.",
            timeout: 8000,
            type: "info",
          });
        });
    }

    if (isAuthor()) {
      callService
        .callStatusStudent(this.props.token, this.state.videoCallInfo.slug)
        .then((response) => {
          if (response.data.videoCall.teacherStatus == "connected") {
            this.setState({
              roomName: response.data.roomName,
              roomToken: response.data.token,
              loading: false,
              modalVideoCallQueueStudent: false,
              twilioVideo: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            modalVideoCallQueueStudent: false,
          });
          this.props.snackbarAdd({
            message:
              "We’re sorry, but the call could not be completed, it was either canceled or failed.",
            timeout: 8000,
            type: "info",
          });
        });
    }
  };

  teacherCheck = () => {
    callService
      .incomingCallCheck(this.props.token)
      .then((response) => {
        if (response.data.roomName && isSupported) {
          const displayNameForCallerFormatted = renderDisplayName(response.data.author);
          this.setState({
            modalVideoCall: true,
            videoCallInfoAuthor: response.data,
            callInitiator: response.data.author,
            videoName: response.data.callClass.name,
            videoSlug: response.data.slug,
            profile: response.data.author.profile,
            checkPhoto: response.data.author.profile.photo,
            photoUrl: response.data.author.profile.photo
              ? response.data.author.profile.photo &&
                response.data.author.profile.photo.big
              : null,
            participant: response.data.author.fullName,
            //isTeacher: response.data.teacher.fullName,
            callFrommQueue: response.data.fromQueue,
            incomingCallUser: response.data.author.fullName,
            author: response.data.author.fullName,
            callStarted: true,
            displayNameForCaller: displayNameForCallerFormatted,
          });

          if (response.data.fromQueue == true) {
            document.getElementById("ringSoundQueue").play();
          }
        } else {
          if (this.state.modalVideoCall == true) {
            this.props.snackbarAdd({
              message:
                "We're sorry, but the call could not be completed, it was either canceled by student or failed.",
              timeout: 8000,
              type: "info",
            });
          }

          this.setState({
            modalVideoCall: false,
            loading: false,
            videoCallInfoAuthor: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          twilioVideo: false,
          modalVideoCall: false,
        });
      });
  };

  studentCheck = () => {
    callService
      .incomingCallCheckStudent(this.props.token)
      .then((response) => {
        if (response.data.roomName && response.data.fromQueue == true) {
          const displayNameForCallerFormatted = renderDisplayName(response.data.teacher);
          this.setState({
            modalVideoCallQueueStudent: true,
            videoCallInfo: response.data,
            videoCallSlug: response.data.callClass.slug,
            callInitiator: response.data.teacher,
            videoName: response.data.callClass.name,
            profile: response.data.teacher.profile,
            checkPhoto: response.data.teacher.profile.photo,
            photoUrl: response.data.author.profile.photo
              ? response.data.author.profile.photo &&
                response.data.author.profile.photo.big
              : null,
            participant: response.data.author.fullName,
            incomingCallUser: response.data.callClass.author.fullName,
            author: response.data.callClass.author.fullName,
            price: response.data.price,
            displayNameForCaller: displayNameForCallerFormatted,
          });
        } else {
          if (
            this.state.modalVideoCallQueueStudent == true &&
            this.state.twilioVideo == false
          ) {
            this.props.snackbarAdd({
              message:
                "We're sorry, but the call could not be completed, it was either canceled by teacher or failed.",
              timeout: 8000,
              type: "info",
            });
          }
          this.setState({
            modalVideoCallQueueStudent: false,
            videoCallInfo: [],
          });
        }
      })
      .catch((error) => {
        console.log("check error student check", error);
        this.setState({
          modalVideoCallQueueStudent: false,
          loading: false,
          twilioVideo: false,
        });
      });
  };

  render() {
    //Loading animation
    const loaderRender = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <CircularProgress />
      </div>
    );

    //console.log('check state', this.state)

    // const isAuthor = () =>
    // Boolean(this.state.participant && this.state.participant === this.props.loggedUser.fullName);
    let authorOfCall = Boolean(
      this.state.participant &&
        this.state.participant === this.props.loggedUser.fullName
    );

    //Clear intervals
    clearInterval(this.intervalTeacherCheck);
    clearInterval(this.intervalStudentCheck);

    const loadingUserData = Boolean(
      this.props.isAuthenticated && !this.props.loggedUser
    );

    const loadingApp = this.props.loadingApp;
    const userIsLogged = Boolean(this.props.loggedUser);

    // public routes
    let publicRoutes = [
      { path: "/check-email", component: container.CheckEmail },
      { path: "/auth/reset", component: container.ResetPassword },
      {
        path: "/terms-and-conditions",
        component: container.TermsAndConditions,
        title: "Terms and Conditions | InfoDepot",
        description: "Read more about the InfoDepot terms and conditions.",
      },
      {
        path: "/privacy-notice",
        component: container.PrivacyNotice,
        title: "Privacy Policy | InfoDepot",
        description:
          "Read more about the InfoDepot privacy policy and find out how InfoDepot collects, uses, protects and handles personal data.",
      },
      {
        path: "/contact-us",
        component: container.ContactUs,
        title: "Contact us | InfoDepot",
        description:
          "Have a question, suggestion or an issue you want to report? Contact the InfoDepot team and someone will get back to you.",
      },
      {
        path: "/faq",
        component: container.FAQ,
        title: "InfoDepot FAQs: Learn more | InfoDepot",
        description:
          "If you need help or you want to find out more about InfoDepot, take a look at the InfoDepot FAQ page where all your questions will be answered.",
      },
      {
        path: "/browse-infodepot/",
        exact: true,
        component: container.BrowseClasses,
        title:
          "Start learning today. Browse popular video classes and tutorials.",
        description:
          "Kick-start your learning journey with a selection of the best teachers and classes on InfoDepot. Join now for free and choose the right class for you.",
      },
      {
        path: "/browse-infodepot/:classType/",
        component: container.BrowseClasses,
      },
      { path: "/video/:id/:tab?", component: container.VideoClass },
      { path: "/call/:slug/live-call", component: container.StartLiveCall },
      { path: "/call/:id/:tab?", component: container.LiveCall },
      {
        path: "/fees-info",
        component: container.FeesInfo,
        title: "InfoDepot Fees | InfoDepot",
        description:
          "Interested in becoming an InfoDepot teacher? Find out more about the InfoDepot fees and why you should register now.",
      },
      { path: "/quick-start/", component: container.QuickStartTutorials },
      {
        path: "/contest",
        component: container.ContestPage,
        title: "InfoDepot: Learn more | InfoDepot",
        description:
          "If you need help or you want to find out more about InfoDepot, take a look at the InfoDepot FAQ page where all your questions will be answered.",
      },
      { path: "/profile/:slug/:tab?", exact: true, component: container.Profile },
      //{ path: '/quick-start/:type/:id/:slug/', component: container.QuickStartTutorials },
      { path: "/pricing-plans/:plans?", component: container.PricingPage },
    ];

    // exclusively public routes
    if (!userIsLogged) {
      publicRoutes.push(
        {
          path: "/auth",
          exact: true,
          component: container.Auth,
          title: "Sign in today and continue learning | InfoDepot",
          description:
            "Already have an account on InfoDepot? Sign in to access your InfoDepot account and to continue your learning and teaching journeys.",
        },
        {
          path: "/sign-up",
          component: container.SignUp,
          title:
            "Join InfoDepot online learning platform. Register for free | InfoDepot",
          description:
            "Join InfoDepot for free and gain access to a variety of online classes. Learn about business, health, language, tech, lifestyle and many other topics.",
        },
        { path: "/sign-up-teacher/:planId?/:isAnnualBilling?", component: container.SignUpTeacher },
        { path: "/sign-up-student", component: container.SignUpStudent },
        { path: "/auth/confirm", component: container.ConfirmToken },
        { path: "/auth", exact: true, component: container.Auth },
        { path: "/forgot-password", component: container.ForgotPassword },
        { path: "/become-a-teacher", component: container.TeacherPage },
        // { path: '/contest', component: container.ContestPage },
        { path: "/", exact: true, component: container.LandingPage }
      );
    }

    // approved routes
    const loggedUserRoutes = [
      {
        path: "/account/settings/:type/",
        component: container.AccountSettings,
      },
      {
        path: "/account/settings",
        exact: true,
        component: container.AccountSettings,
      },
      {
        path: "/account/transaction-history",
        exact: true,
        component: TransactionHistory,
      },
      {
        path: "/account/transaction-history/:type",
        component: TransactionHistory,
      },
      { path: "/sign-up-teacher-step-2/:planId?/:isAnnualBilling?/:upgradeStep?", component: container.SignUpTeacherStep2 },
      { path: "/upgrade-teacher-plan/:planId?/:isAnnualBilling?", component: container.UpgradeTeacherPlan },
      { path: "/account/dashboard/", exact: true, component: Dashboard },
      { path: "/account/dashboard/:type", component: Dashboard },
      { path: "/account/", exact: true, component: container.MyAccount },
      { path: "/account/:tab", component: container.MyAccount },
      { path: "/balance", component: container.MyBalance },
      { path: "/buy-credits", component: container.BuyCredits },
      { path: "/payout", exact: true, component: container.Payout },
      { path: "/successful-payment", component: container.SuccessfulPayment },
      { path: "/successful-payout", component: container.SuccessfulPayout },
      { path: "/start-teaching", component: container.StartTeaching },
      { path: "/create-video", component: container.CreateVideo },
      { path: "/edit-video/:slug", component: container.CreateVideo },
      { path: "/set-up-live-call", component: container.SetUpLiveCall },
      { path: "/edit-live-call/:slug", component: container.SetUpLiveCall },
      { path: "/logout", component: container.Logout },
      {
        path: "/confirmation-message-video-queue",
        component: container.ConfirmMessageVideoQueue,
      },
      { path: "/messages/:cryptName", component: container.Message },
    ];

    let routes = [...publicRoutes, ...(userIsLogged ? loggedUserRoutes : [])];

    let redirection = (
      <Redirect to={userIsLogged ? "/browse-infodepot" : "/"} />
    );

    /* let redirection;
    if (this.props.location.pathname === "/create-video") {
      redirection = (
        <Redirect
          to={userIsLogged ? "/browse-infodepot" : "/sign-up-teacher"}
        />
      );
    } else {
      redirection = <Redirect to={userIsLogged ? "/browse-infodepot" : "/"} />;
    } */

    const appMarkup = (
      <Layout>
        <Switch>
          {/* List all the routes user is able to access to */}
          {routes.map((route, index) => (
            <Route
              path={route.path}
              exact={route.exact}
              component={route.component}
              key={index}
            />
          ))}

          {/* Redirect if some path is not undefined */}
          {redirection}
        </Switch>
      </Layout>
    );

    return (
      // @TODO h1 loader is just a placeholder

      <React.Fragment>
        {loadingUserData || loadingApp ? (
          <h1 style={{ padding: "20px" }}>Loading...</h1>
        ) : (
          appMarkup
        )}
        {/* this.state.roomName != '' ? classes.TwilioVideo : classes.DisplayNone  && this.state.roomToken !== ''  && this.state.roomToken !== ''*/}
        {this.state.twilioVideo == true ? (
          <TwilioVideo
            roomName={this.state.roomName}
            roomToken={this.state.roomToken}
            participient={this.state.participant}
            videoName={this.state.videoName}
            checkPhoto={this.state.checkPhoto}
            photoUrl={this.state.photoUrl} //.big
            videoRoom={this.videoRoom}
            closeTwilioVideo={this.closeTwilioVideo}
            callFrommQueue={this.state.callFrommQueue}
            incomingCallUser={this.state.incomingCallUser}
            author={this.state.author}
            displayNameForCaller={this.state.displayNameForCaller}
          />
        ) : (
          ""
        )}
        <Modal
          className={[classes.Card, classes.ModalVideoCallIncoming].join(" ")}
          open={
            this.state.modalVideoCall || this.state.modalVideoCallQueueStudent
          }
          //onClose={() => this.props.onClose()}
        >
          <div className={classes.LeftShape}>
            <img src={LeftShape} alt="ShapeLeft" />
          </div>
          <div className={classes.RightShape}>
            <img src={RightShape} alt="ShapeLeft" />
          </div>
          <div className={classes.LeftMiddleShape}>
            <img src={LeftMiddleShape} alt="ShapeLeftMiddle" />
          </div>
          <div className={classes.RightMiddleShape} id="right">
            <img src={RightMiddleShape} alt="ShapeRightMiddle" />
          </div>
          {this.state.callFrommQueue == true ? (
            <h2 className={classes.QueueInfo}>
              You have another student in a queue.
              <br /> Are you available to connect?
            </h2>
          ) : (
            ""
          )}
          {authorOfCall == true ? (
            <div>
              <h2 className={classes.QueueInfo}>
                Teacher you queued for is now available.
              </h2>
              <h3 className={classes.QueuePrice}>
                Your account will be charged for{" "}
                <span className={classes.GreenSpan}>
                  ${(this.state.price * 1).toFixed(2)}
                </span>{" "}
                per minute.
              </h3>
            </div>
          ) : (
            ""
          )}
          <Avatar
            src={
              this.state.profile && this.state.checkPhoto
                ? this.state.checkPhoto.big
                : null
            }
            className={
              this.state.callFrommQueue !== true
                ? classes.Avatar
                : classes.AvatarWithoutAnim
            }
            size="140"
          />
          {/* name */}

          {this.state.callFrommQueue == true ? (
            <div>
              <h3 className={classes.VideoCallName}>
                {/* {this.state.incomingCallUser} */}
                {this.state.callInitiator && this.state.callInitiator.displayName && <ChosenDisplayName user={this.state.callInitiator} />}
              </h3>
              <h4 className={classes.AuthorName}>
                queued for {this.state.videoName}
              </h4>
            </div>
          ) : (
            <div>
              <h3 className={classes.VideoCallName}>{this.state.videoName}</h3>
              <h4 className={classes.AuthorName}>
                {/* {this.state.incomingCallUser} */}
                {this.state.callInitiator && this.state.callInitiator.displayName && <ChosenDisplayName user={this.state.callInitiator} />}
              </h4>
            </div>
          )}

          {this.state.callFrommQueue == false ? (
            <span className={classes.GreySpan}>Incoming call...</span>
          ) : (
            ""
          )}
          {this.state.loading == false ? (
            <div className={classes.ButtonsWrap}>
              <div className={classes.ButtonAction}>
                <a
                  className={classes.CancelVideoCall}
                  onClick={this.declineVideoCallTeacher}
                  callSlug={this.props.call}
                >
                  <ReactSVG src={CloseIcon} />
                  <br />
                </a>
                <span>Decline</span>
              </div>
              {this.state.callFrommQueue !== true ? (
                <audio id="ringSoundPlayer" autoPlay loop preload="auto">
                  <source src={SoundUrl} type="audio/mpeg" />
                  <source src={SoundUrlOgg} type="audio/ogg"></source>
                </audio>
              ) : (
                <audio id="ringSoundQueue" autoplay preload="auto">
                  <source src={SoundUrlQueue} type="audio/mpeg" />
                  <source src={SoundUrlQueueOgg} type="audio/ogg"></source>
                </audio>
              )}
              <div className={classes.ButtonAction}>
                <a
                  className={classes.AcceptVideoCall}
                  onClick={this.acceptVideoCall}
                >
                  <ReactSVG src={CheckIcon} />
                  <br />
                </a>
                <span>
                  {this.state.callFrommQueue !== true ? "Accept" : "Connect me"}
                </span>
              </div>
            </div>
          ) : (
            loaderRender
          )}
        </Modal>
        <Modal
          className={classes.Card}
          open={this.state.modalSupport}
          onClose={() => this.closeModalSupport()}
        >
          <div className={classes.ModalBrowserInfo}>
            <h1>Unsupported browser</h1>
            <p>
              You will not be able to receive video calls as your browser
              doesn't support video conversations yet. Don't worry, there is an
              easy fix. Just log in to InfoDepot in one of the following
              browsers to get the video calls from students:
            </p>

            {this.state.osDetected == "Linux" ? (
              <div className={classes.BrowsersWrapp}>
                {/* <div className={classes.BrowserItem}><a href=""><ReactSVG  src={IconSafari} />Safari</a></div> */}
                <div className={classes.BrowserItem}>
                  <a href="https://www.google.com/chrome/">
                    <ReactSVG src={IconChrome} />
                    Chrome
                  </a>
                </div>
                <div className={classes.BrowserItem}>
                  <a href="https://www.mozilla.org/en-US/firefox/browsers/">
                    <ReactSVG src={IconFirefox} />
                    Firefox
                  </a>
                </div>
              </div>
            ) : this.state.osDetected == "Windows" ? (
              <div className={classes.BrowsersWrapp}>
                <div className={classes.BrowserItem}>
                  <a href="https://www.google.com/chrome/">
                    <ReactSVG src={IconChrome} />
                    Chrome
                  </a>
                </div>
                <div className={classes.BrowserItem}>
                  <a href="https://www.mozilla.org/en-US/firefox/browsers/">
                    <ReactSVG src={IconFirefox} />
                    Firefox
                  </a>
                </div>
                <div className={classes.BrowserItem}>
                  <a href="https://www.microsoft.com/en-us/edge">
                    <ReactSVG src={IconEdge} />
                    Edge
                  </a>
                </div>
              </div>
            ) : this.state.osDetected == "MacOS" ? (
              <div className={classes.BrowsersWrapp}>
                <div className={classes.BrowserItem}>
                  <a href="https://www.google.com/chrome/">
                    <ReactSVG src={IconChrome} />
                    Chrome
                  </a>
                </div>
                <div className={classes.BrowserItem}>
                  <a href="https://www.mozilla.org/en-US/firefox/browsers/">
                    <ReactSVG src={IconFirefox} />
                    Firefox
                  </a>
                </div>
                <div className={classes.BrowserItem}>
                  <a href="https://support.apple.com/downloads/safari">
                    <ReactSVG src={IconSafari} />
                    Safari
                  </a>
                </div>
              </div>
            ) : this.state.osDetected == "iOS" ? (
              <div className={classes.BrowsersWrapp}>
                <div className={classes.BrowserItem}>
                  <a href="https://support.apple.com/downloads/safari">
                    <ReactSVG src={IconSafari} />
                    Safari
                  </a>
                </div>
              </div>
            ) : this.state.osDetected == "Android" ? (
              <div className={classes.BrowsersWrapp}>
                <div className={classes.BrowserItem}>
                  <a href="https://www.google.com/chrome/">
                    <ReactSVG src={IconChrome} />
                    Chrome
                  </a>
                </div>
                <div className={classes.BrowserItem}>
                  <a href="https://www.mozilla.org/en-US/firefox/browsers/">
                    <ReactSVG src={IconFirefox} />
                    Firefox
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>

        <Modal
          //className={[classes.Card , classes.CardWithBorder]}
          className={[classes.Card, classes.CardWithBorder].join(" ")}
          open={this.state.modalContest}
          onClose={() => this.closeModalContest()}
        >
          <div className={classes.ModalBrowserInfo}>
            <img src={Trophy} alt="contest" style={{ width: "94px" }} />
            <h1
              style={{
                fontSize: "30px",
                marginTop: "0px",
                fontWeight: "500",
                marginBottom: "20px",
              }}
            >
              Contest for Creators and Crafters
            </h1>
            <h4 style={{ lineHeight: "24px" }}>
              Upload at least <b>2 video tutorials</b> between{" "}
              <b>April 19 and May 19 and win a $1,000 cash prize</b>.
            </h4>
            <Link to={"/contest"}>
              <Button
                className={classes.ButtonDark}
                size="large"
                onClick={this.closeModalContest}
              >
                ENTER THE CONTEST
              </Button>
            </Link>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: Boolean(state.auth.accessToken),
    loggedUser: state.user,
    loadingApp: state.auth.loading,
    token: state.auth.accessToken,
    modalSupport: state.modalSupport,
    modalContest: state.modalContest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    updateLoggedUser: () => dispatch(actions.getLoggedUser()),
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
    newMessageNotification: (newMessageNotification) =>
      dispatch(actions.notificationMessage(newMessageNotification)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
