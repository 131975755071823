import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import clone from 'lodash.clonedeep';

import classes from './SignUpStudent.module.scss';
import { Form, Input, Checkbox } from '../../components/UI/Form/Form';
import Button from '../../components/UI/Button/Button';
import Card from '../../components/UI/Card/Card';
import * as validator from '../../utils/formValidator';
import * as userService from '../../services/user/userService';
import { errorParser } from '../../utils/errorParser';

class SignUpTeacher extends Component {
	state = {
		loading: false,

		formControls: {
			firstName: {
				id: 'firstName',
				type: 'text',
				placeholder: 'First name',
				value: '',
				validation: {
					required: true
				},
				error: '',
				touched: false
			},
			lastName: {
				id: 'lastName',
				type: 'text',
				placeholder: 'Last name',
				value: '',
				validation: {
					required: true
				},
				error: '',
				touched: false
			},
			username: {
				id: 'username',
				type: 'text',
				placeholder: 'Username',
				value: '',
				validation: {
					required: true,
					minLength: 4
				},
				error: '',
				touched: false
			},
			email: {
				id: 'email',
				type: 'text',
				placeholder: 'Email address',
				value: '',
				validation: {
					required: true,
					isEmail: true
				},
				error: '',
				touched: false
			},
			password: {
				id: 'password',
				type: 'password',
				placeholder: 'Password',
				infolabel: '8 characters minimum',
				value: '',
				showPassword: false,
				adornment: {
					position: 'end',
					icon: () => (
						<IconButton
							className={classes.IconShowPassword}
							onClick={(e) => this.toggleShowPassword()}>
							{this.state.formControls.password.showPassword ? (
								<Visibility />
							) : (
								<VisibilityOff />
							)}
						</IconButton>
					)
				},
				validation: {
					required: true,
					minLength: 8
				},
				error: '',
				touched: false
			},
			promotional: {
				id: 'promotional',
				type: 'checkbox',
				checked: 'false',
				value: '',
				error: '',
				touched: true
			},
			terms: {
				id: 'terms',
				type: 'checkbox',
				checked: 'false',
				value: '',
				validation: {
					required: true
				},
				error: '',
				touched: false
			}
		}
	};

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	toggleShowPassword() {
		const stateClone = clone(this.state);

		stateClone.formControls.password.showPassword = !stateClone.formControls.password
			.showPassword;
		stateClone.formControls.password.type = stateClone.formControls.password.showPassword
			? 'text'
			: 'password';
		this.setState(stateClone);
	}

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);

		// if there is no value it is the radio/checkbox input, in that case take the name
		if (formControlsCopy[controlName].type !== 'checkbox') {
			formControlsCopy[controlName].value = e.target.value;
		} else {
			formControlsCopy[controlName].value = formControlsCopy[controlName].value
				? ''
				: controlName;
		}
		formControlsCopy[controlName].touched = true;
		formControlsCopy[controlName].error = '';

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const errorMsg = validator.validateInput(formControlsCopy[controlName]);
		if (errorMsg) {
			formControlsCopy[controlName].error = errorMsg;
		}

		this.setState({
			formControls: formControlsCopy,
			formIsValid: validator.formIsValid(formControlsCopy)
		});
	};

	prepareSubmitData = () => {
		const userData = {
			username: this.state.formControls.username.value,
			email: this.state.formControls.email.value,
			plainPassword: {
				first: this.state.formControls.password.value,
				second: this.state.formControls.password.value
			},
			profile: {
				firstName: this.state.formControls.firstName.value,
				lastName: this.state.formControls.lastName.value,
				terms: Boolean(this.state.formControls.terms.value)
			},
			settings: {
				promotional: Boolean(this.state.formControls.promotional.value)
			}
		};
		return userData;
	};

	submit = () => {
		if (this.state.formIsValid) {
			this.setState({ loading: true });

			userService
				.signupStudent(this.prepareSubmitData())
				.then((response) => {
					const urlParams = this.props.location.pathname;
					this.props.history.push('/check-email', { urlParams });
				})
				.catch((error) => {
					const apiErr = errorParser(error.response.data.errors);
					console.log(error);
					console.log(apiErr);

					const cloneForm = clone(this.state.formControls);

					if (apiErr) {
						// config object to be used by checkFormApiErrors
						const errorStateConfig = {
							formEmailError: 'email',
							formPlainPasswordFirstError: 'password',
							formProfileFirstNameError: 'firstName',
							formProfileLastNameError: 'lastName',
							formUsernameError: 'username'
						};
						// checking form errors for matching pairs defined in the errorStateConfig
						validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);
					}

					this.setState({
						formControls: cloneForm,
						loading: false
					});
				});
		} else {
			this.setState({ formIsValid: validator.formIsValid(this.state.formControls) });
			// TODO focus to the first errored input
		}
	};

	render() {
		return (
			<div className={classes.Container}>
				<h1>Create account as student</h1>
				<Card className={classes.SignUpCard}>
					<Form onSubmit={this.submit}>
						<Input
							style={{ width: 'calc(50% - 6px)' }}
							config={this.state.formControls.firstName}
							onChange={(e) => this.inputChangeHandler(e, 'firstName')}
							onBlur={(e) => this.inputBlurHandler(e, 'firstName')}
						/>
						<Input
							style={{ width: 'calc(50% - 6px)', marginLeft: 'auto' }}
							config={this.state.formControls.lastName}
							onChange={(e) => this.inputChangeHandler(e, 'lastName')}
							onBlur={(e) => this.inputBlurHandler(e, 'lastName')}
						/>
						<Input
							config={this.state.formControls.username}
							onChange={(e) => this.inputChangeHandler(e, 'username')}
							onBlur={(e) => this.inputBlurHandler(e, 'username')}
						/>
						<Input
							config={this.state.formControls.email}
							onChange={(e) => this.inputChangeHandler(e, 'email')}
							onBlur={(e) => this.inputBlurHandler(e, 'email')}
						/>
						<Input
							config={this.state.formControls.password}
							onChange={(e) => this.inputChangeHandler(e, 'password')}
							onBlur={(e) => this.inputBlurHandler(e, 'password')}
						/>
						<div className={classes.TermsWrap}>
							<Checkbox
								config={this.state.formControls.promotional}
								value={'promotional'}
								onChange={(e) => this.inputChangeHandler(e, 'promotional')}
								onBlur={(e) => this.inputBlurHandler(e, 'promotional')}
							/>
							<span style={{ marginLeft: '-1.5rem' }}>
								I wish to receive promotional emails <br />
								(newsletters and special offers).
							</span>
						</div>

						<div className={classes.TermsWrap} style={{ marginBottom: '35px' }}>
							<Checkbox
								config={this.state.formControls.terms}
								value={'terms'}
								onChange={(e) => this.inputChangeHandler(e, 'terms')}
								onBlur={(e) => this.inputBlurHandler(e, 'terms')}
							/>
							<span style={{ marginLeft: '-1.5rem' }}>
								I agree to InfoDepot{' '}
								<Link to={'/terms-and-conditions'}>Terms & Conditions</Link> and{' '}
								<Link to={'/privacy-notice'}>Privacy Policy</Link>.
							</span>
						</div>

						<Button
							fullWidth={true}
							size="large"
							type="submit"
							disabled={this.state.loading || !this.state.formIsValid}
							loading={this.state.loading}>
							FINISH
						</Button>
					</Form>
				</Card>
				<div className={classes.NoAccountWrap}>
					<span>Already have an account?</span> <Link to={'/auth'}>Sign in</Link>
				</div>
			</div>
		);
	}
}

export default SignUpTeacher;
