import axiosInstance from '../axiosDefaultInstance'
// comments and replies
export const postComment = (token, videoSlug, comment) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const payload = {
    content: comment
  }
  const url = '/classes/video/' + videoSlug + '/comment'

  return axiosInstance.post(url, payload, config)
}

export const postReply = (token, commentId, comment) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const payload = {
    content: comment
  }
  const url = '/classes/video/comment/' + commentId + '/reply'

  return axiosInstance.post(url, payload, config)
}

export const getComments = (token, videoSlug, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `/classes/video/${videoSlug}/comments${
    queryParams ? '?' + queryParams : ''
  }`

  return axiosInstance.get(url, token ? config : null)
}

export const getReplies = (token, commentId, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `/classes/video/comment/${commentId}/replies${
    queryParams ? '?' + queryParams : ''
  }`

  return axiosInstance.get(url, config)
}

// documents
export const addDocument = (token, file, videoId, videoSlug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const query = videoSlug
    ? '&videoClassSlug=' + videoSlug
    : 'videoClassId=' + videoId
  const url = '/classes/video/add-document?' + query

  const formData = new FormData()
  formData.append('document[binaryContent]', file, file.name)

  return axiosInstance.post(url, formData, config)
}

export const deleteDocument = (token, docSlug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/video/document/' + docSlug + '/delete'
  return axiosInstance.delete(url, config)
}

export const deleteVideoService= (token, videoSlug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/video/' + videoSlug 
  return axiosInstance.delete(url, config)
}

export const downloadDocument = (token, docSlug) => {
  const config = {
    responseType: 'blob',
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/video/document/' + docSlug + '/download'
  return axiosInstance.get(url, config)
}

// videos PUBLIC in case it is public there is no token
export const getAllVideos = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/all-videos?' + (queryParams || '')

  return axiosInstance.get(url, token ? config : null)
}

export const getPurchasedVideos = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
    '/classes/me/video-purchases' + (queryParams ? '?' + queryParams : '')

  return axiosInstance.get(url, config)
}

export const createSeleton = (token, newInstance = true) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const newInstanceQuery = '?go_back_to_create_class=' + !newInstance
  const url = '/classes/video/create-skeleton' + newInstanceQuery

  return axiosInstance.post(url, null, config)
}

export const uploadCoverPhoto = (token, file, id, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token,
      'content-type': 'multipart/form-data'
    }
  }

  const query = slug ? '&videoClassSlug=' + slug : 'videoClassId=' + id
  const url = '/classes/video/cover-image?' + query
  const formData = new FormData()
  formData.append('cover[binaryContent]', file, file.name)

  return axiosInstance.post(url, formData, config)
}

export const createVideoClass = (token, videoClassData) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/video/create'

  return axiosInstance.post(url, videoClassData, config)
}

export const editVideoClass = (token, videoClassData, videoSlug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/video/' + videoSlug

  return axiosInstance.put(url, videoClassData, config)
}

export const getLevels = type => {
  const query = type ? '?type=' + type : ''
  const url = '/classes/video/levels' + query

  return axiosInstance.get(url)
}

export const getLanguages = () => {
  const url = '/classes/languages'

  return axiosInstance.get(url)
}

// public API for video class
export const getVideoClass = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/video/' + slug

  return axiosInstance.get(url, token ? config : null)
}

export const getTeacherVideos = (token, query) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/videos?' + query

  return axiosInstance.get(url, config)
}

export const purchaseVideoClass = (token, videoSlug, queryParams) => {

  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const url = `/classes/video/${videoSlug}/purchase` + queryParams;
  // console.log('url:');
  // console.log(url);
  // return null;
  return axiosInstance.post(url, null, config);
}

export const publishVideoClass = (token, slug, statusPublished) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const videoStatus = {
    status: 'published'
  }

  const url = '/classes/video/' + slug + '/publishing'

  return axiosInstance.put(url, videoStatus, config)
}

export const getCoupon = (token, queryParams) => {
  const url = '/coupon' + (queryParams || '');

  const config = {
    headers: {
      'X-Auth-Token': token,
    },
  };

  return axiosInstance.get(url, config);
};
