import React, { Component } from 'react';
import clone from 'lodash.clonedeep';
import { withRouter } from 'react-router-dom';

import classes from '../AccountSettings.module.scss';
import * as validator from '../../../utils/formValidator';
import Button from '../../../components/UI/Button/Button';
import { Form, Input } from '../../../components/UI/Form/Form';
import * as userService from '../../../services/user/userService';
import { errorParser } from '../../../utils/errorParser';
import countriesJSON from '../../../shared/json/countries.json';
import usaStatesJSON from '../../../shared/json/usaStates.json';
import { isUrlQueued, goToNextQueuedUrl } from '../../../utils/redirectTo';

class PersonalSettings extends Component {
	state = (() => {
		const user = this.props.user;
		return {
			loading: false,
			formIsValid: false,
			formControls: {
				street: {
					id: 'Street address',
					type: 'text',
					placeholder: 'Street address',
					placeholderStatic: 'Enter street name and number',
					shrink: true,
					value: user.profile.street,
					validation: { required: true },
					error: '',
					valid: false,
					touched: user.profile.street
				},
				city: {
					id: 'city',
					type: 'text',
					placeholderStatic: 'Enter city',
					placeholder: 'City',
					shrink: true,
					value: user.profile.city,
					validation: { required: true },
					error: '',
					valid: false,
					touched: user.profile.city
				},
				zipCode: {
					id: 'zipCode',
					type: 'text',
					placeholder: 'ZIP code',
					placeholderStatic: 'Enter ZIP code',
					shrink: true,
					value: user.profile.zipCode,
					validation: { required: true },
					error: '',
					valid: false,
					touched: user.profile.zipCode
				},
				country: {
					id: 'country',
					type: 'text',
					select: true,
					native: true,
					// using the same list that backend is using and adding an empty value for placeholder
					selectList: [ ...countriesJSON, { value: '', label: '' } ],
					placeholder: 'Country',
					placeholderStatic: 'Select country',
					shrink: true,
					value: user.profile.country || '',
					validation: { required: true },
					error: '',
					valid: false,
					touched: user.profile.country
				},
				state: {
					id: 'state',
					type: 'text',
					select: true,
					native: true,
					// formating the object of state names into array with proper value/label pairs to be accepted by input select
					selectList: Object.keys(usaStatesJSON).reduce(
						(agg, cur) => {
							agg.push({ value: cur, label: usaStatesJSON[cur] });
							return agg;
						},
						[ { value: '', label: '' } ]
					),
					placeholder: 'State',
					placeholderStatic: 'Select state',
					shrink: true,
					value: user.profile.state,
					validation: { required: true },
					error: '',
					valid: false,
					touched: user.profile.state
				},
				// socialNumber1: {
				// 	id: 'socialNumber1',
				// 	type: 'text',
				// 	group: [ 'socialNumber1', 'socialNumber2', 'socialNumber3' ],
				// 	groupError: false,
				// 	placeholder: 'Social security number',
				// 	placeholderStatic: 'xxx',
				// 	shrink: true,
				// 	value: user.profile.socialNumber
				// 		? user.profile.socialNumber.substring(0, 3)
				// 		: '',
				// 	validation: {
				// 		required: true,
				// 		isNumeric: true,
				// 		isSocialNumber: true,
				// 		socialNumLength: 3
				// 	},
				// 	error: '',
				// 	valid: false,
				// 	touched: user.profile.socialNumber
				// },
				// socialNumber2: {
				// 	id: 'socialNumber2',
				// 	type: 'text',
				// 	group: [ 'socialNumber1', 'socialNumber2', 'socialNumber3' ],
				// 	groupError: false,
				// 	placeholder: '',
				// 	placeholderStatic: 'xx',
				// 	shrink: true,
				// 	value: user.profile.socialNumber
				// 		? user.profile.socialNumber.substring(3, 5)
				// 		: '',
				// 	validation: {
				// 		required: true,
				// 		isNumeric: true,
				// 		isSocialNumber: true,
				// 		socialNumLength: 2
				// 	},
				// 	error: '',
				// 	valid: false,
				// 	touched: user.profile.socialNumber
				// },
				// socialNumber3: {
				// 	id: 'socialNumber3',
				// 	type: 'text',
				// 	group: [ 'socialNumber1', 'socialNumber2', 'socialNumber3' ],
				// 	groupError: false,
				// 	placeholder: '',
				// 	placeholderStatic: 'xxxx',
				// 	shrink: true,
				// 	value: user.profile.socialNumber
				// 		? user.profile.socialNumber.substring(5, 9)
				// 		: '',
				// 	validation: {
				// 		required: true,
				// 		isNumeric: true,
				// 		isSocialNumber: true,
				// 		socialNumLength: 4
				// 	},
				// 	error: '',
				// 	valid: false,
				// 	touched: user.profile.socialNumber
				// }
			}
		};
	})();

	// clears the errors and 'dirty' the input on change. Errors will be checked on blur
	clearInput = (inpt) => {
		inpt.touched = true;
		inpt.error = '';

		// if input is part of the group clear all group memebers
		if (inpt.group) {
			inpt.groupError = false;
		}
	};
	// exclude the input from validation if they are constrainded to certain conditon
	excludeRestrictedInputs = (e, controlName, form) => {
		let formFiltered;
		// if country is a non USA or is a student there is no social number fields so remove them from validation
		if (this.props.user.type === 'student' || form.country.value !== 'USA') {
			formFiltered = clone(this.state.formControls);
			// keep the reference to the original input (ctrl) as we still need to validate most current value
			formFiltered[controlName] = form[controlName];
			// delete formFiltered.socialNumber1;
			// delete formFiltered.socialNumber2;
			// delete formFiltered.socialNumber3;
			// only in case of USA value as a country, keep the state input for validation
			if (form.country.value !== 'USA' && e.target.value !== 'USA') {
				delete formFiltered.state;
			}
		}
		return formFiltered;
	};

	inputChangeHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];
		//console.log(this.state.formIsValid);

		// if there is no value it is the radio/checkbox input, in that case take the name
		if (ctrl.type !== 'checkbox') {
			ctrl.value = e.target.value;

			// SPECIAL USA CHECK! If it is not USA empty the dependant fields
			if (controlName === 'country' && e.target.value !== 'USA') {
				formControlsCopy.state.value = '';
				formControlsCopy.state.error = '';

				// formControlsCopy.socialNumber1.value = '';
				// formControlsCopy.socialNumber2.value = '';
				// formControlsCopy.socialNumber3.value = '';

				// formControlsCopy.socialNumber1.groupError = '';
				// formControlsCopy.socialNumber2.groupError = '';
				// formControlsCopy.socialNumber3.groupError = '';

				// formControlsCopy.socialNumber1.error = '';
				// formControlsCopy.socialNumber2.error = '';
				// formControlsCopy.socialNumber3.error = '';
			}
		} else {
			ctrl.value = ctrl.value === controlName ? '' : controlName;
		}
		// if input is part of the group highlight all the memeber of a group in case of an error
		if (ctrl.group) {
			ctrl.group.forEach((inputName) => {
				this.clearInput(formControlsCopy[inputName]);
			});
		} else {
			this.clearInput(ctrl);
		}

		// remove inputs from validation if it has rules: 'State' input shown only for USA or social number only for teachers
		const filteredForm = this.excludeRestrictedInputs(e, controlName, formControlsCopy);

		this.setState({
			formControls: formControlsCopy,
			// if is is a non USA country selected exclude state and social number checks
			formIsValid: validator.formIsValid(filteredForm ? filteredForm : formControlsCopy)
		});
	};

	// checks validity of single input
	getInputError = (input) => {
		return validator.validateInput(input);
	};

	inputBlurHandler = (e, controlName) => {
		const formControlsCopy = clone(this.state.formControls);
		const ctrl = formControlsCopy[controlName];

		// if it is a group show error only underneath the first input
		if (ctrl.group) {
			const errorMsg = this.getInputError(ctrl);
			if (errorMsg) {
				// show the msg on first input
				formControlsCopy[ctrl.group[0]].error = errorMsg;
				// set error on all group fields
				ctrl.group.forEach((inputName) => {
					formControlsCopy[inputName].groupError = true;
				});
			}
		} else {
			ctrl.error = this.getInputError(ctrl);
		}

		// remove inputs from validation if it has rules: 'State' input shown only for USA or social number only for teachers
		const filteredForm = this.excludeRestrictedInputs(e, controlName, formControlsCopy);

		this.setState({
			formControls: formControlsCopy,
			// if is is a non USA country selected exclude state and social number checks
			formIsValid: validator.formIsValid(filteredForm ? filteredForm : formControlsCopy)
		});
	};

	prepareSubmitData() {
		const form = this.state.formControls;

		// const socialNumber =
		// 	this.props.user.type === 'teacher' && form.country.value === 'USA'
		// 		? {
		// 				socialNumber:
		// 					form.socialNumber1.value +
		// 					form.socialNumber2.value +
		// 					form.socialNumber3.value
		// 			}
		// 		: {};

		return {
			street: form.street.value && form.street.value.trim(),
			city: form.city.value && form.city.value.trim(),
			state: form.state.value && form.state.value.trim(),
			country: form.country.value && form.country.value.trim(),
			zipCode: form.zipCode.value && form.zipCode.value.trim(),
			//...socialNumber
		};
	}

	submit(e) {
		e.preventDefault();
		// if user has hit keyboard button "enter" from the form input, focus the element to check the validity of the input on blur
		//e.target.focus();
		
		if (this.state.formIsValid) {
			this.setState({ loading: true });
			userService
				.updatePaymentInfo(
					this.prepareSubmitData(),
					this.props.token,
					this.props.user.type
				)
				.then((response) => {
					// update the store in the main component. As we are getting only the profile clone deep the rest of the data
					const clonedUserData = clone(this.props.user);
					clonedUserData.profile = response.data;
					this.props.updateStore();
					this.setState({ loading: false });

					//const queryParam = new URLSearchParams(this.props.location.search);
					if (isUrlQueued(this.props)) {
						//const backToQueryParam = new URLSearchParams(this.props.location.search);
						//backToQueryParam.delete('back-to');

						setTimeout(
							() => goToNextQueuedUrl(this.props)
							/* 	this.props.history.push(
									queryParam.get('back-to') + '?' + backToQueryParam
								) */,
							1000
						);
					}
				})
				.catch((error) => {
					console.log(error);
					const apiErr = errorParser(error.response.data.errors);
					console.log(apiErr);

					const cloneForm = clone(this.state.formControls);

					// config object to be used by checkFormApiErrors
					const errorStateConfig = {
						formCityError: 'city',
						formCountryError: 'country',
						formStreetError: 'street',
						formZipCodeError: 'zipCode',
						//formSocialNumberError: 'socialNumber1'
					};

					// checking form errors for matching pairs defined in the errorStateConfig
					validator.checkFormApiErrors(cloneForm, errorStateConfig, apiErr);

					this.setState({
						formIsValid: false,
						formControls: cloneForm,
						loading: false
					});
				});
		}
	}

	render() {
		const buttonSaveBase = (text) => (
			<Button
				fullWidth={true}
				size="large"
				type="submit"
				disabled={this.state.loading || !this.state.formIsValid}
				loading={this.state.loading}>
				{text}
			</Button>
		);
		const buttonSaveRender =
			isUrlQueued(this.props)
				? buttonSaveBase('SAVE AND GO BACK')
				: buttonSaveBase('SAVE CHANGES');

		// only teacher can see this info
		const teacherOnlyInfoRender = () =>
			this.props.user.type === 'teacher' ? (
				<React.Fragment>
					<h3 style={{ marginTop: '30px' }}>Social Security Number</h3>
					<p>
						If you are a United States citizen, InfoDepot is required to issue you a 1099
						form and to report your income to the IRS using your social security number.
					</p>
					<div className={classes.SocialInputsWrap}>
						<Input
							style={{ width: 'calc(30% - 7px)' }}
							config={this.state.formControls.socialNumber1}
							onChange={(e) => this.inputChangeHandler(e, 'socialNumber1')}
							onBlur={(e) => this.inputBlurHandler(e, 'socialNumber1')}
						/>
						<Input
							style={{ width: 'calc(30% - 7px)' }}
							config={this.state.formControls.socialNumber2}
							onChange={(e) => this.inputChangeHandler(e, 'socialNumber2')}
							onBlur={(e) => this.inputBlurHandler(e, 'socialNumber2')}
						/>
						<Input
							style={{ width: 'calc(40% - 7px)' }}
							config={this.state.formControls.socialNumber3}
							onChange={(e) => this.inputChangeHandler(e, 'socialNumber3')}
							onBlur={(e) => this.inputBlurHandler(e, 'socialNumber3')}
						/>
					</div>
				</React.Fragment>
			) : null;

		// only visible when USA is selected as a country
		const USAInfoRender = () =>
			this.state.formControls.country.value === 'USA' ? (
				<React.Fragment>
					<Input
						config={this.state.formControls.state}
						onChange={(e) => this.inputChangeHandler(e, 'state')}
						onBlur={(e) => this.inputBlurHandler(e, 'state')}
					/>
					{/* {teacherOnlyInfoRender()} */}
				</React.Fragment>
			) : null;

		return (
			<Form onSubmit={(e) => this.submit(e)}>
				<h3 className={classes.Subheaders}>Billing Address</h3>
				<Input
					config={this.state.formControls.street}
					onChange={(e) => this.inputChangeHandler(e, 'street')}
					onBlur={(e) => this.inputBlurHandler(e, 'street')}
				/>
				<Input
					config={this.state.formControls.city}
					onChange={(e) => this.inputChangeHandler(e, 'city')}
					onBlur={(e) => this.inputBlurHandler(e, 'city')}
				/>
				<Input
					config={this.state.formControls.zipCode}
					onChange={(e) => this.inputChangeHandler(e, 'zipCode')}
					onBlur={(e) => this.inputBlurHandler(e, 'zipCode')}
				/>
				<Input
					config={this.state.formControls.country}
					onChange={(e) => this.inputChangeHandler(e, 'country')}
					onBlur={(e) => this.inputBlurHandler(e, 'country')}
				/>

				{// only visible when USA is selected as a country
				USAInfoRender()}

				{buttonSaveRender}
			</Form>
		);
	}
}

export default withRouter(PersonalSettings);
