import React from "react";
import { Link } from "react-router-dom";

import classes from "./SignUpModal.module.scss";
import Modal from "../../components/UI/Modal/Modal";
import IconStudent from "../../assets/images/icons/Student icon.svg";
import IconTeacher from "../../assets/images/icons/teacher icon.svg";
import Button from "../../components/UI/Button/Button";
import { setReturnToUrlToken } from "../../utils/redirectTo";

const SignUpModal = (props) => {
  // const setReturningPath = () => {
  // 	setReturnToUrlToken(props.onAuthReturnToUrl);
  // 	if(localStorage.getItem('contestPage') ) {
  //         localStorage.removeItem('contestPage');
  //       }
  // };
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h1 style={{ marginTop: 0 }}>Let’s start our journey!</h1>
      <h4>What you would like to do on InfoDepot?</h4>

      <div className={classes.UserTypeWrap}>
        <div>
          <Link to="/sign-up-student">
            <img
              className={classes.IconUserType}
              src={IconStudent}
              alt="student"
            />
            <Button fullWidth={true} size="large">
              START LEARNING
            </Button>
          </Link>
        </div>
        <div>
          <Link to="/pricing-plans">
            <img
              className={classes.IconUserType}
              src={IconTeacher}
              alt="teacher"
            />
            <Button color="secondary" fullWidth={true} size="large">
              START TEACHING
            </Button>
          </Link>
        </div>
      </div>
      <div style={{ marginTop: "50px" }}>
        <span>Already have an account?</span> <Link to={"/auth"}>Sign in</Link>
      </div>
    </Modal>
  );
};

export default React.memo(SignUpModal);
