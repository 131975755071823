import React, { Component } from 'react'
import Collapse from '@material-ui/core/Collapse'
import ReactSVG from 'react-svg'
import Moment from 'react-moment'
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor'

import classes from './Comment.module.scss'
import Avatar from '../../UI/Avatar/Avatar'
import Button from '../../UI/Button/Button'
import PostComment from '../PostComment/PostComment'
import IconBook from './../../../assets/images/icons/book_icon.svg'
import {Link} from "react-router-dom";
import { ChosenDisplayName } from '../../UI/ChosenDisplayName/ChosenDisplayName'

class Comment extends Component {
  state = {
    writeReply: false
  }

  toggleReply = () => {
    this.setState(prevState => ({ writeReply: !prevState.writeReply }))
  }

  render () {
    const { comment, videoOwnerCrypt } = this.props
    const { writeReply } = this.state
    const isAuthor = post => videoOwnerCrypt === post.author.cryptName
    const photoSrc =
      comment.author.profile.photo && comment.author.profile.photo.small
    // const comenterImage = comment.replies.map((reply) => reply.author.profile.photo.small)

    const totalReplies =
      comment.replies.length &&
      comment.replies[comment.replies.length - 1]._total

    const singleReply = comment.replies.map(reply => reply.id)

    const contentRender = (post, type) => {
      console.log(post)

      const commentRender = (
        <div key={post.id} id={post.id} className={classes.ContentWrap}>
          {/* {type} */}
          <strong>
            {/* teacher icon */}
            {isAuthor(post) ? (
              <ReactSVG className={classes.IconTeacher} src={IconBook} />
            ) : null}
            {/* {post.author.profileUrl ? (
              <Link
                to={`/profile/${post.author.profileUrl}`}
              >{`${post.author.profile.firstName} ${post.author.profile.lastName}`}</Link>
            ) : (
              `${post.author.profile.firstName} ${post.author.profile.lastName}`
            )} */}
            {post.author.profileUrl ? (
              <Link to={`/profile/${post.author.profileUrl}`}>
                <ChosenDisplayName
                  user={
                    post.author.profile && post.author.profile.displayName
                      ? post.author.profile
                      : post.author
                  }
                  top="-1px"
                />
              </Link>
            ) : (
              <ChosenDisplayName
                user={
                  post.author.profile && post.author.profile.displayName
                    ? post.author.profile
                    : post.author
                }
                top="-1px"
              />
            )}
          </strong>
          <span className={classes.CreatedAt}>
            <Moment format="MMM DD, YYYY HH:mm">{post.createdAt}</Moment>
          </span>
          <p>{post.content}</p>
        </div>
      );

      const replyWrap = (
        <div
          id={post.id}
          className={[
            classes.ReplyContentWrap,
            isAuthor(post) ? classes.IsTeacherWrapReply : ''
          ].join(' ')}
        >
          <Avatar
            src={post.author.profile.photo && post.author.profile.photo.small}
            className={classes.Avatar}
          />
          {commentRender}
        </div>
      )
      return (
        // if post is reply wrap the content inside ReplyContentWrap
        type === 'reply' ? replyWrap : commentRender
      )
    }

    const viewAllRender = () =>
      totalReplies > comment.replies.length && (
        <div className={classes.ViewAllWrap}>
          <Button type='link' onClick={() => this.props.showAllReplies()}>
            View all replies
          </Button>{' '}
          <strong>{totalReplies} replies</strong>
        </div>
      )
    return (
      <div
        className={[
          classes.CommentBase,
          isAuthor(comment) ? classes.IsTeacherWrapComment : ''
        ].join(' ')}
      >
        {/* upper section */}
        <section className={classes.CommentUpperSection}>
          <Avatar src={photoSrc} className={classes.Avatar} />
          <div className={classes.CommentWrap}>
            {contentRender(comment, 'comment')}
          </div>
        </section>
        {/* lower section */}
        <section className={classes.CommentLowerSection}>
          {/* view all replies */
            viewAllRender()}

          {/* replies list */
            comment.replies.map(reply => contentRender(reply, 'reply'))}

          {/* CTA */}
          {this.props.purchased ? (
            <React.Fragment>
              <Button
                className={[
                  writeReply && classes.ReplyActive,
                  classes.ButtonReply
                ].join(' ')}
                onClick={this.toggleReply}
                type='link'
              >
                Reply
              </Button>
              <Collapse in={writeReply}>
                <PostComment
                  type='reply'
                  slug={comment.id}
                  onPostSuccess={replyData =>
                    this.props.onPostSuccess(replyData)
                  }
                />
              </Collapse>
            </React.Fragment>
          ) : (
            <div className={classes.PurchaseReply}>
              Please{' '}
              <Button
                type='link'
                onClick={() => {
                  this.props.showBuyModal && this.props.showBuyModal()
                }}
              >
                purchase this class
              </Button>{' '}
              to reply
            </div>
          )}
        </section>
      </div>
    )
  }
}

export default Comment
