import React from 'react'
import Moment from 'react-moment'
import {Link} from "react-router-dom";

import classes from './Review.module.scss'
import RateStars from '../RateStars/RateStars'
import Avatar from '../../UI/Avatar/Avatar'
import { ChosenDisplayName } from '../../UI/ChosenDisplayName/ChosenDisplayName';

const Review = props => {
  const { review } = props
  return (
    <div className={classes.Container}>
      <Avatar
        className={classes.Avatar}
        src={review.author.profile.photo && review.author.profile.photo.small}
      />

      <div className={classes.ContentWrap}>
        <div className={classes.NameDateWrap}>
          <strong>
            {/* {review.author.profileUrl ? (
              <Link to={`/profile/${review.author.profileUrl}`}>
                {review.author.profile.firstName +
                  ' ' +
                  review.author.profile.lastName}
              </Link>
            ) : (
              review.author.profile.firstName +
              ' ' +
              review.author.profile.lastName
            )} */}
            {review.author.profileUrl ? (
              <Link to={`/profile/${review.author.profileUrl}`}>
                <ChosenDisplayName user={review.author.profile} top="-1px" />
              </Link>
            ) : (
              <ChosenDisplayName user={review.author.profile} top="-1px" />
            )}
          </strong>
          <Moment format='MMM DD, YYYY'>{review.createdAt}</Moment>
        </div>
        <div className={classes.RateWrap}>
          <strong>{review.rate.toFixed(1)}</strong>
          <RateStars rate={review.rate} />
        </div>

        <p>{review.ratingComment}</p>
      </div>
    </div>
  )
}

export default Review
