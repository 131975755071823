import React from 'react';
import colours from '../../../sass/settings/_settings.colors.scss';
import { PLAN_ID_PRO } from '../../../utils/consts';

export const DISPLAY_NAME_TYPE_BOTH = 'name_and_username';
export const DISPLAY_NAME_TYPE_ONLY_FULLNAME = 'name';
export const DISPLAY_NAME_TYPE_ONLY_USERNAME = 'username';

export const shouldDisplayUsernameSection = (user) => {
  const displayNameObj = user.displayName
    ? JSON.parse(user.displayName)
    : user.teacherDisplayName
    ? JSON.parse(user.teacherDisplayName)
    : null;
  const displayUsernameSection =
    displayNameObj &&
    displayNameObj.name !== '' &&
    displayNameObj.username !== '';
  return displayUsernameSection;
};

export const renderDisplayName = (user) => {
  const displayNameObj = user.displayName
    ? JSON.parse(user.displayName)
    : user.teacherDisplayName
    ? JSON.parse(user.teacherDisplayName)
    : null;
  if (!displayNameObj) {
    return 'displayName is null';
  } else if (displayNameObj.name !== '') {
    return displayNameObj.name;
  } else if (displayNameObj.username !== '') {
    return displayNameObj.username;
  } else {
    return 'displayName is empty';
  }
};

export function ChosenDisplayName({ user, top, noBadge }) {
  // console.log('user from ChosenDisplayName component:');
  // console.log(user);

  const setBackgroundColorKey = () => {
    let backgroundColor =
      user.plan && user.plan === PLAN_ID_PRO
        ? 'color-orange-strong'
        : 'color-purple';
    return backgroundColor;
  };

  const style = {
    position: 'relative',
    display: 'inline-block',
    padding: '0px 3px',
    margin: '0px',
    borderRadius: '3px',
    color: '#ffffff',
    fontSize: '10px',
    fontWeight: 700,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    backgroundColor: colours[setBackgroundColorKey()],
    top: top ? top : 'null',
  };

  return (
    <>
      {renderDisplayName(user)}&nbsp;
      {user.plan && user.plan === PLAN_ID_PRO && !noBadge && (
        <span style={style}>{user.plan}</span>
      )}
    </>
  );
}
