import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import clone from 'lodash.clonedeep';
import { debounce } from 'lodash';

import classes from './BuyVideoModal.module.scss';
import Modal from '../../components/UI/Modal/Modal';
import Divider from '../../components/UI/Divider/Divider';
import Button from '../../components/UI/Button/Button';
import apiConfig from '../../utils/apiConfig';
import * as videoService from '../../services/video/videoService';
import * as actions from '../../store/actions/indexActions';
import IconPlay from './../../assets/images/icons/play_icon.svg';
import IconClock from '@material-ui/icons/AccessTime';
import ReactSVG from 'react-svg';
import RateStars from '../../components/Ratings/RateStars/RateStars';
import { addToUrlQueue } from '../../utils/redirectTo';
import { Input } from '../UI/Form/Form';
import { ChosenDisplayName } from '../UI/ChosenDisplayName/ChosenDisplayName';

class BuyVideoModal extends React.PureComponent {
  state = {
    loading: false,
    discount: 0,
    enteredCode: '',
    formControls: {
      code: {
        id: 'code',
        type: 'text',
        placeholder: 'Coupon code',
        placeholderStatic: 'Enter code',
        shrink: true,
        value: '',
        // validation: { required: true, minLength: 6, maxLength: 20 },
        error: '',
        valid: false,
        touched: false,
        infolabel:
          'For a discount, enter the coupon code you received from the teacher',
      },
    },
  };

  recalculatePrice = (price) => {
    return this.state.discount > 0
      ? (price * (100 - this.state.discount)) / 100
      : price;
  };

  purchaseVideo = () => {
    const price = this.props.video.price;
    const recalculatedPrice = this.recalculatePrice(price);
    // if there isn't enough credits redirect user to Topup dollars page
    if (this.props.user.totalCredits < recalculatedPrice) {
      this.props.snackbarAdd({
        message: 'To purchase a video class, please Top Up your account.',
        type: 'info',
        timeout: 10000,
      });

      this.props.history.replace(
        '/buy-credits?' +
          addToUrlQueue(
            '/video/' + this.props.video.slug + '?buy-modal=1',
            this.props
          )
      );
    } else {
      console.log('Buying video', this.props.video);
      this.setState({ loading: true });
      const queryParams =
        this.state.enteredCode !== ''
          ? `?couponCode=${this.state.enteredCode}`
          : '';
      videoService
        .purchaseVideoClass(
          this.props.token,
          this.props.video.slug,
          queryParams
        )
        .then((response) => {
          this.props.snackbarAdd({
            message:
              'You have successfully bought this video. You can always access it from your Video Library in your Account.',
            timeout: 8000,
          });
          // turn off the loader
          this.setState({ loading: false });
          // on complete update the user to reduce the price of the video
          this.props.updateLoggedUser();

          // on success return the new video to the parent component
          if (this.props.onSuccess) {
            this.props.onSuccess(response.data.videoClass);
          }
          console.log(this.props);
          this.props.history.push('/video/' + this.props.video.slug);
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (error.response) {
            this.props.snackbarAdd({
              message: error.response.data.message,
              timeout: 8000,
              type: 'error',
            });
          }
          // this.props.onClose();
        });
    }
  };

  debouncedCouponCode = debounce(async (value) => {
    this.getCoupon(value, this.props.video.author.cryptName);
  }, 300);

  inputChangeHandler = (e, controlName) => {
    const formControlsCopy = clone(this.state.formControls);
    const ctrl = formControlsCopy[controlName];

    ctrl.value = e.target.value;

    this.setState({
      formControls: formControlsCopy,
    });

    this.debouncedCouponCode(e.target.value.toUpperCase());
  };

  getCoupon = (code, teacherCryptName) => {
    const queryParams = `?code=${code}&teacherCryptName=${teacherCryptName}`;

    videoService
      .getCoupon(this.props.token, queryParams)
      .then((response) => {
        const discount = response.data.discount;
        this.setState({ discount: discount, enteredCode: code });
        this.props.snackbarAdd({
          message: `Great news! You have just received ${discount}% discount.`,
          timeout: 8000,
        });
      })
      .catch((error) => {
        // this.setState({ discount: 0, enteredCode: '' });
        this.setState({ discount: 0, enteredCode: code });
        // console.log(error);
        // console.log(error.response);
      });
  };

  render() {
    return (
      this.props.video && (
        <Modal open={this.props.open} onClose={this.props.onClose}>
          <h2 className={classes.ModalTitle}>Buy Video Class</h2>
          <div className={classes.ModalContent}>
            <div className={classes.ModalColumns}>
              <div className={classes.ImageWrapper}>
                <div
                  className={classes.CoverImage}
                  style={{
                    backgroundImage:
                      'url(' +
                      (this.props.video.cover
                        ? apiConfig.DOMAIN_IMAGE + this.props.video.cover.big
                        : '') +
                      ')',
                  }}
                ></div>
                <div className={classes.IconPlayWrapper}>
                  <ReactSVG className={classes.IconPlay} src={IconPlay} />
                </div>
              </div>
            </div>
            <div className={classes.ModalColumns}>
              <h2>{this.props.video.name}</h2>
              <p className={classes.ModalVideoAuthor}>
                by <ChosenDisplayName user={this.props.video.author} />
              </p>
              <div className={classes.VideoLength}>
                <IconClock />{' '}
                {(
                  this.props.video.uploadVideo.videoDuration &&
                  this.props.video.uploadVideo.videoDuration / 60
                ).toFixed(1) + 'min'}
              </div>
              <div className={classes.StarsWrap}>
                <RateStars
                  className={classes.Stars}
                  rate={Number(this.props.video.averageRating)}
                />
                <strong>{this.props.video.averageRating}</strong>
                {`(${
                  this.props.video.numberOfRating
                    ? this.props.video.numberOfRating
                    : 0
                } reviews)`}
              </div>
            </div>
          </div>
          <Divider horizontal="true" style={{ margin: '30px 0px 35px' }} />
          <Input
            config={this.state.formControls.code}
            onChange={(e) => this.inputChangeHandler(e, 'code')}
            className={classes.TextUppercase}
          />
          <h3 className={classes.ModalTotal}>
            Total:{' '}
            {this.state.discount > 0 ? (
              <span className={classes.ModalPrice}>
                $
                {(this.recalculatePrice(this.props.video.price) * 1).toFixed(2)}{' '}
                <span
                  style={{ textDecoration: 'line-through', color: '#a5a5a5' }}
                >
                  (${(this.props.video.price * 1).toFixed(2)})
                </span>
              </span>
            ) : (
              <span className={classes.ModalPrice}>
                ${(this.props.video.price * 1).toFixed(2)}
              </span>
            )}
          </h3>
          <Button
            fullWidth={true}
            size="large"
            type="submit"
            onClick={this.purchaseVideo}
            loading={Number(this.state.loading)}
          >
            PAY
          </Button>
        </Modal>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
    updateLoggedUser: () => dispatch(actions.getLoggedUser()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuyVideoModal)
);
