import axiosInstance from '../axiosDefaultInstance'

// List of call classes by a logged user.
export const getAllLiveCalls = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/calls?' + (queryParams || '')

  return axiosInstance.get(url, config)
}

// we need to create skeleton for the live calls so we can attach/replace cover picture while on the create form
export const createSeleton = token => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }

  const url = '/classes/call/create-skeleton'

  return axiosInstance.post(url, null, config)
}

export const createCallClass = (token, CallClassData) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/call/create'

  return axiosInstance.post(url, CallClassData, config)
}

export const editCallClass = (token, CallClassData, callSlug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/call/' + callSlug

  return axiosInstance.put(url, CallClassData, config)
}

export const uploadCoverPhoto = (token, file, id, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token,
      'content-type': 'multipart/form-data'
    }
  }
  const query = slug ? '&callClassSlug=' + slug : 'callClassId=' + id
  const url = '/classes/call/cover-image?' + query
  const formData = new FormData()
  formData.append('cover[binaryContent]', file, file.name)

  return axiosInstance.post(url, formData, config)
}

export const getLiveCall = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/call/' + slug

  return axiosInstance.get(url, token ? config : null)
}

// @TODO rename the 'all calls' to proper names
// PUBLIC API, in case there is no token of a logged user do not sent any
export const getAllBrowseCalls = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = '/classes/all-calls' + (queryParams ? '?' + queryParams : '')

  return axiosInstance.get(url, token ? config : null)
}

//Get first calls available per user
export const getFirstAvailableCalls = (queryParams) => {
  const url = '/classes/first-available-calls' + (queryParams ? '?' + queryParams : '')

  return axiosInstance.get(url)
}

// start a live call
export const startLiveCall = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `classes/call/${slug}/make-live-call`

  return axiosInstance.post(url, null, config)
}

// start a live call
export const startLiveCallVideo = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `classes/call/${slug}/make-video-live-call`

  return axiosInstance.post(url, null, config)
}

///api/v1/classes/video-live-call/{slug}/student

//Check teacher status in video call
export const checkTeacherStatusVideoCall = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `classes/video-live-call/${slug}/student`

  return axiosInstance.post(url, null, config)
}

//Teacher if he has call
export const incomingCallCheck = (token) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `realtime/classes/video-live-call/teacher`

  return axiosInstance.get(url, config)
}

//Student if he has call
export const incomingCallCheckStudent = (token) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `realtime/classes/video-live-call/student`

  return axiosInstance.get(url, config)
}


//Teacher accept video call
export const acceptVideoCall = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `/classes/video-live-call/${slug}/1`

  return axiosInstance.post(url, null ,config)
}

//Teacher decline video call
export const declineVideoCall = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `/classes/video-live-call/${slug}/0`

  return axiosInstance.post(url, null ,config)
}

//Room events
// export const roomEvents = (token, slug) => {
//   const config = {
//     headers: {
//       'X-Auth-Token': token
//     }
//   }
//   const url = `/classes/video-live-call/twilio/room-events/${slug}`

//   return axiosInstance.post(url, null ,config)
// }

// add to a teacher queue line
export const addToQueue = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `classes/call/${slug}/queue-live-call`

  return axiosInstance.post(url, null, config)
}

// add to a teacher queue line video
export const addToQueueVideo = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `classes/call/${slug}/queue-video-live-call`

  return axiosInstance.post(url, null, config)
}

//Student stop video call, video call delete
export const deleteVideoCall = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `classes/video-live-call/${slug}`

  return axiosInstance.delete(url, config);
  //return console.log(token, slug);
}

//After call created student check call status
export const callStatusStudent = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = `/classes/video-live-call/${slug}/student`

  return axiosInstance.get(url, config)
}

export const getStudentVideoCalls = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
    'classes/video-live-call/student-calls' + (queryParams || '')

  return axiosInstance.get(url, config)
}

// List of call classes by a logged user.
export const getStudentLiveCalls = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
    'classes/live-call/student-calls' + (queryParams || '')

  return axiosInstance.get(url, config)
}

export const getTeacherLiveCalls = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
    'classes/live-call/teacher-calls' + (queryParams || '')

  return axiosInstance.get(url, config)
}

export const getTeacherLiveCallsVideo = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
    'classes/video-live-call/teacher-calls' + (queryParams || '')

  return axiosInstance.get(url, config)
}

export const getTeacherQueueCalls = (token, queryParams) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
    'classes/live-call/teacher-queues' + (queryParams || '')

  return axiosInstance.get(url, config)
}

export const getStudentQueueCalls = token => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = 'classes/live-call/student-queues'

  return axiosInstance.get(url, config)
}

export const getTeacherQueueVideoCalls = token => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = 'classes/video-live-call/teacher-queues'

  return axiosInstance.get(url, config)
}

export const getStudentQueueVideoCalls = token => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url = 'classes/video-live-call/student-queues'

  return axiosInstance.get(url, config)
}

export const getCallStatusList = idList => {
  const payload = {
    callClassesSlugs: idList
  }

  return axiosInstance.post('/realtime/call-classes', payload)
}

// List of call classes by a logged user.
export const getLiveCallPhoneStatus = (token, slug) => {
  const config = {
    headers: {
      'X-Auth-Token': token
    }
  }
  const url =
  `/realtime/live-call/${slug}`

  return axiosInstance.get(url, config)
}
