import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import windowWidth from 'react-window-size';
import apiConfig from '../../../utils/apiConfig';
import IconButton from '@material-ui/core/IconButton';
import IconCamera from '@material-ui/icons/PhotoCamera';
import Button from './../../../components/UI/Button/Button';
import ImageCropDialog from '../../../containers/TeacherProfile/ImageCoverCropDialog';
import ReactSVG from 'react-svg';
import IconLiveCall from '../../../assets/images/icons/live_call_icon.svg';
import IconVideoBasic from '../../../assets/images/icons/video-solid.svg';
import IconMessage from '../../../assets/images/icons/messages_icon.svg';
import classes from '../../../containers/TeacherProfile/TeacherProfile.module.scss';
import * as actions from '../../../store/actions/indexActions';
import SignUpModal from '../../SignUpModal/SignUpModal';
import IconQueue from '../../../assets/images/icons/hour_glass_icon.svg';
import IconMute from '../../../assets/images/icons/live_call_offline_icon.svg';
import IconVideoSlash from '../../../assets/images/icons/video-slash-solid.svg';
import LiveCallModal from '../../LiveCallModal/LiveCallModal';
import TwilioVideo from '../../TwilioVideo/TwilioVideo';
import { ChosenDisplayName, shouldDisplayUsernameSection } from '../ChosenDisplayName/ChosenDisplayName';

class CoverPhotoUpload extends Component {
  state = {
    file: null,
    modalCropOpened: false,
    src: null,
    modalSignUpOpened: false,
    modalCallOpened: false,
    selectedCall: null,
    modalCallType: '',
    twilioVideo: false,
    modalLiveCallOpened: false,
    modalVideoCall: false,
    roomName: '',
    roomToken: '',
    participient: '',
    videoName: '',
    participientUrlImage: '',
  };

  openCallModalPhone = (call) => {
    if (this.props.userData) {
      this.setState({
        modalCallOpened: true,
        selectedCall: call,
        modalCallType: 'phone',
      });
    } else {
      this.openSignUpModal('/call/' + call.slug);
    }
  };

  openCallModalVideo = (call) => {
    if (this.props.userData) {
      this.setState({
        modalCallOpened: true,
        selectedCall: call,
        modalCallType: 'video',
      });
    } else {
      this.openSignUpModal('/call/' + call.slug);
    }
  };

  //close twilio video
  closeTwilioVideo = () => {
    this.setState({
      twilioVideo: false,
    });
  };

  closeCallModal = (
    room,
    roomToken,
    participient,
    videoName,
    twilioVideo,
    participientUrlImage
  ) => {
    this.setState({
      modalLiveCallOpened: false,
      selectedCall: null,
      modalVideoCall: false,
      roomName: room,
      roomToken: roomToken,
      participient: participient,
      videoName: videoName,
      twilioVideo: twilioVideo,
      participientUrlImage: participientUrlImage,
    });
  };

  closeCropModal = () => {
    this.setState({ modalCropOpened: false });
  };

  onChange = (e) => {
      let file = e.target.files[0];
      if (!this.validImage(e, file)) {
          return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
          let img = document.createElement("img");
          img.onload = (event) =>  {
              // Dynamically create a canvas element
              let canvas = document.createElement("canvas");
              // var canvas = document.getElementById("canvas");
              let ctx = canvas.getContext("2d");
              let width = img.width;
              let height = img.height;
              let MAX_WIDTH = 1200;
              let MAX_HEIGHT = 1000;

              if (width > height) {
                  if (width > MAX_WIDTH) {
                      height = height * (MAX_WIDTH / width);
                      width = MAX_WIDTH;
                  }
              } else {
                  if (height > MAX_HEIGHT) {
                      width = width * (MAX_HEIGHT / height);
                      height = MAX_HEIGHT;
                  }
              }

              canvas.width = width;
              canvas.height = height;

              // Actual resizing
              ctx.drawImage(img, 0, 0, width, height);
              canvas.toBlob((blob) => {
                  this.setState({ file: blob, modalCropOpened: true });
              })
          }
          img.src = e.target.result;
      }
      reader.readAsDataURL(file);
  };

  validImage = (e, file) => {
    const fileFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/x-png'];

    // frontend validation of uploaded file
    if (file) {
      // validation for size, return the function if rule is not applied
      let errorMessage = '';
      if (file.size > 20 * 1024 * 1024) {
        errorMessage = 'This file is too big. Please upload a file up to 20MB.';
      } else if (!fileFormats.includes(file.type)) {
        // validation for type, return the function if rule is not applied
        errorMessage = `Please upload one of the following formats 'JPG', 'JPEG', 'PNG'.`;
      }

      if (errorMessage) {
        this.showError(errorMessage);
        return false;
      } else {
        return true;
      }
    }
  };

  showError = (message) => {
    this.props.snackbarAdd({
      message: message,
      type: 'error',
      timeout: 8000,
    });
  };

  openSignUpModal = () => {
    this.setState({ modalSignUpOpened: true });
  };

  closeSignUpModal = () => {
    this.setState({ modalSignUpOpened: false });
  };

  render() {
    const ButtonCall = (call) => {
      switch (call.liveCallStatus) {
        case 'available':
          return (
            <Button
              className={classes.ButtonWithIcon}
              size="medium"
              color="primary"
              style={{ marginRight: '9px' }}
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalPhone(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconLiveCall}
              />{' '}
              Phone call
            </Button>
          );
        case 'queued':
          return (
            <Button
              className={classes.ButtonWithIcon}
              size="medium"
              color="secondary"
              style={{ marginRight: '9px' }}
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalPhone(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconQueue}
              />{' '}
              Queue for voice call
            </Button>
          );
        default:
          return (
            <Button
              className={classes.ButtonWithIcon}
              size="medium"
              color="primary"
              style={{ marginRight: '9px' }}
              disabled="true"
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconMute}
              />{' '}
              Currently offline
            </Button>
          );
      }
    };

    const ButtonVideoCall = (call) => {
      switch (call.videoCallStatus) {
        case 'available':
          return (
            <Button
              className={classes.ButtonWithIcon}
              size="medium"
              color="primary"
              style={{ marginRight: '9px' }}
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalVideo(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconVideoBasic}
              />{' '}
              Video call
            </Button>
          );
        case 'queued':
          return (
            <Button
              className={classes.ButtonWithIcon}
              size="medium"
              color="secondary"
              style={{ marginRight: '9px' }}
              onClick={(e) => {
                e.preventDefault();
                this.openCallModalVideo(call);
              }}
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconQueue}
              />{' '}
              Queue for video call
            </Button>
          );
        default:
          return (
            <Button
              className={classes.ButtonWithIcon}
              size="medium"
              color="primary"
              style={{ marginRight: '9px' }}
              disabled="true"
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconVideoSlash}
              />{' '}
              Currently offline
            </Button>
          );
      }
    };

    const screenWidth = this.props.windowWidth;
    const user = this.props.userData;
    const { profileWatching, firstAvailableCalls } = this.props;
    const teacherWatchSelf = user && profileWatching && user.cryptName === profileWatching.cryptName;
    const renderNonTeacherButtons = (
      <>
        {user && profileWatching && !teacherWatchSelf && firstAvailableCalls && (
          <>
            {firstAvailableCalls.haveCallClasses &&
              ButtonCall(
                firstAvailableCalls.call &&
                  firstAvailableCalls.call.data &&
                  firstAvailableCalls.call.data[0]
                  ? firstAvailableCalls.call.data[0]
                  : {}
              )}
            {firstAvailableCalls.haveVideoClasses &&
              ButtonVideoCall(
                firstAvailableCalls.videoCall &&
                  firstAvailableCalls.videoCall.data &&
                  firstAvailableCalls.videoCall.data[0]
                  ? firstAvailableCalls.videoCall.data[0]
                  : {}
              )}
          </>
        )}
        {user ? (
          profileWatching && (
            <>
              {!teacherWatchSelf && (
                <Link to={`/messages/${profileWatching.cryptName}`}>
                  <Button
                    className={classes.ButtonWithIcon}
                    color="primary"
                    size="medium"
                  >
                    <ReactSVG
                      style={{
                        fontSize: '13px',
                        width: '14px',
                        marginRight: '5px',
                        position: 'relative',
                        top: '3px',
                      }}
                      src={IconMessage}
                    />{' '}
                    Message
                  </Button>
                </Link>
              )}
            </>
          )
        ) : (
          <div>
              { firstAvailableCalls.haveCallClasses && <Button
                  className={classes.ButtonWithIcon}
                  size="medium"
                  color="primary"
                  style={{ marginRight: '9px' }}
                  onClick={() => this.openSignUpModal()}
              >
                  <ReactSVG
                      style={{
                          fontSize: '13px',
                          width: '14px',
                          marginRight: '5px',
                          position: 'relative',
                          top: '3px',
                      }}
                      src={IconLiveCall}
                  />{' '}
                  Phone call
              </Button>}
              {firstAvailableCalls.haveVideoClasses && <Button
                  className={classes.ButtonWithIcon}
                  color="primary"
                  size="medium"
                  style={{ marginRight: '9px' }}
                  onClick={() => this.openSignUpModal()}
              >
                  <ReactSVG
                      style={{
                          fontSize: '13px',
                          width: '14px',
                          marginRight: '5px',
                          position: 'relative',
                          top: '3px',
                      }}
                      src={IconVideoBasic}
                  />{' '}
                  Video call
              </Button>}
            <Button
              className={classes.ButtonWithIcon}
              color="primary"
              size="medium"
              onClick={() => this.openSignUpModal()}
            >
              <ReactSVG
                style={{
                  fontSize: '13px',
                  width: '14px',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
                src={IconMessage}
              />{' '}
              Message
            </Button>
          </div>
        )}
      </>
    );

    const renderAddress = () => {
        if (profileWatching && profileWatching.profile.state) {
             return (
               <>
                   {`${profileWatching.profile.fullStateName}, USA`}
               </>
             );
        }
        return (
            <>
                {profileWatching && profileWatching.profile.country && profileWatching.profile.fullCountryName}
            </>
        )
    }

    return teacherWatchSelf ? (
      <div className={classes.HeroWrap}>
        <input
          onChange={(e) => this.onChange(e)}
          onClick={(e) => {
            e.target.value = null;
          }}
          style={{ display: 'none' }}
          accept="image/*"
          id="upload-cover-photo"
          type="file"
        />

        <div
          className={[classes.SectionOne, classes.ContainerBackground].join(
            ' '
          )}
        >
          {((profileWatching &&
          profileWatching.profile.coverPhoto &&
          profileWatching.profile.coverPhoto.big) || (teacherWatchSelf && user.profile.coverPhoto && user.profile.coverPhoto.big)) ? (
            <div
              className={classes.ImageBackground}
              style={{
                backgroundImage: `url( ${apiConfig.DOMAIN_IMAGE +
                  user.profile.coverPhoto.big})`,
              }}
            ></div>
          ) : (
            <div
							className={[classes.ImageBackground, classes.DefaultBackground].join(" ")}
            ></div>
          )}
          {screenWidth > 768 && (
            <div className={classes.SectionInner}>
              <div className={classes.SectionLeft}>
                <div className={classes.CoverContent}>
                  <div
                    style={{
                      color:
                          ((profileWatching && profileWatching.profile.coverPhoto) || (teacherWatchSelf && user.profile.coverPhoto))
                          ? 'white'
                          : 'black',
                    }}
                  >
                    <p style={{ fontSize: '30px' }}>
                      {profileWatching && <ChosenDisplayName user={profileWatching} top="-4px" />}
                    </p>
                    { profileWatching && shouldDisplayUsernameSection(profileWatching) && <p>{profileWatching && profileWatching.username}</p>}
                    <p>
                        {renderAddress()}
                    </p>
                  </div>
                  <Link to={'/account/dashboard/videos'}>
                    <Button
                      className={classes.Button}
                      size="medium"
                      color="primary"
                      style={{ marginRight: '9px' }}
                    >
                      Manage your classes
                    </Button>
                  </Link>
                  <Link to={'/account/settings/personal'}>
                    <Button
                      className={classes.Button}
                      color="primary"
                      size="medium"
                      type="outline"
                    >
                      Edit profile
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <label htmlFor="upload-cover-photo">
          <IconButton
            className={classes.IconButtonNoHover}
            style={{ position: 'absolute', bottom: '0px', right: '0px' }}
            component="span"
          >
            <span className={classes.CoverIconCamera}>
              <IconCamera />
            </span>
          </IconButton>
        </label>
        <ImageCropDialog
          modalCropOpen={this.state.modalCropOpened}
          closeModal={this.closeCropModal}
          image={this.state.file && URL.createObjectURL(this.state.file)}
        />
      </div>
    ) : (
      <div className={classes.HeroWrap}>
        <SignUpModal
          open={this.state.modalSignUpOpened}
          onClose={this.closeSignUpModal}
        />
        <LiveCallModal
          open={this.state.modalCallOpened}
          onClose={this.closeCallModal}
          call={this.state.selectedCall}
          type={this.state.modalCallType}
        />
        {this.state.twilioVideo && this.state.roomToken !== '' ? (
          <TwilioVideo
            roomName={this.state.roomName}
            roomToken={this.state.roomToken}
            incomingCallUser={this.state.participient}
            videoName={this.state.videoName}
            photoUrl={this.state.participientUrlImage}
            author={this.state.author}
            closeTwilioVideo={this.closeTwilioVideo}
          />
        ) : (
          ''
        )}
        <div
          className={[classes.SectionOne, classes.ContainerBackground].join(
            ' '
          )}
        >
          {profileWatching &&
          profileWatching.profile.coverPhoto &&
          profileWatching.profile.coverPhoto.big ? (
            <div
              className={classes.ImageBackground}
              style={{
                backgroundImage: `url( ${apiConfig.DOMAIN_IMAGE +
                  profileWatching.profile.coverPhoto.big})`,
              }}
            ></div>
          ) : (
            <div
							className={[classes.ImageBackground, classes.DefaultBackground].join(" ")}
            ></div>
          )}
          {screenWidth > 768 && (
            <div className={classes.SectionInner}>
              <div className={classes.SectionLeft}>
                <div className={classes.CoverContent}>
                  <div
                    style={{
                      color:
                        profileWatching && profileWatching.profile.coverPhoto
                          ? 'white'
                          : 'black',
                    }}
                  >
                    <p style={{ fontSize: '30px' }}>
                      {profileWatching && <ChosenDisplayName user={profileWatching} top="-4px" />}
                    </p>
                    {profileWatching && shouldDisplayUsernameSection(profileWatching) && <p>{profileWatching && profileWatching.username}</p>}
                    <p>
                        {renderAddress()}
                    </p>
                  </div>
                  {renderNonTeacherButtons}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user,
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarAdd: (snackConf) => dispatch(actions.snackbarAdd(snackConf)),
    updateLoggedUser: (userData) => dispatch(actions.getLoggedUser(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(windowWidth(CoverPhotoUpload));
